//海南海易办
import selectDistrict from '@/views/mobile/open/hai-nan/hai-yi-ban/select-district'
import home from '@/views/mobile/open/hai-nan/hai-yi-ban/home'

var routes = [
  {path: 'select-district',component:selectDistrict,name:'HNHYBSD',meta:{title:'海南省智慧村居法律服务平台'}},
  {path: 'home',component:home,name:'HNHYBH',meta:{title:'海南省智慧村居法律服务平台'}},
]

export default{
  routes:routes
}