<style scoped>
  .bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
  .introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
  .introduce-video-player{width:100%;}
  .mediator-list{color:#FFF;}
  .mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
  .mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
  .introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
  .introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
  .introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
  .vr-bg{background-image:url(https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/vr-banner.jpg);height:2rem;background-size:100% auto;}
</style>

<template>
  <div class="bg">
    <div class="vr-bg"></div>
    <div class="introduce-text">
      <div class="introduce-text-title">VR法治阵地</div>
      <div class="introduce-text-content">
        <Row>
          <Col span="18">
            <div style="">1、法治广场</div>
            <div style="">地址：简阳市东滨路南段494号</div>
          </Col>
          <Col span="6">
            <Button :to="{name:'MCJYVV',query:{scene:'sceneA'}}">在线游览</Button>
          </Col>
        </Row>
        <div style="padding:0.1rem;"></div>
        <Row>
          <Col span="18">
            <div style="">2、法治文化街 - 小明的一生</div>
            <div style="">地址：简阳市泰安路与射洪路南段交汇口</div>
          </Col>
          <Col span="6">
            <Button :to="{name:'MCJYVV',query:{scene:'sceneB'}}">在线游览</Button>
          </Col>
        </Row>
        <div style="padding:0.1rem;"></div>
        <Row>
          <Col span="18">
            <div style="">3、法治图书馆</div>
            <div style="">地址：简阳市政府街124号</div>
          </Col>
          <Col span="6">
            <router-link :to="{name:'MCJYVV',query:{scene:'sceneC'}}"><Button >在线游览</Button></router-link>
          </Col>
        </Row>
        <div style="padding:0.1rem;"></div>
        <Row>
          <Col span="18">
            <div style="">4、法治尤安</div>
            <div style="">地址：简阳市平武镇尤安村</div>
          </Col>
          <Col span="6">
            <router-link :to="{name:'MCJYVV',query:{scene:'sceneD'}}"><Button >在线游览</Button></router-link>
          </Col>
        </Row>
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'guild1',
  data() {
    return {

    };
 },
  methods: {

  },
  mounted() {

  },
}
</script>
