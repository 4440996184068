<template>
    <div class="mediateRegion">
        <Cascader placeholder="选择全域" :data="addressData" v-model="addressValue" @on-change="addressSelect"></Cascader>
        <div style="border-bottom: #999999 solid 0.01rem" v-for="(value,index) in mediatorData" @click="mediate(value)">
           <div style="margin: 0.1rem 0 0.1rem 0.3rem;display: inline-block;font-size: 0;max-width: 4.8rem;">
               <div style="font-size: 0.26rem;color: #000;font-weight: bold;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{value.twhmc}}</div>
               <div style="font-size: 0.22rem;color: #666;margin-top: 0.1rem;line-height: 0.4rem" @click.stop="phone(value.lxdh)">
                   <span>办公电话：{{value.lxdh}}</span>
                   <div class="makeaCall">
                     <img class="icon1" src="/img/icon2.png">
                   </div>
               </div>
               <div style="font-size: 0.22rem;color: #666;">
                   <span>地址：{{value.lxdz}}</span>
               </div>
           </div>
            <div style="display: inline-block;font-size: 0.24rem;border-radius: 0.5rem;margin: 0.6rem 0.3rem 0 0;
            padding: 0.3rem;background-color: rgb(45 140 240);color: #FFF;vertical-align: top;float: right;line-height: 0;">
                线上申请
            </div>
        </div>
        <div v-if="noData" style="text-align: center;font-size: 0.36rem;font-weight: bold;margin-top: 0.3rem">
            暂无更多数据
        </div>
    </div>
</template>

<script>
    export default {
        name: "mediateRegion",
        data (){
            return{
                addressData : [],
                addressValue : [],
                mediatorData : [],
                page : 1,
                noData : false,
              AreaCode : ''
            }
        },
        methods:{
          phone : function (lawPhone){
            window.location.href = 'tel://' + lawPhone;
          },
            mediate : function(e){
                var _this = this
                window.sessionStorage.AreaId = this.AreaId
                console.log(e)
                window.sessionStorage.twhmc = e.twhmc
                window.sessionStorage.fzr = e.fzr || '刘灿'
                window.sessionStorage.jflx = e.jflx
                window.sessionStorage.lxdz = e.lxdz
                window.sessionStorage.twhid = e.id || '6F592BFA937E43A28FDD7020A0FB3AB7'
                console.log(window.sessionStorage)
                this.$router.push({path: '/informationFillin',})
            },
            addressSelect : function (value, selectedData){
            console.log(selectedData)
                this.AreaId = value[1]
                this.page = 1
                this.mediatorData = []
                this.AreaCode = selectedData != '' ? selectedData[1].AreaCode : ''
              this.cqTwhlist(this.AreaCode)
                console.log(value, selectedData)
            },
            cqTwhlist:function(areaCode){
                var _this = this
                _this.send({
                    url:'/CqTwhlist',
                    data :{
                        content:{
                            limit: 20,
                            page: _this.page,
                            thirdFlow: "",
                            baseCode:"twh",
                            areaCode: areaCode
                        },
                    },
                    success:function(res){
                        if(res != ''){
                            for(var i in res){
                                _this.mediatorData.push(res[i])
                            }
                            _this.noData = false
                        }else{
                            if(_this.mediatorData == ''){
                                _this.noData = true
                            }
                        }

                    }
                })
            },
            cqAreaList:function(){
                var _this = this
                var data = []
                _this.send({
                    url:'/CqAreaList',
                    success:function(res){
                        for(var i in res){
                            data.push(res[i])
                        }
                        convertTree(data)
                        function convertTree(tree) {
                            const result = []
                            tree.forEach((item) => {
                                // 解构赋值
                                let {
                                    AreaId: value,
                                    AreaName: label,
                                    AreaCode : AreaCode,
                                    list: children
                                } = item
                                if (children) {
                                    children = convertTree(children)
                                }
                                result.push({
                                    value,
                                    label,
                                    AreaCode,
                                    children
                                })
                            })
                            _this.addressData = result
                            console.log(_this.addressData)
                            return result
                        }
                    }
                })
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        },
        created() {
            let that = this;
            window.onscroll = function () {
                // scrollTop 滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                // windowHeight 可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // scrollHeight 滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                // 滚动条到底部的条件
                if (scrollTop + windowHeight+5 >= scrollHeight) {
                    // 加载数据
                    that.page++
                    that.cqTwhlist(that.AreaCode);
                }
            }
        },
        mounted (){
          SYSCONFIG.server.default.host = 'cq.lvwa18.com'
          SYSCONFIG.server.default.port = '8001'
            this.cqAreaList()
            this.setPageSize()
            this.cqTwhlist()
            this.AreaId = '2238'
        },
    }
</script>

<style scoped>
.mediateRegion :deep(.ivu-input){height: 0.7rem;font-size: 0.28rem;font-weight: bold}
.icon1{width: 0.4rem;height: 0.4rem;}
.makeaCall{display: inline-block;vertical-align: top;margin-left: 0.2rem}
</style>
