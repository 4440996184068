//开放端路由模块加载器 Zima -- 2020-10-20
import nullPage from '@/views/nullPage'
import HaiNan from './hai-nan'
// import ChengDu from './cheng-du'
import ChongQing from './chong-qing'
import userClient from './user-client'
let open = {
  CDSFT:require('@/views/mobile/open/cheng-du/PLS/CDSFT').default,
  CDQX:require('@/views/mobile/open/cheng-du/PLS/CDQX').default,
  CDPLS:require('@/views/mobile/open/cheng-du/PLS/plsHome').default,
  CDLQYH:require('@/views/mobile/open/cheng-du/long-quan-yi/home').default,
  JYYLS:require('@/views/mobile/open/cheng-du/long-quan-yi/JYYLS').default,
  JYS:require('@/views/mobile/open/cheng-du/long-quan-yi/jysData').default,
  legalService:require('@/views/mobile/open/cheng-du/long-quan-yi/legalService').default,
  CDLQYD:require('@/views/mobile/open/cheng-du/long-quan-yi/dialogue').default,
  sfalk:require('@/views/mobile/open/cheng-du/long-quan-yi/sfalk').default,
  sfalkDetails:require('@/views/mobile/open/cheng-du/long-quan-yi/sfalkDetails').default,
  CDQBJI:require('@/views/mobile/open/cheng-du/qing-bai-jiang/index').default,
  CDQBJS:require('@/views/mobile/open/cheng-du/qing-bai-jiang/select-area').default,
  mdPage:require('@/views/mobile/open/mdPage').default,
}

let routes = [
  {
    path:'open',
    component:open.mdPage,
    children:new Array().concat([
      userClient.routes,
      HaiNan.routes,
      ChongQing.routes,
      {
        path:'cheng-du',
        component:nullPage,
        children:[
          {path: 'SFT',component:open.CDSFT,name:'CDSFT',meta:{title:'成都司法通'}},
          {path: 'CDQX',component:open.CDQX,name:'CDQX',meta:{title:'成都司法通'}},
          {path: 'PLS',component:open.CDPLS,name:'CDPLS',meta:{title:'成都市-公共法律服务平台'}},
          {path: 'LQYH',component:open.CDLQYH,name:'CDLQYH',meta:{title:'公共法律服务平台'}},
          {path: 'JYYLS',component:open.JYYLS,name:'JYYLS',meta:{title:'公共法律服务平台'}},
          {path: 'JYS',component:open.JYS,name:'JYS',meta:{title:'公共法律服务平台'}},
          {path: 'legalService',component:open.legalService,name:'legalService',meta:{title:'公共法律服务平台'}},
          {path: 'LQYD',component:open.CDLQYD,name:'CDLQYD',meta:{title:'公共法律服务平台'}},
          {path: 'sfalk',component:open.sfalk,name:'sfalk',meta:{title:'公共法律服务平台'}},
          {path: 'sfalkDetails',component:open.sfalkDetails,name:'sfalkDetails',meta:{title:'公共法律服务平台'}},
          {
            path: 'qing-bai-jiang',
            component:nullPage,
            children:[
              {path: 'index',component:open.CDQBJI,name:'CDQBJI',meta:{title:'青白江智慧公共法律服务'}},
              {path: 'select-area',component:open.CDQBJS,name:'CDQBJS',meta:{title:'青白江智慧公共法律服务'}},
            ]
          },
        ]
      },
    ])
  },
]

export default {
  routes:routes
}
