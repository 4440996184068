<template>
    <div>
        <div class="screen">
            <div style="background-color: white;margin:20px;">
                <div style="padding:15px"></div>
                <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px">法律援助 {{count}}条</div>
                <div style="padding:15px"></div>
                <div class="publicWelfare" >
                    <table class="formtable">
                        <thead>
                        <tr v-if="legalAidData[0].SupplementContent == null">
                            <th>申请用户</th>
                            <th>机构所在</th>
                            <th>村(社区)</th>
                            <th>申请内容</th>
                            <th>是否回复</th>
                            <th>平台回复</th>
                            <th>申请时间</th>
                        </tr>
<!--                        重庆-->
                        <tr v-else>
                          <th>申请时间</th>
                          <th>表单申请人</th>
                          <th>申请用户</th>
                          <th>联系电话</th>
                          <th>村（社区）</th>
                          <th>对方当事人</th>
                          <th>对方当事人住所地</th>
                          <th>申请内容</th>
                          <th>办理状态</th>
<!--                          <th>操作</th>-->
                        </tr>
                        </thead>
                        <tbody v-for="(value,index) in legalAidData" >
                        <tr v-if="value.SupplementContent == null">
                            <td><Avatar :src="value.UserImg" /> {{value.UserName}}</td>
                            <td>{{adminId == '1247' ? '东部新区':value.Content.orgCity+value.Content.orgArea}}</td>
                            <td>{{value.CityName}}{{value.AdminName}}{{value.VillagesName}}</td>
                            <td> <Button type="primary" @click="see(value.Content,value.UserId,value.FlyzApplyId);modal1 = true">查看</Button></td>
                            <td v-if="value.ReplyContent">
                                <Button type="primary" v-if="value.ReplyContent.data.zt1 == '已回复'" @click="FlyzApplyId = value.FlyzApplyId;UserId = value.UserId;result();modal3 = true;">{{value.ReplyContent.data.zt1}}</Button>
                                <span v-else>{{value.ReplyContent.data.zt1}}</span>
                            </td>
                            <td v-else="">未回复</td>
                            <td>
                                <Button type="warning" @click="onreply(value.FlyzApplyId,value.PlatformApply,false)" v-if="value.PlatformApply == null">点击回复</Button>
                                <Button type="primary" @click="onreply(value.FlyzApplyId,value.PlatformApply,true)" v-else>查看回复</Button>
                            </td>
                            <td>{{value.CreateTime}}</td>
                        </tr>
                        <tr v-else="">
                          <td>{{value.CreateTime}}</td>
                          <td>{{value.Content['biz_webcase.name']}}</td>
                            <td><Avatar :src="value.UserImg" /> {{value.UserName}}</td>
                          <td>{{value.Content['biz_webcase.phone']}}</td>
                          <td>{{value.CityName}}{{value.AdminName}}{{value.VillagesName}}</td>
                            <td>{{value.Content['biz_webcase.opposingname']}}</td>
                            <td>{{value.Content['biz_webcase.familyaddress']}}</td>
<!--                            <td>{{value.SupplementContent.dfFamilyaddress}}</td>-->
                            <td> <Button type="primary" @click="cqsSee(value,value.UserId,value.FlyzApplyId);modal4 = true">查看</Button></td>
                            <td v-if="value.ReplyContent">
                                <Button type="primary" v-if="value.ReplyContent.data.zt1 == '已回复'" @click="FlyzApplyId = value.FlyzApplyId;UserId = value.UserId;result();modal3 = true;">{{value.ReplyContent.data.zt1}}</Button>
                                <span v-else>{{value.ReplyContent.data.zt1}}</span>
                            </td>
                            <td v-else="">
                              未回复
                              <Button type="warning" @click="onreply(value.FlyzApplyId,value.PlatformApply,false)" v-if="value.PlatformApply == null">点击回复</Button>
                              <Button type="primary" @click="onreply(value.FlyzApplyId,value.PlatformApply,true)" v-else>查看回复</Button>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <div style="text-align: left;margin-left: 40px;font-size: 16px;padding-bottom: 20px">
                    <Page
                            :total="count"
                            show-total
                            :page-size="pageSize"
                            show-elevator
                            @on-change="page = $event;adminflyzApplyList()"
                    />
                </div>
            </div>
        </div>
        <Modal
                v-model="modal1"
                title="法律援助"
                ok-text="关闭"
                cancel-text=""
                >
            <div class="details">
                <div style="font-size: 16px">1、法律援助事项类别</div>
                <p><span>申请类别：</span>{{detailsData.zxsxlxzx}}</p>
                <div style="font-size: 16px">2、基本信息</div>
                <p><span>申请人：</span>{{detailsData.zxr}}</p>
                <p><span>性别：</span>{{detailsData.xb}}</p>
                <p><span>身份证号码：</span>{{detailsData.zjhm}}</p>
                <p><span>民族：</span>{{detailsData.mz}}</p>
                <p><span>出生日期：</span>{{detailsData.csrq}}</p>
                <p><span>年龄：</span>{{detailsData.nl}}</p>
                <p><span>联系电话：</span>{{detailsData.lxdh}}</p>
                <p><span>身份证：</span><Button type="primary" @click="sfclmcImg(detailsData.sfclmc);modal2 = true">查看身份证</Button></p>
                <div style="font-size: 16px">3、被申请人信息</div>
                <p><span>姓名：</span>{{detailsData.bsqr}}</p>
                <p><span>身份证号码：</span>{{detailsData.bsqrzjhm}}</p>
                <p><span>请填写住所地址：</span>{{detailsData.bsqrdz}}</p>
                <p><span>案情及申请 理由概述：</span>{{detailsData.zxnr}}</p>
                <p><span>机构所在：</span>{{detailsData.orgCity}}{{detailsData.orgArea}}</p>
            </div>
            <Button type="primary" @click="result();modal3 = true">结果查询</Button>
        </Modal>
        <!--重庆法律援助-->

        <Modal
                v-model="modal4"
                title="法律援助"
                ok-text="关闭"
                cancel-text=""
        >
            <div class="details">
                <div style="font-size: 16px">1、法律援助事项类别</div>
                <p><span>申请类别：</span>{{SupplementContent.legalhelpreasonname}}</p>
                <div style="font-size: 16px">2、基本信息</div>
                <p><span>申请人：</span>{{Content["biz_webcase.name"]}}</p>
                <p><span>民族：</span>{{SupplementContent.mz}}</p>
                <p><span>性别：</span>{{SupplementContent.sex}}</p>
                <p><span>出生日期：</span>{{SupplementContent.csrq}}</p>
                <p><span>联系电话：</span>{{Content["biz_webcase.phone"]}}</p>
                <p><span>居住地址：</span>{{Content["biz_webcase.familyaddress"]}}</p>
                <p><span>身份证号码：</span>{{Content["biz_webcase.identitycardnumber"]}}</p>
                <p><span>人群类型：</span>{{SupplementContent.webcrowdtypename}}</p>
                <p><span>对方当事人姓名：</span>{{Content["biz_webcase.opposingname"]}}</p>
                <p><span>对方当事人住所地：</span>{{SupplementContent.dfFamilyaddress}}</p>
                <p v-if="SupplementContent.humantypename"><span>类型：</span>{{SupplementContent.humantypename}}</p>
                <p><span>月收入：</span>{{SupplementContent.monthlyincomename}}</p>
                <p><span>申请事项明细：</span>{{Content["biz_webcase.caseremark"]}}</p>
            </div>
            <!--<Button type="primary" @click="result();modal3 = true">结果查询</Button>-->
        </Modal>
        <!---->
        <Modal
                v-model="modal2"
                title="身份证"
                ok-text="关闭"
                cancel-text="">
            <div v-model="sfclmc" >
                <img :src="sfclmc" style="width: 100%;">
            </div>
        </Modal>
        <!---->
        <Modal
                v-model="modal3"
                title="结果查询"
                ok-text="关闭"
                cancel-text=""
                @on-cancel="close"
        >
            <div class="details">
                <div class="consultation">
                    <div style="padding: 20rpx;">
                        <div v-if="resultReply.zt1">
                            <div>状态：{{resultReply.zt1}}</div>
                            <div>答复意见：{{resultReply.dfyj}}</div>
                            <div>答复日期：{{resultReply.jdrq}}</div>
                        </div>
                        <div v-if="resultReply.zt2">
                            <div>受理审查日期：{{resultReply.slscrq}}</div>
                            <div>受理审查单位：{{resultReply.slscdw}}</div>
                        </div>
                        <div v-if="resultReply.zt3">
                            <div>立案日期：{{resultReply.larq}}</div>
                            <div>案件编号：{{resultReply.ah}}</div>
                        </div>
                        <div v-if="resultReply.zt4">
                            <div>案件指派：{{resultReply.zprq}}</div>
                            <div>办理单位：{{resultReply.cbdw}}</div>
                            <div>承办律师{{resultReply.cbr}}</div>
                        </div>
                        <div v-if="resultReply.zt5">结案日期：{{resultReply.jarq}}</div>
                    </div>
                </div>
            </div>
        </Modal>
        <!--平台回复-->
        <Modal
                v-model="replyShow"
                title="平台回复"
                @on-ok="submit"
                ok-text="发送" style="height: 300px">
            <Input v-model="PlatformApply" maxlength="300" show-word-limit type="textarea" :disabled="readonly" placeholder="回复内容"  />
        </Modal>
    </div>
</template>

<script>
    export default {
        name: "legalAidList",
        data () {
            return {
                information : [],
                legalAidData : [],
                count : 0,
                pageSize : 20,
                page : 1,
                modal1 : false,
                detailsData : [],
                sfclmc : '',
                modal4 : false,
                modal3 : false,
                modal2 : false,
                resultReply : [],
                replyShow : false,
                replydata : '',
                FlyzApplyId : '',
                PlatformApply : '',
                Content : [],
                SupplementContent : [],
              readonly : false
            }
        },
        methods : {
            submit : function(){
                var _this = this
                _this.send({
                    url : '/AdminflyzApply',
                    data: {
                        FlyzApplyId : _this.FlyzApplyId,
                        PlatformApply : _this.PlatformApply,
                        AdminId:window.sessionStorage.AdminId,
                    },
                    success: function (res) {
                      _this.$Notice.success({
                        title:'系统提示',
                        desc:'发送成功',
                      })
                      _this.PlatformApply = ''
                      _this.adminflyzApplyList()

                    }
                })
            },
            onreply : function(FlyzApplyId,PlatformApply,readonly){
                this.FlyzApplyId = FlyzApplyId
                this.PlatformApply = PlatformApply
                this.readonly = readonly
                this.replyShow = !this.replyShow
            },
            close : function(){
                this.resultReply = []
            },
            result : function(){
                var _this = this
                _this.send({
                    url : '/flyzApply_resAdmin',
                    data: {
                        FlyzApplyId : _this.FlyzApplyId,
                        UserId : _this.UserId,
                    },
                    success: function (res) {
                        if(res.code != 500){
                            _this.resultReply = res.data
                        }else{
                            _this.resultReply = []
                        }

                    }
                })
            },
            adminflyzApplyList : function () {
                var _this = this
                _this.send({
                    url:'/AdminflyzApplyList',
                    data:{
                        AdminId:window.sessionStorage.AdminId,
                        page : _this.page,
                        pageSize : _this.pageSize
                    },
                    success:function (res) {
                        _this.legalAidData = res.List
                        _this.count = res.Count
                    }
                })
            },
            cqsSee : function (e,UserId,FlyzApplyId) {

                this.Content = e.Content
                this.SupplementContent = e.SupplementContent
                console.log(this.detailsData)
                this.UserId = UserId
                this.FlyzApplyId = FlyzApplyId
            },
            see : function (e,UserId,FlyzApplyId) {
                console.log(e)
                this.detailsData = e
                this.UserId = UserId
                this.FlyzApplyId = FlyzApplyId
            },
            sfclmcImg : function (e) {
                this.sfclmc = e
            }
        },
        mounted (){
            var _this = this
            if(window.sessionStorage.adminLevel == undefined){
                // this.$router.push({name:'login'})
            }else {
                this.information = window.sessionStorage
                _this.adminflyzApplyList()
            }
        },
        computed:{
          adminId:function(){
            return window.sessionStorage.AdminId
          }
        },
    }
</script>

<style scoped>
    .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
    /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
    .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
    .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
    .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
    .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
    .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
    .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
    .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
    .details p{color: #000000}
    .details span{color: #5c6b77}
</style>
