<style scoped>
	.header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
	.menu{font-size:0.24rem;padding:0.3rem 0.3rem;}
	.list{padding:0.2rem 0;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<Modal
			v-model="detialPanel.state"
			title="申请详情"
			ok-text="关闭"
			cancel-text
		>
			<template v-if="detialPanel.data.Content">
				<div>姓名：{{detialPanel.data.Content.applyName}}</div>
				<div>性别：{{detialPanel.data.Content.sex}}</div>
				<div>身份证号码：{{detialPanel.data.Content.idCard}}</div>
				<div>民族：{{detialPanel.data.Content.nation}}</div>
				<div>出生日期：{{detialPanel.data.Content.birthday}}</div>
				<div>年龄：{{detialPanel.data.Content.age}}</div>
				<div>住所地址：{{detialPanel.data.Content.address}}</div>
				<div>联系电话：{{detialPanel.data.Content.phone}}</div>
			</template>
      <template v-if="detialPanel.data.replyMsg">
        <Divider dashed style="margin:0.3rem 0;"/>
        <div>平台回复：{{detialPanel.data.replyMsg}}</div>
      </template>
		</Modal>
		<div class='header'></div>
		<div class='body'>
			<div class="menu">
				<Row>
					<Col span="12" style="font-size:0.3rem;font-weight: bold;"><Icon type="ios-list" style="font-size:0.5rem;"/>申请列表</Col>
				</Row>
			</div>
			<ul v-if="list.length>0">
				<li v-for="(item,index) in list" :style="index%2==0?'background-color: #fff;':''" class='list'>
					<Row type="flex" align="middle" style="font-size:0.2rem;padding:0.1rem 0.3rem;">
						<Col span="20">
							<Row>
								<Col span="24" style="font-size: 0.28rem;font-weight: bold;padding-bottom:0.1rem">{{item.Content.applyType}} | 调解员：{{item.Content.ren}} </Col>
								<Col span="24" style="color:#666666">
									申请人：{{item.Content.applyName}} | 申请时间：{{item.CreateTime}}
								</Col>
							</Row>
						</Col>
						<Col span="4" style="color:#008fd6"><span @click="getDetail(item)">查看详情 ></span></Col>
					</Row>
				</li>
			</ul>
      <div v-else style="background-image:url(/img/legalAid/empty.png);height:1.95rem;width:6.0rem;background-size:100% 100%;margin:0.3rem auto 0;">
        <p style="color: #ababab;text-align: center;padding-top: 1.2rem;font-size: 0.30rem;">未申请</p>
      </div>
			<div  v-if="pid == 1731" style="padding: 30px;font-size: 12px;line-height: 20px;">
				<div style="text-align: center;font-size: 16px;font-weight: bold;margin: 10px;">人民调解</div>
				<div style="text-indent: 2em;">人民调解是指人民调解委员会通过说服、疏导等方法，促使当事人在平等协商基础上自愿达成调解协议，解决民间纠纷的活动。人民调解委员会调解的民间纠纷包括：发生在公民与公民之间，公民与法人之间和其他社会组织之间涉及民事权利义务争议的各种纠纷。目前，我县共建立367个调解组织，其中乡镇调解委员会12个，村（社区）调委会290个企事业单位调委会51个，行业性专业性调委会14个。</div>
			</div>
      <div v-else  style="padding: 0.2rem 0.3rem;font-size: 0.22rem;line-height: 0.36rem;">
        <div style="text-align: center;font-size: 16px;font-weight: bold;margin: 10px;">人民调解在线办理指南</div>
        <div>一、纠纷种类</div>
        <div>（一）选取纠纷种类</div>
        <div>（二）填写纠纷概要</div>
        <div>二、填写基本资料</div>
        <div>（一）上传身份证件照片，通过OCR识别系统自动填充信息</div>
        <div>（二）输入您的联系电话</div>
        <div>三、机构所在地</div>
        <div>（一）选择所在机构所在地</div>
      </div>
      <!--      去申请-->
      <div style="height: 0.8rem"></div>
      <Row>
        <router-link :to="{name:'mediateStep1'}"><Col span="24" style="text-align: center;background-color:#008fd6;height: 0.8rem;font-size: 0.26rem;line-height: 0.8rem;position: fixed;bottom: 0;width: 100%;">
          <span style="color: #FFF;">线上办理</span>
        </Col></router-link>
      </Row>
		</div>
	</div>
</template>

<script>
	export default{
		name:'mediateListH',
		data(){
			return{
				list:[],
				detialPanel:{
					state:false,
					data:{},
					result:{},
				},
				pid : 0
			}
		},
		mounted(){
			var _this = this
			if(window.sessionStorage.userId == undefined){
				if( _this.$route.query.id){
					window.sessionStorage.userId = _this.$route.query.id
					_this.getList()
					window.sessionStorage.AreaId = _this.$route.query.AreaId
					window.sessionStorage.pid = _this.$route.query.pid
          window.sessionStorage.CityId = _this.$route.query.CityId
					_this.pid =window.sessionStorage.pid
				}else{
					_this.$Modal.error({
						title:'系统通知',
						content:'未获取到用户信息！请重新进入！',
					})
				}
			}else{
			  _this.getList()
				if( _this.$route.query.id){
					window.sessionStorage.userId = _this.$route.query.id
					window.sessionStorage.AreaId = _this.$route.query.AreaId
					window.sessionStorage.pid = _this.$route.query.pid
					window.sessionStorage.CityId = _this.$route.query.CityId
					_this.pid =window.sessionStorage.pid
				}
      }
			_this.setPageSize()
		},
		methods:{
			getList:function(){
				var _this = this
				_this.send({
          consulting: true,
					url:'/mediateList',
					data:{
						UserId:window.sessionStorage.userId,
					},
					success:function(res){
						if(res){
              _this.list = res.List
            }
					}
				})
			},
			getDetail:function(item){
				var _this = this
				_this.detialPanel.state = true
				_this.detialPanel.data = item
				return
				_this.send({
					url:'/mediate_resAdmin',
					data:{
						UserId :window.sessionStorage.userId,
						MediateId :item.MediateId,
					},
					success:function(res){
						_this.detialPanel.state = true
						_this.detialPanel.data = item
						_this.detialPanel.result = res.data
					}
				})
			},
			setPageSize:function() {
			    var wsw = document.body.clientWidth
			    document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
			},
		},
	}
</script>
