import nullPage from '@/views/nullPage'

const cd={
    arbitration:require('@/views/mobile/customization/cheng-du/arbitration').default,
    chargeFast:require('@/views/mobile/customization/cheng-du/chargeFast').default,
}

const routes = [
    {
        path:'cheng-du',
        component:nullPage,
        children:[
            {
                path:'cd',
                component:nullPage,
                children:[
                    {path:'arbitration',name:'cd',component:cd.arbitration,meta:{title:'商事仲裁'}},
                    {path:'chargeFast',name:'chargeFast',component:cd.chargeFast,meta:{title:'收费速算'}},
                ]
            },
        ]
    },
]

export default {
    routes:routes
}