import nullPage from '@/views/nullPage'

const mediate={
  introduce:require('@/views/mobile/customization/jian-yang/mediate/introduce').default,
  userClient:{
    list:require('@/views/mobile/customization/jian-yang/mediate/user-client/list').default,
    apply:{
      step1:require('@/views/mobile/customization/jian-yang/mediate/user-client/apply/step1').default,
      step2:require('@/views/mobile/customization/jian-yang/mediate/user-client/apply/step2').default,
      step3:require('@/views/mobile/customization/jian-yang/mediate/user-client/apply/step3').default,
    }
  },
  mediatorClient:{
    login:require('@/views/mobile/customization/jian-yang/mediate/mediator-client/login').default,
    list:require('@/views/mobile/customization/jian-yang/mediate/mediator-client/list').default,
    detial:require('@/views/mobile/customization/jian-yang/mediate/mediator-client/detial').default,
    revise:require('@/views/mobile/customization/jian-yang/mediate/mediator-client/revise').default,
  }
}
const correction={
  introduce:require('@/views/mobile/customization/jian-yang/correction/introduce').default,
  list:require('@/views/mobile/customization/jian-yang/correction/list').default,
  detial:require('@/views/mobile/customization/jian-yang/correction/detial').default,
  exam:require('@/views/mobile/customization/jian-yang/correction/exam').default,
  history:require('@/views/mobile/customization/jian-yang/correction/history').default,
}
const legalAid={
  introduce:require('@/views/mobile/customization/jian-yang/legal-aid/introduce').default,
}
const vr = {
  list:require('@/views/mobile/customization/jian-yang/vr/list').default,
  viewer:require('@/views/mobile/customization/jian-yang/vr/viewer').default,
}

const notarization={
  introduce:require('@/views/mobile/customization/jian-yang/notarization/introduce').default,
  guide:{
    1:require('@/views/mobile/customization/jian-yang/notarization/guide/1').default,
    2:require('@/views/mobile/customization/jian-yang/notarization/guide/2').default,
    3:require('@/views/mobile/customization/jian-yang/notarization/guide/3').default,
    4:require('@/views/mobile/customization/jian-yang/notarization/guide/4').default,
    5:require('@/views/mobile/customization/jian-yang/notarization/guide/5').default,
    6:require('@/views/mobile/customization/jian-yang/notarization/guide/6').default,
    7:require('@/views/mobile/customization/jian-yang/notarization/guide/7').default,
    8:require('@/views/mobile/customization/jian-yang/notarization/guide/8').default,
    9:require('@/views/mobile/customization/jian-yang/notarization/guide/9').default,
    10:require('@/views/mobile/customization/jian-yang/notarization/guide/10').default,
    49:require('@/views/mobile/customization/jian-yang/notarization/guide/49').default,
    51:require('@/views/mobile/customization/jian-yang/notarization/guide/51').default,
  }
}

const routes = [
  {
    path:'jian-yang',
    component:nullPage,
    children:[
      {
        path:'mediate',
        component:nullPage,
        children:[
          {path:'introduce',name:'CJYMI',component:mediate.introduce,meta:{title:'人民调解 — 百米服务圈'}},
          {
            path:'user-client',
            component:nullPage,
            children:[
              {path:'list',name:'CJYUML',component:mediate.userClient.list,meta:{title:'百米服务圈 - 我申请的'}},
              {
                path:'apply',
                component:nullPage,
                children:[
                  {path:'step1',name:'CJYMUAS1',component:mediate.userClient.apply.step1,meta:{title:'人民调解 - 选择调解员'}},
                  {path:'step2',name:'CJYMUAS2',component:mediate.userClient.apply.step2,meta:{title:'人民调解 - 基本信息录入'}},
                  {path:'step3',name:'CJYMUAS3',component:mediate.userClient.apply.step3,meta:{title:'人民调解 - 事项描述'}},
                ],
              },
            ],
          },
          {
            path:'mediator-client',
            component:nullPage,
            children:[
              {path:'login',name:'CJYMMLI',component:mediate.mediatorClient.login,meta:{title:'人民调解-纠纷列表'}},
              {path:'list',name:'CJYMML',component:mediate.mediatorClient.list,meta:{title:'人民调解-纠纷列表'}},
              {path:'detial',name:'CJYMMD',component:mediate.mediatorClient.detial,meta:{title:'人民调解-纠纷详情'}},
              {path:'revise',name:'CJYMMR',component:mediate.mediatorClient.revise,meta:{title:'人民调解-纠纷详情'}},
            ]
          },
        ]
      },
      {
        path:'correction',
        component:nullPage,
        children:[
          {path:'introduce',name:'CJYCI',component:correction.introduce,meta:{title:'社区矫正-介绍'}},
          {path:'list',name:'CJYCL',component:correction.list,meta:{title:'社区矫正-课程列表'}},
          {path:'detial',name:'CJYCD',component:correction.detial,meta:{title:'社区矫正-课程内容'}},
          {path:'exam',name:'CJYCE',component:correction.exam,meta:{title:'社区矫正-考试内容'}},
          {path:'history',name:'CJYCH',component:correction.history,meta:{title:'社区矫正-考试记录'}},
        ]
      },
      {
        path:'legal-aid',
        component:nullPage,
        children:[
          {path:'introduce',name:'CJYlI',component:legalAid.introduce,meta:{title:'法律援助-指南'}},
        ]
      },
      {
        path:'vr',
        component:nullPage,
        children:[
          {path:'list',name:'MCJYVL',component:vr.list,meta:{title:'VR法治阵地'}},
          {path:'viewer',name:'MCJYVV',component:vr.viewer,meta:{title:'VR法治阵地'}},
        ]
      },
      {
        path:'notarization',
        component:nullPage,
        children:[
          {path:'introduce',name:'CJYNI',component:notarization.introduce,meta:{title:'办理公证-介绍'}},
          {
            path:'guide',
            component:nullPage,
            children:[
              {path:'1',name:'CJYNG1',component:notarization.guide[1],meta:{title:'办理公证-指南'}},
              {path:'2',name:'CJYNG2',component:notarization.guide[2],meta:{title:'办理公证-指南'}},
              {path:'3',name:'CJYNG3',component:notarization.guide[3],meta:{title:'办理公证-指南'}},
              {path:'4',name:'CJYNG4',component:notarization.guide[4],meta:{title:'办理公证-指南'}},
              {path:'5',name:'CJYNG5',component:notarization.guide[5],meta:{title:'办理公证-指南'}},
              {path:'6',name:'CJYNG6',component:notarization.guide[6],meta:{title:'办理公证-指南'}},
              {path:'7',name:'CJYNG7',component:notarization.guide[7],meta:{title:'办理公证-指南'}},
              {path:'8',name:'CJYNG8',component:notarization.guide[8],meta:{title:'办理公证-指南'}},
              {path:'9',name:'CJYNG9',component:notarization.guide[9],meta:{title:'办理公证-指南'}},
              {path:'10',name:'CJYNG10',component:notarization.guide[10],meta:{title:'办理公证-指南'}},
              {path:'49',name:'CJYNG49',component:notarization.guide[49],meta:{title:'办理公证-指南'}},
              {path:'51',name:'CJYNG51',component:notarization.guide[51],meta:{title:'办理公证-指南'}},
            ]
          },
        ]
      },
    ]
  },
]

export default {
  routes:routes
}