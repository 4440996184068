<style scoped>
  .bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
  .introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
  .introduce-video-player{width:100%;}
  .mediator-list{color:#FFF;}
  .mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
  .mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
  .introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
  .introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
  .introduce-text-content{color:#999999;font-size:0.26rem;text-indent:2em;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <div style="text-align: center;width:100%;"  v-if="userInfo.AreaId == '2294'">
      <div class="introduce-video">
        <video class="introduce-video-player" preload controls src="/static/customization/jian-yang/videos/简阳市人民调解“百米服务圈”宣传动画.mp4"></video>
        <!-- autoplay  -->
      </div>
    </div>
    <div class="mediator">
      <Row style="margin:0.3rem 0;">
        <Col span="24">
          <div class="mediator-list">
            <div class="mediator-list-title">{{userInfo.Admin_name}}{{userInfo.VillagesName}}</div>
            <div class="mediator-list-content" v-for="(item,index) in mediatorList">
              <div>调解员：{{item.MediatorName}}<span style="padding:0 0.15rem"></span>联系电话：{{item.MediatorPhone}}</div>
              <div>点位：{{item.MediatorAddress}}</div>
            </div>
          </div>
        </Col>
        <!-- <Col span="6"><Button type="warning" style="margin-top:0.2rem;" :to="{name:'CJYMUAS1'}">申请调解</Button></Col>
        <Col span="6"><Button type="warning" style="margin-top:0.2rem;" :to="{name:'CJYUML'}">调解列表</Button></Col> -->
      </Row>
    </div>
    <div class="introduce-text" v-if="userInfo.AreaId == '2294'">
      <div class="introduce-text-title">人民调解“百米服务圈”工作简介</div>
      <div class="introduce-text-content">
        人民调解“百米服务圈”是简阳市持续助力市域社会治理现代化创新手段，是指在进一步完善村（社区）人民调解委员会的基础上，将人民调解组织网络延伸到自然村（组）、社区居民小组、居民聚居区、居民小区、民生类服务场所一级，通过设立矛盾纠纷受理点，配备专兼职人民调解员，实现群众百米范围内获取“矛盾纠纷化解、法规政策宣传、法律服务指引、法律诉求收集”综合法律服务工作目标。近年来，各矛盾纠纷受理点推行“综合性、全方位、立体化”工作模式，提供法律服务导引2.1万余人次，及时排查、受理、化解各类矛盾纠纷8500余件，调解成功率达98%，调解涉案金额2400余万元。纠纷发生率同比下降17%，各类一般性纠纷化解周期缩短至1天，人民调解“百米服务圈”深入渗透在社会生活的各个领域。
      </div>
    </div>
    <div class="introduce-text" v-if="userInfo.AreaId == '2294'" style="margin-top: 0.3rem">
      <div class="introduce-text-title">人民调解业务信息查询指南</div>
      <div class="introduce-text-content">
        一、人民调解相关概念</br>
        人民调解：是指人民调解委员会通过说服、疏导等方法，促使当事人在平等协商基础上自愿达成调解协议，解决民间纠纷的活动。</br>
        人民调解组织：依法设立或授权成立的调解民间纠纷的群众性组织，包括人民调解委员会及其下设的人民调解小组、人民调解工作室等形态。</br>
        人民调解员：人民调解委员会委员和人民调解委员会聘任从事人民调解工作的人员。</br>
        人民调解委员会调解民间纠纷遵循下列原则：</br>
        （一）在当事人自愿、平等的基础上进行调解；</br>
        （二）不违背法律、法规和国家政策；</br>
        （三）尊重当事人的权利，不得因调解而阻止当事人依法通过仲裁、行政、司法等途径维护自己的权利。</br>
        人民调解委员会调解民间纠纷，不收取任何费用。</br>
        当事人在人民调解活动中享有下列权利：</br>
        （一）选择或者接受人民调解员；</br>
        （二）接受调解、拒绝调解或者要求终止调解；</br>
        （三）要求调解公开进行或者不公开进行；</br>
        （四）自主表达意愿、自愿达成调解协议。</br>
        （五）委托代理人参与调解。</br>
        当事人在人民调解活动中履行下列义务：</br>
        （一）	如实陈述纠纷事实；</br>
        （二）遵守调解现场秩序，尊重人民调解员；</br>
        （三）尊重对方当事人行使权利。</br>
        （四）自觉履行调解协议。</br>
        司法确认：司法确认是对于人民调解效力确认所设立的一种制度。人民调解委员会调解达成调解协议的民事权利义务，当事人共同向人民法院申请确认其法律效力的活动。</br>
        司法确认人民调解协议书：经人民调解委员会调解达成的调解协议，具有法律约束力，当事人应当按照约定履行。对具有民事权利、义务内容的调解协议，当事人可以自调解协议生效之日起30日内共同向有管辖权的人民法院申请司法确认。人民法院依法确认调解协议内容部分或全部无效的，当事人可以通过人民调解方式变更原调解协议或者达成新的调解协议，也可以向人民法晥提起诉讼。经人民法院确认合法有效的调解协议，一方当事人拒绝履行或者未全部履行的，对方当事人可向人民法院申请强制执行。</br>
        二、人民调解委员会分布情况</br>
        我市现有乡镇（街道）人民调解委员会22个，村（社区）人民调解委员会291个；企业事业单位调委会12个，建有交通、医患、消费、物业、婚姻家庭、诉调、访调、知识产权、环境保护、劳动10个专业性、行业性人民调解委员会。</br>
      </div>
    </div>
    <div class="introduce-text" v-if="userInfo.AreaId == '2294'" style="margin-top: 0.3rem">
      <div class="introduce-text-title">简阳市专业性行业性人民调解委员会通告</div>
      <div class="introduce-text-content">
        简阳市道路交通事故纠纷、医疗纠纷等10家行业性专业性人民调解委员会已成立，现予以公告。广大市民如遇需调解的民间纠纷，可到相关的行业性专业性人民调解委员会申请调解。</br>
        1、简阳市交通事故纠纷人民调解委员会</br>
        联系人：伍炳兴 电话：15892315198</br>
        人民调解员：伍炳兴 钟政 杨雪英</br>
        调委会成立时间：2008年4月</br>
        办公地址：简阳市公安局交通警察大队1楼</br>
        调解纠纷范围： 一是受理并调解双方当事人自愿提交的财损交通事故及因交通事故中的财物损失所发生的民事赔偿纠纷;二是负责伤人事故的交通事故认定书送达后到民事赔偿前的各方接待工作;三是通过调解工作宣传法律、法规、规章和政策，教育当事人遵纪守法，做好纠纷激化的预防工作。</br>
        备注：交通事故纠纷人民调解委员会主要职责:一是依法受理并调解双方当事人申请，末经交警部门认定，显著轻微且财物损失金额在500以下并自愿赔偿的；二是双方当事人申请调解，持有交警部门交通事故认定书，财物损失较大并有财物定损金额的；三是受理和调解双方当事人申请，提供交警部门交通事故认定书且提交伤，亡人员相关信息，证明，资料，票据等有关材料的；四是依法调解成功的交通事故纠纷案件当即制发调解书并由当事人申请司法确认；五是调解不成的案件，引导当事人依法起诉，主张民事赔偿权利；六是向当事人宣传交通法规，遵章守法，预防和减少交通事故发生。</br>
        2、简阳市医患纠纷人民调解委员会</br>
        联系人：罗晓峰  电话：18181368088</br>
        人民调解员：罗晓峰  张春梅   樊正刚</br>
        调委会成立时间：2010年1月</br>
        办公地址：市人社局5楼</br>
        调解纠纷范围：受理因医疗事故或医疗过错引起的人身损害赔偿纠纷;非医疗事故或医疗过错引起的人身损害赔偿;患者与药品的生产商或经销商因服用药品引起的人身损害赔偿纠纷等。经人民调解委员会调解后，当事双方仍协商不成，进入诉讼程序，确保当事人的合法权利。</br>
        3、简阳市消费纠纷人民调解委员会</br>
        联系人：张明 电话：13778982806</br>
        人民调解员：黄长明、徐根容、杨坤、严光明、杨超</br>
        调委会成立时间：2010年9月</br>
        办公地址：市市场监督管理局5楼516室</br>
        调解纠纷范围：接待简阳市消费者在消费过程中发生矛盾纠纷的来信来访，开展有关法律、法规、规章和政策宣传、咨询工作；根据矛盾纠纷当事人的申请，依据法律、法规、规章、政策以及社会公德，通过说服教育、规劝疏导、协商等方法，调解矛盾纠纷；组织开展矛盾纠纷排查调处工作；积极向有关部门和单位反映矛盾纠纷发生和调解工作情况，提出预防矛盾纠纷发生的建议。</br>
        4、简阳市婚姻家庭纠纷人民调解委员会</br>
        联系人：李红梅  电话：18227401618</br>
        人民调解员：李红梅 张雯 李寿强</br>
        调委会成立时间：2014年4月</br>
        办公地址：市妇联3楼301室</br>
        调解纠纷范围：调解涉及妇女、儿童权益保护的赡养、抚养、继承等婚姻家庭纠纷工作；为婚姻家庭纠纷当事人提供法律咨询服务；以及其他当事人双方愿意调解的其他婚姻家庭纠纷情形。</br>
        5、简阳市诉讼与调解对接人民调解委员会</br>
        联系人：马丹  电话：028—26157733</br>
        人民调解员：冯简 李木林 袁小琴</br>
        调委会成立时间：2015年10月</br>
        办公地址：审判综合大楼2楼205-206室</br>
        调解纠纷范围：受理调处诉前调解的民事案件；调解人民法院委托或移送调解的民事案件和行政赔偿案件；接受人民法院邀请参与民事案件的调解；受理调解诉后执行和解案件。</br>
        6、简阳市物业纠纷人民调解委员会</br>
        联系人：曾志玉  电话：电话：18982929380</br>
        人民调解员：曾志玉</br>
        调委会成立时间：2017年11月</br>
        办公地址：审判综合大楼2楼207室</br>
        调解纠纷范围：受理调处在物业管理区域内涉及物业服务、物业使用和维护、物业项目服务管理交接中业主（使用人）之间、业主与业主委员会之间、业主（使用人）或业主委员会与物业服务企业之间、物业服务企业之间发生的各类适用通过人民调解方式解决的民间纠纷。</br>
        7、简阳市信访事项人民调解委员会</br>
        联系人：袁平 电话：18708250662</br>
        人民调解员：王廷刚</br>
        调委会成立时间：2018年7月</br>
        办公地址：市信访局1楼</br>
        调解纠纷范围：受理信访部门委托移送、党委政府交办、其他人民调解组织移送的以下矛盾纠纷：公民之间有关人身损害赔偿、财产权益和其他权益的纠纷；公民与法人之间或其他社会组织之间的民事纠纷；公民、法人或其他社会组织与行政机关之间发生的具有民事权利义务争议的纠纷；其他依法可以通过人民调解处理的信访事项。</br>
        8、简阳市知识产权纠纷人民调解委员会</br>
        联系人：曾智慧  电话：181 8848 8760</br>
        人民调解员：李进 樊亮 李启德</br>
        调委会成立时间：2020年4月</br>
        办公地址：市司法局5楼511室</br>
        调解纠纷范围：受理和调解公民、法人和其他组织之间发生的知识产权权益纠纷和知识产权合同纠纷；开展有关知识产权法律法规和政策的法治宣传教育工作等。</br>
        9、简阳市劳动纠纷人民调解委员会</br>
        联系人：罗翠  电话：18328346766</br>
        人民调解员：罗翠 彭跃平 邓传奎 朱家铖 程正权    李常旺 陈诗宇</br>
        调委会成立时间：2020年9月</br>
        办公地址：劳动纠纷一站式联处中心（人社局斜对面）</br>
        调解纠纷范围：（一）遵循便民、高效的工作原则，劳动人事争议各方当事人自愿平等的基础上，依据法律、法规、规章和政策进行调解，确保调解工作的公开、公平、公证；（二） 组织纠纷当事人及有关部门就劳动人事争议纠纷进行调解，及时化解矛盾，防止劳动人事争议纠纷激化；（三） 对劳动人事争议纠纷各方当事人达成的调解协议的，督促其履行调解协议；（四） 为劳动人事争议各方当事人提供法律咨询；（五） 定期和及时向主管部门汇报劳动人事争议纠纷的调解工作情况。</br>
        10、简阳市生态环境保护纠纷人民调解委员会</br>
        联系人：陈星全        电话：13882923589</br>
        人民调解员: 陶克 陈翔 陈星全</br>
        调委会成立时间：2021年12月</br>
        办公地址：市生态环境局1楼112室</br>
        调解纠纷范围：一是调解涉及发生环境争议的各种纠纷；二是指导生态环境安全预防和环境社会风险防范工作；三是依法接受司法机关或其它部门委托调解的环境保护纠纷案件；四是通过调解工作宣传环保法律、法规、规章和政策，教育公民爱护环境，保护环境，预防环境纠纷发生。</br>
      </div>
    </div>
    <div style="padding:0.5rem;"></div>
    <Row style="text-align: center;position: fixed;bottom: 0;width: 100%;background-color: #008fd6;height: 1rem;" v-if="userInfo.AreaId == '2294'">
      <Col span="12"><Button type="warning" :to="{name:'CJYUML'}" style="margin-top: 0.23rem">调解列表</Button></Col>
      <Col span="12"><Button type="error" :to="{name:'CJYMUAS1'}" style="margin-top: 0.23rem">申请调解 >>></Button></Col>
    </Row>
  </div>
</template>

<script>
export default {
  name:'introduce',
  data() {
    return {
      mediatorList:[],
    };
 },
  mounted() {
    var _this = this
		var tempTime = setInterval(()=>{
			if(_this.$store.state.userInfo.VillagesId){
				_this.getmediatorList()
				clearInterval(tempTime)
			}
			console.log(1)
		},200)
  },
  methods: {
    getmediatorList:function(){
      var _this = this
      _this.send({
        url:'/VillagesMediatorList',
        data:{
          VillagesId:_this.$store.state.userInfo.VillagesId,
          AdminId:_this.$store.state.userInfo.AdminId,
        },
        success:function(res){
          _this.mediatorList = res
        }
      })
    }
  },
  computed:{
    userInfo:function(){
      return this.$store.state.userInfo
    },
  },
}
</script>
