<style scoped>
    .headPortrait{width: 1.2rem;height: 1.2rem;vertical-align: top;margin: 0.3rem 0.22rem 0.3rem 0.3rem}
    .ziyuan{width: 0.18rem;height: 0.2rem;vertical-align: top;margin: 0 0.02rem}
    .makeaCall{width: 1.46rem;height: 0.54rem;background: #1186E1;border-radius: 0.27rem;font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;vertical-align: top;color: #FFFFFF;display: inline-block;text-align: center;line-height: 0.54rem;margin-top: 0.2rem}
    .jianjie{width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.4rem 0.13rem 0 0.39rem}
    .introduction{width: 6.9rem;background: #fff;border-radius: 0.04rem;font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.42rem;display: inline-block}
    .charge{width: 6.9rem;height: 0.88rem;background-color: #FFF;border-bottom: 0.01rem dashed #D9D9D9;
    line-height: 0.88rem;
        font-size: 0.24rem;display: inline-block;text-align: left}
    .head-title{font-size: .36rem;color: #333;line-height: .88rem;display: inline-block;vertical-align: top;}
    .return{width: .16rem;height: .3rem;display: inline-block;vertical-align: top;float: left;margin-top: .29rem;margin-left: .3rem;}
    .RelatedFile{font-size: 0.26rem;font-family: PingFang SC;font-weight: bold;color: #333333;margin-left:0.3rem;line-height: 0.93rem;text-align: left}
    .leavingaMessage{font-size: 0.26rem;border-bottom: 0.01rem solid #ccc;padding: 0 0.3rem;}

</style>

<template>
    <div style="background: #F5F5F5;">
        <div style="background-color: #F5F5F5; height: 0.88rem; text-align: center;position: fixed;top: 0;width: 100%;right: 0;z-index: 9;">
            <img src="/img/zhqy/return.png" class="return" @click="$router.go(-1)">
            <p class="head-title">律师信息</p>
        </div>
        <div style="padding: 0.44rem"></div>
        <div style="text-align: center">
            <div style="width: 6.9rem;background: #FFFFFF;box-shadow: 0 0.04rem 0.06rem 0rem rgba(0, 0, 0, 0.1);border-radius: 0.04rem;display: inline-block;text-align: left">
                <img v-if="lawyerData.lawyerImage" class="headPortrait" :src="lawyerData.lawyerImage"/>
              <img v-else src="/img/zhqy/mrtx.png" class="headPortrait">
                <div style="font-family: PingFang SC;font-weight: bold;display: inline-block;text-align: left;line-height: 0.36rem;">
                    <div style="font-size: 0.36rem;color: #333333;line-height: 0.36rem;margin-top: 0.3rem">{{lawyerData.lawyerName}}
                        <span style="height: 0.36rem;background: #F0F5FF;font-size: 0.24rem;color: #597EF7;padding: 0.07rem 0.08rem 0.06rem 0.07rem" v-if="lawyerData.lawyerYear">从业{{lawyerData.lawyerYear}}年</span>
                    </div>
                    <div style="font-size: 0.22rem;color: #999;line-height: 0.22rem;margin: 0.22rem 0 0.2rem">
                        <img src="/img/zhqy/ziyuan.png" class="ziyuan">
                        <div style="display: inline-block">{{lawyerPhone}}</div>
                    </div>
                    <div style="font-size: 0.22rem;color: #999;line-height: 0.22rem">
                        <img src="/img/zhqy/top_suoshujigou.png" class="ziyuan">
                        <div style="display: inline-block;overflow: hidden;text-overflow: ellipsis;width: 3.28rem;white-space: nowrap;">{{lawyerData.lawOfficeName}}</div>
                    </div>
                </div>
                <div style="display: inline-block;width: 1.46rem;vertical-align: top;">
                    <!--<img style="width: 0.48rem;height: 0.48rem;" src="/img/zhqy/comments.png">-->
                    <div class="makeaCall" @click="comments">留言</div>
                    <div class="makeaCall" @click="phone">拨打电话</div>
                </div>

                <div style="font-size: 0;margin: 0 0.3rem">
                    <div v-for="(valeu,index) in lawyerData.skillName" style="background: #FFF2E8;display: inline-block;color: #FF8745;font-size: 0.24rem;padding: 0.07rem 0.1rem 0.06rem;margin:0 0.2rem 0.2rem 0">
                        {{valeu}}
                    </div>
                </div>
            </div>
        </div>
        <!---->
        <div style="text-align: center">
            <div style="font-size: 0;line-height: 1.1rem;text-align: left">
                <img src="/img/zhqy/charge.png" class="jianjie">
                <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #333;">服务收费</div>
            </div>
            <div style="font-size: 0">
                <div class="charge" v-for="(value,index) in lawyerData.lawyerCost">
                    <div style="color: #333;margin-left: 0.3rem" class="inline-block">{{value.title}}</div>
                    <div style="color: #999;float: right;margin-right: 0.3rem" class="inline-block"><span style="color: #D81919" v-if="value.money">{{value.money}}元</span><span v-if="value.content">/{{value.content}}</span></div>
                </div>
            </div>
        </div>
        <!---->
        <div style="text-align: center">
            <div style="font-size: 0;line-height: 1.1rem;text-align: left">
                <img src="/img/zhqy/jianjie.png" class="jianjie">
                <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #333;">律师简介</div>
            </div>
            <div class="introduction">
                <div style="margin: 0.3rem 0.37rem 0.29rem 0.28rem;text-align: left">
                    {{lawyerData.lawyerIntro}}
                </div>
            </div>
        </div>
        <!---->
        <div style="text-align: center">
            <div style="font-size: 0;line-height: 1.1rem;text-align: left">
                <img src="/img/zhqy/tupian.png" class="jianjie">
                <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #333;">律所图片</div>
            </div>
            <div style="background: #FFF;width: 6.9rem;display: inline-block;font-size: 0;margin-bottom: 0.3rem;">
                <div style="text-align: left;margin: 0.29rem 0 ">
                    <img v-for="(value,index) in lawyerData.lawyerPhoto" :src="value.path" style="width:1.9rem;height: 1.42rem;margin-left: 0.3rem"/>
                </div>
            </div>
        </div>

        <div style="width: 7.5rem;background-color: #FFF;display: inline-block;text-align: left;margin-top: 0.3rem;border-radius: 0.04rem;" v-if="lawyerData.msg">
            <div class="RelatedFile">留言列表</div>
            <div class="leavingaMessage" v-for="(value,index) in lawyerData.msg">
                <div style="margin: 0.2rem 0">
                    <!--<div>名称：{{value.UserName||value.LawyerName}}</div>-->
                    <div>内容：<span v-if="value.msg">{{value.msg}}</span></div>
                    <div>留言时间：{{value.time}}</div>
                </div>
            </div>
        </div>
        <!---->

        <div style="text-align: center;">
            <div style="font-size: 0;line-height: 1.1rem;text-align: left">
                <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #333;margin-left: 0.3rem">相关案例</div>
            </div>
            <div v-for="(value,index) in lawyerData.lawyerCase" style="width: 6.9rem;background: #FFFFFF;border-radius: 0.04rem;font-family: PingFang SC;font-weight: 500;padding: 0.28rem 0.3rem 0.3rem;display: inline-block;text-align: left;margin-bottom: 0.3rem">
                <div style="font-size: 0.28rem;color: #333;line-height: 0.28rem;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;margin-bottom: 0.19rem;">
                    {{value.title}}
                </div>
                <div style="font-size: 0.22rem;color: #999;line-height: 0.35rem;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
                    {{value.content}}
                </div>
            </div>
        </div>
        <!---->
        <Modal
                v-model="modal1"
                title="留言"
                @on-ok="sendOut" ok-text="发送" cancel-text="关闭">

                <textarea style="color: rgb(34, 34, 34);right: 0px;text-align: left;resize: none;width: 100%;height: 2rem;" placeholder="请输入您留言，字数不超过300字" v-model="msg">

                </textarea>
        </Modal>
    </div>
</template>

<script>
    export default {
        name: "findaLawFirm",
        data() {
            return {
                lawyerData : [],
                lawyerPhone : '',
                msg : '',
                modal1: false
            }
        },
        methods :{
            sendOut : function(){
                var _this = this
                _this.send({
                    url: '/Addmsg',
                    data: {
                        lawyerId : _this.lawyerData.lawyerId || '',
                        msg : _this.msg
                    },
                    success: function (res) {
                        _this.$Message.success('发送成功');
                        _this.lawyerLoginOrById()
                    }
                })
                // if(window.sessionStorage.lawyerId){
                //
                // }else {
                //     this.$Message.warning('请登录后进行留言');
                // }
            },
            comments : function(){
                var _this = this
                _this.modal1 = true
                // if(!window.sessionStorage.lawyerId){
                //     this.$Message.warning('请登录后进行留言');
                // }
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
            lawyerLoginOrById : function () {
                var _this = this
                _this.send({
                    url: '/LawyerLoginOrById',
                    data: {
                        lawyerId : _this.$route.query.lawyerId
                    },
                    success: function (res) {
                        if(res.lawyerPhone){
                            var reg = /^(\d{3})\d{4}(\d{4})$/;
                            _this.lawyerPhone = res.lawyerPhone.replace(reg, "$1****$2")
                        }
                        _this.lawyerData = res
                    }
                })
            },
          phone : function (){
            window.location.href = 'tel://' + this.lawyerData.lawyerPhone;
          },
        },
        mounted (){
            var _this = this
            _this.setPageSize()
            _this.lawyerLoginOrById()
        }
    }
</script>

