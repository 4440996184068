import axios from 'axios'
import merge from 'deepmerge'

var config = SYSCONFIG
export default {
    install(Vue) {
        //请求发送封装
        Vue.prototype.send = function (_option) {
            var _this = this
            var version = ''
            var _url = ''
            var _default = {}
            if (_option.server == undefined) {
                _option.server = 'default'
            }
            if (_option.version != undefined) {
                version = _option.version
            } else {
                version = config.server[_option.server].version
            }
            if (config.server[_option.server].ssl) {
                _url += 'https://'
            } else {
                _url += 'http://'
            }
            _url += config.server[_option.server].host + ':' + config.server[_option.server].port
            _url += config.server[_option.server].src + version
                _default = {
                    url: _url,
                    method: 'post',
                    confirm: false,
                    loading: false,
                    headers: {},
                    responseType: '',
                    params: config.defaultData,
                    data: config.defaultData,
                    success: function (res) {
                        return true
                    },
                    before: function () {
                        if (option.confirm != false) {
                            _this.$Modal.confirm({
                                title: '系统消息',
                                cancelText: '取消',
                                content: '是否确认当前操作？',
                                onOk: function () {
                                    send()
                                },
                                onCancel: function () {}
                            })
                        } else {
                            send()
                        }
                    },
                    error: function (error) {
                        _this.$Notice.error({
                            title: '系统提示',
                            desc: '数据载入中！请稍后再试'
                        })
                        console.log(error)
                    },
                    warning: function (warning) {
                        return true
                    },
                    callback: function (warning) {
                        return true
                    }
                }
            if (_option.url != undefined) {
                _option.url = _default.url + _option.url
            } else {
                _option.url = _default.url
            }
            var option = merge(_default, _option)
            if (option.method.toUpperCase() == 'GET') {
                option.params = option.data
            }
            option.before()
            function send() {
                var loading
                if (option.loading) {
                    loading = setTimeout(function () {
                        _this.$Spin.show()
                    }, 500)
                }
                axios({
                    method: option.method,
                    headers: option.headers,
                    responseType: option.responseType,
                    url: option.url,
                    data: option.data,
                    params: option.params
                })
                    .then(function (response) {
                        clearTimeout(loading)
                        if (option.loading) {
                            _this.$Spin.hide()
                        }
                        if (_option.consulting1 == true) {
                            option.success(response.data)
                            return
                        }
                        if (_option.consulting == true) {
                            option.success(response.data.Data)
                            return
                        }
                        if (response.data.Success == 1) {
                            option.success(response.data.Data)
                        } else {
                            if (response.data.Message == '登录失效，请重新登录') {
                                _this.$Modal.warning({
                                    title: '系统提示',
                                    content: '<p>登录超时，请重新登录</p>',
                                    okText: '去登录',
                                    onOk: function () {
                                        if(window.sessionStorage.AccountTypeId == '2'){
                                            _this.$router.push({ name: 'loginMbr' })
                                            return
                                        }if(window.sessionStorage.AccountTypeId == '3'){
                                            _this.$router.push({ name: 'loginGzy' })
                                            return
                                        }else {
                                            _this.$router.push({ name: 'managerLogin' })
                                            return
                                        }

                                    }
                                })
                            } else {
                                _this.$Notice.info({
                                    title: '系统提示',
                                    desc: response.data.Message
                                })
                            }
                            option.warning(response.data)
                        }
                        option.callback(response.data)
                    })
                    .catch(function (error) {
                        clearTimeout(loading)
                        _this.$Spin.hide()
                        option.error(error)
                        if (config.errorLog.state) {
                            //记录错误日志
                            var errorTemp = error
                            axios({
                                method: 'post',
                                url:
                                    config.errorLog.server.url +
                                    ':' +
                                    config.errorLog.server.port +
                                    '/' +
                                    config.errorLog.server.src,
                                data: errorTemp
                            })
                        }
                    })
            }
        },
        //判断非空
        Vue.prototype.isEmpty = function (obj) {
            return typeof obj === 'undefined' || obj === null || obj === ''
        }
    }
}
