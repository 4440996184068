<style scoped>
  .bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
  .introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
  .introduce-video-player{width:100%;}
  .mediator-list{color:#FFF;}
  .mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
  .mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
  .introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
  .introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
  .introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <div style="text-align: center;width:100%;"  v-if="userInfo.AreaId == '2294'">
      <div class="introduce-video">
        <video class="introduce-video-player" preload controls src="https://cj.lvwa18.com/static/customization/jian-yang/videos/f1040b1fba40fd17ad704cb0c0984f2b.mp4"></video>
        <!-- autoplay  -->
      </div>
    </div>
    <div class="introduce-text">
      <div class="introduce-text-title">简阳市公证处 — 简介</div>
      <div class="introduce-text-content">
        简阳市公证处成立于1983年，是简阳市唯一一家公证机构，曾获“四川群众喜爱的十佳公证处”称号，现有公证员4人，公证员助理2人，公证辅助人员7人。</br>
        地址：简阳市人民路10号（简阳市政务服务中心一楼）。乘坐3路、5路、6路、13路、16路、17路、18路公交车可直达。</br>
        咨询电话：028-27028736</br>
        地址：简阳市政务服务中心一楼</br>
        电话：028-27028736</br>
        微信号：19150104390</br>

      </div>
      <div style="padding:0.15rem;"></div>
      <div class="introduce-text-title">办理公证指南</div>
      <div class="introduce-text-content">
        售房委托公证<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG6'}">点击查看 > > ></router-link></br>
        <div style="padding:0.05rem;"></div>
        继承公证<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG3'}">点击查看 > > ></router-link></br>
        <div style="padding:0.05rem;"></div>
        遗嘱公证<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG8'}">点击查看 > > ></router-link></br>
        <div style="padding:0.05rem;"></div>
        离婚补充协议公证<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG49'}">点击查看 > > ></router-link></br>
        <div style="padding:0.05rem;"></div>
        预约合同公证<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG9'}">点击查看 > > ></router-link></br>
        <div style="padding:0.05rem;"></div>
        公示书证证据保全<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG2'}">点击查看 > > ></router-link></br>
        <div style="padding:0.05rem;"></div>
        企业赋强公证<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG4'}">点击查看 > > ></router-link></br>
        <div style="padding:0.05rem;"></div>
        分房现场监督公证<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG1'}">点击查看 > > ></router-link></br>
        <div style="padding:0.05rem;"></div>
        亲属关系公证<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG5'}">点击查看 > > ></router-link></br>
        <div style="padding:0.05rem;"></div>
        预约合同公证<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG9'}">点击查看 > > ></router-link></br>
        <div style="padding:0.05rem;"></div>
        其他公证<router-link style="margin-left:0.2rem;color:blue;" :to="{name:'CJYNG51'}">点击查看 > > ></router-link></br>
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
    <Row style="text-align: center;">
      <Col span="24"><a href="https://scjys.egongzheng.com/H5/templates/index.html"><Button type="warning" style="width:4rem;">去 办 理</Button></a></Col>
    </Row>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'introduce',
  data() {
    return {
      mediatorList:[]
    };
 },
  mounted() {
  },
  methods: {
  },
  computed:{
    userInfo:function(){
      return this.$store.state.userInfo
    },
  },
}
</script>
