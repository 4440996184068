import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/hub'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    legalAidData: {
      formValidate: {
        zxsxlxzx: '',
        zxr: '',
        xb: '',
        zjhm: '',
        mz: '',
        csrq: '',
        nl: '',
        dz: '',
        lxdh: '',
        bsqr: '未填写',
        bsqrzjhm: '未填写',
        bsqrdz: '未填写',
        zxnr: '',
        orgCity: '',
        orgArea: '',
        sfclmc: '',
      },
      ruleValidate: {
        zxsxlxzx: [{ required: true, trigger: 'change', message: '请选择申请法律援助事项类别' }],
        zxr: [{ required: true, trigger: 'blur', message: ' ' }],
        xb: [{ required: true, trigger: 'change', message: '请选择性别' }],
        zjhm: [{ required: true, trigger: 'blur', message: ' ' }],
        mz: [{ required: true, trigger: 'change', message: ' ' }],
        csrq: [{ required: true, trigger: 'blur', message: ' ' }],
        nl: [{ required: true, trigger: 'blur', message: ' ' }],
        dz: [{ required: true, trigger: 'blur', message: ' ' }],
        lxdh: [{ required: true, trigger: 'blur', message: ' ' }],
        bsqr: [{ required: true, trigger: 'blur', message: ' ' }],
        bsqrzjhm: [{ required: true, trigger: 'blur', message: ' ' }],
        bsqrdz: [{ required: true, trigger: 'blur', message: ' ' }],
        zxnr: [{ required: true, trigger: 'blur', message: ' ' }],
        orgCity: [{ required: true, trigger: 'change', message: ' ' }],
        orgArea: [{ required: true, trigger: 'change', message: ' ' }],
        sfclmc: [{ required: true, trigger: 'change', message: '请上传身份证' }],
      },
    },
    mediateData: {
      formValidate: {
        applyName: '',
        sex: '',
        idCard: '',
        nation: '',
        birthday: '',
        age: '',
        address: '',
        phone: '',
        respondent: '未填写',
        respondentIdCard: '110101199001012096',
        respondentAddress: '未填写',
        respondentPhone: '10000000000',
        applyType: '',
        proposerExcuse: '',
        orgCity: '',
        orgArea: '',
        orgZhen: '',
        hui: '',
        ren: '',
      },
      ruleValidate: {
        applyName: [{ required: true, trigger: 'blur', message: ' ' }],
        sex: [{ required: true, trigger: 'change', message: '请选择性别' }],
        idCard: [{ required: true, trigger: 'blur', message: ' ' }],
        nation: [{ required: true, trigger: 'change', message: ' ' }],
        birthday: [{ required: true, trigger: 'blur', message: ' ' }],
        age: [{ required: true, trigger: 'blur', message: ' ' }],
        address: [{ required: true, trigger: 'blur', message: ' ' }],
        phone: [{ required: true, trigger: 'blur', message: ' ' }],
        respondent: [{ required: true, trigger: 'blur', message: ' ' }],
        respondentIdCard: [{ required: true, trigger: 'blur', message: ' ' }],
        respondentAddress: [{ required: true, trigger: 'blur', message: ' ' }],
        respondentPhone: [{ required: true, trigger: 'blur', message: ' ' }],
        applyType: [{ required: true, trigger: 'change', message: '选择纠纷类型' }],
        proposerExcuse: [{ required: true, trigger: 'change', message: ' ' }],
        orgCity: [{ required: true, trigger: 'change', message: ' ' }],
        orgArea: [{ required: true, trigger: 'change', message: ' ' }],
        orgZhen: [{ required: true, trigger: 'change', message: ' ' }],
        hui: [{ required: true, trigger: 'change', message: ' ' }],
        ren: [{ required: true, trigger: 'change', message: ' ' }],
      },
    },
    mobile: {
      userInfo:{},
      customization: {
        jianYang: {
          mediate: {
            apply: {
              formValidate: {
                MediatorId: '',
                ProposerName: '',
                ProposerSex: '',
                ProposerIDCard: '',
                ProposerNation: '',
                ProposerBirthdate: '',
                ProposerAge: '',
                ProposerAddress: '',
                ProposerPhone: '',
                ProposerIDCardPath: '',
                UserSummarize: '',
                respondent: '未填写',
                respondentIdCard: '110101199001012096',
                respondentAddress: '未填写',
                respondentPhone: '10000000000',
                MediationType: '',
                proposerExcuse: '',
              },
              ruleValidate: {
                // MediatorId : [{ required: true,trigger: 'blur' ,message:' '}],
                // applyName : [{ required: true,trigger: 'blur' ,message:' '}],
                // sex : [{ required: true,trigger: 'change' ,message:'请选择性别'}],
                // idCard : [{ required: true,trigger: 'blur' ,message:' '}],
                // nation : [{ required: true,trigger: 'change' ,message:' '}],
                // birthday : [{ required: true,trigger: 'blur' ,message:' '}],
                // age : [{ required: true,trigger: 'blur' ,message:' '}],
                // address : [{ required: true,trigger: 'blur' ,message:' '}],
                // phone : [{ required: true,trigger: 'blur' ,message:' '}],
                // respondent : [{ required: true,trigger: 'blur' ,message:' '}],
                // respondentIdCard : [{ required: true,trigger: 'blur' ,message:' '}],
                // respondentAddress : [{ required: true,trigger: 'blur' ,message:' '}],
                // respondentPhone : [{ required: true,trigger: 'blur' ,message:' '}],
                // applyType : [{ required: true,trigger: 'change' ,message:'选择纠纷类型'}],
                // proposerExcuse : [{ required: true,trigger: 'change' ,message:' '}],
              },
            }
          }
        }
      },
      open:{
        qingbaijiang:{
          userInfo:{},
        }
      }
    },
    userInfo: {},
    userInfoTF: {
      nickName: '',
      userId: '',
      name: '',
      img: '',
    },
    mediatorInfo: {},
    socketData:{
      status: 'closed',
      ws:{},
    },
    socketFun: {
      state:{},
      openSocket(state) {
        var _this = this
        _this.state = state
        // 打开信道
        _this.state.socketData.ws = new WebSocket("wss://socket.lvwa18.com")
        // Web Socket 已连接上，使用 send() 方法发送数据
        _this.state.socketData.ws.onopen = function(){
          _this.state.socketData.status = 'connected';
          _this.sendMessage(null)
        }
        // 监听服务器推送的消息
        _this.state.socketData.ws.onmessage = function (message) { 
          message = JSON.parse(message.data)
          _this.socketMessage(message)
        }
        //断开时的动作
        _this.state.socketData.ws.onclose = function(){ 
          console.log('WebSocket 已断开')
          _this.state.socketData.status = 'closed'
          _this.retrySocket()
        };
      },
      retrySocket() {
        setTimeout(() => {
          this.openSocket()
        }, 1000)
      },
      //关闭信道
      closeSocket() {
        if (this.socketData.socketStatus === 'connected') {
          wx.closeSocket({
            success: () => {
              this.socketData.socketStatus = 'closed'
            }
          })
        }
      },
      socketMessage(message) {
        let _this = this
        _this[message.type](message)
      },
      //发送消息函数
      sendMessage(data) {
        var _this = this
        if (_this.state.socketData.status === 'connected') {
          var sendData = null
          if (data) {
            sendData = JSON.stringify({ client: 'cj', id: _this.state.userInfo.UserId, receiveId: data.receiveId, content: data.content, conversationId: data.conversationId, type: data.type, version: '1.0.0' })
          } else {
            sendData = JSON.stringify({ client: 'cj', id: _this.state.userInfo.UserId, register: 1 })
          }
          _this.state.socketData.ws.send(sendData)
        } else {
          _this.openSocket()
        }
      },
      connect:function(message){
        console.log(message)
      },
      message: (message) => {
        var routeSrc = router.name
        console.log(routeSrc)
        return
        if (message.type == 'message' && routeSrc != 'pages/userSide/dialogue/dialogue') {
          var desc
          if (message.data.content.messageType == 'text') {
            desc = message.data.content.content
          }
          if (message.data.content.messageType == 'image') {
            desc = '[图片]'
          }
          if (message.data.content.messageType == 'file') {
            desc = '[文件]'
          }
          if (message.data.content.messageType == 'voiced') {
            desc = '[语音]'
          }
          // Notify({
          //   type: 'danger',
          //   message: `${message.data.content.nickName}:${desc} 查看`,
          //   duration: 5000,
          //   onClick: function () {
          //     wx.navigateTo({
          //       url: `/pages/userSide/dialogue/dialogue?contactId=${message.data.conversationId}` //跳转
          //     })
          //   }
          // });
        } else {
          return message
        }
      },
      articel: (message) => {
        if (message.type == 'articel') {
          // Notify({
          //   type: 'warning',
          //   message: `最新资讯:${message.data.title} 查看`,
          //   duration: 5000,
          //   onClick: function () {
          //     // console.log(message.data.url)
          //     wx.navigateTo({
          //       url: `/pages/h5View/h5View?url=${encodeURIComponent(message.data.url)}` //跳转
          //     })
          //   }
          // });
        }
      },
      videoCall: (message) => {
        if (message.type == 'videoCall' && message.data.content.type == 'call') {
          // wx.navigateTo({
          //   url: `/pages/callingPage/callingPage?message=${JSON.stringify(message)}` //跳转
          // })
        } else {
          return message
        }
      },
      voiceCall: (message) => {
        if (message.type == 'voiceCall' && message.data.content.type == 'call') {
          // wx.navigateTo({
          //   url: `/pages/callingPage-voice/callingPage?message=${JSON.stringify(message)}` //跳转
          // })
        } else {
          return message
        }
      },
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
