<template>
    <div>
        <Tabs :value="tabsType" @on-click="switchClick" v-if="cq != ''">
            <TabPane label="村(居)服务管理" name="1"></TabPane>
<!--            <TabPane label="社区公证员管理" name="2"></TabPane>-->
<!--            <TabPane label="法律明白人管理" name="3"></TabPane>-->
        </Tabs>
        <div class="publicWelfare" v-if="tabsType == 1">
            <Row style="margin: 20px">
                <Col span="12">
                    <Input v-model="searchData" placeholder="搜索" style="width: 200px" />
                    <Button type="primary" @click="searchDara(1)">搜索社区</Button>
                    <Button type="primary" @click="searchDara(2)" style="margin-left: 30px">搜索顾问</Button>
                </Col>
                <Col span="12">
                    <!--              <Button type="primary" @click="communityShow = true" style="margin-left: 30px">添加社区</Button>-->
                </Col>
            </Row>
            <table class="formtable">
                <thead>
                    <tr>
                        <th>序号</th>
                        <th>村（社区）</th>
                        <th>姓名</th>
                        <th>服务机构</th>
                        <th>创建时间</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, index) in CommunityData">
                        <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
                        <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
                        <td>
                            {{ value.CityName }}{{ value.adminname }}{{ value.VillagesName }}
                            <!--            <samp style="padding: 10px;"></samp>用户数量：（{{value.UserCount}}）-->
                        </td>
                        <td nowrap="nowrap">

                            <div

                                v-for="(item, i) in value.relation"
                                style="text-align: center; display: inline-block; margin: 0 5px"
                            ><div  class="del-hover" @click="value.relation.length>1?changeLawyer(value.relation,item,value.VillagesId):addLawyer(value.VillagesId, '更换顾问', 'change')">
                              <div class="del-btn">{{value.relation.length>1?'删除':'更换'}}</div>
                                <Avatar :src="item.lawyer.LawyerImg" />
                            </div>
                                <div style="margin-top: 4px">{{ item.lawyer.LawyerName }}</div>
                            </div>


                        </td>
                        <td>
                            <div v-for="(item, i) in value.relation">
                              {{ item.lawyer.LawyerName }}：{{ item.lawyer.organization.OrganizationName }}
                            </div>
                        </td>
                        <td>{{ value.CreatTime }}</td>
                        <td style="text-align: center">
                            <Button type="primary" @click="addLawyer(value.VillagesId, '新增顾问', '')"
                                >新增顾问</Button
                            >
                            <Button
                                type="primary"
                                change
                                @click="addLawyer(value.VillagesId, '更换顾问', 'change')"
                                style="margin-left: 30px"
                                >更换顾问</Button
                            >
                            <!--                <Button type="error" @click="addLawyer(value.VillagesId,'删除社区','shanchu')" style="margin-left: 30px">删除社区</Button>-->
                            <!--                <Button type="primary" @click="modifyCommunity(value)" style="margin-left: 30px">修改社区</Button>-->
                        </td>
                    </tr>
                </tbody>
            </table>
            <Page
                :current="page"
                :total="count"
                show-total
                :page-size="pageSize"
                show-elevator
                @on-change="
                    page = $event
                    adminVillagesList()
                "
            />
        </div>
        <Modal v-model="modal" title="添加" @on-ok="ok" @on-cancel="cancel">
            <Input v-model="lawyerName" placeholder="顾问名称" style="margin-bottom: 30px" />
            <Input v-model="lawyerPhone" placeholder="顾问电话" style="margin-bottom: 30px" />
            <Select
                v-model="OrganizationId"
                filterable
                clearable
                @on-query-change="lawFirmsearch"
                @on-open-change="lawFirmdropDown"
                class="choiceLawyer"
                placeholder="请选择律所"
            >
                <Option v-for="item in FirmPageData" :value="item.OrganizationId" :key="item.OrganizationId">{{
                    item.AdminName
                }}</Option>
                <Page
                    :total="FirmPageCount"
                    show-total
                    :page-size="pageSize"
                    :current="lawFirmPage"
                    @on-change="lawFirmTurnPages"
                ></Page>
            </Select>
        </Modal>
        <!--      更换顾问-->
        <Modal
            :draggable="true"
            v-model="replaceShow"
            title="修改顾问信息"
            @on-ok="modifyLawyer"
            @on-cancel="cancel"
            width="530"
        >
            <Input v-model="lawyerName" placeholder="顾问名称" style="margin-bottom: 30px" />
            <Input v-model="lawyerPhone" placeholder="顾问电话" style="margin-bottom: 30px" />
            <Select
                v-model="OrganizationId"
                filterable
                clearable
                @on-query-change="lawFirmsearch"
                @on-open-change="lawFirmdropDown"
                class="choiceLawyer"
                placeholder="请选择律所"
            >
                <Option v-for="item in FirmPageData" :value="item.OrganizationId" :key="item.OrganizationId">{{
                    item.AdminName
                }}</Option>
                <Page
                    :total="FirmPageCount"
                    show-total
                    :page-size="pageSize"
                    :current="lawFirmPage"
                    @on-change="lawFirmTurnPages"
                ></Page>
            </Select>
        </Modal>
        <!--    村社区管理-->
        <!-- <Modal
            v-model="modifycommunityShow"
            title="修改社区"
            @on-ok="onmodifyCommunity"
            @on-cancel="cancel"
            class="placeholderColor"
        >
            <Form :model="communitySingleData" :label-width="120">
                <FormItem label="服务社区（村）">
                    <Input v-model="communitySingleData.VillagesName" placeholder="服务社区（村）"></Input>
                </FormItem>
                <FormItem label="选择所属区域">
                    <Cascader
                        :data="addressData"
                        v-model="communitySingleData.familyaddress"
                        placeholder="请选择所属区域"
                        @on-change="addressSelect"
                    ></Cascader>
                </FormItem>
                <FormItem label="选择管理层">
                    <Cascader
                        :data="administrationData"
                        change-on-select
                        v-model="communitySingleData.AdminId"
                        :placeholder="communitySingleData._AdminName"
                        @on-change="communityAdministration"
                    ></Cascader>
                </FormItem>
            </Form>
        </Modal> -->
        <!--     更换顾问 - 新增顾问 -->
        <Modal :draggable="true" v-model="modal1" :title="title" @on-ok="determine" @on-cancel="cancel" width="530">
            <Select
                v-model="model1"
                filterable
                multiple
                @on-query-change="onSearch"
                @on-change="onSelect"
                @on-open-change.capture="onCapture"
                class="choiceLawyer"
                placeholder="请输入顾问名称或电话"
            >
                <Option v-for="item in lawyerList" :value="item.LawyerId" :key="item.LawyerId"
                    >{{ item.LawyerName }}{{ item.LawyerPhone }}{{ item.OrganizationName }}</Option
                >
                <Page
                    :total="totalName"
                    show-total
                    :page-size="pageSize"
                    :current="currentPageName"
                    @on-change="handlePageName"
                ></Page>
            </Select>
        </Modal>
        <!--     公证员更换公证员 - 新增公证员 -->
        <Modal
            :draggable="true"
            v-model="greffierVillagesData.modal"
            :title="title"
            @on-ok="determineGzy"
            @on-cancel="cancel"
            width="530"
        >
            <Select
                v-model="greffierVillagesData.model"
                filterable
                multiple
                @on-query-change="searchGzy"
                class="choiceLawyer"
                placeholder="请输入社区公证员名称或电话"
            >
                <Option v-for="item in greffierVillagesData.GzyList" :value="item.GreffierId" :key="item.GreffierId"
                    >{{ item.GreffierName }}{{ item.GreffierPhone }}</Option
                >
                <Page
                    :total="greffierVillagesData.totalName"
                    show-total
                    :page-size="pageSize"
                    :current="greffierVillagesData.currentPageName"
                    @on-change="handlePageNameGzy"
                ></Page>
            </Select>
        </Modal>
        <!--    明白人-->
        <Modal
            :draggable="true"
            v-model="insiderVillagesData.modal"
            :title="title"
            @on-ok="determineMbr"
            @on-cancel="cancel"
            width="530"
        >
            <Select
                v-model="insiderVillagesData.model"
                filterable
                multiple
                @on-query-change="searchMbr"
                class="choiceLawyer"
                placeholder="请输入法律明白人名称或电话"
            >
                <Option v-for="item in insiderVillagesData.GzyList" :value="item.InsiderId" :key="item.InsiderId"
                    >{{ item.InsiderName }}{{ item.InsiderPhone }}</Option
                >
                <Page
                    :total="insiderVillagesData.totalName"
                    show-total
                    :page-size="pageSize"
                    :current="insiderVillagesData.currentPageName"
                    @on-change="handlePageNameMbr"
                ></Page>
            </Select>
        </Modal>

        <!-- <Modal
            :draggable="true"
            v-model="communityShow"
            title="添加社区"
            @on-ok="onCommunity"
            @on-cancel="cancel"
            width="530"
        >
            <div style="margin-bottom: 30px">
                <Select
                    v-model="model1"
                    filterable
                    multiple
                    @on-query-change="onSearch"
                    class="choiceLawyer"
                    placeholder="请选择顾问"
                >
                    <Option v-for="item in lawyerList" :value="item.LawyerId" :key="item.LawyerId"
                        >{{ item.LawyerName }}{{ item.LawyerPhone }}{{ item.OrganizationName }}</Option
                    >
                    <Page
                        :total="totalName"
                        show-total
                        :page-size="pageSize"
                        :current="currentPageName"
                        @on-change="handlePageName"
                    ></Page>
                </Select>
            </div>
            <Input v-model="community.VillagesName" placeholder="服务社区（村）" style="margin-bottom: 30px" />
            <Cascader
                :data="addressData"
                v-model="familyaddress"
                placeholder="请选择所属区域"
                @on-change="addressSelect"
                style="margin-bottom: 30px"
            ></Cascader>
            <Cascader
                :data="administrationData"
                v-model="community.AdminId"
                placeholder="请选择管理层"
                @on-change="onAdministration"
            ></Cascader>
        </Modal> -->
        <!--添加律所-->
        <!-- <Modal
            :draggable="true"
            v-model="lawFirmShow"
            title="添加律所"
            @on-ok="addlawFirm"
            @on-cancel="cancel"
            width="530"
        >
            <Input v-model="addlawFirmData.OrganizationName" placeholder="律所名称" style="margin-bottom: 30px" />
            <Input v-model="addlawFirmData.AdminLogin" placeholder="登录名" style="margin-bottom: 30px" />
            <Input v-model="addlawFirmData.AdminPassword" placeholder="登录密码" style="margin-bottom: 30px" />
            <Input v-model="addlawFirmData.OrganizationLinkman" placeholder="联系人" style="margin-bottom: 30px" />
            <Input v-model="addlawFirmData.OrganizationPhone" placeholder="联系人电话" style="margin-bottom: 30px" />
            <Cascader
                :data="administrationData"
                v-model="addlawFirmData.AdminId"
                placeholder="请选择管理层"
                change-on-select
                @on-change="onAdministration"
                style="margin-bottom: 30px"
            ></Cascader>
            <Cascader
                :data="addressData"
                v-model="addlawFirmData.AreaId"
                placeholder="请选择所属区域"
                @on-change="addressSelect"
                style="margin-bottom: 30px"
            ></Cascader>
        </Modal> -->

        <Modal v-model="modal4" title="添加" @on-ok="lawyerOk" @on-cancel="cancel">
            <div class="single">
                <p class="contacts">律师名称</p>
                <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName" />
            </div>
            <div class="single">
                <p class="contacts">律师电话</p>
                <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone" />
            </div>
        </Modal>
    </div>
</template>

<script>
export default {
    name: 'lawyerManagement',

    data() {
        return {
            key: '',
            lawyerList: [],
            currentPageName: 1,
            pageSize: 15,
            page: 1,
            count: 0,
            totalName: 0,
            modal: false,
            replaceShow: false,
            replaceData: [],
            FirmPageData: [],
            FirmPageCount: 0,
            lawFirmPage: 1,
            searchFirm: '',
            lawyerPhone: '',
            lawyerName: '',
            OrganizationId: '',
            //  村社区管理
            modifycommunityShow: false,
            // Modal标题
            title: '',
            communitySingleData: {},
            administrationData: [],
            searchData: '',
            CommunityData: [],
            modal2: false,
            addressData: [],
            communityShow: false,

            model1: [],
            community: {
                VillagesName: '',
                LawyerId: '',
                AdminId: [],
                ProvinceId: '',
                CityId: '',
                AreaId: '',
                CityName: ''
            },
            familyaddress: [],
            modal1: false,
            //  添加律所
            organizationSearch: '',
            lawFirmData: [],
            lawFirmCount: 0,
            lawFirmShow: false,
            value1: '',
            addData: {},
            listData: [],
            LawyerLpage1: 1,
            addlawFirmData: [],
            modal4: false,
            modal3: false,
            tabsType: '1',
            cq: '',
            greffierVillagesData: {
                data: [],
                page: 1,
                count: 0,
                pageSize: 15,
                Key: '',
                modal: false,
                searchKey: '',
                currentPageName: 1,
                totalName: 0,
                GzyList: [],
                searchData: '',
                model: [],
                GreffierName: '',
                search: ''
            },
            insiderVillagesData: {
                data: [],
                page: 1,
                count: 0,
                pageSize: 15,
                Key: '',
                modal: false,
                searchKey: '',
                currentPageName: 1,
                totalName: 0,
                GzyList: [],
                searchData: '',
                model: [],
                InsiderName: '',
                search: ''
            }
        }
    },
    methods: {
        communityAdministration() {
            console.log('这个是communityAdministration')
        },

        serachLawyer: function (value) {
            var _this = this
            // console.log(value)
            if (value != undefined) {
                _this.currentPageName = 1
                _this.totalName = 0
            }
            _this.send({
                url: '/SerachLawyerByKey',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    key: _this.key,
                    page: _this.currentPageName,
                    pageSize: _this.pageSize
                },
                success: function (res) {
                    _this.lawyerList = res.list
                    _this.totalName = res.count
                }
            })
        },
        replaceLawyer: function (e) {
            this.replaceShow = true
            this.lawyerName = e.LawyerName
            this.replaceData = e
            this.lawyerPhone = e.LawyerPhone
            this.FirmPageData = [e]
            this.OrganizationId = e.OrganizationId
        },
        //删除顾问
        removeLawyer: function (lawyerId) {
            var _this = this
            _this.send({
                url: '/RemoveLawyer',
                confirm: true,
                data: {
                    lawyerId: lawyerId
                },
                success: function (res) {
                    // console.log(res)
                    _this.$Message.success('操作成功')
                    _this.serachLawyer()
                }
            })
        },
        lawFirmsearch: function (e) {
            this.searchFirm = e
            this.lawFirmPage = 1
            this.FirmPageCount = 0
            // this.FirmPageData = []
            this.serachOrganization()
        },
        serachOrganization: function () {
            var _this = this
            _this.send({
                url: '/AdminOrganizationList',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    key: _this.searchFirm,
                    page: _this.lawFirmPage,
                    pageSize: _this.pageSize
                },
                success: function (res) {
                    _this.FirmPageData = res.list
                    _this.FirmPageCount = res.count
                }
            })
        },
        lawFirmdropDown: function (e) {
            if (e) {
                this.searchFirm = ''
                this.OrganizationId = ''
                this.serachOrganization()
            }
            // console.log(this.OrganizationId)
        },
        lawFirmTurnPages: function (e) {
            // console.log(e)
            this.lawFirmPage = e
            this.serachOrganization()
        },
        modifyLawyer: function () {
            var _this = this
            _this.send({
                url: '/EditLawyerInfo',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    lawyerId: _this.replaceData.LawyerId,
                    LawyerName: _this.lawyerName,
                    LawyerPhone: _this.lawyerPhone,
                    OrganizationId: _this.OrganizationId
                },
                success: function (res) {
                    _this.$Message.success('操作成功')
                    _this.serachLawyer()
                }
            })
        },
        //关闭弹出
        cancel: function () {
            var _this = this
            _this.OrganizationId = ''
            _this.lawyerName = ''
            _this.lawyerPhone = ''
            // console.log(_this.OrganizationId)
        },
        ok: function () {
            var _this = this
            _this.send({
                url: '/InsertLawyerInfo',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    OrganizationId: _this.OrganizationId,
                    LawyerName: _this.lawyerName,
                    LawyerPhone: _this.lawyerPhone
                },
                success: function (res) {
                    _this.lawyerList = []
                    _this.page = 1
                    _this.OrganizationId = ''
                    _this.lawyerName = ''
                    _this.lawyerPhone = ''
                    _this.serachLawyer()
                }
            })
        },
      changeLawyer(lawyerArr,item,villageId){
          var _this = this
          let arr = []
        lawyerArr.map(lawyerArritem=>{
          if(lawyerArritem.LawyerId==item.LawyerId){
            return
          }else{
            arr.push(lawyerArritem.LawyerId)
          }
        })
        console.log(arr)
        _this.send({
          url: '/changeLawyerSer',
          confirm:true,
          data: {
            AdminId: window.sessionStorage.AdminId,
            lawyerArrId: arr.toString(),
            VillagesId:villageId,
            type: 'change'
          },
          success: function (res) {
            _this.$Message.success('操作成功')
            _this.adminVillagesList()
          }
        })
      },
        //  村社区管理
        addLawyer: function (e, title, type) {
            var _this = this
            if (type == 'shanchu') {
                _this.send({
                    url: '/RemoveVillages',
                    confirm: true,
                    data: {
                        VillagesId: e
                    },
                    success: function (res) {
                        // console.log(res)
                        _this.$Message.success('操作成功')
                        _this.CommunityData = []
                        _this.adminVillagesList()
                    }
                })
            } else {
                _this.VillagesId = e
                _this.title = title
                _this.modal1 = true
                _this.type = type
            }
        },
        onmodifyCommunity: function () {
            var _this = this
            if (this.communitySingleData.AdminId[this.communitySingleData.AdminId.length - 1] == undefined) {
                var AdminId = this.communityAdminId
            } else {
                var AdminId = this.communitySingleData.AdminId[this.communitySingleData.AdminId.length - 1]
            }
            _this.send({
                url: '/EditVillages',
                data: {
                    AdminId: AdminId,
                    VillagesId: _this.communitySingleData.VillagesId,
                    VillagesName: _this.communitySingleData.VillagesName,
                    CityName: _this.community.CityName,
                    ProvinceId: _this.communitySingleData.familyaddress[0],
                    CityId: _this.communitySingleData.familyaddress[1],
                    AreaId: _this.communitySingleData.familyaddress[2]
                },
                success: function (res) {
                    _this.$Message.success('操作成功')
                    _this.model1 = []
                    _this.adminVillagesList()
                }
            })
            // console.log(AdminId)
        },
        searchDara: function (type) {
            var _this = this
            if (type == 1) {
                _this.search = _this.searchData
                _this.LawyerName = ''
            } else {
                _this.search = ''
                _this.LawyerName = _this.searchData
            }
            _this.adminVillagesData = []
            _this.CommunityData = []
            _this.count = ''
            _this.page = '1'
            _this.adminVillagesList()
        },
        adminTreeList: function () {
            var _this = this
            var data = []
            _this.send({
                url: '/AdminTreeList',
                data: {
                    AdminId: window.sessionStorage.AdminId
                },
                success: function (res) {
                    for (var i in res.list) {
                        data.push(res.list[i])
                    }
                    convertTree(data)
                    function convertTree(tree) {
                        const result = []
                        tree.forEach((item) => {
                            // 解构赋值
                            let { AdminId: value, AdminName: label, list: children } = item
                            if (children) {
                                children = convertTree(children)
                            }
                            result.push({
                                value,
                                label,
                                children
                            })
                        })
                        _this.administrationData = result
                        return result
                    }
                }
            })
        },
        adminVillagesList: function () {
            var _this = this
            _this.send({
                url: '/VillageListByPage',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    typeId: '4',
                    page: _this.page,
                    pageSize: _this.pageSize,
                    key: _this.search,
                    LawyerName: _this.LawyerName,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime,
                    sort: _this.sort
                },
                success: function (res) {
                    _this.CommunityData = res.data
                    _this.count = res.total
                }
            })
        },
        modifyCommunity: function (value) {
            var _this = this
            // console.log(value)
            _this.communitySingleData = value
            _this.communitySingleData.familyaddress = [value.ProvinceId, value.CityId, value.AreaId]
            _this.communityAdminId = value.AdminId
            _this.communitySingleData.AdminId = value.AdminId
            _this.modifycommunityShow = true
        },
        determine: function () {
            var _this = this
            if (_this.type == 'genghuan') {
                _this.send({
                    url: '/EditLawyerSer',
                    data: {
                        lawyerArrId: _this.model1.toString(),
                        VillagesId: _this.VillagesId
                    },
                    success: function (res) {
                        _this.$Message.success('操作成功')
                        _this.model1 = []
                        _this.serachLawyer()
                    }
                })
            } else {
                _this.send({
                    url: '/changeLawyerSer',
                    data: {
                        AdminId: window.sessionStorage.AdminId,
                        lawyerArrId: _this.model1.toString(),
                        VillagesId: _this.VillagesId,
                        type: _this.type
                    },
                    success: function (res) {
                        _this.$Message.success('操作成功')
                        _this.model1 = []
                        _this.adminVillagesList()
                    }
                })
            }
        },
        onSearch: function (e) {
            this.key = e
            this.currentPageName = 1
            this.totalName = 0
            this.lawyerList = []
            this.serachLawyer()
        },
        onCapture(value) {
            console.log(value)
        },
        onSelect(value) {
            console.log(value)
        },
        AreaList: function () {
            var _this = this
            var data = []
            _this.send({
                url: '/AreaList',
                success: function (res) {
                    for (var i in res) {
                        data.push(res[i])
                    }
                    convertTree(data)
                    function convertTree(tree) {
                        const result = []
                        tree.forEach((item) => {
                            // 解构赋值
                            let { AreaId: value, AreaName: label, AreaCode: AreaCode, list: children } = item
                            if (children) {
                                children = convertTree(children)
                            }
                            result.push({
                                value,
                                label,
                                AreaCode,
                                children
                            })
                        })
                        _this.addressData = result
                        return result
                    }
                }
            })
        },
        onCommunity: function () {
            var _this = this
            this.community.LawyerId = _this.model1.toString()
            this.community.AdminId = this.community.AdminId[this.community.AdminId.length - 1]
            // this.community.AdminId = window.sessionStorage.AdminId
            // console.log(this.community)
            _this.send({
                url: '/AddVillages',
                data: _this.community,
                success: function (res) {
                    _this.$Message.success('操作成功')
                    _this.model1 = []
                    _this.VillagesName = ''
                    _this.community.familyaddress = ''
                    _this.page = 1
                    _this.adminVillagesList()
                }
            })
        },
        handlePageName: function (e) {
            // console.log(e)
            this.currentPageName = e
            this.serachLawyer()
        },
        addressSelect: function (value, selectedData) {
            console.log(value, selectedData)
            if (value[1] == 2262 || value[1] == 2237) {
                this.community.ProvinceId = value[0]
                this.community.CityId = value[1]
                this.community.AreaId = value[2] || ''
                this.community.CityName = selectedData[0].label + selectedData[2].label
            } else {
                this.community.ProvinceId = value[0]
                this.community.CityId = value[1]
                this.community.AreaId = value[2] || ''
                if (selectedData.length == 3) {
                    this.community.CityName = selectedData[0].label + selectedData[1].label + selectedData[2].label
                } else {
                    this.community.CityName = selectedData[0].label + selectedData[1].label
                }
            }
            // console.log(this.community)
        },
        onAdministration: function (value, selectedData) {
            console.log(value[value.length - 1])
        },
        // 添加律所
        adminOrganizationList: function (value) {
            var _this = this
            if (value != undefined) {
                _this.organizationPage = 1
                _this.lawFirmCount = 0
            }
            _this.send({
                url: '/AdminOrganizationList',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    key: _this.organizationSearch,
                    page: _this.organizationPage,
                    pageSize: _this.pageSize
                },
                success: function (res) {
                    _this.lawFirmData = res.list
                    _this.lawFirmCount = res.count
                }
            })
        },
        lawList: function () {
            console.log(this.lawFirmData[this.value1[0]].OrganizationId)
            var _this = this
            _this.send({
                consulting: true,
                url: '/LawyerListById',
                data: {
                    OrganizationId: _this.lawFirmData[this.value1[0]].OrganizationId,
                    page: _this.LawyerLpage1,
                    pageSize: 5
                },
                success: function (res) {
                    if (res) {
                        _this.listData = res.List
                        _this.count = res.count
                    } else {
                        _this.listData = []
                        _this.count = 0
                    }
                }
            })
        },
        addlawFirm: function () {
            var _this = this
            // console.log(_this.addlawFirmData.AdminId)
            _this.send({
                url: '/AddMember',
                data: {
                    AdminId: _this.addlawFirmData.AdminId[_this.addlawFirmData.AdminId.length - 1],
                    AdminLogin: _this.addlawFirmData.AdminLogin,
                    AdminPassword: _this.addlawFirmData.AdminPassword,
                    AreaId: _this.addlawFirmData.AreaId[_this.addlawFirmData.AreaId.length - 1], //（区域id）,
                    Level: 3, // （固定）,
                    OrganizationLinkman: _this.addlawFirmData.OrganizationLinkman,
                    OrganizationName: _this.addlawFirmData.OrganizationName,
                    OrganizationPhone: _this.addlawFirmData.OrganizationPhone
                },
                success: function (res) {
                    _this.addlawFirmData = []
                    _this.$Message.success('操作成功')
                    _this.adminOrganizationList()
                }
            })
        },
        lawyerOk: function () {
            var _this = this
            var op = this.lawFirmData[this.value1[0]].OrganizationId + 'p' + _this.lawyerPhone + 'p' + 1
            if (_this.lawyerName != '' && _this.lawyerPhone != '') {
                _this.send({
                    url: '/AddLawyerInfo',
                    data: {
                        OrganizationId: this.lawFirmData[this.value1[0]].OrganizationId,
                        scene: op,
                        userType: '2',
                        page: 'pages/login/login',
                        width: '430',
                        jpg: 'jpg',
                        LawyerName: _this.lawyerName,
                        LawyerPhone: _this.lawyerPhone,
                        LawyerId: _this.lawyerId
                    },
                    success: function (res) {
                        _this.listData = []
                        _this.lawyerName = ''
                        _this.lawyerPhone = ''
                        _this.LawyerLpage1 = 1
                        _this.lawyerId = ''
                        _this.lawList()
                    }
                })
            } else {
                _this.$Notice.warning({
                    title: '系统提示',
                    desc: '名称或电话不能为空'
                })
            }
        },
        switchClick: function (e) {
            var _this = this
            _this.tabsType = e
            if (e == '1') {
            }
            if (e == '2') {
                _this.greffierVillagesList()
                _this.serachGzy()
            }
            if (e == '3') {
                _this.insiderVillagesList()
                _this.serachMbr()
            }
        },
        addMbr: function (e, title, type) {
            var _this = this
            if (type == 'shanchu') {
                _this.send({
                    url: '/RemoveVillages',
                    confirm: true,
                    data: {
                        VillagesId: e
                    },
                    success: function (res) {
                        // console.log(res)
                        _this.$Message.success('操作成功')
                        _this.CommunityData = []
                        _this.adminVillagesList()
                    }
                })
            } else {
                _this.VillagesId = e
                _this.title = title
                _this.insiderVillagesData.modal = true
                _this.type = type
            }
        },
        serachMbr: function (value) {
            var _this = this
            // console.log(value)
            if (value != undefined) {
                _this.currentPageName = 1
                _this.totalName = 0
            }
            _this.send({
                url: '/SerachInsiderByKey',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    key: _this.insiderVillagesData.searchKey,
                    page: _this.insiderVillagesData.currentPageName,
                    pageSize: _this.pageSize
                },
                success: function (res) {
                    _this.insiderVillagesData.GzyList = res.list
                    _this.insiderVillagesData.totalName = res.count
                }
            })
        },
        searchMbr: function (e) {
            this.insiderVillagesData.searchKey = e
            this.insiderVillagesData.currentPageName = 1
            this.insiderVillagesData.totalName = 0
            this.insiderVillagesData.GzyList = []
            this.serachMbr()
        },

        handlePageNameMbr: function (e) {
            // console.log(e)
            this.insiderVillagesData.currentPageName = e
            this.serachMbr()
        },

        determineMbr: function () {
            var _this = this
            if (_this.type == 'genghuan') {
                _this.send({
                    url: '/EditInsiderSer',
                    data: {
                        InsiderArrId: _this.insiderVillagesData.model.toString(),
                        VillagesId: _this.VillagesId
                    },
                    success: function (res) {
                        _this.$Message.success('操作成功')
                        _this.insiderVillagesData.model = []
                        _this.insiderVillagesList()
                    }
                })
            } else {
                _this.send({
                    url: '/ChangeInsiderSer',
                    data: {
                        AdminId: window.sessionStorage.AdminId,
                        InsiderArrId: _this.insiderVillagesData.model.toString(),
                        VillagesId: _this.VillagesId,
                        type: _this.type
                    },
                    success: function (res) {
                        _this.$Message.success('操作成功')
                        _this.insiderVillagesData.model = []
                        _this.insiderVillagesList()
                    }
                })
            }
        },
        MbrDara: function (type) {
            var _this = this
            if (type == 1) {
                _this.insiderVillagesData.search = _this.insiderVillagesData.searchData
                _this.insiderVillagesData.InsiderName = ''
            } else {
                _this.insiderVillagesData.search = ''
                _this.insiderVillagesData.InsiderName = _this.insiderVillagesData.searchData
            }
            _this.insiderVillagesData.CommunityData = []
            _this.insiderVillagesData.count = ''
            _this.insiderVillagesData.page = '1'
            _this.insiderVillagesList()
        },
        //公证员
        greffierVillagesList: function () {
            var _this = this
            _this.send({
                url: '/GreffierVillagesList',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    typeId: '4',
                    page: _this.greffierVillagesData.page,
                    pageSize: _this.greffierVillagesData.pageSize,
                    key: _this.greffierVillagesData.search,
                    GreffierName: _this.greffierVillagesData.GreffierName,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime,
                    sort: _this.sort
                },
                success: function (res) {
                    _this.greffierVillagesData.data = res.List
                    _this.greffierVillagesData.count = res.Count
                }
            })
        },
        addGzy: function (e, title, type) {
            var _this = this
            if (type == 'shanchu') {
                _this.send({
                    url: '/RemoveVillages',
                    confirm: true,
                    data: {
                        VillagesId: e
                    },
                    success: function (res) {
                        // console.log(res)
                        _this.$Message.success('操作成功')
                        _this.CommunityData = []
                        _this.adminVillagesList()
                    }
                })
            } else {
                _this.VillagesId = e
                _this.title = title
                _this.greffierVillagesData.modal = true
                _this.type = type
            }
        },
        serachGzy: function (value) {
            var _this = this
            // console.log(value)
            if (value != undefined) {
                _this.currentPageName = 1
                _this.totalName = 0
            }
            _this.send({
                url: '/SerachGreffierByKey',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    key: _this.greffierVillagesData.searchKey,
                    page: _this.greffierVillagesData.currentPageName,
                    pageSize: _this.pageSize
                },
                success: function (res) {
                    _this.greffierVillagesData.GzyList = res.list
                    _this.greffierVillagesData.totalName = res.count
                }
            })
        },
        searchGzy: function (e) {
            this.greffierVillagesData.searchKey = e
            this.greffierVillagesData.currentPageName = 1
            this.greffierVillagesData.totalName = 0
            this.greffierVillagesData.GzyList = []
            this.serachGzy()
        },

        handlePageNameGzy: function (e) {
            // console.log(e)
            this.greffierVillagesData.currentPageName = e
            this.serachGzy()
        },

        determineGzy: function () {
            var _this = this
            if (_this.type == 'genghuan') {
                _this.send({
                    url: '/EditGreffierSer',
                    data: {
                        GreffierArrId: _this.greffierVillagesData.model.toString(),
                        VillagesId: _this.VillagesId
                    },
                    success: function (res) {
                        _this.$Message.success('操作成功')
                        _this.greffierVillagesData.model = []
                        _this.greffierVillagesList()
                    }
                })
            } else {
                _this.send({
                    url: '/ChangeGreffierSer',
                    data: {
                        AdminId: window.sessionStorage.AdminId,
                        GreffierArrId: _this.greffierVillagesData.model.toString(),
                        VillagesId: _this.VillagesId,
                        type: _this.type
                    },
                    success: function (res) {
                        _this.$Message.success('操作成功')
                        _this.greffierVillagesData.model = []
                        _this.greffierVillagesList()
                    }
                })
            }
        },
        GzyDara: function (type) {
            var _this = this
            if (type == 1) {
                _this.greffierVillagesData.search = _this.greffierVillagesData.searchData
                _this.greffierVillagesData.GreffierName = ''
            } else {
                _this.greffierVillagesData.search = ''
                _this.greffierVillagesData.GreffierName = _this.greffierVillagesData.searchData
            }
            _this.greffierVillagesData.CommunityData = []
            _this.greffierVillagesData.count = ''
            _this.greffierVillagesData.page = '1'
            _this.greffierVillagesList()
        }
    },
    mounted() {
        var _this = this
        //  村社区管理
        _this.adminVillagesList()
        _this.adminTreeList()
        _this.AreaList()
        //
        _this.serachLawyer()
        _this.serachOrganization()
        //添加律所
        // _this.adminOrganizationList()
        if (SYSCONFIG.location == 'chong-qing') {
            _this.cq = 'cq'
        }
    }
}
</script>

<style scoped>
.forMcontent {
    width: calc(100% - 80px);
    height: 60px;
    color: #666666;
    font-size: 16px;
    margin: 0px 40px 0 40px;
    border-bottom: 1px solid #dddddd;
    text-align: center;
}
.forMcontent p {
    line-height: 60px;
    display: inline-block;
    width: 50%;
}
.forMtitle1 p {
    display: inline-block;
    color: #333333;
    font-size: 16px;
    line-height: 60px;
    overflow: hidden;
    white-space: nowrap;
    width: 20%;
}
.forMtitle {
    width: calc(100% - 80px);
    background-color: #f9f9f9;
    margin: 40px 40px 0 40px;
    height: 60px;
}
.forMtitle p {
    display: inline-block;
    color: #333333;
    font-size: 16px;
    width: 50%;
    line-height: 60px;
    overflow: hidden;
    white-space: nowrap;
}
.administration {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #dddddd;
    height: 80px;
    text-align: left;
    margin-bottom: 0;
}
.administration p {
    font-size: 20px;
    color: #333333;
    display: inline-block;
    line-height: 80px;
}
.label {
    width: 6px;
    height: 24px;
    vertical-align: top;
    margin: 28px 20px 0 20px;
}
.addTo {
    width: 100px;
    height: 40px;
    border-radius: 20px;
    background-color: #00b86c;
    color: #ffffff;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    float: right;
    line-height: 40px;
    margin: 20px 40px 0 0;
    cursor: pointer;
}
.inputWord {
    color: #333333;
    font-size: 16px;
    border: 0;
    text-align: left;
    height: 66px;
    margin-left: 20px;
    width: 350px;
}
.contacts {
    font-size: 16px;
    color: #999999;
    display: inline-block;
    height: 66px;
    line-height: 66px;
}
.single {
    width: 100%;
    border-bottom: 1px solid #dddddd;
    text-align: left;
    display: inline-block;
}

.choiceLawyer :deep(.ivu-select-dropdown ){
    max-height: none;
}
.choiceLawyer :deep(.ivu-page ){
    margin-left: 30px;
}
.choiceLawyer {
    width: 380px;
}
.formtable {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}
/*表格*/
.publicWelfare table {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}
.publicWelfare table th {
    background: #f7f7f7;
    white-space: nowrap;
    color: #5c6b77;
    font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table tbody th {
    border: 1px solid #e9e9e9;
    padding: 8px 16px;
    text-align: center;
}
.publicWelfare table thead th {
    border: 1px solid #e9e9e9;
    padding: 8px 16px;
    text-align: center;
}
/*.publicWelfare{margin: 30px}*/
.placeholderColor /deep/ input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    color: #515a6e;
}
.placeholderColor /deep/ input:-moz-placeholder,
textarea:-moz-placeholder {
    color: #515a6e;
}
.placeholderColor /deep/ input::-moz-placeholder,
textarea::-moz-placeholder {
    color: #515a6e;
}
.placeholderColor /deep/ input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
    color: #515a6e;
}
.del-hover{position:relative;border-radius:48px;overflow: hidden;display:inline-block;color:#fff}
.del-hover:hover .del-btn{display:block}
.del-btn{position:absolute;top:0;bottom:0;left:0;right:0;background-color:rgba(0,0,0,0.5);line-height:30px;z-index:2;display:none;cursor:pointer}
</style>
