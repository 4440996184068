<style scoped>
.archive-title-icon{display: inline-block;width:8px;border-radius:4px;background-color: #2d8cf0;height:22px;vertical-align:middle;}
.archive-title{vertical-align:middle;font-size:16px;margin-left:10px;color:#626775;font-weight:bold;}
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>

<template>
  <div>
    <div style="padding:20px;position: relative;">
      <div style="display: flex;justify-content: space-between;align-items:center;">
        <div>
          <div class="archive-title-icon"></div>
          <span class="archive-title">事件报送（治理中心）</span>
        </div>
      </div>
      <Divider style="margin:15px 0 10px 0;"/>
<!--      筛选功能-->
      <div style="margin: 20px 0">
<!--        2023/4/17暂时删除-->
<!--        <div style="display:inline-block;margin: 0 15px">-->
<!--          <div style="display: inline-block;">企业名称：</div>-->
<!--          <div style="display: inline-block">-->
<!--            <Input v-model="filter.enterpriseName.value" placeholder="搜索企业名称" style="width: 200px"/>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="display:inline-block;margin: 0 15px">-->
<!--          <div style="display: inline-block;">服务时间：</div>-->
<!--          <div style="display: inline-block;">-->
<!--            <DatePicker-->
<!--                @on-change="filter.serviceDate.startTime = $event[0], filter.serviceDate.endTime = $event[1]"-->
<!--                type="datetimerange"-->
<!--                format="yyyy-MM-dd"  ref="submittedTime"-->
<!--                placeholder="请选择时间区间" style="width: 200px">-->
<!--            </DatePicker>-->
<!--          </div>-->
<!--        </div>-->
        <div style="display:inline-block;margin: 0 15px">
          <div style="display: inline-block">状态：</div>
          <div style="display: inline-block">
            <Select clearable v-model="filter.approvalStatus.value" placeholder="请选择审核状态" style="width: 200px">
              <Option v-for="item in filter.approvalStatus.list" :value="item.value" :label="item.name"></Option>
            </Select>
          </div>
        </div>
<!--        2023/4/17暂时删除-->
<!--        <div style="display:inline-block;margin: 0 15px">-->
<!--          <div style="display: inline-block">预警级别：</div>-->
<!--          <div style="display: inline-block">-->
<!--            <Select clearable v-model="filter.rankStatus.value" placeholder="请选择预警级别" style="width: 200px">-->
<!--              <Option v-for="item in filter.rankStatus.list" :value="item.value" :label="item.name"></Option>-->
<!--            </Select>-->
<!--          </div>-->
<!--        </div>-->
        <!--        操作（查询-重置）-->
        <div style="float: right;">
          <Button @click="warnList()" type="info">查询</Button>
          <span style="margin-left:10px;"></span>
          <Button type="text" @click="resetting" style="background-color:grey;color: #FFF">重置</Button>
        </div>
      </div>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>所属村（社区）</th>
            <th>村居法律顾问</th>
            <th>服务类型</th>
            <th>事件内容</th>
            <th>服务时间</th>
<!--            <th>处理人</th>-->
            <th>状态</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in warnData">
            <td nowrap="nowrap">{{value.villagesName}}</td>
            <td nowrap="nowrap">{{value.lawyerName}}</td>
            <td>{{value.serviceType}}</td>
            <td>{{value.content}}</td>
            <td nowrap="nowrap">{{value.serviceTime}}</td>
<!--            <td nowrap="nowrap">{{value.handler}}</td>-->
            <td nowrap="nowrap">{{value.status==1?'待处理':'已报送'}}</td>
            <td nowrap="nowrap">
              <div v-if="value.status==1" style="display: inline-block">
                <span @click="operateClisk(value.fileId,value.id,value.status,1)" style="cursor: pointer;color:#2d8cf0;">报送</span>
                <span @click="operateClisk(value.fileId,value.id,value.status,2)" style="cursor: pointer;color:#2d8cf0;margin: 0 20px">忽略</span>
              </div>
              <span @click="operateClisk(value.fileId,value.id,value.status,3)" style="cursor: pointer;color:#2d8cf0;">查看</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <Page
          :total="pageBar.total"
          :page-size="pageBar.size"
          show-elevator
          @on-change="pageBar.current = $event;warnList()"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "earlyWarning",
  data (){
    return {
      filter:{
        enterpriseName : {value:'',list:[],},//企业名称
        approvalStatus:{
          value:'',list:[
            {value:1,name: '待处理'},
            {value:2,name: '已报送'},
          ]
        },//状态
        rankStatus:{
          value:'',list:[
            {value:1,name: '低'},
            {value:2,name: '中'},
            {value:3,name: '高'},
          ]
        },//预警级别
        serviceDate:{startTime: '',endTime: '',}//报送时间
      },
      pageBar:{//分页
        total:0,
        size:15,
        sizeList:[10,15,30],
        current:1,
      },
      warnData : []
    }
  },
  mounted() {
    this.warnList()
  },
  methods: {
    // 列表接口
    warnList : function (){
      var _this = this
      _this.send({
        url: '/warnList',
        data:{
          AdminId: window.sessionStorage.AdminId,
          start_time : _this.filter.serviceDate.startTime,
          end_time : _this.filter.serviceDate.endTime,
          status : _this.filter.approvalStatus.value,
          company_name : _this.filter.enterpriseName.value,
          level : _this.filter.rankStatus.value,
          page : _this.pageBar.current,
          pageSize : _this.pageBar.size,
        },
        success: function (res) {
          _this.pageBar.total = res.total||0
          _this.warnData = res.data
        }
      })
    },
    // 重置
    resetting : function (){
      var _this = this
      // this.$refs.submittedTime.handleClear()
      _this.filter.serviceDate.startTime = ''
      _this.filter.serviceDate.endTime = ''
      _this.filter.approvalStatus.value = ''
      _this.filter.enterpriseName.value = ''
      _this.filter.rankStatus.value = ''
      _this.warnList()
    },
    operateClisk : function (fileId,id,status,type){
      var _this = this
        if(type == 1){
          _this.send({
            url: '/submitted',
            confirm: true,
            data: {
              id: id,
            },
            success: function (res) {
              if(res.data){
                _this.warnList()
                _this.$Message.success('报送成功')
              }
            }
          })
        }
        if(type == 2){
          _this.send({
            url: '/warnDelete',
            confirm: true,
            data: {
              id: id,
            },
            success: function (res) {
              _this.warnList()
              _this.$Message.success('忽略成功')
            }
          })
        }
        if(type == 3){
          window.sessionStorage.submittedType = status
          _this.$router.push({name:'earlyWarningLookover',query:{id:id,fileId:fileId,}})
        }
    },
  }
}
</script>
