<template>
    <div  class="filescontent">
        <div v-html="file"></div>
    </div>
</template>

<script>
    export default {
        name: "sfalkDetails",
        data() {
            return {
                externalArticleId : '',
                ExternalArticleTitle : '',
                file: '',//内容
            }
        },
        mounted() {
            this.externalArticleId = this.$route.query.id
            this.caseArticleById()
            var Area_name = window.sessionStorage.Area_name || ''
            var line = Area_name?'-':''
            document.title = Area_name + line + '公共法律服务平台'
        },
        methods : {
            caseArticleById : function () {
                var _this = this
                _this.send({
                    url:'/CaseArticleById',
                    data: {ExternalArticleId : this.externalArticleId},
                    success:function(res){
                        _this.file = res.ExternalArticleContent
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .filescontent{color: #000000;font-size: 0.22rem;  margin: 0rem 0.6rem 1rem;line-height: 0.60rem;    overflow: hidden;}

</style>
