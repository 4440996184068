<template>
    <div>
        <div style="background:url(https://ai-cdn.lvwa18.com/img/rusticate/newbg.png) no-repeat;background-size:100% 100%;height: 7.21rem">
            <div class="headTransparent">
                <div class="framePortrait" bindtap="myClikc">
                    <img src="/img/logoGov.png" class="userImg" />
                </div>
                <div class="hi">Hi<span >,A bear</span></div>
                <div class="verticalLine"></div>
                <!--<img src="https://ai-cdn.lvwa18.com/img/rusticate/tongzhi.png" class="tongzhi" />-->
            </div>
            <!---->
            <div style="text-align: center;font-size:0;font-weight:700;">
                <img src="https://ai-cdn.lvwa18.com/img/rusticate/sifa.png" class="sifa" />
                <div style="color:#fff;font-size: 0.48rem;">
                    <span>智慧公共法律服务</span>
                </div>
                <div class="address">
                    <span >成都</span>
                    <span>武侯区</span>
                </div>
            </div>
            <!---->
            <div class="lawyerInformation">
                <img class="lawyerImg" src="/img/logoGov.png" />
                <div class="lawIformation">
                    <div style="font-size: 0.36rem;">XXX律师
                        <div class="labelAdviser" style="background: url(https://ai-cdn.lvwa18.com/img/rusticate/tagging.png) no-repeat center bottom;background-size:100% 100%;">
                            <img src="https://ai-cdn.lvwa18.com/img/rusticate/lvshi1.png" class="lvshi1" />
                            社区法律顾问
                        </div>
                    </div>
                    <div style="font-size: 0.24rem;margin:0 0 0.08rem;">1234567891011</div>
                    <div style="font-size: 0.18rem;">
                        <span style="margin-left:0.05rem;">服务时间  9:00-18:00</span>
                        <!-- <text wx:if="{{ userInfo.ProvinceId == '2205'}}" style="margin:0 0 0 20rpx;font-size: 24rpx;" bindtap="beOnDuty">值班日历</text> -->
                    </div>
                </div>
                <img src="https://ai-cdn.lvwa18.com/img/rusticate/dianhua.png" class="dianhua" bindtap="dial" />
            </div>
            <!--咨询服务-->
            <div style="text-align: center">
                <div class="threePlates">
                    <div style="font-size:0.3rem;font-weight:600;padding-bottom:0.10rem;">
                        <img style="width:1.07rem;height: 0.11rem;" src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png"/>
                        咨询服务
                        <img style="width:1.07rem;height: 0.11rem;" src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png"/>
                    </div>
                    <div class="threePlate" @click="selfHelp()">
                        <img src="https://ai-cdn.lvwa18.com/img/rusticate/plate1.png" class="plate" />
                        <div><span>自助咨询</span></div>
                    </div>
                </div>
            </div>
            <!---->
            <div style="text-align: center">
                <div class="threePlates">
                    <div style="font-size:0.3rem;font-weight:600;padding-bottom:0.10rem;">
                        <img style="width:1.07rem;height: 0.11rem;" src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png"/>
                        咨询服务
                        <img style="width:1.07rem;height: 0.11rem;" src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png"/>
                    </div>
                    <div class="threePlate" @click="legalAidListH()">
                        <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon1.png" class="plate" />
                        <div><span>法律援助</span></div>
                    </div>
                    <div class="threePlate" @click="mediateListH()">
                        <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon2.png" class="plate" />
                        <div><span>人民调解</span></div>
                    </div>
                    <div class="threePlate" @click="legalAid()">
                        <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon7.png" class="plate" />
                        <div><span>办理公证</span></div>
                    </div>
                </div>
            </div>
            <!---->
            <div style="text-align: center">
                <div class="threePlates">
                    <div style="font-size:0.3rem;font-weight:600;padding-bottom:0.10rem;">
                        <img style="width:1.07rem;height: 0.11rem;" src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png"/>
                        查询服务
                        <img style="width:1.07rem;height: 0.11rem;" src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png"/>
                    </div>
                    <div class="threePlate" @click="marriage()">
                        <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon6.png" class="plate" />
                        <div><span>模拟判决</span></div>
                    </div>
                    <div class="threePlate" @click="download()">
                        <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon8.png" class="plate" />
                        <div><span>法律文书</span></div>
                    </div>
                    <div class="threePlate" @click="civilCode">
                        <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon5.png" class="plate" />
                        <div><span>民法典</span></div>
                    </div>
                </div>
            </div>
            <!---->
        </div>
    </div>
</template>

<script>
    export default {
        name: "plsHome",
        mounted(){
            var _this = this
            _this.setPageSize()
        },
        methods : {
            selfHelp : function(){
                window.location = "https://panda.lvwa18.com/menuSelect";
            },
            legalAidListH : function(){
                this.$router.push({name:'legalAidListH',query:{id:753487}})
            },
            mediateListH : function(){
                this.$router.push({name:'mediateListH',query:{id:753487}})
            },
            legalAid : function(){
                window.location = "https://sccd.egongzheng.com/H5/templates/index.html";
            },
            marriage : function(){
                window.location = "https://panda.lvwa18.com/documentField";
            },
            download : function(){
                window.location = "https://panda.lvwa18.com/select";
            },
            civilCode : function(){
                window.location = "https://panda.lvwa18.com/dialogue?gid=12";
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        },
    }
</script>

<style scoped>
    .headTransparent{width: 100%;height: 0.7rem;background-image: linear-gradient(rgba(0,111,214,0.8), rgba(76,151,255,0.8));}
    .userImg{width: 0.45rem;height: 0.45rem;border-radius: 0.45rem;margin-top: 0.01rem;}
    .framePortrait{width: 0.47rem;height: 0.47rem;border-radius: 0.47rem;background-color: rgba(255,255,255,0.6);display: inline-block;text-align: center;margin: 0.14rem 0.15rem 0 0.3rem;vertical-align:top;}
    .hi{color:#fff;font-size: 0.24rem;display: inline-block;line-height: 0.70rem;vertical-align:top;}
    .address{color: #ffffff;font-size: 0.36rem;font-weight: 600;margin-top: 0.20rem;}
    .sifa{width: 1.02rem;margin: 0.30rem 0;height: 1.10rem;}
    .lawyerInformation{margin:auto;width: 6.90rem;height: 1.53rem;border-radius: 0.765rem;background-image: linear-gradient(rgba(0,111,214,0.8), rgba(76,151,255,0.8));margin-top: 0.30rem;text-align: left;font-size: 0;margin-bottom: 0.30rem;}
    .lawyerImg{width: 1.06rem;height: 1.06rem;border-radius: 1.06rem;vertical-align: top;margin: 0.24rem 0.12rem 0 0.20rem;}
    .lawIformation{display: inline-block;color: #fff;vertical-align: top;margin-top:0.20rem;}
    .labelAdviser{background-color: #dab10c;color: #fff;font-size: 0.19rem;height: 0.22rem;line-height: 0.22rem;display: inline-block;}
    .lvshi1{width: 0.16rem;height: 0.18rem;margin: 0.02rem 0.04rem 0 0.04rem;}
    .dianhua{width: 0.96rem;height: 0.96rem;float: right;margin: 0.29rem 0.20rem 0 0;}
    .threePlates{width: 7.10rem;padding:0.40rem 0;border-radius: 0.20rem;background-color: #fff;display: inline-block;box-shadow: 0px 0px 0.28rem 0.02rem rgba(0,0,0,0.15);font-size:0.26rem;margin-bottom:0.30rem;}
    .plate{width:0.86rem;height:0.86rem;display: inline-block;}
    .threePlate{vertical-align:top;display: inline-block;font-size: 0.26rem;color: #454545;text-align: center;width: 33%;margin: 0.32rem 0 0 0;position: relative;}

</style>
