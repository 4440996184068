<style scoped>
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
  .details p{color: #000000}
  .details span{color: #5c6b77}
</style>

<template>
  <div>
    <div style="padding:15px"></div>
    <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px">
      课程发布
    </div>
    <div style="padding:15px"></div>
    <div class="publicWelfare" style="font-size:16px;padding:15px" >
      <Row type="flex" align="middle">
        <Col span="4" style="text-align: right;">课程标题：</Col>
        <Col span="10"><Input v-model="title"></Input></Col>
      </Row>
      <!-- <Divider dashed />
      <Row type="flex" align="middle">
        <Col span="4" style="text-align: right;">课程概述：</Col>
        <Col span="10"><Input></Input></Col>
      </Row> -->
      <Divider dashed />
      <Row>
        <Col span="4" style="text-align: right;">课程内容：</Col>
        <Col span="20" style="text-align: left;">
          <VueUeditorWrap :config="editorConfig" style="max-width:800px;" v-model="content"></VueUeditorWrap>
          <!-- <span style="padding:15px"></span>
          <div style="padding:40px;display:inline-block;position:relative;">
            <div style="text-align: center;">手机实时预览</div>
            <div style="width:420px;height:807px;position:relative;">
              <div v-html="content" style="padding:40px;height:807px;overflow:auto;"></div>
              <div style="width:403px;height:807px;position:absolute;top:0;background-image:url(/static/images/IphoneX.png);overflow:auto;background-repeat: no-repeat;"></div>
            </div>
          </div> -->
        </Col>
      </Row>
      <div style="padding:15px"></div>
      <Button type="success" @click="saveDetial">保存</Button>
      <span style="padding:15px"></span>
      <Button type="warning" :to="{name:'correctionAE',query:{correctionId:$route.query.correctionId}}">编辑试卷</Button>


    </div>
    </div>
</template>

<script>
  import VueUeditorWrap from 'vue-ueditor-wrap'
  export default {
      name: "mediateList",
      data () {
          return {
            title:'',
            content:'',
            editorConfig: {
					// 如果需要上传功能,找后端小伙伴要服务器接口地址
					serverUrl: 'https://vn-server.lvwa18.com/ueditor/controller.php',

					// 你的UEditor资源存放的路径,相对于打包后的index.html
					UEDITOR_HOME_URL: window.UEDITOR_HOME_URL || '/plugin/UEditor/',
					// 编辑器不自动被内容撑高
					autoHeightEnabled: false,
					// 初始容器高度
					initialFrameHeight: 600,
					// 初始容器宽度
					initialFrameWidth:'100%',
					// 关闭自动保存
					enableAutoSave: false,
					toolbars: [[
					    'source', '|', 'undo', 'redo', '|',
					    'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
					    'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
					    'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
					    'directionalityltr', 'directionalityrtl', 'indent', '|',
					    'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
					    'link', 'unlink', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
					    'insertimage', 'emotion', 'insertvideo', 'music', 'attachment', 'insertframe',  'pagebreak', 'template', 'background', '|',
					    'horizontal', 'date', 'time', 'spechars',  'wordimage', '|',
					    'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
					    'print', 'searchreplace', 'drafts'
					]],
					information : '',
				},
          }
      },
      methods : {
        getDetial:function(){
          var _this = this
          _this.send({
            url:'/CurriculumContentAdmin',
            data:{
              CurriculumId:_this.$route.query.correctionId
            },
            success:function(res){
              _this.title = res.CurriculumTitle
              _this.content = res.CurriculumContent
            }
          })
        },
        saveDetial:function(){
          var _this = this
          _this.send({
            url:'/CurriculumUpdate',
            data:{
              AdminId:window.sessionStorage.AdminId,
              CurriculumId:_this.$route.query.correctionId,
              CurriculumTitle:_this.title,
              CurriculumContent:_this.content,
            },
            success:function(res){
              _this.$router.push({name:'correctionL'})
            }
          })
        }
      },
      mounted (){
        this.getDetial()
      },
      components:{'VueUeditorWrap': VueUeditorWrap,}
  }
</script>


