<template>
    <div class="caseList">
        <div style="background: url(/img/caseInput/banner.png);background-size: 100% 100%;width: 7.5rem;height: 2.63rem;text-align: center;font-family: PingFang SC;position: relative">
            <div>
                <img style="width: 0.8rem;height: 0.8rem;vertical-align: top;margin: 0.2rem 0 0.15rem;" src="/img/caseInput/logo.png">
            </div>
            <div style="font-size: 0.4rem;font-weight: bold;color: #0070CF;line-height: 0.54rem;">
                <div>成都市武侯区司法局</div>
                <div>律师&律所评价系统</div>
            </div>
            <!--<div style="position: absolute;left: 0.3rem;bottom: 0.1rem;font-size: 0.26rem;color: #FFF">当前律所：{{lawName}}</div>-->
        </div>
        <div style="height: 1.08rem;border-bottom: 0.01rem solid #DFDFDF;font-family: PingFang SC;line-height: 1.08rem;">
            <img style="width: 0.3rem;height: 0.3rem;vertical-align: top;margin: 0.39rem 0.1rem 0 0.31rem;" src="/img/caseInput/anjianliebiao.png">
            <div style="display: inline-block;font-size: 0.3rem;font-weight: bold;color: #000000;">案件管理
                <span style="color: rgba(0, 112, 207, 1);margin-left: 0.2rem">{{count}}条</span>
                <!--<span style="margin-left: 0.2rem" @click="newlyAdded">新增</span>-->
            </div>
            <div style="width: 3.46rem;height: 0.56rem;background: #EBEBEB;margin-right: 0.3rem;
            border-radius: 0.04rem;font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #999;display: inline-block;float: right;margin-top: 0.27rem;position: relative">
                <div style="position: absolute;right: 0;width: 0.74rem;height: 0.56rem;" @click="onSearch">
                    <img style="width: 0.3rem;height: 0.3rem;position: absolute;right: 0.2rem;top: 0.13rem;" src="/img/caseInput/sousuo.png">
                </div>
                <input v-model="key" placeholder="输入案号搜索" style="font-size:0.24rem;height: 0.56rem;vertical-align: top;width: 3.46rem;padding-right: 0.74rem;text-align: right;"/>
            </div>
        </div>
        <div style="text-align: center">
            <div style="text-align: left;font-size: 0.24rem;height: 1.08rem;border-bottom: 0.01rem solid #DFDFDF;font-family: PingFang SC;line-height: 1.08rem;width: 6.9rem;display: inline-block;">
                <div style="font-size: 0.26rem;display: inline-block;">当前律所：<span style="color: rgb(51, 51, 51)">{{lawName}}</span></div>
                <div style="display: inline-block;float: right;margin-right: 0.3rem;"@click="show = true">
                    <div>
                        {{caseTypeName}}
                        <Icon type="ios-arrow-down"></Icon>
                    </div>
                </div>
            </div>
        </div>
        <!--列表-->
        <van-swipe-cell  v-for="(value,index) in caseList" :key="index">
            <van-cell :border="false" >
                <div style="font-family: PingFang SC;margin: 0 0.3rem;height: 1.29rem;border-bottom: 0.01rem solid #DFDFDF" @click="lawCaseBy(value.caseId)">
                    <div style="display: inline-block;width: 0.72rem;height: 0.72rem;background-color: #A8D8FF;border-radius: 0.72rem;font-size: 0.3rem;color: #0070CF;text-align: center;line-height: 0.72rem;vertical-align: top;margin: 0.3rem 0.2rem 0 0">
                        {{index+1}}
                    </div>
                    <div style="display: inline-block">
                        <div style="font-size: 0.28rem;font-weight: bold;color: #333333;line-height: 0.73rem">
                            <div style="display: inline-block;overflow: hidden;vertical-align: top;text-overflow: ellipsis;white-space: nowrap;width: 4.8rem;">{{value.caseName}}</div>
                            <div style="display: inline-block;margin-left: 0.3rem" >
                                <img style="width:0.24rem;height: 0.24rem;margin-right: 0.07rem;margin-bottom: 0.01rem;" src="/img/caseInput/pingfen.png">
                                <span style="font-size: 0.22rem;color: rgba(51, 51, 51, 1);vertical-align: top;margin-top: 0.1rem;"v-if="value.caseStar">{{value.caseStar}}</span>
                                <span style="font-size: 0.22rem;color: rgba(51, 51, 51, 1);vertical-align: top;margin-top: 0.1rem;" v-else>0</span>
                            </div>
                        </div>
                        <div style="font-size: 0.2rem;font-weight: 500;color: #999999;">{{value.caseNum}}<span style="margin: 0 0.08rem" v-if="value.caseTime">|</span>{{value.caseTime}}</div>
                    </div>
                    <!--<div style="display: inline-block;float: right;margin-top: 0.2rem">-->
                        <!--<div><Button type="primary" @click.stop="onEdit(value.caseId)">编辑</Button></div>-->
                        <!--<div style="padding: 0.05rem"></div>-->
                        <!--<div><Button type="primary" @click.stop="lawCaseDelete(value.caseId)">删除</Button></div>-->
                    <!--</div>-->
                </div>
            </van-cell>
            <template #right>
                <div @click.stop="lawCaseDelete(value.caseId)" style="width: 1.4rem;height: 1.34rem;background: #E81F1F;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;display: inline-block;text-align: center;line-height: 1.34rem;">删除</div>
                <div @click.stop="onEdit(value.caseId)" style="width: 1.4rem;height: 1.34rem;background: #00adff;font-size: 0.3rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;display: inline-block;text-align: center;line-height: 1.34rem;">编辑</div>
            </template>
        </van-swipe-cell>

        <!--浮动按钮-->
        <div @click="newlyAdded" style="width: 0.9rem;height: 0.9rem;background: #1186E1;box-shadow: 0rem 0.03rem 0.2rem 0px rgba(7, 147, 255, 0.4);border-radius: 50%;position: fixed;bottom: 0.61rem;right: 0.61rem;text-align: center">
            <img style="width: 0.46rem;height: 0.46rem;vertical-align: top;margin-top: 0.22rem;" src="/img/caseInput/xinjian.png">
        </div>

        <van-popup v-model:show="show" position="bottom">
            <van-picker show-toolbar :columns="caseTypeData" @confirm="onConfirm"/>
        </van-popup>
    </div>
</template>

<script>
    export default {
        name: "caseList",
        data() {
            return {
                caseType : '',
                page : 1,
                caseList : [],
                caseTypeData : [],
                caseTypeName : '筛选案由',
                loadData : false,
                count : 0,
                lawName : '',
                key : '',
                show : false,
            }
        },
        methods :{
            onEdit : function(caseId){
                var _this = this
                _this.$router.push({name: 'informationInput', query: {caseId:caseId}})
            },
            onSearch : function(){
                var _this = this
                _this.caseList = []
                _this.page = 1
                _this.count = 0
                _this.lawCaseList()
            },
            lawCaseDelete : function(caseId){
                var _this = this
                _this.send({
                    confirm: true,
                    url: '/LawCaseDelete',
                    data: {
                        lawOfficeId  : window.sessionStorage.lawOfficeId,
                        caseId : caseId,
                    },
                    success: function (res) {
                        _this.caseList = []
                        _this.page = 1
                        _this.count = 0
                        _this.lawCaseList()
                    }
                })
            },
            lawCaseBy : function(caseId){
                var _this = this
                _this.$router.push({name: 'caseDetails', query: {caseId:caseId}})
            },
            newlyAdded : function(){
                var _this = this
                _this.$router.push({name:'informationInput'});
            },
            onCaseTypeId : function(caseTypeId,caseTypeName){
                this.caseType = caseTypeId
                this.caseTypeName = caseTypeName
                this.caseList = []
                this.page = 1
                this.count = 0
                this.lawCaseList()
            },
            onConfirm : function(value, index){
                this.show = false
                console.log(this.caseTypeData[index[0]].children[index[1]])
                this.caseType = this.caseTypeData[index[0]].children[index[1]].id
                this.caseTypeName = this.caseTypeData[index[0]].children[index[1]].text
                this.caseList = []
                this.page = 1
                this.count = 0
                this.lawCaseList()
            },
            lawCaseList : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/LawCaseList',
                    data: {
                        lawOfficeId  : window.sessionStorage.lawOfficeId,
                        caseType : _this.caseType,PageSize : 20,
                        page : _this.page,kye:_this.key,
                    },
                    success: function (res) {
                        console.log(res)
                        if(res){
                            for(var i in res.list){
                                _this.caseList.push(res.list[i])
                            }
                            _this.count = res.count
                            _this.loadData = false
                        }
                    }
                })
            },
            caseTypeList : function(){
                var _this = this
                var data = []
                _this.send({
                    url: '/caseTypeList',
                    data: {},
                    success: function (res) {

                        for(var i in res){
                            data.push(res[i])
                        }
                        console.log(data)
                        convertTree(data)
                        function convertTree(tree) {
                            const result = []
                            tree.forEach((item) => {
                                // 解构赋值
                                let {
                                    id: id,
                                    keyword: text,
                                    list: children
                                } = item
                                if (children) {
                                    children = convertTree(children)
                                }
                                result.push({
                                    id,
                                    text,
                                    children
                                })
                            })
                            _this.caseTypeData = result
                            return result
                        }
                    }
                })
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        },
        mounted () {
            var _this = this
            _this.setPageSize()
            _this.lawCaseList()
            _this.caseTypeList()
            _this.lawName = window.sessionStorage.lawName
        },

        created() {
            let that = this;
            window.onscroll = function () {
                // scrollTop 滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                // windowHeight 可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                // scrollHeight 滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                // 滚动条到底部的条件
                if (scrollTop + windowHeight+5 >= scrollHeight &&!that.loadData) {
                    that.loadData = true
                    // 加载数据
                    that.page++
                    that.lawCaseList();
                }
            }
        },
    }
</script>

<style scoped>
    .caseList :deep(.van-cell){padding:0;line-height: normal;}
</style>
