<template>
  <div>
<!--    <div style="border-bottom: #999999 solid 0.01rem;padding : 0.3rem 0;" v-for="(value,index) in bindList" @click="bingAccount(value)">-->
<!--      <div style="margin: 0.1rem 0 0.1rem 0.3rem;display: inline-block;font-size: 0;max-width: 4.8rem;">-->
<!--        <div style="font-size: 0.26rem;color: #000;font-weight: bold;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">-->
<!--          {{index+1}}、{{value.AdminName}}-->
<!--        </div>-->
<!--      </div>-->
<!--      <div style="display: inline-block;font-size: 0.24rem;border-radius: 0.5rem;margin: 0rem 0.3rem 0 0;padding: 0.3rem;background-color: rgb(45 140 240);color: #FFF;vertical-align: top;float: right;line-height: 0;">-->
<!--        绑定-->
<!--      </div>-->
<!--    </div>-->
<!--    登录-->
    <div style="position: fixed;width: 100%;height: 100%;background-color: #999;text-align: center;" v-if="loginShow">
      <div style="display: flex;justify-content: center;align-items: center;height: 100%;background-size: cover;">
        <div style="border-radius: 6px;background: #fff;width: 385px;padding: 25px 25px 5px 25px;display: inline-block;">
          <div style="background-position: center center; background-image: url(/img/logoGov.png); height: 80px; background-size: auto 100%; background-repeat: no-repeat;"></div>
          <div style="color: #ccc;text-align: center;font-size: 26px;margin: 10px 0">重庆村居法务平台</div>
          <Row style="margin-bottom: 20px">
            <Col span="24">
              <Input prefix="ios-contact" placeholder="请输用户名" v-model="account"/>
            </Col>
            <Col span="24" style="margin-top: 15px">
              <Input prefix="ios-lock" placeholder="请输密码" type="password" v-model="password"/>
            </Col>
            <Col span="24" style="margin-top: 15px">
              <Button type="primary" long @click="login()">绑 定</Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dd from 'gdt-jsapi'
export default {
  name: "index",
  data (){
    return{
      bindList : [],
      // 获取
      Information : [],
      response : '',
    //   登录账号密码
      account : '',
      password : '',
    //
      loginShow : false,
    }
  },
  mounted() {
    var _this = this
    if(localStorage.getItem('debug')){

    }else {
      if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        dd.getAuthCode({
          corpId: ""
        }).then(res => {
          _this.response = res.auth_code || res.code
          _this.getAuthCode()
        }).catch(err => {
        })
      }else {
        _this.response = _this.$route.query.auth_code
        _this.getAuthCode()
      }
    }

  },
  methods : {
    getAuthCode : function (){
      var _this = this
      _this.send({
        url : '/getAuthCode',
        data: {
          auth_code : _this.response,
        },
        success: function (res) {
           _this.Information = res
          if(res.status == 1){
            if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
              _this.$router.push({ name: 'oneClickLogin' ,query:{key:res.AccountLogin,secret:res.AccountPassword,type:1,data:'dataCenter'}})
            } else {
              _this.$router.push({ name: 'oneClickLogin' ,query:{key:res.AccountLogin,secret:res.AccountPassword,type:1,data:'managerIndex'}})
            }
          }else {
            _this.loginShow = true
            // _this.adminList()
          }
        }
      })
      // https://www.cqcjflgwpt.com:8110/auth-router?key=%E6%B8%9D%E5%8C%97%E5%8C%BA%E5%8F%B8%E6%B3%95%E5%B1%80&secret=MTIzNDU2&type=1&data=dataCenter
      // _this.$router.push({ name: 'oneClickLogin' ,query:{key:'丰都县司法局',secret:require('js-base64').Base64.encode(_this.$route.query.auth_code),type:1,data:'dataCenter'}})
    },
    bingAccount : function (value){
      var _this = this
      _this.send({
        url : '/bingAccount',
        data: {
          adminId : value.AdminId,
          accountId : _this.Information.Uid
        },
        success: function (res) {
          // 选择登录
          // _this.$router.push({ name: 'oneClickLogin' ,query:{key:value.AdminLogin,secret:value.AdminPassword,type:1,data:'dataCenter'}})
          // 账号密码登录
          _this.$router.push({ name: 'oneClickLogin' ,query:{key:_this.account,secret:require('js-base64').Base64.encode(_this.password),type:1,data:'dataCenter'}})
        }
      })
    },
    adminList : function (){
      var _this = this
      _this.send({
        url : '/adminLists',
        data: {},
        success: function (res) {
            _this.bindList = res
        }
      })
    },
  //   登录
    login : function (){
      var _this = this
      _this.send({
        url: '/AccountLogin',
        warningTips: true,
        data: {
          AccountLogin: _this.account,
          AccountPassword: _this.password,
          AccountTypeId : '5',
          key: '',
          captcha : '',
        },
        success: function (res) {
          console.log(res.AdminId,require('js-base64').Base64.encode(_this.password))
          _this.bingAccount(res)
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
