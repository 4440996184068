<template>
  <div>
    <Tabs :value="tabsType" @on-click="switchClick">
      <TabPane label="平台律师" name="1"></TabPane>
      <TabPane label="公益律师" name="2"></TabPane>
    </Tabs>
    <div class="publicWelfare">
      <Row style="margin: 20px"  :gutter="16">
        <Col span="12">
          <Input
              v-model="lawyerList.key"
              search
              enter-button="搜索"
              placeholder="搜索顾问姓名/执业机构/村（社区）"
              @on-search="switchClick(tabsType)"
          />
        </Col>
        <Col span="4">
          <DatePicker
              :value="lawyerList.timeData"
              v-model="lawyerList.timeData"
              type="daterange"
              split-panels
              placeholder="请选择时间"
              style="width: 200px"
              @on-change="timeScreen"
          ></DatePicker>
        </Col>
      </Row>
      <!---->
      <table class="formtable">
        <thead>
        <tr>
          <th>序号</th>
          <th>姓名</th>
          <th>移动电话</th>
          <th>执业机构</th>
          <th>执业证号</th>
          <th>擅长领域</th>
          <th>服务起始时间</th>
          <th>服务终止时间</th>
                    <th>积分</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, index) in lawyerList.data">
          <td v-if="(lawyerList.page - 1) * lawyerList.pageSize + index + 1 < 10">0{{ (lawyerList.page - 1) * lawyerList.pageSize + index + 1 }}</td>
          <td v-else>{{ (lawyerList.page - 1) * lawyerList.pageSize + index + 1 }}</td>
          <td><Avatar :src="value.LawyerImg" style="margin-right: 15px" />{{ value.LawyerName }}</td>
          <td>{{ value.LawyerPhone }}</td>
          <td>{{ value.AdminName }}</td>
          <td>{{ value.PracticeNumber || '--' }}</td>
          <td>
            <div v-if="value.skill_ids">
              <Tooltip placement="top" max-width="300">
                <div slot="content">
                                <span  v-for="(item,i) in value.skill_ids">
                                  {{item.keyword}}{{i+1 < value.skill_ids.length ?'、':''}}
                                </span>
                </div>
                <div style="display: inline-block" v-if="i < 3" v-for="(item,i) in value.skill_ids">
                  {{item.keyword}}<span v-if="i < 2">{{i+1 < value.skill_ids.length ?'、':''}}</span>
                </div>
              </Tooltip>
            </div>
            <div v-else>--</div>
          </td>
          <td>{{ value.ServiceStartTime||'--' }}</td>
          <td>{{ value.ServiceEndTime ||'--'}}</td>
                    <td @click="integralList.LawyerId = value.LawyerId;integralList.LawyerName = value.LawyerName;integralList.page = 1;integralDetails()"
                        style="cursor: pointer;text-decoration:underline;color: #2d8cf0">{{value.total_score || 0 }}</td>

        </tr>
        </tbody>
      </table>
      <Page
          :total="lawyerList.total"
          show-total
          :page-size="lawyerList.pageSize"
          show-elevator
          @on-change="
                    lawyerList.page = $event
                    serachLawyer()
                "
          :current="lawyerList.page"
      />
      <div style="padding: 10px"></div>
    </div>
    <Modal
        v-model="integralList.show"
        :title="integralList.LawyerName+'-积分详情'">
      <div slot="footer">
        <Button @click="integralList.show = false">关闭</Button>
      </div>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>积分来源</th>
            <th>获取时间</th>
            <th>积分</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in integralList.data">
            <td v-if="(integralList.page - 1) * integralList.pageSize + index + 1 < 10">0{{ (integralList.page - 1) * integralList.pageSize + index + 1 }}</td>
            <td v-else>{{ (integralList.page - 1) * integralList.pageSize + index + 1 }}</td>
            <td>{{ value.score_type.type_name }}</td>
            <td>{{ value.today }}</td>
            <td>{{ value.score }}</td>

          </tr>
          </tbody>
        </table>
        <Page
            :total="integralList.total"
            show-total
            :page-size="integralList.pageSize"
            show-elevator
            @on-change="
                    integralList.page = $event
                    integralDetails()"
            :current="integralList.page"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'lawyerManagement',

  data() {
    return {
      tabsType : '1',
      lawyerList : {
        data: [],
        total: 0,
        page: 1,
        pageSize: 15,
        key: '',
        timeData:'',
        start_time:'',
        end_time:'',
        LawyerArr:[],
      },
      integralList:{
        show:false,
        data:[],
        LawyerId:'',
        LawyerName:'',
        total: 0,
        page: 1,
        pageSize: 15,
      }
    }
  },
  methods: {
    timeScreen:function (e){
      var _this = this
      console.log(e)
      _this.lawyerList.start_time = e[0] //开始时间
      _this.lawyerList.end_time = e[1] //结束时间
      if(e[0] && e[1]){
        _this.lawyerScoreSearchByTime()
      }else {
        _this.serachLawyer()
      }
    },
    lawyerScoreSearchByTime: function (){
      var _this = this
      _this.send({
        url: '/LawyerScoreSearchByTime',
        loading: true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          start_time: _this.lawyerList.start_time,
          end_time: _this.lawyerList.end_time,
          LawyerArr: _this.lawyerList.LawyerArr.toString(),
        },
        success: function (res) {
          return _this.lawyerList.data.map(item1 => {
            const matchedItem = res.find(item2 => item1.LawyerId === item2.lawyer_id);
            if (matchedItem) {
              item1.total_score = matchedItem.scoreSum
            } else {
              item1.total_score = 0
            }
          });
          // _this.lawyerList.data
        }
      })
    },
    // 积分详情
    integralDetails: function (){
      var _this = this
      _this.integralList.data = []
      _this.send({
        url: '/LawyerScoreDetails',
        loading: true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          LawyerId: _this.integralList.LawyerId,
          start_time: _this.lawyerList.start_time,
          end_time: _this.lawyerList.end_time,
          page: _this.integralList.page,
          pageSize: _this.integralList.pageSize
        },
        success: function (res) {
          _this.integralList.show = true
          _this.integralList.data = res.data
          _this.integralList.total = res.count
        }
      })
    },
    serachLawyer: function (value) {
      var _this = this
      if (value != undefined) {
        _this.lawyerList.page = 1
        _this.lawyerList.total  = 0
      }
      _this.send({
        url: '/SerachLawyerByAdmin',
        loading: true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          key: _this.lawyerList.key,
          page: _this.lawyerList.page,
          pageSize: _this.lawyerList.pageSize
        },
        success: function (res) {
          _this.lawyerList.data = res.data
          _this.lawyerList.total = res.total
          for(var i in res.data){
            _this.lawyerList.LawyerArr.push(res.data[i].LawyerId)
            if(_this.lawyerList.start_time != '' && _this.lawyerList.end_time != ''){
              res.data[i].total_score = 0
            }
          }
          if(_this.lawyerList.start_time != '' && _this.lawyerList.end_time != ''){
            _this.lawyerScoreSearchByTime()
          }
        }
      })
    },
    serachPublicBenefitLawByAdmin: function (value) {
      var _this = this
      _this.send({
        url: '/SerachPublicBenefitLawByAdmin',
        loading: true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          key: _this.lawyerList.key,
          page: _this.lawyerList.page,
          pageSize: _this.lawyerList.pageSize
        },
        success: function (res) {
          _this.lawyerList.data = res.data
          _this.lawyerList.total = res.Count
        }
      })
    },
    switchClick: function (e) {
      var _this = this
      _this.tabsType = e
      if (e == '1') {
        _this.serachLawyer()
      }
      if (e == '2') {
        _this.serachPublicBenefitLawByAdmin()
      }
    },
  },

  mounted() {
    var _this = this
    _this.adminLevel = window.sessionStorage.adminLevel
    _this.serachLawyer()
  },

  computed:{
    assetsLink:function(){
      return `${SYSCONFIG.server.default.ssl?'https':'http'}://${SYSCONFIG.server.default.host}:${SYSCONFIG.server.default.port}/`
    }
  },
}
</script>

<style scoped>

.forMcontent p {
  line-height: 60px;
  display: inline-block;
  width: 50%;
}
.forMtitle1 p {
  display: inline-block;
  color: #333333;
  font-size: 16px;
  line-height: 60px;
  overflow: hidden;
  white-space: nowrap;
  width: 20%;
}
.forMtitle p {
  display: inline-block;
  color: #333333;
  font-size: 16px;
  width: 50%;
  line-height: 60px;
  overflow: hidden;
  white-space: nowrap;
}

.administration p {
  font-size: 20px;
  color: #333333;
  display: inline-block;
  line-height: 80px;
}

.choiceLawyer :deep(.ivu-select-dropdown ){
  max-height: none;
}
.choiceLawyer :deep(.ivu-page ){
  margin-left: 30px;
}
.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
}
.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
  text-align: center;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
}
.placeholderColor /deep/ input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #515a6e;
}
.placeholderColor /deep/ input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #515a6e;
}
.placeholderColor /deep/ input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #515a6e;
}
.placeholderColor /deep/ input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #515a6e;
}
</style>
