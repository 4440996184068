<style scoped>
    .bold{font-weight: 700}
    .legalAid :deep(.ivu-form-item){margin-bottom: 0rem;}
    .legalAid :deep(.ivu-radio-wrapper){    white-space: break-spaces;}
    .legalAid :deep(.ivu-form-item-error-tip){padding: 0;margin-top: -0.03rem;}
</style>

<template>
    <div>
        <img style="width: 100%;height: 2.6rem" src="/img/mediate.png">
        <div style="padding: 0.3rem 0.5rem;" class="legalAid">
            <!--<div style="font-size:0.4rem;text-align: center">人民调解申请</div>-->
            <!---->
            <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
                <div class="bold">一、请阅读申请须知</div>
                <FormItem prop="gender">
                    1、平台面向四川省居民，提供在线人民调解申请；<br/>

                    2、申请内容填报包括当事人真实信息、纠纷客观描述、证据资料等，资料填写必须真实可靠；<br/>

                    3、调委会选择时，原则上只能选择纠纷一方当事人户籍所在地或纠纷发生所在辖区调委会，选择其他地区调委会，可能导致申请不成功；<br/>

                    4、调解申请原则上不接受选择所在地（发生地）之外的其它调委会；<br/>

                    5、一般性纠纷原则上只能选择村（社区）调委会，所在地乡（镇）级调委会；<br/>

                    6、专业性（行业性）纠纷原则上只能选择区（县）调委会，所在地实际调委会；<br/>

                    7、平台目前只受理乡镇级及以上人民调解委员会调解申请；<br/>

                    8、平台暂不受理省级人民调解组织调解申请；<br/>

                    9、法律法规规定不在调解之列的不予受理。
                </FormItem>
                <Checkbox v-model="social" style="color:red">我已阅读须知    提示：请务必阅读须知，了解调解预约规则</Checkbox>
                <Divider dashed />
                <div class="bold">二、请填写基本信息内容</div>
                <!---->
                <FormItem label="" prop="applyName">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>姓   名：</div>
                    <Input v-model="formValidate.applyName" placeholder=""></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="sex">
                    <div><span style="color: red;padding: 0 0.1rem;">*</span>性别：</div>
                    <RadioGroup v-model="formValidate.sex">
                        <Radio label="男">男</Radio>
                        <Radio label="女">女</Radio>
                    </RadioGroup>
                </FormItem>
                <!---->
                <FormItem label="" prop="idCard">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证号码：</div>
                    <Input v-model="formValidate.idCard" placeholder=""></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="nation">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>民族：</div>
                    <Select v-model="formValidate.nation" placeholder="">
                        <Option :value="value.EthnicityName" v-for="(value,index) in nation">{{value.EthnicityName}}</Option>
                    </Select>
                </FormItem>
                <!---->
                <FormItem label="" prop="birthday">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>出生日期：</div>
                    <Input v-model="formValidate.birthday" placeholder="如：xxxx-xx-xx"></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="age">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>年龄：</div>
                    <Input v-model="formValidate.age" placeholder=""></Input>
                </FormItem>
                <!---->
                <!--<FormItem label="" prop="city">-->
                <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>住所地址：</div>-->
                <!--<Cascader :data="address" v-model="formValidate.dz"></Cascader>-->
                <!--</FormItem>-->
                <FormItem label="" prop="address">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>请填写住所地址：</div>
                    <Input v-model="formValidate.address" placeholder="请填写住所地址"></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="phone">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>联系电话：</div>
                    <Input v-model="formValidate.phone" placeholder=""></Input>
                </FormItem>
                <!---->
                <!--<FormItem label="" prop="sfPathmc">-->
                    <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>身份证：</div>-->
                    <!--<div>1.身份证上的信息不能被遮挡，且清晰可见；-->

                        <!--2.照片支持jpg/bmp格式，最大不超过2MB。</div>-->
                    <!--<Upload action="https://vn-server.lvwa18.com/api/upload_file_H5"-->
                            <!--　　:before-upload="onBefore"-->
                            <!--　　:on-success="Picture"-->
                            <!--:default-file-list="formValidate.sfPathmc"-->
                            <!--:on-remove="deleteValuePicture"-->
                    <!--&gt;-->
                        <!--<Button icon="md-add">上传居民身份证</Button>-->
                    <!--</Upload>-->
                <!--</FormItem>-->
                <!---->
                <!--<FormItem label="" prop="jsPathmc">-->
                    <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>驾驶证：</div>-->
                    <!--<div>1.驾驶证上的信息不能被遮挡，且清晰可见；-->

                        <!--2.照片支持jpg/bmp格式，最大不超过2MB。</div>-->
                    <!--<Upload action="https://vn-server.lvwa18.com/api/upload_file_H5"-->
                            <!--　　:before-upload="onBefore"-->
                            <!--　　:on-success="material"-->
                            <!--:default-file-list="formValidate.jsPathmc"-->
                            <!--:on-remove="deleteValuePicture1"-->
                    <!--&gt;-->
                        <!--<Button icon="md-add">上传经济情况材料</Button>-->
                    <!--</Upload>-->
                <!--</FormItem>-->
                <!---->
                <Divider dashed />
                <div style="text-align:center;">被申请人信息</div>
                <FormItem label="" prop="respondent">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>姓名：</div>
                    <Input v-model="formValidate.respondent" placeholder="请填写被申请人姓名"></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="respondentIdCard">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证号码：</div>
                    <Input v-model="formValidate.respondentIdCard" placeholder="请输入15或18位的身份证"></Input>
                </FormItem>
                <!---->
                <!--<FormItem label="" prop="city">-->
                <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>住所地址：</div>-->
                <!--<Cascader :data="address" v-model="formValidate.bsqrdz"></Cascader>-->
                <!--</FormItem>-->
                <!---->
                <FormItem label="" prop="respondentAddress">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>请填写住所地址：</div>
                    <Input v-model="formValidate.respondentAddress" placeholder="请填写住所地址"></Input>
                </FormItem>
                <!---->
                <FormItem label="" prop="respondentPhone">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>联系电话：</div>
                    <Input v-model="formValidate.respondentPhone" placeholder="请输入手机号码"></Input>
                </FormItem>
                <Divider dashed />
                <!---->
                <div class="bold">三、申请人民调解的纠纷概述</div>
                <FormItem label="" prop="applyType" style="margin-bottom: 0.2rem">
                    <div><span style="color: red;padding: 0 0.1rem;">*</span>纠纷类型：</div>
                    <RadioGroup v-model="dispute">
                        <Radio label="1">一般性纠纷</Radio>
                        <Radio label="2">专业性（行业性）纠纷</Radio>
                    </RadioGroup>
                    <Select v-model="formValidate.applyType" v-if="dispute == '1'">
                        <Option value="邻里纠纷">邻里纠纷</Option>
                        <Option value="抚养纠纷">抚养纠纷</Option>
                        <Option value="房屋宅基地纠纷">房屋宅基地纠纷</Option>
                        <Option value="生产经营纠纷">生产经营纠纷</Option>
                        <Option value="邻里纠纷">邻里纠纷</Option>
                        <Option value="人身损害赔偿纠纷">人身损害赔偿纠纷</Option>
                        <Option value="合同纠纷">合同纠纷</Option>
                    </Select>
                    <Select v-model="formValidate.applyType" v-if="dispute == '2'">
                        <Option value="医疗纠纷">医疗纠纷</Option>
                        <Option value="交通事故纠纷">交通事故纠纷</Option>
                        <Option value="劳动争议纠纷">劳动争议纠纷</Option>
                        <Option value="消费者权益纠纷">消费者权益纠纷</Option>
                        <Option value="物业管理纠纷">物业管理纠纷</Option>
                        <Option value="征地拆迁纠纷">征地拆迁纠纷</Option>
                        <Option value="山林土地纠纷">山林土地纠纷</Option>
                        <Option value="婚姻家庭纠纷">婚姻家庭纠纷</Option>
                        <Option value="电子商务纠纷">电子商务纠纷</Option>
                        <Option value="旅游纠纷">旅游纠纷</Option>
                        <Option value="环境污染纠纷">环境污染纠纷</Option>
                    </Select>
                </FormItem>
                <!---->
                <FormItem label="" prop="proposerExcuse">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>纠纷概述：</div>
                    <Input v-model="formValidate.proposerExcuse" type="textarea" :autosize="{minRows: 2,maxRows: 5}" placeholder=""></Input>
                </FormItem>
                <!---->
                <!--<FormItem label="" prop="zmPathmc">-->
                    <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;"></span>证明材料：</div>-->
                   <!--<div>-->
                       <!--1.附件支持doc/docx/pdf/zip/rar/jpg/jpeg/bmp格式,最大不超过10MB-->
                   <!--</div>-->
                    <!--<Upload action="https://vn-server.lvwa18.com/api/upload_file_H5"-->
                            <!--　　:before-upload="onBefore"-->
                            <!--　　:on-success="caseData"-->
                            <!--:default-file-list="formValidate.zmPathmc"-->
                            <!--:on-remove="deleteValuePicture2"-->
                    <!--&gt;-->
                        <!--<Button icon="md-add">上传与申请有关案件资料</Button>-->
                    <!--</Upload>-->
                <!--</FormItem>-->
                <Divider dashed />
                <div class="bold">四、人民调解委员会及调解员选择</div>
                <FormItem label="" prop="">
                    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>机构所在：</div><br/>
                    <div style="text-align: center">
                        <FormItem label="" prop="orgCity">
                            <Select v-model="formValidate.orgCity">
                                <Option v-for="(value,index) in mediate.loadcity" :value="value">{{ value }}</Option>
                            </Select>
                        </FormItem>
                        <div style="padding:5px;"></div>
                        <FormItem label="" prop="orgArea">
                            <Select v-model="formValidate.orgArea">
                                <Option v-for="(value,index) in mediate.orgArea" :value="value">{{ value }}</Option>
                            </Select>
                        </FormItem>
                        <div style="padding:5px;"></div>
                        <FormItem label="" prop="orgArea">
                            <Select v-model="formValidate.orgZhen">
                                <Option v-for="(value,index) in mediate.orgZhen" :value="value">{{ value }}</Option>
                            </Select>
                        </FormItem>
                        <div style="padding:5px;"></div>
                        <FormItem label="" prop="orgArea">
                            <Select v-model="hui1">
                                <Option v-for="(value,index) in mediate.hui" :value="value.code+','+value.mc">{{ value.mc }}</Option>
                            </Select>
                        </FormItem>
                        <div style="padding:5px;"></div>
                        <FormItem label="" prop="orgArea">
                            <Select v-model="formValidate.ren">
                                <Option v-for="(value,index) in mediate.ren" :value="value">{{ value }}</Option>
                            </Select>
                        </FormItem>
                    </div>
                </FormItem>
                <!---->
                <FormItem >
                    <div style="text-align: center;margin-top: 0.2rem">
                        <Button type="primary" @click="submit('formValidate')" style="display: inline-block">提交</Button>
                    </div>
                </FormItem>
            </Form>
        </div>
        <Modal
                v-model="modal1"
                title=""
                @on-ok="ok"
                @on-cancel="fanhui"
                ok-text="关闭"
                cancel-text="返回上一页">
            <p>申请成功</p>
        </Modal>
    </div>
</template>



<script>
    export default {
        name: "legalAid",
        data () {
            return {
                social : '',
                dispute : '',
                modal1 : true,
                nation : [],
                vertical : '',
                ruleValidate: {
                    applyName : [{ required: true,trigger: 'blur' ,message:' '}],
                    sex : [{ required: true,trigger: 'change' ,message:'请选择性别'}],
                    idCard : [{ required: true,trigger: 'blur' ,message:' '}],
                    nation : [{ required: true,trigger: 'change' ,message:' '}],
                    birthday : [{ required: true,trigger: 'blur' ,message:' '}],
                    age : [{ required: true,trigger: 'blur' ,message:' '}],
                    address : [{ required: true,trigger: 'blur' ,message:' '}],
                    phone : [{ required: true,trigger: 'blur' ,message:' '}],
                    respondent : [{ required: true,trigger: 'blur' ,message:' '}],
                    respondentIdCard : [{ required: true,trigger: 'blur' ,message:' '}],
                    respondentAddress : [{ required: true,trigger: 'blur' ,message:' '}],
                    respondentPhone : [{ required: true,trigger: 'blur' ,message:' '}],
                    applyType : [{ required: true,trigger: 'change' ,message:'选择纠纷类型'}],
                    proposerExcuse : [{ required: true,trigger: 'change' ,message:' '}],
                    orgCity : [{ required: true,trigger: 'change' ,message:' '}],
                    orgArea : [{ required: true,trigger: 'change' ,message:' '}],
                    orgZhen : [{ required: true,trigger: 'change' ,message:' '}],
                    hui : [{ required: true,trigger: 'change' ,message:' '}],
                    ren : [{ required: true,trigger: 'change' ,message:' '}],
                    // sfPathmc :[{ required: true,trigger: 'blur' ,message:' '}],
                    // sfPath : [{ required: true,trigger: 'blur' ,message:' '}],
                    // jsPathmc :[{ required: true,trigger: 'blur' ,message:' '}],
                    // jsPath : [{ required: true,trigger: 'blur' ,message:' '}],
                    // zmPathmc :[{ required: true,trigger: 'blur' ,message:' '}],
                    // zmPath : [{ required: true,trigger: 'blur' ,message:' '}],
                },
                formValidate: {
                    applyName : '',
                    sex : '',
                    idCard : '',
                    nation : '',
                    birthday : '',
                    age : '',
                    address : '',
                    phone : '',
                    respondent : '',
                    respondentIdCard : '',
                    respondentAddress : '',
                    respondentPhone : '',
                    applyType : '',
                    proposerExcuse : '',
                    orgCity : '',
                    orgArea : '',
                    orgZhen : '',
                    hui : '',
                    ren : '',
                    // sfPathmc :[],
                    // sfPath : '',
                    // jsPathmc :[],
                    // jsPath : '',
                    // zmPathmc :[],
                    // zmPath : '',
                },
                hui1 : '',
                mediate: {
                    loadcity : '',
                  orgArea:[],
                  orgZhen:[],
                  hui:[],
                  ren:[],
                }
            }
        },
      watch:{
          'formValidate.orgCity':function (val) {
            this.formValidate.orgArea = ''
            this.formValidate.orgZhen = ''
            this.formValidate.hui = ''
            this.hui1 = ''
            this.formValidate.ren = ''
            val?this.mediate1():''
          },
          'formValidate.orgArea':function (val) {
            this.formValidate.orgZhen = ''
            this.formValidate.hui = ''
              this.hui1 = ''
            this.formValidate.ren = ''
            val?this.mediate2():''
          },
          'formValidate.orgZhen':function (val) {
            this.formValidate.hui = ''
              this.hui1 = ''
            this.formValidate.ren = ''
            val?this.mediate3():''
          },
          'hui1':function (val) {
            this.formValidate.ren = ''
            val?this.mediate4():''
          },
      },
        methods:{
            fanhui: function(){window.history.go(-1)},
            submit : function(name){
                var _this = this
                console.log(_this.social)

                if(_this.social){
                    _this.$refs[name].validate((valid) => {
                        if (valid) {
                            _this.send({
                                url : '/mediate',
                                data : {
                                    Content : _this.formValidate,
                                    UserId : _this.$route.query.id,
                                    AreaId : window.sessionStorage.AreaId
                                },
                                success: function (res) {
                                    console.log(res)
                                    if(res == true){
                                        _this.modal1 = true
                                        // _this.$Message.success('操作成功')
                                        // window.history.go(-1)
                                    }else{
                                        const reg = /[\u4e00-\u9fa5]/g;
                                        console.log(res.match(reg).join(''))
                                        _this.$Message.success(res.match(reg).join(''))
                                    }
                                    // _this.nation = res
                                },
                                error : function (res) {
                                    console.log(res)
                                }
                            })
                        } else {
                            this.$Message.error('输入完成后提交传!');
                        }
                    })
                }else {
                    this.$Message.info('请选中我已阅读须知！');
                }
                console.log(_this.formValidate,_this.address)
            },
            //删除身份证
            deleteValuePicture : function(file, fileList){
                var _this = this
                _this.formValidate.sfPathmc = []
                _this.formValidate.sfPath = []
            },
            deleteValuePicture1 : function(file, fileList){
                var _this = this
                _this.formValidate.jsPathmc = []
                _this.formValidate.jsPath = []
            },
            deleteValuePicture2 : function(file, fileList){
                var _this = this
                _this.formValidate.zmPathmc = []
                _this.formValidate.zmPath = []
            },
            // Picture : function(response, file, fileList){
            //     var _this = this
            //     _this.formValidate.sfPathmc = response.Data.path
            //     _this.formValidate.sfPath = response.Data.path
            // },
            // material : function(response, file, fileList){
            //     var _this = this
            //     _this.formValidate.jsPathmc = response.Data.path
            //     _this.formValidate.jsPath = response.Data.path
            // },
            // caseData : function(response, file, fileList){
            //     var _this = this
            //     _this.formValidate.zmPathmc = response.Data.path
            //     _this.formValidate.zmPath = response.Data.path
            // },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
            ethnicityList : function(){
                var _this = this
                _this.send({
                    url : '/EthnicityList',
                    success: function (res) {
                        _this.nation = res
                    }
                })
            },
          //    地区
          loadcity : function () {
            var _this = this
            _this.send({
              url : '/loadcity',
              success: function (res) {
                _this.mediate.loadcity = res.data
              }
            })
          },
            mediate1 : function(){
                var _this = this
                _this.send({
                    url : '/loadarea',
                  data:{
                      city:_this.formValidate.orgCity,
                  },
                    success: function (res) {
                        _this.mediate.orgArea = res.data
                    }
                })
            },
          mediate2 : function(){
            var _this = this
            _this.send({
              url : '/loadzhen',
              data:{
                city:_this.formValidate.orgCity,
                area:_this.formValidate.orgArea,
              },
              success: function (res) {
                _this.mediate.orgZhen = res.data
              }
            })
          },
          mediate3 : function(){
            var _this = this
            _this.send({
              url : '/loadhui',
              data:{
                city:_this.formValidate.orgCity,
                area:_this.formValidate.orgArea,
                zhen:_this.formValidate.orgZhen,
              },
              success: function (res) {
                _this.mediate.hui = res.data
                  console.log(_this.mediate.hui)
              }
            })
          },
          mediate4 : function(e){
            var _this = this
              console.log(_this.hui1.split(","))
              _this.formValidate.hui = _this.hui1.split(",")[1]
            _this.send({
              url : '/loadren',
              data:{
                city:_this.formValidate.orgCity,
                area:_this.formValidate.orgArea,
                code:_this.hui1.split(",")[0],
              },
              success: function (res) {
                _this.mediate.ren = res.data

              }
            })
          },
            onBefore : function () {

            },

        },
        mounted: function () {
            var _this = this
            this.setPageSize()
            _this.ethnicityList()
            _this.loadcity()
            window.onresize = function () {
                _this.setPageSize()
            }
        }
    }
</script>

