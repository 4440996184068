<style scoped>
	.header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
  .detial-content{border-radius:0.5rem;margin-top:-0.5rem;background-color:#FFF;padding:0.3rem;}
  .content-title{text-align: center;color:#333333;font-size:0.28rem;font-weight:bold;}
  .content-text{color:#888888;font-size:0.24rem;}
</style>

<template>
  <div>
    <div class='header'></div>
    <div class="detial-content">
      <div class="content-title">
        纠纷类型
      </div>
      <Divider style="margin-bottom:0.3rem;"/>
      <template v-for="i in 5">
        <Row class="content-text">
          <Col span="8">姓名：</Col>
          <Col span="16" style="text-align: right;">测试姓名</Col>
        </Row>
        <Divider style="margin:0.3rem 0;"/>
      </template>
    </div>
    <div style="position:fixed;width:100%;text-align:center;bottom:0.2rem">
      <Button type="primary" style="width:3.48rem;font-size:0.28rem;">调解操作</Button>
    </div>
  </div>
</template>

<script>
export default {
  name:'detial',
  data() {
    return {
      
    };
 },
  methods: {
    
  },
  mounted() {
    
  },
}
</script>