<style scoped>
  .top-bg{background:url(https://ai-cdn.lvwa18.com/img/rusticate/newbg.png) no-repeat;background-size:100% 100%;height: 7.21rem;}
  .header{background-image: linear-gradient(rgba(0,111,214,0.8), rgba(76,151,255,0.8));padding:0.12rem 0.3rem;}
  .logo{width: 1.02rem;margin: 0.30rem 0;height: 1.10rem;}
  .user-icon{width: 0.45rem;height: 0.45rem;border-radius: 0.45rem;vertical-align:middle;border-radius:0.225rem;}
  .header-text{color:#fff;font-size: 0.24rem;display: inline-block;vertical-align:middle;}
  .title-panel{text-align:center;}
  .title-text{color:#fff;font-size: 0.48rem;font-weight:700;}
  .title-areaText{color:#fff;font-size: 0.36rem;font-weight: 600;}
  .lawyer-panel{position:relative;margin:auto;width:6.90rem;height:1.53rem;border-radius:0.765rem;background-image: linear-gradient(rgba(0,111,214,0.8), rgba(76,151,255,0.8));text-align: left;padding:0.1rem 0.2rem;}
  .lawyer-icon{width: 1.06rem;height: 1.06rem;border-radius:0.53rem;vertical-align:middle;}
  .lawyer-info{display: inline-block;color: #fff;vertical-align:middle;}
  .lawyer-type{margin-left:0.05rem;}
  .lawyer-type-bg{background-image:url(https://ai-cdn.lvwa18.com/img/rusticate/tagging.png);background-repeat:no-repeat;padding:0 0.1rem;background-size:100% 100%;color: #fff;font-size: 0.19rem;}
  .lawyer-type-icon{width: 0.16rem;height: 0.18rem;vertical-align:middle;}
  .call-icon{width: 0.96rem;height: 0.96rem;}
  .menu-panel{padding:0.40rem 0;border-radius:0.2rem;background-color:#fff;box-shadow:0px 0px 0.28rem 0.02rem rgba(0,0,0,0.15);margin-bottom:0.30rem;text-align:left}
  .menu-title-bg{width:1.07rem;height: 0.11rem;vertical-align:middle;}
  .menu-item{vertical-align:top;display: inline-block;font-size: 0.26rem;color: #454545;text-align: center;width: 33%;margin: 0.32rem 0 0 0;position: relative;}
  .menu-icon{width:0.86rem;height:0.86rem;}
</style>

<template>
  <div>
    <div class="top top-bg">
      <div class="header">
        <img src="/img/logoGov.png" class="user-icon" />
        <span style="padding:0.1rem"></span>
        <div class="header-text">
          Hi，
          <template v-if="$store.state.userInfo.UserName">
            {{$route.query.openId=='dongbuxinqu07'?'公共法律服务中心':$store.state.userInfo.UserName}}
          </template>
          <span>你好！</span>
        </div>
      </div>
      <!---->
      <div class="title-panel">
        <img src="https://ai-cdn.lvwa18.com/img/rusticate/sifa.png" class="logo" />
        <div class="title-text">
          {{$store.state.userInfo.Area_name}}智慧公共法律服务
        </div>
        <div style="padding:0.1rem;"></div>
        <div class="title-areaText">
          {{$store.state.userInfo.Admin_name}}{{$store.state.userInfo.VillagesName}}
        </div>
      </div>
      <div style="padding:0.2rem;"></div>
      <!---->
      <div class="lawyer-panel">
        <img class="lawyer-icon" :src="lawyerInfo.img?lawyerInfo.img:'/img/logoGov.png'" />
        <span style="padding:0.1rem;"></span>
        <div class="lawyer-info">
          <div>
            <span style="font-size: 0.36rem;">{{$store.state.userInfo.LawyerName}}</span>
            <span class="lawyer-type lawyer-type-bg">
              <img src="https://ai-cdn.lvwa18.com/img/rusticate/lvshi1.png" class="lawyer-type-icon"/><span style="vertical-align: middle;">法律顾问</span>
            </span>
          </div>
          <div style="font-size: 0.24rem;">{{$store.state.userInfo.LawyerPhone}}</div>
          <div style="font-size: 0.18rem;">服务时间 9:00-18:00</div>
        </div>
        <a style="position:absolute;right:0;top:50%;margin-top:-0.48rem;right:0.2rem;" :href="'tel:'+lawyerInfo.phone"><img src="https://ai-cdn.lvwa18.com/img/rusticate/dianhua.png" class="call-icon"/></a>
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
    <!--服务菜单-->
    <div style="margin-top:-1.4rem;padding:0.2rem">
      <div style="text-align: center" v-for="(item,index) in menuList">
        <div class="menu-panel">
          <div style="font-size:0.3rem;font-weight:600;text-align: center;">
            <img class="menu-title-bg" src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png" />
            {{item.typeName}}
            <img class="menu-title-bg" src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png" />
          </div>
          <div style="padding-top:0.1rem"></div>
          <div class="menu-item" v-for="(item1,index1) in item.children" @click="selectMenu(item1)">
            <img :src="item1.icon" class="menu-icon" />
            <div><span>{{item1.name}}</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "index",
    data: function () {
      return {
        lawyerInfo: {
          name: '',
          phone: '',
          img: '',
        },
        userInfo:{},
        menuList:[
          {
            typeName:'咨询服务',
            children:[
              {name:'人工咨询',icon:'https://ai-cdn.lvwa18.com/img/rusticate/plate0.png',routerName:'CDLQYD',serviceType:2},
              {name:'自助咨询',icon:'https://ai-cdn.lvwa18.com/img/rusticate/plate1.png',url:'https://panda.lvwa18.com/menuSelect?source=IQBJ',serviceType:2},
              {name:'视频咨询',icon:'https://ai-cdn.lvwa18.com/img/rusticate/plate2.png',fun:'videoCall'},
            ]
          },
          {
            typeName:'申办服务',
            children:[
              {name:'法律援助',icon:'https://ai-cdn.lvwa18.com/img/rusticate/f_icon1.png',routerName:'legalAidListH',serviceType:6,},
              {name:'人民调解',icon:'https://ai-cdn.lvwa18.com/img/rusticate/f_icon2.png',routerName:'mediateListH',serviceType:8,},
              {name:'办理公证',icon:'https://ai-cdn.lvwa18.com/img/rusticate/f_icon7.png',url:'https://sccd.egongzheng.com/H5/templates/index.html',serviceType:7,},
            ]
          },
          {
            typeName:'查询服务',
            children:[
              {name:'模拟判决',icon:'https://ai-cdn.lvwa18.com/img/rusticate/f_icon6.png',url:'https://panda.lvwa18.com/documentField?source=IQBJ'},
              {name:'法律文书',icon:'https://ai-cdn.lvwa18.com/img/rusticate/f_icon8.png',url:'https://panda.lvwa18.com/select?source=IQBJ'},
              {name:'民法典',icon:'https://ai-cdn.lvwa18.com/img/rusticate/f_icon5.png',url:'https://panda.lvwa18.com/dialogue?gid=12&source=IQBJ'},
              {name:'典型案例',icon:'https://ai-cdn.lvwa18.com/img/rusticate/index-icon-exam.png',url:'https://panda.lvwa18.com/dialogue?gid=13&source=IQBJ'},
              {name:'司法案例库',icon:'https://ai-cdn.lvwa18.com/img/rusticate/f_icon10.png',routerName:'sfalk'},
            ]
          },
        ],
      }
    },
    mounted() {
      var _this = this
      _this.checkUserLogin()
    },
    computed: {
      nickName: function () {
        if (this.$store.state.userInfoTF.nickName || window.sessionStorage.nickName) {
          return this.$store.state.userInfoTF.nickName || window.sessionStorage.nickName
        } else {
          return false
        }
      }
    },
    methods: {
      checkUserLogin:function(){
        window.postUserInfo = this.postUserInfo
        window.location.href = "checkuserlogin:///";
      },
      postUserInfo:function(params){
        var jsonParams = eval("(" + params + ")");
        this.checkUserRegister(jsonParams)
        this.$store.state.mobile.open.qingbaijiang.userInfo = jsonParams
        //H5 页面自己处理登录成功后的逻辑
        //jsoParams 下各个参数：
        /* —— 各参数含义：
        // jsoParams.userID:新空云的用户 ID（唯一）
        //jsoParams.password：密文密码（明文密码进行 md5 后的密码）
        // jsoParams.nickName: 新空云的用户昵称
        // jsoParams.userPhone: 新空云的用户手机号
        // jsoParams.loginName: 新空云用户登录名（手机号或邮箱地址）
        // jsoParams.openID: 新空云的第三方登录 openID(uType 不为 0 才有值)
        // jsoParams.faceUrl: 新空云的用户头像地址
        //jsoParams.uType: 用户登录类型 uType：登录类型：0 手机号登录；1 微博登录；2QQ
        登录；3 微信登录
        ——-*/
      },
      checkUserRegister:function(userInfo){
        var _this = this
        _this.send({
          url:"/XkyUserCheck",
          data:{
            Uid:userInfo.userID,
          },
          callback:function(res){
            if(res.Success==0){
              _this.$router.push({name:'CDQBJS'})
            }else{
              _this.$store.state.userInfo = res.Data
              window.sessionStorage.Area_name = res.Data.Area_name
              window.sessionStorage.userId = res.Data.UserId
              document.title = res.Data.Area_name + '-公共法律服务平台'
            }
          }
        })
      },
      selectMenu:function(item1){
        if(item1.serviceType){
          this.addServiceLog(item1.serviceType)
        }
        if(item1.fun){
          this[item1.fun]()
        }
        if(item1.url){
          window.location.href = item1.url
        }
        if(item1.routerName){
          this.$router.push({name:item1.routerName})
        }
      },
      videoCall: function () {
        this.$router.push({ name: 'MOPV', query: { roomId: this.randomRoomID() } })
      },
      randomRoomID: function () {
        return parseInt(Math.random() * 9999)
      },
      // 统计订单类型
      addServiceLog: function (orderTypeId) {
        var _this = this
        return new Promise((resolve, reject) => {
          _this.send({
            url: '/AddOrder',
            data: {
              LawyerId: _this.$store.state.userInfo.LawyerId,
              UserId: _this.$store.state.userInfo.UserId,
              OrderTypeId: orderTypeId
            },
            success: function (res) {
              resolve()
            }
          })
        })
      },
      getLawyerInfo: function () {
        var _this = this
        _this.send({
          url: '/VillagesLawyer',
          data: {
            VillagesId: _this.$route.query.AreaId
          },
          success: function (res) {
            _this.lawyerInfo.name = res.LawyerName
            _this.lawyerInfo.phone = res.LawyerPhone
            _this.lawyerInfo.img = res.LawyerImg
          }
        })
      },
      getAreaInfo: function () {
        var _this = this
        _this.send({
          url: '/VillagesArea',
          data: {
            VillagesId: _this.$route.query.AreaId
          },
          success: function (res) {
            _this.addressInfo.area = res.AreaName
            _this.addressInfo.street = res.AdminName
            _this.addressInfo.community = res.VillagesName
          }
        })
      },

    },
  }
</script>
