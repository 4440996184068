<template>
    <div style="background: rgba(237, 237, 237, 1);">
      <div style="position: fixed;width: 100%;height: 100%;z-index: 99999;background-color: rgba(227, 227, 227 , 0.8);top: 0;left: 0;" v-if="information.IsSign != 1">
        <div style="text-align: center;color: #000;font-size: 20px;margin-top: 20%">
          <div>感谢您一直以来对“重庆村居法务平台”的支持和关注</div>
          <div>本功能为增值服务项目，请购买后使用！</div>
          <div style="margin-top: 30px">
            <div>平台服务商： 重庆律蛙信息科技有限公司</div>
            <div>地   址：重庆市江北区五里店街道创富路5号1幢4楼</div>
            <div>联系人：唐应坤  18980633318</div>
          </div>
          <div style="display:inline-block;margin-top: 20px">
            <Button @click="introduction()">“重庆村居法务平台”的定制增值服务项目介绍.pdf</Button>
          </div>
          <div style="margin-top: 20px">
            <Button @click="introduction1('https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/pdf/%E9%87%8D%E5%BA%86%E6%9D%91%E5%B1%85%E6%B3%95%E5%8A%A1%E5%B9%B3%E5%8F%B0-%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.docx')">重庆村居法务平台-服务协议.docx</Button>
          </div>
        </div>
      </div>
        <div style="width: 7.5rem;height: 3.52rem;background: linear-gradient(-90deg, #305FF3, #133BBD);">
            <div style="width: 7.5rem;height: 0.72rem;background-color: rgba(0, 0, 0, 0.1);color: #FFFFFF;line-height: 0.72rem;overflow: hidden;font-size: 0.2rem;">
                <div style="margin-left: 0.3rem;display: inline-block">当前：{{information.AdminName}}</div>
<!--                <div style="display: inline-block;margin-left: 0.15rem">[切换]</div>-->
                <div style="float: right;margin-right: 0.3rem" v-html="time"></div>
            </div>
<!--          <div style="text-align: center;margin-top: 0.55rem;">-->
<!--            <img style="width: 5.2rem;height: 1.28rem" src="/img/h5/bigData/title.png">-->
<!--          </div>-->
            <div style="font-size: 0.52rem;font-family: REEJI-PinboGB-Flash;font-weight: 400;color: #FFFFFF;line-height: 0.82rem;margin-top: 0.7rem;text-align: center">
              <div>重庆村居法务平台 数据中心</div>
<!--              <div>数据中心</div>-->
            </div>
        </div>
        <div style="text-align: center;margin-top: -0.4rem">
          <!--          核心数据-->
          <div style="width: 7.1rem;background: #FFFFFF;border-radius: 0.2rem;display: inline-block">
            <div style="margin: 0.4rem 0 0.3rem 0.2rem;text-align: left">
              <div style="width: 0.1rem;height: 0.3rem;background: #2D5BED;border-radius: 0.02rem;display: inline-block;vertical-align: top;margin-right: 0.2rem"></div>
              <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.3rem">核心数据</div>
              <div style="float: right;margin-right: 0.2rem">
                <div @click="serviceDate.identification = 'a'" :class="serviceDate.identification == 'a'?'coreDataTrue':'coreDataFalse'">所有</div>
                <div @click="serviceDate.identification = 'y'" :class="serviceDate.identification == 'y'?'coreDataTrue':'coreDataFalse'" style="border-right: 0.01rem solid rgba(203, 211, 231, 1)">年度</div>
                <div @click="serviceDate.identification = 'q'" :class="serviceDate.identification == 'q'?'coreDataTrue':'coreDataFalse'" style="border-right: 0.01rem solid rgba(203, 211, 231, 1)">本季</div>
                <div @click="serviceDate.identification = 'm'" :class="serviceDate.identification == 'm'?'coreDataTrue':'coreDataFalse'" style="border-right: 0.01rem solid rgba(203, 211, 231, 1)">本月</div>
              </div>
            </div>
            <Row >
              <Col span="6" v-for="(value,index) in statisticsData" style="font-size: 0;margin-bottom: 0.3rem" v-if="value.value != -1">
                <div style="width: 0.80rem;height: 0.80rem;background: #F6F6F6;border-radius: 50%;text-align: center;display: inline-block">
                  <img style="width: 0.48rem;height: 0.48rem;margin-top: 0.15rem" :src="value.img">
                </div>
                <div style="font-size: 0.28rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.28rem;margin: 0.2rem 0">
                  <div style="display:inline-block;color: #fa7a0a">{{value.value}}</div>
                  <div style="display:inline-block;font-size: 0.2rem;line-height: 0.2rem;margin-left: 0.05rem"> {{value.unit}}</div>
                </div>
                <div style="font-size: 0.20rem;font-family: PingFang SC;font-weight: 500;color: #696969;line-height: 0.2rem">{{value.name}}</div>
              </Col>
            </Row>
          </div>
<!--          实时数据-->
          <div style="width: 7.1rem;background: #FFFFFF;border-radius: 0.2rem;display: inline-block;margin-top: 0.2rem">
            <div style="margin: 0.4rem 0 0.3rem 0.2rem;text-align: left">
              <div style="width: 0.1rem;height: 0.3rem;background: #2D5BED;border-radius: 0.02rem;display: inline-block;vertical-align: top;margin-right: 0.2rem"></div>
              <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.3rem">实时数据</div>
<!--              <div @click="realTime()" style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.3rem;float: right;margin-right: 0.3rem">更多</div>-->
            </div>
            <div style="position: relative">
              <div style="position: absolute;top: -0.22rem;left: 0.35rem;z-index: 9;white-space: nowrap;">
                <div :class="topList.selected == 'consulting'?'realTrue':'realFalse'" @click="topList.selected = 'consulting'">今日咨询服务</div>
                <div :class="topList.selected == 'archives'?'realTrue':'realFalse'" @click="topList.selected = 'archives'" style="margin: 0 0.2rem">今日村居治理档案</div>
                <div :class="topList.selected == 'duty'?'realTrue':'realFalse'" @click="topList.selected = 'duty'">今日值班情况</div>
              </div>
              <div style="width: 6.7rem;background: #FFFFFF;border: 0.01rem solid #E6E8EA;border-radius: 0.08rem;display: inline-block;margin-bottom: 0.3rem;">
                <div>
                  <div style="margin-top: 0.32rem"></div>
                  <Row style="width: 6.68rem;height: 0.52rem;line-height: 0.52rem;font-size: 0.2rem;white-space: nowrap;overflow: hidden;">
                    <Col span="4">服务时间</Col>
                    <Col span="12">服务村(社区）</Col>
                    <Col span="3">村居顾问</Col>
                    <Col span="5">类型</Col>
                  </Row>
                </div>
                <div style="height: 3.01rem;overflow-y:auto;overflow-x: hidden;" v-if="topList.selected == 'consulting'">
                  <Row v-for="(item,index) in topList.consulting" style="font-size: 0.2rem;width: 6.68rem;height: 0.52rem;line-height: 0.52rem;background: #F3F8FB;border-radius: 2px 2px 2px 4px;margin-bottom: 0.20rem">
                    <Col span="4">{{item.consultTime?item.consultTime.substring(0,5):formatTopTime(item.ask_time)}}</Col>
                    <Col span="12" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;text-align: left">{{item.area.replace('司法局','')||''}}{{item.Street||''}}{{item.VillagesName}}</Col>
                    <Col span="3">{{item.LawyerName}}</Col>
                    <Col span="5" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{item.OrderTypeTitle||'留言咨询'}}</Col>
                  </Row>
                </div>
                <div style="height: 3.01rem;overflow-y:auto;overflow-x: hidden;" v-if="topList.selected == 'archives'">
                  <Row v-for="(item,index) in topList.archives" style="font-size: 0.2rem;width: 6.68rem;height: 0.52rem;line-height: 0.52rem;background: #F3F8FB;border-radius: 2px 2px 2px 4px;margin-bottom: 0.20rem">
                    <Col span="4">{{formatTopTime(item.FileTime)}}</Col>
                    <Col span="12" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;text-align: left">{{item.area.replace('司法局','')||''}}{{item.Street||''}}{{item.VillagesName}}</Col>
                    <Col span="3">{{item.LawyerName}}</Col>
                    <Col span="5" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{item.FileTypeName}}</Col>
                  </Row>
                </div>
                <div style="height: 3.01rem;overflow-y:auto;overflow-x: hidden;" v-if="topList.selected == 'duty'">
                  <Row v-for="(item,index) in topList.duty" style="font-size: 0.2rem;width: 6.68rem;height: 0.52rem;line-height: 0.52rem;background: #F3F8FB;border-radius: 2px 2px 2px 4px;margin-bottom: 0.20rem">
                    <Col span="4">{{formatTopTime(item.ClockInOutTime||item.ClockInTime)}}</Col>
                    <Col span="12" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;text-align: left">{{item.area.replace('司法局','')||''}}{{item.Street||''}}{{item.VillagesName}}</Col>
                    <Col span="3">{{item.LawyerName}}</Col>
                    <Col span="5" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;">{{item.ClockInOutTime?'值班结束':'开始值班'}}</Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
<!--         应用分析 -->
          <div style="width: 7.1rem;background: #FFFFFF;border-radius: 0.2rem;display: inline-block;margin-top: 0.2rem;font-size: 0;">
            <div style="text-align: left">
              <div style="margin: 0.4rem 0 0.3rem 0.2rem;text-align: left;display: inline-block">
                <div style="width: 0.1rem;height: 0.3rem;background: #2D5BED;border-radius: 0.02rem;display: inline-block;vertical-align: top;margin-right: 0.2rem"></div>
                <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.3rem">应用分析</div>
              </div>
              <div style="float: right;margin: 0.4rem 0.3rem 0.3rem 0.2rem;display: inline-block">
                <div @click="shouldAnalysisType = '1'" :class="shouldAnalysisType == '1'?'coreDataTrue':'coreDataFalse'" style="border-right: 0.01rem solid rgba(203, 211, 231, 1)">咨询</div>
                <div @click="shouldAnalysisType = '2'" :class="shouldAnalysisType == '2'?'coreDataTrue':'coreDataFalse'" style="border-right: 0.01rem solid rgba(203, 211, 231, 1)">查询</div>
                <div @click="shouldAnalysisType = '3'" :class="shouldAnalysisType == '3'?'coreDataTrue':'coreDataFalse'" style="border-right: 0.01rem solid rgba(203, 211, 231, 1)">申办</div>
                <div @click="shouldAnalysisType = '4'" :class="shouldAnalysisType == '4'?'coreDataTrue':'coreDataFalse'">7日趋势</div>
              </div>
            </div>
            <!--咨询统计图-->
            <div style="position: relative;margin-top: 0.6rem;margin-bottom: 0.3rem;" v-show="shouldAnalysisType == '1'">
              <div style="position: absolute;top: -0.22rem;left: 50%;transform: translate(-50%, 0)">
                <div class="realWord">咨询服务占比</div>
              </div>
              <div style="width: 6.7rem;height: 4.22rem;background: #FFFFFF;border: 0.01rem solid #E6E8EA;border-radius: 0.08rem;display: inline-block;">
                <div id="seekAdviceFrom" style="width: 6.57rem;height: 4rem;margin-top: 0.3rem"></div>
              </div>
            </div>
<!--            服务占比-->
            <div style="position: relative;margin-top: 0.6rem;margin-bottom: 0.3rem;" v-show="shouldAnalysisType == '2'">
              <div style="position: absolute;top: -0.22rem;left: 50%;transform: translate(-50%, 0)">
                <div class="realWord">查询服务占比</div>
              </div>
              <div style="width: 6.7rem;height: 4.90rem;background: #FFFFFF;border: 0.01rem solid #E6E8EA;border-radius: 0.08rem;display: inline-block;">
                <div id="queryService" style="width: 6.57rem;height: 4.7rem;margin-top: 0.3rem"></div>
              </div>
            </div>
<!--            申办服务占比-->
            <div style="position: relative;margin-top: 0.6rem;margin-bottom: 0.3rem;" v-show="shouldAnalysisType == '3'">
              <div style="position: absolute;top: -0.22rem;left: 50%;transform: translate(-50%, 0)">
                <div class="realWord">申办服务占比</div>
              </div>
              <div style="width: 6.7rem;height: 4.22rem;background: #FFFFFF;border: 0.01rem solid #E6E8EA;border-radius: 0.08rem;display: inline-block;">
                <div id="bidFrom" style="width: 6.57rem;height: 3.8rem;margin-top: 0.3rem"></div>
              </div>
            </div>
<!--            7日服务趋势-->
            <div style="position: relative;margin-top: 0.6rem;margin-bottom: 0.3rem;" v-show="shouldAnalysisType == '4'">
              <div style="position: absolute;top: -0.22rem;left: 50%;transform: translate(-50%, 0)">
                <div class="realWord">7日服务趋势</div>
              </div>
              <div style="width: 6.7rem;height: 4.22rem;background: #FFFFFF;border: 0.01rem solid #E6E8EA;border-radius: 0.08rem;display: inline-block;">
                <div style="width:6.7rem;height:3.76rem;margin-top: 0.3rem" ref="echarts-serveDate" class="inline-block"></div>
              </div>
            </div>
          </div>
          <!--各区县应用排行-->
          <div style="width: 7.1rem;background: #FFFFFF;border-radius: 0.2rem;display: inline-block;margin-top: 0.2rem;text-align: left;">
            <div style="margin: 0.4rem 0 0.3rem 0.2rem;text-align: left;display: inline-block">
              <div style="width: 0.1rem;height: 0.3rem;background: #2D5BED;border-radius: 0.02rem;display: inline-block;vertical-align: top;margin-right: 0.2rem"></div>
              <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.3rem">应用五色排行</div>
              <!-- <div style="display: inline-block;font-size: 0.26rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.3rem" v-if="information.Level == 3">各乡镇（街道）应用五色排行</div>
              <div style="display: inline-block;font-size: 0.26rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.3rem" v-if="information.Level == 4">各村（社区）应用五色排行</div> -->
            </div>
            <div style="margin: 0.4rem 0;display: inline-block;float: right;margin-right: 0.3rem">
              <div @click="countyType = 1" :class="countyType == 1?'coreDataTrue':'coreDataFalse'" style="border-right: 0.01rem solid rgba(203, 211, 231, 1)">用户</div>
              <div @click="countyType = 2" :class="countyType == 2?'coreDataTrue':'coreDataFalse'" style="border-right: 0.01rem solid rgba(203, 211, 231, 1)">服务</div>
              <div @click="countyType = 3" :class="countyType == 3?'coreDataTrue':'coreDataFalse'">档案</div>
            </div>
            <div style="width: 6.7rem;background: #FFFFFF;border-radius: 0.08rem;display: inline-block;">
              <div style="width:6.5rem;" ref="echarts-serveRanking" class="inline-block"></div>
            </div>
          </div>
<!--          -->
          <div style="padding:0.2rem;"></div>
          <div style="text-align: center;color: rgba(126, 126, 126, 1)">
            <div>重庆市司法局&nbsp;&nbsp;&nbsp;&nbsp;重庆市大数据发展局&nbsp;&nbsp;&nbsp;&nbsp;监制</div>
            <div>数字重庆公司&nbsp;&nbsp;&nbsp;&nbsp;重庆市律蛙科技公司&nbsp;&nbsp;&nbsp;&nbsp;支持</div>
          </div>
          <div style="padding:0.2rem;"></div>
<!--          -->
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: "dataCenter",
  data (){
    return{
      shouldAnalysisType : '1',
        time : '',
        statisticsData: [
          { name: '累计用户', value: 0, type: '1', img: '/img/h5/bigData/h5-house1.png' ,unit:'人',},
          { name: '总服务', value: 0, type: '2', img: '/img/h5/bigData/h5-house2.png',unit:'次', },
          { name: '电子档案', value: 0, type: '3', img: '/img/h5/bigData/h5-house3.png',unit:'份', },
          { name: '值班打卡', value: 0, type: '4', img: '/img/h5/bigData/h5-house4.png',unit:'次', },
          { name: '区县', value: 0, type: '5', img: '/img/h5/bigData/h5-house5.png',unit:'个', },
          { name: '乡镇(街道)', value: 0, type: '6', img: '/img/h5/bigData/h5-house6.png',unit:'个', },
          { name: '村(社区)', value: 0, type: '7', img: '/img/h5/bigData/h5-house7.png',unit:'个', },
          { name: '匹配法律顾问', value: 0, type: '8', img: '/img/h5/bigData/h5-house8.png',unit:'人', },
        ],
      information : [],
      topList:{
        consulting:[],//咨询服务
        archives:[],//档案
        duty:[],//值班
        selected:'consulting',
      },
    //   服务占比图
      serviceProportionData : {
        seekAdviceFrom : []
      },
      // 7日服务数据
      echartsServeData:{
        tooltip: {//提示框组件
          trigger: 'axis'//触发类型:坐标轴触发
        },
        grid:{//直角坐标系
          top:'5%',
          left:'3%',
          right:'4%',
          bottom:'3%',
          containLabel:true,//设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {//x轴
          boundaryGap:false,//坐标轴两边留白策略
          type: 'category',//类目轴
          data: ['加载中', '加载中', '加载中', '加载中', '加载中', '加载中', '加载中'],
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#999",
            }
          },
        },
        yAxis: {//y轴
          type: 'value',//数值轴
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#999",
            },
          },
        },
        series: [{//折线/面积图
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: 'line',//折线图
          smooth: true,//是上色折现覆盖区域
          areaStyle: {},
          lineStyle: {//折线样式
            width:4,
          },
          color:{//色彩渐变
            type: 'linear',//线性渐变
            x:0,
            y:1,
            x2:1,
            y2:0,
            colorStops: [
              {
                offset:0,
                color: '#008aff' //蓝色
              },
              {
                offset:1,
                color: '#f950ff' //紫色
              },
            ],
          },
        }],
      },
      serviceDate:{identification: 'a',startTime: '',endTime: '',},//服务时间
      useData:[//使用数据
        {name:'数据载入中',consultingCount:'.....',userCount:'.....'}
      ],
      echartsServeRanking:{
        tooltip: {//提示框组件
          trigger: 'axis',//触发类型:坐标轴触发
          axisPointer: {
            type: 'shadow',
          },
        },
        grid:{//直角坐标系
          top:'2%',
          left:'3%',
          right:'4%',
          bottom:'3%',
          containLabel:true,//设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {//x轴
          type: 'value',//数值轴
          minInterval : 2,
          boundaryGap : [ 0, 0.5 ],
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#999",
            }
          },
          splitLine: {//取消x轴刻度
            show: false
          },
        },
        yAxis: {//y轴
          type: 'category',//类目轴
          data: [],
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#999",
              background : "#E1E9FE",
            },
          },
        },
        series: [//折线/面积图
          {
            data: [],
            showBackground: true,
            backgroundStyle: {//设置背景样式
              color: "#E1E9FE",
              barBorderRadius: 10,
            },
            type: 'bar',//柱状图
            barWidth : 12,//柱宽度
            // color:{//柱子颜色渐变
            //   type: 'linear',//线性渐变
            //   x:0,
            //   y:1,
            //   x2:1,
            //   y2:0,
            //   colorStops: [
            //     {
            //       offset:1,
            //       color: 'red' //蓝色
            //     },
            //   ],
            // },
            itemStyle:{//柱子的样式
              // barBorderRadius:[5,5,5,5],
              normal: {

              },
            },
            label: {

            },
            // label: {
            //   show: true, //开启显示数值
            //   position: 'right', //数值在上方显示
            //   textStyle: {  //数值样式
            //     color: '#2D5BED',   //字体颜色
            //     fontSize: 10  //字体大小
            //   }
            // }
          }
        ],
      },
      countyType : '1',
    }
  },
  watch:{
    'topList.selected':function(value){
      switch(value){
        case 'consulting':this.getConsulting();break;
        case 'archives':this.getArchives();break;
        case 'duty':this.getDuty();break;
      }
    },
    'serviceDate.identification': function (val) {
      this.coreDatascreen()
    },
    'countyType': function (val) {
      this.districtAndCountyData()
    },
    // 'shouldAnalysisType':function(value){
    //   switch(value){
    //     case '1':this.seekAdviceFrom();break;
    //     case '2':this.detailsSee();break;
    //     case '3':this.bidGiveServiceTo();break;
    //     case '4':this.getServeData();break;
    //   }
    // },
  },
  mounted() {
    var _this = this
    this.information = window.sessionStorage
    // 今日咨询
    this.coreDatascreen()
    .then(res=>{
      //   核心数据
      this.coreData()
      // 实时数据
      this.getConsulting()
      //   咨询服务占比
      this.seekAdviceFrom()
      // 查询服务占比
      this.detailsSee()
      // 申办占比
      this.bidGiveServiceTo()
      // 服务趋势
      this.getServeData()
      // 各区县应用排行
      this.areaRank()
    })

    setInterval(function (){_this.currentTime()},1000)
    setInterval(function(){
      _this.cheackToken()
    },300000)
  },
  methods : {
    introduction : function (){
      window.open('https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/pdf/%E2%80%9C%E9%87%8D%E5%BA%86%E6%9D%91%E5%B1%85%E6%B3%95%E5%8A%A1%E2%80%9D%E5%B9%B3%E5%8F%B0%E7%9A%84%E5%AE%9A%E5%88%B6%E5%A2%9E%E5%80%BC%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE%E4%BB%8B%E7%BB%8DV2.pdf');
    },
    introduction1 : function (url){
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
    },
    districtAndCountyData : function (){
      let _this = this
      _this.echartsServeRanking.series[0].data = []
      _this.echartsServeRanking.yAxis.data = []
      if(_this.countyType == 1){
        var label = '人'
        _this.useData.sort((item,item1)=>{
          return item1.userCount-item.userCount
        })
      }
      if(_this.countyType == 2){
        var label = '次'
        _this.useData.sort((item,item1)=>{
          return item1.consultingCount-item.consultingCount
        })
      }
      if(_this.countyType == 3){
        var label = '份'
        _this.useData.sort((item,item1)=>{
          return item1.archivesCount-item.archivesCount
        })
      }
      console.log(_this.useData)
      //倒序输出
      for(let i=_this.useData.length-1;i>=0;i--){
        if(_this.countyType == 1){
          _this.echartsServeRanking.yAxis.data.push(_this.useData[i].name)
          _this.echartsServeRanking.series[0].data.push(_this.useData[i].userCount)
        }
        if(_this.countyType == 2){
          _this.echartsServeRanking.yAxis.data.push(_this.useData[i].name)
          _this.echartsServeRanking.series[0].data.push(_this.useData[i].consultingCount)
        }
        if(_this.countyType == 3){
          _this.echartsServeRanking.yAxis.data.push(_this.useData[i].name)
          _this.echartsServeRanking.series[0].data.push(_this.useData[i].archivesCount)
        }
      }

      _this.echartsServeRanking.series[0].label.normal =  {
        show: true, // 标签
        // 标签内容格式化
        formatter: function(data){
          if(data.value > 0){
            return data.value + label
          } else {
            return data.value
          }
        },
        position: 'right',
        color: '#000',
        fontSize : 12
      },
          _this.echartsServeRanking.series[0].itemStyle.normal = {
            color: function(params) {
              var colorList = ['#C51B00','#E4980D', '#F0F10D', '#3F92E6', '#42F462'];
              var dataIndex = params.dataIndex+1
              var index = dataIndex <= _this.useData.length/colorList.length*1 ? '0' :
                  dataIndex <= _this.useData.length/colorList.length*2 ? '1' : dataIndex <= _this.useData.length/colorList.length*3 ? '2' :
                      dataIndex <= _this.useData.length/colorList.length*4 ? '3' : dataIndex <= _this.useData.length/colorList.length*5 ? '4' : '4'
              return colorList[index]
            },
            barBorderRadius: 5
          }
      echarts.init(_this.$refs['echarts-serveRanking']).setOption(_this.echartsServeRanking)
    },
    districtAndCounty : function (){
      let _this = this
      _this.districtAndCountyData()
      echarts.init(_this.$refs['echarts-serveRanking']).setOption(_this.echartsServeRanking);
      var autoHeight = _this.echartsServeRanking.yAxis.data.length * 30;
      echarts.init(_this.$refs['echarts-serveRanking']).getDom().style.height = autoHeight + "px";
      echarts.init(_this.$refs['echarts-serveRanking']).getDom().childNodes[0].style.height = autoHeight + "px";
      echarts.init(_this.$refs['echarts-serveRanking']).getDom().childNodes[0].childNodes[0].setAttribute("height", autoHeight);
      echarts.init(_this.$refs['echarts-serveRanking']).getDom().childNodes[0].childNodes[0].style.height = autoHeight + "px";
      echarts.init(_this.$refs['echarts-serveRanking']).resize();
      echarts.init(_this.$refs['echarts-serveRanking']).setOption(_this.echartsServeRanking)
    },
    // 各区县应用排行
    areaRank:function(){
      let _this = this
      _this.send({
        url: '/areaRank',
        data: {
          AdminId:window.sessionStorage.AdminId,
        },
        success: function (res) {
          _this.useData = []
          let tableList = JSON.parse(JSON.stringify(res))
          let echartsList = JSON.parse(JSON.stringify(res))
          //按用户数排序
          tableList.sort((item,item1)=>{
            return item1.userCount-item.userCount
          })
          //按咨询数排序
          echartsList.sort((item,item1)=>{
            return item1.orderCount-item.orderCount
          })
          //推送数据到表格
          for(let index in tableList){
            _this.useData.push({
              name:tableList[index].AdminName,
              consultingCount:tableList[index].orderCount,
              userCount:tableList[index].userCount,
              archivesCount:tableList[index].fileCount,
              promotionCount:tableList[index].promotionCount,
            })
          }
          if(_this.$refs['echarts-serveRanking']){
            _this.districtAndCounty()
          }
        }
      })
    },
    coreDatascreen : function (){
      var _this = this
      return new Promise((resolve,reject)=>{
        _this.send({
          url: '/OrganizationCountUser',
          data: {
            AdminId: _this.information.AdminId,
            typeId: 4,
            start_time: '', //开始时间
            end_time: '',
            Type: _this.serviceDate.identification,
          },
          success: function (res) {
            _this.statisticsData[0].value = res.count
            resolve()
          }
        })
        _this.send({
          url: '/OrganizationCountOrder',
          data: {
            AdminId: _this.information.AdminId,
            typeId: 4,
            start_time: '', //开始时间
            end_time: '',
            Type: _this.serviceDate.identification,
          },
          success: function (res) {
            _this.statisticsData[1].value = res.count
            resolve()
          }
        })
        _this.send({
          url: '/OrganizationCountFile',
          data: {
            AdminId: _this.information.AdminId,
            typeId: 4,
            start_time: '', //开始时间
            end_time: '',
            Type: _this.serviceDate.identification,
          },
          success: function (res) {
            _this.statisticsData[2].value = res.count
            resolve()
          }
        })
        _this.send({
          url: '/OrganizationCountClock',
          data: {
            AdminId: _this.information.AdminId,
            typeId: 4,
            start_time: '', //开始时间
            end_time: '',
            Type: _this.serviceDate.identification,
          },
          success: function (res) {
            _this.statisticsData[3].value = res.count
            resolve()
          }
        })
      })

    },
    coreData : function (){
      var _this = this
      _this.send({
        url: '/GetIndexCount',
        data: {
          AdminId: _this.information.AdminId,
          typeId: 4,
          start_time: '', //开始时间
          end_time: ''
        },
        success: function (res) {
          if(_this.information.Level == 3 || _this.information.Level == 4) {
            _this.statisticsData[4].value = -1
          }else {
            _this.statisticsData[4].value = res.count
          }

        }
      })
      _this.send({
        url: '/streetCount',
        data: {
          AdminId: _this.information.AdminId,
          typeId: 4,
          start_time: '', //开始时间
          end_time: ''
        },
        success: function (res) {
          if(_this.information.Level == 4) {
            _this.statisticsData[5].value = -1
          }else {
            _this.statisticsData[5].value = res
          }

        }
      })
      _this.send({
        url: '/OrganizationCountVillage',
        data: {
          AdminId: _this.information.AdminId,
          typeId: 4,
          start_time: '', //开始时间
          end_time: ''
        },
        success: function (res) {
          _this.statisticsData[6].value = res.count
        }
      })
      _this.send({
        url: '/OrganizationCountLawyer',
        data: {
          AdminId: _this.information.AdminId,
          typeId: 4,
          start_time: '', //开始时间
          end_time: ''
        },
        success: function (res) {
          _this.statisticsData[7].value = res.count
        }
      })
    },
    // 申办服务占比
    bidGiveServiceTo : function (){
      var _this = this
      var datas =
          [
            { name: '法律援助', value: 0 ,itemStyle: { color: 'rgba(10, 195, 229, 1)' }},
            { name: '公证预约', value: 0 ,itemStyle: { color: 'rgba(58, 173, 122, 1)' }},
            { name: '人民调解', value: 0 ,itemStyle: { color: 'rgba(239, 181, 42, 1)' }},
          ]
      var bid1 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/UserLegalCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: '',
            start_time: '', //开始时间
            end_time:''
          },
          success: function (res) {
            datas[0].value = res.count
            resolve(res);
          }
        })
      })
      var bid2 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/UserHandleCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: '',
            start_time: '', //开始时间
            end_time:''
          },
          success: function (res) {
            datas[1].value = res.count
            resolve(res);
          }
        })
      })
      var bid3 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/UserMediateCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: '',
            start_time: '', //开始时间
            end_time:''
          },
          success: function (res) {
            datas[2].value = res.count
            resolve(res);
          }
        })
      })
      Promise.all([ bid1,bid2,bid3 ]).then(res => {
        // 当this.getData1(), this.getData2()二个函数成功之后才会打印 res
        let bidFrom = echarts.init(document.getElementById('bidFrom'))
        bidFrom.setOption({
          legend: {
            top: '0%',
            left: 'center',
          },
          series : [
            {
              type: 'pie',
              radius: '80%',
              left: 'center',
              top : '10%',
              width: document.body.clientWidth+100,
              height : '90%',
              // itemStyle: {
              //   borderColor: '#fff',
              //   borderWidth: 5
              // },
              label: {
                alignTo: 'edge',
                formatter: '({d}%)\n{b}',
                minMargin: 50,
                edgeDistance: -0,
                lineHeight: 15,
                rich: {
                  time: {
                    fontSize: 10,
                    color: '#999'
                  }
                },
              },
              labelLine: {
                normal:{
                  length:'5%',
                  distanceToLabelLine : 50
                }
              },
              data: datas
            }
          ]
        });
      })
    },

    // 咨询服务占比
    seekAdviceFrom : function (){
      var _this = this
      var datas =
        [
          { name: '电话咨询', value: 0 ,itemStyle: { color: 'rgba(252, 186, 98, 1)' }},
          { name: '视频咨询', value: 0 ,itemStyle: { color: 'rgba(249, 63, 0, 1)' }},
          { name: '自助咨询', value: 0 ,itemStyle: { color: 'rgba(25, 136, 231, 1)' }},
          { name: '留言咨询', value: 0 ,itemStyle: { color: 'rgba(58, 173, 122, 1)' }},
        ]
      ;
      // 留言咨询
      var advocate1 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/UserOrderCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime
          },
          success: function (res) {
            datas[3].value = res.count
            resolve(res);
          }
        })
      })
      // 自助咨询
      var advocate2 = new Promise((resolve, reject) => {
        _this.send({
          url: '/zizhuUserCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime
          },
          success: function (res) {
            datas[2].value = res.count
            resolve(res);
          }
        })
      })
      //   视频咨询
      var advocate3 = new Promise((resolve, reject) => {
        _this.send({
          url: '/UserVideoCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime
          },
          success: function (res) {
            datas[1].value = res.count
            resolve(res);
          }
        })
      })
      //   电话咨询
      var advocate4 = new Promise((resolve, reject) => {
        _this.send({
          url: '/UserCallCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 1
          },
          success: function (res) {
            datas[0].value = res.Count
            resolve(res);
          }
        })
      })
      Promise.all([ advocate1,advocate2,advocate3,advocate4 ]).then(res => {
        // 当this.getData1(), this.getData2()二个函数成功之后才会打印 res
        const labelItemArr = [];
        for (let i = 0; i < 100; ++i) {
          labelItemArr.push({
            value: 1,
            itemStyle: { normal: { color: '#ccc' } }
          }, {
            value: 3,
            name: '',
            itemStyle: {
              normal: {
                label: { show: false },
                labelLine: { show: false },
                color: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(0, 0, 0, 0)',
                borderWidth: 0
              }
            }
          });
        }
        let seekAdviceFrom = echarts.init(document.getElementById('seekAdviceFrom'))
        seekAdviceFrom.setOption({
          legend: {
            top: '0%',
            left: 'center',icon: "circle",
            textStyle: {
              fontSize: 10,
            },
          },
          series : [
            {
              type: 'pie',
              radius: ['80%', '60%'],
              left: 'center',
              top : '8%',
              width: document.body.clientWidth+100,
              height : '90%',
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 5
              },
              label: {
                alignTo: 'edge',
                formatter: '({d}%)\n{b}',
                minMargin: 50,
                edgeDistance: 50,
                lineHeight: 15,
                rich: {
                  time: {
                    fontSize: 10,
                    color: '#999'
                  }
                },
              },
              labelLine: {
                normal:{
                  length:'5%',
                  distanceToLabelLine : 50
                }
              },
              data: datas
            },

            {
              hoverAnimation: false,
              type: 'pie',
              z: 2,
              data: labelItemArr,
              radius: ['85%', '60%'],
              top : '6%',
              zlevel: -2,
              label: {
                normal: {
                  position: 'inside',
                  show: false,
                }
              },
            },
          ]
        });
      })
    },
    // 查询服务占比
    detailsSee : function () {
        var _this = this
        var datas =
            [
              { name: '模拟判决', value: 0 ,itemStyle: { color: 'rgba(25, 136, 231, 1)' }},
              { name: '文书下载', value: 0 ,itemStyle: { color: 'rgba(142, 118, 246, 1)' }},
              { name: '企业征信', value: 0 ,itemStyle: { color: 'rgba(29, 218, 158, 1)' }},
              { name: '法律法规库', value: 0 ,itemStyle: { color: 'rgba(36, 188, 106, 1)' }},
              { name: '司法案例库', value: 0 ,itemStyle: { color: 'rgba(250, 141, 114, 1)' }},
              { name: '民法典', value: 0 ,itemStyle: { color: 'rgba(230, 226, 103, 1)' }},
              { name: '网上立案', value: 0 ,itemStyle: { color: 'rgba(43, 180, 205, 1)' }},
              { name: '学法用法', value: 0 ,itemStyle: { color: 'rgba(25, 107, 231, 1)' }},
              { name: '法律服务地图', value: 0 ,itemStyle: { color: 'rgba(25, 136, 231, 1)' }},
            ]
        // 模拟判决
        var query1 = new Promise((resolve, reject) => {
          _this.send({
            url: '/UserDecisionList',
            data: {
              AdminId: _this.information.AdminId,
              start_time: '',
              end_time: '',
              page: 1,
              pageSize: 1,
              VillagesId: '',
              nameKeyId: ''
            },
            success: function (res) {
              datas[0].value = res.count
              resolve(res);
            }
          })
        })
      // 文书下载
      var query2 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/UserWritList',
          data: {
            AdminId: _this.information.AdminId,
            start_time: '',
            end_time: '',
            page: 1,
            pageSize: 1,
            VillagesId: '',
            nameKeyId: ''
          },
          success: function (res) {
            datas[1].value = res.count
            resolve(res);
          }
        })
      })
      // 企业征信
      var query3 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/InvestigationList',
          data: {
            AdminId: _this.information.AdminId,
            start_time: '',
            end_time: '',
            page: 1,
            pageSize: 1,
            VillagesId: '',
            nameKeyId: ''
          },
          success: function (res) {
            datas[2].value = res.Count
            resolve(res);
          }
        })
      })
      // 法律法规库
      var query4 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/RegulationsList',
          data: {
            AdminId: _this.information.AdminId,
            start_time: '',
            end_time: '',
            page: 1,
            pageSize: 1,
            VillagesId: '',
            nameKeyId: ''
          },
          success: function (res) {
            datas[3].value = res.Count
            resolve(res);
          }
        })
      })
      // 司法案例库
      var query5 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/JudicialCaseList',
          data: {
            AdminId: _this.information.AdminId,
            start_time: '',
            end_time: '',
            page: 1,
            pageSize: 1,
            VillagesId: '',
            nameKeyId: ''
          },
          success: function (res) {
            datas[4].value = res.Count
            resolve(res);
          }
        })
      })
      // 民法典
      var query6 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/CivilCodeList',
          data: {
            AdminId: _this.information.AdminId,
            start_time: '',
            end_time: '',
            page: 1,
            pageSize: 1,
            VillagesId: '',
            nameKeyId: ''
          },
          success: function (res) {
            datas[5].value = res.Count
            resolve(res);
          }
        })
      })
      // 网上立案
      var query7 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/OnRegistrationList',
          data: {
            AdminId: _this.information.AdminId,
            start_time: '',
            end_time: '',
            page: 1,
            pageSize: 1,
            VillagesId: '',
            nameKeyId: ''
          },
          success: function (res) {
            datas[6].value = res.Count
            resolve(res);
          }
        })
      })
      // 学法用法
      var query8 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/MethodUseList',
          data: {
            AdminId: _this.information.AdminId,
            start_time: '',
            end_time: '',
            page: 1,
            pageSize: 1,
            VillagesId: '',
            nameKeyId: ''
          },
          success: function (res) {
            datas[7].value = res.Count
            resolve(res);
          }
        })
      })
      // 法律服务地图
      var query9 =  new Promise((resolve, reject) => {
        _this.send({
          url: '/ServiceMapList',
          data: {
            AdminId: _this.information.AdminId,
            start_time: '',
            end_time: '',
            page: 1,
            pageSize: 1,
            VillagesId: '',
            nameKeyId: ''
          },
          success: function (res) {
            datas[8].value = res.Count
            resolve(res);
          }
        })
      })
      Promise.all([ query1,query2,query3,query4,query5,query6,query7,query8,query9 ]).then(res => {
        datas.sort((item,item1)=>{
          return item1.value-item.value
        })
        let queryService = echarts.init(document.getElementById('queryService'))
        queryService.setOption({
          legend: {
            top: 'center',
            left: 'right',
            icon: "circle",
            orient: 'vertical',
            formatter: function (name) {
              let total = 0
              let tarValue
              for (let i = 0; i < datas.length; i++) {
                total += datas[i].value
                if (datas[i].name == name) {
                  tarValue = datas[i].value
                }
              }
              //计算出百分比
              let p = Math.round((tarValue / total) * 1000)/10 + '%'
              return `${name}  ${p} `
              //name是名称，v是数值
            }
          },
          series : [
            {
              type: 'pie',
              radius: ['80%', '70%'],
              left: '-45%',
              top : '0%',
              width: document.body.clientWidth+100,
              height : '90%',
              label: {
                show: false
              },
              labelLine: {
                show: false
              },
              data: datas
            }
          ]
        });
      })
    },
    // 7日服务数据
    getServeData:function(){
      let _this = this
      _this.send({
        url: '/GetTenData',
        data: {
          AdminId: window.sessionStorage.AdminId,
        },
        success: function (res) {
          _this.echartsServeData.series[0].data = []
          _this.echartsServeData.xAxis.data = []
          for(var i=res.list.day.length-1;i>=0;i--) {
            if(i > 6){
              continue
            }
            let count = 0
            for(let index in res.list.day[i]){
              if(index=='time'){continue}
              count += parseInt(res.list.day[i][index])
            }
            _this.echartsServeData.xAxis.data.push(res.list.day[i].time.substring(5,res.list.day[i].time.length))
            _this.echartsServeData.series[0].data.push(count)
          }
          if(_this.$refs['echarts-serveDate']){
            echarts.init(_this.$refs['echarts-serveDate']).setOption(_this.echartsServeData)
          }
        }
      })
    },
    // 今日数据时间
    formatTopTime:function(time){
      time = time+'000'
      let date = new Date(parseInt(time))
      return (date.getHours()<10?'0'+date.getHours():date.getHours())+':'+(date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes())
    },
    //咨询Top30
    getConsulting:function(){
      let _this = this
      _this.send({
        url:'/topCount',
        data:{Count:6},
        loading:false,
        success:function(res){
          _this.topList.consulting = res
        }
      })
    },
    //档案Top30
    getArchives:function(){
      let _this = this
      _this.send({
        url:'/topFileCount',
        data:{Count:6},
        loading:false,
        success:function(res){
          _this.topList.archives = res
        }
      })
    },
    //值班Top30
    getDuty:function(){
      let _this = this
      _this.send({
        url:'/topDutyCount',
        data:{Count:6},
        loading:false,
        success:function(res){
          _this.topList.duty = res
        }
      })
    },
    // 获取当前时间
    currentTime : function (){
      let time = new Date();
      var hours =  time.getHours() < 10 ?('0'+time.getHours()):time.getHours()
      var minutes = time.getMinutes() < 10 ?('0'+time.getMinutes()):time.getMinutes()
      var seconds = time.getSeconds() < 10 ?('0'+time.getSeconds()):time.getSeconds()
      var getMonth = time.getMonth()+1
      var a = new Array("日", "一", "二", "三", "四", "五", "六");
      var week = new Date().getDay();
      this.time = (time.getFullYear()+'年'+getMonth+'月'+time.getDate()+'日'+"&emsp;周"+a[week]+'&emsp;'+hours+':'+minutes+':'+seconds)
    },
    cheackToken : function(){
      var _this = this
      _this.send({
        url:"/CheackToken",
        data:{
          Token : window.sessionStorage.Token,
        },
        success: function (res) {
          console.log(res)
        }
      })
    },
    realTime : function (){
      if(this.topList.selected == 'consulting'){
        this.$router.push({ name: 'consultingService' })
      }
      if(this.topList.selected == 'archives'){
        this.$router.push({ name: 'MAL' })
      }
      if(this.topList.selected == 'duty'){
        this.$router.push({ name: 'punchIn' })
      }

    },
  }
}
</script>

<style scoped>
.realWord{width: 2rem;height: 0.44rem;background: #2D5BED;border-radius: 0.22rem;font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.44rem;text-align: center;display: inline-block}
.realTrue{width: 2rem;height: 0.44rem;background: #2D5BED;font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.44rem;text-align: center;display: inline-block}
.realFalse{width: 2rem;height: 0.44rem;background: #E1E9FE;border: 1px solid #E6E8EA;font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: rgba(130, 138, 162, 1);line-height: 0.44rem;text-align: center;display: inline-block}
.coreDataTrue{width: 0.86rem;height: 0.34rem;background: #2D5BED;border-radius: 0.02rem 0px 0px 0.02rem;font-size: 0.20rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.34rem;display: inline-block;text-align: center;}
.coreDataFalse{width: 0.86rem;height: 0.34rem;background: #E1E9FE;font-size: 0.20rem;font-family: PingFang SC;font-weight: 500;color: rgba(130, 138, 162, 1);line-height: 0.34rem;display: inline-block;text-align: center;}
</style>
