<template>
  <div>
    <Row type="flex" justify="space-around" style="background-color: #EAEAEA;">
      <Col span="24" style="background-color: #FFF">
        <div class="header">
          <div>
            <img src="/img/icon/video.png" style="vertical-align: top;margin: 19px 14px 0 20px">
            <div style="display: inline-block;font-size: 14px;color: #333333;line-height: 70px;">视频</div>
          </div>
          <div>
            <Button @click="openTypeModal" type="primary" icon="ios-pricetags-outline" style="margin: 17px 18px">类型</Button>
            <Button @click="videoShow = true" type="primary" icon="ios-cloud-upload-outline" style="float: right;margin: 17px 18px">上传</Button>
          </div>
        </div>
        <Row  v-for="(value,index) in videoList" style="height: 180px;background: #FFF;margin-bottom: 20px">
          <Col span="8" style="text-align: center">
            <img :src="value.Picture"
                 style="width: 180px;height: 140px;background: #FFF;border: 6px solid rgba(255, 255, 255, 0.12);box-shadow: 0px 2px 12px 0px rgba(4, 0, 0, 0.1);border-radius: 4px;margin: 20px 0px 20px 0px">
          </Col>
          <Col span="12">
            <div style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 14px;vertical-align: top;margin:40px 0 20px;overflow: hidden;text-overflow: ellipsis;-webkit-box-orient: vertical;-webkit-line-clamp: 4;display: -webkit-box;">
              {{value.LawsVideoTitle}}</div>
            <div style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 14px;">
              上传时间：{{value.created_at}}
            </div>
            <div v-if="value.law_video_tags" style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 14px;margin-top: 10px">
              标签：{{value.law_video_tags.TagName}}
            </div>
            <div v-if="value.law_video_type" style="font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 14px;margin-top: 10px">
              类型：{{value.law_video_type.TypeName}}
            </div>
          </Col>
          <Col span="4" style="text-align: center">
            <!--                          <img style="width: 14px;height: 16px;vertical-align: top;margin-top: 82px" src="/img/icon/deleteImg.png">-->
            <div @click="fileModify(value)" style="line-height: 180px;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #999999;display: inline-block;cursor: pointer;">修改</div>
            <div @click="openLeaveMessageModal(value)" style="margin-left: 10px; line-height: 180px;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #999999;display: inline-block;cursor: pointer;">留言列表</div>
          </Col>
        </Row>

        <Page :total="count" :page-size="pageSize" show-elevator show-total
              @on-change="page = $event;lawVideoList()" style="margin: 20px"/>
      </Col>
    </Row>

    <!--    videoShow-->
    <Modal v-model="videoShow" title="上传视频" @on-ok="uploadFile()" :loading="loading" @on-cancel="closeFile" ok-text="上传" style="height: 300px" draggable
    >
      <Form :label-width="80">
        <FormItem label="视频标题" required>
          <Input v-model="videoData.LawsVideoTitle" maxlength="300" show-word-limit placeholder="视频标题" style="margin-bottom: 20px"/>
        </FormItem>
        <FormItem label="类型">
          <!--      类型-->
          <Select v-model="videoData.TypeId" placeholder="请选择类型" clearable filterable style="margin-bottom: 20px">
            <Option v-for="item in lawVideoTypeData" :value="item.TypeId">{{ item.TypeName }}</Option>
          </Select>
        </FormItem>
        <FormItem label="视频" required>
          <!--      视频原件-->
          <div class="upload" v-if="videoShow">上传视频
            <input class="change" ref="input" type="file" @change="handleBeforeUpload" accept=".mp4">
          </div>
          <div style="width: 100%;overflow:hidden;" v-if="videoData.LawsVideoUrl != ''">{{videoData.LawsVideoUrl[0].name.substring(videoData.LawsVideoUrl[0].name.lastIndexOf('/')+1)}}</div>
        </FormItem>
        <FormItem label="视频封面" required>
          <!--      视频封面-->
          <Upload
              type="drag"
              :action="information.url+'api/upload_file_H5'"
              　　:on-success="Picture" :paste="true" accept="image/gif,image/jpeg,image/png,image/jpg,image/bmp"
              :default-file-list="videoData.Picture" style="margin-bottom: 20px">
            <div style="padding: 20px 0" >
              <div v-if="videoData.Picture != ''">
                <img style="width: auto;height: 150px;" :src="videoData.Picture[0].url">
              </div>
              <div v-else>视频封面</div>
            </div>
          </Upload>
        </FormItem>
        <FormItem label="标签">
          <!--      标签-->
          <Select v-model="videoData.TagId" placeholder="请选择标签" clearable filterable style="margin-bottom: 20px">
            <Option v-for="item in lawVideoTagData" :value="item.TagId">{{ item.TagName }}</Option>
          </Select>
        </FormItem>
        <FormItem label="视频来源">
          <Input v-model="videoData.LawsVideoSource" maxlength="300" show-word-limit placeholder="视频来源" style="margin-bottom: 20px"/>
        </FormItem>
      </Form>






    </Modal>

    <!-- 标签类型弹窗 -->
    <Modal v-model="isTypeShow" title="类型" ok-text="关闭" cancel-text="">
      <!-- 新增 -->
      <div style="margin-bottom: 20px;">
        <Button type="primary" @click="modifyAndAddType(1)">新增</Button>
      </div>

      <!--  -->
      <Table :columns="typeTableHead" :data="lawVideoTypeData" ></Table>

    </Modal>

    <!-- 标签类型修改新增弹窗 -->
    <Modal width="400" v-model="typeAddAndModify" :title="`${typeAddAndModifyStatus == 1 ? '添加' : '修改'}类型`" @on-ok="submitTypeInfo">
      <Input type="text" v-model="modifyAndAddTypeItemData.TypeName" placeholder="请输入类型内容" maxlength="20"></Input>
    </Modal>

    <!-- 视频留言弹窗 -->
    <Modal width="50%" v-model="leaveMessageModalStatus" title="留言列表" ok-text="关闭" cancel-text="">
      <!--  -->
      <Table :columns="leaveMessageListTableHead" :data="leaveMessageListInfo" stripe></Table>

      <!-- 分页 -->
      <div style="display: flex;width: 100%;justify-content: center;margin-top: 20px;">
        <Page :total="leaveMessageListTotal" :page-size="10" show-elevator show-total @on-change="page => getVideoIdOnLeaveMessageListInfo(currentLookVideoInfo, page)"></Page>
      </div>
    </Modal>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "videoUpload",
  data() {
    return {
      videoList:[],
      page : 1,
      pageSize : 10,
      count : 0,
      StudyId : '',
      videoShow : false,
      //  上传视频
      videoData : {
        LawsVideoId : '',
        LawsVideoTitle : '',
        LawsVideoSource : '',
        LawsVideoUrl : [],
        Picture : [],
        TagId : '',
        TypeId : '',
      },
      lawVideoTagData : [],
      lawVideoTypeData : [],
      information : [],
      loading : true,
      // 标签类型弹窗控制
      isTypeShow: false,
      // 类型 添加/修改 弹窗
      typeAddAndModify: false,
      // 类型修改添加标识
      // 1 增加； 2 修改
      typeAddAndModifyStatus: 1,
      // 标签类型表头
      typeTableHead: [
        {
          title: 'ID',
          key: 'TypeId',
          align: 'center'
        }, {
          title: '名称',
          key: 'TypeName',
          align: 'center'
        }, {
          title: '操作',
          key: 'opreate',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'text'
                },
                on: {
                  click: (event) => this.modifyAndAddType (2, params)
                }
              }, '修改'),
            ])
          }
        }
      ],
      // 需要修改/添加项的详细内容
      modifyAndAddTypeItemData: {
        AdminId: '',
        TypeName: '',
        TypeId: '',
      },
      // 留言视频弹窗
      leaveMessageModalStatus: false,
      // 留言列表-表头
      leaveMessageListTableHead: [
        {
          title: 'ID',
          key: 'id',
          align: 'center'
        },{
          title: '视频标题',
          key: 'videoTitle',
          align: 'center',
          render: (h, params) => this.returnCurrentVideoTitle(h, params),
        },{
          title: '留言内容',
          key: 'message',
          align: 'center'
        },{
          title: '创建时间',
          key: 'created_at',
          align: 'center'
        }
      ],
      // 留言列表
      leaveMessageListInfo: [],
      // 当前查看留言的视频
      currentLookVideoInfo: {},
      // 留言列表总条数
      leaveMessageListTotal: 0,
      //
      //
    }
  },
  methods:{
    videoLabel : function(){
      var _this = this
      // LawVideoTagList-LawVideoTypeList
      _this.send({
        url: '/LawVideoTagList',
        data: {
          AdminId: _this.information.AdminId,
        },
        success: function (res) {
          _this.lawVideoTagData = res
        }
      })

      // 获取视频类型列表
      this.getVideoTypeListInfo ()

    },
    handleBeforeUpload : function (file){
      var _this = this;
      _this.$Spin.show();
      var param = new FormData(); //创建form对象
      console.log(file.target.files[0])
      param.append('upfile', file.target.files[0]);//通过append向form对象添加数据
      // param.append('MenuId', _this.menuId);
      var config = {}
      axios.post(_this.information.url+'api/AliyunUploadVideo', param, config)
          .then(response => {
            console.log(response)
            _this.videoData.LawsVideoUrl =  [{url:response.data.Data.video,name:file.target.files[0].name}]
            _this.videoData.Picture = [{name:window.sessionStorage.url+response.data.Data.image,url:window.sessionStorage.url+response.data.Data.image}]
            _this.$Spin.hide();
          })
    },
    //上传视频源文件
    videoSourceFile : function (e){
      var _this = this
      _this.videoData.LawsVideoUrl = [{url:window.sessionStorage.url+e.Data.path,name:e.Data.name}]
    },
    closeFile : function (){
      var _this = this
      _this.videoData = {
        LawsVideoId : '',
        LawsVideoTitle : '',
        LawsVideoSource : '',
        LawsVideoUrl : [],
        Picture : [],
        TagId : '',
        TypeId : '',
      }
    },
    fileModify : function (value) {
      var _this = this
      _this.send({
        url: '/GetLawsVideoById',
        data: {
          LawsVideoId: value.LawsVideoId,
        },
        success: function (res) {
          console.log(res)
          _this.videoData = res
          if(res.LawsVideoUrl){
            _this.videoData.LawsVideoUrl = [{name:res.LawsVideoUrl,url:res.LawsVideoUrl}]
          }
          if(res.Picture){
            _this.videoData.Picture = [{name:res.Picture,url:res.Picture}]
          }
          _this.videoShow = true
        }
      })

    },
    messageWarningFn (text) {
      this.$Message.warning(text)
      setTimeout(() => {
        this.loading = false
        this.$nextTick(() => {
          this.loading = true
        })
      }, 500)
    },
    uploadFile : function (){
      var _this = this
      if(_this.videoData.LawsVideoTitle == ''){_this.messageWarningFn('请输入视频标题');return}
      if(_this.videoData.Picture == ''){_this.messageWarningFn('请上传视频封面');return}
      if(_this.videoData.LawsVideoUrl == ''){_this.messageWarningFn('请上传视频文件');return}
      _this.send({
        url: '/SaveLawVideo',
        data: {
          AdminId: _this.information.AdminId,
          LawsVideoTitle: _this.videoData.LawsVideoTitle,
          LawsVideoSource: _this.videoData.LawsVideoSource,
          Picture: _this.videoData.Picture[0].url,
          TagId : _this.videoData.TagId,
          TypeId : _this.videoData.TypeId,
          LawsVideoUrl : _this.videoData.LawsVideoUrl[0].url,
          LawsVideoId : _this.videoData.LawsVideoId,
        },
        success: function (res) {
          _this.StudyId = ''
          _this.videoData.LawsVideoUrl = []
          _this.videoData = {
            LawsVideoId : '',
            LawsVideoTitle : '',
            LawsVideoSource : '',
            LawsVideoUrl : [],
            Picture : [],
            TagId : '',
            TypeId : '',
          }
          _this.videoShow = false
          _this.lawVideoList()
        }
      })
    },
    Picture : function (e,file){
      var _this = this
      _this.videoData.Picture = [{url:window.sessionStorage.url+e.Data.path,name:e.Data.name}]
    },
    lawVideoList:function (){
      var _this = this
      _this.send({
        url: '/LawVideoList',
        data: {
          AdminId: _this.information.AdminId,
          page: _this.page,
          pageSize: _this.pageSize,
        },
        success: function (res) {
          _this.videoList = res.data
          _this.count = res.total
        }
      })
    },
    // 获取视频类型列表
    getVideoTypeListInfo () {
      this.send({
        url: '/LawVideoTypeList',
        data: {
          AdminId: this.information.AdminId,
        },
        success: response => {
          this.lawVideoTypeData = response
        }
      })
    },
    // 新增/修改视频类型-打开输入弹窗
    // type: 1 添加； 2 修改
    // itemData: 需要修改项的详情, 仅修改时存在值
    modifyAndAddType (type, itemData) {
      this.typeAddAndModify = true
      this.typeAddAndModifyStatus = type

      // 初始值设置
      this.modifyAndAddTypeItemData = {
        ...this.modifyAndAddTypeItemData,
        TypeName: '',
        TypeId: ''
      }

      // 判断是修改设置填充值
      if (type == 2) {
        const itemValueData = itemData.row
        this.modifyAndAddTypeItemData = {
          ...this.modifyAndAddTypeItemData,
          TypeName: itemValueData.TypeName,
          TypeId: itemValueData.TypeId
        }
      }
    },
    // 打开类型弹窗
    openTypeModal () {
      this.isTypeShow = true;

      // 获取 AdminId
      this.modifyAndAddTypeItemData.AdminId = this.information.AdminId

    },
    // 根据 添加/修改 的类型进行提交
    submitTypeInfo () {
      this.modifyAndAddTypeItemData.TypeName = this.modifyAndAddTypeItemData.TypeName.trim()

      if (this.modifyAndAddTypeItemData.TypeName.length <= 0){
        this.$Notice.warning({
            title: '温馨提示',
            desc: '请输入类型名称'
        })

        return
      }

      // 发送请求
      this.send({
        url: '/updateLawVideoType',
        data: this.modifyAndAddTypeItemData,
        success: response => {
          this.isTypeShow = false

          this.$Message.success(`${this.typeAddAndModifyStatus == 1 ? '添加' : '修改'}成功`)

          // 获取视频类型列表
          this.getVideoTypeListInfo ()
        }
      })
    },
    // 打开留言列表弹窗
    // valueData: 视频 itemData
    openLeaveMessageModal (valueData) {
      this.leaveMessageModalStatus = true
      this.currentLookVideoInfo = valueData
      this.leaveMessageListInfo = []
      this.leaveMessageListTotal = 0

      // 根据视频 ID 获取留言列表
      this.getVideoIdOnLeaveMessageListInfo(valueData)
    },
    // 根据视频 ID 获取留言列表
    // valueData: 视频 itemData
    getVideoIdOnLeaveMessageListInfo (valueData, page = 1, pageSize = 10) {
        this.send({
          url: '/lawVideoMsgList',
          data: {
            LawsVideoId: valueData.LawsVideoId,
            page: page,
            pageSize: pageSize
          },
          success: response => {
            this.leaveMessageListTotal = response.total
            this.leaveMessageListInfo = response.data
          }
        })
    },
    // 当查看留言列表时输出所查看的视频标题
    returnCurrentVideoTitle (h, params) {
      return h ('span', {}, this.currentLookVideoInfo.LawsVideoTitle || '--')
    },
    //
  },
  mounted() {
    this.information = window.sessionStorage
    this.lawVideoList()
    this.videoLabel()
  },
}
</script>

<style scoped>

.header {
    display: flex;
    justify-content: space-between;
    height: 70px;
    border-bottom: 1px solid #DDDDDD;
    font-family: PingFang SC;
    font-weight: bold;
}
.upload:hover{border: 1px dashed #4982f3;}
.upload{
  position: relative;    border: 1px dashed #dcdee2;
  text-decoration: none;
  color: #666;width: 100%;height: 70px;text-align: center;line-height: 70px;cursor: pointer;
}
.change{
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
  opacity: 0;cursor: pointer;    width: 100%;
  height: 70px;
}
</style>
