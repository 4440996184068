<template>
    <div>
        <div style="text-align: center;font-size: 0;border-bottom: 0.01rem solid #DFDFDF;padding-bottom: 0.3rem;">
            <img style="width:1.2rem;height: 1.2rem;margin-top: 0.4rem;" v-if="lawyerId && commentDetailinfor.lawyerImage!=null" :src="commentDetailinfor.lawyerImage">
            <img style="width:1.2rem;height: 1.2rem;margin-top: 0.4rem;" v-if="lawyerId && commentDetailinfor.lawyerImage==null" src="https://cj.lvwa18.com/img/caseInput/mrtx.png">
            <img style="width:1.2rem;height: 1.2rem;margin-top: 0.4rem;" v-if="lawOfficeId && commentDetailinfor.lawPhoto!=null" :src="commentDetailinfor.lawPhoto">
            <img style="width:1.2rem;height: 1.2rem;margin-top: 0.4rem;" v-if="lawOfficeId && commentDetailinfor.lawPhoto==null" src="https://cj.lvwa18.com/img/caseInput/lawFirm.png">
            <!--<img style="width:1.2rem;height: 1.2rem;margin-top: 0.4rem;"  :src="commentDetailinfor.lawPhoto||commentDetailinfor.lawyerImage">-->
            <div style="font-size: 0.28rem;font-family: PingFang SC;font-weight: 500;color: #333;margin-top: 0.3rem;line-height: 0.28rem">{{commentDetailinfor.lawyerName}}</div>
            <div style="font-size: 0.28rem;font-family: PingFang SC;font-weight: 500;color: #333;margin-top: 0.3rem;line-height: 0.28rem" v-if="lawOfficeId">{{commentDetailinfor.lawName}}</div>
            <div style="font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #999;margin-top: 0.2rem;line-height: 0.22rem" v-else>{{commentDetailinfor.lawName}}</div>
            <div style="margin: 0.2rem 0 0rem 0;" v-if="commentDetailinfor.lawyerStar||commentDetailinfor.lawStar">
                <img style="width: 0.24rem;height: 0.24rem;vertical-align: top;margin-right: 0.07rem;" src="/img/caseInput/pingfen.png">
                <div style="display: inline-block;font-size: 0.22rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.26rem;">{{commentDetailinfor.lawyerStar||commentDetailinfor.lawStar}}</div>
            </div>
        </div>
        <!--<div style="text-align: center;margin-top: 0.34rem;">-->
            <!--<div v-for="(value,index) in starData" style="display: inline-block">-->
                <!--<img style="width: 0.46rem;height: 0.46rem;vertical-align: top;margin-right: 0.07rem;" v-if="index+1 <= star" src="/img/caseInput/pingfent.png" @click="onStar(index+1)">-->
                <!--<img style="width: 0.46rem;height: 0.46rem;vertical-align: top;margin-right: 0.07rem;" v-else src="/img/caseInput/pingfenf.png" @click="onStar(index+1)">-->
            <!--</div>-->
            <!--<div style="font-size: 0.24rem;font-family: PingFang SC;font-weight: bold;color: #CCCCCC;line-height: 0.24rem;margin: 0.1rem 0 0.4rem 0;">如果满意请给五星好评哦</div>-->
        <!--</div>-->
        <!--<div style="text-align: center;font-size: 0;">-->
            <!--<textarea style="width: 6.4rem;height: 1.69rem;background: #fff;border-radius: 0.04rem;padding: 0.31rem 0 0 0.31rem;font-size: 0.28rem;font-weight: 500;border: 0.01rem solid rgba(223, 223, 223, 1)" placeholder="欢迎你写下你的评价" >-->
            <!--</textarea>-->
            <!--<div style="text-align: right">-->
                <!--<div @click="evaluate" style="width: 1.40rem;height: 0.64rem;background: #0694E6;border-radius: 10px;font-size: 0.28rem;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;text-align: center;line-height: 0.64rem;margin: 0.2rem 0.3rem 0 0;display: inline-block">-->
                    <!--发布评价-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <div style="text-align: center;font-size: 0;">
            <div v-for="(value,index) in commentDetailData" style="font-family: PingFang SC;margin: 0.3rem 0rem 0 0;width: 6.9rem;padding-bottom: 0.3rem;border-bottom: 0.01rem solid #DFDFDF;text-align: left;display: inline-block;" @click="caseDetails(value.caseId)">
                <img style="width: 0.76rem;height: 0.76rem;margin: 0rem 0.19rem 0 0" src="/img/caseInput/mrtx.png" v-if="value.userImage == null">
                <img style="width: 0.76rem;height: 0.76rem;margin: 0rem 0.19rem 0 0" :src="value.userImage" v-else>
                <div style="display: inline-block;vertical-align: top;width: 5.7rem;">
                    <div style="font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.24rem;">
                        <div style="display: inline-block;">{{value.userName}}</div>
                        <div style="display: inline-block;font-size: 0.2rem;float: right">{{value.time}}{{value.commentTime}}</div>
                    </div>
                    <img style="width:1.14rem;height: 0.18rem;margin: 0.2rem 0 0.19rem 0;"  :src="'/img/caseInput/score'+value.star+'.png'">
                    <div style="font-size: 0.28rem;font-family: PingFang SC;font-weight: 500;color: #666666;line-height: 0.36rem;">
                        {{value.comment}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "evaluate",
        data() {
            return {
                commentDetailData : [],
                commentDetailinfor : [],
                starData : [1,2,3,4,5],
                star : 5,
                lawOfficeId : '',
                lawyerId : ''
            }
        },
        methods :{
            caseDetails : function(caseId){
                var _this = this
                _this.$router.push({name: 'caseDetails', query: {caseId:caseId,qr:1}})
            },
            evaluate : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/evaluate',
                    data: {
                        userId  : window.sessionStorage.userId,
                    },
                    success: function (res) {
                        console.log(res)

                    }
                })
            },
            onStar : function(index){
                this.star = index
            },
            commentDetail : function(){
                var _this = this
                _this.send({
                    consulting : true,
                    url: '/commentDetail',
                    data: {
                        lawyerId : _this.$route.query.lawyerId || '',
                        lawOfficeId : _this.$route.query.lawOfficeId || '',
                    },
                    success: function (res) {
                        console.log(res)
                        if(res){
                            for(var i in res.List){
                                res.List[i].star = parseInt(res.List[i].star)
                                _this.commentDetailData.push(res.List[i])
                            }
                            _this.commentDetailinfor = res.infor
                        }
                    }
                })
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        },
        mounted () {
            var _this = this
            _this.setPageSize()
            _this.commentDetail()
            _this.lawOfficeId = _this.$route.query.lawOfficeId || ''
            _this.lawyerId = _this.$route.query.lawyerId || ''
        },

    }
</script>

<style scoped>

</style>

