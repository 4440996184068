<style scoped>
  .tableTitle{font-size:20px;}
  .export-btn{font-size:10px;color:#2d8cf0;cursor:pointer;}
</style>

<template>
  <div style="margin:10px 0px;">
    <span>统计时间：</span>
    <div style="display: inline-block;">
      <RadioGroup type="button" v-model="dateSelecter.identification" size="small" @on-change="singleChoice">
        <Radio label="m">本月</Radio>
        <Radio label="q">本季</Radio>
        <Radio label="y">本年</Radio>
        <Radio label="a">所有</Radio>
        <Radio label="custom">自定义</Radio>
      </RadioGroup>
      <DatePicker v-if="dateSelecter.identification=='custom'"
        @on-change="dateSelecter.startTime = $event[0],dateSelecter.endTime=$event[1]" @on-ok="callChildGetTable()"
        type="datetimerange" format="yyyy-MM-dd" placeholder="请选择时间区间"></DatePicker>
    </div>
    <div style="padding:5px"></div>
    <span>统计类型：</span>
    <div style="display: inline-block;">
      <RadioGroup type="button" v-model="tableSelected" size="small">
        <Radio label="areaTable">区域总统计</Radio>
        <Radio label="consultationAreaTable">区域咨询申办统计</Radio>
        <Radio label="regionLitongTable" v-if="chongqing">区域服务治理统计</Radio>
        <Radio label="regionalModuleTable" v-if="chongqing">区域模块访问统计</Radio>
        <Radio label="advisoryPresidentTable" v-if="chongqing">顾问总统计表</Radio>
        <Radio label="consultingLawyerTable">顾问咨询服务统计表</Radio>
        <Radio label="adviserGovernmentTable" v-if="chongqing">顾问村居治理服务统计表</Radio>
      </RadioGroup>
    </div>
    <div style="padding:5px"></div>
    <div v-if="tableSelected == 'areaTable'">
      <div v-if="adminLevel.AdminId == 165 || adminLevel.AdminId == 166">
        <span>统计单位：</span>
        <div style="display: inline-block;">
          <RadioGroup type="button" v-model="unitData" size="small">
            <Radio label="county">区县</Radio>
            <Radio label="street">乡镇（街道）</Radio>
          </RadioGroup>
        </div>
      </div>
    </div>
    <div>
      <!-- 区域总统计 -->
      <area-table v-if="tableSelected=='areaTable'" @exportCsv="exportCsv" :dateSelecter="dateSelecter" :unitData="unitData" ref="areaTable"></area-table>
      <!-- 区域咨询申办统计表 -->
      <consultation-area-table v-if="tableSelected=='consultationAreaTable'" @exportCsv="exportCsv" :dateSelecter="dateSelecter" ref="consultationAreaTable"></consultation-area-table>
      <!-- 顾问咨询服务统计表 -->
      <consulting-lawyer-table v-if="tableSelected=='consultingLawyerTable'" @exportCsv="exportCsv" :dateSelecter="dateSelecter" ref="consultingLawyerTable"></consulting-lawyer-table>
      <!-- 区域村居治理统计表 -->
      <region-litong-table v-if="tableSelected=='regionLitongTable'" @exportCsv="exportCsv" :dateSelecter="dateSelecter" ref="regionLitongTable"></region-litong-table>
      <!-- 顾问村居治理服务统计表 -->
      <adviser-government-table v-if="tableSelected=='adviserGovernmentTable'" @exportCsv="exportCsv" :dateSelecter="dateSelecter" ref="adviserGovernmentTable"></adviser-government-table>
      <!-- 区域模块访问统计表 -->
      <regional-module-table v-if="tableSelected=='regionalModuleTable'" @exportCsv="exportCsv" :dateSelecter="dateSelecter" ref="regionalModuleTable"></regional-module-table>
      <!-- 顾问总统计表 未对接 -->
      <advisory-president-table v-if="tableSelected=='advisoryPresidentTable'" @exportCsv="exportCsv" :dateSelecter="dateSelecter" ref="advisoryPresidentTable"></advisory-president-table>
    </div>
  </div>
</template>

<script>
//表头控制文件
import * as tableColumns from "./modules/table-columns"
import * as tableDataSorting from "./modules/data-sorting"
import areaTable from "./modules/area-table"
import consultationAreaTable from "./modules/consultation-area-table"
import consultingLawyerTable from "./modules/consulting-lawyer-table"
import regionalModuleTable from "./modules/regional-module-table"
import regionLitongTable from "./modules/region-litong-table"
import adviserGovernmentTable from "./modules/adviser-government-table"
import advisoryPresidentTable from "./modules/advisory-president-table"
export default {
  name:'governance',//村居治理
  data:function(){
    return{
      dateSelecter:{
        identification:'m',
        startTime:'',
        endTime:'',
      },
      tableSelected:'areaTable',
      unitData:'county',
      chongqing : false,
      adminLevel: []
    }
  },

  components:{
    'area-table':areaTable,
    'consultation-area-table':consultationAreaTable,
    'consulting-lawyer-table':consultingLawyerTable,
    'regional-module-table':regionalModuleTable,
    'region-litong-table':regionLitongTable,
    'adviser-government-table':adviserGovernmentTable,
    'advisory-president-table':advisoryPresidentTable,
  },
  mounted:function(){
    this.adminLevel = window.sessionStorage
    if(SYSCONFIG.location == 'chong-qing'){
      this.chongqing = true
    }else {
      this.chongqing = false
    }
  },
  methods:{
    //导出数据
    exportCsv:function(table){
      table.dom.exportCsv({
        filename:table.fileName,
        original: false,
      });
    },
    singleChoice : function (){
      this.dateSelecter.endTime = ""
      this.dateSelecter.startTime =  ""
    },
    callChildGetTable : function (){
      console.log(this.$refs['areaTable'])
      this.$refs[this.tableSelected].getTableData()
    },

    //顾问村居治理服务统计表
    //区域村居治理统计表 未对接
    //区域模块访问统计表 未对接
    //顾问总统计表 未对接

    /**
     * 请求队列集成器
     * 每个表需要调用5-7个接口，使用Promise异步队列处理
     * 调用方法：requestHub([接口数组]).then(Fun).catch(Fun)
     * 如API请求成功则Fun中返回resolve
     * 其中一个API失败自动抛出异常并Fun中返回reject
     */
    requestHub:function(APIList){
      var _this = this
      var requestList = []
      var resultList = []
      return new Promise((resolve,reject)=>{
        APIList.map((api,index)=>{
          requestList.push(new Promise((resolve1,reject1)=>{
            _this.send({
              url:api,//律师
              data:{
                StartTime:_this.dateSelecter.startTime,
                EndTime:_this.dateSelecter.endTime,
                Type:_this.dateSelecter.identification,
                AdminId : _this.dateSelecter.AdminId||window.sessionStorage.AdminId,
              },
              success:function(res){
                resultList[index]=res
                resolve1()
              },
              error:function(err){
                _this.$Notice.error({
                  title: '系统提示',
                  desc: '队列错误！请稍后再试',
                })
                reject1()
              }
            })
          }))
        })
        Promise.all(requestList)
        .then(res=>{resolve(resultList)})
        .catch(err=>{reject()})
      })
    },
  },
}
</script>
