import nullPage from '@/views/nullPage'

const TJ={
    statistics:require('@/views/mobile/dataStatistics/statistics').default,
    auditList:require('@/views/mobile/dataStatistics/auditList').default,
}

const routes = [
    {
        path:'TJ',
        component:nullPage,
        children:[
            {path:'statistics',name:'statistics',component:TJ.statistics,meta:{title:'数据中心-手机端'}},
            {path:'auditList',name:'auditList',component:TJ.auditList,meta:{title:'数据中心-手机端'}},
        ]
    },
]

export default {
    routes:routes
}