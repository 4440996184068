<style scoped>
.tableTitle{font-size:20px;}
.export-btn{font-size:10px;color:#2d8cf0;cursor:pointer;}
</style>

<template>
  <div>
    <div style="padding:10px"></div>
    <div style="text-align: center;">
      <span class="tableTitle">区域模块访问统计</span>
      <div class="export-btn" @click="exportData(table.data)">[导出数据]</div>
    </div>
    <div style="padding:5px"></div>
    <Table height="500" border :loading="table.loading" :columns="table.columns" :data="table.data" :resizable="true" ref="table" show-summary @on-row-click="lowerLevel" :summary-method="handleSummary"></Table>
    <div style="padding:20px"></div>
    <!--    查看下级数据-->
    <Modal
        v-model="modal1"
        title="区域模块访问统计" @on-cancel="cancel" width="1200">
      <div slot="footer">
        <template>
          <Button
              size="large"
              @click="cancel"
          >关闭</Button>
        </template>
      </div>
      <div>
        <div style="text-align: center;">
          <div class="tableTitle">区域模块访问统计</div>
          <span class="export-btn" @click="exportData(table1.data)">[导出数据]</span>
        </div>
        <div style="padding:5px"></div>
        <Table height="550" border :loading="table1.loading" :columns="table1.columns" :data="table1.data" ref="table1" :resizable="true" show-summary :summary-method="handleSummary"></Table>
        <div style="padding:20px"></div>
      </div>
    </Modal>
  </div>
</template>

<script>
//表头控制文件
import * as tableColumns from "./table-columns"
import * as tableDataSorting from "./data-sorting"
export default {
  name:'regionalModuleTable',//分区域总统计表
  props:{
    dateSelecter:{type:Object,required:true,},
  },
  data:function(){
    return{
      table:{
        loading:true,
        columns:tableColumns.regionalModuleTable,
        data:[],
      },
      table1:{
        loading:false,
        columns:tableColumns.regionalModuleTable,
        data:[],
        areaName : '',
      },
      modal1 : false
    }
  },
  watch:{
    'dateSelecter.identification':function(val){
      if(val != 'custom'){
        this.getTableData()
      }
    }
  },
  mounted:function(){
    this.getTableData()
  },
  methods:{
    handleSummary ({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[key] = {
            key,
            value: v + ''
          };
        } else {
          if(this.modal1){
            sums[key] = {
              key,
              value: this.table1.areaName
            };
          }else {
            sums[key] = {
              key,
              value: window.sessionStorage.AdminName
            };
          }
        }
      });

      return sums;
    },
    exportData : function (data){
      var _this = this
      this.$refs.table.exportCsv({
        filename: '区域咨询申办统计',
        columns : _this.table.columns,
        data : data
      });
    },
    lowerLevel : function (data,index){
      // console.log(this.dateSelecter)
      if(window.sessionStorage.Hierarchy == 2 || window.sessionStorage.Hierarchy == 1){
        this.table1.data = []
        this.table1.areaName = data.areaName
        this.table.loading = false
        this.dateSelecter.AdminId = data.adminId
        this.modal1 = true
        this.getTableData()
      }
    },
    // 弹窗取消
    cancel : function (){
      this.dateSelecter.AdminId = ''
      this.modal1 = false
    },
    //区域模块访问统计
    getTableData:function(){
      var _this = this
      if(this.modal1 == false){_this.table.loading = true}
      _this.table1.loading = true
      if(window.sessionStorage.Hierarchy == 4){
        var Data = [
          '/ReportVillagekeywordClickData', //自助咨询新增访问
          '/ReportVillageContactData', //留言咨询新增访问
          '/ReportVillagePhoneData',  //电话咨询新增访问1
          '/ReportVillageVideoData',  //视频咨询新增访问
          '/ReportVillageFlyzApplyVisitData', //法律援助新增访问
          '/ReportVillageMediateVisitData', //人民调解新增访问
          '/ReportVillageNotaryVisitData', //办理公证新增访问
          '/ReportVillageSimulationData', //模拟判决新增访问
          '/ReportVillageLegalVisitData',  //法律文书新增访问
          '/ReportVillageRegulationsVisitData',  //法律法规库新增访问1
          '/ReportVillageJudicialVisitData', //司法案例库新增访问
          '/ReportVillageCreditVisitData', //企业征信新增访问
          '/ReportVillageCivilCodeVisitData',  //民法典新增访问
          '/ReportVillageLawCirclesVisitData',  //学法用法新增访问
          '/ReportVillageMapOfLawVisitData', //法治地图新增访问

        ]
        if(SYSCONFIG.location == 'chong-qing'){Data.push(
          '/MethodUseDataLevel', //网上立案新增访问
          '/RegulationsDataLevel' ,//国务院政策文件库新增访问
          '/InvestigationDataLevel', //重庆市政策文件库新增访问
        )}
      }else{
        var Data = [
          '/ReportkeywordClickData', //自助咨询新增访问
          '/ReportContactData', //留言咨询新增访问
          '/ReportPhoneData',  //电话咨询新增访问
          '/ReportVideoData',  //视频咨询新增访问
          '/ReportFlyzApplyVisitData', //法律援助新增访问
          '/ReportMediateVisitData', //人民调解新增访问
          '/ReportNotaryVisitData', //办理公证新增访问
          '/ReportSimulationVisitData', //模拟判决新增访问
          '/ReportLegalVisitData',  //法律文书新增访问
          '/ReportRegulationsVisitData',  //法律法规库新增访问
          '/ReportJudicialVisitData', //司法案例库新增访问
          '/ReportCreditVisitData', //企业征信新增访问
          '/ReportCivilCodeVisitData',  //民法典新增访问
          '/ReportLawCirclesVisitData',  //学法用法新增访问
          '/ReportMapOfLawVisitData', //法治地图新增访问
        ]
        if(SYSCONFIG.location == 'chong-qing'){
          Data.push(
            '/OnRegistrationData', //网上立案新增访问
            '/RegulationsData', //国务院政策文件库新增访问
            '/InvestigationData', //重庆市政策文件库新增访问
        )}
      }
      _this.$parent.requestHub(Data)
          .then(res=>{
            if(this.modal1 == false){_this.table.loading = false}
            _this.table1.loading = false
            var data = tableDataSorting.dataMerge(res,{local:'adminId',remote:'AdminId'},[
              {columnName:'areaName',dataIndex:0,remoteVariable:['AreaShortName||AdminName']},
              {columnName:'newReportkeywordClick',dataIndex:0,remoteVariable:['Count','Intime']},
              {columnName:'totalReportkeywordClick',dataIndex:0,remoteVariable:['Count','Total']},
              {columnName:'newReportContact',dataIndex:1,remoteVariable:['Count','Intime']},
              {columnName:'totalReportContact',dataIndex:1,remoteVariable:['Count','Total']},
              {columnName:'newReportPhone',dataIndex:2,remoteVariable:['Count','Intime']},
              {columnName:'totalReportPhone',dataIndex:2,remoteVariable:['Count','Total']},
              {columnName:'newReportVideo',dataIndex:3,remoteVariable:['Count','Intime']},
              {columnName:'totalReportVideo',dataIndex:3,remoteVariable:['Count','Total']},
              {columnName:'newReportFlyzApplyVisit',dataIndex:4,remoteVariable:['Count','Intime']},
              {columnName:'totalReportFlyzApplyVisit',dataIndex:4,remoteVariable:['Count','Total']},
              {columnName:'newReportMediateVisit',dataIndex:5,remoteVariable:['Count','Intime']},
              {columnName:'totalReportMediateVisit',dataIndex:5,remoteVariable:['Count','Total']},
              {columnName:'newReportNotaryVisit',dataIndex:6,remoteVariable:['Count','Intime']},
              {columnName:'totalReportNotaryVisit',dataIndex:6,remoteVariable:['Count','Total']},
              {columnName:'newReportSimulationVisit',dataIndex:7,remoteVariable:['Count','Intime']},
              {columnName:'totalReportSimulationVisit',dataIndex:7,remoteVariable:['Count','Total']},
              {columnName:'newReportLegalVisit',dataIndex:8,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLegalVisit',dataIndex:8,remoteVariable:['Count','Total']},
              {columnName:'newReportRegulationsVisit',dataIndex:9,remoteVariable:['Count','Intime']},
              {columnName:'totalReportRegulationsVisit',dataIndex:9,remoteVariable:['Count','Total']},
              {columnName:'newReportJudicialVisit',dataIndex:10,remoteVariable:['Count','Intime']},
              {columnName:'totalReportJudicialVisit',dataIndex:10,remoteVariable:['Count','Total']},
              {columnName:'newReportCreditVisit',dataIndex:11,remoteVariable:['Count','Intime']},
              {columnName:'totalReportCreditVisit',dataIndex:11,remoteVariable:['Count','Total']},
              {columnName:'newReportCivilCodeVisit',dataIndex:12,remoteVariable:['Count','Intime']},
              {columnName:'totalReportCivilCodeVisit',dataIndex:12,remoteVariable:['Count','Total']},
              {columnName:'newReportLawCirclesVisit',dataIndex:13,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLawCirclesVisit',dataIndex:13,remoteVariable:['Count','Total']},
              {columnName:'newReportMapOfLawVisit',dataIndex:14,remoteVariable:['Count','Intime']},
              {columnName:'totalReportMapOfLawVisit',dataIndex:14,remoteVariable:['Count','Total']},
              {columnName:'newOnRegistrationData',dataIndex:11,remoteVariable:['Count','Intime']},
              {columnName:'totalOnRegistrationData',dataIndex:11,remoteVariable:['Count','Total']},
              {columnName:'newRegulationsData',dataIndex:12,remoteVariable:['Count','Intime']},
              {columnName:'totalRegulationsData',dataIndex:12,remoteVariable:['Count','Total']},
              {columnName:'newInvestigationData',dataIndex:13,remoteVariable:['Count','Intime']},
              {columnName:'totalInvestigationData',dataIndex:13,remoteVariable:['Count','Total']},

            ])
            if(_this.dateSelecter.AdminId == undefined || _this.dateSelecter.AdminId == ''){
              _this.table.data = data
            }else {
              _this.table1.data = data
            }
          })
          .catch(err=>{console.log("区域模块访问统计：失败！")})
    },
  },
}
</script>
