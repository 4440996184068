<template>
  <div>
    <div class="publicWelfare">
      <Row style="margin: 20px">
        <Col span="12">
          <Input v-model="key" search enter-button="搜索" placeholder="请输入关键词搜索" @on-search="serachLawyer" />
        </Col>
        <Col span="12">
          <Button type="primary"  @click="cqCommunity();modal = true" style="margin-left: 30px">添加</Button>
        </Col>
      </Row>
      <!---->


      <!---->
      <table class="formtable">
        <thead>
        <tr>
          <th>序号</th>
          <th>明白人姓名</th>
          <th>积分</th>
          <th>走访记录(次)</th>
          <th>问卷</th>
          <th>日程</th>
          <th>太阳码</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value,index) in 5">

          <td>村（居）顾问</td>

        </tr>
        </tbody>
      </table>
      <Page :total="totalName" show-total :page-size="pageSize" show-elevator
            @on-change="currentPageName = $event;serachLawyer()" />
      <div style="padding: 10px"></div>
    </div>
  </div>
</template>


<script>
export default {
name: "lawList"
}
</script>


<style scoped>
.forMcontent{width: calc(100% - 80px);height: 60px;color: #666666;font-size: 16px;margin:0px 40px 0 40px;border-bottom: 1px solid #dddddd;text-align: center;}
.forMcontent p {line-height: 60px;display: inline-block;width: 50%}
.forMtitle1 p{display: inline-block;color: #333333;font-size: 16px;line-height: 60px;overflow: hidden;white-space: nowrap;width: 20%}
.forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;margin:40px 40px 0 40px;height: 60px;}
.forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 50%;line-height: 60px;overflow: hidden;white-space: nowrap;}
.administration{width: 100%;background-color: #fff;border-bottom: 1px solid #dddddd;height: 80px;text-align: left;margin-bottom: 0}
.administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
.label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
.addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
.inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}
.contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
.single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}


.choiceLawyer :deep(.ivu-select-dropdown ){max-height: none;}
.choiceLawyer :deep(.ivu-page ){margin-left: 30px}
.choiceLawyer{width: 380px}
.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
}
.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
  text-align: center;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
}
.placeholderColor /deep/ input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {color: #515a6e;}
.placeholderColor /deep/ input:-moz-placeholder, textarea:-moz-placeholder {color: #515a6e;}
.placeholderColor /deep/ input::-moz-placeholder, textarea::-moz-placeholder {color: #515a6e;}
.placeholderColor /deep/ input:-ms-input-placeholder, textarea:-ms-input-placeholder {color: #515a6e;}

</style>

