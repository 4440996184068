<style scoped>
	.home{background-color: #fff;padding:20px;}
    .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
    /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
    .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: #00b86c;padding: 0px 16px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: #00b86c;}
    .screen :deep(.ivu-tabs-ink-bar){background-color: #00b86c;}
    .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
    .bbb :deep(.ivu-select-single .ivu-select-selection .ivu-select-placeholder){color: #333}
    .house{width: 72px;height: 72px;margin-top: 29px;}
    .number{font-size: 48px;color: #7a7a7a;}
    .tipsName{font-size: 16px;color: #8d8d8d;}
    .displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}
    .administration{width: 100%;background-color: #fff;border-bottom: 1px solid #dddddd;height: 80px;text-align: left;margin-bottom: 0}
    .administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
    .label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
    .forMtitle1{width:calc(100%);background-color: #f9f9f9;height: 60px;display: inline-block;text-align: center;}
    .forMtitle1 p{display: inline-block;color: #333333;font-size: 16px;line-height: 60px;overflow: hidden;white-space: nowrap;width: 20%}
    .forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;margin:40px 40px 0 40px;height: 60px;}
    .forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
    .forMcontent{width: calc(100% - 80px);height: 60px;color: #666666;font-size: 16px;margin:0px 40px 0 40px;border-bottom: 1px solid #dddddd;text-align: center;}
    .forMcontent p {line-height: 60px;display: inline-block;width: 20%}
    .smallProgram{width: 32px;height: 32px;vertical-align: top;margin-top: 14px;}
    .alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -33px;}
    .alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}
    .qrCode{width: 100px;height: 100px;}
    .addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
    .myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}
    .icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}
    .contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
    .single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
    .inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}

    .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
    .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
    .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
    .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
</style>

<template>
	<div class="home">
		<!--<HelloWorld>-->
		<Modal
        v-model="areaSPCPanel.state"
        title="区域二维码">
        <img :src="areaSPCPanel.imgSrc">
    </Modal>
		<!--</HelloWorld>-->
		<div style="background-color: rgb(241,239,240);font-size: 26px">
			<div class="font-size">
				<div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;font-size: 16px;text-align: left;">
					<div style="margin: 10px">
						<Button type="primary" icon="md-add" @click.stop="addTto(1,0,2)">添加司法厅</Button>
					</div>
					<!--司法厅列表-->
					<Collapse v-model="SFT" accordion @on-change="empty">
						<Panel :name="index.toString()" v-for="(value,index,k) in electronicsList">
							{{index+1}}、{{value.AdminName}}
							<div @click.stop="" style="display: inline-block;float: right;margin-right: 30px">
								<span @click="getAreaSPC(value,value.AreaId)">获取该地区小程序码</span>
								<router-link v-if="value.AdminArr.length == 1" :to="{path:'/manager/login', query:{adminLogin:value.AccountLogin,adminPassword:require('js-base64').Base64.encode(value.AccountPassword)}}"
								 target="_blank">一键登录</router-link>
                <div style="display: inline-block" v-else>
                  <Dropdown>
                    <a href="javascript:void(0)">
                      选择登录<Icon type="ios-arrow-down"></Icon>
                    </a>
                    <DropdownMenu slot="list" >
                      <DropdownItem v-for="(AdminArr1) in value.AdminArr" @click.native="oneclickLogin(AdminArr1)">
                        {{AdminArr1.AccountName}}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
							</div>
							<div slot="content" class="publicWelfare">
								<table class="formtable">
									<thead>
										<tr>
											<th>名称</th>
											<th>登录名</th>
											<th>密码</th>
											<th>注册时间</th>
											<th>编辑</th>
											<th>添加市(州)司法局</th>
											<th>删除市(州)司法局</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{{value.AdminName}}</td>
											<td>{{value.AccountLogin}}</td>
											<td>{{value.AccountPassword}}</td>
											<td>{{value.CreatTime}}</td>
											<td><Button icon="md-create" type="primary" @click.stop="addTto(1,value.AdminId,1,value.AdminName,value.AccountLogin,value.AccountPassword,value.AreaId,value.AreaName,value.AccountId)">编辑</Button></td>
											<td><Button icon="md-add" type="primary" @click.stop="addTto(2,value.AdminId,2)">添加</Button></td>
											<td><Button icon="md-trash" type="primary" @click.stop="deleteClick(value.AdminId)" style="">删除</Button></td>
										</tr>
									</tbody>
								</table>
								<!--司法局列表-->
								<Collapse v-if="SFT[0]==index.toString()" v-model="SFJ" accordion @on-change="empty">
									<Panel :name="index2.toString()" v-for="(value2,index2,k2) in value.list">
										{{index2+1}}、{{value2.AdminName}}
										<div @click.stop="" style="display: inline-block;float: right;margin-right: 30px" >
											<span @click="getAreaSPC(value2,value2.AreaId)">获取该地区小程序码</span>
											<router-link v-if="value2.AdminArr.length == 1" :to="{path:'/manager/login', query:{adminLogin:value2.AccountLogin,adminPassword:require('js-base64').Base64.encode(value2.AccountPassword)}}"
											 target="_blank">一键登录</router-link>
                      <div style="display: inline-block" v-else>
                        <Dropdown>
                          <a href="javascript:void(0)">
                            选择登录<Icon type="ios-arrow-down"></Icon>
                          </a>
                          <DropdownMenu slot="list" >
                            <DropdownItem v-for="(AdminArr2) in value2.AdminArr" @click.native="oneclickLogin(AdminArr2)">
                              {{AdminArr2.AccountName}}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
										</div>

										<div slot="content" class="publicWelfare">
											<table class="formtable">
												<thead>
													<tr>
														<th>名称</th>
														<th>登录名</th>
														<th>密码</th>
														<th>注册时间</th>
														<th>编辑</th>
														<th>添加司法所</th>
														<th>添加区县司法局</th>
														<th>删除司法所</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>{{value2.AdminName}}</td>
														<td>{{value2.AccountLogin}}</td>
														<td>{{value2.AccountPassword}}</td>
														<td>{{value2.CreatTime}}</td>
														<td><Button icon="md-create" type="primary" @click.stop="addTto(2,value2.AdminId,1,value2.AdminName,value2.AccountLogin,value2.AccountPassword,value2.AreaId,value2.AreaName,value2.AccountId)">编辑</Button></td>
														<td><Button icon="md-add" type="primary" @click.stop="addTto(3,value2.AdminId,2)">添加</Button></td>
														<td><Button icon="md-add" type="primary" @click.stop="addTto(2,value2.AdminId,2)">添加区县</Button></td>
														<td><Button icon="md-trash" type="primary" @click.stop="deleteClick(value2.AdminId)" style="">删除</Button></td>
													</tr>
												</tbody>
											</table>
											<!--司法局A列表-->
											<Collapse v-if="SFJ[0]==index2.toString()" v-model="SFJA" accordion @on-change="empty">
												<Panel :name="index3.toString()" v-for="(value3,index3,k3) in value2.list">
													{{index3+1}}、{{value3.AdminName}}
													<div @click.stop="" style="display: inline-block;float: right;margin-right: 30px">
														<span @click="getAreaSPC(value3,value3.AreaId)">获取该地区小程序码</span>
														<router-link v-if="value3.AdminArr.length == 1" :to="{path:'/manager/login', query:{adminLogin:value3.AccountLogin,adminPassword:require('js-base64').Base64.encode(value3.AccountPassword)}}" target="_blank">一键登录</router-link>
                            <div style="display: inline-block" v-else>
                              <Dropdown>
                                <a href="javascript:void(0)">
                                  选择登录<Icon type="ios-arrow-down"></Icon>
                                </a>
                                <DropdownMenu slot="list" >
                                  <DropdownItem v-for="(AdminArr3) in value3.AdminArr" @click.native="oneclickLogin(AdminArr3)">
                                    {{AdminArr3.AccountName}}
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </div>
													<div slot="content" class="publicWelfare">
														<table class="formtable">
															<thead>
																<tr>
																	<th>名称</th>
																	<th>登录名</th>
																	<th>密码</th>
																	<th>注册时间</th>
																	<th>编辑</th>
																	<th v-if="value3.Level == 2">添加司法所</th>
																	<th>删除</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>{{value3.AdminName}}</td>
																	<td>{{value3.AccountLogin}}</td>
																	<td>{{value3.AccountPassword}}</td>
																	<td>{{value3.CreatTime}}</td>
																	<td><Button icon="md-create" type="primary" @click.stop="addTto(3,value3.AdminId,1,value3.AdminName,value3.AccountLogin,value3.AccountPassword,value3.AreaId,value3.AreaName,value3.AccountId)">编辑</Button></td>
																	<td v-if="value3.Level == 2"><Button icon="md-add" type="primary" @click.stop="addTto(3,value3.AdminId,2)">添加</Button></td>
																	<td><Button icon="md-trash" type="primary" @click.stop="deleteClick(value3.AdminId)" style="">删除</Button></td>
																</tr>
															</tbody>
														</table>
														<!--司法所列表-->
														<Collapse v-if="SFJA[0]==index3.toString()" v-model="SFS" accordion @on-change="empty">
															<Panel :name="index4.toString()" v-for="(value4,index4,k4) in value3.list">
																{{index4+1}}、{{value4.AdminName}}
																<div @click.stop="" style="display: inline-block;float: right;margin-right: 30px">
                                  <span @click="getAreaSPC(value4,value4.AreaId)">获取该地区小程序码</span>
																	<router-link v-if="value4.AdminArr.length == 1" :to="{path:'/manager/login', query:{adminLogin:value4.AccountLogin,adminPassword:require('js-base64').Base64.encode(value4.AccountPassword)}}"
																	 target="_blank">一键登录</router-link>
                                  <div style="display: inline-block" v-else>
                                    <Dropdown>
                                      <a href="javascript:void(0)">
                                        选择登录<Icon type="ios-arrow-down"></Icon>
                                      </a>
                                      <DropdownMenu slot="list" >
                                        <DropdownItem v-for="(AdminArr4) in value4.AdminArr" @click.native="oneclickLogin(AdminArr4)">
                                          {{AdminArr4.AccountName}}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </div>
																</div>
																<div slot="content" class="publicWelfare">
																	<table class="formtable">
																		<thead>
																			<tr>
																				<th>名称</th>
																				<th>登录名</th>
																				<th>密码</th>
																				<th>注册时间</th>
																				<th>编辑</th>
																				<th v-if="value4.Level == 3">添加律所</th>
																				<th>删除</th>
																			</tr>
																		</thead>
																		<tbody>
																			<tr>
																				<td>{{value4.AdminName}}</td>
																				<td>{{value4.AccountLogin}}</td>
																				<td>{{value4.AccountPassword}}</td>
																				<td>{{value4.CreatTime}}</td>
																				<td><Button icon="md-create" type="primary" @click.stop="addTto(3,value4.AdminId,1,value4.AdminName,value4.AccountLogin,value4.AccountPassword,value4.AreaId,value4.AreaName,value4.AccountId)">编辑</Button></td>
																				<td v-if="value4.Level == 3"><Button icon="md-add" type="primary" @click.stop="addLawfirm(value4.AdminId,value4.AreaId,value4.Level,1);modal2 = true">添加</Button></td>
																				<td><Button icon="md-trash" type="primary" @click.stop="deleteClick(value4.AdminId)" style="">删除</Button></td>
																			</tr>
																		</tbody>
																	</table>
																	<!--司法所&律师事务所-->
																	<Collapse v-if="SFS[0]==index4.toString()&&value4.Level == 3" v-model="SFSA" accordion @on-change="LS=[];lawList(value4.list,$event)">
																		<Panel :name="indexA.toString()" v-for="(valueA,indexA,kA) in value4.list">
																			{{indexA+1}}、{{valueA.AdminName}}
																			<div @click.stop="" style="display: inline-block;float: right;margin-right: 30px">
<!--																				<span @click="getAreaSPC(valueA,valueA.AreaId)">获取该地区小程序码</span>-->
																				<router-link v-if="valueA.AdminArr.length == 1" :to="{path:'/manager/login', query:{adminLogin:valueA.AccountLogin,adminPassword:require('js-base64').Base64.encode(valueA.AccountPassword)}}"
																				 target="_blank">一键登录</router-link>
                                        <div style="display: inline-block" v-else>
                                          <Dropdown>
                                            <a href="javascript:void(0)">
                                              选择登录<Icon type="ios-arrow-down"></Icon>
                                            </a>
                                            <DropdownMenu slot="list" >
                                              <DropdownItem v-for="(AdminArr5) in valueA.AdminArr" @click.native="oneclickLogin(AdminArr5)">
                                                {{AdminArr5.AccountName}}
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>
																			</div>
																			<div slot="content" class="publicWelfare">
																				<table class="formtable">
																					<thead>
																						<tr>
																							<th>名称</th>
																							<th>登录名</th>
																							<th>密码</th>
																							<th>注册时间</th>
																							<th>编辑</th>
																							<th v-if="valueA.Level == 3">添加律所</th>
																							<th>删除</th>
																						</tr>
																					</thead>
																					<tbody>
																						<tr>
																							<td>{{valueA.AdminName}}</td>
																							<td>{{valueA.AccountLogin}}</td>
																							<td>{{valueA.AccountPassword}}</td>
																							<td>{{valueA.CreatTime}}</td>
																							<td><Button icon="md-create" type="primary" @click.stop="addTto(3,valueA.AdminId,1,valueA.AdminName,valueA.AccountLogin,valueA.AccountPassword,valueA.AreaId,valueA.AreaName,valueA.AccountId)">编辑</Button></td>
																							<td v-if="valueA.Level == 3"><Button icon="md-add" type="primary" @click.stop="addLawfirm(valueA.AdminId,valueA.AreaId,valueA.Level,1);modal2 = true">添加</Button></td>
																							<td><Button icon="md-trash" type="primary" @click.stop="deleteClick(valueA.AdminId)" style="">删除</Button></td>
																						</tr>
																					</tbody>
																				</table>
																			</div>
																			<div slot="content" class="publicWelfare">
																				<div>
																					<div class="font-size" style="width: 100%;display: inline-block;">
																						<div class="administration">
																							<img class="label" src="/img/label.png">
																							<p>管理</p>
																							<div class="addTo" @click="addLawyer(organizationIdNow);modal3 = true">添加</div>
																						</div>
																						<div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;    text-align: center;">
																							<div class="forMtitle" v-if="listData != []">
																								<p>律师</p>
																								<p>电话</p>
																								<p>小程序码</p>
																								<p>评分</p>
																								<p>操作</p>
																							</div>
																							<!--for-->
																							<div class="forMcontent" v-for="(value,index) in listData" :key="index">
																								<p>{{value.LawyerName}}</p>
																								<p>{{value.LawyerPhone}}</p>
																								<div style="color: #00b86c;cursor:pointer;width: 20%;display: inline-block;line-height: 60px;vertical-align: top;overflow: hidden;white-space: nowrap;">
																									<img class="smallProgram" @click="alertbox(value.LawyerQRcode,value.LawyerName,value.LawyerPhone,value.LawyerId,value.OrganizationId)"
																									 src="/img/smallProgram1.png">
																									<div style="display: inline-block;margin: 0 8%"></div>
																									<Button type="success" @click="alertbox1(value.LawyerQRcode,value.LawyerName,value.LawyerId,value.LawyerPhone,value.OrganizationId)">发送</Button>
																								</div>
																								<p v-if="value.avgGrade">{{value.avgGrade}}</p>
																								<p v-else>暂无评分</p>
																								<div style="color: #00b86c;cursor:pointer;width: 20%;display: inline-block;line-height: 60px;vertical-align: top;overflow: hidden;white-space: nowrap;">
																									<Button type="success" style="margin: 0 10px" @click="deleteLawyer(value.LawyerId,value.OrganizationId)">删除</Button>
																									<Button type="success" style="margin: 0 10px" @click="modal3 = true;lawyerId = value.LawyerId;lawyerName = value.LawyerName;lawyerPhone = value.LawyerPhone;OrganizationId = value.OrganizationId;lawyerId = value.LawyerId">更换律师</Button>
																								</div>
																								<!--<p style="color: #00b86c;cursor:pointer" @click="deleteLawyer(value.LawyerId)">删除</p>-->
																							</div>
																							<div style="text-align: left;margin: 40px;font-size: 16px">
																								<!--<Page :total="count" page-size="5"  show-elevator />-->
																								<!--<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>-->
																								<Page :total="count" :page-size="pageSize1" show-elevator @on-change="page1 = $event;lawList(organizationIdNow)" />
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</Panel>
																	</Collapse>
																	<!--律师-->
																	<Collapse v-model="LS" accordion v-if="SFS[0]==index4.toString()&value4.Level == 4" @on-change="lawList(value3.list,$event)">
																		<Panel>
																			律师列表
																			<div slot="content" class="publicWelfare">
																				<div>
																					<div class="font-size" style="width: 100%;display: inline-block;">
																						<div class="administration">
																							<img class="label" src="/img/label.png">
																							<p>管理</p>
																							<div class="addTo" @click="addLawyer(organizationIdNow);modal3 = true">添加</div>
																						</div>
																						<div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;    text-align: center;">
																							<div class="forMtitle" v-if="listData != []">
																								<p>律师</p>
																								<p>电话</p>
																								<p>小程序码</p>
																								<p>评分</p>
																								<p>操作</p>
																							</div>
																							<!--for-->
																							<div class="forMcontent" v-for="(value,index) in listData" :key="index">
																								<p>{{value.LawyerName}}</p>
																								<p>{{value.LawyerPhone}}</p>
																								<div style="color: #00b86c;cursor:pointer;width: 20%;display: inline-block;line-height: 60px;vertical-align: top;overflow: hidden;white-space: nowrap;">
																									<img class="smallProgram" @click="alertbox(value.LawyerQRcode,value.LawyerName,value.LawyerPhone,value.LawyerId,value.OrganizationId)"
																									 src="/img/smallProgram1.png">
																									<div style="display: inline-block;margin: 0 8%"></div>
																									<Button type="success" @click="alertbox1(value.LawyerQRcode,value.LawyerName,value.LawyerId,value.LawyerPhone,value.OrganizationId)">发送</Button>
																								</div>
																								<p v-if="value.avgGrade">{{value.avgGrade}}</p>
																								<p v-else>暂无评分</p>
																								<div style="color: #00b86c;cursor:pointer;width: 20%;display: inline-block;line-height: 60px;vertical-align: top;overflow: hidden;white-space: nowrap;">
																									<Button type="success" style="margin: 0 10px" @click="deleteLawyer(value.LawyerId,value.OrganizationId)">删除</Button>
																									<Button type="success" style="margin: 0 10px" @click="modal3 = true;lawyerId = value.LawyerId;lawyerName = value.LawyerName;lawyerPhone = value.LawyerPhone;OrganizationId = value.OrganizationId;lawyerId = value.LawyerId">更换律师</Button>
																								</div>
																								<!--<p style="color: #00b86c;cursor:pointer" @click="deleteLawyer(value.LawyerId)">删除</p>-->
																							</div>
																							<div style="text-align: left;margin: 40px;font-size: 16px">
																								<!--<Page :total="count" page-size="5"  show-elevator />-->
																								<!--<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>-->
																								<Page :total="count" :page-size="pageSize1" show-elevator @on-change="page1 = $event;lawList(organizationIdNow)" />
																							</div>
																						</div>
																					</div>

																				</div>
																			</div>
																		</Panel>
																	</Collapse>
																</div>
															</Panel>
														</Collapse>
													</div>
												</Panel>
											</Collapse>
										</div>
									</Panel>
								</Collapse>
							</div>
						</Panel>
					</Collapse>
					<!--<div style="text-align: left;margin: 40px;font-size: 16px">-->
					<!--<Page-->
					<!--:total="count1"-->
					<!--:page-size="pageSize1"-->
					<!--show-elevator-->
					<!--@on-change="page1 = $event;activeList()"-->
					<!--/>-->
					<!--</div>-->
				</div>
			</div>
		</div>
		<!--添加-->
		<Modal v-model="modal1" title="添加" @on-ok="confirm" @on-cancel="cancel">
			<Form :model="addData" :label-width="60">
				<FormItem label="名称">
					<Input v-model="addData.AdminName" placeholder="名称"></Input>
				</FormItem>
				<FormItem label="登录名">
					<Input v-model="addData.AdminLogin" placeholder="登录名"></Input>
				</FormItem>
				<FormItem label="密码">
					<Input v-model="addData.AdminPassword" placeholder="密码"></Input>
				</FormItem>
				<FormItem label="地址" :class="[addData.AreaName=='请选择'?'bbb1':'bbb']" v-if="modify == true">
					<Select v-model="area1" style="width:200px" placeholder="">
						<Option v-for="(item,index) in areaListData1" :value="JSON.stringify(item)" :key="'area1'+index">{{ item.AreaName }}</Option>
					</Select>
					<Select v-if="areaListData2.length>0" v-model="area2" style="width:200px" placeholder="">
						<Option v-for="(item,index) in areaListData2" :value="JSON.stringify(item)" :key="'area2'+index">{{ item.AreaName }}</Option>
					</Select>
					<Select v-if="areaListData3.length>1" v-model="area3" style="width:200px" placeholder="">
						<Option v-for="(item,index) in areaListData3" :value="JSON.stringify(item)" :key="'area3'+index">{{ item.AreaName }}</Option>
					</Select>
				</FormItem>
				<FormItem label="地址" v-if="modify == false">
					<Input v-model="addData.AreaName" placeholder="地址"></Input>
					<Button type="primary" @click="modify = !modify;area1 == ''">修改地址</Button>
				</FormItem>
			</Form>
		</Modal>
		<!---->
		<Modal v-model="modal2" title="添加" @on-ok="addMember" @on-cancel="cancel">

			<div class="single">
				<p class="contacts">律所名称</p>
				<input type="text" class="inputWord" placeholder="请输入村居律所名称" v-model="organizationName">
			</div>
			<div class="single">
				<p class="contacts">联系人</p>
				<input type="text" class="inputWord" placeholder="请输入联系人" v-model="organizationLinkman">
			</div>
			<div class="single">
				<p class="contacts">联系电话</p>
				<input type="text" class="inputWord" placeholder="请输入联系电话" v-model="organizationPhone">
			</div>
			<div class="single" v-if="cityList.length != 0">
				<p class="contacts">地址</p>
				<div style="display: inline-block">
					<Select v-model="model8" style="width:200px" @on-change="choice" :label-in-value="labelInValue" :placeholder="tipsWord">
						<Option v-for="(item,index) in cityList" :value="item.AreaId +','+ index  +','+ '1'" :label="item.AreaName" :key="index">{{ item.AreaName }}</Option>
					</Select>
					<Select v-model="model9" style="width:200px" @on-change="choice" :label-in-value="labelInValue" v-if="areaData"
					 :placeholder="tipsWord">
						<Option v-for="(i,index) in areaData" :value="i.AreaId +','+ index +','+ '2'" :label="i.AreaName" :key="index">{{ i.AreaName }}</Option>
					</Select>
				</div>
			</div>
			<div class="single">
				<p class="contacts">登录名称</p>
				<input type="text" class="inputWord" placeholder="请输入登录名" v-model="adminLogin">
			</div>
			<div class="single">
				<p class="contacts">登录密码</p>
				<input type="text" class="inputWord" placeholder="请输入登录密码" v-model="adminPassword">
			</div>
		</Modal>
		<!---->
		<Modal v-model="modal3" title="添加" @on-ok="ok" @on-cancel="cancel">
			<div class="single">
				<p class="contacts">律师名称</p>
				<input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName">
			</div>
			<div class="single">
				<p class="contacts">律师电话</p>
				<input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone">
			</div>
		</Modal>
		<!--弹窗img-->
		<Modal v-model="modal5" :title="lawName+'的太阳码'" @on-ok="downloadUrl" @on-cancel="cancel" ok-text="下载" cancel-text="关闭"
		 width="350">
			<div style="text-align: center">
				<img v-if="modal5 && urlImg" :src="urlImg" style="width: 300px;height: 300px">
			</div>
		</Modal>
		<!---->
		<Modal v-model="modal6" :title="lawName+'的太阳码'" @on-ok="sendOut" @on-cancel="cancel" ok-text="发送" cancel-text="关闭"
		 width="350">
			<div style="text-align: center">
				<p style="font-size: 16px">是否发送律师小程序码至手机</p>
				<img v-if="modal6" :src="urlImg" style="width: 300px;height: 300px">
			</div>
		</Modal>
	</div>
</template>

<script>
	// import HelloWorld from '../../components/HelloWorld.vue'
	export default {
		name: "home",
		// components: {HelloWorld,},
		data() {
			return {
				areaSPCPanel:{
					state:false,
					imgSrc:'',
				},

				area1: [],
				area2: [],
				area3: [],
				areaListData1: [],
				areaListData2: [],
				areaListData3: [],
				modify: true,
				information: [],
				electronicsList: [],
				SFT:[],
				SFJ:[],
				SFJA:[],
				SFS:[],
				SFSA:[],
				LSSWS:[],
				LS:[],
				LSA:[],
				page: '',
				pageSize: '',
				modal1: false,
				modal2: false,
				modal3: false,
				modal5: false,
				modal6: false,
				//地址
				areaListData: [],
				areaList1: '',
				areaList2: '',
				areaList3: '',
				labelInValue: true,
				addressId: ['', '', ''],
				cityId: '', //获取市的index
				page1: 1,
				pageSize1: 5,
				count: 0,
				//
				addData: {
					typeId: '',
					upAdminId: '',
					AdminLogin: '', //登录名
					AdminPassword: '', //密码
					AreaId: '', //区域
					AreaName: '请选择', //区域名称
					AdminName: '', //名称
				},

				cityList: [],
				adminLogin: '',
				adminPassword: '',
				organizationPhone: '',
				organizationLinkman: '',
				organizationName: '',
				AdminId: '',
				AreaId: '',
				Lawtype: '',
				OrganizationId: '',
				organizationIdNow:'',
				listData: [],
				urlImg: '',
				lawName: '',
				lawyerId1: '',
				lLawyerPhone: '',
				lawyerPhone: '',
				lawyerName: '',
			}
		},
		watch: {
			'area1': function(item) {
				if(item != ''){
					item = JSON.parse(item)
					console.log(item)
					if (item.AreaId) {
						this.addData.AreaId = item.AreaId
					}
					this.areaListData2 = []
					this.areaListData3 = []
					if (item.list) {
						this.areaListData2 = item.list
					}
				}
			},
			'area2': function(item) {
				if(item != ''){
					item = JSON.parse(item)
					if (item.AreaId) {
						this.addData.AreaId = item.AreaId
					}
					this.areaListData3 = []
					if (item.list) {
						this.areaListData3 = item.list
					}
				}
			},
			'area3': function(item) {
				if(item != ''){
					item = JSON.parse(item)
					if (item.AreaId) {
						this.addData.AreaId = item.AreaId
					}
				}
			},
		},
		methods: {
			getAreaSPC:function(item,AreaId){
				var _this = this
        if(AreaId == 2236){
          var scene ="cqcj-"+item.AdminId
        }else{
          var scene =  "chooseArea-"+item.AdminId
        }
				_this.areaSPCPanel.state = false
				_this.areaSPCPanel.imgSrc = ''
        if(SYSCONFIG.location == 'chong-qing'){
          var url = '/getVillageSunCode'
        }else {
          var url = '/CD_getVillageSunCode'
        }
				_this.send({
					url:url,
          consulting1:true,
          responseType:"arraybuffer",
					data: {
					  scene:scene,
					  width: '430',
					  jpg: 'jpg',
						page:'public/scanIn/scanIn',
            AreaId: AreaId
					 },
					 success:function(res){
						 _this.areaSPCPanel.state = true
						 _this.areaSPCPanel.imgSrc = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
					 }
				})
			},
			totalAdminList: function() {
				var _this = this
				_this.addData.typeId = ''
				_this.addData.upAdminId = ''
				_this.addData.AdminLogin = ''
				_this.addData.AdminPassword = ''
				_this.addData.AreaId = ''
				_this.addData.AreaName = '请选择'
				_this.addData.AdminName = '',
					_this.addressId = ['', '', ''],
					_this.areaList1 = ''
				_this.areaList2 = ''
				_this.areaList3 = ''
				_this.area1 = []
				_this.areaListData2 = []
				_this.areaListData3 = []
				_this.send({
					url: '/TotalAdminList',
					data: {
						AdminId: _this.information.AdminId,
						page: _this.page,
						pageSize: _this.pageSize,
					},
					success: function(res) {
						_this.electronicsList = res.list
					}
				})
			},
			addTto: function(typeId, adminId, type, adminName, adminLogin, adminPassword, areaId, AreaName,AccountId) {
				var _this = this
				if (AreaName) {
					_this.modify = false
				} else {
					_this.modify = true
				}
				console.log(typeId, adminId, type, adminName, adminLogin, adminPassword, areaId, AreaName)
				//type==1编辑   type==2添加
				if (type == 1) {
					_this.addData.OwnAdminId = adminId
					_this.addData.upAdminId = ''
					_this.addData.AdminName = adminName
					_this.addData.AdminLogin = adminLogin
					_this.addData.AdminPassword = adminPassword
					_this.addData.AreaId = areaId
					_this.addData.AreaName = AreaName
					_this.addData.AccountId = AccountId
				}
				if (type == 2) {
					_this.addData.OwnAdminId = ''
					_this.addData.AccountId = ''
					_this.addData.upAdminId = adminId
					console.log(typeId, adminId, type)
				}
				// if(typeId == 3){
				//     _this.addData.OwnAdminId = ''
				//     _this.addData.upAdminId = adminId
				// }
				_this.addData.typeId = typeId,
					_this.modal1 = !_this.modal1
				console.log(_this.addData)
			},
			//删除
			deleteClick: function(ownAdminId) {
				var _this = this
				_this.send({
					confirm: true,
					url: '/DeleteTotalAdmin',
					data: {
						AdminId: _this.information.AdminId,
						OwnAdminId: ownAdminId,
					},
					success: function(res) {
						_this.totalAdminList()
					}
				})
			},
			confirm: function() {
				var _this = this
				console.log(_this.addData)
				_this.send({
					url: '/ManageTotalAdmin',
					data: {
						AdminId: _this.information.AdminId,
						TypeId: _this.addData.typeId,
						OwnAdminId: _this.addData.OwnAdminId,
						UpAdminId: _this.addData.upAdminId,
						AdminLogin: _this.addData.AdminLogin,
						AdminPassword: _this.addData.AdminPassword,
						AreaId: _this.addData.AreaId,
						AdminName: _this.addData.AdminName,
            RoleType  : window.sessionStorage.AccountTypeId,
            AccountId  : _this.addData.AccountId,
					},
					success: function(res) {
						_this.$Notice.success({
							desc: '操作成功',
						})
						_this.totalAdminList()
					}
				})
			},
			//弹窗取消
			cancel() {
				var _this = this
				_this.addData.typeId = ''
				_this.addData.upAdminId = '',
					_this.addData.AdminLogin = '', //登录名
					_this.addData.AdminPassword = '', //密码
					_this.addData.AreaId = '', //区域
					_this.addData.AdminName = '' //名称
				_this.addData.AreaName = '请选择'
				_this.areaList1 = ''
				_this.areaList2 = ''
				_this.areaList3 = ''
				_this.area1 = []
				_this.areaListData2 = []
				_this.areaListData3 = []
			},
			//    地区
			areaList: function() {
				var _this = this
				_this.send({
					url: '/AreaAdminList',
					data: {},
					success: function(res) {
						_this.areaListData1 = res
						_this.areaListData = res
					}
				})
			},
			//    选择
			choice: function(value) {
				var _this = this
				var data = value.value.split(',')
				if (data[1] == 0) {
					_this.addressId = ['', '', '']
				}
				if (data[1] == 1) {
					_this.addressId[2] = ''
					_this.addressId[1] = ''
					_this.cityId = data[2]
				}
				_this.addData.AreaId = data[0]
				_this.addressId[data[1]] = data[0]
				console.log(_this.addressId)
				// console.log(data)
				// console.log(_this.addressId)
			},
			lawList: function(res,index) {
				var OrganizationId
				if(!index){
					OrganizationId = res
					console.log(OrganizationId)
				}else{
					if(index[0]==undefined){
						return
					}
					OrganizationId = res[index].OrganizationId
				}
				this.organizationIdNow = OrganizationId
				var _this = this
				_this.send({
					url: '/LawyerListById',
					data: {
						OrganizationId: OrganizationId,
						page: _this.page1,
						pageSize: _this.pageSize1,
					},
					success: function(res) {
						_this.listData = res.List
						_this.count = res.count

					}
				})
			},
			addLawfirm: function(AdminId, AreaId, Level, Lawtype) {
				var _this = this
				console.log(AdminId, AreaId, Level)
				_this.AdminId = AdminId
				_this.AreaId = AreaId
				_this.Level = Level
				_this.Lawtype = Lawtype
			},
			addMember: function() {
				var _this = this
				//Lawtype == 1 添加
				// Lawtype == 2 修改
				if (_this.Lawtype == 1) {
					if (_this.organizationName != '' && _this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword !=
						'' && _this.adminLogin != '') {
						if (_this.cityList.length == 0) {
							// var AreaId = _this.information.AreaId
							_this.AreaId = _this.information.AreaId
						}
						_this.send({
							url: '/AddMember',
							data: {
								Level: _this.Level,
								AdminId: _this.AdminId,
								AdminLogin: _this.adminLogin,
								AdminPassword: _this.adminPassword,
								OrganizationLinkman: _this.organizationLinkman,
								OrganizationPhone: _this.organizationPhone,
								OrganizationName: _this.organizationName,
								AreaId: _this.AreaId,
							},
							success: function(res) {
								_this.totalAdminList()
								_this.adminLogin = ''
								_this.adminPassword = ''
								_this.organizationLinkman = ''
								_this.organizationPhone = ''
								_this.organizationName = ''
								_this.AreaId = ''
								_this.model8 = ''
							}
						})
					} else {
						_this.$Notice.warning({
							title: '系统提示',
							desc: '输入完成后添加',
						})
					}
				} else {
					if (_this.organizationName != '' && _this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword !=
						'' && _this.adminLogin != '') {
						if (_this.cityList.length == 0) {
							// var AreaId = _this.information.AreaId
							_this.AreaId = _this.information.AreaId
						}
						_this.send({
							url: '/AdminEdit',
							data: {
								AdminId: _this.AdminId,
								AdminLogin: _this.adminLogin,
								AdminPassword: _this.adminPassword,
								AdminName: _this.AdminName,
								OrganizationName: _this.organizationName,
								OrganizationLinkman: _this.organizationLinkman,
								OrganizationPhone: _this.organizationPhone,
							},
							success: function(res) {
								_this.totalAdminList()
								_this.adminLogin = ''
								_this.adminPassword = ''
								_this.organizationLinkman = ''
								_this.organizationPhone = ''
								_this.organizationName = ''
								_this.AreaId = ''
								_this.model8 = ''
							}
						})
					} else {
						_this.$Notice.warning({
							title: '系统提示',
							desc: '输入完成后添加',
						})
					}
				}
			},
			deleteLawFirm: function(adminid, organizationId, pid, Level) {
				console.log(pid)
				var _this = this
				_this.send({
					url: '/deleteMember',
					confirm: true,
					data: {
						AdminId: adminid,
						Level: _this.Level,
						OrganizationId: organizationId,
						Pid: pid
					},
					success: function(res) {
						console.log(res)
						_this.listData = []
						_this.page = '1'
						_this.totalAdminList()
					}
				})
			},
			// 修改数据
			modifyData: function(AdminId, AdminLogin, AdminPassword, AdminName, OrganizationName, OrganizationLinkman,
				OrganizationPhone) {
				var _this = this
				_this.modal2 = !_this.modal2
				_this.adminLogin = AdminLogin
				_this.adminPassword = AdminPassword
				_this.organizationPhone = OrganizationPhone
				_this.organizationLinkman = OrganizationLinkman
				_this.organizationName = OrganizationName
				_this.AdminName = AdminName
				_this.AdminId = AdminId
				console.log(AdminId, AdminLogin, AdminPassword, AdminName, OrganizationName, OrganizationLinkman,
					OrganizationPhone)
			},
			deleteLawyer: function(lawyerId, OrganizationId) {
				var _this = this
				_this.send({
					url: '/DeleteLawyer',
					confirm: true,
					data: {
						OrganizationId: OrganizationId,
						LawyerId: lawyerId,
					},
					success: function(res) {
						console.log(res)
						_this.listData = []
						_this.page = '1'
						_this.lawList(OrganizationId)
					}
				})
			},
			addLawyer: function(OrganizationId) {
				this.OrganizationId = OrganizationId
			},
			ok: function() {
				var _this = this
				var op = _this.OrganizationId + 'p' + _this.lawyerPhone + 'p' + 1
				if (_this.lawyerName != '' && _this.lawyerPhone != '') {
					_this.send({
						url: '/AddLawyerInfo',
						data: {
							OrganizationId: _this.OrganizationId,
							scene: op,
							userType: '2',
							page: 'pages/login/login',
							width: '430',
							jpg: 'jpg',
							LawyerName: _this.lawyerName,
							LawyerPhone: _this.lawyerPhone,
							LawyerId: _this.lawyerId
						},
						success: function(res) {
							_this.listData = []
							_this.lawList(_this.OrganizationId)
							_this.OrganizationId = ''
							_this.page = '1'
						}
					})
				} else {
					_this.$Notice.warning({
						title: '系统提示',
						desc: '名称或电话不能为空',
					})
				}
			},
			alertbox: function(url, name, lawyerPhone, LawyerId, OrganizationId) {
				var _this = this
				var op = OrganizationId + 'p' + lawyerPhone + 'p' + 1 + 'p' + LawyerId
        if(SYSCONFIG.location == 'chong-qing'){
          var url1 = '/getLawyerSunCode'
        }else {
          var url1 = '/CD_getLawyerSunCode'
        }
        _this.send({
					url: url1,
          consulting1:true,
          responseType:"arraybuffer",
          data: {
						OrganizationId: OrganizationId,
						scene: OrganizationId + 'p' + lawyerPhone + 'p' + 1 + 'p' + LawyerId,
						userType: '2',
						page: 'pages/login/login',
						width: '430',
						jpg: 'jpg',
						LawyerId: LawyerId
					},
					success: function(res) {
						_this.urlImg = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
					}
				})
				console.log(op)
				_this.modal5 = true
				// _this.urlImg = url
				_this.lawName = name
			},
			alertbox1: function(url, name, lawyerId1, lLawyerPhone, OrganizationId) {
				var _this = this
				var op = OrganizationId + 'p' + lLawyerPhone + 'p' + 1 + 'p' + lawyerId1 + ''
        if(SYSCONFIG.location == 'chong-qing'){
          var url1 = '/getLawyerSunCode'
        }else {
          var url1 = '/CD_getLawyerSunCode'
        }
				_this.send({
					url: url1,
          consulting1:true,
          responseType:"arraybuffer",
          data: {
						OrganizationId: OrganizationId,
						scene: op,
						userType: '2',
						page: 'pages/login/login',
						width: '430',
						jpg: 'jpg',
						LawyerId: lawyerId1
					},
					success: function(res) {
						_this.urlImg = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
					}
				})
				console.log(op)
				_this.modal6 = true
				// _this.urlImg = url
				_this.lawName = name
				_this.lawyerId1 = lawyerId1
				_this.lLawyerPhone = lLawyerPhone
			},
			sendOut: function() {
				var _this = this
				_this.send({
					url: '/SendMessage',
					data: {
						LawyerId: _this.lawyerId1,
						LawyerPhone: _this.lLawyerPhone
					},
					success: function(res) {
						if (res.Code == 'OK') {
							_this.$Notice.warning({
								title: '系统提示',
								desc: '发送成功',
							})
						}
					}
				})
			},
			downloadUrl: function() {
				var _this = this
				_this.modal5 = false
				// window.URL = _this.urlImg
				var downloadIamge = function(imgsrc, name) { //下载图片地址和图片名
					let image = new Image();
					// 解决跨域 Canvas 污染问题
					image.setAttribute("crossOrigin", "anonymous");
					image.onload = function() {
						let canvas = document.createElement("canvas");
						canvas.width = image.width;
						canvas.height = image.height;
						let context = canvas.getContext("2d");
						context.drawImage(image, 0, 0, image.width, image.height);
						let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
						let a = document.createElement("a"); // 生成一个a元素
						let event = new MouseEvent("click"); // 创建一个单击事件
						a.download = name || "photo"; // 设置图片名称
						a.href = url; // 将生成的URL设置为a.href属性
						a.dispatchEvent(event); // 触发a的单击事件
					};
					image.src = imgsrc;
				}
				downloadIamge(_this.urlImg, _this.lawName + ".jpg");
			},
			empty: function() {
				this.LSA = 0,
					this.listData = []
					this.LS=[]
			},
      oneclickLogin : function (AdminArr){
			  console.log(AdminArr)
        var page = this.$router.resolve({path:'/manager/login',query: {adminLogin:AdminArr.AccountLogin,adminPassword:require('js-base64').Base64.encode(AdminArr.AccountPassword)}})
        window.open(page.href, '_blank');
      },
		},
		mounted() {
			var _this = this
			if (window.sessionStorage.adminLevel == undefined) {
				// this.$router.push({name:'login'})
			} else {
				this.information = window.sessionStorage
				_this.totalAdminList()
				_this.areaList()
			}
		},
	}
</script>
