<template>
    <!--评价-->
    <div class="screen">
        <div style="background-color: white; padding: 20px">
            <div style="padding: 15px"></div>
            <div
                style="
                    font-size: 16px;
                    text-align: left;
                    margin-left: 20px;
                    border-left: solid #de4f0b 3px;
                    padding-left: 5px;
                    display: inline-block;
                "
            >
              查询服务
            </div>
          <div style="display: inline-block;font-size: 16px;margin-left: 20px">
            共<span style="color: red">{{queryServiceTotal}}</span>条
          </div>
<!--            <div style="display: inline-block">-->
<!--                <router-link to="/consultingService"-->
<!--                    ><Button type="primary" style="margin-left: 20px">咨询服务</Button></router-link-->
<!--                >-->
<!--                <router-link to="/handlingServices"-->
<!--                    ><Button type="primary" style="margin-left: 20px">申办服务</Button></router-link-->
<!--                >-->
<!--            </div>-->
          <div style="padding: 8px"></div>
          <div>数据筛选：
            <DatePicker
                :value="timeData"
                v-model="timeData"
                type="daterange"
                split-panels
                placeholder="请选择时间"
                style="width: 200px"
                @on-change="totalTime1"
            ></DatePicker>
          </div>
            <div style="padding: 8px"></div>
            <Tabs v-if="loadQuantity" :value="type" @on-click="opinion" style="margin: 0 20px">
                <TabPane v-for="(value, index) in serviceData" :label="tabsTabel(value)" :name="value.type" :key="index"> </TabPane>
                <TabPane v-for="(value, index) in serviceData1" :label="tabsTabel(value)" :name="value.type" :key="index+'1'"> </TabPane>
            </Tabs>
            <!--      -->
            <div>
                <div class="publicWelfare">
                    <div style="margin-bottom: 10px">
                      <Select v-model="model1" style="width: 200px" @on-change="lawyerChoice" filterable clearable>
                        <Option v-for="(value, index) in lawyerData" :value="value.LawyerId" :key="value.LawyerId">
                          {{ value.LawyerName }}</Option
                        >
                      </Select>
                      <Select
                          style="width: 200px"
                          v-model="model14"
                          filterable
                          remote
                          clearable
                          :remote-method="remoteMethod2"
                          @on-change="lawyerChoice2"
                          :loading="loading2"
                          placeholder="请输入社区"
                      >
                        <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesId">
                          {{ value.VillagesName }}</Option
                        >
                      </Select>
                    </div>

                    <table class="formtable">
                        <thead>
                            <tr>
                                <th>序号</th>
                                <th>用户</th>
                                <th>所属村（社区）</th>
                                <th>查询时间</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, index) in detailsSeeLIst">
                                <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
                                <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
                                <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
                                <td>{{ value.CityName }}{{ value._AdminName }}{{ value.VillagesName }}</td>
                                <td>{{ value.OrderCreatedTime || value.ClickTime }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <Page
                        :total="count"
                        show-total
                        :page-size="pageSize"
                        show-elevator
                        @on-change="
                            page = $event
                            detailsSee(type)
                        "
                    />
                </div>
            </div>
            <!--      -->
            <!--      -->
        </div>
    </div>
    <!---->
</template>

<script>
import echarts from 'echarts'

export default {
    name: 'consultingService',
    data() {
        return {
            type: '5',
            tabsTabel(data) {
                return (h) => {
                    return h('div', [
                        h('span', data.name),
                        h(
                            'span',
                            {
                                style: { color: 'rgb(222, 79, 11)' }
                            },
                            data.count
                        ),
                        h('span', '条')
                    ])
                }
            },
            loadQuantity: false,
            serviceData: [
                { name: '模拟判决', type: '5', count: 0 },
                { name: '文书下载', type: '6', count: 0 }
            ],
            serviceData1: [
                { name: '企业征信', type: '7', count: 0, url: '/InvestigationList' },
                { name: '法律法规库', type: '8', count: 0, url: '/RegulationsList' },
                { name: '司法案例库', type: '9', count: 0, url: '/JudicialCaseList' },
                { name: '民法典', type: '10', count: 0, url: '/CivilCodeList' },
                { name: '网上立案', type: '11', count: 0, url: '/OnRegistrationList' },
                { name: '学法用法', type: '12', count: 0, url: '/MethodUseList' },
                { name: '法律服务地图', type: '13', count: 0, url: '/ServiceMapList' }
            ],
            UserOrderLIst: [],
            timeData: '',
            lawyerData: [],
            page: 1,
            pageSize: 15,
            model1: '',
            model14: '',
            loading2: false,
            UserCallList: [],
            options2: [],
            detailsSeeLIst: [],
            count: 0,
            VillagesId : '',
            queryServiceTotal : 0
        }
    },
    methods: {
        opinion: function (e) {
            this.type = e
            this.detailsSee(e)
            // if(e == 5 || e == 6){
            //   this.orderTypeCount()
            // }
        },
        //视频咨询
        detailsSee: function (type) {
            var _this = this
            if (type == 5) {
                _this.send({
                    url: '/UserDecisionList',
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        if (_this.type == type) {
                            _this.detailsSeeLIst = res.list
                            _this.count = res.count
                        }
                    }
                })
            }
            if (type == 6) {
                _this.send({
                    url: '/UserWritList',
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        if (_this.type == type) {
                            _this.detailsSeeLIst = res.list
                            _this.count = res.count
                        }
                    }
                })
            }
            if (type == 7) {
                _this.send({
                    url: '/InvestigationList',
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        if (_this.type == type) {
                            _this.detailsSeeLIst = res.List
                            _this.count = res.Count
                        }
                    }
                })
            }
            if (type == 8) {
                _this.send({
                    url: '/RegulationsList',
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        if (_this.type == type) {
                            _this.detailsSeeLIst = res.List
                            _this.count = res.Count
                        }
                    }
                })
            }
            if (type == 9) {
                _this.send({
                    url: '/JudicialCaseList',
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        if (_this.type == type) {
                            _this.detailsSeeLIst = res.List
                            _this.count = res.Count
                        }
                    }
                })
            }
            if (type == 10) {
                _this.send({
                    url: '/CivilCodeList',
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        if (_this.type == type) {
                            _this.detailsSeeLIst = res.List
                            _this.count = res.Count
                        }
                    }
                })
            }
            if (type == 11) {
                _this.send({
                    url: '/OnRegistrationList',
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        if (_this.type == type) {
                            _this.detailsSeeLIst = res.List
                            _this.count = res.Count
                        }
                    }
                })
            }
            if (type == 12) {
                _this.send({
                    url: '/MethodUseList',
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        if (_this.type == type) {
                            _this.detailsSeeLIst = res.List
                            _this.count = res.Count
                        }
                    }
                })
            }
            if (type == 13) {
                _this.send({
                    url: '/ServiceMapList',
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        if (_this.type == type) {
                            _this.detailsSeeLIst = res.List
                            _this.count = res.Count
                        }
                    }
                })
            }
        },
        //
        //
        lawyerClick: function (e) {
            var _this = this
            if (e == undefined) {
                _this.nameKeyId = ''
            } else {
                _this.nameKeyId = e
            }
            _this.page = 1
            _this.userOrderLIst()
        },
        lawyerClick2: function (e) {
            var _this = this
            if (e == undefined) {
                _this.VillagesId = ''
                _this.options2 = []
            } else {
                _this.VillagesId = e
            }
            _this.page = 1
            _this.userOrderLIst()
        },
        totalTime0: function (e) {
            var _this = this
            ;(_this.startTime = e[0]), //开始时间
                (_this.endTime = e[1]) //结束时间
            _this.page = 1
            _this.userOrderLIst()
        },
        totalTime2: function (e) {
            var _this = this
            ;(_this.startTime = e[0]), //开始时间
                (_this.endTime = e[1]) //结束时间
            _this.page = 1
            _this.dialTelephone()
        },
        lawyerList: function () {
            var _this = this
            _this.send({
                url: '/LawyerList',
                data: {
                    AdminId: _this.information.AdminId
                },
                success: function (res) {
                    _this.lawyerData = res.list
                }
            })
        },
        remoteMethod2(query) {
            console.log(query)
            var _this = this
            _this.send({
                url: '/villagesList',
                data: {
                    AdminId: _this.information.AdminId,
                    Key: query
                },
                success: function (res) {
                    _this.options2 = res.list
                }
            })
        },
        lawyerChoiceA: function (e) {
            var _this = this
            if (e == undefined) {
                _this.nameKeyId = ''
            } else {
                _this.nameKeyId = e
            }
            _this.page = 1
            _this.dialTelephone()
        },
        lawyerChoiceA2: function (e) {
            var _this = this
            if (e == undefined) {
                _this.VillagesId = ''
                _this.options2 = []
            } else {
                _this.VillagesId = e
            }
            _this.page = 1
            _this.dialTelephone()
        },
        totalTime1: function (e) {
            var _this = this
            ;(_this.startTime = e[0]), //开始时间
                (_this.endTime = e[1]) //结束时间
            _this.page = 1
            _this.detailsSee(_this.type)
          this.serviceTimes()
        },
        lawyerChoice: function (e) {
            var _this = this
            if (e == undefined) {
                _this.nameKeyId = ''
            } else {
                _this.nameKeyId = e
            }
            _this.page = 1
            _this.detailsSee(_this.type)
        },
        lawyerChoice2: function (e) {
            var _this = this
            if (e == undefined) {
                _this.VillagesId = ''
                _this.options2 = []
            } else {
                _this.VillagesId = e
            }
            _this.page = 1
            _this.detailsSee(_this.type)
        },
        orderTypeCount: function () {
            var _this = this
            _this.send({
                url: '/OrderTypeCount',
                data: {
                    AdminId: _this.information.AdminId,
                    typeId: _this.typeId,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime
                },
                success: function (res) {
                    _this.loadQuantity = true
                }
            })
        },
      serviceTimes: function () {
        var _this = this
        _this.send({
          url: '/UserDecisionCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            VillagesId: _this.VillagesId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime
          },
          success: function (res) {
            for(var i in _this.serviceData){
              if(_this.serviceData[i].type == '5'){
                _this.serviceData[i].count = res.count
              }
            }
            _this.queryServiceTotal += res.count
          }
        })
        _this.send({
          url: '/UserWritCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            VillagesId: _this.VillagesId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime
          },
          success: function (res) {
            for(var i in _this.serviceData){
              if(_this.serviceData[i].type == '6'){
                _this.serviceData[i].count = res.count
              }
            }
            _this.queryServiceTotal += res.count
          }
        })
        _this.send({
          url: '/MingCodeUserCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            VillagesId: _this.VillagesId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime
          },
          success: function (res) {
            for(var i in _this.serviceData1){
              if(_this.serviceData1[i].type == '10'){
                _this.serviceData1[i].count = res.count
              }
            }
            _this.queryServiceTotal += res.count
          }
        })
        //其他咨询
        _this.send({
          url: '/InvestigationCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            VillagesId: _this.VillagesId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.serviceData1) {
              if (_this.serviceData1[i].type == 7) {
                _this.serviceData1[i].count = res.Count
              }
            }
            _this.queryServiceTotal += res.Count
          }
        })
        _this.send({
          url: '/RegulationsCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            VillagesId: _this.VillagesId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.serviceData1) {
              if (_this.serviceData1[i].type == 8) {
                _this.serviceData1[i].count = res.Count
              }
            }
            _this.queryServiceTotal += res.Count
          }
        })
        _this.send({
          url: '/JudicialCaseCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            VillagesId: _this.VillagesId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.serviceData1) {
              if (_this.serviceData1[i].type == 9) {
                _this.serviceData1[i].count = res.Count
              }
            }
            _this.queryServiceTotal += res.Count
          }
        })
        _this.send({
          url: '/OnRegistrationCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            VillagesId: _this.VillagesId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.serviceData1) {
              if (_this.serviceData1[i].type == 11) {
                _this.serviceData1[i].count = res.Count
              }
            }
            _this.queryServiceTotal += res.Count
          }
        })
        _this.send({
          url: '/MethodUseCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            VillagesId: _this.VillagesId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.serviceData1) {
              if (_this.serviceData1[i].type == 12) {
                _this.serviceData1[i].count = res.Count
              }
            }
            _this.queryServiceTotal += res.Count
          }
        })
        _this.send({
          url: '/ServiceMapCount',
          data: {
            AdminId: _this.information.AdminId,
            typeId: _this.typeId,
            VillagesId: _this.VillagesId,
            start_time: _this.startTime, //开始时间
            end_time: _this.endTime,
            type: 2
          },
          success: function (res) {
            for (var i in _this.serviceData1) {
              if (_this.serviceData1[i].type == 13) {
                _this.serviceData1[i].count = res.Count
              }
            }
            _this.queryServiceTotal += res.Count
          }
        })
        if (SYSCONFIG.location == 'chong-qing') {

        }
      },
    },
    mounted() {
        var _this = this
        this.information = window.sessionStorage
        this.detailsSee(5)
        this.detailsSee(6)
        this.lawyerList()
        this.orderTypeCount()
        this.serviceTimes()
        if (SYSCONFIG.location == 'chong-qing') {
            this.detailsSee(7)
            this.detailsSee(8)
            this.detailsSee(9)
            this.detailsSee(10)
            this.detailsSee(11)
            this.detailsSee(12)
            this.detailsSee(13)
        } else {
          _this.serviceData1 = [
            { name: '企业征信', type: '7', count: 0, url: '/InvestigationList' },
            { name: '法律法规库', type: '8', count: 0, url: '/RegulationsList' },
            { name: '司法案例库', type: '9', count: 0, url: '/JudicialCaseList' },
            { name: '民法典', type: '10', count: 0, url: '/CivilCodeList' },
            // { name: '网上立案', type: '11', count: 0, url: '/OnRegistrationList' },
            { name: '学法用法', type: '12', count: 0, url: '/MethodUseList' },
            { name: '法律服务地图', type: '13', count: 0, url: '/ServiceMapList' }
          ]
          this.detailsSee(7)
          this.detailsSee(8)
          this.detailsSee(9)
          this.detailsSee(10)
          // this.detailsSee(11)
          this.detailsSee(12)
          this.detailsSee(13)

        }
    }
}
</script>

<style scoped>
/*.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}*/
/*.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}*/
/*.screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}*/
/*表格*/
.publicWelfare table {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}

.formtable {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}

.publicWelfare table th {
    background: #f7f7f7;
    white-space: nowrap;
    color: #5c6b77;
    font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
    border: 1px solid #e9e9e9;
    padding: 8px 16px;
    text-align: left;
}
</style>
