<style scoped>
  .headTransparent{width: 100%;height: 0.7rem;background-image: linear-gradient(rgba(0,111,214,0.8), rgba(76,151,255,0.8));}
  .userImg{width: 0.45rem;height: 0.45rem;border-radius: 0.45rem;margin-top: 0.01rem;}
  .framePortrait{width: 0.47rem;height: 0.47rem;border-radius: 0.47rem;background-color: rgba(255,255,255,0.6);display: inline-block;text-align: center;margin: 0.14rem 0.15rem 0 0.3rem;vertical-align:top;}
  .hi{color:#fff;font-size: 0.24rem;display: inline-block;line-height: 0.70rem;vertical-align:top;}
  .address{color: #ffffff;font-size: 0.36rem;font-weight: 600;margin-top: 0.20rem;}
  .sifa{width: 1.02rem;margin: 0.30rem 0;height: 1.10rem;}
  .lawyerInformation{margin:auto;width: 6.90rem;height: 1.53rem;border-radius: 0.765rem;background-image: linear-gradient(rgba(0,111,214,0.8), rgba(76,151,255,0.8));margin-top: 0.30rem;text-align: left;font-size: 0;margin-bottom: 0.30rem;}
  .lawyerImg{width: 1.06rem;height: 1.06rem;border-radius: 1.06rem;vertical-align: top;margin: 0.24rem 0.12rem 0 0.20rem;}
  .lawIformation{display: inline-block;color: #fff;vertical-align: top;margin-top:0.20rem;}
  .labelAdviser{background-color: #dab10c;color: #fff;font-size: 0.19rem;height: 0.22rem;line-height: 0.22rem;display: inline-block;}
  .lvshi1{width: 0.16rem;height: 0.18rem;margin: 0.02rem 0.04rem 0 0.04rem;}
  .dianhua{width: 0.96rem;height: 0.96rem;float: right;margin: 0.29rem 0.20rem 0 0;}
  .threePlates{width: 7.10rem;padding:0.40rem 0;border-radius: 0.20rem;background-color: #fff;display: inline-block;box-shadow: 0px 0px 0.28rem 0.02rem rgba(0,0,0,0.15);font-size:0.26rem;margin-bottom:0.30rem;text-align: left}
  .plate{width:0.86rem;height:0.86rem;display: inline-block;}
  .threePlate{vertical-align:top;display: inline-block;font-size: 0.26rem;color: #454545;text-align: center;width: 33%;margin: 0.32rem 0 0 0;position: relative;}
</style>

<template>
  <div>
    <div
      style="background:url(https://ai-cdn.lvwa18.com/img/rusticate/newbg.png) no-repeat;background-size:100% 100%;height: 7.21rem">
      <div class="headTransparent">
        <div class="framePortrait" bindtap="myClikc">
          <img src="/img/logoGov.png" class="userImg" />
        </div>
        <div class="hi">Hi，<template v-if="$store.state.userInfo.UserName">{{$route.query.openId=='dongbuxinqu07'?'公共法律服务中心':$store.state.userInfo.UserName}} </template><span>你好！</span></div>
      </div>
      <!---->
      <div style="text-align: center;font-size:0;font-weight:700;">
        <img src="https://ai-cdn.lvwa18.com/img/rusticate/sifa.png" class="sifa" />
        <div style="color:#fff;font-size: 0.48rem;">
          <!-- <span>成都市智慧公共法律服务</span> -->
          <span>{{$store.state.userInfo.Area_name}}智慧公共法律服务</span>
        </div>
        <div class="address">
          <!-- <span>{{$store.state.userInfo.Area_name}}{{$store.state.userInfo.Admin_name}}{{$store.state.userInfo.VillagesName}}</span> -->
          <span v-if="$route.query.openId=='dongbuxinqu07'">公共法律服务中心</span>
          <span v-else>{{$store.state.userInfo.Admin_name}}{{$store.state.userInfo.VillagesName}}</span>
        </div>
      </div>
      <div>
        <van-swipe class="my-swipe" indicator-color="white" @change="onChange" :initial-swipe="initialLawyers">
          <van-swipe-item  v-for="(item,index) in lawyersList" :key="index+'lawyer'">
            <div  class="lawyerInformation">
              <img class="lawyerImg" :src="item.img?item.img:'/img/logoGov.png'" />
              <div class="lawIformation">
                <div style="font-size: 0.36rem;">{{item.LawyerName}}
                  <div class="labelAdviser"
                       style="background: url(https://ai-cdn.lvwa18.com/img/rusticate/tagging.png) no-repeat center bottom;background-size:100% 100%;">
                    <img src="https://ai-cdn.lvwa18.com/img/rusticate/lvshi1.png" class="lvshi1" />
                    法律顾问
                  </div>
                </div>
                <div style="font-size: 0.24rem;margin:0 0 0.08rem;">{{item.LawyerPhone}}</div>
                <div style="font-size: 0.18rem;">
                  <span style="margin-left:0.05rem;">服务时间 9:00-18:00</span>
                </div>
              </div>
              <a :href="'tel:'+item.LawyerPhone"><img src="https://ai-cdn.lvwa18.com/img/rusticate/dianhua.png"
                                                      class="dianhua" bindtap="dial" /></a>
            </div></van-swipe-item>
        </van-swipe>
      </div>
      <!--咨询服务-->
      <div style="text-align: center">
        <div class="threePlates">
          <div style="font-size:0.3rem;font-weight:600;padding-bottom:0.10rem;text-align: center;">
            <img style="width:1.07rem;height: 0.11rem;"
              src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png" />
            咨询服务
            <img style="width:1.07rem;height: 0.11rem;"
              src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png" />
          </div>
          <div class="threePlate" @click="dialogue()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/plate0.png" class="plate" />
            <div><span>人工咨询</span></div>
          </div>
          <div class="threePlate" @click="selfHelp()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/plate1.png" class="plate" />
            <div><span>自助咨询</span></div>
          </div>
          <div class="threePlate" @click="videoCall()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/plate2.png" class="plate" />
            <div><span>视频咨询</span></div>
          </div>
        </div>
      </div>
      <div style="text-align: center">
        <div class="threePlates">
          <div style="font-size:0.3rem;font-weight:600;padding-bottom:0.10rem;text-align: center;">
            <img style="width:1.07rem;height: 0.11rem;"
              src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png" />
            申办服务
            <img style="width:1.07rem;height: 0.11rem;"
              src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png" />
          </div>
          <div class="threePlate" @click="legalAidListH()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon1.png" class="plate" />
            <div><span>法律援助</span></div>
          </div>
          <div class="threePlate" @click="mediateListH()"  v-if="$store.state.userInfo.AreaId == '2294'">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/icon_05.png" class="plate" />
            <div><span>人民调解</span></div>
          </div>
          <div class="threePlate" @click="mediateListH()" v-else="">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon2.png" class="plate" />
            <div><span>人民调解</span></div>
          </div>
          <div class="threePlate" @click="legalAid()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon7.png" class="plate" />
            <div><span>办理公证</span></div>
          </div>
        </div>
      </div>
      <div style="text-align: center">
        <div class="threePlates">
          <div style="font-size:0.3rem;font-weight:600;padding-bottom:0.10rem;text-align: center;">
            <img style="width:1.07rem;height: 0.11rem;"
              src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png" />
            查询服务
            <img style="width:1.07rem;height: 0.11rem;"
              src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png" />
          </div>
          <div class="threePlate" @click="marriage()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon6.png" class="plate" />
            <div><span>模拟判决</span></div>
          </div>
          <div class="threePlate" @click="download()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon8.png" class="plate" />
            <div><span>法律文书</span></div>
          </div>
          <div class="threePlate" @click="civilCode">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon5.png" class="plate" />
            <div><span>民法典</span></div>
          </div>
          <div class="threePlate" @click="typical()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/index-icon-exam.png" class="plate" />
            <div><span>典型案例</span></div>
          </div>
          <div class="threePlate" @click="judicial()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon10.png" class="plate" />
            <div><span>司法案例库</span></div>
          </div>
          <!---->

          <div class="threePlate" @click="jys(1)" v-if="$store.state.userInfo.AreaId == '2294'">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon17.png" class="plate" />
            <div><span>人民调解指南</span></div>
          </div>
          <div class="threePlate" @click="jys(2)" v-if="$store.state.userInfo.AreaId == '2294'">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon15.png" class="plate" />
            <div><span>律师事务所</span></div>
          </div>
          <div class="threePlate" @click="jys(3)" v-if="$store.state.userInfo.AreaId == '2294'">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon18.png" class="plate" />
            <div><span>法宣视频</span></div>
          </div>
          <div class="threePlate" @click="jys(4)" v-if="$store.state.userInfo.AreaId == '2294'">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon19.png" class="plate" />
            <div><span>法律援助指南</span></div>
          </div>
          <!-- <div class="threePlate"> -->
            <!-- <img src="https://ai-cdn.lvwa18.com/img/rusticate/-s-f_icon07.png" class="plate" /> -->
            <!-- <div><span>律师核验</span></div> -->
          <!-- </div> -->
          <!-- <div class="threePlate"> -->
            <!-- <img src="https://ai-cdn.lvwa18.com/img/rusticate/-s-f_icon08.png" class="plate" /> -->
            <!-- <div><span>企业征信</span></div> -->
          <!-- </div> -->
          <!-- <div class="threePlate"></div> -->
        </div>
      </div>
      <div style="text-align: center" v-if="$store.state.userInfo.AreaId == '2294'">
        <div class="threePlates">
          <div style="font-size:0.3rem;font-weight:600;padding-bottom:0.10rem;text-align: center;">
            <img style="width:1.07rem;height: 0.11rem;"
                 src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png" />
            法务滴滴
            <img style="width:1.07rem;height: 0.11rem;"
                 src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png" />
          </div>
          <div class="threePlate" @click="pdf('https://ai-cdn.lvwa18.com/img/rusticate/files/jian-yang/法律服务项目清单.pdf')">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon6.png" class="plate" />
            <div><span>法律服务配送中心</span></div>
          </div>
          <div class="threePlate" @click="yls()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon8.png" class="plate" />
            <div><span>云律所</span></div>
          </div>
          <div class="threePlate" @click="VR()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/f_icon5.png" class="plate" />
            <div><span>VR阵地</span></div>
          </div>
        </div>
      </div>
      <!---->
    </div>
    <Modal
        v-model="modal1" width="auto"
        title="法律服务配送中心" ok-text="关闭" cancel-text=" ">
      <div  id="demo"></div>
    </Modal>
  </div>
</template>

<script>
    import Pdfh5 from "pdfh5";
    import "pdfh5/css/pdfh5.css"
    export default {
        name: "CDLQYHome",
        data:function(){
          return{
            lawyerInfo:{
              name:'',
              phone:'',
              img:'',
            },
            showAll:false,
            UTCDay:0,
            lawyerArr:[
              {name:'刘春敏',phone:'18081873646',img:''},//星期天
              {name:'姚易沈',phone:'18780103922',img:''},//星期一
              {name:'刘晓宇',phone:'13408686561',img:''},//星期二
              {name:'宋娅楠',phone:'15276856292',img:''},//星期三
              {name:'陈孟龙',phone:'18123224877',img:''},//星期四
              {name:'赵小燕',phone:'17318936200',img:''},//星期五
              {name:'刘春敏',phone:'18081873646',img:''},//星期六
            ],
            addressInfo:{
              area:'',
              street:'',
              community:'',
            },
            lawyersList : [],
            initialLawyers : 0,
            pdfh5:  null,
            modal1: false,
          }
        },
        mounted(){
          var _this = this
          // _this.UTCDay = new Date().getUTCDay()
          _this.lawyersList = _this.$store.lawyersList
          _this.initialLawyers = _this.$store.initialLawyers
        },
        computed:{
          nickName:function(){
            if(this.$store.state.userInfoTF.nickName||window.sessionStorage.nickName){
              return this.$store.state.userInfoTF.nickName||window.sessionStorage.nickName
            }else{
              return false
            }
          }
        },
        methods : {
          onChange : function (e){
            this.$store.state.userInfo.LawyerId = this.lawyersList[e].LawyerId
            this.$store.state.userInfo.LawyerImg = this.lawyersList[e].LawyerImg
            this.$store.state.userInfo.LawyerName = this.lawyersList[e].LawyerName
            this.$store.state.userInfo.LawyerPhone = this.lawyersList[e].LawyerPhone
          },
          generateToken:function () {
            let _this = this
            return new Promise((resolve,reject)=>{
              _this.send({
                url:"/video-chat-token",
                method:'get',
                data:{
                  userId:'2'+this.$store.state.userInfo.UserId,
                  secret:_this.secret,
                  Token:window.sessionStorage.token,
                },
                success:function(res){
                  resolve(res)
                }
              })
            })
          },
          videoCall:async function(){
            let roomName = parseInt(Math.random()*100000)+this.$store.state.userInfo.UserId+this.$store.state.userInfo.LawyerId
            try {
              return AndroidMachine.startVideo(JSON.stringify({
                roomName:roomName,
                localUserId:'2'+this.$store.state.userInfo.UserId,
                remoteUserId:'1'+this.$store.state.userInfo.LawyerId,
                localUserLocation:'公共法律服务大厅',
                localUserName:'一体机用户',
                source:'法律服务一体机',
                roomToken:await this.generateToken()
              }))
            } catch (error) {
              this.$router.push({path:'/mobile/public/video-chat/single',query:{
                roomName:roomName,
                localUserId:'2'+this.$store.state.userInfo.UserId,
                remoteUserId:'1'+this.$store.state.userInfo.LawyerId,
                localUserLocation:'公共法律服务大厅',
                localUserName:'一体机用户',
                source:'法律服务一体机',
                roomToken:await this.generateToken()
              }})
            }
          },
          randomRoomID: function() {
            return parseInt(Math.random() * 9999)
          },
          dialogue:function(){
            this.addOrder(1)
            .then(()=>{this.$router.push({name:'CDLQYD'})})
          },
          // 统计订单类型
          addOrder  : function(orderTypeId){
            var _this = this
            return new Promise((resolve,reject)=>{
              _this.send({
                url:'/AddOrder',
                data:{
                  LawyerId: _this.$store.state.userInfo.LawyerId,
                  UserId: _this.$store.state.userInfo.UserId,
                  OrderTypeId:orderTypeId
                },
                success:function(res){
                  resolve()
                }
              })
            })

          },
          getLawyerInfo:function(){
            var _this = this
            _this.send({
              url:'/VillagesLawyer',
              data:{
                VillagesId:_this.$route.query.AreaId
              },
              success:function(res){
                _this.lawyerInfo.name = res.LawyerName
                _this.lawyerInfo.phone = res.LawyerPhone
                _this.lawyerInfo.img = res.LawyerImg
              }
            })
          },
          getAreaInfo:function(){
            var _this = this
            _this.send({
              url:'/VillagesArea',
              data:{
                VillagesId:_this.$route.query.AreaId
              },
              success:function(res){
                _this.addressInfo.area = res.AreaName
                _this.addressInfo.street = res.AdminName
                _this.addressInfo.community = res.VillagesName
              }
            })
          },
            selfHelp : function(){
              this.addOrder(2)
              .then(()=>{window.location = "https://panda.lvwa18.com/menuSelect?source=TFSMY"})
            },
            legalAidListH : function(){
                if(this.$store.state.userInfo.AreaId == '2294'){
                    this.addOrder(6)
                        .then(()=>{this.$router.push({name:'CJYlI',query:{userId:this.$store.state.userInfo.UserId,openId:this.$store.state.userInfo.UserOpenId}})})
                }else{
                    this.addOrder(6)
                        .then(()=>{this.$router.push({name:'legalAidListH',query:{id:this.$store.state.userInfo.UserId,AreaId:this.$store.state.userInfo.ProvinceId,pid:this.$store.state.userInfo.AreaId}})})
                }
            },
            mediateListH : function(){
              if(this.$store.state.userInfo.AreaId == '2294'){
                this.addOrder(8)
                .then(()=>{this.$router.push({name:'CJYMI',query:{userId:this.$store.state.userInfo.UserId,openId:this.$store.state.userInfo.UserOpenId}})})
              }else{
                this.addOrder(8)
                .then(()=>{this.$router.push({name:'mediateListH',query:{id:this.$store.state.userInfo.UserId,AreaId:this.$store.state.userInfo.ProvinceId,pid:this.$store.state.userInfo.AreaId}})})
              }

            },
            legalAid : function(){
                if(this.$store.state.userInfo.AreaId == '2294'){
                    this.addOrder(7)
                        .then(()=>{this.$router.push({name:'CJYNI',query:{userId:this.$store.state.userInfo.UserId,openId:this.$store.state.userInfo.UserOpenId}})})
                }
                else if (this.$store.state.userInfo.ProvinceId == '2277'){
                  this.addOrder(7)
                      .then(()=>{window.location = "https://sccd.egongzheng.com/H5/templates/index.html";})
                }else if(this.$store.state.userInfo.CityId == '1669'){
                  this.$router.push({name:'legalService',query:{type:7}})
                }
                else {
                  var md5 = require('md5');
                  var date = new Date()
                  var timestamp = date.valueOf().toString().substring(0, 10)
                  var dateNum = date.getFullYear() + '-'
                  dateNum += date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
                  dateNum += '-'
                  dateNum += date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
                  var url = 'https://notarization.lvwa18.com/#/?userid='+this.$store.state.userInfo.UserId +'&key=' + md5('cdssfjcdjyjdzxxjsyxgs' + timestamp + dateNum) + '&timestamp=' + timestamp
                    this.addOrder(7)
                        .then(()=>{window.location = url})
                }
            },
            marriage : function(){
              this.addOrder(4)
              .then(()=>{window.location = "https://panda.lvwa18.com/documentField?source=TFSMY";})
            },
            download : function(){
              this.addOrder(5)
              .then(()=>{window.location = "https://panda.lvwa18.com/select?source=TFSMY";})
            },
            civilCode : function(){
              this.addOrder(9)
              .then(()=>{window.location = "https://panda.lvwa18.com/dialogue?gid=12&source=TFSMY";})
            },
            typical : function(){
              this.addOrder(13)
              .then(()=>{window.location = "https://panda.lvwa18.com/dialogue?gid=13&source=TFSMY";})
            },
            judicial : function(){
              this.addOrder(13)
              .then(()=>{this.$router.push({name:'sfalk'})})
            },
            jys : function (index) {
                this.$router.push({name:'JYS',query:{index:index}})
            },
            pdf : function (url){
              this.modal1 = true
              var  pdfh5 = new  Pdfh5('#demo', {    pdfurl:  url});
            },
            yls: function (){
              this.$router.push({name:'JYYLS',})
            },
            VR: function (){
              window.location = "https://cj.lvwa18.com/mobile/customization/jian-yang/vr/list";
            },
        },
    }
</script>
