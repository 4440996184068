<!-- 使用排名 -->
<style scoped>
.panel{background-image:url(/img/manager/big-data/use-rangking/bg.png);background-size:100% 100%;padding:29px 98px;}
.title{background-image:url(/img/manager/big-data/use-rangking/child-title-bg.png);background-size:100% 100%;height:44px;line-height:44px;color:#39ffff;font-size:14px;font-weight:bold;}
.child-content{background-image:url(/img/manager/big-data/use-rangking/child-content-bg.png);background-size:100% 100%;padding:20px;min-height:394px;}
.child-list-title{color:#fff;font-size:14px;background-image:url(/img/manager/big-data/use-rangking/child-list-title-bg.png);background-size:100% 100%;height:46px;line-height:46px;}
.child-list-content{height:300px;overflow:auto;}
.child-list-content::-webkit-scrollbar{width:7px;height:6px}/* 设置滚动条的样式 */
.child-list-content::-webkit-scrollbar-thumb{border-radius: 10px;background:linear-gradient(to top,#008aff,#47cd93);}/* 滚动条滑块 */
.child-list{color:#fff;line-height:30px;}
.child-list-num{background-image:url(/img/manager/big-data/use-rangking/child-list-num-bg.png);background-size:26px 26px;background-position:center;min-width:46px;background-repeat:no-repeat;}
.child-list-item-import{font-weight:bold;}

</style>

<template>
  <div>
    <Modal v-model="userDataPanel" width="547" footer-hide :styles="{backgroundColor:'rgba(0,0,0,0)'}">
      <div style="margin:-16px;background-color:rgb(1,16,81);">
        <div class="title text-center">
          单位使用情况
        </div>
        <div style="padding:4px;"></div>
        <div class="child-content text-center">
          <Row class="child-list-title">
            <Col span="3">序号</Col>
            <Col span="7">区县名称</Col>
            <Col span="5">服务次数</Col>
            <Col span="4">服务档案</Col>
            <Col span="5">累计用户数</Col>
          </Row>
          <div class="child-list-content" style="width:100%;height:700px;">
            <Row v-for="(item,index) in useData" class="child-list text-center" style="font-size:10px;line-height:17px;" :style="index%2==0?'':'background-color:rgba(255,255,255,0.1)'">
              <Col span="3">
                <div class="inline-block">{{index<9?'0'+(index+1):index+1}}</div>
              </Col>
              <Col span="7">{{item.name}}</Col>
              <Col span="5" class="child-list-item-import">{{item.consultingCount}}</Col>
              <Col span="5" class="child-list-item-import">{{item.archivesCount}}</Col>
              <Col span="4" class="child-list-item-import">{{item.userCount}}</Col>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
    <div class="child-title">使用情况</div>
    <!-- <div style="padding:20px;"></div> -->
    <div class="panel">
      <Row :gutter="30">
        <Col span="12">
          <div class="title text-center">
            单位使用情况 <span v-if="useData.length" @click="userDataPanel = true" style="cursor:pointer;color:#fff">[全部]</span>
          </div>
          <div style="padding:4px;"></div>
          <div class="child-content text-center">
            <Row class="child-list-title">
              <Col span="3">序号</Col>
              <Col span="7">区县名称</Col>
              <Col span="5">服务次数</Col>
              <Col span="4">服务档案</Col>
              <Col span="5">累计用户数</Col>
<!--              <Col span="3">推广人数</Col>-->
            </Row>
            <div class="child-list-content">
              <div @click="jumpEvent(item)" v-for="(item,index) in useData" style="cursor: pointer;">
                <Row class="child-list text-center" :style="index%2==0?'':'background-color:rgba(255,255,255,0.1)'">
                    <Col span="3">
                      <div class="inline-block child-list-num">{{index<9?'0'+(index+1):index+1}}</div>
                    </Col>
                    <Col span="7">{{item.name.replace('司法局', '')}}</Col>
                    <Col span="5" class="child-list-item-import">{{item.consultingCount}}</Col>
                    <Col span="5" class="child-list-item-import">{{item.archivesCount}}</Col>
                    <Col span="4" class="child-list-item-import">{{item.userCount}}</Col>
                    <!--                <Col span="3" class="child-list-item-import">{{item.promotionCount}}</Col>-->
                </Row>
              </div>
            </div>
          </div>
        </Col>
        <Col span="12">
          <div class="title text-center">
            法律顾问使用情况
          </div>
          <div style="padding:4px;"></div>
          <div class="child-content text-center">
            <Row class="child-list-title">
              <Col span="3">序号</Col>
              <Col span="6">姓名</Col>
              <Col span="6">服务次数</Col>
              <Col span="5">档案数</Col>
              <Col span="4">评价数</Col>
            </Row>
            <div class="child-list-content">
              <Row v-for="(item,index) in lawyerData" class="child-list text-center" :style="index%2==0?'':'background-color:rgba(255,255,255,0.1)'">
                <Col span="3">
                  <div class="inline-block child-list-num">{{index<9?'0'+(index+1):index+1}}</div>
                </Col>
                <Col span="7">{{item.LawyerName}}</Col>
                <Col span="5" class="child-list-item-import">{{item.num}}</Col>
                <Col span="5" class="child-list-item-import">{{item.fileCount}}</Col>
                <Col span="4" class="child-list-item-import">{{item.Evaluate}}</Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <div style="padding:8px"></div>
      <Row>
        <Col span="24">
          <div style="height:200px" ref="echarts-useData"></div>
        </Col>
      </Row>
    </div>
  </div>

</template>

<script>
import * as echarts from 'echarts'
export default {
  name:'use-ranking',
  props:{
    adminId:{
      require:true,
    },
    electronicsList:{require:true},
  },
  data() {
    return {
      userDataPanel:false,
      useData:[//使用数据
        {name:'数据载入中',consultingCount:'.....',userCount:'.....'}
      ],
      lawyerData:[
        {name:'数据载入中',serveCount:'.....',archivesCount:'.....',evaluate:'.....',}
      ],
      echartsUseData:{//使用数据图标
        tooltip: {//提示框组件
          trigger: 'axis'//触发类型:坐标轴触发
        },
        legend:{//图例组件
          left:'40px',
          textStyle:{
            color:'#ffffff'
          }
        },
        grid:{//直角坐标系
          top:'40px',
          left:'0',
          right:'0',
          bottom:'0',
          containLabel:true,//设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {//x轴
          type: 'category',//类目轴
          data: ['加载中', '加载中', '加载中', '加载中', '加载中', '加载中', '加载中'],
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#ffffff",
            },
          },
          axisLabel:{
            formatter: function (value, index) {
              let str = value.split("")
                return str.join("\n")
            }
          },
        },
        yAxis: {//y轴
          type: 'value',//数值轴
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#ffffff",
            },
          },
        },
        series: [//折线/面积图
          // {
          //   name: '用户人数',
          //   data: [820, 932, 901, 934, 1290, 1330, 1320],
          //   type: 'line',//折线图
          //   symbol: 'circle',//标记的图形：圆面
          //   lineStyle: {//折线样式
          //     width:4,
          //   },
          //   symbolSize:10,//标记图形大小
          //   itemStyle:{//折线拐点标志的样式
          //     color:'#ceb827',
          //   }
          // },
          {
            name: '区域服务情况',
            data: [50, 80, 500, 130, 400, 390, 998],
            type: 'bar',//柱状图
            barWidth :8,//柱宽度
            color:{//柱子颜色渐变
              type: 'linear',//线性渐变
              x:0,
              y:0,
              x2:0,
              y2:1,
              colorStops: [
                {
                  offset:0,
                  color: '#76d7ff' //深色
                },
                {
                  offset:1,
                  color: '#1122ff' //亮色
                },
              ],
            },
            itemStyle:{//柱子的样式
              barBorderRadius:[5,5,5,5],
            },
          },
        ],
      },
    };
  },
  mounted() {
    echarts.init(this.$refs['echarts-useData']).setOption(this.echartsUseData)
    this.getUseData()
    this.getLawyerRanking()
  },
  methods: {
    jumpEvent: function (data){
      var _this = this
      for(var i in _this.electronicsList){
        if(_this.electronicsList[i].name == data.name.replace('司法局', '')){
          let adminLogin = _this.electronicsList[i].adminLogin;
          let adminPassword = _this.electronicsList[i].adminPassword;
          let routeData = _this.$router.resolve({name: 'managerLogin', params: { adminLogin, adminPassword },});
          let urlWithParams = `${routeData.href}?adminLogin=${adminLogin}&adminPassword=${adminPassword}`;
          window.open(urlWithParams, '_blank');
        }
      }
    },
    getUseData:function(){
      let _this = this
      _this.send({
        // url: '/DifficultCount',
        url: '/areaRank',
        loading:false,
        data: {
          AdminId:_this.adminId,
        },
        success: function (res) {
          _this.useData = []
          _this.echartsUseData.xAxis.data = []
          _this.echartsUseData.series[0].data = []
          // _this.echartsUseData.series[1].data = []
          let tableList = JSON.parse(JSON.stringify(res))
          let echartsList = JSON.parse(JSON.stringify(res))
          //按用户数排序
          tableList.sort((item,item1)=>{
            return item1.userCount-item.userCount
          })
          //按咨询数排序
          echartsList.sort((item,item1)=>{
            return item1.orderCount-item.orderCount
          })
          //推送数据到表格
          for(let index in tableList){
            _this.useData.push({
              name:tableList[index].AdminName,
              consultingCount:tableList[index].orderCount,
              userCount:tableList[index].userCount,
              archivesCount:tableList[index].fileCount,
              promotionCount:tableList[index].promotionCount,
            })
          }
          //推送数据到图表
          for(let index in echartsList){
            _this.echartsUseData.xAxis.data.push(echartsList[index].AdminName.replace('司法局',''))
            _this.echartsUseData.series[0].data.push(echartsList[index].orderCount)
          }
          echarts.init(_this.$refs['echarts-useData']).setOption(_this.echartsUseData)
        }
      })
    },
    //律师服务数据，多接口取值
    getLawyerRanking:function(){
      let _this = this
      _this.send({
        url: '/layerRank',
        data: {
          AdminId:_this.adminId,
        },
        success: function (res) {
          _this.lawyerData = res
        },
      })
    },
    //律师数据整理
    dataMerge:function(data,idName,rules){
      var tableData = []
      //整理数据
      data.map((item,index)=>{
        var tempArr = []
        for(var index1 in item){
          //从第一个数组取出adminId形成基准数据，对齐数据长度
          if(index==0){tableData.push({[idName.local]:item[index1][idName.remote]})}
          //格式化对象为数组
          tempArr.push(item[index1])
        }
        data[index] = tempArr
      })
      //根据基准数据找出子数据并合成至tableData，简化运算流程降低运算负载
      tableData.map((item)=>{
        rules.map(item1=>{
          item[item1.columnName] = data[item1.dataIndex].find((item2)=>{return item2[idName.remote]==item[idName.local]})[item1.remoteVariable[0]]
          //多层取值，最多支持到两层
          if(item1.remoteVariable[1]){
            item[item1.columnName] = item[item1.columnName][item1.remoteVariable[1]]
          }
        })
      })
      return tableData
    },
    //多接口取值方法
    requestHub:function(APIList){
      var _this = this
      var requestList = []
      var resultList = []
      return new Promise((resolve,reject)=>{
        APIList.map((api,index)=>{
          requestList.push(new Promise((resolve1,reject1)=>{
            _this.send({
              url:api,//律师
              data:{
                // StartTime:_this.dateSelecter.startTime,
                // EndTime:_this.dateSelecter.endTime,
                // Type:_this.dateSelecter.identification,
              },
              success:function(res){
                resultList[index]=res
                resolve1()
              },
              error:function(err){
                _this.$Notice.error({
                  title: '系统提示',
                  desc: '队列错误！请稍后再试',
                })
                reject1()
              }
            })
          }))
        })
        Promise.all(requestList)
        .then(res=>{resolve(resultList)})
        .catch(err=>{reject()})
      })
    },
  },
}
</script>
