import { render, staticRenderFns } from "./lawFirmendLogin.vue?vue&type=template&id=3ebbb13f&scoped=true&"
import script from "./lawFirmendLogin.vue?vue&type=script&lang=js&"
export * from "./lawFirmendLogin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ebbb13f",
  null
  
)

export default component.exports