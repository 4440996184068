<style scoped>
</style>

<template>
  <div>
    <template v-if="state=='edit'">
      <div>{{indexQ+1}}、单选题<Icon class="close-icon" type="md-close-circle" @click="$emit('del',indexQ)"></Icon></div>
      <Divider />
      <Row>
        <Col span="4" style="text-align: right;">题目：</Col>
        <Col span="20" style="text-align: left;"><Input v-model="content.title" placeholder="请录入题目"/></Col>
      </Row>
      <Row>
        <Col span="4" style="text-align: right;">分值：</Col>
        <Col span="20" style="text-align: left;"><Input style="width:40px;" size="small" v-model="content.score"/> 分</Col>
      </Row>
      <Divider />
      选项区域：
      <RadioGroup style="width:100%;" v-model="content.answer">
        <template v-for="(item,index) in content.label">
          <Row style="margin:0.2rem 0;" type="flex" align="middle">
            <Col span="2"><Radio disabled style="display:block;"></Radio></Col>
            <Col span="17"><Input v-model="content.label[index]" placeholder="请输入选项内容"/></Col>
            <Col span="5"><Button type="error" size="small" @click="content.label.splice(index,1)">删除</Button></Col>
          </Row>
        </template>
      </RadioGroup>
      <Button @click="content.label.push('在此填写选项内容')" size="small">添加选项</Button>
      <Divider />
      <div>选择答案：</div>
      <Row>
        <Col span="20" style="text-align: left;">
          <RadioGroup v-model="content.answer" style="display: block;text-align: left;">
            <template v-for="(item,index) in content.label">
              <Radio style="display:block;margin:0.2rem 0;white-space:pre-wrap;" :label="item"></Radio>
            </template>
          </RadioGroup>
        </Col>
      </Row>
    </template>
    <template v-if="state=='read'">
      <div>{{indexQ+1}}、单选题：{{content.title}}（{{content.score}}分）</div>
      <RadioGroup v-model="content.userAnswer">
        <template v-for="(item,index) in content.label">
          <Radio disabled style="display:block;margin:0.2rem 0;white-space:pre-wrap;" :label="item"></Radio>
        </template>
      </RadioGroup>
      <div>正确答案：{{content.answer.toString()}}</div>
      <div>得分情况：{{content.userScore}}分</div>
    </template>
    <template v-if="state=='grade'">
      <div style="text-align: left;">
        <div>{{indexQ+1}}、单选题：{{content.title}}（{{content.score}}分）</div>
        <RadioGroup v-model="content.userAnswer" style="text-indent:1em;">
          <template v-for="(item,index) in content.label">
            <Radio disabled style="display:block;margin:0.2rem 0;white-space:pre-wrap;" :label="item"></Radio>
          </template>
        </RadioGroup>
        <div style="text-indent:1em;">
          <div>正确答案：{{content.answer.toString()}}</div>
          <div>用户得分 <Input style="width:40px;" size="small" v-model="content.userScore"/></div>
        </div>
      </div>
    </template>
    <template v-if="state=='answer'">
      <div>{{indexQ+1}}、单选题：{{content.title}}（{{content.score}}分）</div>
      <RadioGroup v-model="content.userAnswer">
        <template v-for="(item,index) in content.label">
          <Radio style="display:block;margin:0.2rem 0;white-space:pre-wrap;" :label="item"></Radio>
        </template>
      </RadioGroup>
    </template>
  </div>
</template>

<script>
export default {
  name:'examRadio',
  props:{
    state:String,
    indexQ:Number,
    contentIn:Object,
  },
  data() {
    return {
      content:{
        title:'',
        answer:'',
        userAnswer:'',
        score:'',
        label:[],
        must:false,
        allowSubmission:false,
        userScore:'',
      },
    };
 },
  methods: {
    
  },
  mounted() {
    if(this.contentIn.title!=undefined){
      this.content = this.contentIn
    }
  },
  watch:{
    content:{
      handler(){
        if(this.content.must!=true||!this.isEmpty(this.content.answer)){
          this.content.allowSubmission = true
        }else{
          this.content.allowSubmission = false
        }
        this.$emit('change',this.content)
      },
      deep:true,
      immediate:true,
    }
  }
}
</script>