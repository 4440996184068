<template>
  <div style="padding:20px;">
    <div style="background-color: white;">
      <div style="font-size:16px;text-align: left;border-left:solid #de4f0b 3px;padding-left:5px">
        积分管理
      </div>
    </div>
    <Tabs :value="active" @on-click="activeClick">
      <TabPane label="区县积分" name="1"></TabPane>
      <TabPane label="乡镇（街道）前30" name="2"></TabPane>
      <TabPane label="村（社区）前30" name="3"></TabPane>
      <TabPane label="明白人前30" name="4"></TabPane>
      <TabPane v-if="information.Hierarchy == 3" :label="information.AdminName.replace('司法局','')+'乡镇（街道）排名'" name="5"></TabPane>
      <TabPane v-if="information.Hierarchy == 3 || information.Hierarchy == 4" :label="information.AdminName.replace('司法局','')+'村（社区）排名'" name="6"></TabPane>
      <TabPane v-if="information.Hierarchy == 3 || information.Hierarchy == 4" :label="information.AdminName.replace('司法局','')+'明白人排名'" name="7"></TabPane>
    </Tabs>
<!--    区县-->
    <Col span="8">
      <Row style="text-align: left;font-size: 10px" v-if="active == 1">
        <Col span="24">
          <Row style="background-color:#edeef0;min-height: 12px;" align="middle">
            <Col span="4" style="padding-left: 30px">序号</Col>
            <Col span="10" style="padding-left: 30px">区县</Col>
            <Col span="10" style="padding-left: 30px">总积分</Col>
          </Row>
        </Col>
        <Col span="24">
          <Row v-for="(item, index) in integralRanking.countyData" :style="{backgroundColor:index%2!=0?'#f5f6f7':'',minHeight:'12px'}" align="middle">
            <Col span="4" style="padding-left: 30px">{{index<9?'0'+parseInt(index+1):index+1}}</Col>
            <Col span="10" style="padding-left: 30px">{{item.AreaShortName}}</Col>
            <Col span="10" style="padding-left: 30px">{{item.scoreCount}}</Col>
          </Row>
        </Col>
      </Row>
    </Col>

<!--    街道-->
    <Row style="text-align: left;" v-if="active == 2 || active == 5">
      <DatePicker
          v-if="active == 5"
          @on-change="wipeData"
          @on-ok="insiderareScore()"
          type="datetimerange"
          format="yyyy-MM-dd"
          placeholder="请选择时间区间" style="margin-left:10px;">
      </DatePicker>
      <Col span="24">
        <Row style="background-color:#edeef0;min-height: 46px;" align="middle">
          <Col span="8">排名</Col>
          <Col span="8">乡镇（街道）</Col>
          <Col span="8">总积分</Col>
        </Row>
      </Col>
      <Col span="24">
        <Row v-for="(item, index) in integralRanking.streetData" :style="{backgroundColor:index%2!=0?'#f5f6f7':'',minHeight:'36px'}" align="middle">
          <Col span="8">{{index<9?'0'+parseInt(index+1):index+1}}</Col>
          <Col span="8">{{active == 2 ? item.AdminName : ''}}{{item.AreaShortName}}</Col>
          <Col span="8">{{item.scoreCount}}</Col>
        </Row>
      </Col>
    </Row>
<!--    村（社区）-->
    <Row style="text-align: left;" v-if="active == 3 || active == 6">
      <DatePicker
          v-if="active == 6"
          @on-change="wipeData"
          @on-ok="insiderVillageScore()"
          type="datetimerange"
          format="yyyy-MM-dd"
          placeholder="请选择时间区间" style="margin-left:10px;">
      </DatePicker>
      <Col span="24">
        <Row style="background-color:#edeef0;min-height: 46px;" align="middle">
          <Col span="8">排名</Col>
          <Col span="8">村（社区）</Col>
          <Col span="8">总积分</Col>
        </Row>
      </Col>
      <Col span="24">
        <Row v-for="(item, index) in integralRanking.villageData" :style="{backgroundColor:index%2!=0?'#f5f6f7':'',minHeight:'36px'}" align="middle">
          <Col span="8">
            <td v-if="(integralRanking.page - 1) * integralRanking.pageSize + index + 1 < 10">0{{ (integralRanking.page - 1) * integralRanking.pageSize + index + 1 }}</td>
            <td v-else>{{ (integralRanking.page - 1) * integralRanking.pageSize + index + 1 }}</td>
          </Col>
          <Col span="8">
            <div v-if="item.address != ''">{{item.address}}</div>
          </Col>
          <Col span="8">{{item.total}}</Col>
        </Row>
      </Col>
      <Page v-if="active == 6"
          :total="integralRanking.count"
          :page-size="integralRanking.pageSize"
          show-elevator
          show-total
          @on-change="integralRanking.page = $event;insiderVillageScore()"
      />
    </Row>
<!--    村（社区）-->
    <Row style="text-align: left;" v-if="active == 4 || active == 7">
      <DatePicker
          v-if="active == 7"
          @on-change="wipeData"
          @on-ok="insiderPeopleScore()"
          type="datetimerange"
          format="yyyy-MM-dd"
          placeholder="请选择时间区间" style="margin-left:10px;">
      </DatePicker>
      <Col span="24">
        <Row style="background-color:#edeef0;min-height: 46px;" align="middle">
          <Col span="6">排名</Col>
          <Col span="6">村（社区）</Col>
          <Col span="6">姓名</Col>
          <Col span="6">总积分</Col>
        </Row>
      </Col>
      <Col span="24">
        <Row v-for="(item, index) in integralRanking.insiderData" :style="{backgroundColor:index%2!=0?'#f5f6f7':'',minHeight:'36px'}" align="middle">
          <Col span="6">
            <td v-if="(integralRanking.page - 1) * integralRanking.pageSize + index + 1 < 10">0{{ (integralRanking.page - 1) * integralRanking.pageSize + index + 1 }}</td>
            <td v-else>{{ (integralRanking.page - 1) * integralRanking.pageSize + index + 1 }}</td>
          </Col>
          <Col span="6">{{item.address}}</Col>
          <Col span="6">
            <div v-if="item.village != ''">{{item.village}}</div>
          </Col>
          <Col span="6">{{item.total}}</Col>
        </Row>
      </Col>
      <Page v-if="active == 7"
            :total="integralRanking.count"
            :page-size="integralRanking.pageSize"
            show-elevator
            show-total
            @on-change="integralRanking.page = $event;insiderPeopleScore()"
      />
    </Row>
  </div>
</template>

<script>
export default {
  name: "integralManage",
  data (){
    return{
      active : '1',
      integralRanking : {
        countyData : [],
        streetData : [],
        villageData : [],
        insiderData : [],
        page : 1,
        pageSize : 15,
        count : 0,
        start : '',
        end : '',
      },
      information : {},
    }
  },
  mounted() {
      this.countyScoreList()
      this.information = window.sessionStorage
  },
  methods : {
    wipeData: function (date){
      console.log(date)
      this.integralRanking.start = date[0],
          this.integralRanking.end = date[1];
      if(date[0] == '' && date[1] == ''){
        // 当前街道数据
        if(this.active == 5){this.insiderareScore()}
        // 当前村（社区）
        if(this.active == 6){this.insiderVillageScore()}
        // 当前明白人
        if(this.active == 7){this.insiderPeopleScore()}
      }
    },
    activeClick : function (e){
      this.active = e
      this.integralRanking.page = 1
      this.integralRanking.count = 0
      // 区县排名
      if(e == 1){this.countyScoreList()}
      // 街道排名
      if(e == 2){this.streetScoreList()}
      // 村（社区）排名
      if(e == 3){this.villageScoreList()}
      // 明白人
      if(e == 4){this.insiderScoreList()}
      // 当前街道数据
      if(e == 5){this.insiderareScore()}
      // 当前村（社区）
      if(e == 6){this.insiderVillageScore()}
      // 当前明白人
      if(e == 7){this.insiderPeopleScore()}
    },

    insiderScoreList : function (){
      var _this = this
      _this.integralRanking.insiderData = []
      if (SYSCONFIG.location == 'chong-qing') {
        _this.send({
          url: '/insiderScoreList',
          loading: true,
          data: {
            AdminId: window.sessionStorage.AdminId,
          },
          success: function (res) {
            for(var i in res){
              _this.integralRanking.insiderData.push({
                village : res[i].village[0].InsiderName,
                total : res[i].total,
                address : res[i].address,
              })
            }
            _this.integralRanking.insiderData.sort((item,item1)=>{
              return item1.total-item.total
            })
          }
        })
      }else {
        _this.send({
          url: '/peopleScoreList',
          loading: true,
          data: {
            AdminId: window.sessionStorage.AdminId,
            page : 1,
            pageSize : 30,
          },
          success: function (res) {
            for(var i in res.List){
              _this.integralRanking.insiderData.push({
                village : res.List[i].insiderName,
                total : res.List[i].total,
                address : res.List[i].address,
              })
            }
            _this.integralRanking.insiderData.sort((item,item1)=>{
              return item1.total-item.total
            })
          }
        })
      }

    },
    insiderPeopleScore : function (){
      var _this = this
      _this.integralRanking.insiderData = []
      _this.send({
        url: '/insiderPeopleScore',
        loading: true,
        data: {
          start:_this.integralRanking.start,
          end:_this.integralRanking.end,
          AdminId: window.sessionStorage.AdminId,
          page : _this.integralRanking.page,
          pageSize : _this.integralRanking.pageSize,
        },
        success: function (res) {
          _this.integralRanking.count = res.Count
          for(var i in res.List){
            _this.integralRanking.insiderData.push({
              village : res.List[i].insiderName,
              total : res.List[i].total,
              address : res.List[i].address,
            })
          }
          _this.integralRanking.insiderData.sort((item,item1)=>{
            return item1.total-item.total
          })
        }
      })
    },
    villageScoreList : function (){
      var _this = this
      _this.integralRanking.villageData = []
      _this.send({
        url: '/villageScoreList',
        loading: true,
        data: {
          AdminId: window.sessionStorage.AdminId,
        },
        success: function (res) {
          for(var i in res){
            _this.integralRanking.villageData.push({
              address : res[i].address,
              total : res[i].total,
            })
          }
          _this.integralRanking.villageData.sort((item,item1)=>{
            return item1.total-item.total
          })
        }
      })
    },
    insiderVillageScore : function (){
      var _this = this
      _this.integralRanking.villageData = []
      _this.send({
        url: '/insiderVillageScore',
        loading: true,
        data: {
          start:_this.integralRanking.start,
          end:_this.integralRanking.end,
          AdminId: window.sessionStorage.AdminId,
          page : _this.integralRanking.page,
          pageSize : _this.integralRanking.pageSize,
        },
        success: function (res) {
          _this.integralRanking.count = res.Count
          for(var i in res.List){
            _this.integralRanking.villageData.push({
              address : res.List[i].address,
              total : res.List[i].total,
            })
          }
          _this.integralRanking.villageData.sort((item,item1)=>{
            return item1.total-item.total
          })
        }
      })
    },
    streetScoreList : function (){
      var _this = this
      _this.integralRanking.streetData = []
      if (SYSCONFIG.location == 'chong-qing') {
        _this.send({
          url: '/streetScoreList',
          loading: true,
          data: {
            AdminId: window.sessionStorage.AdminId,
          },
          success: function (res) {
            for(var i in res){
              _this.integralRanking.streetData.push({
                AreaShortName : res[i].AreaShortName,
                scoreCount : res[i].scoreCount,
                AdminName : res[i].pname[0].AreaShortName,
              })
            }
            _this.integralRanking.streetData.sort((item,item1)=>{
              return item1.scoreCount-item.scoreCount
            })
          }
        })
      }else {
        _this.send({
          url: '/streetScoreListOther',
          loading: true,
          data: {
            AdminId: window.sessionStorage.AdminId,
          },
          success: function (res) {
            for(var i in res){
              _this.integralRanking.streetData.push({
                AreaShortName : res[i].AreaShortName,
                scoreCount : res[i].scoreCount,
                AdminName : res[i].AreaShortName,
              })
            }
            _this.integralRanking.streetData.sort((item,item1)=>{
              return item1.scoreCount-item.scoreCount
            })
          }
        })
      }

    },
    insiderareScore : function (){
      var _this = this
      _this.integralRanking.streetData = []
      _this.send({
        url: '/insiderareScore',
        loading: true,
        data: {
          start:_this.integralRanking.start,
          end:_this.integralRanking.end,
          AdminId: window.sessionStorage.AdminId,
        },
        success: function (res) {
          for(var i in res){
            _this.integralRanking.streetData.push({
              AreaShortName : res[i].AreaShortName,
              scoreCount : res[i].scoreCount,
              AdminName : res[i].AreaShortName,
            })
          }
          _this.integralRanking.streetData.sort((item,item1)=>{
            return item1.scoreCount-item.scoreCount
          })
        }
      })
    },
    countyScoreList : function (){
      var _this = this
      _this.integralRanking.countyData = []
      if (SYSCONFIG.location == 'chong-qing') {
        _this.send({
          url: '/countyScoreList',
          data: {
            AdminId: window.sessionStorage.AdminId,
          },
          success: function (res) {
            for(var i in res){
              _this.integralRanking.countyData.push({
                AreaShortName : res[i].AreaShortName,
                scoreCount : res[i].scoreCount,
              })
            }
            _this.integralRanking.countyData.sort((item,item1)=>{
              return item1.scoreCount-item.scoreCount
            })
          }
        })
      }else {
        _this.send({
          url: '/cityScoreList',
          data: {
            AdminId: window.sessionStorage.AdminId,
          },
          success: function (res) {
            for(var i in res){
              _this.integralRanking.countyData.push({
                AreaShortName : res[i].AreaShortName,
                scoreCount : res[i].scoreCount,
              })
            }
            _this.integralRanking.countyData.sort((item,item1)=>{
              return item1.scoreCount-item.scoreCount
            })
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
