<template>
  <div>
  <!--    头部-->
    <div style="width: 7.5rem;background: linear-gradient(-90deg, #305FF3, #133BBD);text-align: center">
      <div style="font-size: 0.48rem;font-family: Microsoft YaHei;font-weight: bold;color: #FFFFFF;padding-top: 1rem">
        智慧村居法律服务平台
        <br>
        {{ villageAdviserData.areaName }}
      </div>
      <div style="margin-top: 0.5rem;font-size: 0;">
        <router-link :to="{ name: 'oneClickLogin', query: { key: $route.query.key, secret:require('js-base64').Base64.encode($route.query.secret), type: 1, data:'community'} }">
        <div style="width: 3.7rem;text-align: center;font-family: PingFang SC;font-weight: 800;color: #FFFFFF;display: inline-block;vertical-align: top;">
          <div style="font-size: 0.36rem;line-height: 0.28rem;margin-bottom: 0.12rem;">
            <div style="display: inline-block;vertical-align: top">{{ villageAdviserData.villagesCount }}</div>
            <div style="font-size: 0.16rem;margin-left: 0.05rem;line-height: 0.16rem;display: inline-block">个</div>
          </div>
          <div style="font-size: 0.24rem;font-weight: 500;line-height: 0.23rem;">村(社区)数</div>
        </div>
      </router-link>
        <div style="display: inline-block;width: 0.02rem;height: 0.61rem;background: #FFF;"></div>
        <router-link :to="{ name: 'oneClickLogin', query: { key: $route.query.key, secret:require('js-base64').Base64.encode($route.query.secret), type: 1, data:'lawyerManagement' } }">
        <div style="width: 3.7rem;text-align: center;font-family: PingFang SC;font-weight: 800;color: #FFFFFF;display: inline-block;vertical-align: top;">
          <div style="font-size: 0.36rem;line-height: 0.28rem;margin-bottom: 0.12rem;">
            <div style="display: inline-block;vertical-align: top">{{ villageAdviserData.AdviserCount }}</div>
            <div style="font-size: 0.16rem;margin-left: 0.05rem;display: inline-block;line-height: 0.16rem;">人</div>
          </div>
          <div style="font-size: 0.24rem;font-weight: 500;line-height: 0.23rem;">法律顾问</div>
        </div>
      </router-link>
      </div>
      <div style="padding:0.3rem"></div>
    </div>
  <div style="background-color: rgb(237,237,237)">
    <!--      待办数据-->
    <div style="text-align: center;">
      <div style="width: 6.9rem;background: #FFFFFF;border-radius: 0.04rem;font-size: 0;display: inline-block;text-align: left;margin-top: 0.3rem;padding: 0 0 0.3rem">
        <div style="margin-top: 0.3rem">
          <div style="width: 0.08rem;height: 0.38rem;background: #2D5BED;display: inline-block;vertical-align: top"></div>
          <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.29rem;margin: 0.04rem 0 0 0.2rem">待办数据</div>
        </div>
        <div>
          <div class="dataStatistics" v-for="(value, index) in auditData" :style="{ background: value.background }" @click="lookOver(value.type)">
            <div style="padding: 0.52rem 0 0 0.3rem;font-family: PingFang SC;color: #FFFFFF;">
              <div style="line-height: 0.23rem;font-size: 0.24rem;font-weight: 500;">{{ value.name }}</div>
              <div style="line-height: 0.32rem;font-size: 0.42rem;font-weight: bold;margin-top: 0.2rem">{{ value.value }}<span style="font-size: 0.16rem;margin-left: 0.05rem">{{ value.unit }}</span></div>
            </div>
            <img :src="'/img/dataStatistics/iconTJ' + '' + value.img + '.png'" style="position: absolute;right: 0.2rem;top: 0.14rem;width: 1.52rem;height: 1.52rem">
          </div>
        </div>
      </div>
    </div>
    <!--    数据统计-->
    <div style="text-align: center;">
      <div style="width: 6.9rem;background: #FFFFFF;border-radius: 0.04rem;font-size: 0;display: inline-block;text-align: left;margin-top: 0.3rem;padding: 0 0 0.3rem">
        <div style="margin-top: 0.3rem">
          <div style="width: 0.08rem;height: 0.38rem;background: #2D5BED;display: inline-block;vertical-align: top"></div>
          <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.29rem;margin: 0.04rem 0 0 0.2rem">数据统计</div>
        </div>
        <div>
          <div class="dataStatistics" v-for="(value, index) in statisticsData" :style="{ background: value.background }" @click="lookOver(value.type, value.routeName)">
            <div style="padding: 0.52rem 0 0 0.3rem;font-family: PingFang SC;color: #FFFFFF;">
              <div style="line-height: 0.23rem;font-size: 0.24rem;font-weight: 500;">{{ value.name }}</div>
              <div style="line-height: 0.32rem;font-size: 0.42rem;font-weight: bold;margin-top: 0.2rem">{{ value.value }}<span style="font-size: 0.16rem;margin-left: 0.05rem">{{ value.unit }}</span></div>
            </div>
            <img :src="'/img/dataStatistics/iconTJ' + '' + value.img + '.png'" style="position: absolute;right: 0.2rem;top: 0.14rem;width: 1.52rem;height: 1.52rem">
          </div>
        </div>
      </div>
    </div>
<!--     村居治理档案 -->
<!--      <div style="text-align: center;">-->
<!--        <div style="width: 6.9rem;background: #FFFFFF;border-radius: 0.04rem;font-size: 0;display: inline-block;text-align: left;margin-top: 0.3rem">-->
<!--          <div style="margin-top: 0.3rem">-->
<!--            <div style="width: 0.08rem;height: 0.38rem;background: #2D5BED;display: inline-block;vertical-align: top"></div>-->
<!--            <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.29rem;margin: 0.04rem 0 0 0.2rem">村居治理档案</div>-->
<!--          </div>-->
<!--          <div style="display: inline-block;font-family: PingFang SC;width: 2.15rem;text-align: center;margin: 0.4rem 0">-->
<!--              <div style="display: inline-block">-->
<!--                <div style="line-height: 23px;font-size: 24px;font-weight: 500;color: #B5B5B5;margin-top: 0.12rem;">电子档案</div>-->
<!--                <div style="line-height: 32px;font-size: 42px;font-weight: bold;color: #535353;margin-top: 0.2rem;">859<span style="font-size: 0.14rem;margin-left: 0.05rem">份</span></div>-->
<!--              </div>-->
<!--              <div style="width: 0.02rem;height: 1rem;background: #F3F3F3;display: inline-block;vertical-align: top;float: right;margin-right: -0.02rem"></div>-->
<!--          </div>-->
<!--          <div style="display: inline-block;font-family: PingFang SC;width: 2.15rem;text-align: center;margin: 0.4rem 0">-->
<!--              <div style="display: inline-block">-->
<!--                <div style="line-height: 23px;font-size: 24px;font-weight: 500;color: #B5B5B5;margin-top: 0.12rem;">值班打卡</div>-->
<!--                <div style="line-height: 32px;font-size: 42px;font-weight: bold;color: #535353;margin-top: 0.2rem;">859<span style="font-size: 0.14rem;margin-left: 0.05rem">次</span></div>-->
<!--              </div>-->
<!--              <div style="width: 0.02rem;height: 1rem;background: #F3F3F3;display: inline-block;vertical-align: top;float: right;margin-right: -0.02rem"></div>-->
<!--          </div>-->
<!--          <div style="display: inline-block;font-family: PingFang SC;width: 2.15rem;text-align: center;margin: 0.4rem 0">-->
<!--              <div style="display: inline-block">-->
<!--                <div style="line-height: 23px;font-size: 24px;font-weight: 500;color: #B5B5B5;margin-top: 0.12rem;">服务时长</div>-->
<!--                <div style="line-height: 32px;font-size: 42px;font-weight: bold;color: #535353;margin-top: 0.2rem;">859<span style="font-size: 0.14rem;margin-left: 0.05rem">小时</span></div>-->
<!--              </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--   7日服务数据-->
      <div style="text-align: center;">
        <div style="width: 6.9rem;background: #FFFFFF;border-radius: 0.04rem;font-size: 0;display: inline-block;text-align: left;margin-top: 0.3rem">
          <div style="margin-top: 0.3rem">
            <div style="width: 0.08rem;height: 0.38rem;background: #2D5BED;display: inline-block;vertical-align: top"></div>
            <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.29rem;margin: 0.04rem 0 0 0.2rem">7日服务数据</div>
          </div>
          <div>
            <div style="width:6.5rem;height:3.26rem" ref="echarts-serveDate" class="inline-block"></div>
          </div>
        </div>
      </div>
<!--   服务类别排名-->
      <div style="text-align: center;">
        <div style="width: 6.9rem;background: #FFFFFF;border-radius: 0.04rem;font-size: 0;display: inline-block;text-align: left;margin-top: 0.3rem">
          <div style="margin-top: 0.3rem">
            <div style="width: 0.08rem;height: 0.38rem;background: #2D5BED;display: inline-block;vertical-align: top"></div>
            <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.29rem;margin: 0.04rem 0 0 0.2rem">服务类别排名</div>
          </div>
          <div>
            <div style="width:6.5rem;height:6.95rem" ref="echarts-serveRanking" class="inline-block"></div>
          </div>
        </div>
      </div>
<!--   使用情况-->
      <div style="text-align: center;">
        <div style="width: 6.9rem;background: #FFFFFF;border-radius: 0.04rem;font-size: 0;display: inline-block;text-align: left;margin-top: 0.3rem">
          <div style="margin-top: 0.3rem">
            <div style="width: 0.08rem;height: 0.38rem;background: #2D5BED;display: inline-block;vertical-align: top"></div>
            <div style="display: inline-block;font-size: 0.3rem;font-family: PingFang SC;font-weight: bold;color: #333333;line-height: 0.29rem;margin: 0.04rem 0 0 0.2rem">使用情况</div>
<!--            <div style="float: right">-->
<!--                <div style="width: 0.9rem;height: 0.36rem;background: #2D5BED;border-radius: 0.18rem;text-align: center;font-size: 0.2rem;-->
<!--              font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.36rem;display: inline-block;vertical-align: top;margin-right: 0.2rem">单位</div>-->
<!--                <div style="width: 1.16rem;height: 0.36rem;background: rgba(130,130,130,0);border: 1px solid rgba(221,221,221,0.77);border-radius: 0.18rem;text-align: center;-->
<!--              font-size: 0.2rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.36rem;display: inline-block;margin-right: 0.2rem">法律顾问</div>-->
<!--            </div>-->
          </div>
          <div>
              <div class="rankingTitle">
                  <div style="width:7%;">序号</div>
                  <div style="width: 30%">单位名称</div>
                  <div>服务次数</div>
                  <div>服务档案</div>
                  <div>累计用户数</div>
              </div>
              <div class="rankingList" v-for="(value,index) in useRankingData" style="color: #333;">
                <div style="width:7%;">{{index+1}}</div>  
                <div style="width: 30%;white-space: nowrap;overflow: hidden;vertical-align: top;">
                    <span v-if="value.CityName">{{ value.CityName.replace('重庆市', '') }}</span>
                    <span>{{value.AdminName||value.VillagesName}}</span>
                  </div>
                  <div>{{value.orderCount}}</div>
                  <div>{{value.fileCount}}</div>
                  <div>{{value.userCount}}</div>
              </div>
          </div>
        </div>
      </div>
      <div style="padding:0.5rem;"></div>
      <div style="text-align: center;">
        <div>重庆市司法局&nbsp;&nbsp;&nbsp;&nbsp;重庆市大数据发展局&nbsp;&nbsp;&nbsp;&nbsp;监制</div>
        <div>重庆数字公司&nbsp;&nbsp;&nbsp;&nbsp;重庆市律蛙科技公司&nbsp;&nbsp;&nbsp;&nbsp;支持</div>
      </div>
      <div style="padding:0.2rem;"></div>
    </div>
    
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "statistics",
  data(){
    return{
      // 数据统计
      statisticsData: [
        { name: '注册用户', value: 0, type: 0, img: '0',unit:'人',background:'linear-gradient(-90deg, #F7C14B, #FF9715)',routeName:'userAdministration'},
        { name: '服务数', value: 0, type: 1, img: '1' ,unit:'次',background:'linear-gradient(-90deg, #46BAFF, #257BFF)',routeName:'consultingService'},
        { name: '服务档案数', value: 0, type: 2, img: '2' ,unit:'份',background:'linear-gradient(-90deg, #EB9BFB, #5E6AFD)',routeName:'MAL'},
        { name: '值班打卡', value: 0, type: 3, img: '3',unit:'次', background:'linear-gradient(-90deg, #55E9BD, #20B582)',routeName:'punchIn'},
      ],
      // 待办数据
      auditData : [
        { name: '待审核档案', value: 0, type: 4, img: '4',unit:'份', background:'linear-gradient(-90deg, #4ED8F1, #02A3C6)'},
        { name: '待处理咨询', value: 0, type: 5, img: '5',unit:'个', background:'linear-gradient(-90deg, #7F89F4, #404BCA)'},
      ],
      // 7日服务数据
      echartsServeData:{
        tooltip: {//提示框组件
          trigger: 'axis'//触发类型:坐标轴触发
        },
        grid:{//直角坐标系
          top:'5%',
          left:'3%',
          right:'4%',
          bottom:'3%',
          containLabel:true,//设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {//x轴
          boundaryGap:false,//坐标轴两边留白策略
          type: 'category',//类目轴
          data: ['加载中', '加载中', '加载中', '加载中', '加载中', '加载中', '加载中'],
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#999",
            }
          },
        },
        yAxis: {//y轴
          type: 'value',//数值轴
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#999",
            },
          },
        },
        series: [{//折线/面积图
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: 'line',//折线图
          smooth: true,//是上色折现覆盖区域
          areaStyle: {},
          lineStyle: {//折线样式
            width:4,
          },
          color:{//色彩渐变
            type: 'linear',//线性渐变
            x:0,
            y:1,
            x2:1,
            y2:0,
            colorStops: [
              {
                offset:0,
                color: '#008aff' //蓝色
              },
              {
                offset:1,
                color: '#f950ff' //紫色
              },
            ],
          },
        }],
      },
      //服务类别排名
      echartsServeRanking:{
        tooltip: {//提示框组件
          trigger: 'axis',//触发类型:坐标轴触发
          axisPointer: {
            type: 'shadow',
          },
        },
        grid:{//直角坐标系
          top:'5%',
          left:'3%',
          right:'4%',
          bottom:'3%',
          containLabel:true,//设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {//x轴
          type: 'value',//数值轴
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#999",
            }
          },
          splitLine: {//取消x轴刻度
            show: false
          },
        },
        yAxis: {//y轴
          type: 'category',//类目轴
          data: [],
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#999",
              background : "#E1E9FE",
            },
          },
        },
        series: [//折线/面积图
          {
            data: [],
            showBackground: true,
            backgroundStyle: {//设置背景样式
              color: "#E1E9FE",
              barBorderRadius: 10,
            },
            type: 'bar',//柱状图
            barWidth : 10,//柱宽度
            color:{//柱子颜色渐变
              type: 'linear',//线性渐变
              x:0,
              y:1,
              x2:1,
              y2:0,
              colorStops: [
                {
                  offset:1,
                  color: '#2D5BED' //蓝色
                },
              ],
            },
            itemStyle:{//柱子的样式
              barBorderRadius:[5,5,5,5],
            },
          }
        ],
      },
      //社区-顾问数
      villageAdviserData : [],
      //使用情况
      useRankingData : [],
    }
  },
  mounted() {
    if(!this.$route.query.key||!this.$route.query.secret){
      this.$router.push('/')
    }
    // 7日服务数据
    this.getServeData()
    //服务类别排名
    this.getServeRanking()
    //  社区-顾问数
    this.villageAndAdviserCount()
    //数据统计
    this.dataCount()
    //使用情况
    this.usageData()
    //待办数据
    this.auditevent()
  },
  methods: {
    //咨询代办
    auditevent : function (){
      var _this = this
      // 咨询
      _this.send({
        url: '/filesDealtCount',
        data: {
          id: this.$route.query.id,
          Token: '1',
        },
        success: function (res) {
          for(var i in _this.auditData){
            if(_this.auditData[i].type == 5){
              _this.auditData[i].value = res
            }
          }
        }
      })
      // 档案
      _this.send({
        url: '/adviceCount',
        data: {
          id: this.$route.query.id,
          Token: '1',
        },
        success: function (res) {
          for(var i in _this.auditData){
            if(_this.auditData[i].type == 4){
              _this.auditData[i].value = res
            }
          }
        }
      })
    },
    // 数据统计点击
    lookOver : function (type,routeName){
      var _this = this
      // 待审核档案 == 4 - 待处理咨询 == 5
      if(type == 4 || type == 5){
        this.$router.push({ name: 'auditList',query:{type:type,token:this.$route.query.token,id:this.$route.query.id,key:this.$route.query.key,secret:require('js-base64').Base64.encode(this.$route.query.secret)} })
      }else {
        _this.$router.push({ name: 'oneClickLogin' ,query:{key:this.$route.query.key,secret:require('js-base64').Base64.encode(this.$route.query.secret),type:1,data:routeName}})
      }
    },
    //使用情况
    usageData : function (){
      var _this = this
      _this.send({
        url: '/UsageData',
        data: {
          id: this.$route.query.id,
          Token: '1',
        },
        success: function (res) {
          res.sort((item,item1)=>{
            return item1.userCount-item.userCount
          })
          _this.useRankingData = res
        }
      })
    },
    //数据统计
    dataCount : function (){
      var _this = this
      _this.send({
        url: '/dataCount',
        data: {
          id: this.$route.query.id,
          Token: '1',
        },
        success: function (res) {
          for(var i in _this.statisticsData){
            if(_this.statisticsData[i].type == 0){
              _this.statisticsData[i].value = res.userCount
            }
            if(_this.statisticsData[i].type == 1){
              _this.statisticsData[i].value = res.orderCount
            }
            if(_this.statisticsData[i].type == 2){
              _this.statisticsData[i].value = res.fileData
            }
            if(_this.statisticsData[i].type == 3){
              _this.statisticsData[i].value = res.clock
            }
          }
        }
      })
    },
    // 村居顾问数
    villageAndAdviserCount : function (){
      var _this = this
      _this.send({
        url: '/villageAndAdviserCount',
        data: {
          id: this.$route.query.id,
          Token: '1',
        },
        success: function (res) {
          _this.villageAdviserData = res
        }
      })
    },
    // 7日服务数据
    getServeData:function(){
      let _this = this
      _this.send({
        url: '/getTenData',
        data: {
          id: this.$route.query.id,
          Token: '1',
        },
        success: function (res) {
          _this.echartsServeData.series[0].data = []
          _this.echartsServeData.xAxis.data = []
          for(let i = 0;i<res.list.day.length;i++){//取前7日数据，倒序输出
            if(i > 6){
              continue
            }
            let count = 0
            for(let index in res.list.day[i]){
              if(index=='time'){continue}
              count += parseInt(res.list.day[i][index])
            }
            _this.echartsServeData.xAxis.data.push(res.list.day[i].time.substring(5,res.list.day[i].time.length))
            _this.echartsServeData.series[0].data.push(count)
          }
          if(_this.$refs['echarts-serveDate']){
            echarts.init(_this.$refs['echarts-serveDate']).setOption(_this.echartsServeData)
          }
        }
      })
    },
    // 服务类别排名
    getServeRanking:function(){
      let _this = this
      _this.send({
        url: '/serviceType',
        data: {
          id: this.$route.query.id,
          Token: '1',
        },
        success: function (res) {
          _this.echartsServeRanking.series[0].data = []
          _this.echartsServeRanking.yAxis.data = []
          //删除有效咨询
          for(let index in res){
            if(res[index].OrderTypeId=='12'){
              res.splice(index,1)
            }
          }
          //数据排序
          res.sort((item,item1)=>{
            return item1.count-item.count
          })
          res.length = 10
          //倒序输出
          for(let i=res.length-1;i>=0;i--){
            _this.echartsServeRanking.yAxis.data.push(res[i].OrderTypeTitle)
            _this.echartsServeRanking.series[0].data.push(res[i].count)
          }
          // for(let i in res){
          //   _this.echartsServeRanking.yAxis.data.push(res[i].OrderTypeTitle)
          //   _this.echartsServeRanking.series[0].data.push(res[i].count)
          // }
          if(_this.$refs['echarts-serveRanking']){
            echarts.init(_this.$refs['echarts-serveRanking']).setOption(_this.echartsServeRanking)
          }
        }
      })
    },
  }
}
</script>

<style scoped>
    .dataStatistics{
      width: 3rem;
      height: 1.8rem;
      border-radius: 0.04rem;position: relative;margin: 0.3rem 0 0 0.3rem;display: inline-block;
    }
    .rankingTitle{width: 6.9rem;height: 0.57rem;background: #EEF3FF;margin-top: 0.4rem;text-align: center}
    .rankingTitle div{width: 20%;font-size: 0.2rem;font-family: PingFang SC;font-weight: 500;color: #999999;line-height: 0.57rem;display: inline-block}
    .rankingList{text-align: center}
    .rankingList div{width: 20%;font-size: 0.24rem;font-family: PingFang SC;font-weight: bold;line-height: 0.65rem;display: inline-block}
</style>