<style scoped>
.bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
.introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
.introduce-video-player{width:100%;}
.mediator-list{color:#FFF;}
.mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
.mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
.introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
.introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
.introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <div class="introduce-text">
      <div class="introduce-text-title">申办继承公证需要的基础材料</div>
      <div class="introduce-text-content">
        1、所有继承人的身份证原件（若身份证遗失，可带临时身份证。2万以下的小额存款继承，未到公证处的继承人要提供身份证复印件；房屋继承或存款2万以上的，所有的继承人均要带身份证到公证处办理，在外地的继承人不到场的在外地办理放弃声明公证或委托公证）；</br>
        2、死者的户口本原件（若已经更换新户口本，则带申办继承的人的户口本）；</br>
        3、死者的结婚证/离婚证及离婚协议原件（若已遗失，则到简阳市档案局查结婚/离婚档案。查无档案的,提供死者的工作档案或提供档案局的无档案证明）；</br>
        4、遗产证明（房产证+国土证或商品房买卖合同、存折或存款证明或拆迁协议+交割单或保险合同、股金、股权证明等死者的财产所有权证明材料）；</br>
        5、居民死亡户籍注销证明或医院出具的死亡证明原件（包括：死者本人、若死者的继承人中有2003年之后死亡的，则还需已死亡继承人的死亡证明或居民死亡户籍注销证明）；</br>
        6、亲属关系表（填本处提供的专用表格，表内填写死者的配偶、父母、子女，并由证明机构盖章或找两个以上熟悉死者家庭情况的证人带身份证到公证处证明亲属关系。配偶、父母、子女中有死亡的只填写姓名和死亡时间，此表只能一人笔迹且用同一颜色的签字笔或钢笔填写，用其他笔填写的无效）；</br>
        7、公证员要求的其他资料（公证员在审查基础材料之后判断）</br>
        <div style="padding:0.15rem;"></div>
        注意：1、申办放弃继承的，本人必须亲自到公证处来办理；</br>
        2、若申请人不会写自己的名字，需带上自己的私章；</br>
        3、未成年人和神志不清、患精神病等不能清楚表达自己意思的继承人不能申办放弃继承公证，只能继承遗产并由监护人代为申请。</br>
        4、继承公证主要程序：咨询—办理—调查核实—出证</br>
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
    <Row style="text-align: center;">
      <Col span="24"><a href="https://scjys.egongzheng.com/H5/templates/index.html"><Button type="warning" style="width:4rem;">去 办 理</Button></a></Col>
    </Row>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'guild3',
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {

  },
}
</script>
