//手机端路由模块加载器 Zima -- 2020-09-23
import mdPage from '@/views/mobile/mdPage'
import mediate from './mediate'
import open from './open'
import _public from './public'
import customization from './customization/customization'
import dataStatistics from './dataStatistics'

var routes = [
  {
    path:'/mobile',
    component:mdPage,
    children:new Array().concat(
      customization.routes,//定制功能路由
      mediate.routes,//人民调解路由
      open.routes,//开放平台路由
      _public.routes,//开放平台路由
      dataStatistics.routes,//数据统计
    )
  }
]

export default {
  routes:routes
}