<style scoped>
.header {
  background-image: url(/img/mediate/banner.png);
  height: 2.6rem;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
</style>

<template>
  <div style="background-color:#f8f9fb;">
    <div class="header"></div>
    <div style="padding:0.3rem;">
      <stepMenu :step="2" :menu="['纠纷类型','基本信息','机构所在']"></stepMenu>
      <div style="padding:0.3rem"></div>
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
        <FormItem label="" prop="sfcl">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证件：</div>
            <div style="text-align: center;">
              <Upload
                action="https://vn-server.lvwa18.com/api/upload_file_H5"
            　　:on-success="Picture"
                :default-file-list="uploadPicture"
                :on-remove="deleteValuePicture" accept=".jpg,.bmp"
              >
                <img v-if="formValidate.sfcl" :src="formValidate.sfcl" style="width:4.74rem;height:3.3rem;"/>
                <img v-else src="/img/legalAid/IDcard.png" style="width:4.74rem;height:3.3rem;"/>
                <div>点击拍摄/更换身份证件照片</div>
                <div>1.身份证件上的信息不能被遮挡，且清晰可见；</div>
                <div>2.支持jpg/bmp格式，最大不超过2MB。</div>
              </Upload>
            </div>
        </FormItem>
        <template v-if="formValidate.sfcl">
          <FormItem label prop="applyName">
            <div class="inline-block">
              <span style="color: red;padding: 0 0.1rem;">*</span>姓 名：
            </div>
            <Input v-model="formValidate.applyName" placeholder></Input>
          </FormItem>
          <!---->
          <FormItem label prop="sex">
            <div>
              <span style="color: red;padding: 0 0.1rem;">*</span>性别：
            </div>
            <RadioGroup v-model="formValidate.sex">
              <Radio label="男">男</Radio>
              <Radio label="女">女</Radio>
            </RadioGroup>
          </FormItem>
          <!---->
          <FormItem label prop="idCard">
            <div class="inline-block">
              <span style="color: red;padding: 0 0.1rem;">*</span>身份证号码：
            </div>
            <Input v-model="formValidate.idCard" placeholder></Input>
          </FormItem>
          <!---->
          <FormItem label prop="nation">
            <div class="inline-block">
              <span style="color: red;padding: 0 0.1rem;">*</span>民族：
            </div>
            <Select v-model="formValidate.nation" placeholder>
              <Option :value="value.EthnicityName" v-for="(value,index) in nation">{{value.EthnicityName}}</Option>
            </Select>
          </FormItem>
          <!---->
          <FormItem label prop="birthday">
            <div class="inline-block">
              <span style="color: red;padding: 0 0.1rem;">*</span>出生日期：
            </div>
            <Input v-model="formValidate.birthday" placeholder="如：xxxx-xx-xx"></Input>
          </FormItem>
          <!---->
          <FormItem label prop="age">
            <div class="inline-block">
              <span style="color: red;padding: 0 0.1rem;">*</span>年龄：
            </div>
            <Input v-model="formValidate.age" placeholder></Input>
          </FormItem>
          <!---->
          <!--<FormItem label="" prop="city">-->
          <!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>住所地址：</div>-->
          <!--<Cascader :data="address" v-model="formValidate.dz"></Cascader>-->
          <!--</FormItem>-->
          <FormItem label prop="address">
            <div class="inline-block">
              <span style="color: red;padding: 0 0.1rem;">*</span>请填写住所地址：
            </div>
            <Input v-model="formValidate.address" placeholder="请填写住所地址"></Input>
          </FormItem>
          <!---->
          <FormItem label prop="phone">
            <div class="inline-block">
              <span style="color: red;padding: 0 0.1rem;">*</span>联系电话：
            </div>
            <Input v-model="formValidate.phone" placeholder type="number"></Input>
          </FormItem>
          <Checkbox v-model="social" style="color:red">本人承诺以上所填内容和提交的证件、证明材料均为真实</Checkbox>
          <!---->
<!--          <FormItem label prop="sfclmc">
            <div class="inline-block">
              <span style="color: red;padding: 0 0.1rem;">*</span>身份证：
            </div>
            <div>
              1.身份证上的信息不能被遮挡，且清晰可见；
              2.照片支持jpg/bmp格式，最大不超过2MB。
            </div>
            <Upload
              action="https://vn-server.lvwa18.com/api/upload_file_H5"
              　　:on-success="Picture"
              :default-file-list="uploadPicture"
              :on-remove="deleteValuePicture"
            >
              <Button icon="md-add">上传居民身份证</Button>
            </Upload>
          </FormItem> -->
        </template>
      </Form>
      <div style="padding: 0.3rem;"></div>
      <div style="text-align: center;" v-if="formValidate.sfcl">
        <router-link :to="{name:'mediateStep1'}">
          <Button type="primary" style="width:3rem;">上一步</Button>
        </router-link>
        <span style="padding:0 0.3rem"></span>
        <router-link :to="{name:'mediateStep3'}">
          <Button type="primary" style="width:3rem;">下一步</Button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import stepMenu from "../../../components/stepMenu.vue";
import axios from "axios";
export default {
  name: "step2",
  data() {
    return {
      nation: [],
      social: false,
      uploadPicture: []
    };
  },
  mounted() {
    var _this = this;
    _this.setPageSize();
    _this.ethnicityList();
  },
  beforeRouteLeave: function(to, form, next) {
    if (to.name == "mediateStep3") {
      this.$refs.formValidate.validate(valid => {
        if (valid == false) {
          this.$Message.error("请录入完成后再进行下一步操作!");
        } else {
          if (this.social) {
            next();
          } else {
            this.$Message.info(
              "请选中本人承诺以上所填内容和提交的证件、证明材料均为真实！"
            );
          }
        }
      });
    } else {
      next();
    }
  },
  components: {
    stepMenu: stepMenu
  },
  computed: {
    ruleValidate: function() {
      return this.$store.state.mediateData.ruleValidate;
    },
    formValidate: function() {
      return this.$store.state.mediateData.formValidate;
    }
  },
  methods: {
    ethnicityList: function() {
      var _this = this;
      _this.send({
        url: "/EthnicityList",
        success: function(res) {
          _this.nation = res;
        }
      });
    },
    deleteValuePicture: function(file, fileList) {
      var _this = this;
      _this.formValidate.sfclmc = "";
      _this.formValidate.sfcl = "";
      _this.uploadPicture = fileList;
    },
    Picture: function(response, file, fileList) {
      var _this = this;
			_this.send({
				url:'/Identification',
				data:{
					ImgPath:window.sessionStorage.url+response.Data.path
				},
				success:function(res){
          var birthday = res.Birth.split('/')
          if(birthday[1].length<2){
            birthday[1] = '0'+birthday[1]
          }
          if(birthday[2].length<2){
            birthday[2] = '0'+birthday[2]
          }
					_this.formValidate.sfclmc = response.Data.name;
					_this.formValidate.sfcl = window.sessionStorage.url+response.Data.path;
					_this.uploadPicture = fileList;
					_this.formValidate.applyName = res.Name
					_this.formValidate.sex = res.Sex
					_this.formValidate.idCard = res.IdNum
					_this.formValidate.nation = res.Nation+'族'
					_this.formValidate.birthday = `${birthday[0]}-${birthday[1]}-${birthday[2]}`
					_this.formValidate.address = res.Address
				}
			})
    },
    setPageSize: function() {
      var wsw = document.body.clientWidth;
      document.getElementsByTagName("html")[0].style.fontSize =
        (wsw / 750) * 100 + "px";
    }
  }
};
</script>
