<template>
  <div style="padding:20px;">
    <div style="background-color: white;">
      <div style="font-size:16px;text-align: left;border-left:solid #de4f0b 3px;padding-left:5px">
        预约列表
        <div style="display: inline-block;vertical-align:middle;margin-left:10px">
          <span style="margin-left: 30px">共{{count}}条</span>
        </div>
      </div>
      <!--      <div style="margin: 15px 0">-->
      <!--        <DatePicker :value="timeData" v-model="timeData" type="daterange" split-panels placeholder="请选择时间"-->
      <!--                    style="width: 200px" @on-change="totalTime3"></DatePicker>-->

      <!--        <Select style="width:200px" v-model="model140" filterable remote clearable :remote-method="remoteMethod2"-->
      <!--                @on-change="lawyerClick3" :loading="loading2" placeholder="请输入社区">-->
      <!--          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">-->
      <!--            {{value.VillagesName}}</Option>-->
      <!--        </Select>-->
      <!--        <div style="width: 300px;display: inline-block;vertical-align: top;">-->
      <!--          <Input v-model="userKey" search enter-button placeholder="搜索姓名" @on-search="userSearch" />-->
      <!--        </div>-->
      <!--      </div>-->
      <Select style="width: 300px" v-model="SubscribeTypeId" placeholder="预约类型" @on-change="subscribeType" clearable>
        <Option :value="value.SubscribeTypeId" v-for="(value,index) in fsubscribeTypeData">{{value.SubscribeTypeName}}</Option>
      </Select>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>姓名</th>
            <th>预约日期</th>
            <th>预约类型</th>
            <th>预约说明</th>
            <th>预约状态</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in aAdminSubscribeData">
            <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
            <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
            <td>{{value.UserName}}</td>
            <td>{{value.SubscribeStartTime}}</td>
            <td>{{value.SubscribeTypeName}}</td>
            <td>{{value.SubscribeContent}}</td>
            <td>
              <div :style="{'color':value.Status==1?'red':''}">{{value.Status==0?'未处理':value.Status==1?'已处理':'已拒绝'}}</div>
            </td>
          </tr>
          </tbody>
        </table>
        <Page :total="count" :page-size="pageSize" show-elevator show-total
              @on-change="page = $event;aAdminSubscribeList(2)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "subscribeList",
  data() {
    return {
      aAdminSubscribeData:[],
      information:[],
      VillagesId : '',
      page : 1,
      pageSize : 15,
      startTime : '',
      endTime : '',
      timeData: '',
      model140: false,
      loading2: false,
      options2: [],
      userKey : '',
      SubscribeTypeId : '',
      fsubscribeTypeData : [],
      count : 0
    }
  },
  methods:{
    aAdminSubscribeList:function (){
      var _this = this
      _this.send({
        url: '/AAdminSubscribeList',
        data: {
          AdminId: _this.information.AdminId,
          SubscribeTypeId : _this.SubscribeTypeId,
          page: _this.page,
          pageSize: _this.pageSize,
        },
        success: function (res) {
          _this.aAdminSubscribeData = res.List
          _this.count = res.Count
        }
      })
    },
    fsubscribeTypeList:function (){
      var _this = this
      _this.send({
        url: '/FsubscribeTypeList',
        data: {},
        success: function (res) {
          _this.fsubscribeTypeData = res
        }
      })
    },
    subscribeType : function (e){
      console.log(e)
      this.SubscribeTypeId = e
      this.aAdminSubscribeList()
    },
  },
  mounted() {
    this.information = window.sessionStorage
    this.aAdminSubscribeList()
    this.fsubscribeTypeList()
  },
}
</script>

<style scoped>

/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>
