<style scoped>
</style>

<template>
  <router-view v-if="loadComplete"></router-view>
</template>

<script>
export default {
  name:'mdPage',
  data() {
    return {
      loadComplete : false
    };
 },
  methods: {
    getUserInfo:function(){
      var _this = this
      if(_this.$route.query.openId){
        _this.send({
          url:'/BindingUser',
          data:{
            openid:_this.$route.query.openId,
            userType:'0',
          },
          success:function(res){
            _this.$store.state.userInfo = res
            window.sessionStorage.Area_name = res.Area_name
            document.title = res.Area_name + '-公共法律服务平台'
            window.sessionStorage.token = res.Token
            SYSCONFIG.defaultData.Token = res.Token
            if(res.AdminId == '1253'){
              _this.village_lawyers(res.AdminId,2)
            }
            else{
              _this.village_lawyers(res.VillagesId,1)
            }

          },
        })
      }else{
        _this.loadComplete = true
      }

    },
    setPageSize:function(){
      document.getElementsByTagName("html")[0].style.fontSize = document.body.clientWidth / 750 * 100 + "px"
      if( this.$route.query.userId){
        window.sessionStorage.userId = this.$route.query.userId
      }
    },
    village_lawyers:function(VillagesId,TypeId){
      var _this = this
      _this.send({
        url:'/village_lawyers',
        data:{
          VillagesId:VillagesId,
          TypeId : TypeId
        },
        success:function(res){
          _this.loadComplete = true
          // _this.$store.lawyersList = res
          if(res.length >= 5){
            if(new Date().getDay() == 0){
              if(res.length == 7){
                var index = 6
              }else {
                var index = 0
              }
            }else if(new Date().getDay() == 6){
              if(res.length == 6){
                var index = new Date().getDay()-1
              }else {
                var index = 0
              }
            }else {
              var index = new Date().getDay()-1
            }
            _this.$store.lawyersList = res
            _this.$store.initialLawyers = index
            console.log([res[index]])
          }else{
            _this.$store.lawyersList = res
            _this.$store.state.userInfo.LawyerId = res[0].LawyerId
            _this.$store.state.userInfo.LawyerImg = res[0].LawyerImg
            _this.$store.state.userInfo.LawyerName = res[0].LawyerName
            _this.$store.state.userInfo.LawyerPhone = res[0].LawyerPhone
            _this.$store.initialLawyers = 0
          }
        }
      })
    },
  },
  mounted() {
    var _this = this
    if(this.$route.name!='CDSFT'){
      this.getUserInfo()
    }else{
      _this.loadComplete = true
    }
    this.setPageSize()
    // window.onresize = function () {
    //   _this.setPageSize()
    // }
  },
  // beforeRouteLeave(to, form, next) {
  //   document.getElementsByTagName("html")[0].style.fontSize = ''
  //   next()
  // }
}
</script>
