<style scoped>
  .home{background-color: rgb(241,239,240);height:100%;overflow: auto;text-align:center;}
  /*.screen{width: calc(100% - 80px);display: inline-block;}*/
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .house{width: 72px;height: 72px;margin-top: 29px;}
  .number{font-size: 48px;color: #7a7a7a;}
  .tipsName{font-size: 16px;color: #8d8d8d;}
  .displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}
  .administration{width: 100%;background-color: #fff;margin-top: 40px;border-bottom: 1px solid #dddddd;height: 80px;text-align: left;margin-bottom: 0}
  .administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
  .label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
  .forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;margin:40px 40px 0 40px;height: 60px;}
  .forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
  .forMcontent{width: calc(100% - 80px);height: 60px;color: #666666;font-size: 16px;margin:0px 40px 0 40px;border-bottom: 1px solid #dddddd;text-align: center;}
  .forMcontent p {line-height: 60px;display: inline-block;width: 20%;overflow: hidden;white-space: nowrap;}
  .smallProgram{width: 32px;height: 32px;vertical-align: top;}
  .alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -23px;}
  .alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}
  .qrCode{width: 100px;height: 100px;}
  .addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
  .myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}
  .icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}
  .contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
  .single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
  .inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}

  /*表格*/
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
</style>

<template>
  <div class="home screen">
    <Modal :z-index="1" :draggable="true" v-model="EvaPanel.state" title="评价详情">
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
              <th>用户</th>
              <th>分值</th>
              <th>评语</th>
              <th>咨询类型</th>
              <th>咨询结束时间</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in EvaPanel.list">
              <td>{{value.UserName}}</td>
              <td>{{value.OrderGrade}}</td>
              <td>{{value.OrderEvaluate}}</td>
              <td>{{value.OrderTypeTitle}}</td>
              <td>{{value.OrderEndTime}}</td>
          </tr>
          </tbody>
      </table>
      </div>

    </Modal>
      <!--<HelloWorld>-->
      <Modal :draggable="true"
              v-model="SPC.state"
      >
          <!--phoneNum:'',
          lawyerName:'',
          vallageName:'',
          src:'', -->
          <div style="text-align: center;">
              <div style="padding:15px"></div>
              <div style="font-size:36px;">
                  {{SPC.areaName}} - {{SPC.VNName}}
              </div>
              <div style="padding:15px"></div>
              <img :src="'https://vn-server.lvwa18.com/'+SPC.src"/>
              <div style="font-size:30px;">村（社区）法律顾问：{{SPC.lawyerName}}</div>
              <div style="font-size:30px;">{{SPC.phoneNum}}</div>
          </div>

      </Modal>
      <!--</HelloWorld>-->
      <div style="background-color: rgb(241,239,240);">
          <!--<div class="screen">-->
          <!--<div>本月 </div>-->
          <!--</div>-->
          <div style="background-color: white;margin:20px;">
              <div style="padding:15px"></div>
              <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 总统计数</div>
              <div style="font-size: 16px;text-align: left;margin: 10px 0 0 20px;">
                  <!---->
                  <DatePicker  :value="timeData" v-model="timeData"  :transfer="transfer" type="daterange" placement="bottom-end" placeholder="选择时间" style="width: 200px" @on-change="totalTime"></DatePicker>
              </div>
              <div style="white-space:nowrap;">
                  <div class="displayData" @click="jurisdiction();modal16 = true"  v-if="information.adminLevel == 0 || information.adminLevel == 1 || information.adminLevel == 2" :style="{'width':information.adminLevel == 0 || information.adminLevel == 1 || information.adminLevel == 2 ? '18%':'23%'}">
                      <!--<img class="house" :src="/img/house.png">-->
                      <img class="house" src="/img/house4.png">
                      <p class="number">{{countData}}</p>
                      <p class="tipsName" v-if="information.adminLevel == 1">市州数</p>
                  </div>
                  <div class="displayData" v-for="(value,index) in statisticsData" :key="index" :style="{'margin-left':(index==0?'0':'2%')},{'width':information.adminLevel == 0 || information.adminLevel == 1 || information.adminLevel == 2 ? '18%':'23%'}" v-if="index != 3" @click="orderTypeCount(value.type)">
                      <!--<img class="house" :src="/img/house.png">-->
                      <img class="house" :src="'/img/house'+''+index+'.png'">
                      <p class="number">{{value.value}}</p>
                      <p class="tipsName">{{value.name}}</p>
                  </div>
                  <div class="displayData" v-for="(value,index) in statisticsData" :key="index" :style="{'margin-left':(index !=3?'0':'2%'),'cursor': 'pointer'},{'width':information.adminLevel == 0 || information.adminLevel == 1 || information.adminLevel == 2 ? '18%':'23%'}" v-if="index == 3" @click="modal3 = true;orderTypeCount(value.type)">
                      <!--<img class="house" :src="/img/house.png">-->
                      <img class="house" :src="'/img/house'+''+index+'.png'">
                      <p class="number">{{value.value}}</p>
                      <p class="tipsName">{{value.name}}</p>
                  </div>

              </div>
              <!--<Tabs v-model="boxt" class="screen"  @on-click="switchData">-->
              <!--<TabPane :label="time.name"  v-for="(time,i) in timelist">-->
              <!---->

              <!--</TabPane>-->
              <!--</Tabs>-->
              <!--<div id="allDataView" style="height: 400px;"></div>-->
          </div>
          <!---->

          <div style="background-color: white;margin:20px;">
              <div style="padding:15px"></div>
              <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 十日新增数据</div>
              <div id="myChart1" style="height:400px"></div>
              <div style="padding:15px"></div>
          </div>
          <!---->
          <div style="background-color: white;margin:20px;">
              <div style="padding:15px"></div>
              <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 昨日数据</div>
              <Row style="font-size:14px;height:180px" type="flex" justify="center" align="middle">
                  <Col span="6" style="border-right:1px solid rgb(209, 209, 209)">
                      <Row><Col>服务村（社区）</Col></Row>
                      <div style="padding:10px"></div>
                      <Row><Col style="color:red">{{yesterdayData.village}}</Col></Row>
                  </Col>
                  <Col span="6" style="border-right:1px solid rgb(209, 209, 209)">
                      <Row><Col>服务律师</Col></Row>
                      <div style="padding:10px"></div>
                      <Row><Col style="color:red">{{yesterdayData.lawyer}}</Col></Row>
                  </Col>
                  <Col span="6" style="border-right:1px solid rgb(209, 209, 209)">
                      <Row><Col>服务用户</Col></Row>
                      <div style="padding:10px"></div>
                      <Row><Col style="color:red">{{yesterdayData.user}}</Col></Row>
                  </Col>
                  <Col span="6">
                      <Row><Col>咨询次数</Col></Row>
                      <div style="padding:10px"></div>
                      <Row><Col style="color:red">{{yesterdayData.order}}</Col></Row>
                  </Col>
              </Row>
          </div>
          <!---->
          <div style="background-color: white;margin:20px;">
              <div style="padding:15px"></div>
              <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 十日服务数据</div>
              <div id="myChartGetTen" :style="{width: '100%', height: '600px'}"></div>
          </div>
          <!---->
          <div style="background-color: white;margin:20px;">
              <div style="padding:15px"></div>
              <div style="position: relative">
                  <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 地图分布</div>
                  <div id="mapChart" :style="{width: '100%', height: '600px'}"></div>
                  <div v-if="map.mapHistory.length>1&&map.mapLodingState==false" style="cursor:pointer;padding:5px 15px;position:absolute;top:80px;left:40px;z-index:999;background-color:#29c8ff;font-size:14px;color:#fff;border-radius:5px" @click="backMap">返回</div>
                  <div ref=childPanel style="color:#fff;font-size:14px;padding:15px;border-radius:5px;background-color:rgba(0,0,0,0.5);position:absolute;display:none;">
                      <div v-for="(item,index) in map.childData">{{item.name}}:{{item.value}}</div>
                  </div>
              </div>
              <div style="padding:15px"></div>
          </div>
          <!--律所管理律师列表-->

          <!--管理律师列表-->

          <!---->
      </div>
      <!--弹出-->
      <Modal :draggable="true"
              v-model="modal1"
              title="添加"
              @on-ok="ok"
              @on-cancel="cancel">
          <div class="single">
              <p class="contacts">律师名称</p>
              <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName">
          </div>
          <div class="single">
              <p class="contacts">律师电话</p>
              <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone">
          </div>
      </Modal>
      <!--更换律师-->
      <Modal :draggable="true"
              v-model="modal4"
              title="更换律师"
              @on-ok="replace();"
              @on-cancel="cancel">
          <div class="single">
              <p class="contacts">律师名称</p>
              <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName">
          </div>
          <div class="single">
              <p class="contacts">律师电话</p>
              <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone">
          </div>
      </Modal>
      <!---->
      <Modal :draggable="true"
              :z-index="1"
              v-model="modal2"
              title="添加"
              @on-ok="addMember"
              @on-cancel="cancel">

          <div class="single">
              <p class="contacts">村居律所名称</p>
              <input type="text" class="inputWord" placeholder="请输入村居律所名称" v-model="organizationName">
          </div>
          <div class="single">
              <p class="contacts">联系人</p>
              <input type="text" class="inputWord" placeholder="请输入联系人" v-model="organizationLinkman">
          </div>
          <div class="single" v-if="cityList.length != 0">
              <p class="contacts">地址</p>
              <div style="display: inline-block">
                  <Select v-model="model8" style="width:200px" @on-change="choice" :label-in-value="labelInValue" :placeholder="tipsWord">
                      <Option  v-for="(item,index) in cityList" :value="item.AreaId +','+ index  +','+ '1'" :label="item.AreaName" :key="index">{{ item.AreaName }}</Option>
                  </Select>
                  <Select v-model="model9" style="width:200px" @on-change="choice" :label-in-value="labelInValue" v-if="areaData" :placeholder="tipsWord">
                      <Option v-for="(i,index) in areaData" :value="i.AreaId +','+ index +','+ '2'" :label="i.AreaName" :key="index">{{ i.AreaName }}</Option>
                  </Select>
              </div>
          </div>
          <div class="single">
              <p class="contacts">联系电话</p>
              <input type="text" class="inputWord" placeholder="请输入联系电话" v-model="organizationPhone">
          </div>
          <div class="single">
              <p class="contacts">登录名称</p>
              <input type="text" class="inputWord" placeholder="请输入登录名" v-model="adminLogin">
          </div>
          <div class="single">
              <p class="contacts">登录密码</p>
              <input type="text" class="inputWord" placeholder="请输入登录密码" v-model="adminPassword">
          </div>
      </Modal>
      <Modal :draggable="true"
              v-model="modal3"
              title="服务次数"
              :z-index="1"
              @on-ok=""
              @on-cancel="cancel">
          <div class="publicWelfare">
              <table class="formtable">
                  <thead>
                  <tr>
                      <th>服务类型</th>
                      <th>服务次数</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value,index) in orderTypeCountData">
                      <td v-if="value.OrderTypeId != '12'">{{value.OrderTypeTitle}}</td>
                      <td v-if="value.OrderTypeTitle == '人工咨询'"><div style="display: inline-block;margin: 7px auto;">{{value.count}}</div>
                          <Button style="background-color:  rgb(243, 133, 4);color: #fff;float: right" @click="modal12 = true;orderTypeCount(5,undefined,1);lawyerList()">详情</Button>
                          <Button style="background-color:  rgb(243, 133, 4);color: #fff;float: right" @click="modal12 = true;orderTypeCount(5,undefined,2);lawyerList()" v-if="orderTypeCountData[11].OrderTypeId == '12'">{{orderTypeCountData[11].OrderTypeTitle}}{{orderTypeCountData[11].count}}条</Button>
                      </td>
                      <td v-else-if="value.OrderTypeTitle == '自助咨询'"><div style="display: inline-block;margin: 7px auto;">{{value.count}}</div>
                          <Button style="background-color:  rgb(243, 133, 4);color: #fff;float: right" type="warning" @click="robot()">案由排名</Button>
                      </td>
                      <td v-else-if="value.OrderTypeTitle == '拨打律师电话'"><div style="display: inline-block;margin: 7px auto;">{{value.count}}</div>
                          <Button style="background-color:  rgb(243, 133, 4);color: #fff;float: right" type="warning" @click="dialTelephone(value.OrderTypeId,value.OrderTypeTitle,1);modal18 = true">详情</Button>
                      </td>
                      <td v-else-if="value.OrderTypeTitle == '拨打12348热线'"><div style="display: inline-block;margin: 7px auto;">{{value.count}}</div>
                          <Button style="background-color:  rgb(243, 133, 4);color: #fff;float: right" type="warning" @click="dialTelephone(value.OrderTypeId,value.OrderTypeTitle,2);modal18 = true">详情</Button>
                      </td>
                      <td v-else-if="value.OrderTypeId == '9'">
                          <div style="display: inline-block;margin: 7px auto;" v-if="chengdu" >{{3248+value.count}}</div>
                          <div style="display: inline-block;margin: 7px auto;"  v-else>{{value.count}}</div>
                          <!--<Button style="background-color:  rgb(243, 133, 4);color: #fff;float: right" @click="detailsSee(value.OrderTypeId,value.OrderTypeTitle);modal17 = true">详情</Button>-->
                      </td>
                      <td v-else-if="value.OrderTypeId != '12'"><div style="display: inline-block;margin: 7px auto;">{{value.count}}</div>
                          <Button style="background-color:  rgb(243, 133, 4);color: #fff;float: right" @click="detailsSee(value.OrderTypeId,value.OrderTypeTitle);modal17 = true">详情</Button>
                      </td>
                  </tr>
                  </tbody>
              </table>
          </div>
      </Modal>
      <!--服务次数剩下的详情-->
      <Modal :draggable="true"
              v-model="modal17"
              @on-ok=""
              @on-cancel="cancel"
              width="650">
          <p slot="header" style="color:#17233d;text-align:left">
              <span>{{serviceName}}</span>
              <span style="margin-left: 30px">共{{count}}条</span>
          </p>
          <div class="publicWelfare">
              <DatePicker  :value="timeData" v-model="timeData"  type="daterange" split-panels placeholder="请选择时间" style="width: 200px" @on-change="totalTime1"></DatePicker>
              <Select v-model="model1" style="width:200px" @on-change="lawyerChoice" filterable clearable >
                  <Option v-for="(value,index) in lawyerData" :value="value.LawyerId" :key="value.LawyerName" >{{ value.LawyerName }}</Option>
              </Select>
              <Select
                      style="width:200px"
                      v-model="model14"
                      filterable
                      remote
                      clearable
                      :remote-method="remoteMethod2"
                      @on-change="lawyerChoice2"
                      :loading="loading2"
                      placeholder="请输入社区">
                  <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">{{value.VillagesName}}</Option>
              </Select>
              <table class="formtable">
                  <thead>
                  <tr>
                      <th>用户</th>
                      <th>律师名称</th>
                      <th>社区</th>
                      <th v-if="serviceName== '法律援助'||serviceName== '调解申请'">申请状态</th>
                      <th>咨询时间</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value,index) in detailsSeeLIst">
                      <td><Avatar :src="value.UserImg" /> {{value.UserName}}</td>
                      <td>{{value.LawyerName}}</td>
                      <td>{{value.VillagesName}}</td>
                      <td v-if="serviceName== '法律援助'||serviceName== '调解申请'">{{value.IsApply}}</td>
                      <td>{{value.OrderCreatedTime}}</td>

                  </tr>
                  </tbody>
              </table>

              <Page
                      :total="count"
                      show-total
                      :page-size="pageSize"
                      show-elevator
                      @on-change="page = $event;detailsSee(serviceType,serviceName)"
              />
          </div>
      </Modal>
      <!--拨打电话-->
      <Modal :draggable="true"
              v-model="modal18"
              @on-ok=""
              @on-cancel="cancel"
              width="650">
          <p slot="header" style="color:#17233d;text-align:left">
              <span>{{serviceName}}</span>
              <span style="margin-left: 30px">共{{count}}条</span>
          </p>
          <div class="publicWelfare">
              <DatePicker :value="timeData" v-model="timeData" type="daterange" split-panels placeholder="请选择时间" style="width: 200px" @on-change="totalTime2"></DatePicker>
              <Select v-model="model1" style="width:200px" @on-change="lawyerChoiceA" filterable clearable >
                  <Option v-for="(value,index) in lawyerData" :value="value.LawyerId" :key="value.LawyerName" >{{ value.LawyerName }}</Option>
              </Select>
              <Select
                      style="width:200px"
                      v-model="model142"
                      filterable
                      remote
                      clearable
                      :remote-method="remoteMethod2"
                      @on-change="lawyerChoiceA2"
                      :loading="loading2"
                      placeholder="请输入社区">
                  <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">{{value.VillagesName}}</Option>
              </Select>
              <table class="formtable">
                  <thead>
                  <tr>
                      <th>用户</th>
                      <th>律师名称</th>
                      <th>社区</th>
                      <th>拨打时间</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value,index) in UserCallList">
                      <td><Avatar :src="value.UserImg" /> {{value.UserName}}</td>
                      <td>{{value.LawyerName}}</td>
                      <td>{{value.VillagesName}}</td>
                      <td>{{value.Time}}</td>
                  </tr>
                  </tbody>
              </table>

              <Page
                      :total="count"
                      show-total
                      :page-size="pageSize"
                      show-elevator
                      @on-change="page = $event;dialTelephone(serviceType,serviceName,typeData)"
              />
          </div>
      </Modal>
      <!--人工咨询详情-->
      <Modal :draggable="true"
              v-model="modal12"
              title="人工咨询详情"
              @on-ok=""
              @on-cancel="cancel"
              width="900">
          <p slot="header" style="color:#17233d;text-align:left">
              <span v-if="effect == 1">人工咨询详情</span>
              <span v-if="effect == 2">有效咨询</span>
              <span style="margin-left: 30px">共{{count}}条</span>
          </p>
          <div class="publicWelfare">
              <DatePicker  :value="timeData" v-model="timeData"  type="daterange" split-panels placeholder="请选择时间" style="width: 200px" @on-change="totalTime0"></DatePicker>
              <Select v-model="model1" style="width:200px" @on-change="lawyerClick" filterable clearable >
                  <Option v-for="(value,index) in lawyerData" :value="value.LawyerId" :key="value.LawyerName" >{{ value.LawyerName }}</Option>
              </Select>
              <Select
                      style="width:200px"
                      v-model="model141"
                      filterable
                      remote
                      clearable
                      :remote-method="remoteMethod2"
                      @on-change="lawyerClick2"
                      :loading="loading2"
                      placeholder="请输入社区">
                  <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">{{value.VillagesName}}</Option>
              </Select>
              <table class="formtable">
                  <thead>
                  <tr>
                      <th>用户</th>
                      <th>律师名称</th>
                      <th>社区</th>
                      <!--<th>用户号码</th>-->
                      <th>咨询时间</th>
                      <th>状态</th>
                      <th>处理状态</th>
                      <th>律师小结</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value,index) in UserOrderLIst">
                      <td><Avatar :src="value.UserImg" /> {{value.UserName}}</td>
                      <td>{{value.LawyerName}}</td>
                      <td>{{value.VillagesName}}</td>
                      <!--<td>{{value.UserPhone}}</td>-->
                      <td>{{value.OrderCreatedTime}}</td>
                      <td>{{value.state}}</td>
                      <td>{{value.IsReply}}</td>
                      <td>{{value.OrderSummary?value.OrderSummary:'未填写'}}</td>
                  </tr>
                  </tbody>
              </table>

              <Page
                      :total="count"
                      show-total
                      :page-size="pageSize"
                      show-elevator
                      @on-change="page = $event;orderTypeCount(5,undefined,effect)"
              />
          </div>
      </Modal>
      <!--案由排名-->
      <Modal :draggable="true"
              v-model="modal13"
              title="咨询案由排名"
              @on-ok=""
              @on-cancel="cancel" width="600">
          <div class="publicWelfare">
              <div id="robot" style="height:600px;width: 100%"></div>
          </div>
      </Modal>
      <!--服务社区（村）数量-->
      <Modal :draggable="true"
              v-model="modal10"
              title="服务村（社区）数"
              @on-ok=""
              @on-cancel="cancel"
              width="1100">
          <p slot="header" style="color:#17233d;text-align:left">
              <span>服务村（社区）数</span>
              <span style="margin-left: 30px">共{{count}}条</span>
          </p>
          <div class="publicWelfare">
              <Row>
                  <Col span="12">
                      <Input v-model="search" search enter-button placeholder="搜索社区（村）名称" @on-search="searchDara"/>
                  </Col>
                  <Col span="12" style="text-align: center">
                      <Button type="primary" v-if="sort == 2" @click="sortClick('')">用户量(升序)</Button>
                      <Button type="primary" v-else="" @click="sortClick(2)">用户量(降序)</Button>
                  </Col>
              </Row>
              <!---->


              <!---->
              <table class="formtable">
                  <thead>
                  <tr>
                      <th>律师名称</th>
                      <th>服务村（社区）</th>
                      <th>法律服务所</th>
                      <th>创建时间</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value,index) in adminVillagesData">
                      <td><Avatar :src="value.LawyerImg" /> {{value.LawyerName}}</td>
                      <td>{{value.CityName}}{{value.VillagesName}}<samp style="padding: 10px;"></samp>用户数量：（{{value.UserCount}}）</td>
                      <td>{{value.AdminName}}</td>
                      <td>{{value.CreatTime}}</td>
                  </tr>
                  </tbody>
              </table>
              <Page
                      :total="count"
                      show-total
                      :page-size="pageSize"
                      show-elevator
                      @on-change="page = $event;orderTypeCount(1,administration)"
              />
          </div>

      </Modal>
      <!---->
      <Modal :draggable="true"
              v-model="modal16"
              title="服务村（社区）数"
              @on-ok=""
              @on-cancel="cancel"
              :z-index="1"
              width="900">
          <p slot="header" style="color:#17233d;text-align:left">
              <span v-if="information.adminLevel == 1">市州数</span>
          </span>
              <span style="margin-left: 30px">共{{count1}}条</span>
          </p>
          <div class="publicWelfare">
              <table class="formtable">
                  <thead>
                  <tr>
                      <!--<th v-if="information.adminLevel == 0">省数</th>-->
                      <th>名称</th>
                      <th v-if="information.adminLevel == 1 || information.adminLevel == 0">
                          <span v-if="information.AdminId == '275'">乡镇数</span>
                          <span v-else>区县数</span>
                      </th>
                      <th v-if="information.adminLevel == 1 || information.adminLevel == 0  || information.adminLevel == 2 && (information.AdminName.match(RegExp(/区/))) == null">街道（司法所）数</th>
                      <th>服务村（社区）</th>
                      <th>律师人数</th>
                      <th>用户人数</th>
                      <th>咨询次数</th>
                      <th>创建时间</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value,index) in difficultCount">
                      <td>{{value.AdminName}}</td>
                      <td v-if="information.adminLevel == 1 || information.adminLevel == 0">{{value.quxian_count}}</td>
                      <td v-if="information.adminLevel == 1 || information.adminLevel == 0  || information.adminLevel == 2 && (information.AdminName.match(RegExp(/区/))) == null">{{value.sfs_count}}</td>
                      <td  style="cursor: pointer;" @click="orderTypeCount(1,value.AdminId)">{{value.Villages_count}}</td>
                      <td>{{value.Lawyers_count}}</td>
                      <td>{{value.users_count}}</td>
                      <td>{{value.Orders_count}}</td>
                      <td>{{value.CreatTime}}</td>
                  </tr>
                  </tbody>
              </table>
              <!--<Page-->
              <!--:total="count"-->
              <!--show-total-->
              <!--:page-size="pageSize"-->
              <!--show-elevator-->
              <!--@on-change="page = $event;jurisdiction()"-->
              <!--/>-->
          </div>

      </Modal>
      <!--用户人数-->
      <Modal :draggable="true"
              v-model="modal11"
              title="用户人数"
              @on-ok=""
              @on-cancel="cancel" width="700">
          <p slot="header" style="color:#17233d;text-align:left">
              <span>用户人数</span>
              <span style="margin-left: 30px">共{{count}}条</span>
          </p>
          <div class="publicWelfare">
              <DatePicker  :value="timeData" v-model="timeData"  type="daterange" split-panels placeholder="请选择时间" style="width: 200px" @on-change="totalTime3"></DatePicker>

              <Select
                      style="width:200px"
                      v-model="model140"
                      filterable
                      remote
                      clearable
                      :remote-method="remoteMethod2"
                      @on-change="lawyerClick3"
                      :loading="loading2"
                      placeholder="请输入社区">
                  <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">{{value.VillagesName}}</Option>
              </Select>
              <table class="formtable">
                  <thead>
                  <tr>
                      <th>用户</th>
                      <th>社区</th>
                      <!--<th>用户号码</th>-->
                      <th>注册时间</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value,index) in adminUserData">
                      <td><Avatar :src="value.UserImg" /> {{value.UserName}}</td>
                      <td>{{value.VillagesName}}</td>
                      <!--<td>{{value.UserPhone}}</td>-->
                      <td>{{value.CreatTime}}</td>
                  </tr>
                  </tbody>
              </table>
              <Page
                      :total="count"
                      :page-size="pageSize"
                      show-elevator
                      show-total
                      @on-change="page = $event;orderTypeCount(2)"
              />
          </div>
      </Modal>
      <!---->
      <Modal :draggable="true"
              v-model="modal15"
              title="用户人数"
              :z-index="1"
              @on-ok=""
              @on-cancel="cancel" width="1000">
          <p slot="header" style="color:#17233d;text-align:left">
              <span>律师人数</span>
              <span style="margin-left: 30px">共{{count}}条</span>
          </p>
          <div class="publicWelfare">
              <Row>
                  <Col span="12">
                      <Input v-model="searchLaw" search enter-button placeholder="搜索律师名称" @on-search="searchLawyer"/>
                  </Col>
                  <!--<Col span="12" style="text-align: center">-->
                      <!--<Button type="primary" @click="sendOutinformation()">一键发送</Button>-->
                  <!--</Col>-->
              </Row>

              <table class="formtable">
                  <thead>
                  <tr>
                      <th>律师名称</th>
                      <th>所属律所</th>
                      <th>电话</th>
                      <th >共<span v-if="avgs == 0">0</span><span v-else>{{sum}}</span>人评分</th>
                      <th>服务数据</th>
                      <!-- <th>小程序码 -->
                              <!--<Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>-->
                      <!-- </th> -->
                      <th>是否绑定</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value,index) in adminLawyerList">
                      <td><Avatar :src="value.LawyerImg" /> {{value.LawyerName}}</td>
                      <td>{{value.OrganizationName}}</td>
                      <td>{{value.LawyerPhone}}</td>
                      <td v-if="value.avgs == 0">暂无</td>

                      <td v-else=""><span style="cursor: pointer;" @click="EvaPanel.page=1,getEva(value.LawyerId)">{{Math.floor(value.avgs * 100) / 100}}分（{{value.Count}}人评分）</span></td>
                      <td><Button style="background-color:  rgb(243, 133, 4);color: #fff" @click="serviceClick(value.LawyerId);modal19 = true">详情</Button></td>
                      <!-- <td style="width: 190px;"> -->
                          <!--<CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange" style="display: inline-block;color:#19be6b;">-->
                              <!--<Checkbox :label="value.LawyerPhone+','+value.LawyerId+','+value.OrganizationId" style="">-->
                                  <!--<div></div>-->
                              <!--</Checkbox>-->
                          <!--</CheckboxGroup>-->
                          <!-- <img class="smallProgram" @click="alertbox(value.LawyerQRcode,value.LawyerName,value.LawyerPhone,value.LawyerId,value.OrganizationId)" src="/img/smallProgram1.png"> -->
                          <!-- <div style="display: inline-block;margin: 0 8%"></div> -->
                          <!-- <Button type="success" @click="alertbox1(value.LawyerQRcode,value.LawyerName,value.LawyerId,value.LawyerPhone,value.OrganizationId)">发送</Button> -->
                      <!-- </td> -->
                      <td v-if="value.LawyerOpenId" style="color: red">已绑定</td>
                      <td v-else>未绑定</td>
                  </tr>
                  </tbody>
              </table>
              <Page
                      :total="count"
                      :page-size="pageSize"
                      show-elevator
                      show-total
                      @on-change="page = $event;orderTypeCount(3)"
              />
          </div>
      </Modal>
      <!---->
      <Modal :draggable="true"
              v-model="modal19"
              title="服务数据"
              @on-ok=""
              @on-cancel="" width="650">
          <p slot="header" style="color:#17233d;text-align:left">
              <span>服务数据</span>
          </p>
          <div class="publicWelfare">
              <table class="formtable">
                  <thead>
                  <tr>
                      <th>服务类型</th>
                      <th>服务次数</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>服务村（社区）数</td><td>{{statisticsData1.villages_count}}</td>
                  </tr>
                  <tr>
                      <td>服务用户数量</td><td>{{statisticsData1.user_count}}</td>
                  </tr>
                  <tr>
                      <td>拨打电话数量</td><td>{{statisticsData1.tel_count}}</td>
                  </tr>
                  <tr v-for="(value,index) in lawyerOrderTypeData">
                      <td>{{value.OrderTypeTitle}}</td><td>{{value.count}}</td>
                  </tr>
                  </tbody>
              </table>
          </div>
      </Modal>
      <!---->
      <Modal :draggable="true"
              v-model="modal8"
              title="案由"
              @on-ok=""
              @on-cancel="cancel" width="530">
          <div class="publicWelfare">
              <table class="formtable">
                  <thead>
                  <tr>
                      <!--<th>案由</th>-->
                      <th>社区（村）</th>
                      <th>数量</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value,index) in listData">
                      <!--<td>{{value.keyword}}</td>-->
                      <td>{{value.VillagesName}}</td>
                      <td>{{value.nums}}</td>

                  </tr>
                  </tbody>
              </table>
          </div>
      </Modal>
      <!--弹窗img-->
      <Modal :draggable="true"
              v-model="modal5"
              :title="lawName+'的太阳码'"
              @on-ok="downloadUrl"
              @on-cancel=""
              ok-text="下载"
              cancel-text="关闭"
              width="350">
          <div style="text-align: center">
              <!--<img v-if="modal5" :src="urlImg" style="width: 300px;height: 300px">-->
              <div style="width: 300px;height: 300px">
                  <img v-if="modal5 && lawImg" :src="lawImg" style="width: 300px;height: 300px">
              </div>
          </div>
      </Modal>
      <!--弹窗发送-->
      <Modal :draggable="true"
              v-model="modal6"
              :title="lawName+'的太阳码'"
              @on-ok="sendOut"
              @on-cancel=""
              ok-text="发送"
              cancel-text="关闭"
              width="350">
          <div style="text-align: center">
              <p style="font-size: 16px">是否发送律师小程序码至手机</p>
              <!--<img v-if="modal6" :src="urlImg" style="width: 300px;height: 300px">-->
              <div style="width: 300px;height: 300px">
                  <img v-if="modal6 && lawImg" :src="lawImg" style="width: 300px;height: 300px">
              </div>
          </div>
      </Modal>
  </div>
</template>

<script>
  import echarts from 'echarts'
  import axios from 'axios'
  // import HelloWorld from '../../components/HelloWorld.vue'
  export default {
      name: "home",
      // components: {HelloWorld,},
      data () {
          return {
            EvaPanel:{
              state:false,
              list:[],
              pageSize:10,
              page:1,
              count:0,
            },
              SPC:{
                  state:false,
                  phoneNum:'',
                  lawyerName:'',
                  vallageName:'',
                  src:'',
              },
              map:{
                  mapChart:false,
                  mapLodingState:false,
                  mapName:"海南省",
                  mapHistory:[],
                  mapData:[
                      {
                          name:'四川省',
                          value:395,
                          type:[{name:'服务村（社区）数',value:152,},{name:'律师数量',value:30,},{name:'咨询数量',value:80,},{name:'用户数量',value:80,},]
                      },
                      {
                          name:'贵州省',
                          value:98,
                          type:[{name:'服务村（社区）数',value:12,},{name:'律师数量',value:50,},{name:'咨询数量',value:30,},{name:'用户数量',value:30,},]
                      },
                      {
                          name:'云南省',
                          value:15,
                          type:[{name:'服务村（社区）数',value:12,},{name:'律师数量',value:70,},{name:'咨询数量',value:20,},{name:'用户数量',value:20,},]
                      },
                  ],
                  childData:[
                      {name:'服务村（社区）',value:152,},
                      {name:'律师人数',value:30,},
                      {name:'用户人数',value:30,},
                      {name:'咨询次数',value:80,},
                  ],
              },
              yesterdayData:{
                  village:'',
                  lawyer:'',
                  user:'',
                  order:'',
              },
              ageDistributedList:[],
              devicesDistributedList:[],
              sexDistributedList:[],
              areaData: null,
              labelInValue : true,
              value2: ['0', '1', '2'],
              // addressData : [],
              model8: '',
              model9: '',
              cityList: [],
              ElectronicsList: [],
              statisticsData1 : [],
              lawyerOrderTypeData : [],
              tipsWord: '请选择地址',
              boxt : 0,
              modal1: false,
              modal2: false,
              modal3: false,
              modal4: false,
              modal5: false,
              modal6: false,
              modal17: false,
              modal18: false,
              modal19: false,
              modal8: false,
              model14: false,
              model140: false,
              model141: false,
              model142: false,
              loading2: false,
              options2: [],
              VillagesId : '',
              orderTypeCountData : [],
              administration : '',
              listData : [],
              arCode : false,//二维码
              current : '',
              lawyerPhone : '',
              lawyerName : '',
              information :[],
              page : 1,
              pageSize : 15,
              count : 0,
              count1 : 0,
              opinionpage : 1,
              opinionpageSize : 10,
              opinioncount : 0,
              typeId : 1,
              adminLogin : '',
              adminPassword : '',
              organizationPhone : '',
              organizationLinkman : '',
              organizationName : '',
              urlImg : '',
              lawImg : '',
              lawName : '',
              lawyerId1 : '',
              lLawyerPhone : '',
              AreaId : '',
              sum : '',
              statisticsData : [
                  {name : '服务村（社区）',value:'',type : '1'},
                  {name : '律师人数',value:'',type : '3'},
                  {name : '用户人数',value:'',type : '2'},
                  {name : '咨询次数',value:'',type : '4'}
              ],
              timelist : [
                  {name:'总计',value:4},
                  {name:'本月',value:1},
                  {name:'本季',value:2},
                  {name:'本年',value:3},
              ],
              //
              modal10 : false,
              adminVillagesData : [],
              //
              modal11 : false,
              modal12 : false,
              modal13 : false,
              modal15 : false,
              modal16 : false,
              difficultCount : [],
              countData : '',
              adminLawyerList : [],
              adminUserData : [],
              equipment : [],
              UserOrderLIst : [],
              getOnlineNum : '',

              start_time : '',//开始时间
              end_time : '',//结束时间
              nameKeyId : '',
              lawyerData : [],
              model1 : '',
              startTime : '',//开始时间
              endTime : '',
              transfer : true,
              //
              search : '',
              model2 : '',
              sort : '',
              detailsSeeLIst : [],
              UserCallList : [],
              serviceType : '',
              serviceName : '',


              clickname : '',
              opinionData : '',

              //
              modelA1 : '',
              modelA2 : '',
              CommunityData1 : [],
              CommunityData2 : [],
              CommunityData3 : [],
              CommunityData4 : [],
              CommunityData5 : [],
              modelA3 : '',
              modelA4 : '',
              modelA5 : '',

              //
              getTen_Data : [],
              myChartGetTen : {},
              typeData : '',
              timeData : '',
              effect : '',
              avgs : '',
              chengdu : [],

              searchLaw : '',
              indeterminate: true,
              checkAll: false,
              checkAllGroup: [],
          }
      },

      mounted () {
          var _this = this
          _this.getMapGeo()
          _this.newlyDataView()
          _this.getYesterdayData()
          window.sessionStorage.adminLevel = 1
          this.information = window.sessionStorage
          _this.jurisdiction()
          _this.switchData(0)
          // _this.getAreaData()
          // _this.opinion(2)
          _this.getTenData()

      },
      methods:{
        getEva:function(id){
          var _this =this
          _this.EvaPanel.list = []
          _this.send({
            url:'/OrderGradeList',
            data:{
              LawyerId:id,
              page:_this.EvaPanel.page,
              pageSize:_this.EvaPanel.pageSize,
            },
            success:function(res){
              _this.EvaPanel.state = true
              _this.EvaPanel.list = res.data
              _this.EvaPanel.page = res.SumPage
              _this.EvaPanel.count = res.Count
            }
          })
        },
          //搜索
          searchLawyer : function(){
              console.log(this.searchLaw)
              this.orderTypeCount(3)
          },
          //全选
          checkAllGroupChange : function(data){
              var _this = this
              _this.checkAllGroup = data
              var Data = data

          },
          handleCheckAll : function(){
              var _this = this
              var checkAllGroup = []//全选
              var back = []//反选
              console.log(_this.checkAllGroup.length,_this.adminLawyerList.length)
              console.log(_this.checkAllGroup.length,_this.adminLawyerList.length)
              // value.LawyerPhone+','+value.LawyerId
              if(_this.checkAllGroup.length == 0){
                  //全选
                  for(var i in _this.adminLawyerList){
                      checkAllGroup.push(_this.adminLawyerList[i].LawyerPhone+','+_this.adminLawyerList[i].LawyerId+','+_this.adminLawyerList[i].OrganizationId)
                  }
                  _this.checkAllGroup = checkAllGroup
                  console.log(1)
              }
              else{
                  //反选
                  console.log(_this.checkAllGroup)
                  for(var i in _this.adminLawyerList){
                      checkAllGroup.push(_this.adminLawyerList[i].LawyerPhone+','+_this.adminLawyerList[i].LawyerId+','+_this.adminLawyerList[i].OrganizationId)
                  }
                  // 首先构造Object
                  const idSet = _this.checkAllGroup.reduce((acc, v) => {
                      acc[v] = true;
                      return acc;
                  }, {});
                  // 遍历list2，去掉在idSet中存在的id
                  const result = checkAllGroup.filter(v => !idSet[v]);
                  _this.checkAllGroup = result
                  console.log(2)
              }
              var Data = _this.checkAllGroup

          },
          getSPC:function(item){
              var _this = this
              _this.SPC = {
                  state:false,
                  phoneNum:'',
                  lawyerName:'',
                  vallageName:'',
                  src:'',
              },
                  _this.send({
                      url:'/Hainan_getUnlimited',
                      data: {
                          scene: item.LawyerId+'p'+item.VillagesId+'p'+2,
                          LawyerId : item.LawyerId,
                          width: '430',
                          jpg: 'jpg',
                          page: 'pages/login/login',
                          userType : '1',
                          VillagesId : item.VillagesId
                      },
                      success:function(res){
                          _this.SPC.state = true
                          _this.SPC.phoneNum = item.LawyerPhone
                          _this.SPC.areaName = item._AdminName
                          _this.SPC.VNName = item.VillagesName
                          _this.SPC.lawyerName = item.LawyerName
                          _this.SPC.src = res
                      }
                  })
              console.log(item)
          },
          onLine : function(){
              var _this = this
              axios({
                  url:'https://cj.lvwa18.com:6003/cj/getOnlineNum',
                  method: 'get',
                  data: { AdminId: window.sessionStorage.AdminId,}
              }).then(res => {
                  _this.getOnlineNum = res.data
                  console.log('请求结果：', res.data);
              });
          },
          getYesterdayData:function(){
              var _this = this
              _this.send({
                  url:'/Hainan_YesterdayGetCount',
                  data:{
                      AdminId:window.sessionStorage.AdminId
                  },
                  success:function (res) {
                      _this.yesterdayData = {
                          village:res.villages_sql,
                          lawyer:res.lawyer_count,
                          user:res.user_count,
                          order:res.order_count,
                      }
                  }
              })
          },
          allDataView:function(){
              var _this = this
              let allDataDom = echarts.init(document.getElementById("allDataView"));
              _this.send({
                  url:'/Hainan_GetCount',
                  data: {
                      AdminId: window.sessionStorage.AdminId,
                  },
                  success: function (res) {
                      var time = []//时间
                      var order_count = []//咨询数量
                      var lawyer_count = []//律师数量
                      var user_count = []//用户数量
                      var villages_sql = []//创建村数量
                      for (var i in res) {
                          time.push(res[i].time)
                          order_count.push(res[i].order_count)
                          lawyer_count.push(res[i].lawyer_count)
                          user_count.push(res[i].user_count)
                          villages_sql.push(res[i].villages_sql)
                      }
                      // 绘制图表
                      allDataDom.setOption({
                          tooltip: {
                              trigger: "axis",
                              axisPointer: {
                                  type: "cross"
                              }
                          },
                          xAxis: {
                              type: "category",
                              axisTick: {
                                  alignWithLabel: true
                              },
                              data: time
                          },
                          legend: {
                              data: ["服务村（社区）", "用户人数", "律师人数", "咨询次数"],
                              left: "center",
                              // top: 50
                          },
                          grid: {
                              // top: 100
                          },
                          yAxis: [
                              {
                                  type: "value"
                              }
                          ],
                          series: [
                              {
                                  name: "服务村（社区）",
                                  type: "line",
                                  data: villages_sql
                              },
                              {
                                  name: "用户人数",
                                  type: "line",
                                  data: user_count
                              },
                              {
                                  name: "律师人数",
                                  type: "line",
                                  data: lawyer_count
                              },
                              {
                                  name: "咨询次数",
                                  type: "line",
                                  data: order_count
                              }
                          ]
                      });
                  }
              })
          },
          devicesDistributed:function(){
              var _this = this
              _this.send({
                  url:'/Hainan_UserPhoneTypeCount',
                  data:{
                      AdminId:window.sessionStorage.AdminId
                  },
                  success: function (res) {
                      var title = []
                      var data = []
                      _this.equipment = []
                      for(var index in res){
                          title.push(res[index].title)
                          data.push({name:res[index].title,value:res[index].total})
                          if(res[index].PhoneType == null){
                              _this.equipment.push({
                                  // key:res[index].PhoneType,
                                  name: '未知',
                                  value:res[index].user_count
                              })
                          }
                          if(res[index].PhoneType == '1'){
                              _this.equipment.push({
                                  name: 'Android',
                                  value:res[index].user_count
                              })
                          }if(res[index].PhoneType == '2'){
                              _this.equipment.push({
                                  name: 'Iphone',
                                  value:res[index].user_count
                              })
                          }if(res[index].PhoneType == '3'){
                              _this.equipment.push({
                                  name: '未知',
                                  value:res[index].user_count
                              })
                          }
                      }
                      var devicesDom = echarts.init(document.getElementById('devicesDistributed'))
                      var option = {
                          tooltip: {
                              trigger: 'item',
                              formatter: '{a} <br/>{b}: {c} ({d}%)'
                          },
                          legend: {top:40},
                          series: [
                              {
                                  name: '访问设备',
                                  type: 'pie',
                                  radius: ['50%', '70%'],
                                  avoidLabelOverlap: false,
                                  label: {
                                      show: false,
                                      position: 'center'
                                  },
                                  emphasis: {
                                      label: {
                                          show: true,
                                          fontSize: '24',
                                          fontWeight: 'bold'
                                      }
                                  },
                                  labelLine: {
                                      show: false
                                  },
                                  data: _this.equipment
                              }
                          ]
                      }
                      devicesDom.setOption(option, true);
                  }
              })

          },
          backMap:function(){
              this.map.mapName = this.map.mapHistory[this.map.mapHistory.length-2]
              this.map.mapHistory.length = this.map.mapHistory.length-1
              this.getMapGeo()
          },
          getMapGeo:function(){
              var _this = this
              _this.map.mapLodingState = true
              if(!_this.map.mapChart){
                  _this.map.mapChart = echarts.init(document.getElementById('mapChart'))
              }
              _this.map.mapChart.showLoading({
                  text: '数据正在加载...',
                  textStyle: { fontSize : 30 , color: '#444' },
                  effectOption: {backgroundColor: 'rgba(0, 0, 0, 0)'}
              });
              axios.get('https://restapi.amap.com/v3/config/district?parameters',{
                  params:{
                      key:'82c553750fcb97e01ef7b6e6e581ae79',
                      keywords:_this.map.mapName,
                      districts:0,
                  },
              })
                  .then((res)=>{
                      if(!res.data.districts[0]||res.data.districts[0].level=='district'){
                          _this.map.mapChart.hideLoading()
                          _this.map.mapLodingState = false
                          _this.$Message.warning({
                              content:'已经是最详细区域！'
                          })
                      }
                      else{
                          axios.get('/geo-json/'+res.data.districts[0].adcode+'.json')
                              .then((res1)=>{
                                  _this.getMapData(res.data.districts[0].adcode)
                                      .then((mapData)=>{
                                          _this.map.mapName = _this.map.mapName
                                          if(_this.map.mapHistory[_this.map.mapHistory.length-1]!=_this.map.mapName){
                                              _this.map.mapHistory.push(_this.map.mapName)
                                          }
                                          _this.loadMap(res1.data)
                                      })
                              })
                      }
                  })
          },
          loadMap:function(mapGeo){
              var _this = this
              var myChart = _this.map.mapChart
              echarts.registerMap(_this.map.mapName,mapGeo);
              var mapOption = {
                  title: {
                      text: '村居法律顾问平台 地图分布状况 - '+_this.map.mapName,
                      subtext: '鼠标点击可查看下级目录',
                      left: 'center',
                  },
                  visualMap: {
                      inRange: {
                          color: ['lightskyblue', 'yellow', 'orangered']
                      }
                  },
                  series:[
                      {
                          type:'map',
                          map:_this.map.mapName,
                          name: "服务村（社区）数",
                          data:_this.map.mapData
                      }
                  ]
              }
              myChart.setOption(mapOption, true);
              myChart.on('mousemove',function(params){
                  if(!params.data.type){return}
                  _this.map.childData = params.data.type || {}
                  _this.$refs.childPanel.style.display = 'block'
                  _this.$refs.childPanel.style.left = params.event.offsetX+20+'px'
                  _this.$refs.childPanel.style.top = params.event.offsetY+20+'px'
              })
              myChart.on('mouseout', function(params) {
                  _this.$refs.childPanel.style.display='none'
              })

              if(myChart._$handlers.click){
                  myChart._$handlers.click.length = 0;
              }
              myChart.on('click',function(params){
                  _this.map.mapName = params.name
                  _this.getMapGeo()
              });
              myChart.hideLoading()
              _this.map.mapLodingState = false
          },
          getMapData:function (adcode) {
              var _this = this
              return new Promise((resolve, reject) => {
                  _this.send({
                      url:'/Hainan_GetMapCount',
                      data:{
                          AreaCode:adcode,
                      },
                      success:function (res) {
                          _this.map.mapData = []
                          for(var index in res){
                              _this.map.mapData.push({
                                  name:res[index].AreaName,
                                  value:res[index].villages_count+res[index].lawyer_count+res[index].order_count+res[index].user_count,
                                  type:[
                                      {name:'服务村（社区）数',value:res[index].villages_count},
                                      {name:'律师人数',value:res[index].lawyer_count},
                                      {name:'用户人数',value:res[index].user_count},
                                      {name:'咨询次数',value:res[index].order_count},
                                  ],
                              })
                          }
                          resolve(res)
                      }
                  })
              })
          },
          newlyDataView:function () {
              var _this = this
              // 新增
              let myChart1 = echarts.init(document.getElementById("myChart1"),"light");
              _this.send({
                  url:'/Hainan_GetManageData',
                  data: {
                      AdminId : window.sessionStorage.AdminId,
                  },
                  success: function (res) {
                      var time = []//时间
                      var order_count = []//咨询数量
                      var lawyer_count = []//律师数量
                      var user_count = []//用户数量
                      var villages_sql = []//创建村数量
                      for(var i in res.list.day){
                          time.push(res.list.day[i].time)
                          order_count.push(res.list.day[i].order_count)
                          lawyer_count.push(res.list.day[i].lawyer_count)
                          user_count.push(res.list.day[i].user_count)
                          villages_sql.push(res.list.day[i].villages_sql)
                      }
                      myChart1.setOption({
                          tooltip: {
                              trigger: "axis",
                              axisPointer: {
                                  type: "cross"
                              }
                          },
                          // title: { text: "十日新增数据", left: "center" },
                          xAxis: {
                              type: "category",
                              axisTick: {
                                  alignWithLabel: true
                              },
                              data: time
                          },
                          legend: {
                              data: ["服务村（社区）数", "用户人数", "律师人数", "咨询次数"],
                              left: "center",
                              // top: 50
                          },
                          grid: {
                              // top: 100
                          },
                          yAxis: [
                              {
                                  type: "value"
                              }
                          ],
                          series: [
                              {
                                  name: "服务村（社区）数",
                                  type: "bar",
                                  data: villages_sql
                              },
                              {
                                  name: "用户人数",
                                  type: "bar",
                                  data: user_count
                              },
                              {
                                  name: "律师人数",
                                  type: "bar",
                                  data: lawyer_count
                              },
                              {
                                  name: "咨询次数",
                                  type: "line",
                                  data: order_count
                              }
                          ]
                      });
                  }
              })
          },
          lawList :function(){
              var _this = this
              _this.send({
                  url:'/Hainan_LawyerListById',
                  data: {
                      OrganizationId : _this.information.OrganizationId,
                      page: _this.page,
                      pageSize : _this.pageSize,
                  },
                  success: function (res) {
                      _this.listData = res.List
                      _this.count = res.count

                  }
              })
          },
          addMember :function(){
              var _this = this
              console.log(_this.AreaId)
              if(_this.organizationName != ''&&_this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword != ''&& _this.adminLogin != ''){
                  if(_this.cityList.length == 0){
                      _this.AreaId = _this.information.AreaId
                  }
                  _this.send({
                      url:'/Hainan_AddMember',
                      data: {
                          Level : _this.information.adminLevel,
                          AdminId : _this.information.AdminId,
                          AdminLogin : _this.adminLogin,
                          AdminPassword : _this.adminPassword,
                          OrganizationLinkman : _this.organizationLinkman,
                          OrganizationPhone : _this.organizationPhone,
                          OrganizationName : _this.organizationName,
                          AreaId : _this.AreaId,
                      },
                      success: function (res) {
                          _this.adminList()

                          _this.adminLogin=''
                          _this.adminPassword=''
                          _this.organizationLinkman=''
                          _this.organizationPhone=''
                          _this.organizationName =''
                          _this.AreaId = ''
                          _this.model8 = ''
                      }
                  })
              }else{
                  _this.$Notice.warning({
                      title:'系统提示',
                      desc:'输入完成后添加',
                  })
              }
          },
          ok:function () {
              var _this = this
              var op = _this.information.OrganizationId+'p'+_this.lawyerPhone +'p'+1
              if(_this.lawyerName != '' && _this.lawyerPhone != ''){
                  _this.send({
                      url:'/Hainan_getUnlimited',
                      data: {
                          OrganizationId : _this.information.OrganizationId,
                          scene: op,
                          userType : '2',
                          page: 'pages/login/login',
                          width: '430',
                          jpg: 'jpg',
                          LawyerName : _this.lawyerName,
                          LawyerPhone : _this.lawyerPhone
                      },
                      success: function (res) {
                          _this.listData = []
                          _this.page = '1'
                          _this.lawList()
                      }
                  })
              }else {
                  _this.$Notice.warning({
                      title:'系统提示',
                      desc:'名称或电话不能为空',
                  })
              }

          },
          cancel () {
              var _this = this
              _this.page = 1
              _this.count = 0
              _this.detailsSeeLIst = []
              _this.UserOrderLIst = []
              _this.model1 = false
              _this.model14 = false
              this.endTime = ''
              this.startTime = ''
              _this.timeData = ''
              console.log(_this.end_time)
              // _this.listData = []
          },
          enter:function(index){
              console.log(index)
              this.arCode = true
              this.current = index
          },
          alertbox:function(url,name,lawyerPhone,LawyerId,OrganizationId){
              var _this = this
              var _this = this
              var op = OrganizationId+'p'+lawyerPhone +'p'+1+'p'+LawyerId
              _this.send({
                  url:'/Hainan_getLawyerQRcode',
                  data: {
                      OrganizationId : OrganizationId,
                      scene: OrganizationId+'p'+lawyerPhone +'p'+1+'p'+LawyerId,
                      userType : '2',
                      page: 'pages/login/login',
                      width: '430',
                      jpg: 'jpg',
                      LawyerId : LawyerId
                  },
                  success: function (res) {
                      _this.lawImg = 'https://vn-server.lvwa18.com/'+res
                  }
              })
              console.log(op)
              _this.modal5 = true
              // _this.urlImg = url
              _this.lawName = name
          },
          alertbox1:function(url,name,lawyerId1,lLawyerPhone,OrganizationId){
              var _this = this
              var op = OrganizationId+'p'+lLawyerPhone +'p'+1+'p'+lawyerId1+''
              _this.send({
                  url:'/Hainan_getLawyerQRcode',
                  data: {
                      OrganizationId : OrganizationId,
                      scene: op,
                      userType : '2',
                      page: 'pages/login/login',
                      width: '430',
                      jpg: 'jpg',
                      LawyerId : lawyerId1
                  },
                  success: function (res) {
                      _this.lawImg = 'https://vn-server.lvwa18.com/' +res
                  }
              })
              console.log(op)
              _this.modal6 = true
              // _this.urlImg = url
              _this.lawName = name
              _this.lawyerId1 = lawyerId1
              _this.lLawyerPhone = lLawyerPhone
          },
          downloadUrl : function(){
              var _this = this
              _this.modal5 = false
              // window.URL = _this.urlImg
              var downloadIamge = function(imgsrc, name) { //下载图片地址和图片名
                  let image = new Image();
                  // 解决跨域 Canvas 污染问题
                  image.setAttribute("crossOrigin", "anonymous");
                  image.onload = function() {
                      let canvas = document.createElement("canvas");
                      canvas.width = image.width;
                      canvas.height = image.height;
                      let context = canvas.getContext("2d");
                      context.drawImage(image, 0, 0, image.width, image.height);
                      let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
                      let a = document.createElement("a"); // 生成一个a元素
                      let event = new MouseEvent("click"); // 创建一个单击事件
                      a.download = name || "photo"; // 设置图片名称
                      a.href = url; // 将生成的URL设置为a.href属性
                      a.dispatchEvent(event); // 触发a的单击事件
                  };
                  image.src = imgsrc;
              }
              downloadIamge(_this.urlImg, _this.lawName+".jpg");
          },
          leave(){
              this.arCode = false
              this.current = null
          },
          //    统计
          switchData : function (e) {
              var _this = this
              _this.typeId = _this.timelist[e].value
              _this.send({
                  url:'/Hainan_GetOrganizationCount',
                  data: {
                      AdminId : window.sessionStorage.AdminId,
                      typeId : _this.typeId,
                      start_time : _this.startTime ,//开始时间
                      end_time : _this.endTime
                  },
                  success: function (res) {
                      _this.statisticsData[0].value = res.villages_count
                      _this.statisticsData[1].value = res.lawyer_count
                      _this.statisticsData[2].value = res.user_count
                      _this.statisticsData[3].value = res.order_count
                  }
              })
          },
          //    管理列表
          adminList : function () {
              var _this = this
              _this.send({
                  url:'/Hainan_AdminList',
                  data: {
                      AdminId : _this.information.AdminId,
                  },
                  success: function (res) {
                      console.log(res)
                      _this.listData = res
                      _this.count = res.count
                  }
              })
          },
          //    机器人咨询TOP30
          robot : function(){
              var _this = this
              _this.modal13 = true
              this.$nextTick(() => {
                  this.robotImplement()
              })
          },
          robotImplement : function(){
              var _this = this
              let myChart1 = echarts.init(document.getElementById("robot"),"light");
              _this.send({
                  url:'/Hainan_keyword_click',
                  data: {
                      AdminId : _this.information.AdminId,
                  },
                  success: function (res) {
                      var click_count = []//数量
                      var keyword = []//名称
                      var gid = []//名称

                      var compare = function (prop) {
                          return function (obj1, obj2) {
                              var val1 = obj1[prop];
                              var val2 = obj2[prop];
                              if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
                                  val1 = Number(val1);
                                  val2 = Number(val2);
                              }
                              if (val1 < val2) {
                                  return -1;
                              } else if (val1 > val2) {
                                  return 1;
                              } else {
                                  return 0;
                              }
                          }
                      }
//调用方式
                      res.sort(compare("click"))
                      console.log(res.sort(compare("click")))
                      // for(var i=res.length-1;i>=0;i--){
                      //     click_count.push(res[i].click)
                      //     keyword.push(res[i].keyword)
                      // }
                      for(var i in res){
                          click_count.push(res[i].click)
                          keyword.push(res[i].keyword)
                          gid.push(res[i].gid)
                      }
                      console.log(keyword)
                      // myChart1.on('click', params => {
                      //     console.log(params)
                      // })
                      myChart1.getZr().on('click', params => {
                          console.log(gid[clickname.dataIndex])
                          // console.log(gid[clickname.dataIndex])
                          _this.send({
                              url:'/Hainan_keyword_details',
                              data: {
                                  AdminId : window.sessionStorage.AdminId,
                                  gid : gid[clickname.dataIndex],
                                  keyword : clickname.name ,//开始时间
                              },
                              success: function (res) {
                                  _this.listData = res
                                  _this.modal8 = !_this.modal8
                              }
                          })
                      })
                      var clickname
                      var clickid
                      myChart1.setOption({
                          title: {
                              text: '关键字点击量TOP30',
                              subtext: '案由TOP30'
                          },
                          tooltip: {
                              trigger: 'axis',
                              formatter:function(params){
                                  for(var i in params){
                                      clickname = params[i]

                                      // return params[i].name+','+ gid[i]
                                  }
                                  for(var i in gid){
                                      clickid = gid[i]
                                      // console.log(gid[i])
                                  }
                              },
                              axisPointer: {
                                  type: 'shadow'
                              }
                          },
                          legend: {
                              data: ['关键字']
                          },
                          grid: {
                              left: '3%',
                              right: '4%',
                              bottom: '3%',
                              containLabel: true
                          },
                          xAxis: {
                              type: 'value',
                              boundaryGap: [0, 0.01],
                          },
                          yAxis: {
                              type: 'category',
                              data:keyword
                          },
                          series: [
                              {
                                  label: {
                                      show: true,
                                      position: 'right'
                                  },
                                  name: gid,
                                  type: 'bar',
                                  data:click_count
                              }
                          ]
                      });
                  }
              })
          },
          //    服务村
          orderTypeCount : function (type,adminId,index) {
              console.log(type)
              if(type == 1){
                  var _this = this
                  _this.modal10 = true
                  _this.adminVillagesData = []
                  console.log(adminId)
                  if(adminId == undefined){
                      _this.administration = _this.information.AdminId
                  }else {
                      _this.administration = adminId
                  }
                  if(_this.information.AdminId == '0' || _this.information.adminLevel == '1'){
                      _this.send({
                          url:'/Hainan_AdminVillages',
                          data: {
                              AdminId : _this.administration,
                              typeId : _this.typeId,
                              page : _this.page,
                              pageSize : _this.pageSize,
                              key : _this.search,
                              start_time : _this.startTime ,//开始时间
                              end_time : _this.endTime,
                              sort : _this.sort
                          },
                          success: function (res) {
                              _this.adminVillagesData = res.List
                              _this.count = res.Count
                          }
                      })
                  }
                  else{
                      _this.send({
                          url:'/Hainan_AdminVillagesList',
                          data: {
                              AdminId : _this.administration,
                              typeId : _this.typeId,
                              page : _this.page,
                              pageSize : _this.pageSize,
                              key : _this.search,
                              start_time : _this.startTime ,//开始时间
                              end_time : _this.endTime,
                              sort : _this.sort
                          },
                          success: function (res) {
                              _this.adminVillagesData = res.List
                              _this.count = res.Count
                          }
                      })
                  }
              }
              if(type == 2){
                  var _this = this
                  _this.modal11 = true
                  _this.send({
                      url:'/Hainan_AdminUserList',
                      data: {
                          AdminId : _this.information.AdminId,
                          typeId : _this.typeId,
                          page : _this.page,
                          pageSize : _this.pageSize,
                          start_time : _this.startTime ,//开始时间
                          end_time : _this.endTime,
                          VillagesId : _this.VillagesId
                      },
                      success: function (res) {
                          _this.adminUserData = res.List
                          _this.count = res.Count
                      }
                  })
              }
              if(type == 3){
                  var _this = this
                  _this.modal15 = true
              _this.checkAllGroup = []
                  _this.send({
                      url:'/Hainan_AdminLawyerList',
                      data: {
                          AdminId : _this.information.AdminId,
                          page : _this.page,
                          pageSize : _this.pageSize,
                          key : _this.searchLaw
                      },
                      success: function (res) {
                          _this.adminLawyerList = res.List
                          for(var i in res.List){

                              var num = res.List[i].avgs
                              console.log(Math.floor(num * 100) / 100 )
                          }
                          _this.count = res.Count
                          _this.avgs =  res.List[0].avgs
                          _this.sum = res.Sum
                      }
                  })
              }
              if(type == 4){
                  var _this = this
                  _this.send({
                      url:'/Hainan_OrderTypeCount',
                      data: {
                          AdminId : _this.information.AdminId,
                          typeId : _this.typeId,
                          start_time : _this.startTime ,//开始时间
                          end_time : _this.endTime
                      },
                      success: function (res) {
                          _this.orderTypeCountData = res
                      }
                  })
              }if(type == 5){
                  var _this = this
                  _this.effect = index
                  if(index == 1){
                      _this.send({
                          url:'/Hainan_UserOrderLIst',
                          data: {
                              AdminId : _this.information.AdminId,
                              start_time : _this.startTime,//开始时间
                              end_time : _this.endTime,//结束时间
                              nameKeyId : _this.nameKeyId,//关键字
                              page : _this.page,
                              pageSize : _this.pageSize,
                              VillagesId : _this.VillagesId
                          },
                          success: function (res) {
                              _this.UserOrderLIst = res.list
                              _this.count = res.count
                          }
                      })
                  }if(index == 2){
                      _this.send({
                          url:'/Hainan_ValidUserList',
                          data: {
                              AdminId : _this.information.AdminId,
                              start_time : _this.startTime,//开始时间
                              end_time : _this.endTime,//结束时间
                              nameKeyId : _this.nameKeyId,//关键字
                              page : _this.page,
                              pageSize : _this.pageSize,
                              VillagesId : _this.VillagesId
                          },
                          success: function (res) {
                              _this.UserOrderLIst = res.list
                              _this.count = res.count
                          }
                      })
                  }

                  // 详情
              }
          },
          replace : function () {
              var _this = this
              var op = _this.information.OrganizationId+'p'+_this.lawyerPhone +'p'+1
              _this.send({
                  url:'/Hainan_getUnlimited',
                  data: {
                      OrganizationId : _this.information.OrganizationId,
                      scene: op,
                      userType : 2,
                      page: 'pages/login/login',
                      width: '430',
                      jpg: 'jpg',
                      LawyerName : _this.lawyerName,
                      LawyerPhone : _this.lawyerPhone,
                      LawyerId : _this.lawyerId
                  },
                  success: function (res) {
                      _this.listData = []
                      _this.lawyerName = '',
                          _this.lawyerPhone = '',
                          _this.page = '1'
                      _this.lawList()
                  }
              })
          },
          deleteLawFirm : function (adminid,organizationId) {
              var _this = this
              _this.send({
                  url:'/Hainan_deleteMember',
                  confirm:true,
                  data: {
                      AdminId : adminid,
                      Level : _this.information.Level,
                      OrganizationId : organizationId
                  },
                  success: function (res) {
                      console.log(res)
                      _this.listData = []
                      _this.page = '1'
                      _this.adminList()
                  }
              })
          },
          sendOut : function () {
              var _this = this
              _this.send({
                  url:'/Hainan_SendMessage',
                  data: {
                      LawyerId:_this.lawyerId1,
                      LawyerPhone:_this.lLawyerPhone},
                  success: function (res) {
                      if(res.Code == 'OK'){
                          _this.$Notice.warning({
                              title:'系统提示',
                              desc:'发送成功',
                          })
                      }
                  }
              })
          },
          //    地区
          getAreaData : function () {
              var _this = this
              _this.send({
                  url:'/Hainan_GetAreaData',
                  data: {
                      AreaId: _this.information.AreaId,
                  },
                  success: function (res) {
                      //
                      _this.cityList = res[1]
                      console.log(_this.cityList)

                      //
                      convertTree(res[1])
                      function convertTree(tree) {
                          const result = []
                          tree.forEach((item) => {
                              // 解构赋值
                              let {
                                  AreaId: value,
                                  AreaName: label,
                                  children: children
                              } = item
                              if (children) {
                                  children = convertTree(children)
                              }
                              result.push({
                                  value,
                                  label,
                                  children
                              })
                          })
                          _this.addressData = result
                          return result
                      }
                      console.log(_this.addressData)
                  }
              })
          },
          choice : function(value){
              var _this = this
              var data = value.value.split(',')
              console.log(data)
              _this.AreaId = data[0]
              if(data[2] == '1'){
                  _this.areaData = _this.cityList[data[1]].children
              }else {

              }
              console.log()
              _this.send({
                  url:'/Hainan_GetfileList',
                  data: {
                      AreaId: data[0],
                      AdminId: _this.information.AdminId,
                  },
                  success: function (res) {
                      console.log(res)
                      var ElectronicsList = []
                      _this.ElectronicsList = res
                  }
              })
          },
          oncliTime : function (e) {
              console.log(e)
              var _this = this
              _this.start_time = e[0],//开始时间
                  _this.end_time = e[1]//结束时间
              _this.page = 1
              _this.orderTypeCount(5,undefined,_this.effect)
          },
          totalTime : function(e){
              var _this = this
              _this.startTime = e[0],//开始时间
                  _this.endTime = e[1]//结束时间
              _this.page = 1
              _this.switchData(0)
          },
          totalTime0 : function(e){
              var _this = this
              _this.startTime = e[0],//开始时间
                  _this.endTime = e[1]//结束时间
              _this.page = 1
              _this.orderTypeCount(5,undefined,_this.effect)
          },
          totalTime1 : function(e){
              var _this = this
              _this.startTime = e[0],//开始时间
                  _this.endTime = e[1]//结束时间
              _this.page = 1
              _this.detailsSee(_this.serviceType,_this.serviceName)
          },
          totalTime2 : function(e){
              var _this = this
              _this.startTime = e[0],//开始时间
                  _this.endTime = e[1]//结束时间
              _this.page = 1
              _this.dialTelephone(_this.serviceType,_this.serviceName,_this.typeData)
          },
          totalTime3 : function(e){
              var _this = this
              _this.startTime = e[0],//开始时间
                  _this.endTime = e[1]//结束时间
              _this.page = 1
              _this.orderTypeCount(2)
          },
          lawyerList : function () {
              var _this = this
              _this.send({
                  url:'/Hainan_LawyerList',
                  data: {
                      AdminId: _this.information.AdminId,
                  },
                  success: function (res) {
                      _this.lawyerData = res.list
                      console.log(res)

                  }
              })
          },
          lawyerClick : function (e) {
              var _this = this
              if(e == undefined){
                  _this.nameKeyId = ''
              }else {
                  _this.nameKeyId = e
              }
              _this.page = 1
              _this.orderTypeCount(5,undefined,_this.effect)
              console.log(e)
          },
          lawyerChoice : function (e) {
              var _this = this
              if(e == undefined){
                  _this.nameKeyId = ''
              }else {
                  _this.nameKeyId = e
              }
              _this.page = 1
              _this.detailsSee(_this.serviceType,_this.serviceName)
              console.log(e)
          },
          lawyerChoiceA : function (e) {
              var _this = this
              if(e == undefined){
                  _this.nameKeyId = ''
              }else {
                  _this.nameKeyId = e
              }
              _this.page = 1
              _this.dialTelephone(_this.serviceType,_this.serviceName,_this.typeData)
              console.log(e)
          },
          searchDara : function () {
              console.log()
              var _this = this
              _this.adminVillagesData = []
              _this.count = ''
              _this.page = '1'
              _this.orderTypeCount(1)
          },
          //    排序
          sortClick : function (e) {
              var _this = this
              _this.adminVillagesData = []
              _this.count = ''
              _this.page = '1'
              _this.sort = e
              _this.orderTypeCount(1)
              console.log(this.sort)
          },
          dialTelephone : function(type,name,typeData){
              var _this = this
              _this.serviceType = type
              _this.serviceName = name
              _this.typeData = typeData
              _this.lawyerList()
              _this.send({
                  url:'/Hainan_UserCallList',
                  data: {
                      type : typeData,
                      AdminId : _this.information.AdminId,
                      start_time : _this.startTime,//开始时间
                      end_time : _this.endTime,//结束时间
                      page : _this.page,
                      pageSize : _this.pageSize,
                      VillagesId : _this.VillagesId,
                      nameKeyId : _this.nameKeyId,//关键字
                  },
                  success: function (res) {
                      console.log(res.List)
                      _this.UserCallList = res.List
                      _this.count = res.Count
                  }
              })
          },
          detailsSee : function (type,name) {
              var _this = this
              _this.serviceType = type
              _this.serviceName = name
              // _this.lawyerList()
              console.log(type)
              if(type == 3){
                  var _this = this
                  _this.send({
                      url:'/Hainan_UserVideoList',
                      data: {
                          AdminId : _this.information.AdminId,
                          start_time : _this.startTime,//开始时间
                          end_time : _this.endTime,//结束时间
                          page : _this.page,
                          pageSize : _this.pageSize,
                          VillagesId : _this.VillagesId,
                          nameKeyId : _this.nameKeyId,//关键字
                      },
                      success: function (res) {
                          _this.detailsSeeLIst = res.list
                          console.log(_this.detailsSeeLIst)
                          _this.count = res.count
                      }
                  })
              }
              if(type == 4){
                  var _this = this
                  _this.send({
                      url:'/Hainan_UserDecisionList',
                      data: {
                          AdminId : _this.information.AdminId,
                          start_time : _this.startTime,//开始时间
                          end_time : _this.endTime,//结束时间
                          page : _this.page,
                          pageSize : _this.pageSize,
                          VillagesId : _this.VillagesId,
                          nameKeyId : _this.nameKeyId,//关键字
                      },
                      success: function (res) {
                          _this.detailsSeeLIst = res.list
                          console.log(_this.detailsSeeLIst)
                          _this.count = res.count
                      }
                  })
              }
              if(type == 5){
                  var _this = this
                  _this.send({
                      url:'/Hainan_UserWritList',
                      data: {
                          AdminId : _this.information.AdminId,
                          start_time : _this.startTime,//开始时间
                          end_time : _this.endTime,//结束时间
                          page : _this.page,
                          pageSize : _this.pageSize,
                          VillagesId : _this.VillagesId,
                          nameKeyId : _this.nameKeyId,//关键字
                      },
                      success: function (res) {
                          _this.detailsSeeLIst = res.list
                          console.log(_this.detailsSeeLIst)
                          _this.count = res.count
                      }
                  })
              }
              if(type == 6){
                  var _this = this
                  _this.send({
                      url:'/Hainan_UserLegalList',
                      data: {
                          AdminId : _this.information.AdminId,
                          start_time : _this.startTime,//开始时间
                          end_time : _this.endTime,//结束时间
                          page : _this.page,
                          pageSize : _this.pageSize,
                          VillagesId : _this.VillagesId,
                          nameKeyId : _this.nameKeyId,//关键字
                      },
                      success: function (res) {
                          _this.detailsSeeLIst = res.list
                          console.log(_this.detailsSeeLIst)
                          _this.count = res.count
                      }
                  })
              }
              if(type == 7){
                  var _this = this
                  _this.send({
                      url:'/Hainan_UserHandleList',
                      data: {
                          AdminId : _this.information.AdminId,
                          start_time : _this.startTime,//开始时间
                          end_time : _this.endTime,//结束时间
                          page : _this.page,
                          pageSize : _this.pageSize,
                          VillagesId : _this.VillagesId,
                          nameKeyId : _this.nameKeyId,//关键字
                      },
                      success: function (res) {
                          _this.detailsSeeLIst = res.list
                          console.log(_this.detailsSeeLIst)
                          _this.count = res.count
                      }
                  })
              }
              if(type == 8){
                  var _this = this
                  _this.send({
                      url:'/Hainan_UserMediateList',
                      data: {
                          AdminId : _this.information.AdminId,
                          start_time : _this.startTime,//开始时间
                          end_time : _this.endTime,//结束时间
                          page : _this.page,
                          pageSize : _this.pageSize,
                          VillagesId : _this.VillagesId,
                          nameKeyId : _this.nameKeyId,//关键字
                      },
                      success: function (res) {
                          _this.detailsSeeLIst = res.list
                          console.log(_this.detailsSeeLIst)
                          _this.count = res.count
                      }
                  })
              }
              if(type == 9){
                  var _this = this
                  _this.send({
                      url:'/Hainan_UserMediateList',
                      data: {
                          AdminId : _this.information.AdminId,
                          start_time : _this.startTime,//开始时间
                          end_time : _this.endTime,//结束时间
                          page : _this.page,
                          pageSize : _this.pageSize,
                          VillagesId : _this.VillagesId,
                          nameKeyId : _this.nameKeyId,//关键字
                      },
                      success: function (res) {
                          _this.detailsSeeLIst = res.list
                          console.log(_this.detailsSeeLIst)
                          _this.count = res.count
                      }
                  })
              }
          },
          remoteMethod2 (query) {
              console.log(query)
              var _this = this
              _this.send({
                  url:'/Hainan_villagesList',
                  data: {
                      AdminId : _this.information.AdminId,
                      Key : query,
                  },
                  success: function (res) {
                      console.log(res)
                      _this.options2 = res.list
                  }
              })
          },
          lawyerClick2 : function (e) {
              var _this = this
              if(e == undefined){
                  _this.VillagesId  = ''
                  _this.options2 = []
              }else {
                  _this.VillagesId  = e
              }
              _this.page = 1
              _this.orderTypeCount(5,undefined,_this.effect)
              console.log(e)
          },
          lawyerClick3 : function (e) {
              var _this = this
              if(e == undefined){
                  _this.VillagesId  = ''
                  _this.options2 = []
              }else {
                  _this.VillagesId  = e
              }
              _this.page = 1
              _this.orderTypeCount(2)
              console.log(e)
          },
          lawyerChoice2 : function (e) {
              var _this = this
              if(e == undefined){
                  _this.VillagesId  = ''
                  _this.options2 = []
              }else {
                  _this.VillagesId  = e
              }
              _this.page = 1
              _this.detailsSee(_this.serviceType,_this.serviceName)
              console.log(e)
          },
          lawyerChoiceA2 : function (e) {
              var _this = this
              if(e == undefined){
                  _this.VillagesId  = ''
                  _this.options2 = []
              }else {
                  _this.VillagesId  = e
              }
              _this.page = 1
              _this.dialTelephone(_this.serviceType,_this.serviceName,_this.typeData)
              console.log(e)
          },
          opinion1 : function(e){
              this.opinionpage = 1
              this.opinion(e)
          },
          opinion : function (e) {
              var _this = this
              _this.opinionData = []
              _this.send({
                  url:'/Hainan_FeedbackList',
                  data: {
                      AdminId : _this.information.AdminId,
                      type : e,
                      page : _this.opinionpage,
                      pageSize : _this.opinionpageSize
                  },
                  success: function (res) {
                      console.log(res)
                      _this.opinioncount = res.Count
                      _this.opinionData = res.List
                  }
              })
          },
          serviceCommunity : function (e) {
              var _this = this
              _this.modelA1 = '',
                  _this.modelA2 = '',
                  _this.modelA3 = '',
                  _this.modelA4 = '',
                  _this.modelA5 = '',
                  _this.CommunityData1 = [],
                  console.log(_this.adminVillagesData[e[0]].AdminId)
              _this.send({
                  url:'/Hainan_AdminVillagesList',
                  data: {
                      AdminId : _this.adminVillagesData[e[0]].AdminId,
                      typeId : _this.typeId,
                      page : _this.page,
                      pageSize : _this.pageSize,
                      key : _this.search,
                      start_time : _this.startTime ,//开始时间
                      end_time : _this.endTime,
                      sort : _this.sort
                  },
                  success: function (res) {
                      _this.CommunityData1 = res.List
                  }
              })
          },
          serviceCommunity2 : function (e) {
              var _this = this
              _this.modelA1 = '',
                  _this.modelA2 = '',
                  _this.modelA3 = '',
                  _this.modelA4 = '',
                  _this.modelA5 = '',
                  _this.CommunityData2 = [],
                  _this.send({
                      url:'/Hainan_AdminVillagesList',
                      data: {
                          AdminId : _this.CommunityData1[e[0]].AdminId,
                          typeId : _this.typeId,
                          page : _this.page,
                          pageSize : _this.pageSize,
                          key : _this.search,
                          start_time : _this.startTime ,//开始时间
                          end_time : _this.endTime,
                          sort : _this.sort
                      },
                      success: function (res) {
                          _this.CommunityData2 = res.List
                      }
                  })
          },
          serviceCommunity3 : function (e) {
              var _this = this
              _this.modelA1 = '',
                  _this.modelA2 = '',
                  _this.modelA3 = '',
                  _this.modelA4 = '',
                  _this.modelA5 = '',
                  _this.CommunityData3 = [],
                  _this.send({
                      url:'/Hainan_AdminVillagesList',
                      data: {
                          AdminId : _this.CommunityData2[e[0]].AdminId,
                          typeId : _this.typeId,
                          page : _this.page,
                          pageSize : _this.pageSize,
                          key : _this.search,
                          start_time : _this.startTime ,//开始时间
                          end_time : _this.endTime,
                          sort : _this.sort
                      },
                      success: function (res) {
                          _this.CommunityData3 = res.List
                      }
                  })
          },
          serviceCommunity4 : function (e) {
              var _this = this
              _this.modelA1 = '',
                  _this.modelA2 = '',
                  _this.modelA3 = '',
                  _this.modelA4 = '',
                  _this.modelA5 = '',
                  _this.CommunityData4 = [],
                  _this.send({
                      url:'/Hainan_AdminVillagesList',
                      data: {
                          AdminId : _this.CommunityData3[e[0]].AdminId,
                          typeId : _this.typeId,
                          page : _this.page,
                          pageSize : _this.pageSize,
                          key : _this.search,
                          start_time : _this.startTime ,//开始时间
                          end_time : _this.endTime,
                          sort : _this.sort
                      },
                      success: function (res) {
                          _this.CommunityData4 = res.List
                      }
                  })
          },
          serviceCommunity5 : function (e) {
              var _this = this
              _this.modelA1 = '',
                  _this.modelA2 = '',
                  _this.modelA3 = '',
                  _this.modelA4 = '',
                  _this.modelA5 = '',
                  _this.CommunityData5 = [],
                  _this.send({
                      url:'/Hainan_AdminVillagesList',
                      data: {
                          AdminId : _this.CommunityData4[e[0]].AdminId,
                          typeId : _this.typeId,
                          page : _this.page,
                          pageSize : _this.pageSize,
                          key : _this.search,
                          start_time : _this.startTime ,//开始时间
                          end_time : _this.endTime,
                          sort : _this.sort
                      },
                      success: function (res) {
                          _this.CommunityData5 = res.List
                      }
                  })
          },
          //    10日统计
          getTenData : function () {
              var _this = this
              let myChartGetTen = echarts.init(document.getElementById("myChartGetTen"),"light");
              _this.send({
                  url:'/Hainan_GetTenData',
                  data: {
                      AdminId : _this.information.AdminId,
                  },
                  success: function (res) {
                      _this.getTen_Data = res.list.day
                      var time = []//底部日期
                      var Aid_count = []//
                      var Self_count  = []//
                      var video_count  = []//
                      var Moni_count  = []//
                      var Daixie_count  = []//
                      var Legal_count  = []//
                      var Banli_count  = []//
                      var Tiaojie_count  = []//
                      var Phone_count  = []//
                      var Rexian_count  = []//
                      for(var i in res.list.day){
                          time.push(res.list.day[i].time)
                          time.splice(12,12)
                          Aid_count.push(res.list.day[i].Aid_count)
                          Aid_count.splice(12,12)
                          Self_count.push(res.list.day[i].Self_count )
                          Self_count.splice(12,12)
                          video_count.push(res.list.day[i].video_count )
                          video_count.splice(12,12)
                          Moni_count.push(res.list.day[i].Moni_count )
                          Moni_count.splice(12,12)
                          Daixie_count.push(res.list.day[i].Daixie_count )
                          Daixie_count.splice(12,12)
                          Legal_count.push(res.list.day[i].Legal_count )
                          Legal_count.splice(12,12)
                          Banli_count.push(res.list.day[i].Banli_count )
                          Banli_count.splice(12,12)
                          Tiaojie_count.push(res.list.day[i].Tiaojie_count )
                          Tiaojie_count.splice(12,12)
                          Phone_count.push(res.list.day[i].Phone_count )
                          Phone_count.splice(12,12)
                          Rexian_count.push(res.list.day[i].Rexian_count )
                          Rexian_count.splice(12,12)
                      }
                      console.log(Aid_count,time)

                      // 绘制图表
                      var datanums = []
                      var visit = []
                      myChartGetTen.setOption({
                          title: {
                              text: ''
                          },
                          tooltip: {
                              trigger: 'axis',
                              // hideDelay: '10000',
                              axisPointer: {
                                  type: "cross"
                              }
                          },
                          legend: {
                              data:['人工咨询','自助咨询','视频咨询','模拟判决','代拟法律文书','法律援助','办理公证','调解申请','律师电话','拨打12348热线']
                          },
                          grid: {
                              left: '3%',
                              right: '4%',
                              bottom: '3%',
                              containLabel: true
                          },
                          toolbox: {
                              feature: {
                                  saveAsImage: {}
                              }
                          },
                          xAxis: {
                              name: '日期',
                              type: 'category',
                              boundaryGap: false,
                              //时间显示
                              data: time
                          },
                          yAxis: {
                              name: '数',
                              type: 'value',
                          },
                          series: [
                              {
                                  name:'人工咨询',
                                  type:'line',
                                  data:Aid_count
                              },
                              {
                                  name:'自助咨询',
                                  type:'line',
                                  data:Self_count
                              },
                              {
                                  name:'视频咨询',
                                  type:'line',
                                  data: video_count
                              },
                              {
                                  name:'模拟判决',
                                  type:'line',
                                  data:Moni_count
                              },
                              {
                                  name:'代拟法律文书',
                                  type:'line',
                                  data: Daixie_count
                              },
                              {
                                  name:'法律援助',
                                  type:'line',
                                  data:Legal_count
                              },
                              {
                                  name:'办理公证',
                                  type:'line',
                                  data:Banli_count
                              },
                              {
                                  name:'调解申请',
                                  type:'line',
                                  data:Tiaojie_count
                              },
                              {
                                  name:'律师电话',
                                  type:'line',
                                  data:Phone_count
                              },
                              {
                                  name:'拨打12348热线',
                                  type:'line',
                                  data:Rexian_count
                              },
                          ]

                      });
                  }
              })
          },
          jurisdiction : function () {
              var _this = this
              _this.send({
                  url:'/Hainan_DifficultCount',
                  data:{
                      AdminId:window.sessionStorage.AdminId
                  },
                  success:function (res) {
                      _this.difficultCount = res.list
                      _this.count1 = res.count
                      _this.countData = res.count
                      console.log(res)
                  }
              })
          },
          serviceClick : function (LawyerId) {
              var _this = this
              _this.send({
                  url:'/GetLawyerCount_',
                  data:{
                      LawyerId : LawyerId,
                      start_time : _this.startTime ,//开始时间
                      end_time : _this.endTime,
                      VillagesId : _this.VillagesId
                  },
                  success:function (res) {
                      _this.statisticsData1 = res
                      console.log(res)
                  }
              })
              _this.send({
                  url:'/Hainan_LawyerOrderTypeCount',
                  data:{
                      LawyerId : LawyerId,
                      start_time : _this.startTime ,//开始时间
                      end_time : _this.endTime,
                      VillagesId : _this.VillagesId
                  },
                  success:function (res) {
                      _this.lawyerOrderTypeData = res
                  }
              })
          },
          findDazhou : function(){
              var _this = this
              _this.send({
                  url:'/Hainan_FindDazhou',
                  data: {
                      type:'chengdu'
                  },
                  success: function (res) {
                      for(var i in res){
                          if(res[i] == _this.information.AdminId){
                              _this.chengdu = true
                              return
                          }else {
                              _this.chengdu = false
                          }
                      }
                      console.log(res)
                  }
              })
          },
      },
      computed:{
        areaId:function(){
          return window.sessionStorage.AreaId
        },
      }
  }
</script>

