<style scoped>
  .tableTitle{font-size:20px;}
  .export-btn{font-size:10px;color:#2d8cf0;cursor:pointer;}
</style>

<template>
  <div>
    <div style="padding:10px"></div>
    <div style="text-align: center;">
      <span class="tableTitle">区域咨询申办统计</span>
      <div class="export-btn" @click="exportData(table.data)">[导出数据]</div>
    </div>
    <div style="padding:5px"></div>
    <Table height="500" border :loading="table.loading" :columns="table.columns" :data="table.data" :resizable="true" ref="table" show-summary @on-row-click="lowerLevel" :summary-method="handleSummary"></Table>
    <div style="padding:20px"></div>
    <!--    查看下级数据-->
    <Modal
        v-model="modal1"
        title="区域咨询申办统计" @on-cancel="cancel" width="1200">
      <div slot="footer">
        <template>
          <Button
              size="large"
              @click="cancel"
          >关闭</Button>
        </template>
      </div>
      <div>
        <div style="text-align: center;">
          <div class="tableTitle">区域咨询申办统计</div>
          <span class="export-btn" @click="exportData(table1.data)">[导出数据]</span>
        </div>
        <div style="padding:5px"></div>
        <Table height="550" border :loading="table1.loading" :columns="table1.columns" :data="table1.data" ref="table1" :resizable="true" show-summary :summary-method="handleSummary"></Table>
        <div style="padding:20px"></div>
      </div>
    </Modal>
  </div>
</template>

<script>
//表头控制文件
import * as tableColumns from "./table-columns"
import * as tableDataSorting from "./data-sorting"
export default {
  name:'consultation-area-table',//分区域总统计表
  props:{
    dateSelecter:{type:Object,required:true,},
  },
  data:function(){
    return{
      table:{
        loading:true,
        columns:tableColumns.consultationAreaTable,
        data:[],
      },
      table1:{
        loading:false,
        columns:tableColumns.consultationAreaTable,
        data:[],
        areaName : '',
      },
      modal1 : false
    }
  },
  watch:{
    'dateSelecter.identification':function(val){
      if(val != 'custom'){
        this.getTableData()
      }
    }
  },
  mounted:function(){
    this.getTableData()
  },
  methods:{
    handleSummary ({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[key] = {
            key,
            value: v + ''
          };
        } else {
          if(this.modal1){
            sums[key] = {
              key,
              value: this.table1.areaName
            };
          }else {
            sums[key] = {
              key,
              value: window.sessionStorage.AdminName
            };
          }
        }
      });

      return sums;
    },
    exportData : function (data){
      var _this = this
      this.$refs.table.exportCsv({
        filename: '区域咨询申办统计',
        columns : _this.table.columns,
        data : data
      });
    },
    lowerLevel : function (data,index){
      // console.log(this.dateSelecter)
      if(window.sessionStorage.Hierarchy == 2 || window.sessionStorage.Hierarchy == 1){
        this.table1.data = []
        this.table1.areaName = data.areaName
        this.table.loading = false
        this.dateSelecter.AdminId = data.adminId
        this.modal1 = true
        this.getTableData()
      }
    },
    // 弹窗取消
    cancel : function (){
      this.dateSelecter.AdminId = ''
      this.modal1 = false
    },
    //区域咨询申办统计
    getTableData:function(){
      var _this = this
      if(this.modal1 == false){_this.table.loading = true}
      _this.table1.loading = true
      if(SYSCONFIG.location == 'chong-qing'){
        if(window.sessionStorage.Hierarchy == 4){
          var Data = [
            '/ReportVillageSelfServiceData',//自助数据
            '/ReportVillageContactData',//留言数据
            '/ReportVillagePhoneData',//电话数据
            '/ReportVillageMediateData',//调解数据
            '/ReportVillageFlyzApplyData',//法律援助数据
          ]
        }else{
          var Data = [
            '/ReportSelfServiceData',//自助数据
            '/ReportContactData',//留言数据
            '/ReportPhoneData',//电话数据
            '/ReportMediateData',//调解数据
            '/ReportFlyzApplyData',//法律援助数据
            // '/ReportFileData',//档案数据
            // '/ReportEvaluateData',//订单评价数据
          ]
        }
      }else {
        if(window.sessionStorage.Hierarchy == 4){
          var Data = [
            '/CDReportVillageSelfServiceData',//自助数据
            '/CDReportVillageContactData',//留言数据
            '/CDReportVillagePhoneData',//电话数据
            '/CDReportVillageMediateData',//调解数据
            '/CDReportVillageFlyzApplyData',//法律援助数据
          ]
        }else{
          var Data = [
            '/CDReportSelfServiceData',//自助数据
            '/CDReportContactData',//留言数据
            '/CDReportPhoneData',//电话数据
            '/CDReportMediateData',//调解数据
            '/CDReportFlyzApplyData',//法律援助数据
            // '/ReportFileData',//档案数据
            // '/ReportEvaluateData',//订单评价数据
          ]
        }
      }

      _this.$parent.requestHub(Data)
      .then(res=>{
        if(this.modal1 == false){_this.table.loading = false}
        _this.table1.loading = false
        var data = tableDataSorting.dataMerge(res,{local:'adminId',remote:'AdminId'},[
          {columnName:'areaName',dataIndex:0,remoteVariable:['AreaShortName||AdminName']},
          {columnName:'newSelfConsult',dataIndex:0,remoteVariable:['Count','Intime']},
          {columnName:'totalSelfConsult',dataIndex:0,remoteVariable:['Count','Total']},
          {columnName:'newMsgConsult',dataIndex:1,remoteVariable:['Count','Intime']},
          {columnName:'totalMsgConsult',dataIndex:1,remoteVariable:['Count','Total']},
          {columnName:'newTelConsult',dataIndex:2,remoteVariable:['Count','Intime']},
          {columnName:'totalTelConsult',dataIndex:2,remoteVariable:['Count','Total']},
          // 无现场咨询数据
          // {columnName:'newSceneConsult',dataIndex:2,remoteVariable:['Count','Intime']},
          // {columnName:'totalSceneConsult',dataIndex:2,remoteVariable:['Count','Total']},
          //  和分区域总统计表重复
          // {columnName:'newConsultArch',dataIndex:5,remoteVariable:['Count','Intime']},
          // {columnName:'totalConsultArch',dataIndex:5,remoteVariable:['Count','Total']},
          // {columnName:'newConsultEval',dataIndex:6,remoteVariable:['Count','Intime']},
          // {columnName:'totalConsultEval',dataIndex:6,remoteVariable:['Count','Total']},
          {columnName:'newApplyAid',dataIndex:4,remoteVariable:['Count','Intime']},
          {columnName:'totalApplyAid',dataIndex:4,remoteVariable:['Count','Total']},
          {columnName:'newApplyMediate',dataIndex:3,remoteVariable:['Count','Intime']},
          {columnName:'totalApplyMediate',dataIndex:3,remoteVariable:['Count','Total']},
        ])
        if(_this.dateSelecter.AdminId == undefined || _this.dateSelecter.AdminId == ''){
          _this.table.data = data
        }else {
          _this.table1.data = data
        }
        console.log(_this.table1.data)
      })
      .catch(err=>{console.log("区域咨询申办统计：失败！")})
    },
  },
}
</script>
