import Vue from 'vue'
import App from './App.vue'
import router from './router/hub'
import store from './store'
import global from '../public/js/global'
import publicComponents from '@/components/public'
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.config.productionTip = false

Vue.use(Viewer)
Vue.use(global)
Vue.use(publicComponents)
Vue.use(ViewUI)
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    SYSCONFIG.defaultData.Token = window.sessionStorage.Token
    if(SYSCONFIG.location == 'chong-qing'){
      window.sessionStorage.url = 'https://cq.lvwa18.com:8001/'
    }else {
      window.sessionStorage.url = 'https://vn-server.lvwa18.com/'
    }
    if(window.sessionStorage.ParentId == '1505' || window.sessionStorage.AreaId == '1505'){
      to.meta.title = '律蛙数字法治-智慧司法平台'
    }
    if(window.sessionStorage.ParentId == '2205' || window.sessionStorage.AreaId == '2205'){
      to.meta.title = '智慧村居法律服务平台'
    }
    if(window.sessionStorage.ParentId == '2809' || window.sessionStorage.AreaId == '2809'){
      to.meta.title = '智慧司法服务平台'
      window.sessionStorage.sysName = '智慧司法服务平台'
    }
    console.log(SYSCONFIG.server.default.host)
    if(window.location.hostname == 'hhhtsft.lvwa18.com'){
      document.title = '呼和浩特司法通-后台管理端'
    }
    else if(to.meta.title == '律蛙数字法治-智慧司法平台'){
      document.title = window.sessionStorage.sysName || '律蛙数字法治-智慧司法平台'
    }else{
      document.title = to.meta.title
    }
    if(to.path == '/manager/index' || to.path == '/understandingList' || to.path == '/notaryList'){
      document.getElementsByTagName('html')[0].setAttribute('class','grey')
    }else {
      document.getElementsByTagName('html')[0].setAttribute('class','')
    }
  }
  next()
})
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'printReportShowQuery') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
