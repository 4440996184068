//路由模块加载器 Zima -- 2020-09-22
import Vue from 'vue'
import VueRouter from 'vue-router'
import old from './childRoute/old/old'
import mobile from './childRoute/mobile/mobile'
import manager from './childRoute/manager'

Vue.use(VueRouter)

var routes = new Array().concat(
  old.routes,//旧的路由
  mobile.routes,//手机端路由
  manager.routes,//管理端路由
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
