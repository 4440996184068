<template>
  <div class="bg">
    <div class="introduce-text">
      <div class="introduce-text-title">（一）成都仲裁委员会概况</div>
      <div class="introduce-text-content">成都仲裁委员会是成都市人民政府依据《中华人民共和国仲裁法》，于1997年组建的经济纠纷仲裁机构，其职责是仲裁平等主体的公民、法人和其他组织之间发生的合同纠纷和其他财产权益纠纷。为实现服务专业化，成都仲裁委先后增设了农村产权仲裁院和金融仲裁院。</br>
        成都仲裁委员会自成立以来，公正高效地解决了2万多件平等主体的公民、法人和其他组织之间发生的合同纠纷及其他财产权益纠纷，案件标的额近800亿元。案件涉及房地产交易、建设施工、股权转让、金融投资等多个领域，案件当事人涉及全国各省市和港澳台地区，以及美国、日本、韩国、老挝等国家，有效地维护了当事人的合法权益和市场经济秩序。为体现仲裁专业化服务方向，成都仲裁委员会先后设立了成都农村产权仲裁院、成都金融仲裁院和成都国际商事仲裁院、成都国际商事互联网仲裁院、成都体育商事仲裁院五个专业仲裁院。</br>
        成都仲裁委员会着力强化廉洁仲裁建设，确保办案质量与效率，加强仲裁宣传推行，推进机构规范发展，紧跟信息化建设步伐，不断开拓进取、勇攀高峰。</br>
        成都仲裁委将继续保持公正、廉洁、高效的办案作风，保护当事人的合法权益，为促进社会主义市场经济的健康发展提供有力的法律保障。</br>
      </div>
    </div>
    <div class="introduce-text" style="margin-top: 0.2rem">
      <div class="introduce-text-title">（二）办公场所</div>
      <div class="introduce-text-content">
        2012年，成都仲裁委员会迁入位于成都高新区天泰路111号特拉克斯国际广场北楼的新址办公。办公区面积2200平方米，设有7个仲裁庭，配备了办公自动化系统，规范了案件管理，提高了办案服务效率。</br>
        2021年2月，成都仲裁委员会入驻天府中央法务区，设立天府新区办公区，配备专业人员，开展咨询、立案、现场或视频开庭等全流程仲裁服务。</br>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(value,index) in carouselData" :key="index">
            <div style="text-align: center;color: #008fd6;">{{value.name}}</div>
            <img class="img" :src="value.url">
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="introduce-text" style="margin-top: 0.2rem">
      <div class="introduce-text-title">（三）联系方式</div>
      <div class="introduce-text-content">
        本部地址：四川省成都市高新区天泰路111号特拉克斯国际广场北楼15楼</br>
        咨询电话：（028）86715847（值守时间：工作日上午9:00-12:00，下午1:00-5:00）</br>
        投诉电话：（028）83382957（值守时间：工作日上午9:00-12:00，下午1:00-5:00）</br>
        廉洁仲裁监督电话：（028）61881895（值守时间：工作日上午9:00-12:00，下午1:00-5:00）
        传真：（028）86665117            邮编：610041</br>
        E-mail：cdzcwyh@cdac.org.cn</br>
        F-官网网址： www.cdac.org.cn</br>
        <div style="text-align: center">
          <img  src="https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/官方微信二维码.jpg">
        </div>
        天府新区办公区地址：天府中央法务区综合服务中心（天府新区博览城路万怡酒店旁天府国际商务中心A栋）</br>
        咨询电话：（028）81300883（值守时间：工作日上午
        9:00-12:00，下午1:00-5:00）</br>
        二、仲裁规则</br>
        <p class="connect" @click="download(pdf+'附件：成都仲裁委员会仲裁规则.pdf')">《成都仲裁委员会仲裁规则》</p>
        <p class="connect" @click="download(pdf+'附件：成都仲裁委员会金融仲裁程序特别规定.pdf')">《成都仲裁委员会金融仲裁程序特别规定》</p>
        三、申请仲裁</br>
        申请仲裁时，申请人应当向成都仲裁委员会提交仲裁申请书、仲裁协议、案件事实所依据的证据等材料并交纳仲裁费。</br>
        仲裁申请书应写明：</br>
        （一）申请人和被申请人的名称（姓名）、住所和联系方式等基本情况；</br>
        （二）具体明确的仲裁请求和所根据的事实理由。</br>
        仲裁申请书应由申请人签名或盖章。</br>
        仲裁申请书和证据材料的份数一般为被申请人、仲裁员、仲裁秘书三方人数的总和。
        <p class="connect"  @click="download(pdf+'附件：仲裁申请书样本.pdf')">《仲裁申请书（样本）》</p>

      </div>
    </div>
    <div class="introduce-text" style="margin-top: 0.2rem">
      <div class="introduce-text-title">四、仲裁员</div>
      <div class="introduce-text-content">
        成都仲裁委员会聘任了近600多名仲裁员，组建了一支由法律、金融、建筑、房地产、国际贸易与投资、科技、农村产权等方面的专家组成的仲裁员队伍。</br>
        为方便当事人选择，成都仲裁委员会对仲裁员按专业进行了分类管理，并编制了买卖租赁委托等一般合同、建设与房地产、金融、公司法务、知识产权、国际贸易与投资、计算机信息技术、农村产权八个不同专业的仲裁员名册。当事人可以从仲裁员名册中选择自己认可的仲裁员审理案件。

      </div>
    </div>
    <div class="introduce-text" style="margin-top: 0.2rem">
      <div class="introduce-text-title">五、收退费办法及快算</div>
      <div class="introduce-text-content">
        （一）收费办法</br>
        根据国务院办公厅印发的《仲裁委员会仲裁收费办法》（国办发〔1995〕44号）的规定，当事人申请仲裁，应当向仲裁委员会交纳仲裁案件受理费和处理费。</br>
        仲裁费用原则上由败诉的当事人承担；当事人部分胜诉、部分败诉的，由仲裁庭根据当事人各方责任大小确定其各自应当承担的仲裁费用的比例。当事人自行和解或者经仲裁庭调解结案的，当事人可以协商确定各自承担的仲裁费用的比例。</br>
        <p class="connect" @click="chargeFast()">（二）收费快算</p>
<!--        链接到我会官网-->
        （三）退费办法</br>
        根据《成都仲裁委员会仲裁费退费办法》（成仲发〔2013〕7号），仲裁费退费标准如下：</br>
        1.案件受理后，被申请人向仲裁委或人民法院提出仲裁协议效力或仲裁管辖异议，经仲裁委决定或人民法院裁定仲裁协议无效或仲裁委无管辖权的，案件受理费和处理费全部退回。</br>
        2.案件受理后，当事人撤回仲裁请求或反请求的，分下列情形退费或不退费：</br>
        （1）仲裁庭组成前，案件受理费全部退回，案件处理费退回50%；</br>
        （2）仲裁庭组成后开庭前，案件受理费和处理费各退回50%;</br>
        （3）仲裁庭开庭审理或书面审理后，案件受理费和处理费不予退回。</br>
        3.案件受理后，当事人变更仲裁请求或反请求，使仲裁费减少的，分下列情形退费或不退费：</br>
        （1）仲裁庭组成前，减少的案件受理费全部退费，减少的案件处理费退回50%；</br>
        （2）仲裁庭组成后开庭前，减少的案件受理费和处理费各退回50%；</br>
        （3）仲裁庭开庭审理或书面审理后，减少的案件受理费和处理费不予退回。</br>
        4.当事人经书面通知，无正当理由不到庭或者未经仲裁庭许可中途退庭，视为撤回仲裁请求或反请求的，案件受理费和处理费不予退回。

      </div>
    </div>
    <div class="introduce-text" style="margin-top: 0.2rem">
      <div class="introduce-text-title">六、仲裁案件流程</div>
      <div class="introduce-text-content">
        <p class="connect"  @click="download(pdf+'附件：成都仲裁委员会仲裁案件办理流程示意图.pdf')">《成都仲裁委员会仲裁案件办理流程示意图》</p>
      </div>
    </div>
    <div class="introduce-text" style="margin-top: 0.2rem">
      <div class="introduce-text-title">七、仲裁知识简介</div>
      <div class="introduce-text-content">
        （一）仲裁的概念</br>
        仲裁又称公断，是争议双方达成协议，自愿将争议交给中立的第三方裁决，双方有义务执行的一种解决争议的方式。我们这里所说的仲裁，仅指商事仲裁。</br>
        （二）仲裁的受案范围</br>
        成都仲裁委员会受理平等主体的自然人、法人和非法人组织之间发生的合同纠纷和其他财产权益纠纷，包括但不限于买卖合同、建设工程合同、承揽合同、运输合同、仓储保管合同、租赁合同、借款合同、保险合同、证劵合同、股权转让合同、知识产权合同等合同纠纷和其他财产权益纠纷。</br>
        成都仲裁委员会不仅受理国内商事纠纷，也受理国际商事纠纷。</br>
        成都仲裁委员会不受理下列纠纷：</br>
        1.劳动争议；</br>
        2.婚姻、收养、监护、扶养、继承纠纷；</br>
        3.依法应当由行政机关处理的行政争议；</br>
        4.农业集体经济组织内部的农业承包合同纠纷。</br>
        （三）仲裁协议</br>
        《中华人民共和国仲裁法》第四条规定：“当事人采用仲裁方式解决纠纷，应当双方自愿，达成仲裁协议。没有仲裁协议，一方申请仲裁的，仲裁委员会不予受理。”</br>
        仲裁协议包括合同中订立的仲裁条款，以及以其他方式在纠纷发生前或者纠纷发生后达成的请求仲裁的协议。</br>
        根据仲裁法的规定，完整的仲裁协议应包括以下内容：</br>
        1.请求仲裁的意思表示；</br>
        2.仲裁事项；</br>
        3.选定的仲裁委员会。</br>
        成都仲裁委员会推荐示范仲裁条款：</br>
        “凡因本合同产生的或与本合同有关的一切争议，均提交成都仲裁委员会按照该会仲裁规则进行仲裁。”</br>
        （四）仲裁的特点和优势</br>
        1.意思自治，自愿选择。自愿原则是仲裁的基本原则，该原则贯穿于整个仲裁程序，当事人可以选择是否仲裁；可以选择由哪个仲裁机构仲裁；可以选择仲裁员等。</br>
        2.高效快捷，灵活简便。仲裁实行“一裁终局”制度，裁决书一经作出即发生法律效力。在仲裁过程中，双方当事人还可以约定简化仲裁程序，缩短审理期限，有利于纠纷的迅速解决。</br>
        3.专家办案，权威性强。仲裁法对仲裁员的聘任条件设置了较高标准。由具有良好的道德素养和所属领域专业知识的专家来裁判商事争议，更能体现专业性、权威性。</br>
        4.不公开进行，替当事人保密。仲裁法规定，除当事人另有约定外，仲裁不公开进行，有利于保护双方当事人的商业秘密，维护当事人的商业信誉。</br>
        5.广泛的执行力，国际范围的承认和执行保障。我国于1987年1月22日加入联合国《承认及执行外国仲裁裁决公约》，我国仲裁机构所作出的裁决可以在加入该公约的160个国家和地区得到承认和执行。</br>
        （五）仲裁与诉讼的主要区别</br>
        1.管辖不同。仲裁机构受理案件是以合同中的仲裁条款或当事人双方自愿订立的书面仲裁协议为前提，仲裁不实行级别管辖、地域管辖，当事人享有选择仲裁机构的权利。法院对案件的管辖权由法律予以规定。</br>
        2.审级不同。仲裁实行一裁终局的制度，裁决一经作出即发生法律效力。诉讼实行两审终审制，当事人对一审判决不服时，可在法定期限内提起上诉，从而启动二审程序。</br>
        3.审理方式不同。仲裁以不公开审理为原则，当事人同意公开审理的除外。诉讼以公开审理为原则，只有涉及国家秘密、个人隐私或法律另有规定的除外。</br>
        4.纠纷裁判者的确定方式不同。仲裁的双方当事人均有权选定自己信赖的仲裁员，而诉讼中的当事人无权选定审判人员。</br>
        （六）仲裁的财产和证据保全</br>
        根据《中华人民共和国民事诉讼法》和《中华人民共和国仲裁法》的规定，当事人在申请仲裁前或仲裁过程中，可以申请财产保全和证据保全。</br>
        1.申请仲裁前的保全。当事人直接向被保全财产/证据所在地、被申请人住所地或者对案件有管辖权的人民法院提交申请，在人民法院采取保全措施后30日内申请仲裁。</br>
        2.仲裁过程中的保全。当事人申请仲裁后提出保全申请的，成都仲裁委员会根据当事人的保全申请向有管辖权的人民法院出具保全函，由人民法院采取保全措施。</br>
        （七）仲裁裁决的执行</br>
        仲裁裁决具有强制执行效力。仲裁裁决一经作出，与法院终审判决具有同等法律效力。当事人应按照裁决书规定的期限自动履行义务。如一方当事人不履行仲裁裁决，另一方当事人可向法院申请强制执行。申请执行的期限为两年。仲裁裁决的执行范围宽泛：</br>
        1.在中国内地的执行。如果被执行人的住所地或财产所在地在中国境内，无论涉外仲裁裁决还是国内仲裁裁决，均可向其住所地或财产所在地的中级人民法院申请强制执行。</br>
        2.在港澳台地区的执行。如果被执行人的住所地或财产所在地在港澳台地区，则分别按照《最高人民法院关于内地与香港特别行政区相互执行仲裁裁决的安排》、《最高人民法院关于内地与澳门特别行政区相互认可和执行仲裁裁决的安排》以及《台湾地区与大陆地区人民关系条例》等相关规定申请强制执行。</br>
        3.在其他国家和地区的执行。由于我国加入了联合国《承认及执行外国仲裁裁决公约》，我国仲裁机构所作出的裁决可以在加入该公约的160个国家和地区得到承认和执行。</br>

      </div>
    </div>
    <div class="introduce-text" style="margin-top: 0.2rem">
      <div class="introduce-text-title">八、法律法规</div>
      <div class="introduce-text-content">
        <p class="connect"  @click="download(pdf+'附件：中华人民共和国仲裁法.pdf')">（一）中华人民共和国仲裁法</p>
        <p class="connect"  @click="download(pdf+'附件：国务院办公厅关于贯彻实施《中华人民共和国仲裁法》需要明确的几个问题的通知.pdf')">（二）国务院办公厅关于贯彻实施《中华人民共和国仲裁法》需要明确的几个问题的通知国办发〔１９９６〕２２号</p>
        <p class="connect"  @click="download(pdf+'附件：最高人民法院关于适用《中华人民共和国仲裁法》若干问题的解释.pdf')">（三）最高人民法院关于适用《中华人民共和国仲裁法》若干问题的司法解释（法释〔2006〕7号）</p>
        <p class="connect"  @click="download(pdf+'附件：最高人民法院关于审理仲裁司法审查案件若干问题的规定.pdf')">（四）最高人民法院关于审理仲裁司法审查案件若干问题的规定（法释〔2017〕22号）</p>
        <p class="connect"  @click="download(pdf+'最高人民法院关于人民法院办理仲裁裁决执行案件若干问题的规定1.pdf')">（五）最高人民法院关于人民法院办理仲裁裁决执行案件若干问题的规定（法释〔2018〕5号）</p>
        <p class="connect"  @click="download(pdf+'仲裁知识问答.pdf')">（六）仲裁知识问答</p>

      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
name: "arbitration",
  data() {
    return {
      pdf : 'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/pdf/',
      carouselData : [
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/前台.png',name:'前台'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/办公区.jpg',name:'办公区'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/天府新区办公区.png',name:'天府新区办公区 (1）'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/天府新区办公区 (2).png',name:'天府新区办公区 (2）'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/会议室.jpg',name:'会议室 (1）'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/会议室 (2).jpg',name:'会议室 (2)'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/会议室 (3).jpg',name:'会议室 (3)'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/会议室 (4).jpg',name:'会议室 (4)'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/仲裁庭.jpg',name:'仲裁庭 (1）'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/仲裁庭 (2).jpg',name:'仲裁庭 (2)'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/仲裁庭 (2).png',name:'仲裁庭 (3)'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/仲裁庭 (3).jpg',name:'仲裁庭 (4)'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/仲裁庭.png',name:'仲裁庭'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/仲裁员休息室.png',name:'仲裁员休息室 (1）'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/仲裁员休息室 (2).png',name:'仲裁员休息室 (2）'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/仲裁员休息室 (3).png',name:'仲裁员休息室 (3）'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/12楼庭前等候区.png',name:'12楼庭前等候区'},
        {url:'https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/15楼接待厅.png',name:'15楼接待厅'},
      ]
    };
  },
  mounted() {
  },
  methods: {
    download : function (url) {
      var ua = navigator.userAgent.toLowerCase();
      if(ua.match(/MicroMessenger/i)=="micromessenger") {
        //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv((res)=>{
          if (res.miniprogram) {
            wx.miniProgram.navigateTo({
              url: `/pages/userSide/download/download?url=`+`${url}`+'&type='+1+''
            })
          } else {
            window.open(url)
          }
        })
      }else{
        window.open(url)
      }
    },
    jumpUrl : function (url){
      window.open(url)
    },
    chargeFast : function (){
      this.$router.push({name:'chargeFast'})
    },
  },
  computed:{
  },
}
</script>

<style scoped>
.bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
.img{width: 6.8rem}
.introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
.introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
.introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
.connect{color: #008fd6;padding: 0.1rem;text-decoration:underline;}
</style>