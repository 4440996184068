<template>
  <div style="padding:20px;">
    <Tabs :value="active" @on-click="activeClick">
      <TabPane label="人员管理" name="1"></TabPane>
      <TabPane label="线上申请管理" name="2"></TabPane>
    </Tabs>
    <div style="background-color: white;" v-if="active == 1">
      <div style="font-size:16px;text-align: left;border-left:solid #de4f0b 3px;padding-left:5px">
        人员管理
        <div style="display: inline-block;vertical-align:middle;margin-left:10px">
          <span style="margin-left: 30px">共{{startingNumber}}人</span>
          <span style="margin-left: 30px">已绑定 {{ Bound }} 人</span>
          <span style="margin-left: 30px">未绑定 {{ UnBound }} 人</span>
          <span style="margin-left: 30px">禁用 {{ count - startingNumber }} 人</span>
        </div>
      </div>
      <!--      <div style="margin: 15px 0">-->
      <!--        <DatePicker :value="timeData" v-model="timeData" type="daterange" split-panels placeholder="请选择时间"-->
      <!--                    style="width: 200px" @on-change="totalTime3"></DatePicker>-->

      <!--        <Select style="width:200px" v-model="model140" filterable remote clearable :remote-method="remoteMethod2"-->
      <!--                @on-change="lawyerClick3" :loading="loading2" placeholder="请输入社区">-->
      <!--          <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">-->
      <!--            {{value.VillagesName}}</Option>-->
      <!--        </Select>-->
      <!--        <div style="width: 300px;display: inline-block;vertical-align: top;">-->
      <!--          <Input v-model="userKey" search enter-button placeholder="搜索姓名" @on-search="userSearch" />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="publicWelfare">
        <Row :gutter="16">
          <Col span="3">
            <div>区域范围：</div>
            <Select v-model="filter.areaRange.value" placeholder="请选择区域" clearable>
              <Option value="0" :label="information.Level==1?'全市':'全部'">{{ information.Level==1?'全市':'全部' }}</Option>
              <Option v-for="(item, index) in filter.areaRange.list" :value="item.AdminId" :label="item.AdminName">
                {{ item.AdminName }}</Option>
            </Select>
          </Col>
          <Col span="3">
            <div>性别：</div>
            <Select v-model="filter.sex.value" placeholder="请选择性别" clearable>
              <Option v-for="(item, index) in filter.sex.data" :value="item.id" :label="item.text">
                {{ item.text }}</Option>
            </Select>
          </Col>
          <Col span="3">
            <div>年龄：</div>
            <Select v-model="filter.age.value" placeholder="请选择年龄" clearable>
              <Option v-for="(item, index) in filter.age.data" :value="item.id" :label="item.type_content">
                {{ item.type_content }}</Option>
            </Select>
          </Col>
          <Col span="3">
            <div>学历：</div>
            <Select v-model="filter.education.value" placeholder="请选择学历" clearable>
              <Option v-for="(item, index) in filter.education.data" :value="item.id" :label="item.type_content">
                {{ item.type_content }}</Option>
            </Select>
          </Col>
          <Col span="3">
            <div>政治面貌：</div>
            <Select v-model="filter.political.value" placeholder="请选择政治面貌" clearable>
              <Option v-for="(item, index) in filter.political.data" :value="item.id" :label="item.type_content">
                {{ item.type_content }}</Option>
            </Select>
          </Col>
          <Col span="3">
            <div>工作单位及职务：</div>
            <Select v-model="filter.InsiderPosition.value" placeholder="请选择工作单位及职务" clearable>
              <Option v-for="(item, index) in filter.InsiderPosition.data" :value="item.short_name" :label="item.type_content">
                {{ item.type_content }}</Option>
            </Select>
          </Col>
          <Col span="3">
            <div>绑定状态：</div>
            <Select v-model="filter.bing.value" placeholder="请选择绑定状态" clearable>
              <Option v-for="(item, index) in filter.bing.data" :value="item.id" :label="item.text">
                {{ item.text }}</Option>
            </Select>
          </Col>
          <Col span="3">
            <div>账户状态：</div>
            <Select v-model="filter.disable.value" placeholder="请选择绑定状态" clearable>
              <Option v-for="(item, index) in filter.disable.data" :value="item.id" :label="item.text">
                {{ item.text }}</Option>
            </Select>
          </Col>
        </Row>
        <Row  style="margin: 20px 0">
          <Col span="12">
            <Input v-model="filter.InsiderName" search enter-button placeholder="搜索明白人" @on-search="aQuestionnaireList" />
          </Col>
          <!--<Col span="12" style="text-align: center">-->
          <!--<Button type="primary" @click="sendOutinformation()">一键发送</Button>-->
          <!--</Col>-->
          <Col span="12">
            <Button type="primary"  @click="cqCommunity();modal = true" style="margin-left: 30px">添加</Button>
            <Button type="primary"  @click="batchReview()" style="margin-left: 30px">批量导出</Button>
          </Col>
        </Row>

        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>姓名</th>
            <th>性别</th>
            <th>年龄</th>
            <th>电话</th>
            <th>村（社区）</th>
            <th>学历</th>
            <th>政治面貌</th>
            <th>工作单位及职务</th>
            <!--            <th>服务数据</th>-->
<!--            <th>小程序码</th>-->
              <!--<Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox>-->

<!--            <th>是否绑定</th>-->
            <th>服务起始</th>
            <th>服务终止</th>
            <th>是否绑定</th>
            <th>操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in adminLawyerList">
            <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
            <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
            <td nowrap="nowrap">
              <Avatar :src="value.InsiderImg||'https://ai-cdn.lvwa18.com/img/rusticate/text3.jpg'" /> {{value.InsiderName}}</td>
            <td>{{value.InsiderSex==1?'女':'男'}}</td>
            <td>{{value.InsiderAge||'--'}}岁</td>
            <td>{{value.InsiderPhone}}</td>
            <td nowrap="nowrap">
              <div v-for="(item,i) in value.insider_relation">{{ item.address }}</div>
            </td>
            <td><div v-for="(item,index) in filter.education.data" v-if="value.InsiderEducation == item.id">{{item.type_content}}</div></td>
            <td><div v-for="(item,index) in filter.political.data" v-if="value.InsiderPolitics == item.id">{{item.type_content}}</div></td>
            <td>{{value.InsiderPosition||'--'}}</td>
            <!--            <td><Button style="background-color:  rgb(243, 133, 4);color: #fff"-->
            <!--                        @click="serviceClick(value.InsiderId);modal19 = true">详情</Button></td>-->
<!--            <td style="width: 190px;">-->
<!--              <img class="smallProgram"-->
<!--                   @click="alertbox(value.LawyerQRcode,value.InsiderName,value.InsiderPhone,value.InsiderId,value.OrganizationId)"-->
<!--                   src="/img/smallProgram1.png"></td>-->
<!--            <td v-if="value.isBinding == 1" style="color: red">已绑定</td>-->
<!--            <td v-else>未绑定</td>-->
            <td>{{ value.ServiceStartTime||'--' }}</td>
            <td>{{ value.ServiceEndTime||'--' }}</td>
            <td :style="{'color':value.InsiderOpenId?'red':''}">{{value.InsiderOpenId?'已绑定':'未绑定'}}</td>
            <td style="text-align: center" nowrap="nowrap">
              <Button type="primary" @click="replaceLawyer(value)">修改</Button>

              <div style="margin-left: 20px;display: inline-block">
                <Button
                    v-if="value.IsUse == 0"
                    type="error"
                    @click="ClockSet(value.InsiderId, 1)"
                >解绑</Button>
                <Button
                    v-if="value.IsUse == 1"
                    type="success"
                    @click="ClockSet(value.InsiderId, 0)"
                >启用</Button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <Page :total="count" :page-size="pageSize" show-elevator show-total
              @on-change="page = $event;aQuestionnaireList()" />
      </div>

    </div>
    <div v-if="active == 2">
      <Row  style="margin: 20px 0">
        <Col span="12">
          <Input v-model="applyFor.key" search enter-button placeholder="搜索申请人姓名/电话" @on-search="applyList" />
        </Col>
      </Row>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>申请人姓名</th>
            <th>性别</th>
            <th>出生年月</th>
            <th>年龄</th>
            <th>政治面貌</th>
            <th>学历</th>
            <th>村（社区）</th>
            <th>工作单位及职务</th>
            <th>移动电话</th>
            <th>申请提交时间</th>
<!--            <th>审核</th>-->
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in applyFor.data">
            <td v-if="(applyFor.page - 1) * applyFor.pageSize + index + 1 < 10">0{{ (applyFor.page - 1) * applyFor.pageSize + index + 1 }}</td>
            <td v-else>{{ (applyFor.page - 1) * applyFor.pageSize + index + 1 }}</td>
            <td><Avatar :src="value.InsiderImg" /> {{value.InsiderName}}</td>
            <td>{{value.InsiderSex==1?'女':'男'}}</td>
            <td>{{value.InsiderBirthday}}</td>
            <td>{{value.InsiderAge||'--'}}岁</td>
            <td>{{value.politics.type_content||''}}</td>
            <td>{{value.education.type_content||''}}</td>
            <td>
              {{ value.village.CityName }}{{ value.village.VillagesName }}
            </td>
            <td>{{value.InsiderPosition||'--'}}</td>

            <td>{{value.InsiderPhone}}</td>
            <td>{{value.created_at}}</td>
<!--            <td>-->
<!--              <div style="margin-left: 20px;display: inline-block" v-if="!value.is_pass">-->
<!--                <Button type="error" @click="applyExamine.id=value.id;applyExamine.replyShow = true;applyExamine.isPass=2" style="margin-right: 20px">驳回</Button>-->
<!--                <Button type="primary" @click="applyExamine.id=value.id;applyExamine.replyShow = true;applyExamine.isPass=1">通过</Button>-->
<!--              </div>-->
<!--              <div v-else>-->
<!--                  {{value.is_pass==1?'已通过':'已驳回'}}-->
<!--              </div>-->
<!--            </td>-->
            <!--            <td><Button style="background-color:  rgb(243, 133, 4);color: #fff"-->
            <!--                        @click="serviceClick(value.InsiderId);modal19 = true">详情</Button></td>-->
            <!--            <td style="width: 190px;">-->
            <!--              <img class="smallProgram"-->
            <!--                   @click="alertbox(value.LawyerQRcode,value.InsiderName,value.InsiderPhone,value.InsiderId,value.OrganizationId)"-->
            <!--                   src="/img/smallProgram1.png"></td>-->
            <!--            <td v-if="value.isBinding == 1" style="color: red">已绑定</td>-->
            <!--            <td v-else>未绑定</td>-->

          </tr>
          </tbody>
        </table>
        <Page :total="applyFor.total" :page-size="applyFor.pageSize" show-elevator show-total
              @on-change="applyFor.page = $event;applyList()" />
      </div>
    </div>
    <Modal :draggable="true" v-model="modal6" :title="lawName+'的太阳码'" @on-cancel="" ok-text="确定"
           cancel-text="关闭" width="350">
      <div style="text-align: center">
<!--        <p style="font-size: 16px">是否发送明白人小程序码至手机</p>-->
        <!--<img v-if="modal6" :src="urlImg" style="width: 300px;height: 300px">-->
        <div style="width: 300px;height: 300px">
          <img v-if="modal6 && lawImg" :src="lawImg" style="width: 300px;height: 300px">
        </div>
      </div>
    </Modal>
    <!--    添加明白人-->
    <Modal
        v-model="modal"
        title="添加">
      <div slot="footer">
        <Button type="text" @click="cancel();modal=false">取消</Button>
        <Button type="primary" @click="increase">确定</Button>
      </div>
      <Form :label-width="80">
        <FormItem label="姓名" required>
          <Input v-model="Addmodifications.InsiderName" placeholder="姓名" style="" />
        </FormItem>
        <FormItem label="性别" required>
          <Select v-model="Addmodifications.InsiderSex" placeholder="请选择性别" clearable>
            <Option v-for="(item, index) in filter.sex.data" :value="item.id" :label="item.text">
              {{ item.text }}</Option>
          </Select>
        </FormItem>
        <FormItem label="电话" required>
          <Input v-model="Addmodifications.InsiderPhone" placeholder="电话" style="" />
        </FormItem>
        <FormItem label="年龄" >
          <Input v-model="Addmodifications.InsiderAge" placeholder="年龄" style="" />
        </FormItem>
        <FormItem label="学历" >
          <Select v-model="Addmodifications.InsiderEducation" placeholder="请选择学历" clearable>
            <Option v-for="(item, index) in filter.education.data" :value="item.id" :label="item.type_content">
              {{ item.type_content }}</Option>
          </Select>
        </FormItem>
        <FormItem label="政治面貌" >
          <Select v-model="Addmodifications.InsiderPolitics" placeholder="请选择政治面貌" clearable>
            <Option v-for="(item, index) in filter.political.data" :value="item.id" :label="item.type_content">
              {{ item.type_content }}</Option>
          </Select>
        </FormItem>
        <FormItem label="工作单位及职务" >
          <Input v-model="Addmodifications.InsiderPosition" placeholder="工作单位及职务" style="" />
        </FormItem>
        <FormItem label="区域" required  v-if="adminLevel != 3">
          <Cascader :data="serachData" v-model="serachValue" placeholder="区域" @on-change="serachSelect"  ></Cascader>
        </FormItem>
        <FormItem label="村社区" required v-if="VillageByData != ''">
          <Select  v-model="VillagesId" filterable clearable multiple @on-query-change="villageByQuery" @on-open-change="villageByOpen" class="choiceLawyer" placeholder="请选择村（社区），可多选">
            <Option v-for="item in VillageByData" :value="item.VillagesId" :key="item.VillagesId">{{item.VillagesName}}</Option>
            <Page
                :total="villageByCount" show-total
                :page-size="pageSize"
                :current="villageByPage"
                @on-change="serachSelectPage"
            ></Page>
          </Select>
        </FormItem>
        <FormItem label="服务起始">
          <DatePicker
              :value="ServiceStartTime"
              v-model="ServiceStartTime"
              type="date"
              split-panels
              placeholder="服务起始时间"
              style="width: 200px"
              @on-change="totalTime1"
          ></DatePicker>
        </FormItem>
        <FormItem label="服务终止">
          <DatePicker
              :value="ServiceEndTime"
              v-model="ServiceEndTime"
              type="date"
              split-panels
              placeholder="服务终止时间"
              style="width: 200px"
              @on-change="totalTime2"
          ></DatePicker>
        </FormItem>
      </Form>
      <!--      <Select  style="margin-bottom: 30px" v-model="OrganizationId" filterable clearable @on-query-change="lawFirmsearch" @on-open-change="lawFirmdropDown" class="choiceLawyer" placeholder="服务机构">-->
      <!--        <Option v-for="item in FirmPageData" :value="item.OrganizationId" :key="item.OrganizationId">{{item.AdminName}}</Option>-->
      <!--        <Page-->
      <!--            :total="FirmPageCount" show-total-->
      <!--            :page-size="pageSize"-->
      <!--            :current="lawFirmPage"-->
      <!--            @on-change="lawFirmTurnPages"-->
      <!--        ></Page>-->
      <!--      </Select>-->
    </Modal>
    <Modal
        :draggable="true"
        v-model="Addmodifications.replaceShow"
        title="修改明白人信息"
        @on-ok="modifyInsider"
        @on-cancel="cancel"
        width="530"
    >
      <Form :label-width="80">
        <FormItem label="姓名" required>
          <Input v-model="Addmodifications.InsiderName" placeholder="姓名" style="" />
        </FormItem>
        <FormItem label="性别" required>
          <Select v-model="Addmodifications.InsiderSex" placeholder="请选择性别" clearable>
            <Option v-for="(item, index) in filter.sex.data" :value="item.id" :label="item.text">
              {{ item.text }}</Option>
          </Select>
        </FormItem>
        <FormItem label="电话" required>
          <Input v-model="Addmodifications.InsiderPhone" placeholder="电话" style="" />
        </FormItem>
        <FormItem label="年龄" >
          <Input v-model="Addmodifications.InsiderAge" placeholder="年龄" style="" />
        </FormItem>
        <FormItem label="学历" >
          <Select v-model="Addmodifications.InsiderEducation" placeholder="请选择学历" clearable>
            <Option v-for="(item, index) in filter.education.data" :value="item.id" :label="item.type_content">
              {{ item.type_content }}</Option>
          </Select>
        </FormItem>
        <FormItem label="政治面貌" >
          <Select v-model="Addmodifications.InsiderPolitics" placeholder="请选择政治面貌" clearable>
            <Option v-for="(item, index) in filter.political.data" :value="item.id" :label="item.type_content">
              {{ item.type_content }}</Option>
          </Select>
        </FormItem>
        <FormItem label="工作单位及职务" >
          <Input v-model="Addmodifications.InsiderPosition" placeholder="工作单位及职务" style="" />
        </FormItem>
        <FormItem label="服务起始">
          <DatePicker
              :value="Addmodifications.ServiceStartTime"
              v-model="Addmodifications.ServiceStartTime"
              type="date"
              split-panels
              placeholder="服务起始时间"
              style="width: 200px"
              @on-change="totalTime1"
          ></DatePicker>
        </FormItem>
        <FormItem label="服务终止">
          <DatePicker
              :value="Addmodifications.ServiceEndTime"
              v-model="Addmodifications.ServiceEndTime"
              type="date"
              split-panels
              placeholder="服务终止时间"
              style="width: 200px"
              @on-change="totalTime2"
          ></DatePicker>
        </FormItem>
      </Form>
    </Modal>
<!--    检查明白人是否存在-->
    <Modal
        v-model="checkInsiderData.show"
        title="温馨提示：当前明白人已存在"
        >
      <div slot="footer">
        <Button type="text" @click="checkInsiderData.show=false">取消</Button>
<!--        <Button type="primary" @click="emptyInsider();checkInsiderData.show=false" v-if="checkInsiderData.openIdShow">继续</Button>-->
      </div>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>姓名</th>
            <th>电话</th>
            <th>村（社区）</th>
            <th>是否绑定</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in checkInsiderData.data">
            <td>
              <Avatar :src="value.InsiderImg||'https://ai-cdn.lvwa18.com/img/rusticate/text3.jpg'" /> {{value.InsiderName}}</td>
            <td>{{value.InsiderPhone}}</td>
            <td>
              <div v-for="(item,i) in value.relation_village">{{ item.villages.CityName }}{{item.villages.admin[0].AdminName}}{{ item.villages.VillagesName }}</div>
            </td>
            <td :style="{'color':value.InsideropenId?'red':''}">{{value.InsideropenId?'已绑定':'未绑定'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </Modal>
    <!--回复-->
    <Modal v-model="applyExamine.replyShow" title="留言" @on-ok="submit" ok-text="发送" style="height: 300px">
      <Input v-model="applyExamine.content" maxlength="300" show-word-limit type="textarea" placeholder="留言内容" />
    </Modal>
  </div>
</template>

<script>
export default {
  name: "userAdministration",
  data() {
    return {
      adminLawyerList:[],
      adminUserData:[],
      information:[],
      VillagesId : '',
      page : 1,
      pageSize : 15,
      startTime : '',
      endTime : '',
      timeData: '',
      model140: false,
      loading2: false,
      modal6: false,
      options2: [],
      userKey : '',
      urlImg: '',
      lawImg: '',
      count: 0, // 包含了禁用总数
      startingNumber: 0, // 启用总数
      lawName: '',
      pageUrl : 'chong-qing/login/login',
      searchLaw : '',
      modal : false,
      InsiderName : '',
      InsiderPhone : '',
      adminLevel : '',
      FirmPageData : [],
      FirmPageCount : 0,
      lawFirmPage : 1,
      serachData : [],
      VillageByData : [],
      serachValue : [],
      villageByCount : 0,
      villageByPage : 1,
      ServiceStartTime: '',
      ServiceEndTime: '',
      ServiceStartTimeData : '',
      ServiceEndTimeData : '',
      replaceData : '',
      active : '1',
      filter:{
        education : {value:'',data:[]},
        InsiderPosition : {value:'',data:[]},
        sex : {value:'',data:[{text:'男',id:2},{text:'女',id:1},]},
        age : {value:'',data:[]},
        bing : {value:'',data:[{text:'已绑定',id:2},{text:'未绑定',id:1}]},
        disable : {value:'',data:[{text:'已启用',id:0},{text:'已禁用',id:1}]},
        political : {value:'',data:[]},
        areaRange:{value:'0',list:[],},//区域范围
        InsiderName : ''
      },
      applyFor : {
        data : [],
        total : 0,
        page : 1,
        pageSize : 15,
        key : '',
      },
      Addmodifications : {
        replaceShow: false,
        InsiderId : '',
        InsiderName : '',
        InsiderSex : '',
        InsiderPhone : '',
        InsiderAge : '',
        InsiderEducation : '',
        InsiderPolitics : '',
        InsiderPosition : '',

        // 时间
        ServiceStartTime: '',
        ServiceEndTime: '',
        ServiceStartTimeData : '',
        ServiceEndTimeData : '',
      },
      UnBound : 0,
      Bound : 0,
      checkInsiderData : {
        data : [],
        openIdShow : false,
        show : false,
      },
      applyExamine : {
        replyShow : false,
        content : '',
        id : '',
        isPass : '',
      },
    }
  },
  watch: {
    'filter.education.value': function (val) {this.aQuestionnaireList()},
    'filter.InsiderPosition.value': function (val) {this.aQuestionnaireList()},
    'filter.sex.value': function (val) {this.aQuestionnaireList()},
    'filter.age.value': function (val) {this.aQuestionnaireList()},
    'filter.bing.value': function (val) {this.aQuestionnaireList()},
    'filter.disable.value': function (val) {this.aQuestionnaireList()},
    'filter.political.value': function (val) {this.aQuestionnaireList()},
    'filter.areaRange.value': function (val) {this.aQuestionnaireList()},
  },
  methods:{
    submit : function (){
        var _this = this
        console.log(_this.applyExamine)
      _this.send({
        url: '/checkApplyById',
        data: {
          AdminId: _this.information.AdminId,
          id : _this.applyExamine.id,
          isPass : _this.applyExamine.isPass,
          msg : _this.applyExamine.content,
        },
        success: function(res) {
          _this.applyExamine = {
            replyShow : false,
            content : '',
            id : '',
            isPass : '',
          }
          _this.applyList()
        }
      })
    },
    boundInsder : function (){
      var _this = this
      _this.send({
        url: '/BoundInsder',
        data: {
          AdminId: _this.information.AdminId,
        },
        success: function(res) {
          _this.UnBound = res.UnBound
          _this.Bound = res.Bound
        }
      })
    },
    //获取区域列表
    getAdminList:function(){
      let _this = this
      _this.send({
        url: '/FindBottomVillagesAndAdmin_',
        data:{
          AdminId: window.sessionStorage.AdminId,
        },
        success: function (res) {
          _this.filter.areaRange.list = []
          _this.filter.areaRange.list = res.Admin
        }
      })
    },
    // 批量导出
    batchReview : function (){
      var _this = this
      _this.send({
        url: '/insiderExport',
        loading: true,
        data: {
          AdminFind: _this.filter.areaRange.value,
          AdminId: _this.information.AdminId,
          InsiderName: _this.filter.InsiderName,
          age : _this.filter.age.value,
          sex : _this.filter.sex.value,
          bing : _this.filter.bing.value,
          IsUse : _this.filter.disable.value,
          education : _this.filter.education.value,
          InsiderPosition : _this.filter.InsiderPosition.value,
          political : _this.filter.political.value,
        },
        success: function (res) {
          window.open(window.sessionStorage.url + res);
        }
      })
    },
    filterContent : function (){
      var _this = this
      // 政治面貌
      _this.send({
        url: '/TypeDictionaryContentList',
        data: {id:1},
        success: function (res) {
          _this.filter.political.data = res
        }
      })
      // 年龄区间
      _this.send({
        url: '/TypeDictionaryContentList',
        data: {id:2},
        success: function (res) {
          _this.filter.age.data = res
        }
      })
      // 学历
      _this.send({
        url: '/TypeDictionaryContentList',
        data: {id:3},
        success: function (res) {
            _this.filter.education.data = res
        }
      })
      // 工作单位及职务
      _this.send({
        url: '/TypeDictionaryContentList',
        data: {id:4},
        success: function (res) {
            _this.filter.InsiderPosition.data = res
        }
      })
    },
    activeClick : function (e){this.active = e
      if(e == 2){
        this.applyList()
      }
    },
    applyList : function (){
      var _this = this
      _this.send({
        url: '/ApplyList',
        data: {
          AdminId: window.sessionStorage.AdminId,
          page: _this.applyFor.page,
          pageSize: _this.applyFor.pageSize,
          key: _this.applyFor.key,
        },
        success: function (res) {
          console.log(_this.applyFor.data)
          _this.applyFor.data = res.data
          _this.applyFor.total = res.total
        }
      })
    },
    ClockSet: function (InsiderId, IsUse) {
      var _this = this
      _this.send({
        url: '/EditInsiderIsuse',
        confirm: true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          InsiderId: InsiderId,
          IsUse: IsUse
        },
        success: function (res) {
          _this.$Notice.success({
            title: '系统通知',
            desc: '该数据已更新！'
          })
          _this.aQuestionnaireList()
        }
      })
    },
    totalTime1: function (e) {
      this.Addmodifications.ServiceStartTimeData = e
    },
    totalTime2: function (e) {
      this.Addmodifications.ServiceEndTimeData = e
    },
    modifyInsider : function (){
      var _this = this
      _this.send({
        url: '/EdiInsiderInfo',
        data: {
          AdminId: _this.information.AdminId,
          InsiderId: _this.Addmodifications.InsiderId,
          InsiderName: _this.Addmodifications.InsiderName,
          InsiderSex: _this.Addmodifications.InsiderSex,
          InsiderPhone: _this.Addmodifications.InsiderPhone,
          InsiderAge: _this.Addmodifications.InsiderAge,
          InsiderEducation: _this.Addmodifications.InsiderEducation,
          InsiderPolitics: _this.Addmodifications.InsiderPolitics,
          ServiceStartTime: _this.Addmodifications.ServiceStartTimeData,
          ServiceEndTime: _this.Addmodifications.ServiceEndTimeData,
          InsiderPosition: _this.Addmodifications.InsiderPosition,
        },
        success: function (res) {
          _this.$Message.success('操作成功')
          _this.cancel()
          _this.aQuestionnaireList()
        }
      })
    },
    aQuestionnaireList:function (){
      var _this = this
      _this.send({
        url: '/insiderList',
        loading: true,
        data: {
          AdminFind: _this.filter.areaRange.value,
          AdminId: _this.information.AdminId,
          page: _this.page,
          pageSize: _this.pageSize,
          InsiderName: _this.filter.InsiderName,
          age : _this.filter.age.value,
          sex : _this.filter.sex.value,
          bing : _this.filter.bing.value,
          IsUse : _this.filter.disable.value,
          education : _this.filter.education.value,
          InsiderPosition : _this.filter.InsiderPosition.value,
          political : _this.filter.political.value,
        },
        success: function (res) {
          _this.adminLawyerList = res.data.data
          _this.count = res.data.total
          _this.startingNumber = res.count
        }
      })
    },
    alertbox: function (url, name, InsiderPhone, InsiderId, OrganizationId) {
      var _this = this
      var _this = this
      var op = 0 + 'p' + InsiderPhone + 'p' + 4 + 'p' + InsiderId
      _this.send({
        url: '/AgetInsiderQRcode',
        data: {
          // OrganizationId: OrganizationId,
          scene: 0 + 'p' + InsiderPhone + 'p' + 4 + 'p' + InsiderId,
          userType: '4',
          page: _this.pageUrl,
          width: '430',
          jpg: 'jpg',
          InsiderId: InsiderId
        },
        success: function (res) {
          _this.lawImg = 'https://cq.lvwa18.com:8001/' + res
        }
      })
      console.log(op)
      _this.modal6 = true
      // _this.urlImg = url
      _this.lawName = name
    },
    alertbox1: function (url, name, lawyerId1, lInsiderPhone, OrganizationId) {
      var _this = this
      var op = OrganizationId + 'p' + lInsiderPhone + 'p' + 1 + 'p' + lawyerId1 + ''
      if(SYSCONFIG.location == 'chong-qing'){
        var url1 = '/getLawyerSunCode'
      }else {
        var url1 = '/CD_getLawyerSunCode'
      }

      _this.send({
        url: url1,
        consulting1:true,
        responseType:"arraybuffer",
        data: {
          OrganizationId: OrganizationId,
          scene: op,
          userType: '2',
          page: _this.pageUrl,
          width: '430',
          jpg: 'jpg',
          InsiderId: lawyerId1
        },
        success: function (res) {
          _this.lawImg = 'data:image/png;base64,' + window.btoa(String.fromCharCode(...new Uint8Array(res)));
        }
      })
      console.log(op)
      _this.modal6 = true
      // _this.urlImg = url
      _this.lawName = name
      _this.lawyerId1 = lawyerId1
      _this.lInsiderPhone = lInsiderPhone
    },

    serachSelectPage : function (value){
      var _this = this
      _this.villageByPage = value
      _this.VillageByAdmin()
    },
    serachSelect : function (value, selectedData){
      var _this = this
      console.log(value, selectedData)
      if(value != ''){
        _this.serachAdminId = value[value.length-1]
        _this.VillageByAdmin()
      }else {
        _this.VillageByData = []
      }
    },

    VillageByAdmin : function (){
      var _this = this
      _this.send({
        url: '/VillageByAdmin',
        data: {
          AdminId: _this.serachAdminId,
          key : _this.serachkey,
          page : _this.villageByPage,pageSize : _this.pageSize,
        },
        success: function(res) {
          _this.VillageByData = res.list
          _this.villageByCount = res.count
        }
      })
    },
    CqAdminSerach : function (){
      var _this = this
      var data = []
      if (SYSCONFIG.location == 'chong-qing') {
        var url = '/CqAdminSerach'
      }else {
        var url = '/AdminTreeList'
      }
      _this.send({
        url: url,
        data: {
          AdminId: window.sessionStorage.AdminId,
          Level: window.sessionStorage.adminLevel,
          page : 1,
          pageSize : '999',
        },
        success: function(res) {
          for(var i in res.list){
            data.push(res.list[i])
          }
          convertTree(data)
          function convertTree(tree) {
            const result = []
            tree.forEach((item) => {
              // 解构赋值
              let {
                AdminId: value,
                AdminName: label,
                Level : Level,
                Pid : Pid,
                list: children
              } = item
              if (children) {
                children = convertTree(children)
              }
              result.push({
                value,
                label,
                Level,
                Pid,
                children
              })
            })
            _this.serachData = result
            return result
          }
        }
      })
    },
    lawFirmdropDown : function (e){
      if(e){
        this.searchFirm = ''
        this.OrganizationId = ''
        this.serachOrganization()
      }
      console.log(this.OrganizationId)
    },
    replaceLawyer : function (e){
      this.Addmodifications.replaceShow = true
      this.Addmodifications.InsiderId = e.InsiderId
      this.Addmodifications.InsiderName = e.InsiderName
      this.Addmodifications.InsiderSex = e.InsiderSex
      this.Addmodifications.InsiderPhone = e.InsiderPhone
      this.Addmodifications.OrganizationId = e.OrganizationId
      this.Addmodifications.InsiderAge = e.InsiderAge
      this.Addmodifications.InsiderEducation = e.InsiderEducation
      this.Addmodifications.InsiderPolitics = e.InsiderPolitics

      this.Addmodifications.ServiceEndTime = e.ServiceEndTime
      this.Addmodifications.ServiceEndTimeData = e.ServiceEndTime
      this.Addmodifications.ServiceStartTime = e.ServiceStartTime
      this.Addmodifications.ServiceStartTimeData = e.ServiceStartTime
      this.Addmodifications.InsiderPosition = e.InsiderPosition
    },
    serachOrganization : function (){
      var _this = this
      _this.send({
        url: '/AdminOrganizationList',
        data: {
          AdminId : window.sessionStorage.AdminId,
          key: _this.searchFirm,
          page : _this.lawFirmPage,
          pageSize : '15',
        },
        success: function (res) {
          _this.FirmPageData = res.list
          _this.FirmPageCount = res.count
        }
      })
    },

    cqCommunity : function (){
      var _this = this

    },
    lawFirmTurnPages : function (e){
      console.log(e)
      this.lawFirmPage = e
      this.serachOrganization()
    },
    lawFirmsearch : function (e){
      this.searchFirm = e
      this.lawFirmPage = 1
      this.FirmPageCount = 0
      // this.FirmPageData = []
      this.serachOrganization()
    },

    villageByQuery : function (e){
      console.log(e,this.VillagesId)
      this.serachkey = e
      this.villageByPage = 1
      this.villageByCount = 0
      this.VillageByAdmin()
    },
    villageByOpen : function (e){
      console.log(e)
    },
    cancel : function (){
      var _this = this
      _this.Addmodifications = {
        InsiderName : '',
        InsiderPhone : '',
        InsiderAge : '',
        InsiderEducation : '',
        InsiderPolitics : '',
        // 时间
        ServiceStartTime: '',
        ServiceEndTime: '',
        ServiceStartTimeData : '',
        ServiceEndTimeData : '',
      }

      _this.serachValue = []
      _this.VillagesId = ''
    },
    increase: function() {
      var _this = this
      // if(_this.serachValue == ''){_this.$Notice.warning({title: '系统提示', desc: '请选择区域',});return}
      if(_this.VillagesId == ''){_this.$Notice.warning({title: '系统提示', desc: '请选择村社区',});return}
      if (_this.Addmodifications.InsiderName != '' && _this.Addmodifications.InsiderPhone != '') {
        _this.send({
          url: '/checkInsiderInfo',
          data: {InsiderPhone:_this.Addmodifications.InsiderPhone,
            InsiderName: _this.Addmodifications.InsiderName,},
          success: function(res) {
            if(res != ''){
              for(var i in res){
                console.log(res[i].InsideropenId)
                if(res[i].InsideropenId == null){
                  _this.checkInsiderData.openIdShow = true
                }
              }
              _this.checkInsiderData.data = res
              _this.checkInsiderData.show = true
            }else {
              _this.AAddInsiderInfo()
            }
          }
        })

      } else {
        _this.$Notice.warning({
          title: '系统提示',
          desc: '名称或电话不能为空',
        })
      }
    },
    emptyInsider : function (){
      var _this = this
      _this.send({
        url: '/cleanInsiderPhone',
        data: {InsiderId:_this.checkInsiderData.data[0].InsiderId,AdminId: _this.information.AdminId,
          InsiderPhone:_this.Addmodifications.InsiderPhone,
          InsiderName: _this.Addmodifications.InsiderName,},
        success: function(res) {
          console.log(res)
          _this.AAddInsiderInfo()
        }
      })
    },
    AAddInsiderInfo : function (){
      var _this = this
      _this.send({
        url: '/AAddInsiderInfo',
        data: {

          AdminId: _this.information.AdminId,

          VillagesArr : _this.VillagesId.toString(),



          InsiderId: _this.Addmodifications.InsiderId,
          InsiderName: _this.Addmodifications.InsiderName,
          InsiderSex: _this.Addmodifications.InsiderSex,
          InsiderPhone: _this.Addmodifications.InsiderPhone,
          InsiderAge: _this.Addmodifications.InsiderAge,
          InsiderEducation: _this.Addmodifications.InsiderEducation,
          InsiderPolitics: _this.Addmodifications.InsiderPolitics,
          ServiceStartTime: _this.Addmodifications.ServiceStartTimeData,
          ServiceEndTime: _this.Addmodifications.ServiceEndTimeData,
          InsiderPosition: _this.Addmodifications.InsiderPosition,
        },
        success: function(res) {
          _this.VillageByData = []
          _this.serachValue = []
          _this.page = 1
          _this.modal = false
          _this.cancel()
          _this.aQuestionnaireList()
        }
      })
    },
  },
  mounted() {
    var _this = this
    this.information = window.sessionStorage
    _this.adminLevel = window.sessionStorage.adminLevel
    this.aQuestionnaireList()
    this.getAdminList()
    this.serachOrganization()
    this.filterContent()
    this.boundInsder()
    if(window.sessionStorage.adminLevel == 3){
      _this.serachAdminId = window.sessionStorage.AdminId
      _this.VillageByAdmin()
    }else{
      _this.CqAdminSerach()
    }
  },
}
</script>

<style scoped>

/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
.smallProgram {
  width: 32px;
  height: 32px;
  vertical-align: top;
}
.addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}

.single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
.contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
.inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}

</style>
