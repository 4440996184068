<style scoped>
.content {
    padding: 20px;
}
.list-item {
    border: 1px solid #e5e5e5;
    padding: 10px;
    margin-bottom: 20px;
    transition: 0.1s;
    position: relative;
}
.list-item:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 8px -3px;
    transform: scale(101%);
}
.list-item:hover .list-more-btn {
    opacity: 1;
}
.list-title {
    font-size: 14px;
    font-weight: bold;
}
.list-icon {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    vertical-align: middle;
    margin-right: 6px;
}
.list-content-text {
    font-size: 13px;
    color: #5e5e5e;
}
.list-more-btn {
    width: 120px;
    margin-left: -60px;
    height: 25px;
    line-height: 25px;
    position: absolute;
    left: 50%;
    bottom: -15px;
    cursor: pointer;
    padding: 0 10px;
    background-color: rgb(232 67 53);
    color: #fff;
    text-align: center;
    opacity: 0;
    transition: 0.5s;
}

.screen :deep(.ivu-tabs-nav-scroll ){
    height: 60px;
    line-height: 60px;
}
/*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
.screen :deep(.ivu-tabs-nav ){
    height: 60px;
    line-height: 60px;
}
/*.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}*/
/*.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}*/
/*.screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}*/
.screen :deep(.ivu-tabs-bar ){
    border-bottom: 2px solid #d7d7d7;
    margin-bottom: 40px;
}
.publicWelfare table {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}
.formtable {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}
.publicWelfare table th {
    background: #f7f7f7;
    white-space: nowrap;
    color: #5c6b77;
    font-weight: 600;
}
.publicWelfare table td,
.publicWelfare table th {
    border: 1px solid #e9e9e9;
    padding: 8px 16px;
    text-align: left;
}
.details p {
    color: #000000;
}
.details span {
    color: #5c6b77;
}
</style>

<template>
    <div class="content screen">
      <div style="padding: 15px"></div>
      <div
          style="
                    font-size: 16px;
                    text-align: left;
                    margin-left: 20px;
                    border-left: solid #de4f0b 3px;
                    padding-left: 5px;
                    display: inline-block;margin-right: 30px;
                "
      >
        申办服务
      </div>
      <Button type="primary" @click="applicationclick" v-if="information.adminLevel != 3">申办统计</Button>
<!--        <div style="display: inline-block">-->
<!--            <router-link to="/consultingService"-->
<!--                ><Button type="primary" style="margin-left: 20px">咨询服务</Button></router-link-->
<!--            >-->
<!--            <router-link to="/queryService"-->
<!--                ><Button type="primary" style="margin-left: 20px">查询服务</Button></router-link-->
<!--            >-->
<!--        </div>-->
        <Tabs :value="type" @on-click="opinion" style="margin: 0 20px">
            <TabPane :label="tabsTabel('法律援助', count)" name="1"></TabPane>
            <TabPane :label="tabsTabel('人民调解', pageBar.total)" name="2"></TabPane>
        </Tabs>
     <div style="margin-left: 20px">
       <Button @click="noticeSwitching();noticeData.show = true" type="primary">通知管理</Button>
     </div>
        <!--    法律援助-->
        <div v-show="type == 1">
          <legalaidModal
              :tableData="legalaidPanel.data"
              :state="legalaidPanel.state"
              @on-close="legalaidPanel.state = $event"
          ></legalaidModal>
<!--          呼呼浩特-->
          <legalaidModalhhht
              :tableData="legalaidPanelhhht.data"
              :state="legalaidPanelhhht.state"
              @on-close="legalaidPanelhhht.state = $event"
          ></legalaidModalhhht>
            <div>
                <div class="screen">
                    <div style="background-color: white; margin: 20px">
                        <div class="publicWelfare">
                            <table class="formtable">
                                <thead>
                                <tr v-if="hhht == 'hhht'">
                                  <th>申请用户</th>
                                  <th>申请人</th>
                                  <th>事项类别</th>
<!--                                  <th>机构所在</th>-->
                                  <th>所属村（社区）</th>
                                  <th>申请时间</th>
                                  <th>状态</th>
<!--                                  <th>回复时间</th>-->
                                  <th>操作</th>
                                  <!--                                        <th>机构回复</th>-->
                                </tr>
                                    <tr v-else-if="cq == ''">
                                        <th>申请用户</th>
                                        <th>申请人</th>
                                        <th>事项类别</th>
                                        <th>机构所在</th>
                                        <th>所属村（社区）</th>
                                        <th v-if="information.ParentId == '1669' || information.AreaId == '1669'">律所</th><!-- 驻马店 -->
                                        <th v-if="information.ParentId == '1669' || information.AreaId == '1669'">律师</th><!-- 驻马店 -->
                                        <th>申请时间</th>
                                        <th>状态</th>
                                        <th>回复时间</th>
                                        <th>操作</th>
<!--                                        <th>机构回复</th>-->
                                    </tr>
                                    <!--                        重庆-->
                                    <tr v-else>
                                        <th>序号</th>
                                        <th>用户</th>
                                        <th>申请人</th>
                                        <th>联系电话</th>
                                      <th>所属村（社区）</th>
                                      <th>对方当事人</th>
                                      <th>申请法律援助事项</th>
                                        <th>发起申请时间</th>
                                        <th>申请内容</th>
                                        <th>办理状态</th>
                                                                  <th v-if="cqykt == 1">操作</th>
                                    </tr>
                                </thead>
                                <tbody v-for="(value, index) in legalAidData">
                                    <tr v-if="hhht == 'hhht'">
                                  <td><Avatar :src="value.user.UserImg" /> {{ value.user.UserName }}</td>
                                  <td>{{ value.aid_context.name }}</td>
                                  <td style="width:150px;">
                                    <Tooltip :content="value.aid_context.lawHelperType" placement="right" max-width="-">
                                      <div style=" white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 150px">
                                        {{ value.aid_context.lawHelperType }}
                                      </div>
                                    </Tooltip>

                                  </td>
<!--                                  <td></td>-->
                                  <td>{{ value.user.village_byadmin.admin[0].AdminName }}{{ value.user.village_byadmin.VillagesName }}</td>
                                  <td>{{ value.created_at }}</td>
                                  <td>
                                    <div v-if="value.deportation_admin == null || value.deportation_admin == information.AdminId">
                                      <template v-if="value.status == 5"><Tag color="red">律师驳回</Tag></template>
                                      <template v-if="value.status == 4"><Tag color="blue">律师审核通过</Tag></template>
                                      <template v-if="value.status == 3"><Tag color="green">已分配</Tag></template>
                                      <template v-if="value.status == 2"><Tag color="red">已驳回</Tag></template>
                                      <template v-if="value.status == 1"><Tag color="blue">已通过</Tag></template>
                                      <template v-else-if="value.status == 0"><Tag color="orange">未审核</Tag></template>
                                    </div>
                                    <div v-else>
                                      <template><Tag color="cyan">已移送</Tag></template>
                                    </div>
                                  </td>
<!--                                  <td>-->
<!--                                    <div v-if="value.ReplyContent != null">-->
<!--                                      <div>{{value.ReplyContent.data.jdrq}}</div>-->
<!--                                    </div>-->
<!--                                    <div v-else-if="value.PlatformApply != null">-->
<!--                                      <div>{{value.PlatformApplyTime}}</div>-->
<!--                                    </div>-->
<!--                                    <div v-else>-->
<!--                                      &#45;&#45;-->
<!--                                    </div>-->
<!--                                  </td>-->
                                  <td>
                                    <span @click="(legalaidPanelhhht.data = value), (legalaidPanelhhht.state = true)" style="cursor: pointer;color:#fa8c16;">查看</span>
                                    <span style="padding:3px;"></span>
                                    <div v-if="value.deportation_admin == null || value.deportation_admin == information.AdminId" style="display:inline-block;">
                                      <div style="display:inline-block;" v-if="value.status == 0">
                                        <div v-if="value.deportation_admin == null" style="display: inline-block">
                                          <span @click="transferhhht();transfer.show = true;transfer.aid_id = value.aid_id" style="cursor: pointer;color:#13c2c2;">移送</span>
                                          <span style="padding:3px;"></span>
                                        </div>
                                        <span @click="rejectHhht(value.aid_id,1, false)" style="cursor: pointer;color:#1890ff;">通过</span>
                                        <span style="padding:3px;"></span>
                                        <span @click="toExamineClick(value.aid_id, 2, false)" style="cursor: pointer;color:#f5222d;">驳回</span>
                                      </div>
                                      <div style="display: inline-block" v-if="value.status == 1 || value.status == 5">
                                        <span @click="assignAlawyer(value.aid_id, false)" style="cursor: pointer;color:#1890ff;">指派律师</span>
                                        <span style="padding:3px;"></span>
                                      </div>
                                      <div style="display: inline-block">
                                        <span v-if="value.status == 2 || value.status == 5" @click="reasonforRejection(value.aid_id)" style="cursor: pointer;color:#1890ff;">驳回理由</span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                    <tr v-else-if="cq == ''">
                                        <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
                                        <td>{{ value.Content.zxr }}</td>
                                        <td style="width:150px;">
                                          <Tooltip :content="value.Content.zxsxlxzx" placement="right" max-width="-">
                                            <div style=" white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 150px">
                                              {{ value.Content.zxsxlxzx }}
                                            </div>
                                          </Tooltip>

                                        </td>
                                        <td>
                                            {{
                                                adminId == '1247'
                                                    ? '东部新区'
                                                    : value.Content.orgCity + value.Content.orgArea
                                            }}
                                        </td>
                                        <td>{{ value.CityName }}{{ value.AdminName }}{{ value.VillagesName }}</td>
                                        <td style=" white-space: nowrap; " v-if="information.ParentId == '1669' || information.AreaId == '1669'"><!-- 驻马店 -->
                                          <template v-if="value.FlyzApplyId <= '1188'">--</template>
                                          <template v-else-if="value.OrganizationId == null"><Tag color="orange">未分配</Tag></template>
                                          <template v-else>{{value.OrgName}}</template>
                                        </td>
                                        <td style=" white-space: nowrap; " v-if="information.ParentId == '1669' || information.AreaId == '1669'"><!-- 驻马店 -->
                                          <template v-if="value.FlyzApplyId <= '1188'">--</template>
                                          <template v-else-if="value.lawyerName == null"><Tag color="orange">未分配</Tag></template>
                                          <template v-else>{{value.lawyerName}}</template>
                                        </td>
                                      <td>{{ value.CreateTime }}</td>
                                        <td style=" white-space: nowrap; ">
                                          <div v-if="information.ParentId == '1669' || information.AreaId == '1669'"><!-- 驻马店 -->
                                            <template v-if="value.ReplyContent == null && value.PlatformApply == null"><Tag color="orange">未回复</Tag></template>
                                            <template v-else-if="value.CaseStatus == 2"><Tag color="red">已驳回</Tag></template>
                                            <template v-else-if="value.PlatformApply != null"><Tag color="green">平台已回复</Tag></template>
                                          </div>
                                          <div v-else>
                                            <template v-if="value.ReplyContent == null && value.PlatformApply == null"><Tag color="red">未回复</Tag></template>
                                            <template v-else-if="value.ReplyContent != null"><Tag color="green">机构已回复</Tag></template>
                                            <template v-else-if="value.PlatformApply != null"><Tag color="green">平台已回复</Tag></template>
                                          </div>
                                        </td>
<!--                                        <td v-if="value.ReplyContent">-->
<!--                                            <Button-->
<!--                                                type="primary"-->
<!--                                                v-if="value.ReplyContent.data.zt1 == '已回复'"-->
<!--                                                @click="-->
<!--                                                    FlyzApplyId = value.FlyzApplyId-->
<!--                                                    UserId = value.UserId-->
<!--                                                    result()-->
<!--                                                    modal3 = true-->
<!--                                                "-->
<!--                                                >{{ value.ReplyContent.data.zt1 }}</Button-->
<!--                                            >-->
<!--                                            <span v-else>{{ value.ReplyContent.data.zt1 }}</span>-->
<!--                                        </td>-->
<!--                                        <td v-else="">未回复</td>-->
                                        <td>
                                          <div v-if="value.ReplyContent != null">
                                            <div>{{value.ReplyContent.data.jdrq}}</div>
                                          </div>
                                          <div v-else-if="value.PlatformApply != null">
                                            <div>{{value.PlatformApplyTime}}</div>
                                          </div>
                                          <div v-else>
                                            --
                                          </div>
                                        </td>
                                        <td style=" white-space: nowrap; ">
<!--                                          see(value.Content, value.UserId, value.FlyzApplyId,value);-->
                                          <span @click="(legalaidPanel.data = value), (legalaidPanel.state = true)" style="cursor: pointer;color:#2d8cf0;">查看</span>
                                          <div style="display:inline-block;" v-if="information.ParentId != '1669' && information.AreaId != '1669'"><!-- 不等于驻马店才显示回复功能 -->
                                            <span style="padding:3px;"></span>
                                            <span  v-if="value.ReplyContent == null && value.PlatformApply == null" @click="onreply(value.FlyzApplyId, value.PlatformApply, false)" style="cursor: pointer;color:#f90;">点击回复</span>
                                          </div>
                                          <!--  判断驻马店分配律所 - 并且id小于1188才能分配 -->
                                          <div style="display:inline-block;" v-if="value.OrganizationId == null && value.CaseStatus == 0 && value.FlyzApplyId > '1188'">
                                            <div v-if="information.ParentId == '1669' || information.AreaId == '1669'" style="display:inline-block;">
                                              <span style="padding:3px;"></span>
                                              <span v-if="information.Level != 4" @click="assignLawFirms(value)" style="cursor: pointer;color:#1890ff;">分配律所</span>
<!--                                                驻马店驳回等待接口-->
                                              <span style="padding:3px;"></span>
                                              <span @click="toExamineClickZMD(value.FlyzApplyId, 2, false)" style="cursor: pointer;color:#f5222d;">驳回</span>
                                            </div>
                                          </div>
<!--                                            <Button-->
<!--                                                type="warning"-->
<!--                                                @click="onreply(value.FlyzApplyId, value.PlatformApply, false)"-->
<!--                                                v-if="value.PlatformApply == null"-->
<!--                                                >点击回复</Button-->
<!--                                            >-->
<!--                                            <div v-else>-->
<!--                                              <Button-->
<!--                                                  type="primary"-->
<!--                                                  @click="onreply(value.FlyzApplyId, value.PlatformApply, true)"-->
<!--                                              >查看回复</Button>-->
<!--                                              <div>回复时间：{{value.PlatformApplyTime}}</div>-->
<!--                                            </div>-->
                                        </td>
                                    </tr>
                                    <tr v-else="">
                                        <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
                                        <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
                                        <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
                                        <td>{{ value.Content['biz_webcase.name'] }}</td>
                                      <td>{{ value.Content['biz_webcase.phone'] }}</td>
                                      <td>{{ value.CityName }}{{ value.AdminName }}{{ value.VillagesName }}</td>
                                      <td>{{ value.Content['biz_webcase.opposingname'] }}</td>
                                      <td>{{ value.SupplementContent.legalhelpreasonname }}</td>
                                        <td>{{ value.CreateTime }}</td>
                                        <!--                  <td>{{value.Content['biz_webcase.familyaddress']}}</td>-->
<!--                                        <td>{{ value.SupplementContent.dfFamilyaddress }}</td>-->
                                        <td>
                                            <Button
                                                type="primary"
                                                @click="
                                                    cqsSee(value, value.UserId, value.FlyzApplyId)
                                                    modal4 = true
                                                "
                                                >查看</Button
                                            >
                                        </td>
                                        <td v-if="value.ReplyContent">
                                            <Button
                                                type="primary"
                                                v-if="value.ReplyContent.data.zt1 == '已回复'"
                                                @click="
                                                    FlyzApplyId = value.FlyzApplyId
                                                    UserId = value.UserId
                                                    result()
                                                    modal3 = true
                                                "
                                                >{{ value.ReplyContent.data.zt1 }}</Button
                                            >
                                            <span v-else>{{ value.ReplyContent.data.zt1 }}</span>
                                        </td>
                                        <td v-else="">
                                            <Button
                                                type="warning"
                                                @click="onreply(value.FlyzApplyId, value.PlatformApply, false)"
                                                v-if="value.PlatformApply == null"
                                                >点击回复</Button
                                            >
                                            <Button
                                                type="primary"
                                                @click="onreply(value.FlyzApplyId, value.PlatformApply, true)"
                                                v-else
                                                >查看回复</Button
                                            >
                                        </td>
                                      <td v-if="cqykt == 1">
                                        <Button v-if="value.status == 1" type="primary" @click="submitFlyzApply(value.FlyzApplyId)">上报</Button>
                                        <div v-if="value.status == 2">已上报</div>
                                      </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style="text-align: left; margin-left: 40px; font-size: 16px; padding-bottom: 20px">
                            <Page
                                :total="count"
                                show-total
                                :page-size="pageSize"
                                show-elevator
                                @on-change="
                                    page = $event
                                    adminflyzApplyList()
                                "
                            />
                        </div>
                    </div>
                </div>
                <Modal v-model="modal1" title="法律援助" ok-text="关闭" cancel-text="">
                    <div class="details">
                        <div style="font-size: 16px">1、法律援助事项类别</div>
                        <p><span>申请类别：</span>{{ detailsData.zxsxlxzx }}</p>
                        <div style="font-size: 16px">2、基本信息</div>
                        <p><span>申请人：</span>{{ detailsData.zxr }}</p>
                        <p><span>性别：</span>{{ detailsData.xb }}</p>
                        <p><span>身份证号码：</span>{{ detailsData.zjhm }}</p>
                        <p><span>民族：</span>{{ detailsData.mz }}</p>
                        <p><span>出生日期：</span>{{ detailsData.csrq }}</p>
                        <p><span>年龄：</span>{{ detailsData.nl }}</p>
                        <p><span>联系电话：</span>{{ detailsData.lxdh }}</p>
                        <p>
                            <span>身份证：</span
                            ><Button
                                type="primary"
                                @click="
                                    sfclmcImg(detailsData.sfcl)
                                    modal2 = true
                                "
                                >查看身份证</Button
                            >
                        </p>
                        <div style="font-size: 16px">3、被申请人信息</div>
                        <p><span>姓名：</span>{{ detailsData.bsqr }}</p>
                        <p><span>身份证号码：</span>{{ detailsData.bsqrzjhm }}</p>
                        <p><span>请填写住所地址：</span>{{ detailsData.bsqrdz }}</p>
                        <p><span>案情及申请 理由概述：</span>{{ detailsData.zxnr }}</p>
                        <p><span>机构所在：</span>{{ detailsData.orgCity }}{{ detailsData.orgArea }}</p>
                    </div>
<!--                    <Button-->
<!--                        type="primary"-->
<!--                        @click="-->
<!--                            result()-->
<!--                            modal3 = true-->
<!--                        "-->
<!--                        >结果查询</Button-->
<!--                    >-->
                  <Divider />
                  <div>
                    <div v-if="detailsValue.ReplyContent != null">
                      机构回复：{{ detailsValue.ReplyContent.data.dfyj }}
                      <div>回复时间：{{detailsValue.ReplyContent.data.jdrq}}</div>
                    </div>
                    <div v-else-if="detailsValue.PlatformApply != null">
                      平台回复：{{detailsValue.PlatformApply}}
                      <div>回复时间：{{detailsValue.PlatformApplyTime}}</div>
                    </div>
                    <div v-else>
                      平台回复：未回复
                    </div>
                  </div>
                </Modal>
                <Modal v-model="modal5" title="人民调解" ok-text="关闭" cancel-text="">
                    <div class="details">
                      <p v-if="detailsData.SupplementContent"><span>调解类型：</span>{{ detailsData.SupplementContent.jflx }}</p>
                      <p v-if="detailsData.Content"><span>调解内容说明：</span>{{ detailsData.Content.data.jfjj }}</p>
                        <div style="font-size: 16px">1、申请人信息</div>
                        <p>
                            <span>身份证：</span
                            ><Button
                                type="primary"
                                @click="
                                    sfclmcImg(detailsData.SupplementContent.sfcl)
                                    modal2 = true
                                "
                                >查看身份证</Button
                            >
                        </p>
                        <p v-if="detailsData.Content"><span>姓名：</span>{{ detailsData.Content.data.sqrxm }}</p>
                        <p v-if="detailsData.Content"><span>性别：</span>{{ detailsData.Content.data.sqrxb==1?'男':'女' }}</p>
                        <p v-if="detailsData.Content"><span>民族：</span>{{ detailsData.SupplementContent.sqrmz }}</p>
                        <p v-if="detailsData.Content"><span>出生日期：</span>{{ detailsData.Content.data.sqrcsrq }}</p>
                        <p v-if="detailsData.Content"><span>身份证号码：</span>{{ detailsData.Content.data.sqrsfzh }}</p>
                        <p v-if="detailsData.Content"><span>居住地址：</span>{{ detailsData.Content.data.sqrdz }}</p>
                        <p v-if="detailsData.Content"><span>手机号：</span>{{ detailsData.Content.data.sqrsjhm }}</p>
                        <div style="font-size: 16px">2、被申请人信息</div>
                        <p v-if="detailsData.Content"><span>姓名：</span>{{ detailsData.Content.data.bsqrxm }}</p>
                        <p v-if="detailsData.Content"><span>联系电话：</span>{{ detailsData.Content.data.bsqrsjhm }}</p>
                        <p v-if="detailsData.Content"><span>身份证号：</span>{{ detailsData.Content.data.bsqrsfzh }}</p>
                        <p v-if="detailsData.Content"><span>地址：</span>{{ detailsData.Content.data.bsqrdz }}</p>
                        <p v-if="detailsData.Content"><span>性别：</span>{{ detailsData.Content.data.bsqrxb==1?'男':'女'  }}</p>
                        <p v-if="detailsData.Content"><span>民族：</span>{{ detailsData.Content.data.bsqrmz==1?'汉族':'' }}</p>
                        <p v-if="detailsData.Content"><span>出生日期：</span>{{ detailsData.Content.data.bsqrcsrq }}</p>
<!--                      <div style="font-size: 16px">3、资料</div>-->
<!--                      <Button-->
<!--                          type="primary"-->
<!--                          @click="sfclmcImg();">查看资料</Button>-->
                    </div>
                </Modal>
                <!--重庆法律援助-->

                <Modal v-model="modal4" title="法律援助" ok-text="关闭" cancel-text="">
                    <div class="details">
                        <div style="font-size: 16px">1、法律援助事项类别</div>
                        <p><span>申请类别：</span>{{ SupplementContent.legalhelpreasonname }}</p>
                        <div style="font-size: 16px">2、基本信息</div>
                        <p><span>申请人：</span>{{ Content['biz_webcase.name'] }}</p>
                        <p><span>民族：</span>{{ SupplementContent.mz }}</p>
                        <p><span>性别：</span>{{ SupplementContent.sex }}</p>
                        <p><span>出生日期：</span>{{ SupplementContent.csrq }}</p>
                        <p><span>联系电话：</span>{{ Content['biz_webcase.phone'] }}</p>
                        <p><span>居住地址：</span>{{ Content['biz_webcase.familyaddress'] }}</p>
                        <p><span>身份证号码：</span>{{ Content['biz_webcase.identitycardnumber'] }}</p>
                        <p><span>人群类型：</span>{{ SupplementContent.webcrowdtypename }}</p>
                        <p><span>对方当事人姓名：</span>{{ Content['biz_webcase.opposingname'] }}</p>
                        <p><span>对方当事人住所地：</span>{{ SupplementContent.dfFamilyaddress }}</p>
                        <p v-if="SupplementContent.humantypename">
                            <span>类型：</span>{{ SupplementContent.humantypename }}
                        </p>
                        <p><span>月收入：</span>{{ SupplementContent.monthlyincomename }}</p>
                        <p><span>申请事项明细：</span>{{ Content['biz_webcase.caseremark'] }}</p>
                    </div>
                    <!--<Button type="primary" @click="result();modal3 = true">结果查询</Button>-->
                </Modal>
                <!---->
                <Modal v-model="modal2" title="身份证" ok-text="关闭" cancel-text="">
                    <div v-model="sfclmc">
                        <img :src="sfclmc" style="width: 100%" />
                    </div>
                </Modal>
                <!---->
                <Modal v-model="modal3" title="结果查询" ok-text="关闭" cancel-text="" @on-cancel="close">
                    <div class="details">
                        <div class="consultation">
                            <div style="padding: 20rpx">
                                <div v-if="resultReply.zt1">
                                    <div>状态：{{ resultReply.zt1 }}</div>
                                    <div>答复意见：{{ resultReply.dfyj }}</div>
                                    <div>答复日期：{{ resultReply.jdrq }}</div>
                                </div>
                                <div v-if="resultReply.zt2">
                                    <div>受理审查日期：{{ resultReply.slscrq }}</div>
                                    <div>受理审查单位：{{ resultReply.slscdw }}</div>
                                </div>
                                <div v-if="resultReply.zt3">
                                    <div>立案日期：{{ resultReply.larq }}</div>
                                    <div>案件编号：{{ resultReply.ah }}</div>
                                </div>
                                <div v-if="resultReply.zt4">
                                    <div>案件指派：{{ resultReply.zprq }}</div>
                                    <div>办理单位：{{ resultReply.cbdw }}</div>
                                    <div>承办律师{{ resultReply.cbr }}</div>
                                </div>
                                <div v-if="resultReply.zt5">结案日期：{{ resultReply.jarq }}</div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <!--平台回复-->
                <Modal v-model="replyShow" title="平台回复" @on-ok="submit" ok-text="发送" style="height: 300px">
                    <Input
                        v-model="PlatformApply" :autosize="true"
                        maxlength="300"
                        show-word-limit
                        type="textarea"
                        :disabled="readonly"
                        placeholder="回复内容"
                    />
                </Modal>
            </div>
        </div>
        <!--    人民调解-->
        <div v-show="type == 2">
            <dataModal
                :tableData="dataPanel.data"
                :state="dataPanel.state"
                @on-close="dataPanel.state = $event"
            ></dataModal>
          <div style="background-color: white; margin: 20px" v-if="cq == ''">
            <div class="publicWelfare">
              <table class="formtable">
                <thead>
                <tr>
                  <th>序号</th>
                  <th>用户</th>
                  <th>所属村（社区）</th>
                  <th>姓名</th>
                  <th>性别</th>
<!--                  <th>联系方式</th>-->
                  <th>申请时间</th>
                  <th>调解类型</th>
                  <th>受理单位</th>
                  <th>调解员</th>
                  <th v-if="hhht == 'hhht'">状态</th>
                  <th v-if="hhht == 'hhht'">受理截至日期</th>
                  <th v-if="hhht != 'hhht'">回复状态</th>
                  <th v-if="hhht != 'hhht'">回复时间</th>
                  <th>操作</th>
                </tr>
                </thead>
                <tbody  v-for="(item, index) in list">
                <tr >
                  <td v-if="(pageBar.current - 1) * pageSize + index + 1 < 10">0{{ (pageBar.current - 1) * pageSize + index + 1 }}</td>
                  <td v-else>{{ (pageBar.current - 1) * pageSize + index + 1 }}</td>
                  <td><Avatar :src="item.UserImg" /> {{ item.UserName }}</td>
                  <td>{{ item.CityName }}{{ item.AdminName }}{{ item.VillagesName }}</td>
                  <td>{{ item.Content.applyName }}</td>
                  <td>{{ item.Content.sex }}</td>
<!--                  <td>{{ item.Content.phone }}</td>-->
                  <td>{{ item.CreateTime }}</td>
                  <td>{{ item.Content.applyType }}</td>
                  <td>{{ item.Content.hui || '--' }}</td>
                  <td>{{ item.Content.ren }}</td>
                  <td v-if="hhht== 'hhht'" style=" white-space: nowrap; ">

                    <div v-if="item.is_accept == 0 && item.is_agree == 0 && item.is_succeed == 0">
                      <template><Tag color="orange">待受理</Tag></template>
                    </div>
                    <div>
                      <template v-if="item.is_accept == 1 && item.is_agree == 0 && item.is_succeed == 0"><Tag color="green">已受理</Tag></template>
                      <template v-if="item.is_accept == 1 && item.is_agree == 1 && item.is_succeed == 0"><Tag color="green">已受理</Tag></template>
                    </div>
                    <div v-if="item.is_accept == 2 || item.is_agree == 2 || item.is_succeed == 2">
                      <template><Tag color="red">已驳回</Tag></template>
                    </div>
                    <div v-if="item.is_accept == 1 && item.is_agree == 1 && item.is_succeed == 1">
                      <template><Tag color="blue">受理完成</Tag></template>
                    </div>
                  </td>
                  <td v-if="hhht == 'hhht'">{{item.countdown}}</td>
                  <td v-if="hhht != 'hhht'">
                    <template v-if="item.is_accept == 2"><Tag color="red">已驳回</Tag></template>
                    <template v-else-if="item.replyMsg == null"><Tag color="orange">未回复</Tag></template>
                    <template v-else><Tag color="green">已回复</Tag></template>
                  </td>
                  <td v-if="hhht != 'hhht'">{{ item.replyTime || '--' }}</td>
                  <td style=" white-space: nowrap; ">
                    <span @click=";(dataPanel.data = item), (dataPanel.state = true)" style="cursor: pointer;color:#2d8cf0;">查看</span>
                    <span style="padding:3px;"></span>
                    <div style="display: inline-block" v-if="hhht == 'hhht'">
                      <!-- 1、受理人民调解 -->
                      <div style="display: inline-block" v-if="item.is_accept == 0">
                        <span @click="acceptance(item.MediateId,1, 1)" style="cursor: pointer;color:#1890ff;">受理</span>
                        <span style="padding:3px;"></span>
                        <span @click="rejectClick(item.MediateId, 2, 1)" style="cursor: pointer;color:#f5222d;">驳回</span>
                      </div>
                      <div style="display: inline-block" v-else-if=" item.is_accept == 1 && item.is_agree == 0">
                        <span @click="rejectClick(item.MediateId,1, 2)" style="cursor: pointer;color:#1890ff;">同意调解</span>
                        <span style="padding:3px;"></span>
                        <span @click="rejectClick(item.MediateId, 2, 2)" style="cursor: pointer;color:#f5222d;">不同意调解</span>
                      </div>
                      <div style="display: inline-block" v-else-if="item.is_accept == 1 && item.is_agree == 1 && item.is_succeed == 0">
                        <span @click="rejectClick(item.MediateId,1, 3)" style="cursor: pointer;color:#1890ff;">成功调解</span>
                        <span style="padding:3px;"></span>
                        <span @click="rejectClick(item.MediateId, 2, 3)" style="cursor: pointer;color:#f5222d;">未成功调解</span>
                      </div>
                    </div>
                    <div style="display: inline-block" v-if="hhht != 'hhht'">
                      <div style="display: inline-block" v-if="item.is_accept == 0 && item.replyMsg == null">
                        <span @click="onreply(item.MediateId, item.replyMsg, false)" style="cursor: pointer;color:#f90;">点击回复</span>
<!--                        <span @click="acceptance(item.MediateId,1, 1)" style="cursor: pointer;color:#1890ff;">受理</span>-->
                        <span style="padding:3px;"></span>
                        <span @click="rejectClick(item.MediateId, 2, 1)" style="cursor: pointer;color:#f5222d;">驳回</span>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
<!--            <ul>-->
<!--                <li v-for="(item, key) in list" class="list-item" v-if="cq == ''">-->
<!--                    <Row :gutter="16" class="list-title">-->
<!--                        <Col span="8">-->
<!--                            <img :src="item.UserImg" class="list-icon" />-->
<!--                            <span style="vertical-align: middle">用户：{{ item.UserName }}</span>-->
<!--                        </Col>-->
<!--                        <Col span="8"-->
<!--                            >所属村（社区）：{{ item.CityName }}{{ item.AdminName }}{{ item.VillagesName }}</Col-->
<!--                        >-->
<!--                        <Col span="8">村（社区）律师：{{ item.LawyerName }}</Col>-->
<!--                    </Row>-->
<!--                    <div >-->
<!--                        <Divider style="margin: 7px 0" />-->
<!--                        <Row :gutter="16" class="list-content-text">-->
<!--                            <Col span="6">姓名：{{ item.Content.applyName }}</Col>-->
<!--                            <Col span="6">性别：{{ item.Content.sex }}</Col>-->
<!--                            <Col span="6">联系方式：{{ item.Content.phone }}</Col>-->
<!--                            <Col span="6">申请时间：{{ item.CreateTime }}</Col>-->
<!--                        </Row>-->
<!--                        <div style="padding: 3px"></div>-->
<!--                        <Row :gutter="16" class="list-content-text">-->
<!--                            <Col span="6">调解类型：{{ item.Content.applyType }}</Col>-->
<!--                            <Col span="6">受理单位：{{ item.Content.hui }}</Col>-->
<!--                            <Col span="6">调解员：{{ item.Content.ren }}</Col>-->
<!--                            <Col span="6">-->
<!--                              <Button type="warning" @click="onreply(item.MediateId, item.replyMsg, false)" v-if="item.replyMsg == null">点击回复</Button>-->
<!--                              <Button type="primary" @click="onreply(item.MediateId, item.replyMsg, true)" v-else>查看回复</Button>-->
<!--                            </Col>-->
<!--                        </Row>-->
<!--                    </div>-->
<!--                    <div class="list-more-btn" @click=";(dataPanel.data = item), (dataPanel.state = true)">-->
<!--                        更多信息 <Icon type="ios-arrow-dropdown" />-->
<!--                    </div>-->
<!--                </li>-->
<!--            </ul>-->

          <div style="background-color: white; margin: 20px" v-if="cq != ''">
            <div class="publicWelfare">
              <table class="formtable">
                <thead>
                <tr>
                  <th>序号</th>
                  <th>用户</th>
                  <th>申请人</th>
                  <th>联系电话</th>
                  <th>所属村（社区）</th>
                  <th>调解员</th>
                  <th>申请调解事项</th>
                  <th>发起申请时间</th>
                  <th>申请内容</th>
<!--                  <th>办理状态</th>-->
                  <th v-if="cqykt == 1">操作</th>
                </tr>
                </thead>
                <tbody v-for="(value, index) in list">
                <tr >
                  <td v-if="(pageBar.current - 1) * pageSize + index + 1 < 10">0{{ (pageBar.current - 1) * pageSize + index + 1 }}</td>
                  <td v-else>{{ (pageBar.current - 1) * pageSize + index + 1 }}</td>
                  <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
                  <td>{{ value.Content.data.sqrxm }}</td>
                  <td>{{ value.Content.data.sqrsjhm }}</td>
                  <td>{{ value.CityName }}{{ value.AdminName }}{{ value.VillagesName }}</td>
                  <td>{{ value.SupplementContent.fzr }}</td>
                  <td>{{ value.SupplementContent.jflx }}</td>
                  <td>{{ value.CreateTime }}</td>
                  <td>
<!--                    <Button-->
<!--                        type="primary"-->
<!--                        @click="see(value, value.UserId, value.FlyzApplyId)-->
<!--                                                    modal5 = true"-->
<!--                    >查看</Button-->
<!--                    >-->
                    <span @click=";(dataPanel.data = value), (dataPanel.state = true)" style="cursor: pointer;color:#2d8cf0;">查看</span>
                    <span style="padding:3px;"></span>
                    <span @click="onreply(value.MediateId, value.replyMsg, false)" v-if="value.replyMsg == null" style="cursor: pointer;color:#f90;">点击回复</span>
                  </td>
                  <td v-if="cqykt == 1">
                    <Button v-if="value.status == 1" type="primary" @click="submitMediate(value.MediateId)">上报</Button>
                    <div v-if="value.status == 2">已上报</div>
                  </td>
<!--                  <td v-if="value.ReplyContent">-->
<!--                    <Button-->
<!--                        type="primary"-->
<!--                        v-if="value.ReplyContent.data.zt1 == '已回复'"-->
<!--                        @click="-->
<!--                                                    FlyzApplyId = value.FlyzApplyId-->
<!--                                                    UserId = value.UserId-->
<!--                                                    result()-->
<!--                                                    modal3 = true-->
<!--                                                "-->
<!--                    >{{ value.ReplyContent.data.zt1 }}</Button-->
<!--                    >-->
<!--                    <span v-else>{{ value.ReplyContent.data.zt1 }}</span>-->
<!--                  </td>-->
<!--                  <td v-else="">-->
<!--                    未回复-->
<!--                    <Button-->
<!--                        type="warning"-->
<!--                        @click="onreply(value.FlyzApplyId, value.PlatformApply, false)"-->
<!--                        v-if="value.PlatformApply == null"-->
<!--                    >点击回复</Button-->
<!--                    >-->
<!--                    <Button-->
<!--                        type="primary"-->
<!--                        @click="onreply(value.FlyzApplyId, value.PlatformApply, true)"-->
<!--                        v-else-->
<!--                    >查看回复</Button-->
<!--                    >-->
<!--                  </td>-->
                </tr>
                </tbody>
              </table>
            </div>
          </div>
            <Page
                :total="pageBar.total"
                :current="pageBar.current"
                :page-size="pageBar.pageSize"
                :page-size-opts="[10, 15, 20, 30, 50]"
                @on-change="getList"
                @on-page-size-change=";(pageBar.pageSize = $event), getList()"
                show-elevator
                show-total
                show-sizer
            />
        </div>
<!--      申办统计弹出-->
      <Modal
          v-model="applicationData.show"
          :title="applicationData.title" width="900" ok-text="关闭"
          cancel-text="">
        <Tabs :value="applicationData.type" @on-click="statisticalSwitching" style="margin: 0 20px">
          <TabPane label="法律援助" name="1"></TabPane>
          <TabPane label="人民调解" name="2"></TabPane>
        </Tabs>
        <div v-if="applicationData.data.length > 0" id="myChart1" :style="{ height: applicationData.data.length * 50 + 'px', width: '100%' }"></div>
<!--        <div class="publicWelfare" >-->
<!--          <table class="formtable">-->
<!--            <thead>-->
<!--            <tr>-->
<!--              <th>序号</th>-->
<!--              <th>名称</th>-->
<!--              <th>总计</th>-->
<!--              <th>已回复</th>-->
<!--              <th>未回复</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <tr v-for="(value,index) in applicationData.data">-->
<!--              <td>{{index+1}}</td>-->
<!--              <td>{{value.AdminName}}</td>-->
<!--              <td>{{value.Count}}</td>-->
<!--              <td>{{value.ReplyCount}}</td>-->
<!--              <td>{{value.NoReplyCount}}</td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </div>-->
      </Modal>
<!--      通知管理-->
      <Modal
          v-model="noticeData.showAdd"
          :title="'添加'+noticeData.title+'通知人员'" ok-text="提交"
          cancel-text="" @on-ok="submitNotice">
        <div>
          <Form :model="noticeData" :label-width="60">
            <FormItem label="姓名">
              <Input v-model="noticeData.name" placeholder="请输入姓名"></Input>
            </FormItem>
            <FormItem label="手机号">
              <Input v-model="noticeData.phone" placeholder="请输入手机号"></Input>
            </FormItem>
          </Form>
        </div>
      </Modal>
      <Modal
          v-model="noticeData.show"
          :title="noticeData.title + '通知管理'" width="800" ok-text="关闭"
          cancel-text="">
        <Tabs :value="noticeData.type" @on-click="noticeSwitching" style="margin: 0 20px">
          <TabPane label="法律援助" name="1"></TabPane>
          <TabPane label="人民调解" name="2"></TabPane>
        </Tabs>
        <div>
          <Button type="primary" @click="noticeData.showAdd = true">添加通知人员</Button>
        </div>
        <div class="publicWelfare" >
          <table class="formtable">
            <thead>
            <tr>
              <th>序号</th>
              <th>名称</th>
              <th>手机号</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(value,index) in noticeData.data">
              <td>{{index+1}}</td>
              <td>{{value.name}}</td>
              <td>{{value.phone}}</td>
              <td>
                <span @click="deleteClick(value.id)" style="cursor: pointer;color:red;">删除</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </Modal>
<!--      呼呼浩特-->
      <Modal
          v-model="assignaLawyerData.assignShow"
          title="指派律师" width="900" ok-text="关闭"
          cancel-text="">
        <div class="publicWelfare" >
          <table class="formtable">
            <thead>
            <tr>
              <th>序号</th>
              <th>律师名称</th>
              <th>擅长领域</th>
              <th>政治面貌</th>
              <th>律师执业证号</th>
              <th>所属律所</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(value,index) in assignaLawyerData.assignAlawyerList">
              <td>{{index+1}}</td>
              <td>{{value.lawyer_name}}</td>
              <td>{{value.lawyer_expertise}}</td>
              <td>{{value.lawyer_politics}}</td>
              <td>{{value.lawyer_code}}</td>
              <td>{{value.lawyer_or}}</td>
              <td>
                <span @click="assignClick(value.id)" style="cursor: pointer;color:rgb(24, 144, 255);">指派</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </Modal>
      <!--呼呼浩特移送-->
      <Modal v-model="transfer.show" title="移送" @on-ok="migrateComplete" ok-text="移送" >
        <Cascader :data="transfer.data" :load-data="transferhhht" v-model="transfer.value"></Cascader>
      </Modal>
      <Modal v-model="assignaLawyerData.toExamineShow" title="驳回理由" @on-ok="rejectHhht" ok-text="发送" >
        <Input
            v-model="assignaLawyerData.remark" :autosize="true"
            maxlength="300"
            show-word-limit
            type="textarea"
            :disabled="readonly"
            placeholder="回复内容"
        />
      </Modal>
      <Modal v-model="mediatehhht.toExamineShow" :title="mediatehhht.status == 2 ? '理由' : mediatehhht.status == 1 && mediatehhht.type == 2 ? '信息录入' : '录入成功结果'" @on-ok="acceptance" ok-text="发送" >
        <Input v-if="mediatehhht.status == 2"
            v-model="mediatehhht.content" :autosize="true"
            maxlength="300"
            show-word-limit
            type="textarea"
            :disabled="readonly"
            placeholder="回复内容"
        />
        <Input v-if="mediatehhht.status == 1 && mediatehhht.type == 3"
               v-model="mediatehhht.content" :autosize="true"
               maxlength="300"
               show-word-limit
               type="textarea"
               :disabled="readonly"
               placeholder="回复内容"
        />
        <div v-if="mediatehhht.type == 2 && mediatehhht.status == 1">
          <Form :model="mediatehhht.mediateInfo" :label-width="80">
            <FormItem label="调解员">
              <Input v-model="mediatehhht.mediateInfo.name" placeholder="调解员"></Input>
            </FormItem>
            <FormItem label="调解机构">
              <Input v-model="mediatehhht.mediateInfo.organization" placeholder="调解组织"></Input>
            </FormItem>
            <FormItem label="调解地点">
              <Input v-model="mediatehhht.mediateInfo.address" placeholder="调解地点"></Input>
            </FormItem>
            <FormItem label="调解地点">
              <DatePicker
                  :value="mediatehhht.mediateInfo.time"
                  v-model="mediatehhht.mediateInfo.time"
                  type="date"
                  split-panels
                  placeholder="调解时间"
                  style="width: 200px"
                  @on-change="mediationTime"
              ></DatePicker>
            </FormItem>
          </Form>
        </div>
      </Modal>
<!--      驳回理由-->
      <Modal v-model="assignaLawyerData.rejectShow" title="驳回理由"  ok-text="关闭"
             cancel-text="" >
          <div>
            {{assignaLawyerData.rejectData.remark}}
          </div>
      </Modal>
      <!--    驻马店分配律所-->
      <Modal
          v-model="assignLawFirmsData.show"
          title="分配律所" width="900" ok-text="关闭"
          cancel-text="">
        <div class="publicWelfare" >
          <table class="formtable">
            <thead>
            <tr>
              <th>序号</th>
              <th>律所</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(value,index) in assignLawFirmsData.data">
              <td>{{index+1}}</td>
              <td>{{value.AdminName}}</td>
              <td>
                <span @click="assignOrganization(value)" style="cursor: pointer;color:rgb(24, 144, 255);">分配</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </Modal>
<!--      驻马店法律援助驳回通过-->
      <Modal v-model="assignLawFirmsData.toExamineShow" title="驳回理由" @on-ok="rejectZMD" ok-text="发送" >
        <Input
            v-model="assignLawFirmsData.remark" :autosize="true"
            maxlength="300"
            show-word-limit
            type="textarea"
            :disabled="readonly"
            placeholder="回复内容"
        />
      </Modal>
    </div>
</template>

<script>
import dataModal from '../manager/mediation/modules/data-modal'
import legalaidModal from '../manager/mediation/modules/legalaidModal'
import legalaidModalhhht from '../manager/mediation/modules/legalaidModalhhht'
import echarts from "echarts";
export default {
    name: 'list',
    data() {
        return {
            tabsTabel(name, count) {
                return (h) => {
                    return h('div', [
                        h('span', name),
                        h(
                            'span',
                            {
                                style: { color: 'rgb(222, 79, 11)' }
                            },
                            count
                        ),
                        h('span', '条')
                    ])
                }
            },
            //法律援助
            information: [],
            legalAidData: [],
            count: 0,
            pageSize: 10,
            page: 1,
            modal1: false,
            detailsData: [],
            detailsValue: [],
            sfclmc: '',
            modal4: false,
            modal3: false,
            modal2: false,
            modal5: false,
            resultReply: [],
            replyShow: false,
            replydata: '',
            FlyzApplyId: '',
            PlatformApply: '',
            Content: [],
            SupplementContent: [],
            readonly: false,
            //人民调解
            list: [],
            pageBar: {
                current: 1,
                total: 0,
                pageSize: 10
            },
            dataPanel: {
                state: false,
                data: {}
            },
            legalaidPanel: {
              state: false,
              data: {}
            },
            legalaidPanelhhht: {
              state: false,
              data: {}
            },
            type: '1',
            adminId: '',
          cq: '',
          hhht: '',
          cqykt : '',
          applicationData : {
              show: false,
              data:[],
              type: '1',
              title: '法律援助'
          },
        // 通知管理
          noticeData: {
              show : false,
            showAdd : false,
              data: [],
              type: '1',
            title: '法律援助',
            name: '',
            phone: '',
          },
        //   审核-指派律师
          assignaLawyerData: {
              toExamineShow: false,
              aid_id: '',
              remark: '',
              status: '',
              assignShow: false,
              assignAlawyerList: [],
              data: [],
              rejectData: [],
              rejectShow: false,
          },
          // 驻马店分配律所
          assignLawFirmsData: {
            data: [],
            show: false,
            toExamineShow: false,
            remark: '',
            FlyzApplyId: '',
          },
        //   呼和浩特 移送数据
          transfer: {
            show: false,
            data : [],
            aid_id: '',
            value: '',
          },
          mediatehhht: {
            MediateId: '',
            content: '',
            status: '',
            toExamineShow: false,
            mediateInfo: {
              time:'',
              name:'',
              address: '',
              organization: ''
            },
            type: '',
          }
        }
    },
    components: { dataModal,legalaidModal,legalaidModalhhht },
    computed: {
        isCq() {
            return this.legalAidData[0] || {}
        }
    },
    mounted() {
        this.getList()
        this.information = window.sessionStorage
      if (SYSCONFIG.location == 'chong-qing') {
        this.cq = 'cq'
      }
      // 判断当前是呼呼浩特
      if(window.sessionStorage.ParentId == '349' || window.sessionStorage.AreaId == '349'){
        this.hhht = 'hhht'
      }
      this.cqykt = window.sessionStorage.cqykt

      this.adminflyzApplyList()
    },

    methods: {
        // 删除
        deleteClick: function (id) {
          var _this = this
          if(this.noticeData.type == '1'){var url = '/FlyzApplyLinkmanDel'}
          if(this.noticeData.type == '2'){var url = '/MediateLinkmanDel'}
          _this.send({
            confirm: true,
            url: url,
            data: {
              AdminId: window.sessionStorage.AdminId,
              id: id,
            },
            success: function (res) {
              _this.noticeSwitching()
            }
          })
        },
        // 通知管理切换
        noticeSwitching: function (e){
          if(e){
            this.noticeData.type = e
          }
          if(this.noticeData.type == '1'){
            this.noticeData.title = '法律援助'
            this.flyzApplyLinkmanList()
          }
          if(this.noticeData.type == '2'){
            this.noticeData.title = '人民调解'
            this.mediateLinkmanList()
          }
        },
        // 提交
        submitNotice : function (){
          var _this = this
          if(this.noticeData.type == '1'){
            var url = '/FlyzApplyLinkman'
          }
          if(this.noticeData.type == '2'){
            var url = '/MediateLinkman'
          }
          _this.send({
            url: url,
            loading: true,
            data: {
              AdminId: window.sessionStorage.AdminId,
              name: _this.noticeData.name,
              phone: _this.noticeData.phone,
            },
            success: function (res) {
              _this.noticeSwitching()
              _this.noticeData.showAdd = false
              _this.noticeData.name = ''
              _this.noticeData.phone = ''
            }
          })
        },
        // 法律援助
        flyzApplyLinkmanList : function (){
          var _this = this
          _this.send({
            url: '/FlyzApplyLinkmanList',
            loading: true,
            data: {
              AdminId: window.sessionStorage.AdminId
            },
            success: function (res) {
              console.log(res)
              _this.noticeData.data = res
            }
          })
        },
        // 法律援助
        mediateLinkmanList : function (){
          var _this = this
          _this.send({
            url: '/MediateLinkmanList',
            loading: true,
            data: {
              AdminId: window.sessionStorage.AdminId
            },
            success: function (res) {
              console.log(res)
              _this.noticeData.data = res
            }
          })
        },
        // 申办统计
        applicationclick: function (){
          this.applicationData.show = true
          this.adminFlyzapplyCountList()
        },
        // 申办统计切换
        statisticalSwitching: function (e){
            console.log(e)
            if(e == '1'){
              this.applicationData.title = '法律援助'
              this.adminFlyzapplyCountList()
            }
            if(e == '2'){
              this.applicationData.title = '人民调解'
              this.adminMediateCountList()
            }
        },
        // 法律援助
        adminFlyzapplyCountList: function (){
          var _this = this
          _this.send({
            url: '/AdminFlyzapplyCountList',
            loading: true,
            data: {
              AdminId: window.sessionStorage.AdminId
            },
            success: function (res) {
              console.log(res)
              _this.applicationData.data = res
              _this.histogram()
            }
          })
        },
      adminMediateCountList: function (){
          var _this = this
          _this.send({
            url: '/AdminMediateCountList',
            loading: true,
            data: {
              AdminId: window.sessionStorage.AdminId
            },
            success: function (res) {
              console.log(res)
              _this.applicationData.data = res
              _this.histogram()
            }
          })
        },
        histogram: function (){
          var _this = this
          var AdminName = []
          var Count = []
          var ReplyCount = []
          var NoReplyCount = []
          this.applicationData.data.sort(function(a, b) {
            return a.Count - b.Count; // 升序排列
          });
          for (var i in this.applicationData.data) {
            AdminName.push(this.applicationData.data[i].AdminName.replace('司法局',''))
            Count.push(this.applicationData.data[i].Count)
            ReplyCount.push(this.applicationData.data[i].ReplyCount)
            NoReplyCount.push(this.applicationData.data[i].NoReplyCount)
          }
          setTimeout(function() {
            let myChart1 = echarts.init(document.getElementById('myChart1'), 'light')
            myChart1.setOption({
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross'
                }
              },
              // title: { text: "十日新增数据", left: "center" },
              legend: {
                data: ['总计', '已回复', '未回复'],
                left: 'center'
                // top: 50
              },
              grid: {
                // top: 100
              },

              xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
              },
              yAxis: {
                type: 'category',
                data: AdminName,
                axisLabel: {
                  interval: 0, // 强制显示所有标签
                  rotate: 0,   // 不旋转标签
                  margin: 15,  // 增大刻度标签与轴线之间的距离
                  textStyle: {
                    fontSize: 12, // 减小标签字体大小
                    fontFamily: 'Arial', // 标签字体
                  },
                  formatter: function (value) {
                    // 自定义格式化标签文字的方法
                    // 可以根据实际情况进行调整
                    if (value.length > 6) { // 假设8是一个需要换行的长度阈值
                      return value.substring(0, 6) + '\n' + value.substring(6);
                    } else {
                      return value;
                    }
                  }
                }
              },
              series: [

                {
                  name: '总计',
                  type: 'bar',
                  data: Count,
                  itemStyle:{
                    color:"#3498db",barBorderRadius:4,
                  },
                  label: {
                    show: true,
                    position: 'right'
                  },
                },
                {
                  name: '已回复',
                  type: 'bar',//line
                  data: ReplyCount,
                  itemStyle:{
                    color:"#2ecc71",barBorderRadius:4,
                  },
                  label: {
                    show: true,
                    position: 'right'
                  },
                },
                {
                  name: '未回复',
                  type: 'bar',
                  data: NoReplyCount,
                  itemStyle:{
                    color:"#e74c3c",barBorderRadius:4,
                  },
                  label: {
                    show: true,
                    position: 'right'
                  },
                }
              ]
            })
          },0)

        },
      // 呼和浩特移送
      transferhhht: function (item, callback){
          console.log(item)
        if(item){
          var AdminId = item.value
        }else {
          var AdminId = ''
        }
        var _this = this
        _this.send({
          url: '/adminDeportationList',
          data: {
            AdminId: AdminId,
          },
          success: function (res) {
            console.log(res)
            if(item){
              var data = []
              for(var i in res){
                data.push({
                  value:res[i].AdminId,
                  label:res[i].AdminName,
                })
              }
              item.children = data
              item.loading = false;
              callback();
            }else {
              var data = []
              for(var i in res){
                data.push({
                  value:res[i].AdminId,
                  label:res[i].AdminName,
                  children: [],
                  loading:false
                })
              }
              _this.transfer.data = data
            }
          }
        })
      },
      // 确定移送
      migrateComplete: function (){
        var _this = this
        console.log(_this.transfer.value)
        _this.send({
          url: '/aidDeportation',
          data: {
            AdminId: window.sessionStorage.AdminId,
            aid_id: _this.transfer.aid_id,
            deportation_admin: _this.transfer.value[1],
          },
          success: function (res) {
            _this.transfer.value = ''
            _this.adminflyzApplyList()
          }
        })
      },
      // 呼呼浩特法律援助驳回
      rejectHhht: function (aid_id, status){
          var _this = this
        _this.send({
          url: '/aidApplyReview',
          confirm: status == 1 ? true : false,
          data: {
            AdminId: window.sessionStorage.AdminId,
            aid_id: _this.assignaLawyerData.aid_id || aid_id,
            remark: _this.assignaLawyerData.remark,
            status: _this.assignaLawyerData.status || status
          },
          success: function (res) {
            _this.$Notice.success({
              title: '系统提示',
              desc: '发送成功'
            })
            _this.assignaLawyerData.aid_id = ''
            _this.assignaLawyerData.remark = ''
            _this.assignaLawyerData.status = ''
            _this.adminflyzApplyList()
          }
        })
      },
      // 驻马店驳回功能
      rejectZMD: function (FlyzApplyId, status){
          var _this = this
        _this.send({
          url: '/CheckFlyzApply',
          data: {
            AdminId: window.sessionStorage.AdminId,
            FlyzApplyId: _this.assignLawFirmsData.FlyzApplyId || FlyzApplyId,
            PlatformApply: _this.assignLawFirmsData.remark,
            CaseStatus: _this.assignLawFirmsData.status || status
          },
          success: function (res) {
            _this.$Notice.success({
              title: '系统提示',
              desc: '发送成功'
            })
            _this.assignLawFirmsData.FlyzApplyId = ''
            _this.assignLawFirmsData.remark = ''
            _this.assignLawFirmsData.status = ''
            _this.adminflyzApplyList()
          }
        })
      },
      // 呼和浩特受理驳回事件
      acceptance: function (MediateId, status, type){
          var _this = this
          if(type){_this.mediatehhht.type = type}
          if(_this.mediatehhht.type == 1){
            _this.send({
              url: '/AcceptMediate',
              confirm: status == 1 ? true : false,
              data: {
                AdminId: window.sessionStorage.AdminId,
                MediateId: _this.mediatehhht.MediateId || MediateId,
                content: _this.mediatehhht.content,
                is_accept: _this.mediatehhht.status || status
              },
              success: function (res) {
                _this.$Notice.success({
                  title: '系统提示',
                  desc: '操作成功'
                })
                _this.mediatehhht.MediateId = ''
                _this.mediatehhht.content = ''
                _this.mediatehhht.status = ''
                _this.getList()
              }
            })
          }
          if(_this.mediatehhht.type == 2){
            _this.send({
              url: '/AgreeMediate',
              data: {
                AdminId: window.sessionStorage.AdminId,
                MediateId: _this.mediatehhht.MediateId || MediateId,
                content: _this.mediatehhht.content,
                mediateInfo: _this.mediatehhht.mediateInfo,
                is_agree: _this.mediatehhht.status || status
              },
              success: function (res) {
                _this.$Notice.success({
                  title: '系统提示',
                  desc: '操作成功'
                })
                _this.mediatehhht.MediateId = ''
                _this.mediatehhht.content = ''
                _this.mediatehhht.mediateInfo = {
                  time:'',
                  name:'',
                  address: '',
                  organization: ''
                }
                _this.mediatehhht.status = ''
                _this.getList()
              }
            })
          }
        if(_this.mediatehhht.type == 3){
          _this.send({
            url: '/isSucceedMediate',
            data: {
              AdminId: window.sessionStorage.AdminId,
              MediateId: _this.mediatehhht.MediateId || MediateId,
              content: _this.mediatehhht.content,
              is_succeed: _this.mediatehhht.status || status
            },
            success: function (res) {
              _this.$Notice.success({
                title: '系统提示',
                desc: '操作成功'
              })
              _this.mediatehhht.MediateId = ''
              _this.mediatehhht.content = ''
              _this.mediatehhht.status = ''
              _this.getList()
            }
          })
        }

      },
      // 人民调解驳回弹窗
      rejectClick: function (MediateId, status, type) {
        this.mediatehhht.MediateId = MediateId
        this.mediatehhht.status = status
        this.mediatehhht.type = type
        this.mediatehhht.toExamineShow = !this.mediatehhht.toExamineShow
      },
      // 时间
      mediationTime: function (e){
        this.mediatehhht.mediateInfo.time = e
      },
      //法律援助
        submit: function () {
            var _this = this
            if (_this.type == 1) {
              _this.send({
                url: '/AdminflyzApply',
                data: {
                  FlyzApplyId: _this.FlyzApplyId,
                  PlatformApply: _this.PlatformApply,
                  AdminId: window.sessionStorage.AdminId
                },
                success: function (res) {
                  _this.$Notice.success({
                    title: '系统提示',
                    desc: '发送成功'
                  })
                  _this.PlatformApply = ''
                  _this.adminflyzApplyList()
                }
              })
            }
            if (_this.type == 2){
              _this.send({
                url: '/ReplyMediate',
                data: {
                  MediateId: _this.FlyzApplyId,
                  content: _this.PlatformApply,
                  AdminId: window.sessionStorage.AdminId
                },
                success: function (res) {
                  _this.$Notice.success({
                    title: '系统提示',
                    desc: '发送成功'
                  })
                  _this.PlatformApply = ''
                  _this.getList()
                }
              })
            }
        },
        onreply: function (FlyzApplyId, PlatformApply, readonly) {
            this.FlyzApplyId = FlyzApplyId
            this.PlatformApply = PlatformApply
            this.readonly = readonly
            this.replyShow = !this.replyShow
        },
        // 律师驳回理由
        reasonforRejection: function (id){
          var _this = this
          _this.send({
            url: '/recordList',
            data: {
              aid_id : id
            },
            success: function (res) {
              _this.assignaLawyerData.rejectData = res
              _this.assignaLawyerData.rejectShow = true
            }
          })
        },
        // 指派律师弹窗
        assignAlawyer: function (aid_id) {
          this.assignaLawyerData.aid_id = aid_id
          this.assignaLawyerData.assignShow = !this.assignaLawyerData.assignShow
          var _this = this
          _this.send({
            url: '/aidLawyerList',
            data: {},
            success: function (res) {
              console.log(res)
              _this.assignaLawyerData.assignAlawyerList = res
            }
          })
        },
        // 驳回弹窗
        toExamineClick: function (aid_id, status, readonly) {
            this.assignaLawyerData.aid_id = aid_id
            this.assignaLawyerData.status = status
            this.readonly = readonly
            this.assignaLawyerData.toExamineShow = !this.assignaLawyerData.toExamineShow
        },
        toExamineClickZMD: function (FlyzApplyId, status, readonly) {
            this.assignLawFirmsData.FlyzApplyId = FlyzApplyId
            this.assignLawFirmsData.status = status
            this.readonly = readonly
            this.assignLawFirmsData.toExamineShow = !this.assignLawFirmsData.toExamineShow
        },
        // 点击指派律师
        assignClick: function (id){
          var _this = this
          _this.send({
            url: '/aidAssignLawyer',
            confirm: true,
            data: {
              aid_id:_this.assignaLawyerData.aid_id,
              lawyer_id:id,
            },
            success: function (res) {
              _this.$Message.success('指派成功')
              _this.assignaLawyerData.assignShow = !_this.assignaLawyerData.assignShow
              _this.assignaLawyerData.aid_id = ''
              _this.adminflyzApplyList()
            }
          })
        },
        close: function () {
            this.resultReply = []
        },
        result: function () {
            var _this = this
            _this.send({
                url: '/flyzApply_resAdmin',
                data: {
                    FlyzApplyId: _this.FlyzApplyId,
                    UserId: _this.UserId
                },
                success: function (res) {
                    if (res.code != 500) {
                        _this.resultReply = res.data
                    } else {
                        _this.resultReply = []
                    }
                }
            })
        },
        adminflyzApplyList: function () {
            var _this = this
            if(this.hhht == 'hhht'){
              _this.send({
                url: '/adminAidApplyList',
                data: {
                  AdminId: window.sessionStorage.AdminId,
                  page: _this.page,
                  pageSize: _this.pageSize
                },
                success: function (res) {
                  _this.legalAidData = res.data
                  _this.count = res.total
                }
              })
            }else {
              _this.send({
                url: '/AdminflyzApplyList',
                data: {
                  AdminId: window.sessionStorage.AdminId,
                  page: _this.page,
                  pageSize: _this.pageSize
                },
                success: function (res) {
                  _this.legalAidData = res.List
                  _this.count = res.Count
                }
              })
            }
        },
        cqsSee: function (e, UserId, FlyzApplyId) {
            this.Content = e.Content
            this.SupplementContent = e.SupplementContent
            console.log(this.detailsData)
            this.UserId = UserId
            this.FlyzApplyId = FlyzApplyId
        },
        see: function (e, UserId, FlyzApplyId, value) {
            console.log(value)
            this.detailsData = e
            this.detailsValue = value
            this.UserId = UserId
            this.FlyzApplyId = FlyzApplyId
        },
        sfclmcImg: function (e) {
            this.sfclmc = e
        },
        //人民调解
        opinion: function (e) {
            this.type = e
          // if(e == 1){this.adminflyzApplyList()}
          // if(e == 2){this.getList()}
        },
        getList: function (currentPage) {
            var _this = this
            _this.pageBar.current = currentPage || 1
            _this.send({
                url: '/AdminMediateList',
                data: {
                    AdminId: window.sessionStorage.AdminId,
                    page: _this.pageBar.current,
                    pageSize: _this.pageBar.pageSize
                },
                success: function (res) {
                    _this.list = res.List
                    _this.pageBar.total = res.Count
                }
            })
        },
      submitFlyzApply : function (id){
        var _this = this
        _this.send({
          url: '/submitFlyzApply',
          confirm: true,
          data: {
            FlyzApplyId: id,
          },
          success: function (res) {
            _this.$Message.success('上报成功')
            _this.adminflyzApplyList()
          }
        })
      },
      submitMediate : function (id){
        var _this = this
        _this.send({
          url: '/submitMediate',
          confirm: true,
          data: {
            MediateId: id,
          },
          success: function (res) {
            _this.$Message.success('上报成功')
            _this.getList()
          }
        })
      },
      // 驻马店律所列表
      assignLawFirms: function (value){
        var _this = this
        _this.assignLawFirmsData.FlyzApplyId = value.FlyzApplyId
        _this.send({
          url: '/OrganizationList',
          data: {

          },
          success: function (res) {
            _this.assignLawFirmsData.data = res
            _this.assignLawFirmsData.show = true
          }
        })
      },
      // 驻马店分配律所事件
      assignOrganization: function (value){
        var _this = this
        _this.send({
          url: '/AdminAssignOrg',
          confirm: true,
          data: {
            FlyzApplyId: _this.assignLawFirmsData.FlyzApplyId,
            OrganizationId: value.AdminId,
            OrgName: value.AdminName,
            AdminId: window.sessionStorage.AdminId,
          },
          success: function (res) {
            _this.$Message.success('分配成功')
            _this.assignLawFirmsData.FlyzApplyId = ''
            _this.assignLawFirmsData.show = false
            _this.rejectZMD (_this.assignLawFirmsData.FlyzApplyId, 1)
            _this.adminflyzApplyList()
          }
        })
      },
    }
}
</script>
