<style scoped>
</style>

<template>
  <router-view></router-view>
</template>

<script>
export default {
  name:'mdPage',
  data() {
    return {
      getUserInfoFun:{}
    };
 },
  methods: {
  },
  mounted() {
    var _this = this
    // _this.getUserInfoFun = setInterval(()=>{
    //   if(_this.$store.state.userInfo.UserId){
    //     _this.$store.state.socketFun.openSocket(_this.$store.state)
    //   }
    //   clearInterval(_this.getUserInfoFun)
    // },2000)
  },
}
</script>
