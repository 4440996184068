var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"background":"url(https://ai-cdn.lvwa18.com/img/rusticate/newbg.png) no-repeat","background-size":"100% 100%","height":"7.21rem"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"threePlates"},[_vm._m(3),_c('div',{staticClass:"threePlate",on:{"click":function($event){return _vm.selfHelp()}}},[_c('img',{staticClass:"plate",attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/plate1.png"}}),_vm._m(4)])])]),_c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"threePlates"},[_vm._m(5),_c('div',{staticClass:"threePlate",on:{"click":function($event){return _vm.legalAidListH()}}},[_c('img',{staticClass:"plate",attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/f_icon1.png"}}),_vm._m(6)]),_c('div',{staticClass:"threePlate",on:{"click":function($event){return _vm.mediateListH()}}},[_c('img',{staticClass:"plate",attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/f_icon2.png"}}),_vm._m(7)]),_c('div',{staticClass:"threePlate",on:{"click":function($event){return _vm.legalAid()}}},[_c('img',{staticClass:"plate",attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/f_icon7.png"}}),_vm._m(8)])])]),_c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"threePlates"},[_vm._m(9),_c('div',{staticClass:"threePlate",on:{"click":function($event){return _vm.marriage()}}},[_c('img',{staticClass:"plate",attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/f_icon6.png"}}),_vm._m(10)]),_c('div',{staticClass:"threePlate",on:{"click":function($event){return _vm.download()}}},[_c('img',{staticClass:"plate",attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/f_icon8.png"}}),_vm._m(11)]),_c('div',{staticClass:"threePlate",on:{"click":_vm.civilCode}},[_c('img',{staticClass:"plate",attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/f_icon5.png"}}),_vm._m(12)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headTransparent"},[_c('div',{staticClass:"framePortrait",attrs:{"bindtap":"myClikc"}},[_c('img',{staticClass:"userImg",attrs:{"src":"/img/logoGov.png"}})]),_c('div',{staticClass:"hi"},[_vm._v("Hi"),_c('span',[_vm._v(",A bear")])]),_c('div',{staticClass:"verticalLine"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","font-size":"0","font-weight":"700"}},[_c('img',{staticClass:"sifa",attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/sifa.png"}}),_c('div',{staticStyle:{"color":"#fff","font-size":"0.48rem"}},[_c('span',[_vm._v("智慧公共法律服务")])]),_c('div',{staticClass:"address"},[_c('span',[_vm._v("成都")]),_c('span',[_vm._v("武侯区")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lawyerInformation"},[_c('img',{staticClass:"lawyerImg",attrs:{"src":"/img/logoGov.png"}}),_c('div',{staticClass:"lawIformation"},[_c('div',{staticStyle:{"font-size":"0.36rem"}},[_vm._v("XXX律师 "),_c('div',{staticClass:"labelAdviser",staticStyle:{"background":"url(https://ai-cdn.lvwa18.com/img/rusticate/tagging.png) no-repeat center bottom","background-size":"100% 100%"}},[_c('img',{staticClass:"lvshi1",attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/lvshi1.png"}}),_vm._v(" 社区法律顾问 ")])]),_c('div',{staticStyle:{"font-size":"0.24rem","margin":"0 0 0.08rem"}},[_vm._v("1234567891011")]),_c('div',{staticStyle:{"font-size":"0.18rem"}},[_c('span',{staticStyle:{"margin-left":"0.05rem"}},[_vm._v("服务时间 9:00-18:00")])])]),_c('img',{staticClass:"dianhua",attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/dianhua.png","bindtap":"dial"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"0.3rem","font-weight":"600","padding-bottom":"0.10rem"}},[_c('img',{staticStyle:{"width":"1.07rem","height":"0.11rem"},attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png"}}),_vm._v(" 咨询服务 "),_c('img',{staticStyle:{"width":"1.07rem","height":"0.11rem"},attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("自助咨询")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"0.3rem","font-weight":"600","padding-bottom":"0.10rem"}},[_c('img',{staticStyle:{"width":"1.07rem","height":"0.11rem"},attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png"}}),_vm._v(" 咨询服务 "),_c('img',{staticStyle:{"width":"1.07rem","height":"0.11rem"},attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("法律援助")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("人民调解")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("办理公证")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"0.3rem","font-weight":"600","padding-bottom":"0.10rem"}},[_c('img',{staticStyle:{"width":"1.07rem","height":"0.11rem"},attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png"}}),_vm._v(" 查询服务 "),_c('img',{staticStyle:{"width":"1.07rem","height":"0.11rem"},attrs:{"src":"https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("模拟判决")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("法律文书")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("民法典")])])
}]

export { render, staticRenderFns }