<style scoped>
.header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
.mediateapply :deep(.ivu-date-picker){display: inline}
.mediateapply :deep(.ivu-radio-group){display: inline}
</style>

<template>
  <div style="background-color:#f8f9fb;" class="mediateapply">
    <div class="header"></div>
    <div style="padding:0.3rem;">
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
        <template >
          <FormItem label prop="applyaddress">
            <div class="inline-block">
              <span style="color: red;padding: 0 0.1rem;">*</span>选择地区：
            </div>
            <Cascader :data="addressData" v-model="addressValue" @on-change="addressSelect"></Cascader>
          </FormItem>
          <FormItem label="" prop="twhmc">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>调解委员会：</div>
            <Select v-model="formValidate.twhmc" placeholder="调解委员会" @on-change="onMediator">
              <Option :value="index" v-for="(value,index) in mediatorData">{{value.twhmc}}</Option>
            </Select>
          </FormItem>
          <FormItem label="" prop="ly">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>来源：</div>
            <Input v-model="formValidate.ly" placeholder="来源"></Input>
          </FormItem>
          <FormItem label="" prop="sqrxm">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人姓名：</div>
            <Input v-model="formValidate.sqrxm" placeholder="申请人姓名"></Input>
          </FormItem>
          <FormItem label="" prop="sqrxb">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人性别：</div>
            <div>
              <RadioGroup v-model="formValidate.sqrxb" @on-change="gender">
                <Radio label="1">男</Radio>
                <Radio label="2">女</Radio>
              </RadioGroup>
            </div>
          </FormItem>
          <FormItem label="" prop="sqrsfzh">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人身份证号：</div>
            <Input v-model="formValidate.sqrsfzh" placeholder="申请人身份证号"></Input>
          </FormItem>
          <FormItem label="" prop="sqrmz">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人民族：</div>
            <Select v-model="formValidate.sqrmz" placeholder="申请人民族" >
              <Option :value="value.EthnicityName" v-for="(value,index) in nation">{{value.EthnicityName}}</Option>
            </Select>
          </FormItem>
          <FormItem label="" prop="sqrcsrq">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人出生日期：</div>
            <DatePicker v-model="formValidate.sqrcsrq" type="date" placeholder="申请人出生日期" @on-change="choiceDate"></DatePicker>
          </FormItem>
          <FormItem label="" prop="sqrdz">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人地址：</div>
            <Input v-model="formValidate.sqrdz" placeholder="申请人地址"></Input>
          </FormItem>
          <FormItem label="" prop="sqrsjhm">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人手机号码：</div>
            <Input v-model="formValidate.sqrsjhm" placeholder="申请人手机号码"></Input>
          </FormItem>
          <FormItem label="" prop="bsqrxm">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>被申请人姓名：</div>
            <Input v-model="formValidate.bsqrxm" placeholder="被申请人姓名"></Input>
          </FormItem>
          <FormItem label="" prop="bsqrxb">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>被申请人性别：</div>
            <div>
              <RadioGroup v-model="formValidate.bsqrxb" @on-change="gender">
                <Radio label="1">男</Radio>
                <Radio label="2">女</Radio>
              </RadioGroup>
            </div>
          </FormItem>
          <FormItem label="" prop="bsqrsfzh">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>被申请人身份证号：</div>
            <Input v-model="formValidate.bsqrsfzh" placeholder="被申请人身份证号"></Input>
          </FormItem>
          <FormItem label="" prop="bsqrmz">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>被申请人民族：</div>
            <Select v-model="formValidate.bsqrmz" placeholder="被申请人民族" >
              <Option :value="value.EthnicityName" v-for="(value,index) in nation">{{value.EthnicityName}}</Option>
            </Select>
          </FormItem>
          <FormItem label="" prop="bsqrcsrq">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>被申请人出生日期：</div>
            <DatePicker v-model="formValidate.bsqrcsrq" type="date" placeholder="被申请人出生日期" @on-change="coverapplicantchoiceDate"></DatePicker>
          </FormItem>
          <FormItem label="" prop="bsqrdz">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>被申请人地址：</div>
            <Input v-model="formValidate.bsqrdz" placeholder="被申请人地址"></Input>
          </FormItem>
          <FormItem label="" prop="bsqrsjhm">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>被申请人手机号码：</div>
            <Input v-model="formValidate.bsqrsjhm" placeholder="被申请人手机号码"></Input>
          </FormItem>
          <FormItem label="" prop="jfjj">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>纠纷简介：</div>
            <Input v-model="formValidate.jfjj" placeholder="纠纷简介"></Input>
          </FormItem>
          <FormItem label="" prop="jflx">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>纠纷类型：</div>
            <Select v-model="formValidate.jflx" placeholder="纠纷类型" @on-change="onDispute">
              <Option :value="value.DisputeType" v-for="(value,index) in dispute">{{value.DisputeType}}</Option>
            </Select>
          </FormItem>
          <FormItem label="" prop="yysjq">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>预约时间起：</div>
            <DatePicker type="datetime" ref="producetime" v-model="formValidate.yysjq" placeholder="预约时间起" @on-change="startTime('producetime')"format="yyyy-MM-dd HH:mm:ss"></DatePicker>
          </FormItem>
          <FormItem label="" prop="yysjz">
            <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>预约时间止：</div>
            <DatePicker type="datetime" ref="endtime" v-model="formValidate.yysjz" placeholder="预约时间止" @on-change="endTime('endtime')"format="yyyy-MM-dd HH:mm:ss"></DatePicker>
          </FormItem>
        </template>
      </Form>
    </div>
    <div style="text-align: center;padding-bottom: 0.2rem">
      <Button type="primary" style="width:3rem;" @click="submit('formValidate')">提交</Button>
    </div>
    <Modal
            v-model="modal1"
            title=""
            @on-cancel="fanhui"
            ok-text="关闭"
            cancel-text="返回上一页">
      <p>申请成功</p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "mediateapply",
  data(){
    return {
      current : '0',
      nation : [],
      modal1 : false,
      mediatorData : [],
      dispute : [],
      formValidate :{
        ywlsh:'',
        ly:'',
        sqrxm:'',
        sqrxb:'',
        sqrsfzh:'',
        sqrmz:'',
        sqrcsrq:'',
        sqrdz:'',
        sqrsjhm:'',
        bsqrxm:'',
        bsqrxb:'',
        bsqrsfzh:'',
        bsqrmz:'',
        bsqrcsrq:'',
        bsqrdz:'',
        bsqrsjhm:'',
        jfjj:'',
        jflx:'',
        yysjq:'',
        yysjz:'',
        twhid:'',
      },
      addressData : [],
      addressValue : [],
      content : {
        thirdFlow:'',
        baseCode:'fwaj',
        data : []
      },
      AreaId : '',
      SupplementContent:{
        twhmc: '',
        fzr: '',
        jflx: '',
        lxdz :'',
      },
      ruleValidate : {
        // applyaddress : [{ required: true,trigger: 'blur' ,message:'选择地区'}],
        // ly : [{ required: true,trigger: 'blur' ,message:' '}],
        // sqrxm : [{ required: true,trigger: 'blur' ,message:' '}],
        // sqrxb : [{ required: true,trigger: 'blur' ,message:' '}],
        // sqrsfzh : [{ required: true,trigger: 'blur' ,message:' '}],
        // sqrmz : [{ required: true,trigger: 'change' ,message:' '}],
        // twhmc : [{ required: true,trigger: 'change' ,message:' '}],
        // sqrcsrq : [{ required: true,trigger: 'change' ,message:' ',type: 'date'}],
        // sqrdz : [{ required: true,trigger: 'blur' ,message:' '}],
        // sqrsjhm : [{ required: true,trigger: 'blur' ,message:' '}],
        // bsqrxm : [{ required: true,trigger: 'blur' ,message:' '}],
        // bsqrxb : [{ required: true,trigger: 'blur' ,message:' '}],
        // bsqrsfzh : [{ required: true,trigger: 'blur' ,message:' '}],
        // bsqrmz : [{ required: true,trigger: 'change' ,message:' '}],
        // bsqrcsrq : [{ required: true,trigger: 'change' ,message:' ',type: 'date'}],
        // bsqrdz : [{ required: true,trigger: 'blur' ,message:' '}],
        // bsqrsjhm : [{ required: true,trigger: 'blur' ,message:' '}],
        // jfjj : [{ required: true,trigger: 'blur' ,message:' '}],
        // jflx : [{ required: true,trigger: 'change' ,message:' '}],
        // yysjq : [{ required: true,trigger: 'change' ,type: 'date', message:' '}],
        // yysjz : [{ required: true,trigger: 'change' ,type: 'date', message:' '}],
        // twhid : [{ required: true,trigger: 'blur' ,message:' '}],
      },
    }
  },
  computed: {
    ruleValidate: function() {
      return this.$store.state.mediateData.ruleValidate;
    },
  },
  methods:{
    fanhui: function(){window.history.go(-1)},
    submit : function(name){
      var _this = this

      // _this.$refs[name].validate((valid) => {
      //   if (valid) {
      //     console.log(1)
      //   }
      // })
      // return
      _this.content.data = _this.formValidate
      console.log(_this.content.data)
      _this.send({
        url:'/CqMediate',
        data :{
          content:_this.content,
          UserId:window.sessionStorage.userId,
          AreaId:window.sessionStorage.AreaId,
          SupplementContent : _this.SupplementContent
        },
        success:function(res){
          console.log(res)
          _this.modal1 = true
        }
      })

      console.log(_this.content)
    },
    //申请人出生日期
    choiceDate : function (e){
      this.formValidate.sqrcsrq = e
    },
    //被申请人出生日期
    coverapplicantchoiceDate : function (e){
      this.formValidate.bsqrcsrq = e
    },
    //预约时间起
    startTime : function(refName){
      this.$refs[refName].onSelectionModeChange('time');
      var date = new Date(this.formValidate.yysjq);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':';
      var s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
      this.formValidate.yysjq = Y + M + D + h +  m + s
    },
    //预约时间止
    endTime : function(refName){
      this.$refs[refName].onSelectionModeChange('time');
      var date = new Date(this.formValidate.yysjz);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
      var h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
      var m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ':';
      var s = (date.getSeconds() < 10 ? '0'+(date.getSeconds()) : date.getSeconds());
      this.formValidate.yysjz = Y + M + D + h +  m + s
    },
    gender : function (e){
      console.log(e,this.formValidate.sqrmz)
      // this.formValidate.sqrxb = e
    },
    addressSelect : function (value, selectedData){
      this.AreaId = value[1]
      this.cqTwhlist(selectedData[1].AreaCode)
      console.log(value, selectedData)
    },
    ethnicityList : function(){
      var _this = this
      _this.send({
        url : '/EthnicityList',
        success: function (res) {
          _this.nation = res
        }
      })
    },
    cqAreaList:function(){
      var _this = this
      var data = []
      _this.send({
        url:'/CqAreaList',
        success:function(res){
          for(var i in res){
            data.push(res[i])
          }
          convertTree(data)
          function convertTree(tree) {
            const result = []
            tree.forEach((item) => {
              // 解构赋值
              let {
                AreaId: value,
                AreaName: label,
                AreaCode : AreaCode,
                list: children
              } = item
              if (children) {
                children = convertTree(children)
              }
              result.push({
                value,
                label,
                AreaCode,
                children
              })
            })
            _this.addressData = result
            console.log(_this.addressData)
            return result
          }
        }
      })
    },
    //
    onDispute : function(e){
      var _this = this
      _this.SupplementContent.jflx = _this.formValidate.jflx
    },
    onMediator : function(e){
      var _this = this
      _this.SupplementContent.twhmc = _this.mediatorData[e].twhmc
      _this.SupplementContent.fzr = _this.mediatorData[e].fzr
      _this.SupplementContent.jflx = _this.mediatorData[e].jflx
      _this.SupplementContent.lxdz = _this.mediatorData[e].lxdz
      _this.formValidate.twhid = _this.mediatorData[e].id
      console.log(_this.SupplementContent)
    },
    setPageSize:function() {
      var wsw = document.body.clientWidth
      document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
    },
    cqTwhlist:function(areaCode){
      var _this = this
      _this.send({
        url:'/CqTwhlist',
        data :{
          content:{
            limit: 200,
            page: 1,
            thirdFlow: "",
            baseCode:"twh",
            areaCode: areaCode
          },
        },
        success:function(res){
          _this.mediatorData = res
          console.log(res)
        }
      })
    },
    disputeType : function () {
      var _this = this
      _this.send({
        url:'/DisputeType',
        data :{
        },
        success:function(res){
          console.log(res)
          _this.dispute = res
        }
      })
    }
  },
  mounted() {
    var _this = this
    SYSCONFIG.server.default.host = 'cq.lvwa18.com'
    SYSCONFIG.server.default.port = '8001'
    _this.cqAreaList()
    _this.ethnicityList()
    _this.disputeType()
    _this.setPageSize()
  }
}
</script>

