<style scoped>
	.header{background-image: url(/img/legalAid/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
	.menu{font-size:0.24rem;padding:0.3rem 0.3rem;}
	.list{padding:0.2rem 0;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<Modal
			v-model="detialPanel.state"
			title="申请详情"
		>
			<template v-if="detialPanel.data.Content && CityId == '2278'">
				<div>姓名：{{detialPanel.data.Content.zxr}}</div>
				<div>性别：{{detialPanel.data.Content.xb}}</div>
				<div>身份证号码：{{detialPanel.data.Content.zjhm}}</div>
				<div>民族：{{detialPanel.data.Content.mz}}</div>
				<div>出生日期：{{detialPanel.data.Content.csrq}}</div>
				<div>年龄：{{detialPanel.data.Content.nl}}</div>
				<div>住所地址：{{detialPanel.data.Content.dz}}</div>
				<div>联系电话：{{detialPanel.data.Content.lxdh}}</div>
				<Divider dashed style="margin:0.3rem 0;"/>
				<div>处理进度：{{detialPanel.result.zt1}}</div>
				<Divider dashed style="margin:0.3rem 0;"/>
				<Button type="primary" @click="result();modal3 = true">结果查询</Button>
        <Divider dashed style="margin:0.3rem 0;"/>
			</template>
      <template >
        <div>平台回复：
          <span v-if="detialPanel.data.PlatformApply">{{detialPanel.data.PlatformApply}}</span>
          <span v-else>未回复</span>
        </div>
      </template>
		</Modal>
		<!---->
		<Modal
				v-model="modal3"
				title="结果查询"
				ok-text="关闭"
				cancel-text=""
		>
			<div class="details">
				<div class="consultation">
					<div style="padding: 20rpx;">
						<div v-if="resultReply.zt1">
							<div>状态：{{resultReply.zt1}}</div>
							<div>答复意见：{{resultReply.dfyj}}</div>
							<div>答复日期：{{resultReply.jdrq}}</div>
						</div>
						<div v-if="resultReply.zt2">
							<div>受理审查日期：{{resultReply.slscrq}}</div>
							<div>受理审查单位：{{resultReply.slscdw}}</div>
						</div>
						<div v-if="resultReply.zt3">
							<div>立案日期：{{resultReply.larq}}</div>
							<div>案件编号：{{resultReply.ah}}</div>
						</div>
						<div v-if="resultReply.zt4">
							<div>案件指派：{{resultReply.zprq}}</div>
							<div>办理单位：{{resultReply.cbdw}}</div>
							<div>承办律师{{resultReply.cbr}}</div>
						</div>
						<div v-if="resultReply.zt5">结案日期：{{resultReply.jarq}}</div>
					</div>
				</div>
			</div>
		</Modal>
		<!---->
		<div class='header'></div>
		<div class='body'>
			<div class="menu">
				<Row>
					<Col span="12" style="font-size:0.3rem;font-weight: bold;"><Icon type="ios-list" style="font-size:0.5rem;"/>申请列表</Col>
				</Row>
			</div>
			<ul v-if="list.length>0">
				<li v-for="(item,index) in list" :style="index%2==0?'background-color: #fff;':''" class='list'>
					<Row type="flex" align="middle" style="font-size:0.2rem;padding:0.1rem 0.3rem;">
						<Col span="20">
							<Row>
								<Col span="24" style="font-size: 0.28rem;font-weight: bold;padding-bottom:0.1rem">{{item.Content.zxsxlxzx}}</Col>
								<Col span="24" style="color:#666666">
									申请人：{{item.Content.zxr}} | 申请时间：{{item.CreateTime}}
								</Col>
							</Row>
						</Col>
						<Col span="4" style="color:#008fd6"><span @click="getDetail(item)">查看详情 ></span></Col>
					</Row>
				</li>
			</ul>
			<div v-else style="background-image:url(/img/legalAid/empty.png);height:1.95rem;width:6.0rem;background-size:100% 100%;margin:0.3rem auto 0;">
				<p style="color: #ababab;text-align: center;padding-top: 1.2rem;font-size: 0.30rem;">未申请</p>
			</div>
			<div style="padding: 30px;font-size: 12px;line-height: 20px;" v-if="pid == 2295">
				<div style="text-align: center;font-size: 16px;font-weight: bold;margin: 10px;">都江堰市法律援助办事指南</div>
				<div style="text-indent: 2em;">法律援助是指由政府设立的法律援助机构组织法律援助人员，为经济困难或特殊案件的人给予无偿提供法律服务的一项法律保障制度。</div>
				<div style="text-indent: 2em;">都江堰市法律援助中心以扶助困难群众为己任，以提供良好的法律服务为目的，认真体现党和政府对困难群众的深切关怀。组织律师、公证员、基层法律服务工作者积极为残疾人、老人、妇女、儿童和农民工等各类困难群体提供法律援助。 同时，为方便群众办理法律援助申请， 我局还在市公安、检察院、法院、劳动仲裁院等部门以及11个乡镇(街道办）依托司法所设立了法律援助工作站。</div>
				<div style="text-indent: 2em;">都江堰市法律援助咨询热线 ：028-87200148</div>
				<div style="text-indent: 2em;">成都市法律援助24小时咨询热线：12348</div>
				<div>一、法律援助对象</div>
				<div>向法律援助机构申请法律援助，应当符合以下条件：</div>
				<div>（一）符合规定的经济困难标准；</div>
				<div>（二）申请事项依法在本行政区域审理或者处理；</div>
				<div>（三）因维护自身合法权益需要无偿法律服务。</div>
				<div>经济困难标准：按照申请人家庭成员人均收入不足住所地或者受理法律援助申请地最低工资标准确定。</div>
				<div>二、申请人有下列情形之一的，免于经济困难审查：</div>
				<div>（一）重点优抚对象（享受抚恤和生活补助的人员）；</div>
				<div>（二）纳入享受最低生活保障待遇范围的城乡低保对象、城乡特困人员、孤儿；</div>
				<div>（三）在社会福利机构由政府供养的或者在救助管理机构接受救助的；</div>
				<div>（四）无固定生活来源的残疾人、老年人、患有严重疾病的人；</div>
				<div>（五）在本市行政区域内务工的农民因请求支付劳动报酬、经济补偿或者工伤赔偿的；</div>
				<div>（六）因遭受自然灾害或者其他不可抗力正在接受社会救济的；</div>
				<div>（七）申请的法律援助事项已经获得公安机关、人民检察院、人民法院司法救助的；</div>
				<div>（八）因见义勇为行为、实施志愿服务行为或者为保护社会公共利益致使其合法权益受到损害请求赔偿或者补偿的；</div>
				<div>（九）义务兵、供给制学员以及军属，执行作战、重大非战争军事行动任务的军人以及军属，烈士、因公牺牲或者病故的军人、人民警察、消防救援人员的遗属；军队中的文职人员、非现役公勤人员、在编职工，由军队管理的离退休人员，以及执行军事任务的预备役人员和其他人员，参照军人条件执行；</div>
				<div>（十）法律法规以及国家政策规定的其他情形。</div>
				<div>三、法律援助的形式</div>
				<div>(一)法律咨询、代拟法律文书;</div>
				<div>(二)刑事辩护、刑事代理、刑事法律帮助;</div>
				<div>(三)民事、行政诉讼代理;</div>
				<div>(四)非诉讼法律事务代理;</div>
				<div>(五) 已经受理的法律援助案件需要进行的公证、司法鉴定；</div>
				<div>(六) 法律法规规定的其他形式。</div>
				<div>四、申请法律援助应当提交下列材料：</div>
				<div>（一）身份证或者其他有效身份证明，如委托他人代理的，代理人还应当提交有代理权的证明；</div>
				<div>（二）经济困难证明或者能够证明其经济困难的其他材料；</div>
				<div>（公民申请法律援助需要提交的经济困难证明，由其户籍所在地或者经常居住地的街道办事处、镇人民政府出具，或者由申请人采取书面形式作出承诺。经济困难证明应当如实载明申请人家庭成员、就业状况、家庭人均收入等情况。）</div>
				<div>(三)与申请事项相关的案件材料;</div>
			</div>
			<div  v-else-if="pid == 1731" style="padding: 30px;font-size: 12px;line-height: 20px;">
				<div style="text-align: center;font-size: 16px;font-weight: bold;margin: 10px;">法律援助</div>
				<div style="text-indent: 2em;">法律援助是政府的职责，是政府设立的为经济困难公民或特殊案件的当事人提供法律援助，依法保障确有经济困难的弱势群体获得必要法律服务，维护公民的合法权益的法律保障制度。</div>
				<div style="text-indent: 2em;">公民只有符合法定的条件，才能获得法律援助。提出法律援助申请必须符合两个基本条件：一是属于可以申请的法律援助事项范围，即案件必须符合规定的条件；二是因经济困难无力支付法律服务费用，即申请人必须符合经济困难的条件。另外，一些特殊案件的当事人，主要是刑事案件的犯罪嫌疑人和被告人，可以不经申请，由人民法院直接指定获得法律援助。 </div>
				 <div style="text-indent: 2em;">谷城县法律援助中心主要负责接待、解答来电、来访法律咨询，办理民事、行政诉讼代理，刑事辩护，非诉讼法律事务。负责受理公民申请的法律援助案件和人民法院指定的刑事法律援助案件，组织、协调、指派律师和其它法律工作者承办法律援助案件。掌握法律援助工作中涉及的社情民意，为党委、政府决策提供依据。办理或协调处理其他法律援助事务。</div>
				<div>法律援助咨询热线：0710-7335148</div>
			</div>
      <div v-else  style="padding: 0.2rem 0.3rem;font-size: 0.22rem;line-height: 0.36rem;">
        <div style="text-align: center;font-size: 16px;font-weight: bold;margin: 10px;">法律援助在线办理指南</div>
        <div>一、选择所在地</div>
        <div>（一）选择当前地区</div>
        <div>二、填写基本资料</div>
        <div>（一）上传身份证件照片，通过OCR识别系统自动填充信息</div>
        <div>（二）请选择需要申请法律援助的事项类别</div>
        <div>（三）输入您的联系电话</div>
        <div>三、事项描述</div>
        <div>（一）请简要概述您所申请法律援助事项的案情及理由</div>
      </div>
<!--     电话 -->
      <div style="padding: 0 0.3rem;font-size: 0.22rem;line-height: 0.36rem;" v-if="hotlineData != ''">
        <div style="text-align: center;font-size: 16px;font-weight: bold;margin: 10px;">法律援助热线电话咨询</div>
        <div>
          <div style="display: inline-block">
            <div>地址：{{hotlineData.address}}</div>
            <div>联系电话：{{hotlineData.phone}}</div>
          </div>
          <div style="float: right;display:inline-block;width: 1.80rem;height: 0.64rem;background: linear-gradient(90deg, #64C3FF, #3391FE);box-shadow: 0px 0.04rem 0.06rem 0px rgba(53,148,255,0.26);border-radius: 0.32rem;">
            <img style="width:0.32rem;height:0.32rem;margin:0.16rem 0.10rem 0.16rem 0.18rem;vertical-align: top;" src="https://ai-cdn.lvwa18.com/img/rusticate/notarizationDH.png"/>
            <span style="font-size: 0.26rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.64rem;" @click="dialTelephone(hotlineData.phone)" >一键拨打</span>
          </div>
        </div>
      </div>
<!--      去申请-->
      <div style="height: 0.8rem"></div>
      <Row>
        <router-link :to="{name:'legalAidStep1'}"><Col span="24" style="text-align: center;background-color:#008fd6;height: 0.8rem;font-size: 0.26rem;line-height: 0.8rem;position: fixed;bottom: 0;width: 100%;">
          <span style="color: #FFF;">线上办理</span>
        </Col></router-link>
      </Row>
		</div>
	</div>
</template>

<script>
	export default{
		name:'legalAidListH',
		data(){
			return{
				list:[],
				FlyzApplyId : '',
				modal3 : false,
				resultReply : [],
				detialPanel:{
					state:false,
					data:{},
					result:{},
				},
                pid : 0,
        hotlineData: [],
        CityId: ''
			}
		},
		mounted(){
			var _this = this
			_this.setPageSize()
			if(window.sessionStorage.userId == undefined){
				if( _this.$route.query.id){
					window.sessionStorage.userId = _this.$route.query.id
					_this.getList()
					window.sessionStorage.AreaId = _this.$route.query.AreaId
					window.sessionStorage.pid = _this.$route.query.pid
          window.sessionStorage.CityId = _this.$route.query.CityId
          window.sessionStorage.CityName = _this.$route.query.CityName
					_this.pid =window.sessionStorage.pid
					console.log(window.sessionStorage,'AAA')
				}else{
					_this.$Modal.error({
						title:'系统通知',
						content:'未获取到用户信息！请重新进入！',
					})
				}
			}else{
			  _this.getList()
				if( _this.$route.query.id){
					window.sessionStorage.userId = _this.$route.query.id
					window.sessionStorage.AreaId = _this.$route.query.AreaId
					window.sessionStorage.pid = _this.$route.query.pid
          window.sessionStorage.CityId = _this.$route.query.CityId
          window.sessionStorage.CityName = _this.$route.query.CityName
					_this.pid =window.sessionStorage.pid
				}
      }
      if(_this.$route.query.CityName){
        this.aidInfo()
      }
      this.CityId = window.sessionStorage.CityId
		},
		methods:{
      aidInfo: function (){
        var _this = this
        _this.send({
          url : '/aidInfo',
          data: {
            province : window.sessionStorage.CityName.split("-")[0],
            city : window.sessionStorage.CityName.split("-")[1],
            area : window.sessionStorage.CityName.split("-")[2],
          },
          success: function (res) {
            if(res){
              _this.hotlineData = res
            }
          }
        })
      },
			result : function(){
				var _this = this
				_this.send({
					url : '/flyzApply_resAdmin',
					data: {
						FlyzApplyId : _this.FlyzApplyId,
						UserId : window.sessionStorage.userId,
					},
					success: function (res) {
						if(res.code != 500){
							_this.resultReply = res.data
						}else{
							_this.resultReply = []
						}

					}
				})
			},
			getList:function(){
				var _this = this
				_this.send({
          consulting: true,
					url:'/flyzApplyList',
					data:{
						UserId:window.sessionStorage.userId,
					},
					success:function(res){
            if(res){
              _this.list = res.List
            }
					}
				})
			},
			getDetail:function(item){
				var _this = this
        if(window.sessionStorage.CityId == 2278){
          _this.FlyzApplyId = item.FlyzApplyId
          _this.send({
            url:'/flyzApply_resAdmin',
            data:{
              UserId :window.sessionStorage.userId,
              FlyzApplyId :item.FlyzApplyId,
            },
            success:function(res){
              _this.detialPanel.state = true
              _this.detialPanel.data = item
              _this.detialPanel.result = res.data
            }
          })
        }else {
          _this.detialPanel.state = true
          _this.detialPanel.data = item
          _this.detialPanel.result = item
        }

			},
			setPageSize:function() {
			    var wsw = document.body.clientWidth
			    document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
			},
    //   拨打电话
      dialTelephone: function (phoneNumber){
        window.location.href = 'tel:' + phoneNumber;
      },
		},
	}
</script>
