<style scoped>
    .home{padding:20px;position: relative;}
    .archive-title-icon{display: inline-block;width:8px;border-radius:4px;background-color: #2d8cf0;height:22px;vertical-align:middle;}
    .archive-title{vertical-align:middle;font-size:16px;margin-left:10px;color:#626775;font-weight:bold;}
    .archive-statistics{vertical-align: middle;font-size:15px;display:inline-block;margin-left:40px;color:#2d8cf0;}
    .statistics-num{color:#fc2c2d;margin:0 5px;}
    .filter>div{width:136px;margin-right:10px;display:inline-block;}
    .filter>div>div{margin-bottom:2px;}
    .spin-icon-load{animation: ani-demo-spin 1s linear infinite;}
    .hide-text{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
    .forMtitle1{background-color: #f9f9f9;height:40px;text-align: center;}
    .forMtitle1 p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height:40px;overflow: hidden;white-space: nowrap;}
    .forMcontent{color: #666666;font-size:14px;padding:5px 0;border-bottom: 1px solid #dddddd;text-align: center;}
    .forMcontent p {display: inline-block;width: 20%;overflow: hidden; vertical-align:middle;white-space: nowrap;padding:10px 0;}
    .operation-btn{line-height:30px;cursor: pointer;transition:0.5s;}
    .operation-btn:hover{background-color:#efefef;}

</style>
<template>
  <div class="home">
    <div style="display: flex;justify-content: space-between;align-items:center;">
      <div>
        <div class="archive-title-icon"></div>
        <span class="archive-title">服务档案</span>
        <div class="archive-statistics">
          总上传<span class="statistics-num">{{archiveStatistics.total}}</span>条<Divider type="vertical"/>本月上传<span class="statistics-num">{{archiveStatistics.month }}</span>条<Divider type="vertical"/>今日上传<span class="statistics-num">{{archiveStatistics.today}}</span>条
        </div>
        <div style="vertical-align: middle;font-size:15px;display:inline-block;margin-left:40px;color:#626775;">
          <span>本月服务时长<span class="statistics-num">{{ parseInt(serviceDuration.month / 60) }}</span>小时<span class="statistics-num">{{ serviceDuration.month % 60 }}</span>分钟</span><Divider type="vertical"/><span>总服务时长<span class="statistics-num">{{ parseInt(serviceDuration.total / 60) }}</span>小时</span>
        </div>
      </div>
      <div style="float: right;">
        <Button @click="submitCheckActive()" type="primary">批量审核</Button>
        <span style="margin-left:5px;"></span>
        <Button type="success" @click="clockDownload" style="background-color:#16c1a3">批量导出</Button>
      </div>
    </div>
    <Divider style="margin:15px 0 10px 0;"/>
    <!-- 筛选 Start -->
    <div class="filter">
      <div>
        <div>区域范围：</div>
        <Select v-model="filter.areaRange.value" placeholder="请选择区域" clearable>
          <Option v-for="(item, index) in filter.areaRange.list" :value="item.AdminId" :label="item.AdminName">
            {{ item.AdminName }}</Option>
        </Select>
      </div>
      <div>
        <div>审核状态：</div>
        <Select clearable v-model="filter.approvalStatus.value" placeholder="请选择审核状态">
          <Option v-for="item in filter.approvalStatus.list" :value="item.value" :label="item.name"></Option>
        </Select>
      </div>
      <div>
        <div>考核分类：</div>
        <Cascader :data="filter.examinationType.list" v-model="filter.examinationType.value" placeholder="请选择考核分类" clearable></Cascader>
      </div>
      <div>
        <div>法律顾问：</div>
        <Select placeholder="请选择法律顾问" clearable filterable @on-select="lawyerSelect" @on-clear="lawyerCancellation">
          <Option v-for="item in filter.lawyer.list" :value="item.LawyerId">{{ item.LawyerName }}</Option>
        </Select>
      </div>
      <div>
        <div>服务方式：</div>
        <Select v-model="filter.serviceType.value" placeholder="请选择服务方式" clearable filterable>
          <Option v-for="item in filter.serviceType.list" :value="item.ServiceTypeId" :label="item.ServiceTypeName"></Option>
        </Select>
      </div>
      <div>
        <div>所属社区：</div>
        <Select v-model="filter.community.value" remote clearable filterable :remote-method="getCommunityList" not-found-text="暂无此社区" placeholder="搜索社区">
          <Option v-for="item in filter.community.list" :value="item.VillagesId" :key="item.VillagesId">{{item.VillagesName}}</Option>
        </Select>
      </div>
      <div>
        <div>村(社区)数量：</div>
        <Select v-model="filter.communityNumber.value" clearable>
          <Option v-for="item in filter.communityNumber.list" :value="item.value" :key="item.value">{{item.name}}</Option>
        </Select>
      </div>
      <div style="width: auto;">
        <div>
          <RadioGroup v-model="filter.TimeType">
            <Radio label="">
              <span>上传时间：</span>
            </Radio>
            <Radio label="service">
              <span>服务时间：</span>
            </Radio>
          </RadioGroup>
        </div>
        <div style="display: inline-block;">
          <RadioGroup type="button" v-model="filter.serviceDate.identification">
            <Radio label="m">本月</Radio>
            <Radio label="q">本季</Radio>
            <Radio label="y">本年</Radio>
            <Radio label="a">所有</Radio>
            <Radio label="custom">自定义</Radio>
          </RadioGroup>
          <DatePicker
            v-if="filter.serviceDate.identification == 'custom'"
            @on-change="filter.serviceDate.startTime = $event[0], filter.serviceDate.endTime = $event[1]"
            @on-ok="getArchiveList()" @on-clear="filter.serviceDate.identification='m'"
            type="datetimerange"
            format="yyyy-MM-dd" placement="bottom-end"
            placeholder="请选择时间区间" style="margin-left:10px;">
          </DatePicker>
        </div>
      </div>
      <Button @click="exportLawyerData = ''; LawyerName = ''; endTime = ''; submit('选择')" type="primary" style="vertical-align: middle;">筛选导出</Button>
    </div>
    <div style="margin:10px 0;"></div>
    <!-- 筛选 End -->
    <Row style="text-align: center;">
      <Col span="24">
        <Row style="background-color:#edeef0;min-height: 46px;" align="middle">
          <Col span="1"><Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">序号</Checkbox></Col>
          <Col span="3">所属村(社区)</Col>
          <Col span="2">村居法律顾问</Col>
          <Col span="2">用户</Col>
          <Col span="2">服务方式</Col>
          <Col span="2">服务时间</Col>
          <Col span="2">服务时长</Col>
          <Col span="2">服务类型</Col>
          <Col span="2">评价</Col>
          <Col span="2">上传时间</Col>
          <Col span="2">审核状态</Col>
          <Col span="2">操作</Col>
        </Row>
      </Col>
      <Col span="24" style="min-height:500px;">
        <Row v-for="(item, index) in archiveList" :style="{backgroundColor:index%2!=0?'#f5f6f7':'',minHeight:'46px'}" align="middle">
          <Col span="1">
            <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange" class="articel">
              <Checkbox :label="item.FileId + ',' + item.IsCheck">
                <span v-if="(pageBar.current - 1) * pageBar.size + index + 1 < 10">0{{ (pageBar.current - 1) * pageBar.size + index + 1 }}</span>
                <span v-else>{{ (pageBar.current - 1) * pageBar.size + index + 1 }}</span>
              </Checkbox>
            </CheckboxGroup>
          </Col><!-- 序号 -->
<!--          <Col span="3" class="hide-text" :title="item.CityName+item.AdminName+item.VillagesName">{{ item.CityName.replace('河南省驻马店市', '') }}{{ item.AdminName }}{{ item.VillagesName }}</Col>&lt;!&ndash; 所属村(社区) &ndash;&gt;-->
          <Col span="3" class="hide-text" :title="item.CityName.replace('河南省驻马店市', '')+item.AdminName+item.communityName">
            {{ item.CityName.replace('河南省驻马店市', '') }}{{ item.AdminName }}<br v-if="item.villagesNameArr.length > 1"/>{{item.communityName}}
          </Col><!-- 所属村(社区) -->
          <Col span="2">{{ item.LawyerName }}</Col><!-- 村居法律顾问 -->
          <Col span="2">{{ item.ServiceLinkman || '--' }}</Col><!-- 用户 -->
          <Col span="2" v-if="location=='default'">{{ item.DailyType ? item.DailyType.toString() : '--' }}</Col><!-- 服务方式 -->
          <Col span="2" v-if="location=='chong-qing'">{{ item.ServiceTypeName ? item.ServiceTypeName : '--' }}</Col><!-- 服务方式 -->
          <Col span="2">{{ timestampToDate(item.ServiceTime,'date') || '暂无' }}</Col><!-- 服务时间 -->
          <Col span="2">{{ item.ServiceLong?item.ServiceLong+'小时':'' }}{{ item.ServiceMins }}分钟</Col><!-- 服务时长 -->
          <Col span="2" class="hide-text" :title="item.title+'-'+item.pTitle">{{ item.title }}-{{ item.pTitle }}</Col><!-- 服务类型 -->
          <Col span="2"><!-- 评价 -->
            <span v-if="item.OrderGrade == 5">非常满意</span>
            <span v-if="item.OrderGrade == 4">满意</span>
            <span v-if="item.OrderGrade == 3">基本满意</span>
            <span v-if="item.OrderGrade == 2">不满意</span>
            <span v-if="item.OrderGrade == 1">非常不满意</span>
            <span v-if="!item.OrderGrade">--</span>
          </Col>
          <Col span="2">{{ timestampToDate(item.FileTime,'date') || '暂无' }}</Col><!-- 上传时间 -->
          <Col span="2"><!-- 审核状态 -->
            <template v-if="item.IsCheck == 0"><Tag color="orange">未审核</Tag></template>
            <template v-if="item.IsCheck == 1"><Tag color="green">审核通过</Tag></template>
            <template v-if="item.IsCheck == 2"><Tag color="red">已驳回</Tag></template>
          </Col>
          <Col span="2">
            <span @click="getArchiveDetail(item.FileStatsId)" style="cursor: pointer;color:#2d8cf0;">查看/审核</span>
            <span style="padding:3px;"></span>
            <Dropdown>
              <span style="cursor: pointer;">操作</span>
              <Icon type="ios-arrow-down"></Icon>
              <DropdownMenu slot="list">
                <div @click="submit(item.FileId)" class="operation-btn">导出</div>
                <div @click="exportLawyerData = ''; LawyerName = ''; endTime = ''; submit(item.FileId, '1')" class="operation-btn">打印</div>
                <div @click="deleteClick(item.FileId)" class="operation-btn" style="color: red;">删除</div>
              </DropdownMenu>
            </Dropdown>
          </Col><!-- 操作 -->
        </Row>
        <Spin fix v-if="listLoading">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>加载中</div>
        </Spin>
      </Col>
    </Row>
    <div style="height:50px;"></div>
    <div style="text-align: center;position:absolute;bottom:20px;width:calc(100% - 40px);">
      <Page
      :total="pageBar.total"
      :page-size="pageBar.size"
      :page-size-opts="pageBar.sizeList"
      :current="pageBar.current"
      :disabled="listLoading"
      show-elevator
      show-sizer
      show-total
      @on-change="pageBar.current = $event;getArchiveList()"
      @on-page-size-change="pageBar.size = $event"/>
    </div>

    <!--    导出-->
    <Modal v-model="showExport" title="导出" @on-ok="submit" @on-cancel="onCancel" ok-text="导出" style="height: 300px">
      <DatePicker :value="timeData" v-model="timeData" :transfer="transfer" type="daterange" placement="bottom-end"
        placeholder="选择时间" style="width: 200px" @on-change="totalTime"></DatePicker>
<!--      <Select style="width:200px;margin-left: 20px" @on-change="exportLawyerName" placeholder="全部律师"-->
<!--        clearable filterable>-->
<!--        <Option v-for="item in lawyerData" :value="item.LawyerId" :key="'L' + item.LawyerId">-->
<!--          {{ item.LawyerName }}</Option>-->
<!--      </Select>-->
    </Modal>
    <detail :archiveDetail="archiveDetail"></detail>
  </div>
</template>

<script>
import detail from './components/detail-modal'
export default {
  name: "archivesList",
  data() {
    return {
      archiveDetail:{//档案详情
        state:false,
        data:{}
      },
      archiveList:[],
      listLoading:false,
      serviceDuration:{//服务时长
        month:0,
        total:0,
      },
      archiveStatistics:{//档案统计
        today:0,
        month:0,
        total:0,
      },
      filter:{//搜索筛选
        community:{value:'',list:[],},//社区
        communityNumber:{value:'',list:[
            {value:1,name: '多个村(社区)'},
            {value:2,name: '单个村(社区)'},
          ],},//社区数
        lawyer:{value:'',list:[]},//律师
        examinationType:{value:[],list:[],},//考核分类
        serviceType:{value:'',list:[],},//服务类型
        approvalStatus:{value:'',list:[
          {value:0,name: '未审核'},
          {value:1,name: '审核通过'},
          {value:2,name: '已驳回'},
        ]},//审核状态
        areaRange:{value:'',list:[],},//区域范围
        serviceDate:{identification: 'y',startTime: '',endTime: '',},//服务时间
        TimeType : '',
      },
      pageBar:{//分页
        total:0,
        size:15,
        sizeList:[10,15,30],
        current:1,
      },
      OrderGrade: '',
      villagesList: [],
      ElectronicsList: [],
      fileId: '',
      lawyerName: '',
      page: 1,
      pageSize: 10,
      count: 0,
      fileType: '',
      lawyerData: [],
      AreaId: '',
      evaluationTypeId: '',
      showExport: false,
      timeData: '',
      transfer: true,
      startTime: '',//开始时间
      endTime: '',
      exportLawyerData: '',
      checkAllGroup: [],
      indeterminate: true,
      checkAll: false,
      checkAllGroupPage: [],
      options2: [],
    }
  },
  components:{
    detail
  },
  watch: {
    'filter.community.value': function (val) {this.pageBar.current=1;this.getArchiveList()},
    'filter.communityNumber.value': function (val) {this.pageBar.current=1;this.getArchiveList()},
    // 'filter.lawyer.value': function (val) {this.pageBar.current=1;this.getArchiveList()},
    'filter.examinationType.value': function (val) {this.pageBar.current=1;this.getArchiveList()},
    'filter.serviceType.value': function (val) {this.pageBar.current=1;this.getArchiveList()},
    'filter.approvalStatus.value': function (val) {this.pageBar.current=1;this.getArchiveList()},
    'filter.areaRange.value': function (val) {this.pageBar.current=1;this.getArchiveList()},
    'filter.TimeType': function (val) {this.pageBar.current=1;this.getArchiveList()},
    'filter.serviceDate.identification': function (val) {
      if(val!='custom'){
        this.pageBar.current=1;this.filter.serviceDate.startTime='';this.filter.serviceDate.endTime='';this.getArchiveList()
      }
    },
    // 'pageBar.current':function (val) {this.getArchiveList()},
    'pageBar.size':function (val) {this.pageBar.current=1;this.getArchiveList()},
  },
  mounted() {
    let _this = this
    if(window.sessionStorage.AdminId == 166){
      this.filter.approvalStatus.value = 1
    }
    _this.getArchiveStatistics()
    _this.getServiceDuration()
    _this.getAdminList()
    _this.getServiceType()
    _this.getExaminationType()
    _this.getArchiveList()
    _this.getLawyerList()
  },
  computed:{
    //获取当前项目地区
    location:function(){
      return SYSCONFIG.location
    }
  },
  methods: {
    //获取服务时长
    getServiceDuration:function(){
      let _this = this
      //本月服务时长
      _this.send({
        url: '/fileTotalMonth',
        success: (res) => {
          _this.serviceDuration.month = res
        }
      })
      //总服务时长
      _this.send({
        url: '/fileTotal',
        success: function (res) {
          _this.serviceDuration.total = res
        }
      })
    },
    //获取档案统计
    getArchiveStatistics:function(){
      let _this = this
      //本月数据
      _this.send({
        url: '/MouthClock',
        success: (res) => {
          _this.archiveStatistics.month = res.FilesCount
          //ClockNum 值班打卡人次
          //FilesCount 本月电子档案数量 取
          //ServiceTime 本月值班时长
        }
      })
      _this.send({
        url: '/GetfileDay',
        success: function (res) {
          //day 今日上传档案数
          //total 总上传档案数
          _this.archiveStatistics.today = res.day
          _this.archiveStatistics.total = res.total
        }
      })
    },
    //获取区域列表
    getAdminList:function(){
      let _this = this
      _this.send({
        url: '/FindBottomVillagesAndAdmin_',
        data:{
          AdminId: window.sessionStorage.AdminId,
        },
        success: function (res) {
          _this.filter.areaRange.list = []
          _this.filter.areaRange.list = res.Admin
        }
      })
    },
    //获取服务方式
    getServiceType:function(){
      var _this = this
      if(SYSCONFIG.location == 'chong-qing'){
        _this.send({
          url: '/ServiceSerType',
          success: function (res) {
            _this.filter.serviceType.list = res
          }
        })
      }else {
        _this.send({
          url: '/dailyType',
          success: function (res) {
            for(var i in res){
              _this.filter.serviceType.list.push({
                ServiceTypeId : res[i].DailyId,
                ServiceTypeName : res[i].DailyType
              })
            }
          }
        })
      }


    },
    //获取考核分类
    getExaminationType: function () {
      let _this = this
      _this.send({
        url: '/EvaluationTypeList',
        data:{ AdminId: window.sessionStorage.AdminId},
        success: function (res) {
          _this.filter.examinationType.list = convertTree(res)
          function convertTree(tree) {
            let result = []
            tree.forEach((item) => {
              // 解构赋值
              let {
                EvaluationTypeId: value,
                EvaluationTitle: label,
                Unit: Unit,
                list: children
              } = item
              if (children) {
                children = convertTree(children)
              } else {
                children = []
              }
              result.push({
                value,
                label,
                Unit,
                children
              })
            })
            return result
          }
        }
      })
    },
    //获取律师列表
    getLawyerList:function(){
      let _this = this
      _this.send({
        url: '/LawyerList',
        success: function (res) {
          _this.filter.lawyer.list = res.list
        }
      })
    },
    //获取社区列表
    getCommunityList:function(query){
      let _this = this
      _this.send({
        url: '/villagesList',
        data: {
          AdminId: _this.filter.areaRange.value||window.sessionStorage.AdminId,
          Key: query,
        },
        success: function (res) {
          _this.filter.community.list = res.list
        }
      })
    },
    serviceIF : function (){
      return SYSCONFIG.location == 'chong-qing'?{ServiceTypeId: this.filter.serviceType.value} : {DailyId: this.filter.serviceType.value}
    },
    //获取数据列表
    getArchiveList:function(){
      var _this = this
      _this.archiveList = []
      var _data = {
        AdminFind: _this.filter.areaRange.value,
        VillagesId: _this.filter.community.value,
        villages: _this.filter.communityNumber.value,
        AdminId: window.sessionStorage.AdminId,
        FileTypeId: _this.fileType,
        EvaluationTypeId: _this.filter.examinationType.value.at(-1),
        IsCheck:_this.filter.approvalStatus.value,
        LawyerId: _this.filter.lawyer.value,
        StartTime: _this.filter.serviceDate.startTime,
        EndTime: _this.filter.serviceDate.endTime,
        DateType: _this.filter.serviceDate.identification,
        page: _this.pageBar.current,
        pageSize: _this.pageBar.size,
        TimeType : _this.filter.TimeType
      }
      _this.listLoading = true
      _this.send({
        consulting: true,
        url:'/EleFileList',
        data: Object.assign(_this.serviceIF(),_data),
        success: function (res) {
          // if(res.Count){_this.archiveStatistics.total = res.Count||0}
          _this.pageBar.total = res.Count||0
          for(var i in res.List){
            res.List[i].communityName = ''
            for (var index in res.List[i].villagesNameArr){
              res.List[i].communityName += res.List[i].villagesNameArr[index].VillagesName
              if (index < res.List[i].villagesNameArr.length - 1) {
                res.List[i].communityName += '、';
              }
            }
          }
          _this.archiveList = res.List
          console.log(_this.archiveList)
          _this.checkAllGroup = _this.checkAllGroupPage[_this.pageBar.current - 1] || []
          _this.listLoading = false
        }
      })
    },
    //获取档案详情
    getArchiveDetail:function(archiveId){
      var _this = this
      _this.send({
        url: '/eleFileView',
        data: {
          FileStatsId: archiveId,
        },
        success: function (res) {
          _this.archiveDetail.data = {}
          if (res.FileUrl) {
            res.FileUrl = res.FileUrl.split(",")
          }
          if(res.DocumentUrl){
            try {
              res.DocumentUrl = JSON.parse(res.DocumentUrl)
            } catch (e) {
              //旧格式兼容，旧格式仅为文件路径，需截从路径取出文件名
              res.DocumentUrl = [{ name: getFileName(res.DocumentUrl), url: res.DocumentUrl }]
            }
          }
          _this.archiveDetail.data = res
          _this.archiveDetail.state = true
        }
      })
      function getFileName(url) {
        return url.substring(url.lastIndexOf("/"))
      }
    },
    //时间戳转换成日期
    timestampToDate: function (timestamp,type) {
      var date = new Date(1000 * timestamp); //获取一个时间对象
      var Y = date.getFullYear();
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
      var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      var H = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours());
      var B = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes());
      if (type == 'datetime') { return `${Y}年${M}月${D}日 ${H}:${B}`}
      if (type == 'date') { return `${Y}年${M}月${D}日`}
    },

    handleCheckAll: function () {
      var _this = this
      var checkAllGroup = []//全选
      var back = []//反选
      console.log(_this.checkAllGroup)
      if (_this.checkAllGroup.length == 0) {
        //全选
        for (var i in _this.archiveList) {
          checkAllGroup.push(_this.archiveList[i].FileId + ',' + _this.archiveList[i].IsCheck)
        }
        _this.checkAllGroup = checkAllGroup
        _this.checkAllGroupPage[_this.pageBar.current - 1] = checkAllGroup
        _this.indeterminate = true
      }
      else {
        //反选
        for (var i in _this.archiveList) {
          checkAllGroup.push(_this.archiveList[i].FileId + ',' + _this.archiveList[i].IsCheck)
        }
        // 首先构造Object
        const idSet = _this.checkAllGroup.reduce((acc, v) => {
          acc[v] = true;
          return acc;
        }, {});
        // 遍历list2，去掉在idSet中存在的id
        const result = checkAllGroup.filter(v => !idSet[v]);
        _this.checkAllGroup = result
        _this.checkAllGroupPage[_this.pageBar.current - 1] = result
      }
    },
    checkAllGroupChange: function (data) {
      var _this = this
      _this.checkAllGroup = data
      _this.checkAllGroupPage[_this.pageBar.current - 1] = data
      console.log(_this.checkAllGroup)
    },
    onCancel: function () {
      var _this = this
      _this.endTime = ''
      _this.startTime = ''
      _this.exportLawyerData = ''
      _this.timeData = ''
    },
    submit: function (ClockInId, type) {
      var _this = this
      var clockArr = []
      if (ClockInId == '选择') {
        if (_this.checkAllGroupPage != '') {
          for (var i in _this.checkAllGroupPage) {
            for (var index in _this.checkAllGroupPage[i]) {
              clockArr.push(_this.checkAllGroupPage[i][index].split(',')[0])
            }
          }
        } else {
          this.$Message.info('请选择后导出');
          return
        }
      }
      console.log(_this.startTime, _this.endTime, _this.exportLawyerData)
      if (SYSCONFIG.location == 'chong-qing') {
        var url = '/FileAdminListWord'
      } else {
        var url = '/CD_FileAdminListWord'
      }
      var _data = {

        StartTime: _this.startTime,
        EndTime: _this.endTime,
        AdminFind: _this.filter.areaRange.value,
        VillagesId: _this.filter.community.value,
        villages: _this.filter.communityNumber.value,
        AdminId: window.sessionStorage.AdminId,
        FileTypeId: _this.fileType,
        EvaluationTypeId: _this.filter.examinationType.value.at(-1),
        IsCheck:_this.filter.approvalStatus.value,
        LawyerId: _this.filter.lawyer.value,
        // StartTime: _this.filter.serviceDate.startTime,
        // EndTime: _this.filter.serviceDate.endTime,
        DateType: _this.filter.serviceDate.identification,
        page: _this.pageBar.current,
        pageSize: _this.pageBar.size,
        TimeType : _this.filter.TimeType,
        FileArr: clockArr.toString() || ClockInId,

        // AdminFind: _this.filter.areaRange.value,
        // VillagesId: _this.filter.community.value,
        // AdminId: window.sessionStorage.AdminId,
        // FileTypeId: _this.fileType,
        // EvaluationTypeId: _this.filter.examinationType.value.at(-1),
        // IsCheck:_this.filter.approvalStatus.value,
        // LawyerId: _this.filter.lawyer.value,
        // StartTime: _this.startTime,
        // EndTime: _this.endTime,
        // DateType: 'custom',
        // page: _this.pageBar.current,
        // pageSize: _this.pageBar.size,
        // TimeType : _this.filter.TimeType
      }
      // {
      //   StartTime: _this.startTime,
      //       EndTime: _this.endTime,
      //     DateType: 'custom',
      //     AreaId: _this.AreaId,
      //     AdminId: window.sessionStorage.AdminId,
      //     FileTypeId: _this.fileType,
      //     EvaluationTypeId: _this.evaluationTypeId,
      //     LawyerId: _this.exportLawyerData,
      //     FileArr: clockArr.toString() || ClockInId
      // }
      _this.send({
        loading:true,
        url: url,
        data: Object.assign(_this.serviceIF(),_data),
        success: function (res) {
          if (res != '') {
            if (type == 1) {
              if (SYSCONFIG.location == 'chong-qing') {
                _this.showPdf(window.sessionStorage.url + res)
              } else {
                if (res.substring(res.lastIndexOf('.') + 1) == 'zip') {
                  window.open(window.sessionStorage.url + res);
                } else {
                  window.open('https://view.officeapps.live.com/op/view.aspx?src=' + window.sessionStorage.url + res)
                }
              }
            } else {
              window.open(window.sessionStorage.url + res);
            }
          }
          _this.endTime = ''
          _this.startTime = ''
          _this.exportLawyerData = ''
          _this.timeData = ''
          _this.checkAllGroupPage = []
          _this.checkAllGroup = []
          _this.endTime = ''
          _this.LawyerName = ''
        }
      })
    },
    showPdf: function (e) {
      var _this = this
      if (SYSCONFIG.location == 'chong-qing') {
        var url = '/ShowPdf'
      } else {
        var url = '/CD_ShowPdf'
      }
      window.open(window.sessionStorage.url + 'api' + url + '?FilePath='+ e)
    },
    totalTime: function (e) {
      var _this = this
      _this.startTime = e[0],//开始时间
        _this.endTime = e[1]//结束时间
    },
    clockDownload: function () {
      this.showExport = !this.showExport
    },
    exportLawyerName: function (value) {
      var _this = this
      _this.exportLawyerData = value
    },
    submitCheckActive: function () {
      var _this = this
      var clockArr = []
      for (var i in _this.checkAllGroupPage) {
        for (var index in _this.checkAllGroupPage[i]) {
          if (_this.checkAllGroupPage[i][index].split(',')[1] != 2) {
            clockArr.push(_this.checkAllGroupPage[i][index].split(',')[0])
          }
        }
      }
      console.log(clockArr)
      var _this = this
      if (clockArr != '') {
        _this.send({
          url: '/CheckActive',
          confirm: true,
          data: {
            IsCheck: 1,
            FileId: clockArr.toString(),
            AdminId: window.sessionStorage.AdminId,
            PidArr : window.sessionStorage.parentIdArrStr.split(),
          },
          success: function (res) {
            _this.getArchiveList()
            _this.checkAllGroupPage = []
            _this.checkAllGroup = []
          }
        })
      } else {
        this.$Message.warning('请选择未审核状态');
      }
    },

    deleteClick: function (FileId) {
      var _this = this
      _this.send({
        confirm: true,
        url: '/AdminActiveDelete',
        data: {
          AdminId: window.sessionStorage.AdminId,
          FileId: FileId,
        },
        success: function (res) {
          _this.getArchiveList()
        }
      })
    },
    lawyerSelect: function (value){
      this.filter.lawyer.value = value.value
      this.pageBar.current=1;
      this.getArchiveList()
    },
    lawyerCancellation: function (){
      this.filter.lawyer.value = ''
      this.pageBar.current=1;
      this.getArchiveList()
    },
  },
}
</script>
