<style scoped>
.panel-type-bar{background-image:url(/img/manager/big-data/map-count/panel-type-bar-bg.png);width:731px;line-height:41px;height:71px;background-size:100% 100%;padding:15px;position:relative;z-index:2;}
.panel-type-bar-icon{background-image:url(/img/manager/big-data/map-count/panel-type-bar-icon.png);width:53px;height:53px;display:inline-block;vertical-align:middle;margin:-6px 0px}
.panel-type-bar-menu{width:122px;margin:0 20px;display:inline-block;text-align:center;font-size:16px;color:#39ffff;background-position:center;cursor:pointer;background-size:100% 100%;}
.panel-type-bar-menu-selected{font-weight:bold;color:#ffa100;background-image:url(/img/manager/big-data/map-count/panel-type-bar-menu-icon.png);}
.panel-type-bar-menu:hover{background-image:url(/img/manager/big-data/map-count/panel-type-bar-menu-icon.png);}
.panel-l{width:420px;display:inline-block;margin-left:20px;background-image:url(/img/manager/big-data/map-count/panel-l-bg.png);background-repeat:no-repeat;color:#fff;line-height:46px;padding:10px;}
.panel-l-type{text-align:left;font-weight:bold;font-size:14px;background-color:#081650;padding:0 13px;background-image:url(/img/manager/big-data/map-count/panel-l-type-bg.png);background-repeat:no-repeat;background-position:right 10px bottom 50%;}
.panel-l-title{font-weight:bold;font-size:14px;background-color:rgba(8,47,94,0.5);}
.panel-l-list{height:460px;overflow-y:auto;}
.panel-l-list::-webkit-scrollbar{width:7px;height:6px}/* 设置滚动条的样式 */
.panel-l-list::-webkit-scrollbar-thumb{border-radius: 10px;background:linear-gradient(to top,#008aff,#47cd93);}/* 滚动条滑块 */
.panel-l-list *{text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
.panel-map{height:766px;vertical-align:top;margin-top:-100px;}
.map-tips{background-image:url(/img/manager/big-data/map-count/map-tips-bg.png);width:320px;height:185px;position:absolute;z-index:30;padding:20px;color:#fff;font-size:14px;}
.map-tips-title{color:#39ffff;font-weight:bold;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
.map-tips-userIcon{width:34px;height:34px;border-radius:17px;vertical-align:middle;}
.map-tips-status{width:140px;height:28px;background-image:url(/img/manager/big-data/map-count/map-tips-status-bg.png);background-size:100% 100%;line-height:28px;}
.map-tips-time{color:#39ffff}
.map-bg{position:absolute;width:518px;height:518px;right:280px;opacity:0.2}
.map-bg1,.map-bg2,.map-bg3{position:absolute;}
.map-bg1{animation: map-bg1-action 15s infinite linear;left:50%;top:50%;margin-left:-321.5px;margin-top:-321.5px;}
.map-bg2{opacity: 0.2; animation: map-bg2-action 10s infinite linear;left:50%;top:50%;margin-left:-283px;margin-top:-283px;}
.map-bg3{left:50%;top:50%;margin-left:-259px;margin-top:-259px;}
@keyframes map-bg1-action{from {transform: rotate(0deg);}to {transform: rotate(359deg);}}
@keyframes map-bg2-action{from {transform: rotate(0deg);}to {transform: rotate(-359deg);}}
.alert :deep(.ivu-modal-header){padding: 0}
.alert :deep(.ivu-modal-body){padding: 0;margin-top: -1px;}
.alert :deep(.ivu-modal-footer){padding: 0;border-top:0;}
</style>

<template>
  <div>
    <div class="child-title">服务监测</div>
    <div class="panel-type-bar">
      <span class="panel-type-bar-icon"></span>
      <span class="panel-type-bar-menu" :class="{'panel-type-bar-menu-selected':topList.selected == 'consulting'}" @click="topList.selected = 'consulting'">咨询服务</span>
      <span class="panel-type-bar-menu" :class="{'panel-type-bar-menu-selected':topList.selected == 'archives'}" @click="topList.selected = 'archives'">村居治理档案</span>
      <span class="panel-type-bar-menu" :class="{'panel-type-bar-menu-selected':topList.selected == 'duty'}" @click="topList.selected = 'duty'">值班情况</span>
    </div>
    <div style="padding:10px"></div>
    <div style="display:flex;flex-direction: row;">
      <div class="panel-l text-center" v-if="topList.selected == 'consulting'">
        <div class="panel-l-type">当日咨询 New30</div>
        <Row class="panel-l-title">
          <Col span="4">时间</Col>
          <Col span="12" class="text-left">村(社区)</Col>
          <Col span="4">村居顾问</Col>
          <Col span="4">类型</Col>
        </Row>
        <div class="panel-l-list" >
          <div v-for="(item,index) in topList.consulting" @click="detailedData(item.OrderId,1)">
            <Row :style="index%2==0?'background-color:rgba(249,250,252,0.1)':''" >
              <Col span="4" >{{item.consultTime?item.consultTime.substring(0,5):formatTopTime(item.ask_time)}}</Col>
              <Col span="12" class="text-left" :title="(item.area.replace('司法局','')||'')+(item.Street||'')+(item.VillagesName)">
                {{item.area.replace('司法局','')||''}}{{item.Street||''}}{{item.VillagesName}}
              </Col>
              <Col span="4">{{item.LawyerName}}</Col>
              <Col span="4" class="text-left">{{item.OrderTypeTitle||'留言咨询'}}</Col>
            </Row>
          </div>
        </div>
      </div>
      <div class="panel-l text-center" v-if="topList.selected == 'archives'">
        <div class="panel-l-type">当日档案 New30</div>
        <Row class="panel-l-title">
          <Col span="4">时间</Col>
          <Col span="12" class="text-left">村(社区)</Col>
          <Col span="4">村居顾问</Col>
          <Col span="4">类型</Col>
        </Row>
        <div class="panel-l-list">
          <div v-for="(item,index) in topList.archives"  @click="detailedData(item.FileStatsId,2)">
            <Row :style="index%2==0?'background-color:rgba(249,250,252,0.1)':''">
              <Col span="4">{{formatTopTime(item.FileTime)}}</Col>
              <Col span="12" class="text-left" :title="(item.area.replace('司法局','')||'')+(item.Street||'')+(item.VillagesName)">{{item.area.replace('司法局','')||''}}{{item.Street||''}}{{item.VillagesName}}</Col>
              <Col span="4">{{item.LawyerName}}</Col>
              <Col span="4">{{item.FileTypeName}}</Col>
            </Row>
          </div>
        </div>
      </div>
      <div class="panel-l text-center" v-if="topList.selected == 'duty'">
        <div class="panel-l-type">当日值班 New30</div>
        <Row class="panel-l-title">
          <Col span="4">时间</Col>
          <Col span="12" class="text-left">村(社区)</Col>
          <Col span="4">村居顾问</Col>
          <Col span="4">类型</Col>
        </Row>
        <div class="panel-l-list">
          <div v-for="(item,index) in topList.duty" @click="detailedData(item.ClockInId,3)">
            <Row :style="index%2==0?'background-color:rgba(249,250,252,0.1)':''">
              <Col span="4">{{formatTopTime(item.ClockInOutTime||item.ClockInTime)}}</Col>
              <Col span="12" class="text-left" :title="(item.area.replace('司法局','')||'')+(item.Street||'')+(item.VillagesName)">{{item.area.replace('司法局','')||''}}{{item.Street||''}}{{item.VillagesName}}</Col>
              <Col span="4">{{item.LawyerName}}</Col>
              <Col span="4">{{item.ClockInOutTime?'值班结束':'开始值班'}}</Col>
            </Row>
          </div>
        </div>
      </div>
      <div style="flex-grow: 1;position:relative;z-index:1;">
        <div class="map-bg" v-if="false">
          <div class="map-bg3"><img src="/img/manager/big-data/map-count/bg3.png"></div>
          <div class="map-bg2"><img src="/img/manager/big-data/map-count/bg2.png"></div>
          <div class="map-bg1"><img src="/img/manager/big-data/map-count/bg1.png"></div>
        </div>
        <div class="panel-map" ref="echarts-map"></div>
        <div class="map-tips" v-for="(item,index) in mapTips" :style="item.style" v-if="item.status">
          <div class="map-tips-title">{{item.data.userArea.district}}{{item.data.userArea.street}}{{item.data.userArea.village}}</div>
          <div style="padding:7px"></div>
          <div><img class="map-tips-userIcon" :src="item.data.userIconSrc"/><span style="padding:5px"></span>{{item.data.userName}}</div>
          <div style="padding:7px"></div>
          <div><span class="map-tips-status inline-block text-center">{{item.data.consultingType}}</span><span class="map-tips-time"><span style="padding:5px"></span>{{item.data.consultTime.substring(5)}}</span></div>
          <div style="padding:8px"></div>
          <div>村居顾问 > {{item.data.lawyerName}}</div>
        </div>
      </div>
    </div>
<!--    咨询-档案-值班弹窗-->
    <Modal v-model="modal" class="alert">
              <div slot="header">
              </div>
              <div slot="footer"></div>
      <div style="background-image: url(/img/manager/big-data/bg.png);background-size: cover;overflow: auto;">
        <div style="color: #FFF;margin: 30px">
          <div style="text-align: center;font-size: 24px;" v-if="detailedType == 1">咨询服务</div>
          <div style="text-align: center;font-size: 24px;" v-if="detailedType == 2">村居治理档案</div>
          <div style="text-align: center;font-size: 24px;" v-if="detailedType == 3">值班情况</div>
          <div v-if="detailedType == 1">
            <div>用户：{{eleFileViewData.UserName||'--'}}</div>
            <div>法律顾问：{{eleFileViewData.LawyerName||'--'}}</div>
            <div>村（社区）：{{ eleFileViewData.CityName }}{{ eleFileViewData._AdminName }}{{ eleFileViewData.VillagesName }}</div>
            <div>咨询时间：{{eleFileViewData.OrderCreatedTime||'--'}}</div>
            <div>服务状态：{{eleFileViewData.state}}</div>
            <div>处理状态：{{eleFileViewData.IsReply||'--'}}</div>
            <div>结束时间：{{eleFileViewData.OrderEndTime}}</div>
            <div v-if="cq != ''">咨询摘要：{{ eleFileViewData.FileExplain }}</div>
            <div v-else>律师小结：{{ eleFileViewData.OrderSummary ? eleFileViewData.OrderSummary : '未填写' }}</div>
            <div v-if="cq != ''">是否归档：
              <span v-if="eleFileViewData.archiveId">已归档</span>
              <span v-else>未归档</span>
            </div>
          </div>
          <div v-if="detailedType == 2">
            <div style="height: 420px;overflow: auto;">
              <!--<p>{{fileurl}}</p>-->
              <div style="margin-bottom: 10px;" v-if="eleFileViewData.DocumentUrl">
                <div v-for="(value,index) in eleFileViewData.DocumentUrl" style="margin-left: 30px" >
                  {{value.name}}
                  <Button type="primary" @click="seeFile(domainName+value.url,1)" >查看</Button>
                  <Button type="primary" @click="seeFile(domainName+value.url,2)" >下载</Button>
                </div>

              </div>
              <viewer :images="tupians" v-if="eleFileViewData.FileUrl" style="width: 47%;display: inline-block;">
                <Carousel v-model="picture" v-if="modal && eleFileViewData.FileUrl" @on-click="enlarge" dots="none">
                  <CarouselItem v-for="(value,index) in eleFileViewData.FileUrl" style="text-align: center">
                    <img :src="domainName+value" style="max-width:100%;max-height: 420px">
                  </CarouselItem>
                </Carousel>
              </viewer>
              <div style="display: inline-block;vertical-align: top;width: 48%;margin-left: 10px">
                <div v-if="cq == ''">
                  标题：<span v-if="eleFileViewData.FileName">{{eleFileViewData.FileName}}</span>
                </div>
                <div v-if="eleFileViewData.villagesNameArr">社区(村)：<p v-for="(value,index) in eleFileViewData.villagesNameArr" style="display: inline-block">
                  {{value.VillagesName}}<span v-if="index+1<eleFileViewData.villagesNameArr.length">-</span></p>
                </div>
                <div v-if="cq == ''&& eleFileViewData.title">
                  考核分栏：<span v-if="eleFileViewData.title">{{eleFileViewData.title}}-</span>{{eleFileViewData.pTitle}}
                </div>
                <div v-if="eleFileViewData.EvaluationCount">
                  考核计数：<span>{{eleFileViewData.EvaluationCount}}{{eleFileViewData.Unit}}</span>
                </div>
                <div v-if="eleFileViewData.ServiceLinkman">联系人：{{eleFileViewData.ServiceLinkman}}</div>
                <div v-if="eleFileViewData.ServiceTel">联系电话：{{eleFileViewData.ServiceTel}}</div>
                <div v-if="eleFileViewData.ConsultHumanTypeArr">咨询人类别类型：
                  <p v-for="(value,index) in eleFileViewData.ConsultHumanTypeArr" style="display: inline-block">
                    {{value}}<span v-if="index+1<eleFileViewData.ConsultHumanTypeArr.length">-</span></p>
                </div>
                <div v-if="eleFileViewData.ManageSerTypeArr">服务事项：
                  <p v-for="(value,index) in eleFileViewData.ManageSerTypeArr" style="display: inline-block">
                    {{value}}<span v-if="index+1<eleFileViewData.ManageSerTypeArr.length">-</span></p>
                </div>
                <div v-if="eleFileViewData.MediateTypeArr">纠纷类型：
                  <p v-for="(value,index) in eleFileViewData.MediateTypeArr" style="display: inline-block">
                    {{value}}<span v-if="index+1<eleFileViewData.MediateTypeArr.length">-</span></p>
                </div>
                <div v-if="eleFileViewData.ConsultTypeArr">知识类型：
                  <p v-for="(value,index) in eleFileViewData.ConsultTypeArr" style="display: inline-block">
                    {{value}}<span v-if="index+1<eleFileViewData.ConsultTypeArr.length">-</span></p>
                </div>
                <div v-if="eleFileViewData.DealTypeArr">处理方式：
                  <p v-for="(value,index) in eleFileViewData.DealTypeArr" style="display: inline-block">
                    {{value}}<span v-if="index+1<eleFileViewData.DealTypeArr.length">-</span></p>
                </div>
                <div v-if="eleFileViewData.MediateHumanTypeArr">纠纷人群：
                  <p v-for="(value,index) in eleFileViewData.MediateHumanTypeArr" style="display: inline-block">
                    {{value}}<span v-if="index+1<eleFileViewData.MediateHumanTypeArr.length">-</span></p>
                </div>
                <div v-if="eleFileViewData.PetitionerArr">
                  <div v-for="(value,index) in eleFileViewData.PetitionerArr" style="display: inline-block">
                    <div>信访人：{{value.name}}  信访人电话：{{value.telephone}}</div>
                  </div>
                </div>
                <div v-if="eleFileViewData.MediateResult">调解结果：
                  <span v-if="eleFileViewData.MediateResult == 1">调解成功</span>
                  <span v-if="eleFileViewData.MediateResult == 2">调解不成功</span>
                </div>
                <div v-if="eleFileViewData.ServiceCount">服务人数：{{eleFileViewData.ServiceCount}}</div>
                <div v-if="eleFileViewData.ServiceTime">服务时间：{{uploadDate(eleFileViewData.ServiceTime,2)}}</div>
                <div v-if="parseInt(eleFileViewData.ServiceLong) > 0 || eleFileViewData.ServiceMins">服务时长：{{parseInt(eleFileViewData.ServiceLong)}}小时
                  <span v-if="eleFileViewData.ServiceMins">{{eleFileViewData.ServiceMins}}分钟</span>
                </div>
                <div v-if="cq == '' && eleFileViewData.DailyType">服务方式：<p v-for="(value,index) in eleFileViewData.DailyType" style="display: inline-block">
                  {{value}}<span v-if="index+1<DailyType.length">-</span></p>
                </div>
                <div v-if="cq != '' && eleFileViewData.ServiceTypeName">
                  服务方式：<span style="width: 8%;" v-if="eleFileViewData.ServiceTypeName">{{eleFileViewData.ServiceTypeName}}</span>
                  <span style="width: 8%;" v-else>{{eleFileViewData.EvaluationTypePid}}</span>
                </div>
                <div v-if="eleFileViewData.FileExplain">内容：{{eleFileViewData.FileExplain}}</div>
                <div v-if="eleFileViewData.OrderGrade">评价：
                  <span v-if="eleFileViewData.OrderGrade == 5">非常满意</span>
                  <span v-if="eleFileViewData.OrderGrade == 4">满意</span>
                  <span v-if="eleFileViewData.OrderGrade == 3">基本满意</span>
                  <span v-if="eleFileViewData.OrderGrade == 2">不满意</span>
                  <span v-if="eleFileViewData.OrderGrade == 1">非常不满意</span>
                </div>
              </div>
            </div>

            <div style="position: relative">
              <div style="width: 47%;position: absolute;z-index: 999;bottom: 0;text-align: center;">
                <img :style="picture == index ?'outline: 2px solid #000;':'border: 2px;'" v-for="(value,index) in eleFileViewData.FileUrl" :src="domainName+value" style="width:40px;height: 40px;margin: 0 5px" @click="switchWalkingLantern(index)">
              </div>
            </div>
          </div>
          <div v-if="detailedType == 3">
            <div>律师名称：{{eleFileViewData.LawyerName||'--'}}</div>
            <div>律所名称：{{eleFileViewData.OrganizationName||'--'}}</div>
            <div>上班时间：{{ eleFileViewData.ClockInTime }}</div>
            <div>上班地址：<span>{{ eleFileViewData.ClockInAddress }}</span></div>
            <div >
              上班备注、图片：
              <div style="display: inline-block" v-if="eleFileViewData.InClockRemark">{{ eleFileViewData.InClockRemark }}</div>
              <div style="display: inline-block;margin-left: 10px" v-if="eleFileViewData.InClockUrl" @click="seeImg(eleFileViewData.InClockUrl);modal3 = true">
                <Button>查看</Button>
              </div>
            </div>
            <div>
              下班时间：{{ eleFileViewData.ClockInOutTime }}
            </div>
            <div>
              下班地址：{{ eleFileViewData.ClockInOutAddress }}
            </div>
            <div>
              下班备注、图片：
              <div style="display: inline-block" v-if="eleFileViewData.OutClockRemark">{{ eleFileViewData.OutClockRemark }}</div>
              <div style="display: inline-block;margin-left: 10px" v-if="eleFileViewData.OutClockUrl" @click="seeImg(eleFileViewData.OutClockUrl);modal3 = true">
                <Button>查看</Button>
              </div>
            </div>
            <div>在线时间：{{ eleFileViewData.OnlineTime }}分钟{{ eleFileViewData.Status == 0 ? '（已作废）' : '' }}</div>
          </div>

        </div>
        <div style="text-align: right;margin: 0 30px 15px 0">
          <Button @click="modal = false">关闭</Button>
        </div>
      </div>
    </Modal>
<!--    -->
    <Modal v-model="modal3" >
      <div slot="header">
      </div>
      <div slot="footer"></div>
      <div style="text-align: center">
        <viewer :images="tupians">
          <img :src="fileurl" style="max-width: 100%; max-height: 500px" @click="enlarge" />
        </viewer>
      </div>
    </Modal>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import axios from 'axios'
//自定义地图名称映射
import mapName from './map-name'
export default {
  name:'map-count',
  props:{
    areaId:{require:true},
    adminId:{require:true},
    areaCode:{require:true},
    electronicsList:{require:true},
  },
  data() {
    return {
      modal : false,
      modal3 : false,
      topList:{
        consulting:[],//咨询服务
        archives:[],//档案
        duty:[],//值班
        selected:'consulting',
      },
      mapTipsList:[],//弹窗缓存区
      mapTips:[
        {style:'top:-80px;left:50%;margin-left:-160px;',data:{},status:false},
        {style:'top:80px;left:80px;',data:{},status:false},
        {style:'top:90px;left:50%;',data:{},status:false},
        {style:'bottom:80px;left:50%;margin-left:-180px;',data:{},status:false},
      ],
      echartsMap:{
        tooltip: {
          trigger: 'item',
          // formatter: '{b}:{c}',
          formatter: function (params) {
            if(params.data){
              return params.name +'<br>'+
                  // '服务村（社区）数：' + params.data.type.villages_count + '个'+'<br>'
                  // +'顾问人数：' + params.data.type.lawyer_count +'人'+'<br>'+
                  '用户人数：'+ params.data.type.user_count +'人'+'<br>'+
                  '服务档案数：'+ params.data.type.file_count +'条'+'<br>'+
                  '服务次数：'+ params.data.type.order_count+'次'
            }else {
              return params.name
            }
          },
        },
        grid:{//直角坐标系
          top:'0',
          left:'0',
          right:'0',
          bottom:'0',
          containLabel:true,//设置直角坐标系区域包含坐标轴的刻度标签
        },
        visualMap: {
          min:0,
          max:0,
          show:false,//取消左侧visualMap-continuous组件保留数据映射功能
          inRange: {//设置最低最高颜色
            color: ['#063B89','#6099EC']
          }
        },
        series: [{
          type: 'map',
          zoom:1.1,//地图缩放
          map: '地图',
          nameMap:mapName,//自定义地图名称映射geo-json数据能够对应，则忽略
          emphasis:{//地图各版块高亮样式设置
            label:{
              color:'#ffffff',//地图板块高亮区域文字颜色
            },
          },
          itemStyle:{
            borderColor:'#ffffff',//地图各板块描边颜色
            borderWidth:0.3,
          },
          label: {
            show: true,//区域名称上屏
            color:'#4ADEFE',//地图板块区域名称颜色
            textBorderColor:'#000000',//地图板块区域名称文字描边颜色
            textBorderWidth:2,//地图板块区域名称文字描边宽度
          },
          // formatter:[

          // ],
          data: [
            //{ name: '巫溪县', value: 20057.34},
          ],
        }]
      },

      changerTimer: null,
      num: 0,
      cq : '',
      //档案
      eleFileViewData : [],
      tupians: [],
      domainName: '',
      picture: 0,
      DailyType: '',
      detailedType : 0,
      fileurl: [],
    };
  },
  watch:{
    'topList.selected':{
      handler:function(value){
        switch(value){
          case 'consulting':this.getConsulting();break;
          case 'archives':this.getArchives();break;
          case 'duty':this.getDuty();break;
        }
      },
      immediate:true,
    }
  },
  mounted() {
    this.makeMap(this.areaCode)
    this.startSocket()
    this.mapTipsControllers()
    this.autoChange()
    if(SYSCONFIG.location == 'chong-qing'){
      this.cq = 'cq'
    }
    this.domainName = window.sessionStorage.url
  },
  methods: {
    detailedData : function (id,type){
      var _this = this
      this.detailedType = type
      if(type == 1){
        _this.send({
          url: '/topOrderById',
          data: {
            OrderId: id,
            AdminId: _this.adminId,
          },
          success: function (res) {
            _this.eleFileViewData = res
          }
        })
      }
      if(type == 2){
        _this.send({
          url: '/eleFileView',
          data: {
            FileStatsId: id,
            AdminId: _this.adminId,
          },
          success: function (res) {
            try{
              res.DocumentUrl =  JSON.parse(res.DocumentUrl)
            }catch(e){
              res.DocumentUrl =  [{name:_this.getFileName(res.DocumentUrl),url:res.DocumentUrl}]
            }
            if(res.FileUrl){
              res.FileUrl = res.FileUrl.split(",")
            }
            _this.eleFileViewData = res
          }
        })
      }
      if(type == 3){
        _this.send({
          url: '/topClockById',
          data: {
            ClockInId: id,
            AdminId: _this.adminId,
          },
          success: function (res) {
            _this.eleFileViewData = res
          }
        })
      }
      this.modal = true
    },
    getFileName (name) {
      return name.substring(name.lastIndexOf("/"))
    },
    seeFile: function (url,type) {
      var _this = this
      var fileType = url.substr(url.lastIndexOf(".") + 1, 4).toLowerCase()
      if (fileType == "txt") {
        axios({
          method: 'get',
          responseType: 'blob',
          url: url,
          transformResponse: [function (data) {
            var render = new FileReader()
            render.readAsText(data, 'UTF8')
            render.onload = function () {
              if (render.result.indexOf('�') != -1) {
                var renderGBK = new FileReader()
                renderGBK.readAsText(data, 'GBK')
                renderGBK.onload = function () {
                  var newWindow = window.open('about:blank', '', 'width=400,height=400')
                  newWindow.document.write(renderGBK.result)
                }
              } else {
                var newWindow = window.open('about:blank', '', 'width=400,height=400')
                newWindow.document.write(render.result)
              }
            }
          }]
        })
      }
          // else if (fileType == "doc" || fileType == "doc") {
          //   _this.send({
          //     url: "/AdminFileContent",
          //     data: {
          //       FileId: _this.fileId,
          //     },
          //     success: res => {
          //       window.open((SYSCONFIG.server.default.port == 443 ? 'https://' : 'http://') + SYSCONFIG.server.default.host + res.DocumentUrl)
          //       // window.location.href = res
          //     }
          //   })
      // }
      else {
        if(fileType == 'pdf'){
          window.open(url)
        }else{
          if(SYSCONFIG.location == 'chong-qing'){
            if(type == 2){
              window.open(url)
            }else{
              _this.showPdf(url)
            }
          }else{
            window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
          }
        }
      }
      return
      if (this.documentUrl.substr(this.documentUrl.lastIndexOf(".") + 1) == 'txt') {
        _this.filesToRar([{
          fileUrl: _this.documentUrl,
          renameFileName: 'txt.txt',
        }], 'txt')
      } else {
        window.location.href = this.documentUrl
      }

    },
    switchWalkingLantern : function (index){
      this.picture = index
    },
    enlarge: function (e) {
      this.tupians = this.fileurl
    },
    seeImg: function (url) {
      this.fileurl = window.sessionStorage.url + url
    },
    uploadDate : function (source,type){
      var date = new Date(1000 * source); //获取一个时间对象
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
      var H = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
      var B = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' ';
      if(type == 1){return Y + M + D + H + B}
      if(type == 2){return Y + M + D}
    },
    mapTipsControllers:function(){
      let _this = this
      _this.mapTips.map(item=>{
        if(item.status==false&&_this.mapTipsList.length>0){
          item.data = _this.mapTipsList[0]
          setTimeout(()=>{item.status = true},2000)
          _this.mapTipsList.splice(0,1)
          setTimeout(()=>{item.status = false},30000)
        }
      })
      setTimeout(_this.mapTipsControllers,1000)
    },
    startSocket:function(){
      let _this = this
      let socket = new WebSocket("wss://node.lvwa18.com/ws")
      // let socket = new WebSocket("ws://127.0.0.1:6002")
      socket.onopen = function () {
        socket.send(JSON.stringify({
          id:'admin'+_this.adminId,
          client:'lvwa18',
          type:'register'
        }))
      }
      socket.onerror = (event) => {
        console.log('error')
        setTimeout(_this.startSocket,10000)
      }
      // 监听Socket的关闭
      socket.onclose = (event) => {
        setTimeout(_this.startSocket,10000)
      }
      socket.onmessage = msg=>{
        let data = JSON.parse(msg.data)
        if(data.content.type=='map-tips'){
          _this.mapTipsList.push(data.content)
          _this.topList.consulting.splice(0,0,{
            consultTime:data.content.consultTime.substring(12),
            VillagesName:data.content.userArea.village,
            LawyerName:data.content.lawyerName,
            OrderTypeTitle:data.content.consultingType,
            area:data.content.userArea.district,
            Street:data.content.userArea.street,
          })
          _this.topList.consulting.length = 30
        }
      }
    },
    //咨询Top30
    getConsulting:function(){
      let _this = this
      _this.send({
        url:'/topCount',
        loading:false,
        data:{
          AdminId:_this.adminId,
          pageSize:30,
        },
        success:function(res){
          _this.topList.consulting = res
        }
      })
    },
    //档案Top30
    getArchives:function(){
      let _this = this
      _this.send({
        url:'/topFileCount',
        loading:false,
        success:function(res){
          _this.topList.archives = res
        }
      })
    },
    //值班Top30
    getDuty:function(){
      let _this = this
      _this.send({
        url:'/topDutyCount',
        loading:false,
        success:function(res){
          _this.topList.duty = res
        }
      })
    },
    makeMap:function(areaCode){
      let _this = this
      // echarts.init(_this.$refs['echarts-map']).showLoading({
      //     text: '数据正在加载中...',
      //     textStyle: { fontSize: 30, color: '#444' },
      //     effectOption: { backgroundColor: 'rgba(0, 0, 0, 0)' }
      //   });
      _this.getMapGeo(areaCode)//获取地图渲染GEOJSON
      .then(_this.getMapData)//获取地图的数据
      .then(_this.checkSquireArea)//检查特殊地区数据
      .then(res=>{
        _this.echartsMap.series[0].data = []
        for(let index in res){
          if(res[index].user_count>_this.echartsMap.visualMap.max){
            _this.echartsMap.visualMap.max = res[index].user_count
          }
          _this.echartsMap.series[0].data.push({
            name:res[index].AreaName,
            value:res[index].user_count,
            type : res[index]
          })

        }
        const myChart = echarts.init(_this.$refs['echarts-map'])
        myChart.setOption(_this.echartsMap)
        myChart.on('click', (e) => {
          for(var i in _this.electronicsList){
            if(_this.electronicsList[i].name == e.data.name){
              let adminLogin = _this.electronicsList[i].adminLogin;
              let adminPassword = _this.electronicsList[i].adminPassword;
              let routeData = _this.$router.resolve({name: 'managerLogin', params: { adminLogin, adminPassword },});
              let urlWithParams = `${routeData.href}?adminLogin=${adminLogin}&adminPassword=${adminPassword}`;
              window.open(urlWithParams, '_blank');
            }
          }

        })
        echarts.init(_this.$refs['echarts-map']).setOption(_this.echartsMap)
        // echarts.init(_this.$refs['echarts-map']).hideLoading()
      })
    },
    getMapGeo:function(areaCode){
      let _this = this
      return new Promise((resolve,reject)=>{
        axios.get(`/geo-json/${areaCode}.json`)
        .then(res=>{
          console.log(res.data)
          echarts.registerMap('地图',res.data)
          echarts.init(_this.$refs['echarts-map']).setOption(_this.echartsMap)
          resolve(areaCode)
        })
      })
    },
    getMapData:function(areaCode){
      let _this = this
      return new Promise((resolve,reject)=>{
        _this.send({
          url: '/_GetMapCount',
          loading:false,
          data: {
            AreaCode: areaCode,
          },
          success: function (res) {
            resolve(res)
          }
        })
      })
    },
    formatTopTime:function(time){
      time = time+'000'
      let date = new Date(parseInt(time))
      return (date.getHours()<10?'0'+date.getHours():date.getHours())+':'+(date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes())
    },
    formatTopDate:function(time){
      time = time+'000'
      let date = new Date(parseInt(time))
      return date.getFullYear()+'/'+(date.getMonth()+1)+'/'+date.getDate()
    },
    //检查特殊区域
    checkSquireArea:function(areaListIn){
      let _this = this
      let areaList = []
      return new Promise((resolve,reject)=>{
        if(areaListIn[0].AreaCode==500100){
          //areaListIn.splice(0,1)//是否删除最大值
          let getMapFunList = []
          for(let index in areaListIn){
            getMapFunList.push(_this.getMapData(areaListIn[index].AreaCode))
          }
          Promise.all(getMapFunList)
          .then(res=>{
            res.map((item,index)=>{
              // item.splice(0,1)
              areaList = [...areaList,...item]
            })
            resolve(areaList)
          })
        }else{
          resolve(areaListIn)
        }
      })
    },

    autoChange() {
      let that = this
      let list = ['consulting', 'archives', 'duty']
      that.changerTimer = setInterval(() => {
        that.num += 1
        if (that.num > 2) {
          that.num = 0 // 循环
        }
        for (let n in list) {
          if (n == that.num) {
            that.topList.selected = list[n]
          }
        }
      }, 8000)
    },
    // changeActive : function (){
    //   console.log(1)
    //   clearInterval(this.changerTimer)
    // },
    // removeActive : function (){
    //   console.log(2)
    //   setInterval(this.changerTimer)
    // },
  },
}
</script>
