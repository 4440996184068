<style scoped>
	.header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
	.menu{font-size:0.24rem;padding:0.3rem 0.3rem;}
	.list{padding:0.2rem 0;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<Modal
			v-model="detialPanel.state"
			title="申请详情"
			ok-text="关闭"
			cancel-text
		>
			<template v-if="detialPanel.data">
        <div>姓名：{{detialPanel.data.ProposerName}}</div>
        <div>联系电话：{{detialPanel.data.ProposerPhone}}</div>
        <div>处理进度：{{detialPanel.data.ProcessingTitle}}</div>
				<div>纠纷类型：{{detialPanel.data.MediationType}}</div>
        <div>纠纷描述：{{detialPanel.data.UserSummarize}}</div>
        <Divider style="margin:0.3rem 0;"/>
				<div>性别：{{detialPanel.data.ProposerSex}}</div>
				<div>身份证号码：{{detialPanel.data.ProposerIDCard}}</div>
				<div>民族：{{detialPanel.data.ProposerNation}}</div>
				<div>出生日期：{{detialPanel.data.ProposerBirthdate}}</div>
				<div>年龄：{{detialPanel.data.ProposerAge}}</div>
				<div>住所地址：{{detialPanel.data.ProposerAddress}}</div>
			</template>
		</Modal>
		<div class='header'></div>
		<div class='body'>
			<div class="menu">
				<Row>
          <Col span="16" style="font-size:0.3rem;font-weight: bold;">
            <Icon type="ios-list" style="font-size:0.5rem;"/>百米服务圈 - 我申请的调解
          </Col>
					<!-- <Col span="8" style="text-align: right;">
						<router-link :to="{name:'CJYMUAS1'}">
              <span style="background-color:#008fd6;padding:0.17rem 0.45rem;border-radius:0.1rem;color: #FFF;">去申请</span>
            </router-link>
					</Col> -->
				</Row>
			</div>
			<ul v-if="list.length>0">
				<li v-for="(item,index) in list" :style="index%2==0?'background-color: #fff;':''" class='list'>
					<Row type="flex" align="middle" style="font-size:0.2rem;padding:0.1rem 0.3rem;">
						<Col span="20">
							<Row>
								<Col span="24" style="font-size: 0.28rem;font-weight: bold;padding-bottom:0.1rem">{{item.MediationType}} | 调解员：{{item.MediatorName}} </Col>
								<Col span="24" style="color:#666666">
									进度：<span style="color: red;">{{item.ProcessingTitle}}</span> | 申请时间：{{item.CreatedTime}}
								</Col>
							</Row>
						</Col>
						<Col span="4" style="color:#008fd6"><span @click="getDetial(item)">查看详情</span></Col>
					</Row>
				</li>
			</ul>
			<div v-else style="background-image:url(/img/empty.png);height:4.95rem;width:6.9rem;background-size:100% 100%;margin:1.1rem auto;"></div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'list',
		data(){
			return{
				list:[],
				detialPanel:{
					state:false,
					data:{},
				},
			}
		},
		mounted(){
      var _this = this
      var retryTiems = 0
      var tempTime = setInterval(()=>{
				if(_this.$store.state.userInfo.AdminId){
					this.getList()
          clearInterval(tempTime)
          if(retryTiems >= 20){
            _this.$Modal.error({
              title:'系统通知',
              content:'未获取到用户信息！请重新进入或检查网络！',
            })
            clearInterval(tempTime)
          }
				}
			},200)
			_this.setPageSize()
		},
		methods:{
			getList:function(){
				var _this = this
				_this.send({
					url:'/UserMediationList',
					data:{
						UserId:_this.$store.state.userInfo.UserId,
					},
					success:function(res){
						_this.list = res
					}
				})
      },
			getDetial:function(item){
				var _this = this
				_this.send({
					url:'/MediationContent_',
					data:{
						MediationId :item.MediationId,
					},
					success:function(res){
						_this.detialPanel.state = true
						_this.detialPanel.data = res
					}
				})
			},
			setPageSize:function() {
			    var wsw = document.body.clientWidth
			    document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
			},
		},
	}
</script>
