<template>
  <div>
    <div style="padding:20px;">
      <div style="font-size:16px;text-align: left;border-left:solid #de4f0b 3px;padding-left:5px;" >
        <span>区域分布</span>
        <span style="margin-left: 30px">共{{ difficultCount.length }}条</span>
      </div>
    </div>

    <div class="publicWelfare">
      <table class="formtable">
        <thead>
        <tr>
          <!--<th v-if="information.adminLevel == 0">省数</th>-->
          <th>序号</th>
          <th>名称</th>
          <th v-if="information.adminLevel == 0">市州数</th>
          <th v-if="information.adminLevel == 1 || information.adminLevel == 0">
            <span v-if="information.AdminId == '275'">乡镇数</span>
            <span v-else>区县数</span>
          </th>
          <th v-if="information.Hierarchy < 3">街道（司法所）数</th>
          <th>服务村（社区）</th>
          <th>公证员数</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, index) in difficultCount">
          <td>{{ index+1 }}</td>
          <td>{{ value.AdminName }}</td>
          <td v-if="information.adminLevel == 0">{{ value.shiju_count }}</td>
          <td v-if="information.adminLevel == 1 || information.adminLevel == 0">
            {{ value.quxian_count }}
          </td>
          <td v-if="information.Hierarchy < 3">
            {{ value.AdminCount }}
          </td>
          <td  @click="orderTypeCount(1)">
            {{ value.VillagesCount }}
          </td>
          <td>{{ value.GreffierCount }}</td>
          <td>
            <router-link style="margin: 0 10px 0 10px" :to="{path:'/manager/loginGzy', query:{adminLogin:value.AccountLogin,adminPassword:require('js-base64').Base64.encode(value.AccountPassword)}}" target="_blank">
              <Button type="primary">
                <img src="/img/icon/yijian.png" style="vertical-align: top;margin-top: 1px;"/>
                一键登录
              </Button>
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "regionAdmin",
  data(){
    return{
      difficultCount : [],
      information : [],
    }
  },
  methods:{
    insderCover : function (){
      var _this = this
      _this.send({
        url: '/greffierCover',
        data: {
          AdminId: _this.information.AdminId,
        },
        success: function(res) {
          _this.difficultCount = res
        }
      })
    },
  },
  mounted() {
    this.information = window.sessionStorage
    this.insderCover()
  }
}
</script>

<style scoped>
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>