//分区域总统计表
export var areaTable =[
  {title:'序号',type:'index',align:'center',fixed:'left',width:60,},
  {title:'区域',key:'areaName',fixed:'left',width:130,},
  {title:'新增顾问(个)',key:'newLawyer',sortable:true,minWidth:80},
  {title:'累计顾问(个)',key:'totalLawyer',sortable:true,minWidth:80},
  {title:'新增用户(个)',key:'newUser',sortable:true,minWidth:80},
  {title:'累计用户(个)',key:'totalUser',sortable:true,minWidth:80},
  {title:'新增服务(次)',key:'newService',sortable:true,minWidth:80,titleName:'服务次数为用户使用的咨询、查询服务次数',renderHeader: (h, params) => {
      return h('Tooltip', {
        props: {
          placement: 'top',transfer: true
        },
      },[params.column.title,
        h('span', {
          slot: 'content',
          style: {
            whiteSpace: 'normal',
            wordBreak: 'break-all',
            zIndex : '999999999'
          }
        }, params.column.titleName)
      ]
      )
    }},
  {title:'累计服务(次)',key:'totalService',sortable:true,minWidth:80,titleName:'服务次数为用户使用的咨询、查询服务次数',renderHeader: (h, params) => {
      return h('Tooltip', {
            props: {
              placement: 'top',transfer: true
            },
          },[params.column.title,
            h('span', {
              slot: 'content',
              style: {
                whiteSpace: 'normal',
                wordBreak: 'break-all',
                zIndex : '999999999'
              }
            }, params.column.titleName)
          ]
      )
    }},
  {title:'新增档案(个)',key:'newArchives',sortable:true,minWidth:80},
  {title:'累计档案(个)',key:'totalArchives',sortable:true,minWidth:80},
  {title:'新增评价(个)',key:'newEvaluate',sortable:true,minWidth:80},
  {title:'累计评价(个)',key:'totalEvaluate',sortable:true,minWidth:80},
  {title:'新增服务时长（分）',key:'newServiceDuration',sortable:true,minWidth:80},
  {title:'累计服务时长（分）',key:'totalServiceDuration',sortable:true,minWidth:80},
  // {title:'新增登录(次)',key:'lawyerTotal',sortable:true,minWidth:80},
  // {title:'累计登录(次)',key:'lawyerTotal',sortable:true,minWidth:80},
  // {title:'新增访问(次)',key:'lawyerTotal',sortable:true,minWidth:80},
  // {title:'累计访问(次)',key:'lawyerTotal',sortable:true,minWidth:80},
]

//区域咨询申办统计表
export var consultationAreaTable =[
  {title:'序号',type:'index',align:'center',fixed:'left',width:60,},
  {title:'区域',key:'areaName',fixed:'left',width:150,},
  {title:'新增自助咨询(次)',key:'newSelfConsult',sortable:true,minWidth:80},
  {title:'累计自助咨询(次)',key:'totalSelfConsult',sortable:true,minWidth:80},
  {title:'新增留言咨询(次)',key:'newMsgConsult',sortable:true,minWidth:80},
  {title:'累计留言咨询(次)',key:'totalMsgConsult',sortable:true,minWidth:80},
  {title:'新增电话咨询(次)',key:'newTelConsult',sortable:true,minWidth:80},
  {title:'累计电话咨询(次)',key:'totalTelConsult',sortable:true,minWidth:80},
  // {title:'新增现场咨询(次)',key:'newSceneConsult',sortable:true,minWidth:80},
  // {title:'累计现场咨询(次)',key:'totalSceneConsult',sortable:true,minWidth:80},
  // {title:'新增咨询档案(个)',key:'newConsultArch',sortable:true,minWidth:80},
  // {title:'累计咨询档案(个)',key:'totalConsultArch',sortable:true,minWidth:80},
  // {title:'新增咨询评价(次)',key:'newConsultEval',sortable:true,minWidth:80},
  // {title:'累计咨询评价(次)',key:'totalConsultEval',sortable:true,minWidth:80},
  {title:'新增申请援助(次)',key:'newApplyAid',sortable:true,minWidth:80},
  {title:'累计申请援助(次)',key:'totalApplyAid',sortable:true,minWidth:80},
  {title:'新增申请调解(次)',key:'newApplyMediate',sortable:true,minWidth:80},
  {title:'累计申请调解(次)',key:'totalApplyMediate',sortable:true,minWidth:80},
]

//顾问咨询服务统计表
export var consultingLawyerTable =[
  {title:'序号',type:'index',align:'center',fixed:'left',width:70,},
  {title:'姓名',key:'lawyerName',fixed:'left',width:80,},
  {title:'新增留言咨询(次)',key:'newMsgConsult',sortable:true,minWidth:80},
  {title:'累计留言咨询(次)',key:'totalMsgConsult',sortable:true,minWidth:80},
  {title:'新增电话咨询(次)',key:'newTelConsult',sortable:true,minWidth:80},
  {title:'累计电话咨询(次)',key:'totalTelConsult',sortable:true,minWidth:80},
  {title:'新增视频咨询(次)',key:'newVideoConsult',sortable:true,minWidth:80},
  {title:'累计视频咨询(次)',key:'totalVideoConsult',sortable:true,minWidth:80},
  {title:'新增现场咨询(次)',key:'newSceneConsult',sortable:true,minWidth:80},
  {title:'累计现场咨询(次)',key:'totalSceneConsult',sortable:true,minWidth:80},
  {title:'新增咨询档案(个)',key:'newConsultArch',sortable:true,minWidth:80},
  {title:'累计咨询档案(个)',key:'totalConsultArch',sortable:true,minWidth:80},
  {title:'新增咨询评价(次)',key:'newConsultEval',sortable:true,minWidth:80},
  {title:'累计咨询评价(次)',key:'totalConsultEval',sortable:true,minWidth:80},
]

//区域模块访问统计表
export var regionalModuleTable =[
  {title:'序号',type:'index',align:'center',fixed:'left',width:60,},
  {title:'区域',key:'areaName',fixed:'left',width:130,},
  {title:'自助咨询新增访问',key:'newReportkeywordClick',sortable:true,minWidth:80},
  {title:'自助咨询累计访问',key:'totalReportkeywordClick',sortable:true,minWidth:80},
  {title:'留言咨询新增访问',key:'newReportContact',sortable:true,minWidth:80},
  {title:'留言咨询累计访问',key:'totalReportContact',sortable:true,minWidth:80},
  {title:'电话咨询新增访问',key:'newReportPhone',sortable:true,minWidth:80},
  {title:'电话咨询累计访问',key:'totalReportPhone',sortable:true,minWidth:80},
  {title:'视频咨询新增访问',key:'newReportVideo',sortable:true,minWidth:80},
  {title:'视频咨询累计访问',key:'totalReportVideo',sortable:true,minWidth:80},
  {title:'法律援助新增访问',key:'newReportFlyzApplyVisit',sortable:true,minWidth:80},
  {title:'法律援助累计访问',key:'totalReportFlyzApplyVisit',sortable:true,minWidth:80},
  {title:'人民调解新增访问',key:'newReportMediateVisit',sortable:true,minWidth:80},
  {title:'人民调解累计访问',key:'totalReportMediateVisit',sortable:true,minWidth:80},
  {title:'公证申请新增访问',key:'newReportNotaryVisit',sortable:true,minWidth:80},
  {title:'公证申请累计访问',key:'totalReportNotaryVisit',sortable:true,minWidth:80},
  {title:'模拟判决新增访问',key:'newReportSimulationVisit',sortable:true,minWidth:80},
  {title:'模拟判决累计访问',key:'totalReportSimulationVisit',sortable:true,minWidth:80},
  {title:'法律文书新增访问',key:'newReportLegalVisit',sortable:true,minWidth:80},
  {title:'法律文书累计访问',key:'totalReportLegalVisit',sortable:true,minWidth:80},
  {title:'法律法规库新增访问',key:'newReportRegulationsVisit',sortable:true,minWidth:80},
  {title:'法律法规库科技访问',key:'totalReportRegulationsVisit',sortable:true,minWidth:80},
  // {title:'重庆文件库新增访问',key:'',sortable:true,minWidth:80},
  // {title:'重庆文件库累计访问',key:'',sortable:true,minWidth:80},
  {title:'司法案例库新增访问',key:'newReportJudicialVisit',sortable:true,minWidth:80},
  {title:'司法案例库累计访问',key:'totalReportJudicialVisit',sortable:true,minWidth:80},
  {title:'企业征信新增访问',key:'newReportCreditVisit',sortable:true,minWidth:80},
  {title:'企业征信累计访问',key:'totalReportCreditVisit',sortable:true,minWidth:80},
  {title:'民法典新增访问',key:'newReportCivilCodeVisit',sortable:true,minWidth:80},
  {title:'民法典累计访问',key:'totalReportCivilCodeVisit',sortable:true,minWidth:80},
  {title:'学法用法新增访问',key:'newReportLawCirclesVisit',sortable:true,minWidth:80},
  {title:'学法用法累计访问',key:'totalReportLawCirclesVisit',sortable:true,minWidth:80},
  {title:'法治地图新增访问',key:'newReportMapOfLawVisit',sortable:true,minWidth:80},
  {title:'法治地图累计访问',key:'totalReportMapOfLawVisit',sortable:true,minWidth:80},
  {title:'网上立案新增访问',key:'newOnRegistrationData',sortable:true,minWidth:80},
  {title:'网上立案累计访问',key:'totalOnRegistrationData',sortable:true,minWidth:80},
  {title:'国务院政策文件库新增访问',key:'newRegulationsData',sortable:true,minWidth:80},
  {title:'国务院政策文件库累计访问',key:'totalRegulationsData',sortable:true,minWidth:80},
  {title:'重庆院政策文件库新增访问',key:'newInvestigationData',sortable:true,minWidth:80},
  {title:'重庆院政策文件库累计访问',key:'totalInvestigationData',sortable:true,minWidth:80},
]

//区域村居治理统计表
export var regionLitongTable =[
  {title:'序号',type:'index',align:'center',fixed:'left',width:60,},
  {title:'区域',key:'areaName',fixed:'left',width:130,},
  {title:'新增代拟文书(件)',key:'newReportWrit',sortable:true,minWidth:80},
  {title:'累计代拟文书(件)',key:'totalReportWrit',sortable:true,minWidth:80},
  {title:'新增审核文件(件)',key:'newReportCheckFile',sortable:true,minWidth:80},
  {title:'累计审核文件(件)',key:'totalReportCheckFile',sortable:true,minWidth:80},
  {title:'新增列席会议(次)',key:'newReportAttendCf',sortable:true,minWidth:80},
  {title:'累计列席会议(次)',key:'totalReportAttendCf',sortable:true,minWidth:80},
  {title:'新增参加活动(次)',key:'newReportCampaign',sortable:true,minWidth:80},
  {title:'累计参加活动(次)',key:'totalReportCampaign',sortable:true,minWidth:80},
  {title:'新增法治宣讲(次)',key:'newReportLawPreach',sortable:true,minWidth:80},
  {title:'累计法治宣讲(次)',key:'totalReportLawPreach',sortable:true,minWidth:80},
  {title:'新增参与调解(次)',key:'newReportInMediate',sortable:true,minWidth:80},
  {title:'累计参与调解(次)',key:'totalReportInMediate',sortable:true,minWidth:80},
  {title:'新增接处信访(次)',key:'newReportPetitionDeal',sortable:true,minWidth:80},
  {title:'累计接处信访(次)',key:'totalReportPetitionDeal',sortable:true,minWidth:80},
  {title:'新增值班接待(次)',key:'newReportDuty',sortable:true,minWidth:80},
  {title:'累计值班接待(次)',key:'totalReportDuty',sortable:true,minWidth:80},
  {title:'新增其他服务(次)',key:'newReportOther',sortable:true,minWidth:80},
  {title:'累计其他服务(次)',key:'totalReportOther',sortable:true,minWidth:80},
]

//顾问村居治理服务统计表
export var adviserGovernmentTable =[
  {title:'序号',type:'index',align:'center',fixed:'left',width:70,},
  {title:'姓名',key:'lawyerName',fixed:'left',width:80,},
  {title:'新增代拟文书',key:'newReportLawyerWrit',sortable:true,minWidth:80},
  {title:'累计代拟文书',key:'totalReportLawyerWrit',sortable:true,minWidth:80},
  {title:'新增审核文件',key:'newReportLCheckFile',sortable:true,minWidth:80},
  {title:'累计审核文件',key:'totalReportLCheckFile',sortable:true,minWidth:80},
  {title:'新增列席会议',key:'newReportLAttendCf',sortable:true,minWidth:80},
  {title:'累计列席会议',key:'totalReportLAttendCf',sortable:true,minWidth:80},
  {title:'新增参加活动',key:'newReportLCampaign',sortable:true,minWidth:80},
  {title:'累计参加活动',key:'totalReportLCampaign',sortable:true,minWidth:80},
  {title:'新增法治宣讲',key:'newReportLawyerPreach',sortable:true,minWidth:80},
  {title:'累计法治宣讲',key:'totalReportLawyerPreach',sortable:true,minWidth:80},
  {title:'新增参与调解',key:'newReportLawyerInMediate',sortable:true,minWidth:80},
  {title:'累计参与调解',key:'totalReportLawyerInMediate',sortable:true,minWidth:80},
  {title:'新增接处信访',key:'newReportLPetitionDeal',sortable:true,minWidth:80},
  {title:'累计接处信访',key:'totalReportLPetitionDeal',sortable:true,minWidth:80},
  {title:'新增值班接待',key:'newReportLawyerDuty',sortable:true,minWidth:80},
  {title:'累计值班接待',key:'totalReportLawyerDuty',sortable:true,minWidth:80},
  {title:'新增其他服务',key:'newReportLawyerOther',sortable:true,minWidth:80},
  {title:'累计其他服务',key:'totalReportLawyerOther',sortable:true,minWidth:80},
  {title:'新增服务档案',key:'newReportLawyerServiceFile',sortable:true,minWidth:80},
  {title:'累计服务档案',key:'totalReportLawyerServiceFile',sortable:true,minWidth:80},
  {title:'新增服务评价',key:'newReportLawyerEvaluate',sortable:true,minWidth:80},
  {title:'累计服务评价',key:'totalReportLawyerEvaluate',sortable:true,minWidth:80},
]

//顾问村居治理服务统计表
export var advisoryPresidentTable =[
  {title:'序号',type:'index',align:'center',fixed:'left',width:60,},
  {title:'姓名',key:'lawyerName',fixed:'left',width:80,},
  {title:'新增咨询请求',key:'newReportLOrder',sortable:true,minWidth:80},
  {title:'累计咨询请求',key:'totalReportLOrder',sortable:true,minWidth:80},
  {title:'新增办理咨询请求',key:'newReportLOrderDeal',sortable:true,minWidth:80},
  {title:'累计办理咨询请求',key:'totalReportLOrderDeal',sortable:true,minWidth:80},
  {title:'新增村居治理请求',key:'newReportLVillageGovernance',sortable:true,minWidth:80},
  {title:'累计村居治理请求',key:'totalReportLVillageGovernance',sortable:true,minWidth:80},
  {title:'新增电子档案',key:'newReportLawyerTotalFile',sortable:true,minWidth:80},
  {title:'累计电子档案',key:'totalReportLawyerTotalFile',sortable:true,minWidth:80},
  {title:'新增评价',key:'newReportLawyerEvaluate',sortable:true,minWidth:80},
  {title:'累计评价',key:'totalReportLawyerEvaluate',sortable:true,minWidth:80},
]
