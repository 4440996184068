<style scoped>
.bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
.introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
.introduce-video-player{width:100%;}
.mediator-list{color:#FFF;}
.mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
.mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
.introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
.introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-top: 0.25rem;}
.introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <div class="introduce-text">
      <div class="introduce-text-content">
        <div v-for="(value,index) in guideContent">
          <div class="introduce-text-title">{{value[0]}}</div>
          <div class="introduce-text-content" v-for="(value1,index1) in value" v-if="index1>0">
            {{value1}}
          </div>
        </div>
        <div class="introduce-text-title">微信公众号迁移</div>
        <div class="introduce-text-content">
          1、营业执照</br>
          2、授权委托书（法定代表人签字）</br>
          3、身份证（法定代表人、受托人）</br>
          4、迁移申请书（3份）</br>
          5、公章
        </div>
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
    <Row style="text-align: center;">
      <Col span="24"><a href="https://scjys.egongzheng.com/H5/templates/index.html"><Button type="warning" style="width:4rem;">去 办 理</Button></a></Col>
    </Row>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'guild3',
  data() {
    return {
      guideContent:[
        [
          '（自然人申办）其它财产委托公证需提交的材料',
          '1．申请人身份证明；',
          '2．委托书涉及的财产权利凭证，价值小于人民币2万元的非登记类动产可不提供；',
          '3．受托人身份证明影印件或者复印件；',
          '4．涉及财产处分的，提交婚姻状况证明。',
        ],
        [
          '（自然人申办）一般事务性委托公证需提交的材料',
          '1．申请人身份证明；',
          '2．受托人身份证明影印件或者复印件。',
        ],
        [
          '（法人申办）委托公证需提交的材料',
          '1．申请人营业执照；',
          '2．法定代表人资格证明及身份证明；',
          '3．委托书涉及授权事项的材料；',
          '4．受托人身份证明影印件或者复印件；',
          '5．拟办理公证的授权委托书（带电子版，以备修改）；',
          '6．涉及公司重大事项的，需提供股东会议决议。',
        ],
        [
          '涉及法律事实的声明公证需提交的材料',
          '1．申请人身份证明；',
          '2．出生、死亡、婚姻状况事实的证明材料。',
        ],
        [
          '放弃继承声明公证需提交的材料',
          '1．申请人身份证明；',
          '2．死者的死亡证明材料；',
          '3．申请人与死者的关系证明；',
          '4．要放弃的财产证',
        ],
        [
          '知识产权类声明公证需提交的材料',
          '1．申请人身份证明，法人的提供营业执照；',
          '2.知识产权权利证明；',
          '3.法人的提供法定代表人身份证明；',
          '4．涉及知识产权权利转让的，提交股东会决议证明。',
        ],
        [
          '（自然人）申办签名（捺印）公证需提交的材料',
          '1．申请人的身份证明；',
          '2．需证明签名（捺印）的文书。',
        ],
        [
          '（法人）申办签名（印鉴）公证需提交的材料',
          '1．公司法定代表人的身份证明；',
          '2．公司营业执照的正本或副本；',
          '3．需证明签名（印鉴）的文书。',
        ],
        [
          '申办赠与公证需提交的材料',
          '1．赠与人、受赠人的身份证明；',
          '2．赠与人的婚姻状况证明；',
          '3．赠与物权利凭证；',
          '4．赠与人与受赠人的关系证明。',
        ],
        [
          '申办遗赠扶养协议公证需提交的材料',
          '1．遗赠人及遗赠扶养人的身份证明；',
          '2．扶养人是非自然人的，应提交资格证明、法定代表人身份证明及其复印件；代理人代为申请的，代理人应提交授权委托书及代理人的身份证明；',
          '3．扶养人已婚的，应提交结婚证和配偶同意订立遗赠扶养协议的书面意见；',
          '4．遗赠财产权利证明和遗赠财产清单。',
        ],
        [
          '申办财产约定协议公证需提交的材料',
          '1．协议双方的身份证明；',
          '2．婚姻状况证明；',
          '3．拟办理公证的财产协议书；',
          '4．有关财产凭证。',
        ],
        [
          '申办监护协议公证需提交的材料',
          '1．监护人、被监护人的身份证明；',
          '2．被监护人需要监护的证明材料以及监护人与被监护人之间关系的证明材料；',
          '3．不是法定监护人且自愿担任监护人的，须经被监护人住所地的村（居）委会或者民政部门同意的证明；',
          '4．监护协议草稿。',
        ],
        [
          '申办意定监护协议公证需提交的材料',
          '1．监护人、被监护人的身份证明；',
          '2．意定监护协议草稿；',
          '3．协议中约定有财产代管的提供代管财产凭证及财产清单；',
          '4．被监护人亲属关系材料；',
        ],
        [
          '申办房产买卖合同公证需提交的材料',
          '1．出售方为单位的应提供下列材料：',
          '（1）房屋所有权证书；',
          '（2）营业执照、法定代表人身份证明，法人授权委托书，受托人身份证；',
          '（3）法人组织转让房产的，提供上级主管部门或国有资产管理部门同意其转让房产的批准文件及董事会（股东会）决议。',
          '2．出售方为个人的应提供下列材料：',
          '（1）提供个人身份证明，婚姻状况证明；',
          '（2）房产共有人应共同申办公证或提供经公证的同意共有人出卖房产的声明书；',
          '（3）房屋所有权证书。',
          '3．购买方应提供下列材料：',
          '（1）购买方为个人的，提供个人身份证明；',
          '（2）购买方为企业法人组织的，提供法人资格证明、法定代表人身份证明、公司章程、股东会决议；',
          '（3）代理人代办公证的，须提供有代理权资格的证明及身份证明。',
        ],
        [
          '申办股权转让合同公证需提交的材料',
          '1．合资（作）公司提供的材料：',
          '（1）公司的营业执照；',
          '（2）公司的章程；',
          '（3）由工商局出具的股东情况证明，中外合资企业、外商独资企业可提供政府批准证书；',
          '（4）经注册的会计师事务所出具的公司验资证明；',
          '（5）有关股权转让的股东会决议；外商投资企业提供有关股权转让的董事会决议。',
          '2．转让方、受让方需提供的材料：',
          '（1）公司的营业执照或公司注册证书；',
          '（2）法定代表人资格证明。委托办理的，提供授权委托书，受托人的身份证；',
          '（3）转让方、受让方是有限责任公司的，需提供本公司同意转让或受让股权的股东会决议；转让方、受让方是个人，需提供本人身份证明；',
          '（4）转让方或受让方是外商或港澳台商，所提供的材料为董事会决议、授权委托书、商业登记证，香港公司的材料需经中国司法部指定的香港律师办理见证；其他地区或国家的应到当事人所在地公证机构办理公证或经中国驻该国大使馆或领事馆办理认证；',
          '（5）涉及国有资产的，还需提供有资产评估资格的会计师事务所出具的资产评估报告、国有资产管理部门批准按某价格转让的文件；',
          '（6）上市公司的应提供股权是否设定质押的证明材料。',
        ],
        [
          '申办保全证据公证需提交的材料',
          '1．自然人申请人的身份证明；',
          '2．法人应提供法人资格证明、法定代表人的身份证明。如代理人代办应提供法人授权委托书和代理人的身份证明；',
          '3．需保全证据的有关情况的材料，如拆迁房屋的拆迁证明，房屋所有权证、书证、合同、物证等；',
          '4．申请人与保全的证据有利害关系的证明材料。',
        ],
        [
          '申办出生公证需提交的材料',
          '1．申请人的身份证证明；',
          '2．出生证明材料；',
          '3．父母的身份证明和结婚证，若父或母已死亡的需提供死亡证明材料；',
          '4．非婚生子女提供亲子鉴定报告（司法鉴定机构出具）。',
          '5．单人免冠正面近期大1寸（或2寸）相片3张（美国使用的出生公证，提供4张）；',
          '6．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办出生证公证需提交的材料',
          '1．申请人及其父母的身份证明；',
          '2．出生证；',
          '3．父母的结婚证，若父或母已死亡的需提供死亡证明；',
          '4．非婚生子女提供亲子鉴定报告（司法鉴定机构出具）；',
          '5．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办已婚公证需提交的材料',
          '1．申请人及其配偶的身份证明；',
          '2．结婚证；',
          '3．再婚的，提供离婚的证明，丧偶的提供原配偶的死亡证明；',
          '4．夫妻两人正面2寸合照3张（往美国使用的提供4张）；',
          '5．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办未（再）婚公证需提交的材料',
          '1．申请人的身份证明；',
          '2．离婚未再婚的，提供离婚证明；配偶死亡未再婚的需提供配偶死亡证明；',
          '3．单人免冠正面近期大1寸（或2寸）相片3张（美国使用的提供4张）；',
          '4．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办无（有）犯罪记录公证需提交的材料',
          '1．申请人的身份证明；',
          '2．公安机关（派出所）出具的无（有）犯罪记录证明；',
          '3．委托他人办理的，提供授权委托书、代理人的身份证明。',
          '注：单人免冠正面近期大1寸（或2寸）相片3张（仅限到哥伦比亚使用）。',
        ],
        [
          '申办工作经历公证需提交的材料',
          '1．申请人的身份证明；',
          '2．工作经历证明。企业单位的需提供《营业执照》；',
          '3．任职文件、劳动合同或聘用合同；',
          '4．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办学历公证需提交的材料',
          '1．申请人的身份证明；',
          '2．毕业证书；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办学位公证需提交的材料',
          '1．申请人的身份证明；',
          '2．学位证书；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办成绩单公证需提交的材料',
          '1．申请人的身份证明；',
          '2．学校出具的成绩单；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办驾驶证公证需提交的材料',
          '1．申请人的身份证明；',
          '2．申请人的驾驶证；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办房产证公证需提交的材料',
          '1．申请人的身份证明；',
          '2．房产证；',
          '3．不动产登记查询资料；',
          '4．法人申请办理房产证公证的，应提交法人的营业执照、法人的委托书、法定代表人的身份证明（复印件需加盖单位公章）；',
          '5．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办存款证明公证需提交的材料',
          '1．申请人的身份证明；',
          '2．个人存款凭证（如完整记录的存折、存单等），需证明的存款若是人民币的应是已存满三个月的定期存款；',
          '3．存款所在金融机构出具的存款证明。',
        ],
        [
          '申办资助出国留学协议公证需提交的材料',
          '1．国家留学基金管理委员会：',
          '（1）公证申请表（公证机构登录国家留学基金管理委员会网站http://www.csc.edu.cn下载）；',
          '（2）承诺书（公证机构登录国家留学基金管理委员会网站http://www.csc.edu.cn下载）；',
          '（3）资助出国留学协议书（一式若干份，由留学人员带至公证处）。',
          '2．留学人员：',
          '（1）身份证明；',
          '（2）国家留学基金资助出国留学资格证书；',
          '（3）录取通知书（国家留学基金管理委员会函件，该函件能体现国家留学基金管理委员会资助的金额）。',
          '3．经济保证人：',
          '（1）身份证、户口簿；',
          '（2）婚姻状况证明。',
        ],
        [
          '申办毕业证书公证需提交的材料',
          '1．申请人的身份证明；',
          '2．申请人的毕业证书原件；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办学位证书公证需提交的材料',
          '1．申请人的身份证明；',
          '2．毕业证书、学位证书原件；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办营业执照公证需提交的材料',
          '1．公司法定代表人的身份证明；',
          '2．公司营业执照的正本或副本原件；',
          '3．不是法定代表人亲自办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办结婚证公证需提交的材料',
          '1．申请人的身份证明；',
          '3．结婚证原件；',
          '4．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办离婚证公证需提交的材料',
          '1．申请人的身份证明；',
          '3．离婚证原件；',
          '4．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办护照公证需提交的材料',
          '1．申请人的身份证明；',
          '2．申请人的护照原件；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办居民户口簿公证需提交的材料',
          '1．申请人的身份证明；',
          '2．申请人的户口簿原件；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办身份证公证需提交的材料',
          '1．申请人的身份证原件；',
          '2．公证书用途的相关资料。',
        ],
        [
          '申办户籍注销公证需提交的材料',
          '1．申请人的身份证明；',
          '2．原户籍所在地公安机关开具的户籍注销证明；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办个人所得税完税证明公证证需提交的材料',
          '1．申请人的身份证明；',
          '2．税务部门出具的个人所得税完税证明原件；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办死亡公证（非正常死亡）需提交的材料',
          '1．申请人（即死者的利害关系人）的身份证明；',
          '2．申请人与死者具有法律上的利害关系的证明；',
          '3．公安机关出具的死亡证明；',
          '4．死者身份证明、户籍注销证明、死亡鉴定书或火化证等。',
        ],
        [
          '申办死亡公证（正常死亡）需提交的材料',
          '1．申请人（即死者的利害关系人）的身份证明；',
          '2．申请人与死者的具有法律上的利害关系的证明；',
          '3．医院或公安机关出具的死亡证明。',
        ],
        [
          '申办生存公证需提交的材料',
          '1．申请人的身份证明；',
          '2．单人免冠正面近期大1寸（或2寸）相片3张（美国使用的，提供4张）。',
        ],
        [
          '申办职务公证需提交的材料',
          '1．申请人的身份证明；',
          '2．单位人事部门出具的职务证明；',
          '3．企业法人单位的需提供营业执照的正本或副本；',
          '4．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办职称公证需提交的材料',
          '1．申请人的身份证明；',
          '2．职称证原件；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办收养公证需提交的材料',
          '（一）收养人应提交的材料：',
          '1．收养人的身份证明；',
          '2．收养人的婚姻状况证明；',
          '3．收养人无子女或只有一个子女，有抚养教育被收养人能力的证明；',
          '4．县级以上医疗机构出具的未患有在医学上认为不应当收养子女的疾病的身体健康检查证明。',
          '5．收养继子女的，只提交身份证明、收养人与被收养人生父或者生母结婚证。',
          '（二）送养人应提交的材料：',
          '1．送养人的身份证明；',
          '2．监护人送养孤儿的，应提交征得有抚养义务的人同意的书面意见；',
          '3．儿童福利机构作送养人的，应当提交弃婴、儿童进入社会福利机构的原始记录，公安机关出具的捡拾弃婴、儿童报案的证明，或者孤儿的生父母死亡或者宣告死亡的证明；',
          '4．监护人为送养人的，应当提交实际承担监护责任的证明，孤儿的父母死亡或者宣告死亡的证明，或者被收养人生父母无完全民事行为能力并对被收养人有严重危害的证明；',
          '5．生父母为送养人的，应当提交有特殊困难无力抚养子女的证明和送养人有特殊困难的声明。其中，因丧偶或者一方下落不明由单方送养的，还应当提交配偶死亡或者下落不明的证明；',
          '6．子女由三代以内旁系同辈血亲收养的，应当提交公安机关出具的或者经过公证的与收养人的亲属关系证明；',
          '7．被收养人是残疾儿童的，应当提交县级以上医疗机构出具的该儿童的残疾证明。',
        ],
        [
          '申办公司章程公证需提交的材料',
          '1．公司法定代表人的身份证明；',
          '2．公司营业执照的正本或副本；',
          '3．不是法定代表人亲自办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办影印本与原本相符公证需提交的材料',
          '1．申请人的身份证明；',
          '2．需证明文件（证书）的原件；',
          '3．委托他人办理的，还应提供授权委托书、代理人的身份证明。',
        ],
        [
          '申办招标投标公证需提交的材料',
          '1．法人资格证明和法定代表人身份证明，代为申请的，应提交授权委托书和代理人的身份证明；',
          '2．受委托招标的，应提交委托书和具有承办招标事项资格的证明；',
          '3．有关主管部门对招标项目、招标活动的批准文件；',
          '4．招标组织机构及组成人员名单；',
          '5．招标通知（公告）或招标邀请函；',
          '6．招标文件（主要包括：招标说明书、投标人须知、招标项目技术要求、投标书格式、投标保证文件、合同条件等）；',
          '7．对投标人资格预审文件；',
          '8．评标组织机构及组成人员名单。',
        ],
        [
          '申办拍卖公证需提交的材料',
          '1．拍卖机构的主体资格；',
          '2．拍卖物的基本情况；',
          '3．拍卖公告；',
          '4．拍卖委托书。',
          '本证明材料清单适用于一般的、常见的情况。但不同当事人和不同证件之间可能情况存在较大差别，需要的证明材料也将有所不同。如果你所提供的证据材料不严谨、不完整，不能准确证明所办公证事项时，应根据公证员要求补充证明材料。  '
        ]
      ]
    };
  },
  methods: {

  },
  mounted() {

  },
}
</script>
