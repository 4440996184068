<template>
  <div class="login background-white">
    <div class="min container vertical" >
      <div class="inline-block">
        <img class="left-img" src="/img/administration/left-img.png">
      </div>
      <div class="inline-block vertical-align" :style="{ 'margin-left': mobile ? '141px' : '50px' }">
        <div >
          <div
            style="background-position:center;background-image:url('/img/logoGov.png');height:150px;background-size:auto 100%;background-repeat:no-repeat">
          </div>
          <br />
          <p class="hello" :style="{ 'font-size': mobile ? '26px' : '' }">{{sysName}}</p>
          <p class="cooperation" style="text-align:center;"><span style="color: #2b8bec;">管理端</span> </p>
        </div>
        <div>
          <div class="input-Administration user1" :class="u==false ? 'typeselect' : 'typeselect1'">
            <!---->
            <img class="user" id="img_id" src="/img/administration/user.png">
            <input v-model="account" class="input-content" type="text" placeholder="请输用户名" id="txtUserName"
              autocomplete="on" @focus="changeActive($event)" @blur.prevent="removeActive($event)">
          </div>
          <div class="input-Administration password1" :class="m==false ? 'typeselect' : 'typeselect1'">
            <img class="user" id="lock" src="/img/administration/password.png">
            <input v-model="password" class="input-content" type="password" placeholder="请输密码" id="txtPassword"
                   @focus="changeActive1($event)" @blur.prevent="removeActive1($event)">
          </div>
          <div>
            <div class="input-Administration password1" :class="c==false ? 'typeselect' : 'typeselect1'" style="width: 310px;display: inline-block">
              <img class="user" id="lock" src="/img/administration/code.png" style="width: 21px;height: 20px;margin-left: 27px;">
              <input v-model="captcha" class="input-content" type="text" placeholder="请输验证码" id="txtPassword"
                     @focus="changeActive2($event)" @blur.prevent="removeActive2($event)" style="width: 200px;">
            </div>
            <img v-if="codeImg" @click="apiCode()" style="width: 130px;height: 45px;vertical-align: top;margin-top: 45px;margin-left: 10px;" :src="codeImg">
          </div>
          <!--<div class="inline-block" style="margin-left: 25px;margin-top: 22px;">-->
          <!--<a href="javascript:;">-->

          <!--<label>-->
          <!--<input v-model="checked" class="remember" checked="checked" type="checkbox" id="chkRememberPwd" @click="remember =!remember"><p class="inline-block remember-x">记住密码</p>-->
          <!--</label>-->
          <!--</a>-->
          <!--</div>-->
          <a href="javascript:;" @click="login()">
            <div class="signin">登 录</div>
          </a>



        </div>
      </div>
    </div>
    <div class="bottom-png min center">
      <a href="https://www.lvwa18.com" target="_blank">
        <p class="inline-block copyright">技术支持：律蛙科技</p>
      </a>
      <p class="inline-block copyright" style="margin-left: 10px">400-066-8283</p>
      <!--<p class="inline-block copyright">Copyright © 2017 律蛙智能 版权所有（http://www.lvwa18.com）All Right.</p>-->
    </div>
  </div>
</template>

<script>
  export default {
    name: "login",
    data() {
      return {
        u: false,
        m: false,
        c: false,
        // remember : true,
        //
        checked: true,
        mobile: false,
        account: '',
        password: '',
        sysName : '',
        codeImg : '',
        captcha : '',
        key : ''
      }
    },
    methods: {
      // 鼠标移入加入class
      changeActive() {
        this.u = true
      },
      removeActive() {
        this.u = false
      }, changeActive1() {
        this.m = true
      },
      removeActive1() {
        this.m = false
      },
      changeActive2() {
        this.c = true
      },
      removeActive2() {
        this.c = false
      },
      apiCode : function (){
        var _this = this
        _this.send({
              url: '/code',
              data: {},
              success: function (res) {
                _this.codeImg = res.img
                _this.key = res.key
              }
        })
      },
      //
      login: function () {
        var _this = this
        _this.send({
          url: '/AccountLogin',
          warningTips: true,
          data: {
            AccountLogin: _this.account,
            AccountPassword: _this.password,
            AccountTypeId : '1',
            key: _this.key,
            captcha : _this.captcha,
          },
          success: function (res) {
            console.log(res.AdminId)
            // var objStr = JSON.stringify(res)JSON.stringify(res)
            window.sessionStorage.AdminId = res.AdminId
            window.sessionStorage.AdminLogin = res.AccountLogin
            window.sessionStorage.AdminName = res.AccountName
            window.sessionStorage.AccountTypeId = res.AccountTypeId
            window.sessionStorage.AreaName = res.AreaName
            window.sessionStorage.adminLevel = res.adminLevel
            window.sessionStorage.areaCode = res.AreaCode
            window.sessionStorage.Token = res.Token
            window.sessionStorage.Level = res.Level
            window.sessionStorage.AreaId = res.AreaId
            window.sessionStorage.Pid = res.Pid
            window.sessionStorage.ParentId = res.ParentId
            window.sessionStorage.Hierarchy = res.Hierarchy
            window.sessionStorage.AccountId = res.AccountId
            window.sessionStorage.IsSign = res.IsSign
            window.sessionStorage.information = JSON.stringify(window.sessionStorage)
            window.sessionStorage.parentIdArrStr = res.PidArr.toString()
            SYSCONFIG.server.information.AdminId = res.AdminId
            SYSCONFIG.server.information.areaCode = res.AreaCode
            SYSCONFIG.server.information.AdminLogin = res.AdminLogin
            SYSCONFIG.server.information.AdminName = res.AdminName
            SYSCONFIG.server.information.AreaName = res.AreaName
            SYSCONFIG.server.information.adminLevel = res.adminLevel
            SYSCONFIG.server.information.Level = res.Level
            SYSCONFIG.server.information.AreaId = res.AreaId
            SYSCONFIG.server.information.Pid = res.Pid
            SYSCONFIG.server.information.ParentId = res.ParentId
            SYSCONFIG.server.information.Hierarchy = res.Hierarchy
            SYSCONFIG.server.information.parentIdArrStr = res.PidArr
            SYSCONFIG.server.information.AccountId = res.AccountId
            SYSCONFIG.defaultData.Token = res.Token
            if(window.sessionStorage.ParentId == '1505' || window.sessionStorage.AreaId == '1505'){
              window.sessionStorage.sysName = '智慧公共法律服务平台'
            } else if(window.sessionStorage.ParentId == '2205' || window.sessionStorage.AreaId == '2205'){
              window.sessionStorage.sysName = '智慧村居法律服务平台'
            }else if(res.ParentId == '650'){
              window.sessionStorage.sysName = '智慧司法服务平台'
            }else if(res.AreaId == '3269'){
              window.sessionStorage.sysName = '德阳经开区智慧村（社区）法务平台'
            }else if(res.AreaId == '2104' || res.ParentId == '2104'){
              window.sessionStorage.sysName = '村（社区）法律服务平台'
            }else if(res.ParentId == '2320' || res.AreaId == '2320'){
              window.sessionStorage.sysName = '德阳市村(社区)法律服务平台'
            }else {
              if(SYSCONFIG.location == 'chong-qing'){
                window.sessionStorage.sysName = '重庆村居法务'
                window.sessionStorage.url = 'https://cq.lvwa18.com:8001/'
              }else{
                window.sessionStorage.sysName = '智慧公共法律服务平台'
                window.sessionStorage.url = 'https://vn-server.lvwa18.com/'
              }
            }
            if(window.sessionStorage.AdminLogin == 'cqykt'){
              window.sessionStorage.cqykt = 1
            }
            _this.$router.push({ name: 'managerIndex' })
            if(_this.password == '123456'){
              _this.$Message.warning('为了您的账户安全，请尽快修改初始密码');
            }
          },
          callback:function(res){
            _this.captcha = ''
            _this.apiCode()
          }
        })

      },
      _isMobile() {
        if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
          this.mobile = false // 移动端
        }else{
          this.mobile = true // PC端
        }
      }
    },
    mounted() {
      //   this.$router.push({name:'adminhome'})
      var _this = this
      if(SYSCONFIG.location == 'chong-qing'){
        window.sessionStorage.sysName = '重庆村居法务'
        window.sessionStorage.url = 'https://cq.lvwa18.com:8001/'
      // }else if(window.location.hostname == 'hhhtsft.lvwa18.com'){
      }else if(window.location.hostname == 'hhhtsft.lvwa18.com'){
        window.sessionStorage.sysName = '呼和浩特司法通-后台管理端'
        window.sessionStorage.url = 'https://vn-server.lvwa18.com/'
      }else{
        window.sessionStorage.sysName = '律蛙数字法治-智慧司法平台'
        window.sessionStorage.url = 'https://vn-server.lvwa18.com/'
      }
      if (this.$route.query.adminLogin != undefined && this.$route.query.adminPassword != undefined) {
        _this.account = this.$route.query.adminLogin,
          _this.password = require('js-base64').Base64.decode(this.$route.query.adminPassword)
        _this.login()
      }else {
        _this.apiCode()
      }
      _this.sysName = window.sessionStorage.sysName
      var meta = document.getElementsByTagName('meta');
      meta[2].content = ''
      this._isMobile()
    },
    created() {
      let that = this;
      document.onkeypress = function (e) {
        var keycode = document.all ? event.keyCode : e.which;
        if(that.$route.name == 'managerLogin'){
          if (keycode == 13) {
            that.login();// 登录方法名
            return false;
          }
        }
      };
    }
  }
</script>

<style scoped>

    .container{width: 1200px;margin: auto auto;}

    .min{min-width: 1200px;}
    .background-white{background-color: white;position: fixed;height: 100%;width: 100%}
    .vertical{height: 450px;position: absolute;width: 1044px;left: 50%;margin-left: -522px;top:50%;margin-top: -300px;}
    .left-img{width: 443px;height: 446px;}
    .hello{font-size: 30px;color: #cccccc;text-align: center;}
    .cooperation{font-size: 28px;color: #cccccc;}
    .input-content{background:none;outline:none;border:0px;vertical-align: top;margin-top: 5px;color: #999999;height: 45px;width: 350px;display: inline-block}
    .input-Administration{width: 460px;height: 52px;background-color: #fafafa;border-radius:26px;margin-top: 40px;}
    .user{width: 17px;height: 20px;margin-left: 31px;margin-right: 23px;margin-top: 16px;}
    .remember{width:15px;height:15px;border: 1px solid #4a83f6;font-size: 18px;line-height: 16px;text-align: center;color: #4a83f6;}
    .remember-x{color: #4a83f6;vertical-align: top;margin-top: 1px;line-height: 16px;margin-left: 10px;}
    .signin{background-color: #519eec;color: white;font-size: 14px;border-radius: 26px;width: 460px;height: 52px;line-height: 52px;text-align: center;margin-top: 35px;}


    /*管理*/

    .lvwa_texe div{width: 33%;}
    .layui-input{border: 0;background-color: rgb(244,245,249);height: 30px;}
    .layui-input,textarea,select,a:focus {outline: none;}
    .jump_page,textarea,select,a:focus {outline: none;}

    .lvwa_texe1 div{width:22%;white-space:nowrap;overflow:hidden}
    .bottom-png {
        background: url(/img/administration/bottom-png.png) no-repeat;
        width: 100%;
        height: 281px;
        background-size: 100% 100%;
        position: absolute;
        bottom: 0;
        z-index: -1;
    }
    .copyright {
        font-size: 14px;
        /*color: #519eec;*/
        color: #000;
        margin-top: 232px;
    }
    .typeselect{border: 0px; color: rgb(151, 196, 243); background-color: rgb(250, 250, 250);}
    .typeselect1{border: 1px solid rgb(137,188,241);color: rgb(151, 196, 243);background-color: white;}
</style>
