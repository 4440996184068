<style scoped>
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
  .details p{color: #000000}
  .details span{color: #5c6b77}
</style>

<template>
  <div>
    <Modal
              v-model="positionPanel.state"
              :title="positionPanel.name+'负责区域列表'"
              ok-text="保存"
              cancel-text="关闭"
              @on-ok="addMediator">
              <div class="publicWelfare">
              <table class="formtable" stripe show-header>
                <thead>
                <tr>
                    <th>村（社区）名称</th>
                    <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in positionPanel.ownList" style="text-align:center;">
                    <td>{{item.VillagesName}}</td>
                    <td>
                      <Button type="error" @click="deleteOwnPosition(item.MechanismId)">删除</Button>
                    </td>
                </tr>
                </tbody>
            </table>
            <Poptip title="选择点位" placement="bottom">
              <Button type="warning" @click="" >
                新增绑定村（社区）
              </Button>
              <div slot="content">
                <div style="cursor: pointer" @click="addOwnPosition(item)" v-for="(item,index) in positionPanel.list">{{item.VillagesName}}</div>
              </div>
          </Poptip>

          </div>
      </Modal>
    <Modal
              v-model="mediatorPanel.state"
              title="填写数据"
              ok-text="保存"
              cancel-text="关闭"
              @on-ok="addMediator">
          <div>姓名：<Input style="width:300px;" v-model="mediatorPanel.name"/></div>
          <div style="padding:10px"></div>
          <div>手机：<Input style="width:300px;" v-model="mediatorPanel.phone"/></div>
          <div style="padding:10px"></div>
          <div>密码：<Input style="width:300px;" v-model="mediatorPanel.passwd"/></div>
      </Modal>
      <Modal
              v-model="mediatorPanel.state"
              title="填写数据"
              ok-text="保存"
              cancel-text="关闭"
              @on-ok="addMediator">
          <div>姓名：<Input style="width:300px;" v-model="mediatorPanel.name"/></div>
          <div style="padding:10px"></div>
          <div>手机：<Input style="width:300px;" v-model="mediatorPanel.phone"/></div>
          <div style="padding:10px"></div>
          <div>密码：<Input style="width:300px;" v-model="mediatorPanel.passwd"/></div>
      </Modal>
    <div style="padding:15px"></div>
    <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px">
      调解员列表({{mediatorList.length}})
      <Button type="error" style="float:right;margin-right:20px" @click="mediatorPanel.state = true,mediatorPanel.id='',mediatorPanel.name='',mediatorPanel.phone=''">添加</Button>
    </div>
    <div style="padding:15px"></div>
              <div class="publicWelfare" >
                  <table class="formtable">
                      <thead>
                      <tr>
                          <th>姓名</th>
                          <th>手机号码</th>
                          <th>地点</th>
                          <th>操作</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(value,index) in mediatorList">
                          <td> {{value.MediatorName}}</td>
                          <td>{{value.MediatorPhone}}</td>
                          <td>{{value.MediatorAddress}}</td>
                          <td>
                            <Button type="success" @click="showPosition(value)">
                              查看负责区域
                            </Button>
                            <span style="padding:15px"></span>
                            <Button type="error" @click="deleteMediator(value)">删除</Button>
                            <span style="padding:15px"></span>
                            <Button
                              type="primary"
                              @click="mediatorPanel.state=true,mediatorPanel.id=value.MediatorId,mediatorPanel.name=value.MediatorName,mediatorPanel.phone=value.MediatorPhone">
                              编辑
                            </Button>
                          </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <div style="text-align: left;margin-left: 40px;font-size: 16px;padding-bottom: 20px">
                  <!-- <Page
                    :total="count"
                    show-total
                    :page-size="pageSize"
                    show-elevator
                    @on-change="page = $event;adminflyzApplyList()"
                  /> -->
              </div>
  </div>
</template>

<script>
  export default {
      name: "mediateList",
      data () {
          return {
            addMediatorPanel:{
              state:false,
            },
            mediatorPanel:{
              state:false,
              name:'',
              passwd:'',
              phone:'',
              id:'',
            },
            mediatorList:[],
              information : [],
              legalAidData : [],
              count : '0',
              pageSize : 10,
              page : 1,
              modal1 : false,
              detailsData : [],
              sfclmc : '',
              positionPanel:{
                id:'',
                name:'',
                state:false,
                list:[],
                ownList:[],
              },
          }
      },
      methods : {
        showPosition:function(item){
          this.positionPanel.id = item.MediatorId
          this.positionPanel.name = item.MediatorName
          this.getPositionList()
          this.selectOwnPosition(item.MediatorId)
        },
        selectOwnPosition:function(id){
          var _this = this
          _this.send({
            url:'/MediatorVillagesList',
            data:{
              MediatorId:id
            },
            success:function(res){
              _this.positionPanel.ownList = res
              _this.positionPanel.state = true
            }
          })
        },
        addOwnPosition:function(item){
          var _this = this
          _this.send({
            url:'/MediatorToVillages',
            data:{
              MediatorId:_this.positionPanel.id,
              VillagesId:item.VillagesId,
            },
            success:function(res){
              _this.$Notice.success({
                title:'系统提示',
                desc:'数据更新成功！'
              })
              _this.selectOwnPosition(_this.positionPanel.id)
            }
          })
        },
        deleteOwnPosition:function(id){
          var _this = this
          _this.send({
            url:'/MechanismDelete',
            data:{
              MechanismId:id,
            },
            confirm:true,
            success:function(res){
              _this.$Notice.success({
                title:'系统提示',
                desc:'数据更新成功！'
              })
              _this.selectOwnPosition(_this.positionPanel.id)
            }
          })
        },
        getPositionList:function(){
          var _this = this
          _this.positionPanel.list = []
          _this.send({
              url : '/FindBottomVillagesAndAdmin_',
              data: {
                  // AdminId : 5,
                  AdminId : window.sessionStorage.AdminId,
              },
              success: function (res) {
                  _this.positionPanel.list = res.Villages
              }
          })
        },
        deleteMediator:function(item){
          var _this = this
          _this.send({
            url:'/DeleteMediator',
            data:{
              MediatorId:item.MediatorId
            },
            confirm:true,
            success:function(res){
              _this.$Notice.success({
                title:'系统提示',
                desc:'数据更新成功！'
              })
              _this.adminflyzApplyList()
            },
          })
        },
        addMediator:function(){
          var _this = this
          var _url = '/NewMediator'
          if(_this.mediatorPanel.id){
            _url = '/UpdateMediator'
          }
          _this.send({
            url:_url,
            data:{
              MediatorPhone:_this.mediatorPanel.phone,
              MediatorName:_this.mediatorPanel.name,
              MediatorPassword:_this.mediatorPanel.passwd,
              MediatorId:_this.mediatorPanel.id,
              // AdminId:5,
              AdminId:window.sessionStorage.AdminId,
            },
            success:function(res){
              _this.$Notice.success({
                title:'系统提示',
                desc:'数据更新成功！'
              })
              _this.adminflyzApplyList()
            },
          })
        },
          adminflyzApplyList : function () {
              var _this = this
              _this.send({
                  url:'/AdminMediatorList',
                  data:{
                      // AdminId:5,
                      AdminId:window.sessionStorage.AdminId,
                  },
                  success:function (res) {
                    _this.mediatorList = res
                  }
              })
          },
          see : function (e) {
              console.log(e)
              this.detailsData = e
          },
          sfclmcImg : function (e) {
              this.sfclmc = e
          }
      },
      mounted (){
          var _this = this
          if(window.sessionStorage.adminLevel == undefined){
              // this.$router.push({name:'login'})
          }else {
              this.information = window.sessionStorage
              _this.adminflyzApplyList()
          }
      }
  }
</script>


