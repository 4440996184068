<style scoped>
*{font-size:14px;}
/*.articel :deep(.ivu-checkbox){position: absolute;left: 28px;top:20px;}*/
</style>

<template>
  <div style="padding:20px;">
    <Modal
        v-model="articlePushModal.state"
        :mask-closable="false"
        title="请选择推送的二级账户"
        @on-ok="pushArticle"
    >
      <Checkbox :indeterminate="indeterminate1" :value="checkAll1" @click.prevent.native="secondLevel">全选</Checkbox>
      <CheckboxGroup v-model="checkAllGroup1" @on-change="">
        <Checkbox v-for="(value,index) in articlePushModal.accountList" :label="value.AdminId">{{value.AdminName}}</Checkbox>
      </CheckboxGroup>
      <!--<accountTree @getAccountIdList="articlePushModal.accountIdList = $event" :listIn="{selectState:true,list:articlePushModal.accountList}"></accountTree>-->
    </Modal>
    <Modal
        v-model="articleModal.state"
        :mask-closable="false"
        title="编辑详情"
        :z-index="1"
        width=80
        @on-ok="saveArticel" fullscreen
    >
      <Row type="flex" align="middle">
        <Col span="1" style="text-align: right;">标题：</Col>
        <Col span="11"><Input v-model="articlelData.title"></Input></Col>
      </Row>
      <Divider dashed />
      <Row type="flex" align="middle">
        <Col span="1" style="text-align: right;">来源：</Col>
        <Col span="11"><Input v-model="articlelData.summary"></Input></Col>
      </Row>
      <Divider dashed />
      <Row>
        <Col span="1" style="text-align: right;">正文：</Col>
        <Col span="23"><VueUeditorWrap :config="editorConfig" v-model="articlelData.content"></VueUeditorWrap></Col>
      </Row>
    </Modal>
    <div>
      <Row style="border-bottom: #ccc solid 1px;padding-bottom:15px;">
        <Col span="12" style="text-align: left;font-size:24px;">热点法律</Col>
        <Col span="12" style="text-align:right;">
          <Button type="primary" @click="articleModal.state = true,articleModal.id ='',articlelData.title='',articlelData.summary='',articlelData.content=''">新增</Button>
        </Col>
      </Row>
      <Row style="margin-top:15px;border-bottom: #ccc solid 1px;padding-bottom:15px;">
        <Col span="2"><Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">全选</Checkbox></Col>
        <Col span="10" style="text-align: left;padding-left:25px;">标题</Col>
        <Col span="3">创建时间</Col>
        <Col span="3">修改时间</Col>
        <Col span="3">是否推送</Col>
        <Col span="3">操作 <Button type="primary" @click="getAccountList()">推送</Button></Col>
      </Row>

      <Row  style="height:55px;border-bottom: #ccc dashed 1px;" type="flex" align="middle" v-for="(item,index) in articleList" :label="item.HotNewsId">
        <Col span="2" style="text-align: center;">
          <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange" class="articel">
            <Checkbox  :label="item.HotNewsId">
              {{null}}
            </Checkbox>
          </CheckboxGroup>
        </Col>
        <Col span="10" style="text-align: left;padding-left:25px;">{{item.HotNewsTitle}}</Col>
        <Col span="3">{{item.HotNewsTime}}</Col>
        <Col span="3" style="line-height:55px;">{{item.HotNewsUpTime}}</Col>
        <Col span="3">
          <Tag :color="item.IsPush==1?'success':'warning'">{{item.IsPush==1?'已推送':'未推送'}}</Tag>
        </Col>
        <Col span="3" style="cursor: pointer;">
          <span style="color: #0000FF;" @click="editArticle(item.HotNewsId)">修改 </span>
          <span style="color: red;" @click="deleteArticle(item.HotNewsId)">删除 </span>
          <span style="color: green;" @click="articlePushModal.id=item.HotNewsId,getAccountList(item.HotNewsId)">推送</span></Col>
      </Row>
      <Page
          :total="count"
          :page-size="pageSize"
          show-elevator
          show-total
          @on-change="
                        page = $event
                        getArticelList()
                    "
      />
    </div>
  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import accountTree from '../../components/accountTree.vue'
export default{
  name:'hotspotlaw',
  data(){
    return{
      indeterminate: true,
      checkAll: false,
      checkAllGroup: [],
      indeterminate1: true,
      checkAll1: false,
      checkAllGroup1: [],
      articlePushModal:{
        state:false,
        id:'',
        pushList:[],
        accountList:[],
        vallageList:[],
        accountIdList:[],
      },
      articleModal:{
        state:false,
        id:'',
      },
      articlelData:{
        title:'',
        summary:'',
        content:'',
      },
      articleList:[],
      page:1,
      pageSize:15,
      count:0,
      editorConfig: {
        // 如果需要上传功能,找后端小伙伴要服务器接口地址
        serverUrl: 'https://vn-server.lvwa18.com/ueditor/controller.php',

        // 你的UEditor资源存放的路径,相对于打包后的index.html
        UEDITOR_HOME_URL: '/plugin/UEditor/',
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 400,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 关闭自动保存
        enableAutoSave: false,
        toolbars: [[
          'source', '|', 'undo', 'redo', '|',
          'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
          'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
          'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
          'directionalityltr', 'directionalityrtl', 'indent', '|',
          'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 'touppercase', 'tolowercase', '|',
          'link', 'unlink', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
          'insertimage', 'emotion', 'insertvideo', 'music', 'attachment', 'insertframe',  'pagebreak', 'template', 'background', '|',
          'horizontal', 'date', 'time', 'spechars', 'snapscreen', 'wordimage', '|',
          'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol', 'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols', 'charts', '|',
          'print', 'preview', 'searchreplace', 'drafts'
        ]],
        information : '',
      },
    }
  },
  mounted(){
    if(window.sessionStorage.adminLevel == undefined){
      // this.$router.push({name:'login'})
    }else {
      this.information = window.sessionStorage
      // _this.information.AdminId = 0
      this.getArticelList()
    }

  },
  components: {
    'VueUeditorWrap': VueUeditorWrap,
    'accountTree':accountTree,
  },
  methods:{
    secondLevel : function(){
      var _this = this
      console.log(_this.checkAllGroup1)
      var checkAllGroup1 = []//全选
      var back = []//反选
      console.log(_this.checkAllGroup1.length,_this.articlePushModal.accountList.length)
      console.log(_this.checkAllGroup1.length,_this.articlePushModal.accountList.length)
      if(_this.checkAllGroup1.length == 0){
        //全选
        for(var i in _this.articlePushModal.accountList){
          checkAllGroup1.push(_this.articlePushModal.accountList[i].AdminId)
        }
        _this.checkAllGroup1 = checkAllGroup1
        console.log(_this.checkAllGroup1)
      }
      else{
        //反选
        console.log(_this.checkAllGroup1)
        for(var i in _this.articlePushModal.accountList){
          checkAllGroup1.push(_this.articlePushModal.accountList[i].AdminId)
        }
        // 首先构造Object
        const idSet = _this.checkAllGroup1.reduce((acc, v) => {
          acc[v] = true;
          return acc;
        }, {});
        // 遍历list2，去掉在idSet中存在的id
        const result = checkAllGroup1.filter(v => !idSet[v]);
        _this.checkAllGroup1 = result
        console.log(2)
      }
    },
    handleCheckAll : function(){
      var _this = this
      var checkAllGroup = []//全选
      var back = []//反选
      console.log(_this.checkAllGroup.length,_this.articleList.length)
      console.log(_this.checkAllGroup.length,_this.articleList.length)
      if(_this.checkAllGroup.length == 0){
        //全选
        for(var i in _this.articleList){
          checkAllGroup.push(_this.articleList[i].HotNewsId)
        }
        _this.checkAllGroup = checkAllGroup
        console.log(1)
      }
      // _this.checkAllGroup.length < _this.articleList.length && _this.checkAllGroup.length != 0
      else{
        //反选
        console.log(_this.checkAllGroup)
        for(var i in _this.articleList){
          checkAllGroup.push(_this.articleList[i].HotNewsId)
        }
        // 首先构造Object
        const idSet = _this.checkAllGroup.reduce((acc, v) => {
          acc[v] = true;
          return acc;
        }, {});
        // 遍历list2，去掉在idSet中存在的id
        const result = checkAllGroup.filter(v => !idSet[v]);
        _this.checkAllGroup = result
        console.log(2)
      }
    },
    checkAllGroupChange : function(data){
      var _this = this
      if(data.length){
        _this.checkAllGroup = data
        console.log(data)
      }
    },
    getAccountList:function(HotNewsId){
      var _this = this
      var checkAllGroup = []
      if(HotNewsId){
        checkAllGroup.push(HotNewsId)
        _this.checkAllGroup = checkAllGroup
      }else{
        if(_this.checkAllGroup == ''){
          this.$Message.info('请选择后推送');
          return
        }
      }
      if(_this.information.adminLevel == 4){
        _this.send({
          url : '/LawyerListById',
          data: {
            AdminId : window.sessionStorage.AdminId,
            Level : _this.information.adminLevel,
            page: _this.page,
            pageSize : _this.pageSize,
          },
          success: function (res) {
            _this.articlePushModal.accountList = res
            _this.articlePushModal.state = true
            console.log(res.list)
          }
        })
      }if(_this.information.adminLevel == 3){
        _this.checkAllGroup1 = [window.sessionStorage.AdminId]
        _this.pushArticle()
        return;
        _this.send({
          url : '/AdminList',
          data: {
            AdminId : window.sessionStorage.AdminId,
            Level : _this.information.adminLevel,
            page: _this.page,
            pageSize : _this.pageSize,
          },
          success: function (res) {
            _this.articlePushModal.accountList = res.list
            _this.articlePushModal.state = true
            console.log(res.list)
          }
        })
      }
      if(_this.information.adminLevel == 2){
        _this.send({
          url : '/justiceList',
          data: {
            AdminId : window.sessionStorage.AdminId,
            Level : _this.information.adminLevel,
            page: _this.page,
            pageSize : 999999,
          },
          success: function (res) {
            _this.articlePushModal.accountList = res.list
            _this.articlePushModal.state = true
            console.log(res.list)
          }
        })
      }
      if(_this.information.adminLevel == 1){
        _this.send({
          url : '/AllAdminList',
          data: {
            AdminId : window.sessionStorage.AdminId,
            Level : _this.information.adminLevel,
            page: _this.page,
            pageSize : _this.pageSize,
          },
          success: function (res) {
            _this.articlePushModal.accountList = res.list
            _this.articlePushModal.state = true
            console.log(res.list)
          }
        })
      }
      if(_this.information.adminLevel == 0){
        _this.send({
          url : '/TotalAdminList',
          data: {
            AdminId : window.sessionStorage.AdminId,
            Level : _this.information.adminLevel,
            page: _this.page,
            pageSize : _this.pageSize,
          },
          success: function (res) {
            _this.articlePushModal.accountList = res.list
            _this.articlePushModal.state = true
            console.log(res.list)
          }
        })
      }
    },
    pushArticle:function(){
      var _this = this
      console.log(_this.checkAllGroup1)
      var promiseList = []
      var vallageList = []

      _this.send({
        url : '/villageArrArticle',
        data: {
          AdminIdArr : _this.checkAllGroup1.toString(),
          typeId :4,
          page : 1,
          pageSize :99999999,
          key :'',
          start_time : '' ,//开始时间
          end_time :'',
          sort :''
        },
        success: function (res) {
          console.log(_this.checkAllGroup)
          for(var i in _this.checkAllGroup){
            _this.send({
              url:'/PushHotNews',
              data:{
                HotNewsId:_this.checkAllGroup[i],
                AdminId:window.sessionStorage.AdminId,
                VillagesIdArr:res.toString(),
              },
              success:function(res){
                _this.$Message.success('推送成功');
              }
            })
          }
        }
      })
    },
    editArticle:function(id){
      var _this = this
      _this.send({
        url:'/GetHotNews',
        data:{
          HotNewsId:id,
          AdminId:window.sessionStorage.AdminId,
        },
        success:function(res){
          _this.articleModal.id=res.HotNewsId
          _this.articlelData.title=res.HotNewsTitle
          _this.articlelData.summary=res.HotNewsSource
          _this.articlelData.content=res.HotNewsContent
          _this.articleModal.state = true
        }
      })
    },
    deleteArticle:function(id){
      var _this = this
      _this.send({
        url : '/DelHotNews',
        confirm:true,
        data: {
          AdminId : window.sessionStorage.AdminId,
          HotNewsId :id,//开始时间
        },
        success: function (res) {
          _this.getArticelList()
        }
      })
    },
    saveArticel:function(){
      var _this = this
      _this.send({
        url : '/SetHotNews',
        data: {
          AdminId : window.sessionStorage.AdminId,
          HotNewsTitle : _this.articlelData.title,//标题
          HotNewsSource : _this.articlelData.summary,//来源
          HotNewsContent : _this.articlelData.content,
          HotNewsId :_this.articleModal.id,
        },
        success: function (res) {
          _this.getArticelList()
        }
      })
    },
    getArticelList:function(){
      var _this = this
      _this.send({
        url : '/HotNewsList',
        data: {
          page : _this.page,
          pageSize : _this.pageSize,
          AdminId : window.sessionStorage.AdminId,
        },
        success: function (res) {
          _this.articleList = res.List
          _this.count = res.Count
        }
      })
    },
  },
}
</script>
