export default{
  sceneA:[
    {
      id:'A1',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A1-法治广场.jpg",
      name: '法治广场-1',
      position: [0,0],
      links:[
        {nodeId:'A2'},
      ],
      markers: [
        {
          id: "A1-T1", //标记的唯一id，必须
          longitude: -5.36,  //标记的经度
          latitude:-0.02, //标记的纬度
          html: `<div class='click-tips'>云普法</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A1-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A1-1.jpg'],
          },
        },
      ],
    },
    {
      id:'A2',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-法治广场.jpg",
      name: '法治广场-2',
      position: [0,5],
      links:[
        {nodeId:'A1'},
        {nodeId:'A3'},
      ],
      markers: [
        {
          id: "A2-T1", //标记的唯一id，必须
          longitude:0.150304900785433,  //标记的经度
          latitude:0.0007184119920442544, //标记的纬度
          html: `<div class='click-tips'>查看1</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-6.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-6.jpg'],
          },
        },
        {
          id: "A2-T2", //标记的唯一id，必须
          longitude:6.040956901280813,  //标记的经度
          latitude:0.0022683968497987372, //标记的纬度
          html: `<div class='click-tips'>查看2</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-5.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-5.jpg'],
          },
        },
        {
          id: "A2-T3", //标记的唯一id，必须
          longitude:2.158257165835025,  //标记的经度
          latitude:0.00482219223364444, //标记的纬度
          html: `<div class='click-tips'>查看3</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-3.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-3.jpg'],
          },
        },
        {
          id: "A2-T4", //标记的唯一id，必须
          longitude:2.308477361852262,  //标记的经度
          latitude:0.0072076454881415675, //标记的纬度
          html: `<div class='click-tips'>查看4</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-2.jpg'],
          },
        },
        {
          id: "A2-T5", //标记的唯一id，必须
          longitude:4.287370713032635,  //标记的经度
          latitude:-0.00581997181055649, //标记的纬度
          html: `<div class='click-tips'>查看5</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-4.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-4.jpg'],
          },
        },
        {
          id: "A2-T6", //标记的唯一id，必须
          longitude:4.5056656774488975,  //标记的经度
          latitude:-0.0047083306411161985, //标记的纬度
          html: `<div class='click-tips'>查看6</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A2-1.jpg'],
          },
        },
      ],
    },
    {
      id:'A3',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A3-法治广场.jpg",
      name: '法治广场-3',
      position: [5,5],
      links:[
        {nodeId:'A2'},
        {nodeId:'A4'},
      ],
      markers: [
        {
          id: "A3-T1", //标记的唯一id，必须
          longitude:4.826086450883491,  //标记的经度
          latitude:-0.009349582698638503, //标记的纬度
          html: `<div class='click-tips'>宪法</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A3-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A3-2.jpg'],
          },
        },
        {
          id: "A3-T2", //标记的唯一id，必须
          longitude:4.177202827193209,  //标记的经度
          latitude:-0.05063750656738231, //标记的纬度
          html: `<div class='click-tips'>查看1</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A3-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A3-1.jpg'],
          },
        },
        {
          id: "A3-T3", //标记的唯一id，必须
          longitude:6.271503594725151,  //标记的经度
          latitude:-0.05603267661279232, //标记的纬度
          html: `<div class='click-tips'>宪法</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A3-3.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A3-3.jpg'],
          },
        }
      ],
    },
    {
      id:'A4',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A4-法治广场.jpg",
      name: '法治广场-4',
      position: [10,5],
      links:[
        {nodeId:'A3'},
        {nodeId:'A5'},
      ],
      markers: [
        {
          id: "A4-T1", //标记的唯一id，必须
          longitude:5.055839075221591,  //标记的经度
          latitude:-0.05165263859293323, //标记的纬度
          html: `<div class='click-tips'>查看1</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A4-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A4-1.jpg'],
          },
        },
        {
          id: "A4-T2", //标记的唯一id，必须
          longitude:2.7889389751201983,  //标记的经度
          latitude:-0.18552116761319892, //标记的纬度
          html: `<div class='click-tips'>查看2</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A4-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A4-2.jpg'],
          },
        },
      ],
    },
    {
      id:'A5',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A5-法治广场.jpg",
      name: '法治广场-5',
      position: [15,4],
      links:[
        {nodeId:'A4'},
      ],
      markers: [
        {
          id: "A5-T1", //标记的唯一id，必须
          longitude:5.997074270340772,  //标记的经度
          latitude:0.039770036240978746, //标记的纬度
          html: `<div class='click-tips'>查看1</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A5-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A5-1.jpg'],
          },
        },
        {
          id: "A5-T2", //标记的唯一id，必须
          longitude:1.8812412222624586,  //标记的经度
          latitude:-0.07530650107214587, //标记的纬度
          html: `<div class='click-tips'>查看2</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A4-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/A4-2.jpg'],
          },
        },
      ],
    },
  ],
  sceneB:[
    {
      id:'B1',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B1-法治长廊.jpg",
      name: '法治文化街-1',
      position: [-1,0],
      links:[
        {nodeId:'B2'},
      ],
      markers: [
        {
          id: "B1-T1", //标记的唯一id，必须
          longitude:5.936457755513558,  //标记的经度
          latitude:-0.013273584611633193, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B1-3.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B1-3.jpg'],
          },
        },
        {
          id: "B1-T2", //标记的唯一id，必须
          longitude:6.27175965954556,  //标记的经度
          latitude:-0.016591229765626325, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B1-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B1-1.jpg'],
          },
        },
        {
          id: "B1-T3", //标记的唯一id，必须
          longitude:0.9021538427969903,  //标记的经度
          latitude:-0.021120158822778734, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B1-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B1-2.jpg'],
          },
        },
      ],
    },
    {
      id:'B2',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B2-法治长廊.jpg",
      name: '法治文化街-2',
      position: [0,0],
      links:[
        {nodeId:'B1'},
        {nodeId:'B3'},
      ],
      markers: [
        {
          id: "B2-T1", //标记的唯一id，必须
          longitude:5.623479262827177,  //标记的经度
          latitude:-0.10736928384553357, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B2-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B2-1.jpg'],
          },
        },
      ],
    },
    {
      id:'B3',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B3-法治长廊.jpg",
      name: '法治文化街-3',
      position: [0,5],
      links:[
        {nodeId:'B2'},
        {nodeId:'B4'},
      ],
      markers: [
        {
          id: "B3-T1", //标记的唯一id，必须
          longitude:4.017549065031629,  //标记的经度
          latitude:-0.06122607606499231, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B3-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B3-1.jpg'],
          },
        },
        {
          id: "B3-T2", //标记的唯一id，必须
          longitude:5.541061997316099,  //标记的经度
          latitude:-0.06666155849558675, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B3-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B3-2.jpg'],
          },
        },
      ],
    },
    {
      id:'B4',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B4-法治长廊.jpg",
      name: '法治文化街-4',
      position: [0,10],
      links:[
        {nodeId:'B3'},
        {nodeId:'B5'},
      ],
      markers: [
        {
          id: "B4-T1", //标记的唯一id，必须
          longitude:4.044104167219159,  //标记的经度
          latitude:-0.048919599972256034, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B4-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B4-1.jpg'],
          },
        },
        {
          id: "B4-T2", //标记的唯一id，必须
          longitude:5.457972517101723,  //标记的经度
          latitude:-0.06762944023119832, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B4-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B4-2.jpg'],
          },
        },
      ],
    },
    {
      id:'B5',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B5-法治长廊.jpg",
      name: '法治文化街-5',
      position: [0,15],
      links:[
        {nodeId:'B4'},
        {nodeId:'B6'},
      ],
      markers: [
        {
          id: "B5-T1", //标记的唯一id，必须
          longitude:4.044104167219159,  //标记的经度
          latitude:-0.048919599972256034, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B5-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B5-1.jpg'],
          },
        },
        {
          id: "B5-T2", //标记的唯一id，必须
          longitude:5.457972517101723,  //标记的经度
          latitude:-0.06762944023119832, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B5-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B5-2.jpg'],
          },
        },
      ],
    },
    {
      id:'B6',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B6-法治长廊.jpg",
      name: '法治文化街-6',
      position: [0,20],
      links:[
        {nodeId:'B5'},
        {nodeId:'B7'},
      ],
      markers: [
        {
          id: "B6-T1", //标记的唯一id，必须
          longitude:4.044104167219159,  //标记的经度
          latitude:-0.048919599972256034, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B6-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B6-1.jpg'],
          },
        },
        {
          id: "B6-T2", //标记的唯一id，必须
          longitude:5.457972517101723,  //标记的经度
          latitude:-0.06762944023119832, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B6-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B6-2.jpg'],
          },
        },
      ],
    },
    {
      id:'B7',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B7-法治长廊.jpg",
      name: '法治文化街-7',
      position: [0,25],
      links:[
        {nodeId:'B6'},
        {nodeId:'B8'},
      ],
      markers: [{
        id: "B7-T1", //标记的唯一id，必须
        longitude:4.044104167219159,  //标记的经度
        latitude:-0.048919599972256034, //标记的纬度
        html: `<div class='click-tips'>查看</div>`,
        content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B7-1.jpg"/>',
        data:{
          imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B7-1.jpg'],
        },
      },
      {
        id: "B7-T2", //标记的唯一id，必须
        longitude:5.457972517101723,  //标记的经度
        latitude:-0.06762944023119832, //标记的纬度
        html: `<div class='click-tips'>查看</div>`,
        content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B7-2.jpg"/>',
        data:{
          imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B7-2.jpg'],
        },
      },
      ],
    },
    {
      id:'B8',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B8-法治长廊.jpg",
      name: '法治文化街-8',
      position: [0,30],
      links:[
        {nodeId:'B7'},
      ],
      markers: [
        {
          id: "B8-T1", //标记的唯一id，必须
          longitude:4.830211740387465,  //标记的经度
          latitude:-0.04861984004761366, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B8-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/B8-1.jpg'],
          },
        },
      ],
    },
  ],
  sceneC:[
    {
      id:'C1',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C1-法治图书馆.jpg",
      name: '法治图书馆-1',
      position: [0,0],
      links:[
        {nodeId:'C2'},
      ],
      markers: [
        {
          id: "C1-T1", //标记的唯一id，必须
          longitude:4.875597286728299,  //标记的经度
          latitude:0.33176427580952117, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C1-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C1-1.jpg'],
          },
        },
        {
          id: "C1-T2", //标记的唯一id，必须
          longitude:1.2030575178284653,  //标记的经度
          latitude:0.34210391280007957, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C1-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C1-2.jpg'],
          },
        },
      ],
    },
    {
      id:'C2',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-法治图书馆.jpg",
      name: '法治图书馆-2',
      position: [0,5],
      links:[
        {nodeId:'C1'},
        {nodeId:'C3'},
        {nodeId:'C5'},
      ],
      markers: [
        {
          id: "C2-T1", //标记的唯一id，必须
          longitude:5.8484668501586405,  //标记的经度
          latitude:0.01210274942632017, //标记的纬度
          html: `<div class='click-tips'>查看1</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-6.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-6.jpg'],
          },
        },
        {
          id: "C2-T2", //标记的唯一id，必须
          longitude:5.878893575503963,  //标记的经度
          latitude:-0.4664931838191553, //标记的纬度
          html: `<div class='click-tips'>查看2</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-4.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-4.jpg'],
          },
        },
        {
          id: "C2-T3", //标记的唯一id，必须
          longitude:0.42157911003887044,  //标记的经度
          latitude:0.023538485624335248, //标记的纬度
          html: `<div class='click-tips'>查看3</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-5.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-5.jpg'],
          },
        },
        {
          id: "C2-T4", //标记的唯一id，必须
          longitude:0.4074459999772018,  //标记的经度
          latitude:-0.4839305166334853, //标记的纬度
          html: `<div class='click-tips'>查看4</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-3.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-3.jpg'],
          },
        },
        {
          id: "C2-T5", //标记的唯一id，必须
          longitude:3.811404262792353,  //标记的经度
          latitude:0.2492316556426053, //标记的纬度
          html: `<div class='click-tips'>查看5</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-1.jpg'],
          },
        },
        {
          id: "C2-T6", //标记的唯一id，必须
          longitude:2.536437072869526,  //标记的经度
          latitude:0.28161412938123687, //标记的纬度
          html: `<div class='click-tips'>查看6</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C2-2.jpg'],
          },
        },
      ],
    },
    {
      id:'C3',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C3-法治图书馆.jpg",
      name: '法治图书馆-3',
      position: [0,10],
      links:[
        {nodeId:'C2'},
        {nodeId:'C4'},
        {nodeId:'C6'},
        {nodeId:'C7'},
      ],
    },
    {
      id:'C4',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-法治图书馆.jpg",
      name: '法治图书馆-4',
      position: [0,15],
      links:[
        {nodeId:'C3'},
        {nodeId:'C8'},
      ],
      markers: [
        {
          id: "C4-T1", //标记的唯一id，必须
          longitude:3.6671557508938015,  //标记的经度
          latitude:0.03970593452827331, //标记的纬度
          html: `<div class='click-tips'>查看1</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-3.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-3.jpg'],
          },
        },
        {
          id: "C4-T2", //标记的唯一id，必须
          longitude:3.6073381422400796,  //标记的经度
          latitude:-0.49182385308534426, //标记的纬度
          html: `<div class='click-tips'>查看2</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-4.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-4.jpg'],
          },
        },
        {
          id: "C4-T3", //标记的唯一id，必须
          longitude:5.687104610244757,  //标记的经度
          latitude:0.05828782914295805, //标记的纬度
          html: `<div class='click-tips'>查看3</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-8.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-8.jpg'],
          },
        },
        {
          id: "C4-T4", //标记的唯一id，必须
          longitude:5.723175763973786,  //标记的经度
          latitude:-0.6276067327043027, //标记的纬度
          html: `<div class='click-tips'>查看4</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-7.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-7.jpg'],
          },
        },
        {
          id: "C4-T5", //标记的唯一id，必须
          longitude:0.7423924731867286,  //标记的经度
          latitude:0.07115663021165997, //标记的纬度
          html: `<div class='click-tips'>查看5</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-5.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-5.jpg'],
          },
        },
        {
          id: "C4-T6", //标记的唯一id，必须
          longitude:0.735053148710926,  //标记的经度
          latitude:-0.627987194179163, //标记的纬度
          html: `<div class='click-tips'>查看6</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-6.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-6.jpg'],
          },
        },
        {
          id: "C4-T7", //标记的唯一id，必须
          longitude:2.6653825620730247,  //标记的经度
          latitude:0.09489125562714129, //标记的纬度
          html: `<div class='click-tips'>查看5</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-1.jpg'],
          },
        },
        {
          id: "C4-T8", //标记的唯一id，必须
          longitude:2.699376813315804,  //标记的经度
          latitude:-0.4669502963228598, //标记的纬度
          html: `<div class='click-tips'>查看6</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C4-2.jpg'],
          },
        },
      ],
    },
    {
      id:'C5',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C5-法治图书馆.jpg",
      name: '法治图书馆-展览室',
      position: [-5,5],
      links:[
        {nodeId:'C2'},
      ],
    },
    {
      id:'C6',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C6-法治图书馆.jpg",
      name: '法治图书馆-交通安全体验室',
      position: [-5,12],
      links:[
        {nodeId:'C3'},
      ],
    },
    {
      id:'C7',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C7-法治图书馆.jpg",
      name: '法治图书馆-阅览室',
      position: [5,12],
      links:[
        {nodeId:'C3'},
      ],
    },
    {
      id:'C8',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/C8-法治图书馆.jpg",
      name: '法治图书馆-禁毒防邪室',
      position: [5,15],
      links:[
        {nodeId:'C4'},
      ],
    },
  ],
  sceneD:[
    {
      id:'D1',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D1-法治尤安.jpg",
      name: '法治尤安-1',
      position: [0,0],
      links:[
        {nodeId:'D2'},
      ],
    },
    {
      id:'D2',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D2-法治尤安.jpg",
      name: '法治尤安-2',
      position: [0,5],
      links:[
        {nodeId:'D3'},
        {nodeId:'D4'},
        {nodeId:'D7'},
      ],
      markers: [
        {
          id: "D2-T1", //标记的唯一id，必须
          longitude:0.7334101566336374,  //标记的经度
          latitude:-0.0791551130108854, //标记的纬度
          html: `<div class='click-tips'>查看1</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D2-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D2-1.jpg'],
          },
        },
        {
          id: "D2-T2", //标记的唯一id，必须
          longitude:0.8292964110712187,  //标记的经度
          latitude:-0.07874596349817953, //标记的纬度
          html: `<div class='click-tips'>查看2</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D2-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D2-2.jpg'],
          },
        },
      ],
    },
    {
      id:'D3',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D3-法治尤安.jpg",
      name: '法治尤安-3',
      position: [-5,5],
      links:[
        {nodeId:'D2'},
      ],
      markers: [
        {
          id: "D3-T1", //标记的唯一id，必须
          longitude:5.336953848864357,  //标记的经度
          latitude:-0.05318911005572513, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D3-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D3-1.jpg'],
          },
        },
      ],
    },
    {
      id:'D4',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D4-法治尤安.jpg",
      name: '法治尤安-4',
      position: [0,15],
      links:[
        {nodeId:'D2'},
        {nodeId:'D5'},
      ],
      markers: [
        {
          id: "D4-T1", //标记的唯一id，必须
          longitude:1.6558217023874615,  //标记的经度
          latitude:0.08685943921983785, //标记的纬度
          html: `<div class='click-tips'>查看1</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D4-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D4-1.jpg'],
          },
        },
        {
          id: "D4-T2", //标记的唯一id，必须
          longitude:5.057614579396951,  //标记的经度
          latitude:-0.1980661963306909, //标记的纬度
          html: `<div class='click-tips'>查看2</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D4-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D4-2.jpg'],
          },
        },
        {
          id: "D4-T3", //标记的唯一id，必须
          longitude:0.13513387982110472,  //标记的经度
          latitude:-0.08222027011432798, //标记的纬度
          html: `<div class='click-tips'>查看3</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D4-3.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D4-3.jpg'],
          },
        },
      ],
    },
    {
      id:'D5',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D5-法治尤安.jpg",
      name: '法治尤安-5',
      position: [-5,17],
      links:[
        {nodeId:'D4'},
      ],
      markers: [
        {
          id: "D5-T1", //标记的唯一id，必须
          longitude:3.578028243239822,  //标记的经度
          latitude:-0.14760166682678277, //标记的纬度
          html: `<div class='click-tips'>查看</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D5-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D5-1.jpg'],
          },
        },
      ],
    },
    {
      id:'D7',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D7-法治尤安.jpg",
      name: '法治尤安-7',
      position: [5,7],
      links:[
        {nodeId:'D2'},
        {nodeId:'D8'},
      ],
      markers: [
        {
          id: "D7-T1", //标记的唯一id，必须
          longitude:5.787784827512359,  //标记的经度
          latitude:-0.07893059870771357, //标记的纬度
          html: `<div class='click-tips'>查看1</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D7-1.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D7-1.jpg'],
          },
        },
        {
          id: "D7-T2", //标记的唯一id，必须
          longitude:4.221855077437589,  //标记的经度
          latitude:0.10966539847181322, //标记的纬度
          html: `<div class='click-tips'>查看2</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D7-2.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D7-2.jpg'],
          },
        },
        {
          id: "D7-T3", //标记的唯一id，必须
          longitude:3.9196376976324707,  //标记的经度
          latitude:-0.06717476804080147, //标记的纬度
          html: `<div class='click-tips'>查看3</div>`,
          content:'<img style="width:100%" src="https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D7-3.jpg"/>',
          data:{
            imgList:['https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D7-3.jpg'],
          },
        },
      ],
    },
    {
      id:'D8',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D8-法治尤安.jpg",
      name: '法治尤安-8',
      position: [5,15],
      links:[
        {nodeId:'D7'},
        {nodeId:'D10'},
      ],
    },
    {
      id:'D10',
      panorama: "https://ai-cdn.lvwa18.com/img/rusticate/jian-yang/vr/D10-法治尤安.jpg",
      name: '法治尤安-10',
      position: [5,20],
      links:[
        {nodeId:'D8'},
      ],
    },
  ],
}