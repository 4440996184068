import { render, staticRenderFns } from "./communityRevise.vue?vue&type=template&id=8a5dc79c&scoped=true&"
import script from "./communityRevise.vue?vue&type=script&lang=js&"
export * from "./communityRevise.vue?vue&type=script&lang=js&"
import style0 from "./communityRevise.vue?vue&type=style&index=0&id=8a5dc79c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a5dc79c",
  null
  
)

export default component.exports