<style scoped>
    .head-title{font-size: 36px;color: #333333;line-height: 88px;display: inline-block; vertical-align: top;}
    .return{width: 16px;height: 30px;display: inline-block;vertical-align: top;float: left;margin-top: 29px;margin-left: 30px}
    .switch{font-size: 3px;border-bottom: solid 1px #d9d9d9;background-color: #f8f8f8;height: 100px;width: 724px;line-height: 100px;padding: 0 13px;}
    .label{color: #666666;width: 160px;text-align: center;margin: 0 10px;}
    .selectedc1{border-bottom: 2px solid #438bff;color: #333333;}
    .name{font-size: 16px;vertical-align: top;width: 577px;white-space: nowrap;overflow: hidden;}
    .option{background-color: #ffffff;width: 750px;height: 110px;font-size: 28px;line-height: 110px;margin-top: 0.3rem;}
    .document-img{width: 0.36rem;height: 0.36rem;margin: 0.39rem 0.18rem 0.35rem 0.30rem;}
    .xiazai{width: 0.8rem;height: 0.8rem;float: right;margin-top: 0.3rem;}
    .filescontent{color: #000000;font-size: 20px;font-weight:bold;    padding: 0 60px 100px;line-height: 60px;    overflow: hidden;text-align: left}
    .filescontent h1{font-size:36px;color: #333333;    font-weight: 400;}
    .download{width: 0.37rem;height: 0.36rem;vertical-align: top;margin: 0.12rem 0rem 0.12rem 0.18rem;}
    .gray{background-color: #dfdfdf;width: 1.4rem;height: 0.60rem;font-size: 0.3rem;line-height: 0.6rem;}
    .sendout{width: 3.6rem;height: 1rem;background-color: #ff9c00;font-size: 0.3rem;line-height: 1rem;border-radius:0.5rem;color: white}
    .fasong{width: 0.32rem;height: 0.36rem;vertical-align: top;margin: 0.32rem 0.05rem 0.32rem 0.81rem; }
    .mailbox{width: 600px;height: 378px;background-color: white;position: fixed;top: 150px;font-size: 30px;text-align: center;left: 50%; margin-left: -300px;z-index: 100}
    .cancel{width: 230px;height: 72px;line-height: 72px;}
    .inputmailbox{width: 480px;height: 80px;border: 1px solid rgb(221,221,221);margin-top: 40px;margin-bottom: 31px;font-size: 30px;
        display: inline-block;}
    .youxiang{width: 45px;height: 36px;vertical-align: top;margin-top: 27px;}
    .sign{border-radius:45px;width:90px;height: 90px;border: 1px solid rgb(221,221,221);position: absolute;top: -45px;background-color: white;left: 50%;margin-left: -45px;}
    .transparent{background-color: #000000;opacity: 0.5;width: 750px;height: 100%;position: fixed;z-index: 99;}
    .prohibit {overflow-x: scroll;overflow-y: hidden;height: 100%;position: fixed;}

    .preservationButtom{width: 600px;height: 100px;background-color: #ffffff;font-size: 30px;color: #989898;text-align: center;line-height: 100px;display: inline-block}
    .generateButtom{width: 1200px;height: 100px;background-color: #4195ff;font-size: 30px;color: #fff;text-align: center;line-height: 100px;display: inline-block}
</style>

<template>
    <div style="background: linear-gradient(-90deg, #2340EE, #339AEF);background-size: 100% 100%;position: fixed;height: 100%;width: 100%;text-align: center">
        <div style="background: url(/img/pc/bg.png);min-width: 1200px;text-align: left;margin-bottom: 60px;">
            <div style="height: 180px;width: 100%;background: rgba(255,255,255,0.1);font-size: 48px;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;">
                <div>
                    <img src="/img/pc/mark.png" style="margin:39px 20px 0 60px;">
                    <div style="display: inline-block;line-height: 180px;vertical-align: top">重庆市渝中区人民法院—智能法律问答系统</div>
                    <!--<span style="font-size: 18px;float: right;vertical-align: top;margin-right: 60px;line-height: 180px"> 已回答 <span style="color: #FFC618">{{total_click}}</span> 个问题</span>-->
                    <div style="float: right;vertical-align: top;margin-right: 60px;line-height: 180px" @click="$router.go(-1)">
                        <img style="vertical-align: top;margin: 90px 10px 10px 0;" src="/img/pc/sy.png">
                        <div style="display: inline-block;font-size: 14px;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;vertical-align: top;margin: 10px 10px 10px 0">返回上一页</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align: center;height: 70%;overflow: auto;overflow-x: hidden;overflow-y: auto;width: 1200px;display: inline-block" class="nui-scroll">

            <div style="width: 1200px;display: inline-block;height: 70%">
                <div class="document" style="background-color: rgb(240,244,247)">
                    <div>
                        <!--<div style="background-color: #fff;height: 0.88rem;text-align: center;" v-if="source != 'TFSMY'">-->
                        <!--<img class="return" src="/img/pc/return.png" @click="previous()">-->
                        <!--<p class="head-title">合同协议一键下载</p>-->
                        <!--</div>-->
                        <div id="app-1">
                            <div class=""></div>
                            <div class="transparent" v-if="show" @click="show = !show"></div>
                            <div class="filescontent" v-html="file.content">
                                <!--<h1 style="font-size: 0.36rem;color: #333333;font-weight: 400;">{{name}}</h1>-->
                                <!--{{file}}-->
                            </div>

                            <!--<div style="position: fixed;bottom: 0;width: 100%;    text-align: left;" v-if="source != 'TFSMY'">-->
                                <!--&lt;!&ndash;<div class="preservationButtom" @click="download('down')">&ndash;&gt;-->
                                    <!--&lt;!&ndash;<p class="inline-block">下载本地</p>&ndash;&gt;-->
                                <!--&lt;!&ndash;</div>&ndash;&gt;-->
                                <!--&lt;!&ndash;<router-link :to="{name:'documentDownload',query:{type:3}}">&ndash;&gt;-->
                                <!--<div class="generateButtom" @click="show = !show">-->
                                    <!--<p class="inline-block">发送邮箱</p>-->
                                <!--</div>-->
                                <!--&lt;!&ndash;</router-link>&ndash;&gt;-->
                            <!--</div>-->
                            <!--<div style="position: fixed;bottom: 0;width: 100%;" v-else>-->
                                <!--<div class="generateButtom" @click="show = !show" style="width: 100%">-->
                                    <!--<p class="inline-block">发送邮箱</p>-->
                                <!--</div>-->
                                <!--&lt;!&ndash;</router-link>&ndash;&gt;-->
                            <!--</div>-->

                            <!--<div style="position: fixed; bottom: 0.1rem; width: 7.5rem;">-->
                            <!--<div class="gray inline-block" style="margin-left: 0.3rem;" @click="download('down')">-->
                            <!--<img src="/img/download/download.png" class="download">-->
                            <!--<p class="inline-block">下载</p>-->
                            <!--</div>-->
                            <!--<div @click="previous()" class="gray inline-block" style="margin-left: 0.3rem;">-->
                            <!--<img src="/img/download/fanhui.png" class="download">-->
                            <!--<p class="inline-block">返回</p>-->
                            <!--</div>-->
                            <!--<div class="inline-block sendout" style="margin-left: 0.3rem;" @click="show = !show">-->
                            <!--<img src="/img/download/fasong.png" class="download fasong">-->
                            <!--<p class="inline-block">发送至邮箱</p>-->
                            <!--</div>-->
                            <!--</div>-->
                            <div class="mailbox" v-show="show">
                                <div class="sign"><img src="/img/pc/youxiang1.png" class="youxiang"></div>
                                <p style="color: rgb(0, 0, 0); margin-top: 70px;">— 填写邮箱地址 —</p>
                                <input type="text" id="input" class="inputmailbox" v-model="inputmailbox">
                                <!--<input type="text" class="inputmailbox" v-model="inputmailbox"   @focus="keyboardTrue" data-layout="normal" />-->
                                <div class="inline-block cancel" style="border: 0.01rem solid rgb(221, 221, 221); color: rgb(0, 0, 0);"
                                     @click="show = !show;visible = false">
                                    取消
                                </div>
                                <div class="inline-block cancel"
                                     style="background-color: rgb(66, 129, 245); color: rgb(255, 255, 255); margin-left: 0.1rem;"
                                     @click="download('send')">
                                    确定
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <!--<vue-touch-keyboard id="keyboard" v-if="visible" :layout="layout" :cancel="hide" :accept="accept" :input="input" :next="next" />-->
        </div>
        <!---->
        <!--<textarea type="text" v-model="keyboards"></textarea>-->
        <!--<Keyboard  v-on:updatekey="GetKeyVal"></Keyboard>-->
    </div>


</template>

<script>

    import VueTouchKeyboard from "vue-touch-keyboard";
    import style from "vue-touch-keyboard/dist/vue-touch-keyboard.css"
    // import Keyboard from '../../components/Keyboard.vue'
    import axios from 'axios'
    export default {
        name: "download",
        components: {
            "vue-touch-keyboard": VueTouchKeyboard.component
        },
        data() {
            return {
                kind: '2',
                file: '',
                show: false,
                inputmailbox: '',
                source : '',
                keyboards : '',
            //
                visible: false,
                layout: "normal",
                input: null,
                options: {
                    useKbEvents: false
                }
            };
        },
        methods: {
            keyboardTrue : function(e){

                this.input = e.target;
                this.layout = e.target.dataset.layout;
                console.log(e.target)
                this.inputmailbox = e.target.value
                if (!this.visible)
                    this.visible = true
            },
            accept(text) {
                console.log("Input text: " + text);
                this.hide();
            },
            hide() {
                this.visible = false;
            },
            next() {
                let inputs = document.querySelectorAll("input");
                let found = false;
                [].forEach.call(inputs, (item, i) => {
                    if (!found && item == this.input && i < inputs.length - 1) {
                        found = true;
                        this.$nextTick(() => {
                            inputs[i+1].focus();
                        });
                    }
                });
                if (!found) {
                    this.input.blur();
                    this.hide();
                }
            },
            previous:function () {
                this.$router.go(-1)
            },
            datahandover: function () {
                var id = this.$route.query.id;
                var _this = this
                axios.post('https://ai.lvwa18.com/doc_template',{
                    kind: _this.kind,
                    type: id
                })
                    .then((res)=>{
                        _this.file = res.data.list
                    })
            },
            download: function (type) {
                var _this = this
                axios.post('https://ai.lvwa18.com/send_down',{
                    kind: type,//下载
                    id: _this.file.id,//第几个数据页
                    emails: _this.inputmailbox,//邮箱内容element
                })
                    .then((res)=>{
                        this.visible = false
                        if (type == 'down') {
                            window.location.href = 'https://ai.lvwa18.com' +'/'+res.data
                        }
                        if(res.data.code == 200){
                            _this.show = !_this.show
                            _this.$Message.info('发送成功，请注意查阅');
                            // _this.$toast('发送成功，请注意查阅');
                        }
                        if(res.data.code == 100){
                            _this.$Message.error('请输入正确的邮箱！');
                        }
                        if(res.data.code == 101){
                            _this.$Message.error('邮箱错误，请重新输入')
                        }
                    })
                // this.send({
                //     server: 'ai',
                //     url: '/send_down',
                //     data: {
                //         kind: type,//下载
                //         id: _this.file.id,//第几个数据页
                //         emails: _this.inputmailbox,//邮箱内容element
                //     },
                //     success: function (res) {
                //         if (type == 'down') {
                //             window.location.href = _this.getServerURL('ai') +'/'+res
                //         }
                //         if(res.code == 200){
                //             _this.$toast('发送成功，请注意查阅');
                //             _this.show = !_this.show
                //         }
                //         if(res.code == 100){
                //             _this.$toast.fail('请输入正确的邮箱！');
                //         }
                //         if(res.code == 101){
                //             _this.$toast.fail('邮箱错误，请重新输入')
                //         }
                //     },
                //     error:function (err) {
                //         _this.$toast.fail('网络繁忙！');
                //     }
                // })
            },
        },
        mounted: function () {
            this.datahandover()
            this.source = window.sessionStorage.source
            $('#input').virtualkeyboard()
        }
    }
</script>

