import { render, staticRenderFns } from "./examInput.vue?vue&type=template&id=8885bda0&scoped=true&"
import script from "./examInput.vue?vue&type=script&lang=js&"
export * from "./examInput.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8885bda0",
  null
  
)

export default component.exports