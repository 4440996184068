<style scoped>
.header {
  background-image: url(/img/mediate/banner.png);
  height: 2.6rem;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
</style>

<template>
  <div style="background-color:#f8f9fb;">
    <div class="header"></div>
    <div style="padding:0.3rem">
      <stepMenu :step="1" :menu="['选择地区','基本信息','事项描述']"></stepMenu>
      <div style="padding:0.3rem"></div>
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
        <FormItem label="" prop="applyType" style="margin-bottom: 0.2rem">
					<div><span style="color: red;padding: 0 0.1rem;">*</span>纠纷类型：</div>
					<RadioGroup v-model="dispute">
						<Radio label="1">一般性纠纷</Radio>
						<Radio label="2">专业性（行业性）纠纷</Radio>
					</RadioGroup>
					<Select v-model="formValidate.applyType" v-if="dispute == '1'">
						<Option value="邻里纠纷">邻里纠纷</Option>
						<Option value="抚养纠纷">抚养纠纷</Option>
						<Option value="房屋宅基地纠纷">房屋宅基地纠纷</Option>
						<Option value="生产经营纠纷">生产经营纠纷</Option>
						<Option value="邻里纠纷">邻里纠纷</Option>
						<Option value="人身损害赔偿纠纷">人身损害赔偿纠纷</Option>
						<Option value="合同纠纷">合同纠纷</Option>
					</Select>
					<Select v-model="formValidate.applyType" v-if="dispute == '2'">
						<Option value="医疗纠纷">医疗纠纷</Option>
						<Option value="交通事故纠纷">交通事故纠纷</Option>
						<Option value="劳动争议纠纷">劳动争议纠纷</Option>
						<Option value="消费者权益纠纷">消费者权益纠纷</Option>
						<Option value="物业管理纠纷">物业管理纠纷</Option>
						<Option value="征地拆迁纠纷">征地拆迁纠纷</Option>
						<Option value="山林土地纠纷">山林土地纠纷</Option>
						<Option value="婚姻家庭纠纷">婚姻家庭纠纷</Option>
						<Option value="电子商务纠纷">电子商务纠纷</Option>
						<Option value="旅游纠纷">旅游纠纷</Option>
						<Option value="环境污染纠纷">环境污染纠纷</Option>
					</Select>
				</FormItem>
				<!---->
				<FormItem label="" prop="proposerExcuse">
					<div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>纠纷概述：</div>
					<Input v-model="formValidate.proposerExcuse" type="textarea" :autosize="{minRows:10,maxRows:15}" placeholder=""></Input>
				</FormItem>
      </Form>
      <div style="text-align: center;">
				<router-link :to="{name:'MUCAS2'}">
					<Button type="primary" style="width:3rem;">上一步</Button>
				</router-link>
				<span style="padding:0 0.3rem"></span>
				<Button type="primary" style="width:3rem;" @click="submit">提交</Button>
			</div>
    </div>
  </div>
</template>

<script>
import stepMenu from '@/components/stepMenu.vue'
export default {
  name: "step3",
  data() {
    return {
      dispute: '',
    };
  },
  mounted() {
  },
  components: {
    stepMenu: stepMenu
  },
  computed: {
    ruleValidate: function() {
      return this.$store.state.mediateData.ruleValidate;
    },
    formValidate: function() {
      return this.$store.state.mediateData.formValidate;
    }
  },
  methods: {
    submit : function(name){
        var _this = this
        _this.$refs.formValidate.validate((valid) => {
            if (valid) {
                _this.send({
                    url : '/mediate',
                    data : {
                        Content : _this.formValidate,
                        UserId : window.sessionStorage.userId,
                        AreaId : window.sessionStorage.AreaId
                    },
                    success: function (res) {
                        console.log(res)
                        if(res == true){
                            _this.$Modal.success({
                              title:'系统通知',
                              content:'人民调解申请成功！',
                              onOk:function(){
                                _this.$router.push({name:'mediateListH'})
                              }
                            })
                            // _this.$Message.success('操作成功')
                            // window.history.go(-1)
                        }else{
                            // const reg = /[\u4e00-\u9fa5]/g;
                            // console.log(res.match(reg).join(''))
                            // _this.$Message.success(res.match(reg).join(''))
                            _this.$Message.error('数据填写有误，请核对后提交！')
                        }
                        // _this.nation = res
                    },
                    error : function (res) {
                        console.log(res)
                    }
                })
            } else {
                this.$Message.error('请输入完成后提交!');
            }
        })
    },
  }
};
</script>
