const index = require('@/views/lawAdmin/home').default
// const index = require('@/views/manager/index').default

const routes = [
  {path:'index',component:index,name:'managerIndex',meta:{title:'律蛙数字法治-智慧司法平台'},},
]

export default {
  routes:routes,
}
