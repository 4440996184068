<style scoped>
.forMcontent{width: calc(100% - 80px);height: 60px;color: #666666;font-size: 16px;margin:0px 40px 0 40px;border-bottom: 1px solid #dddddd;text-align: center;}
.forMcontent p {line-height: 60px;display: inline-block;width: 50%}
.forMtitle1 p{display: inline-block;color: #333333;font-size: 16px;line-height: 60px;overflow: hidden;white-space: nowrap;width: 20%}
.forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;margin:40px 40px 0 40px;height: 60px;}
.forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 50%;line-height: 60px;overflow: hidden;white-space: nowrap;}
.administration{width: 100%;background-color: #fff;border-bottom: 1px solid #dddddd;height: 80px;text-align: left;margin-bottom: 0}
.administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
.label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
.addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
.inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}
.contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
.single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}


.choiceLawyer :deep(.ivu-select-dropdown ){max-height: none;}
.choiceLawyer :deep(.ivu-page ){margin-left: 30px}
.choiceLawyer{width: 380px}
.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
.placeholderColor /deep/ input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {color: #515a6e;}
.placeholderColor /deep/ input:-moz-placeholder, textarea:-moz-placeholder {color: #515a6e;}
.placeholderColor /deep/ input::-moz-placeholder, textarea::-moz-placeholder {color: #515a6e;}
.placeholderColor /deep/ input:-ms-input-placeholder, textarea:-ms-input-placeholder {color: #515a6e;}
</style>

<template>
  <div class="replaceCommunity">

    <p style="color:#17233d;text-align:left;margin: 20px;font-size: 20px">
      <span>村（社区）</span>
      <span style="margin-left: 30px">共{{count}}条</span>
    </p>
    <div class="publicWelfare">
      <Row style="margin: 0 0 20px 20px">
        <Col span="12">
          <Input v-model="searchData" placeholder="搜索"  style="width: 200px"/>
          <!--                  <Button slot="append" enter-button="Search" @on-search="searchDara" >123</Button>-->
          <Button type="primary"  @click="searchDara(1)" >搜索社区</Button>
          <Button type="primary"  @click="searchDara(2)" >搜索律师</Button>
        </Col>
        <Col span="12">
<!--          <Button type="primary" @click="communityShow = true" style="margin-left: 30px">添加社区（村）</Button>-->
        </Col>
      </Row>
      <!---->


      <!---->
      <table class="formtable">
        <thead>
        <tr>
          <th>序号</th>
          <th>律师名称</th>
          <th>村（社区）</th>
<!--          <th>服务机构</th>-->
          <th>创建时间</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value,index) in CommunityData">
          <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
          <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
          <td nowrap="nowrap">
            <div v-for="(item,i) in value.lawyerArr" style="text-align: center;display: inline-block;margin: 0 5px">
              <Avatar :src="item.LawyerImg" />
              <div style="margin-top: 4px">{{item.LawyerName}}</div>
            </div>
          </td>
          <td>{{value.VillagesName}}<samp style="padding: 10px;"></samp>用户数量：（{{value.UserCount}}）
          </td>
<!--          <td>-->
<!--            <div v-for="(item,i) in value.lawyerArr">-->
<!--              {{item.LawyerName}}：{{item.OrganizationName}}-->
<!--            </div>-->
<!--          </td>-->
          <td>{{value.CreatTime}}</td>
          <td style="text-align: center;">
<!--            <Button type="primary" @click="addLawyer(value.VillagesId,'新增律师','')">新增律师</Button>-->
<!--            <Button type="primary" @click="addLawyer(value.VillagesId,'更换律师','change')" style="margin-left: 30px">更换律师</Button>-->
<!--            <Button type="error" @click="addLawyer(value.VillagesId,'删除社区','shanchu')" style="margin-left: 30px">删除社区</Button>-->
            <Button type="primary" @click="modifyCommunity(value)" style="margin-left: 30px">修改社区</Button>
          </td>
        </tr>
        </tbody>
      </table>
      <Page :total="count" show-total :page-size="pageSize" show-elevator
            @on-change="page = $event;adminVillagesList()" />
    </div>
    <!--     更换律师 - 新增律师 -->
    <Modal :draggable="true" v-model="modal" :title="title" @on-ok="determine" @on-cancel="cancel" width="530">
      <Select v-model="model1" filterable multiple @on-query-change="onSearch" @on-change="onSelect" @on-open-change.capture="onCapture" class="choiceLawyer" placeholder="请选择律师">
        <Option v-for="item in lawyerList" :value="item.LawyerId" :key="item.LawyerId">{{ item.LawyerName }}{{item.LawyerPhone}}{{item.OrganizationName}}</Option>
        <Page
            :total="totalName" show-total
            :page-size="pageSize"
            :current="currentPageName"
            @on-change="handlePageName"
        ></Page>
      </Select>
    </Modal>
    <!--      添加社区-->
    <Modal :draggable="true" v-model="communityShow" title="添加社区" @on-ok="onCommunity" @on-cancel="cancel" width="530">
      <div style="margin-bottom: 30px">
        <Select v-model="model1" filterable multiple @on-query-change="onSearch" class="choiceLawyer" placeholder="请选择律师">
          <Option v-for="item in lawyerList" :value="item.LawyerId" :key="item.LawyerId">{{ item.LawyerName }}{{item.LawyerPhone}}{{item.OrganizationName}}</Option>
          <Page
              :total="totalName" show-total
              :page-size="pageSize"
              :current="currentPageName"
              @on-change="handlePageName"
          ></Page>
        </Select>
      </div>
      <Input v-model="community.VillagesName" placeholder="服务社区（村）" style="margin-bottom: 30px" />
      <Cascader :data="addressData" v-model="familyaddress" placeholder="请选择所属区域" @on-change="addressSelect" style="margin-bottom: 30px" ></Cascader>
      <Cascader :data="administrationData" v-model="community.AdminId" placeholder="请选择管理层" @on-change="onAdministration"></Cascader>
    </Modal>
    <!--      更换律师-->
    <Modal :draggable="true" v-model="replaceShow" title="修改律师信息" @on-ok="modifyLawyer" @on-cancel="cancel" width="530">
      <Input v-model="replaceData.LawyerName" placeholder="律师名称" style="margin-bottom: 30px" />
      <Input v-model="replaceData.LawyerPhone" placeholder="律师电话" style="margin-bottom: 30px" />
      <Select v-model="OrganizationId" filterable clearable @on-query-change="lawFirmsearch" @on-open-change="lawFirmdropDown" class="choiceLawyer" placeholder="请选择律所">
        <Option v-for="item in FirmPageData" :value="item.OrganizationId" :key="item.OrganizationId">{{item.OrganizationName}}</Option>
        <Page
            :total="FirmPageCount" show-total
            :page-size="pageSize"
            :current="lawFirmPage"
            @on-change="lawFirmTurnPages"
        ></Page>
      </Select>
    </Modal>
    <!--       添加律所addlawFirm-->
    <Modal :draggable="true" v-model="lawFirmShow" title="添加律所" @on-ok="addlawFirm" @on-cancel="cancel" width="530">
      <Input v-model="addlawFirmData.OrganizationName" placeholder="律所名称" style="margin-bottom: 30px" />
      <Input v-model="addlawFirmData.AdminLogin" placeholder="登录名" style="margin-bottom: 30px" />
      <Input v-model="addlawFirmData.AdminPassword" placeholder="登录密码" style="margin-bottom: 30px" />
      <Input v-model="addlawFirmData.OrganizationLinkman" placeholder="联系人" style="margin-bottom: 30px" />
      <Input v-model="addlawFirmData.OrganizationPhone" placeholder="联系人电话" style="margin-bottom: 30px" />
      <Cascader :data="administrationData" v-model="addlawFirmData.AdminId" placeholder="请选择管理层" change-on-select @on-change="onAdministration" style="margin-bottom: 30px"></Cascader>
      <Cascader :data="addressData" v-model="addlawFirmData.AreaId" placeholder="请选择所属区域" @on-change="addressSelect" style="margin-bottom: 30px" ></Cascader>
    </Modal>
    <!--       添加律师-->
    <Modal v-model="modal4" title="添加" @on-ok="lawyerOk" @on-cancel="cancel">
      <div class="single">
        <p class="contacts">律师名称</p>
        <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName">
      </div>
      <div class="single">
        <p class="contacts">律师电话</p>
        <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone">
      </div>
    </Modal>
    <!-- communitySingleData.CityName       修改社区-->
    <Modal v-model="modifycommunityShow" title="修改社区（村）" @on-ok="onmodifyCommunity" @on-cancel="cancel" class="placeholderColor">
      <Form :model="communitySingleData" :label-width="120">
        <FormItem label="服务社区（村）">
          <Input v-model="communitySingleData.VillagesName" placeholder="服务社区（村）"></Input>
        </FormItem>
<!--        <FormItem label="选择所属区域">-->
<!--          <Cascader :data="addressData" v-model="communitySingleData.familyaddress" placeholder="请选择所属区域" @on-change="addressSelect"></Cascader>-->
<!--        </FormItem>-->
<!--        <FormItem label="选择管理层">-->
<!--          <Cascader :data="administrationData" change-on-select v-model="communitySingleData.AdminId" :placeholder="communitySingleData._AdminName" @on-change="communityAdministration"></Cascader>-->
<!--        </FormItem>-->
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "communityRevise",
  data(){
    return{
      CommunityData : [],
      page : 1,
      pageSize : 15,
      search : '',
      startTime : '',
      endTime : '',
      sort : '',
      count : 0,
      type : '',
      modal : false,
      modal4 : false,
      communityShow : false,
      replaceShow : false,
      lawFirmShow : false,
      replaceData : [],
      VillagesId : '',
      title : '',
      key : '',
      searchData : '',
      model1 : [],
      lawyerList : [],

      totalName : 0,
      currentPageName : 1,

      addressData : [],
      familyaddress : '',
      community : {
        VillagesName:'',
        LawyerId:'',
        AdminId:'',
        ProvinceId:'',
        CityId:'',
        AreaId:'',
        CityName:'',
      },
      LawyerName : '',
      lawFirmPage : 1,
      searchFirm : '',
      FirmPageData : [],
      FirmPageCount : 0,
      OrganizationId : '',
      administrationData : [],
      organizationPage : 1,
      lawFirmData : [],
      lawFirmCount : 0,
      organizationSearch : '',
      addlawFirmData : [],
      value1 : '1',
      LawyerLpage1 : 1,
      lawyerName : '',
      lawyerPhone : '',
      listData : [],
      addData : [],
      modifycommunityShow : false,
      communitySingleData : [],
      communityAdminId : ''
    }
  },
  methods:{
    //删除律师
    removeLawyer : function (lawyerId){
      var _this = this
      _this.send({
        url: '/RemoveLawyer',
        confirm: true,
        data: {
          lawyerId  : lawyerId
        },
        success: function (res) {
          console.log(res)
          _this.$Message.success('操作成功');
          _this.serachLawyer()
        }
      })
    },
    //关闭弹出
    cancel : function (){
      var _this = this
      _this.communitySingleData.AdminId = []
      console.log(_this.communitySingleData.AdminId)
    },
    communityAdministration : function (value){
      this.communitySingleData.AdminId =  value
      console.log(this.communitySingleData.AdminId)
    },
    onmodifyCommunity : function (){
      var _this = this
      if(this.communitySingleData.AdminId[this.communitySingleData.AdminId.length-1] == undefined){
        var AdminId = this.communityAdminId
      }else {
        var AdminId = this.communitySingleData.AdminId[this.communitySingleData.AdminId.length-1]
      }
      _this.send({
        url: '/EditVillages',
        data: {
          AdminId : AdminId,
          VillagesId : _this.communitySingleData.VillagesId,
          VillagesName : _this.communitySingleData.VillagesName,
          CityName : _this.community.CityName,
          ProvinceId : _this.community.ProvinceId,
          CityId : _this.community.CityId,
          AreaId : _this.community.AreaId,
        },
        success: function (res) {
          _this.$Message.success('操作成功');
          _this.model1 = []
          _this.adminVillagesList()
        }
      })
      console.log(AdminId)
    },
    modifyCommunity : function (value){
      var _this = this
      console.log(value)
      _this.communitySingleData = value
      _this.communitySingleData.familyaddress = [value.ProvinceId,value.CityId,value.AreaId]
      _this.communityAdminId = value.AdminId
      _this.communitySingleData.AdminId =  value.AdminId
      _this.modifycommunityShow = true
    },
    lawyerOk: function() {
      var _this = this
      var op = this.lawFirmData[this.value1[0]].OrganizationId + 'p' + _this.lawyerPhone + 'p' + 1
      if (_this.lawyerName != '' && _this.lawyerPhone != '') {
        _this.send({
          url: '/AddLawyerInfo',
          data: {
            OrganizationId: this.lawFirmData[this.value1[0]].OrganizationId,
            scene: op,
            userType: '2',
            page: 'pages/login/login',
            width: '430',
            jpg: 'jpg',
            LawyerName: _this.lawyerName,
            LawyerPhone: _this.lawyerPhone,
            LawyerId: _this.lawyerId
          },
          success: function(res) {
            _this.listData = []
            _this.lawyerName = ''
            _this.lawyerPhone = ''
            _this.LawyerLpage1 = 1
            _this.lawyerId = ''
            _this.lawList()
          }
        })
      } else {
        _this.$Notice.warning({
          title: '系统提示',
          desc: '名称或电话不能为空',
        })
      }
    },
    lawList : function (){
      console.log(this.lawFirmData[this.value1[0]].OrganizationId)
      var _this = this
      _this.send({
        consulting : true,
        url: '/LawyerListById',
        data: {
          OrganizationId: _this.lawFirmData[this.value1[0]].OrganizationId,
          page: _this.LawyerLpage1,
          pageSize: 5,
        },
        success: function(res) {
          if(res){
            _this.listData = res.List
            _this.count = res.count
          }else{
            _this.listData = []
            _this.count = 0
          }
        }
      })
    },
    addlawFirm : function (){
      var _this = this
      console.log(_this.addlawFirmData.AdminId)
      _this.send({
        url:'/AddMember',
        data : {
          AdminId: _this.addlawFirmData.AdminId[_this.addlawFirmData.AdminId.length-1],
          AdminLogin: _this.addlawFirmData.AdminLogin,
          AdminPassword: _this.addlawFirmData.AdminPassword,
          AreaId: _this.addlawFirmData.AreaId[_this.addlawFirmData.AreaId.length-1], //（区域id）,
          Level: 3, // （固定）,
          OrganizationLinkman: _this.addlawFirmData.OrganizationLinkman,
          OrganizationName: _this.addlawFirmData.OrganizationName,
          OrganizationPhone: _this.addlawFirmData.OrganizationPhone,
        },
        success:function(res){
          _this.addlawFirmData = []
          _this.$Message.success('操作成功');
          _this.adminOrganizationList()
        }
      })
    },
    modifyLawyer : function (){
      var _this = this
      _this.send({
        url:'/EditLawyerInfo',
        data : {
          AdminId : window.sessionStorage.AdminId,
          lawyerId : _this.replaceData.LawyerId,
          LawyerName : _this.replaceData.LawyerName,
          LawyerPhone : _this.replaceData.LawyerPhone,
          OrganizationId : _this.OrganizationId,
        },
        success:function(res){
          _this.$Message.success('操作成功');
          _this.serachLawyer()
        }
      })
    },
    replaceLawyer : function (e){
      this.replaceShow = true
      this.replaceData = e
      this.FirmPageData = [e]
      this.OrganizationId = e.OrganizationId
    },
    onCommunity : function (){
      var _this = this
      this.community.LawyerId = _this.model1.toString()
      this.community.AdminId = this.community.AdminId[this.community.AdminId.length-1]
      // this.community.AdminId = window.sessionStorage.AdminId
      console.log(this.community)
      _this.send({
        url:'/AddVillages',
        data : _this.community,
        success:function(res){

          _this.$Message.success('操作成功');
          _this.model1 = []
          _this.VillagesName = ''
          _this.community.familyaddress = ''
          _this.page = 1
          _this.adminVillagesList()
        }
      })
    },
    handlePageName : function (e){
      console.log(e)
      this.currentPageName = e
      this.serachLawyer()
    },
    determine : function (){
      var _this = this
      if(_this.type == 'genghuan'){
        _this.send({
          url: '/EditLawyerSer',
          data: {
            lawyerArrId  : _this.model1.toString(),
            VillagesId : _this.VillagesId,
          },
          success: function (res) {
            _this.$Message.success('操作成功');
            _this.model1 = []
            _this.serachLawyer()
          }
        })
      }else{
        _this.send({
          url: '/changeLawyerSer',
          data: {
            AdminId: window.sessionStorage.AdminId,
            lawyerArrId  : _this.model1.toString(),
            VillagesId : _this.VillagesId,
            type : _this.type,
          },
          success: function (res) {
            _this.$Message.success('操作成功');
            _this.model1 = []
            _this.adminVillagesList()
          }
        })
      }
    },
    onSelect : function (e){
      console.log(e)
    },
    onCapture : function (e){
      console.log(e)
    },
    onSearch : function (e){
      this.key = e
      this.currentPageName = 1
      this.totalName = 0
      this.lawyerList = []
      this.serachLawyer()
    },
    addLawyer : function (e,title,type){
      var _this = this
      if(type == 'shanchu'){
        _this.send({
          url: '/RemoveVillages',
          confirm: true,
          data: {
            VillagesId  : e
          },
          success: function (res) {
            console.log(res)
            _this.$Message.success('操作成功');
            _this.adminVillagesList()
            _this.CommunityData = []
          }
        })
      }else{
        _this.VillagesId = e
        _this.title = title
        _this.modal = true
        _this.type = type
      }
    },
    serachLawyer : function (value){
      var _this = this
      console.log(value)
      if(value != undefined){_this.currentPageName = 1
        _this.totalName = 0}
      _this.send({
        url: '/SerachLawyer',
        data: {
          key : _this.key,
          page : _this.currentPageName,
          pageSize : _this.pageSize,
        },
        success: function (res) {
          _this.lawyerList = res.List
          _this.totalName = res.Count
          console.log(_this.totalName)
          console.log(_this.currentPageName)
        }
      })
    },
    searchDara: function (type) {
      var _this = this
      if(type == 1){
        _this.search = _this.searchData
        _this.LawyerName = ''
      }else{
        _this.search = ''
        _this.LawyerName = _this.searchData
      }
      _this.adminVillagesData = []
      _this.CommunityData = []
      _this.count = ''
      _this.page = '1'
      _this.adminVillagesList()
    },
    adminVillagesList : function (){
    //   更换VillageListByPage
    },
    addressSelect : function (value, selectedData){
      console.log(value, selectedData)
      if(value[1] == 2262 || value[1] == 2237){
        this.community.ProvinceId = value[0]
        this.community.CityId = value[1]
        this.community.AreaId = value[2] || ''
        this.community.CityName = selectedData[0].label+selectedData[2].label
      }else {
        this.community.ProvinceId = value[0]
        this.community.CityId = value[1]
        this.community.AreaId = value[2] || ''
        if(selectedData.length == 3){
          this.community.CityName = selectedData[0].label+selectedData[1].label+selectedData[2].label
        }else{
          this.community.CityName = selectedData[0].label+selectedData[1].label
        }
      }
      console.log(this.community)
    },
    AreaList:function(){
      var _this = this
      var data = []
      _this.send({
        url:'/AreaList',
        success:function(res){
          for(var i in res){
            data.push(res[i])
          }
          convertTree(data)
          function convertTree(tree) {
            const result = []
            tree.forEach((item) => {
              // 解构赋值
              let {
                AreaId: value,
                AreaName: label,
                AreaCode : AreaCode,
                list: children
              } = item
              if (children) {
                children = convertTree(children)
              }
              result.push({
                value,
                label,
                AreaCode,
                children
              })
            })
            _this.addressData = result
            return result
          }
        }
      })
    },
    chooseAdminList : function (){
      var _this = this
      _this.send({
        url:'/chooseAdminList',
        success:function(res){

        }
      })
    },
    serachOrganization : function (){
      var _this = this
      _this.send({
        url: '/SerachOrganization',
        data: {
          key: _this.searchFirm,
          page : _this.lawFirmPage,
          pageSize : _this.pageSize,
        },
        success: function (res) {
          _this.FirmPageData = res.List
          _this.FirmPageCount = res.Count
        }
      })
    },
    lawFirmTurnPages : function (e){
      console.log(e)
      this.lawFirmPage = e
      this.serachOrganization()
    },
    lawFirmsearch : function (e){
      this.searchFirm = e
      this.lawFirmPage = 1
      this.FirmPageCount = 0
      // this.FirmPageData = []
      this.serachOrganization()
    },
    lawFirmdropDown : function (e){
      if(e){
        this.searchFirm = ''
        this.OrganizationId = ''
        this.serachOrganization()
      }
      console.log(this.OrganizationId)
    },
    adminTreeList : function (){
      var _this = this
      var data = []
      _this.send({
        url: '/AdminTreeList',
        data: {
          AdminId:  window.sessionStorage.AdminId,
        },
        success: function (res) {
          for(var i in res.list){
            data.push(res.list[i])
          }
          convertTree(data)
          function convertTree(tree) {
            const result = []
            tree.forEach((item) => {
              // 解构赋值
              let {
                AdminId: value,
                AdminName: label,
                list: children
              } = item
              if (children) {
                children = convertTree(children)
              }
              result.push({
                value,
                label,
                children
              })
            })
            _this.administrationData = result
            console.log(_this.administrationData)
            return result
          }
        }
      })
    },
    onAdministration : function (value, selectedData){
      console.log(value[value.length-1])
    },
    adminOrganizationList : function (value){
      var _this = this
      if(value != undefined){_this.organizationPage = 1;_this.lawFirmCount=0}
      _this.send({
        url: '/OurAdminOrganizationList',
        data: {
          AdminId: window.sessionStorage.AdminId,
          key: _this.organizationSearch,
          page : _this.organizationPage,
          pageSize : _this.pageSize,
        },
        success: function (res) {
          _this.lawFirmData = res.list
          _this.lawFirmCount = res.count
        }
      })
    },
  },
  mounted() {
    var _this = this
    _this.adminVillagesList()
    _this.serachLawyer()
    _this.AreaList()
    _this.chooseAdminList()
    _this.serachOrganization()
    _this.adminTreeList()
    _this.adminOrganizationList()
  }
}
</script>

