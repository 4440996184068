<style scoped>
    .home{background-color: #fff;padding:20px;}
    .screen{width: calc(100% - 80px);display: inline-block;}
    .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
    /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
    .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: #00b86c;padding: 0px 16px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: #00b86c;}
    .screen :deep(.ivu-tabs-ink-bar){background-color: #00b86c;}
    .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
    .house{width: 72px;height: 72px;margin-top: 29px;}
    .number{font-size: 48px;color: #7a7a7a;}
    .tipsName{font-size: 16px;color: #8d8d8d;}
    .displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}
    .administration{border-bottom: 1px solid #dddddd;text-align: left;margin-bottom: 0}
    .administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
    .label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
    .forMtitle1{background-color: #f9f9f9;height: 60px;text-align: center;}
    .forMtitle1 p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
    .forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;height: 60px;}
    .forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
    .forMcontent{color: #666666;font-size:14px;padding:5px 0;border-bottom: 1px solid #dddddd;text-align: center;}
    .forMcontent p {display: inline-block;width: 20%;overflow: hidden; vertical-align:middle;white-space: nowrap;padding:10px 0;}
    .smallProgram{width: 32px;height: 32px;vertical-align: top;margin-top: 14px;}
    .alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -33px;}
    .alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}
    .qrCode{width: 100px;height: 100px;}
    .addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
    .myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}
    .icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}
    .contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
    .single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
    .inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}
</style>

<template>
	<div class="home" style="padding:0 20px;">
		<!--审核-->
		<div>
			<div class="font-size" style="width:100%;display: inline-block;">
        <div style="padding:10px;"></div>
        <div style="font-size:16px;border-left:solid #de4f0b 3px;padding-left:10px;">
          <span style="vertical-align:middle;">案例库详情</span>

        </div>
        <div style="padding:10px;"></div>
        <div style="margin-bottom: 40px;font-size: 16px;text-align: left;">
          <div style="text-align: center;">
            <div class="forMtitle1">
              <p style="width:10%">律师</p>
              <p style="width: 15%">上传类型</p>
              <p style="width: 15%">标题</p>
              <p style="width: 15%">内容</p>
              <p style="width: 15%">时间</p>
              <p style="width: 15%">审核状态</p>
              <p style="width: 15%">操作</p>
            </div>
            <div class="forMcontent" v-for="(value,index) in ElectronicsList">
              <p style="width:10%">{{value.LawyerName}}</p>
              <p style="width: 15%" v-if="value.FileTypeName">{{value.FileTypeName.FileTypeName}}</p>
              <p style="width: 15%" v-else>{{value.FileTypeName}}</p>
              <p style="width: 15%">{{value.FileName}}</p>
              <p style="width: 15%">{{value.FileExplain}}</p>
              <p style="width: 15%">{{value.FileTime}}</p>
              <div style="width: 15%;display: inline-block;vertical-align:middle;">
                <div v-if="value.IsCheck==0">未审核</div>
                <div v-if="value.IsCheck==1">审核通过</div>
                <div v-if="value.IsCheck==2">审核失败</div>
                <template v-if="value.IsCheck==0">
                  <div style="padding:3px;"></div>
                  <!-- /审核失败/未审核 -->
                  <Button type="success" size="small" @click="toExamine(1,value.FileId)">通过</Button>
                  <span style="padding:5px;"></span>
                  <Button type="warning" size="small" @click="toExamine(0,value.FileId)">驳回</Button>
                </template>
              </div>
              <div style="width: 15%;display: inline-block;vertical-align:middle;">
                <Button type="primary" size="small" @click="see(value.FileUrl,value.FileName,value.DocumentUrl,value.FileExplain,value.ServiceTime,value.ServiceLong,value.ServiceCount,value.ServiceLinkman,value.ServiceTel,value.DailyType,value.VillagesName);modal3 = true">查看</Button>
                <span style="padding:5px;"></span>
                <Button type="error" size="small" @click="deleteClick(value.FileId)">删除</Button>
              </div>
            </div>
            <div style="padding:10px;"></div>
            <Page :total="count" show-total :page-size="pageSize" show-elevator	@on-change="page = $event;fileAdminList()" />
          </div>
        </div>




			</div>
		</div>
		<!--查看img-->
		<Modal v-model="modal3" :title="fileName" width="60" @on-ok="ok" @on-cancel="cancel">
			<!--<p>{{fileurl}}</p>-->
			<div style="margin-bottom: 10px;" v-if="documentUrl">
        <Button style="margin-left: 30px" type="primary" @click="seeFile(value.url)" v-for="(value,index) in documentUrl">{{value.name}}-查看</Button>
			</div>
			<!--<Carousel v-model="value1" v-if="modal3 && fileurl" @on-click="enlarge">-->
			<!--<CarouselItem v-for="(value,index) in fileurl" >-->
			<!--<img :src="value" style="width: 100%;">-->
			<!--</CarouselItem>-->
			<!--</Carousel>-->
			<viewer :images="tupians">
				<!--<i-col span="4" v-for="item in tupians" v-if="tupians">-->
				<!--<div class="detailed">-->
				<!--<img :src="item" alt="">-->
				<!--</div>-->
				<!--</i-col>-->
				<Carousel v-model="value1" v-if="modal3 && fileurl" @on-click="enlarge">
					<CarouselItem v-for="(value,index) in fileurl" style="text-align: center">
						<img :src="value" style="max-width:100%;max-height: 500px">
					</CarouselItem>
				</Carousel>
			</viewer>
			<div v-if="VillagesName">社区(村)：<p v-for="(value,index) in VillagesName" style="display: inline-block">
					{{value}}<span v-if="index+1<VillagesName.length">-</span></p>
			</div>
			<div v-if="ServiceLinkman">联系人名称：{{ServiceLinkman}}</div>
			<div v-if="ServiceTel">联系人电话：{{ServiceTel}}</div>
			<div v-if="ServiceCount">服务人数：{{ServiceCount}}</div>
			<div v-if="ServiceTime">服务时间：{{ServiceTime}}</div>
            <div v-if="parseInt(ServiceLong) > 0">服务时长：{{parseInt(ServiceLong)}}</div>
			<div v-if="DailyType">服务方式：<p v-for="(value,index) in DailyType" style="display: inline-block">
					{{value}}<span v-if="index+1<DailyType.length">-</span></p>
			</div>
			<div v-if="FileExplain">内容：{{FileExplain}}</div>
		</Modal>

	</div>
</template>

<script>
	// import HelloWorld from '../../components/HelloWorld.vue'
	import JSZip from 'jszip'
	import FileSaver from 'file-saver'
	export default {
		name: "case-base",
		// components: {HelloWorld,},
		data() {
			return {
        villageCountDate:{
          identification:'m',
          startTime:'',
          endTime:'',
        },
        lawyerCountDate:{
          identification:'m',
          startTime:'',
          endTime:'',
        },
				FileExplain: '',
				value1: 0,
				value2: ['0', '1', '2'],
				addressData: [{}],
				fileurl: '',
				fileName: '',
				documentUrl: '',
				lawyerId: '',
				modal3: false,
				modal1: false,
				modal2: false,
				modal4: false,
				modal5: '0',
				model8: '',
				model9: '',
				tipsWord: '请选择地址',
				cityList: [],
				ElectronicsList: [],
				areaData: null,
				labelInValue: true,
				listData: [],
				arCode: false, //二维码
				current: '',
				lawyerPhone: '',
				lawyerName: '',
				information: [],
        lawyerTable:{
          col:[],
          data:[],
        },
        vallageTable:{
          col:[],
          data:[],
        },
				page: 1,
				pageSize: 20,
				count: 0,
				page1: 1,
				pageSize1: 5,
				count1: 0,
				adminLogin: '',
				adminPassword: '',
				organizationPhone: '',
				organizationLinkman: '',
				organizationName: '',
				statisticsData: [
          {name: '服务社区（村）数量',value: ''},
					{name: '服务用户数量',value: ''},
					{name: '律师数量',value: ''},
					{name: '咨询消息数量',value: ''}
				],
				timelist: [
          {name: '本月',value: 0},
					{name: '本季',value: 1},
					{name: '本年',value: 2},
					{name: '总计',value: 3},
				],
				activeListData: [],
				tupians: [],
				typeList: [],
				fileType: '',
				model1: '',
				model2: '',
				getfileData: [],
				lawyerData: [],
				lawyerNameData: '',
				AreaId: '',
				ServiceTime: '',
				ServiceLong: '',
				ServiceCount: '',
				ServiceTel: '',
				ServiceLinkman: '',
				DailyType: '',
				VillagesName: '',
        url : '',
			}
		},
    watch:{
      'villageCountDate.identification':function(val){
        if(val!='custom'){
          this.getVallageTable()
        }
      },
      'lawyerCountDate.identification':function(val){
        if(val!='custom'){
          this.getLawyerTableData()
        }
      }
    },
		methods: {
      setTime:function(val,val1,val2){
        console.log(val,val1,val2)
      },
      clickCell:function(item,col,index,event){
        var _this = this
        var checkType = 0
        var key = col.key.split('-')[0]||''
        var colId = col.key.split('-')[1]||''
        console.log(item)
        key=='check'?checkType = 1:key=='uncheck'?checkType = 0:checkType = ''
        if(item.VillagesId){
          _this.send({
            url:'/VillagesFileList',
            data:{
              VillagesId:item.VillagesId,
              FileTypeId:colId,
              IsCheck:checkType,
              EndTime:'',
              StartTime:'',
            },
            success:function(res){
              console.log(res)
            }
          })
        }else if(item.LawyerId){
          _this.send({
            url:'/LawyerFileList',
            data:{
              LawyerId:item.LawyerId,
              FileTypeId:colId,
              IsCheck:checkType,
              EndTime:'',
              StartTime:'',
            },
            success:function(res){
              console.log(res)
            }
          })
        }
      },
      onLawyerName: function(value) {
				var _this = this
				_this.lawyerNameData = value
				_this.page = 1
				_this.count = 0
				_this.ElectronicsList = []
				console.log(_this.Count)
				_this.send({
					url: _this.url,
					data: {
						AreaId: _this.AreaId,
						AdminId: _this.information.AdminId,
						FileTypeId: 8,
						LawyerName: _this.lawyerNameData,
						page: _this.page,
						pageSize: _this.pageSize,
					},
					success: function(res) {
						console.log(res)
						if (res) {
							var ElectronicsList = []
							_this.ElectronicsList = res.List
							_this.count = res.Count
						} else {
							_this.ElectronicsList = []
						}
					}
				})
			},
			popularizingLaw: function(value) {
				var _this = this
				console.log(value)
				_this.fileType = value
				_this.page = 1
				_this.count = 0
				_this.ElectronicsList = []
				_this.send({
					url: _this.url,
					data: {
						AreaId: _this.AreaId,
						AdminId: _this.information.AdminId,
						FileTypeId: 8,
						LawyerName: _this.lawyerNameData,
						page: _this.page,
						pageSize: _this.pageSize,
					},
					success: function(res) {
						console.log(res)
						if (res) {
							var ElectronicsList = []
							_this.ElectronicsList = res.List
							_this.count = res.Count
						} else {
							_this.ElectronicsList = []
						}
					}
				})
			},
			getTypeList: function() {
				var _this = this
				_this.send({
					url: '/fileListAdmin',
					success: function(res) {
						_this.typeList = res
						// console.log(res)
					}
				})
			},
			enlarge: function(e) {
				this.tupians = this.fileurl
				console.log(this.fileurl)
			},
			addMember: function() {
				var _this = this
				_this.send({
					url: '/AddMember',
					data: {
						Level: _this.information.adminLevel,
						AdminId: _this.information.AdminId,
						AdminLogin: _this.adminLogin,
						AdminPassword: _this.adminPassword,
						OrganizationLinkman: _this.organizationLinkman,
						OrganizationPhone: _this.organizationPhone,
						OrganizationName: _this.organizationName,

					},
					success: function(res) {
						_this.adminList()
					}
				})
			},

			cancel() {
				this.fileurl = ''
				this.fileName = ''
				this.documentUrl = ''
			},
			enter: function(index) {
				console.log(index)
				this.arCode = true
				this.current = index
			},
			leave() {
				this.arCode = false
				this.current = null
			},
			//列表审核
			activeList: function() {
				var _this = this
				_this.activeListData = []
				_this.count1 = ''
				_this.send({
					url: '/ActiveList',
					data: {
						OrganizationId: _this.information.OrganizationId,
						page: _this.page1,
						pageSize: _this.pageSize1,
					},
					success: function(res) {
						_this.activeListData = res.List
						_this.count1 = res.count
						// console.log(_this.activeListData)
					}
				})
			},
			toExamine: function(isCheck, fileId) {
				var _this = this
				_this.send({
					url: '/CheckActive',
					confirm: true,
					data: {
						IsCheck: isCheck,
						FileId: fileId,
            AdminId: _this.information.AdminId,
					},
					success: function(res) {
						_this.fileAdminList()
					}
				})
			},
			see: function(fileurl, fileName, documentUrl, FileExplain, ServiceTime, ServiceLong, ServiceCount,ServiceLinkman, ServiceTel, DailyType, VillagesName) {
        try{
          this.documentUrl =  JSON.parse(documentUrl)
        }catch(e){
          this.documentUrl =  [{name:this.getFileName(documentUrl),url:documentUrl}]
        }
        if (fileurl) {
					this.fileurl = fileurl.split(",")
				}
				console.log(ServiceTime, ServiceLong)
				this.fileName = fileName
				this.FileExplain = FileExplain
				this.ServiceTime = ServiceTime
				this.ServiceLong = ServiceLong
				this.ServiceCount = ServiceCount
				this.ServiceLinkman = ServiceLinkman
				this.ServiceTel = ServiceTel
				if (DailyType) {
					this.DailyType = DailyType.DailyType
				}
				if (VillagesName) {
					this.VillagesName = VillagesName.VillagesName
				}
			},
			seeFile: function() {
				var _this = this
				if (this.documentUrl.substr(this.documentUrl.lastIndexOf(".") + 1) == 'txt') {
					_this.filesToRar([{
						fileUrl: _this.documentUrl,
						renameFileName: 'txt.txt',
					}], 'txt')
				} else {
					window.location.href = this.documentUrl
				}

			},
			filesToRar: function(arrImages, filename) {
				let _this = this;
				let zip = new JSZip();
				let cache = {};
				let promises = [];
				_this.title = '正在加载压缩文件';

				for (let item of arrImages) {
					const promise = _this.getImgArrayBuffer(item.fileUrl).then(data => {
						// 下载文件, 并存成ArrayBuffer对象(blob)
						zip.file(item.renameFileName, data, {
							binary: true
						}); // 逐个添加文件
						cache[item.renameFileName] = data;
					});
					promises.push(promise);
				}

				Promise.all(promises).then(() => {
					zip.generateAsync({
						type: "blob"
					}).then(content => {
						_this.title = '正在压缩';
						// 生成二进制流
						FileSaver.saveAs(content, filename); // 利用file-saver保存文件  自定义文件名
						_this.title = '压缩完成';
					});
				}).catch(res => {
					_this.$message.error('文件压缩失败');
				});
			},
			getImgArrayBuffer(url) {
				let _this = this;
				return new Promise((resolve, reject) => {
					//通过请求获取文件blob格式
					let xmlhttp = new XMLHttpRequest();
					xmlhttp.open("GET", url, true);
					xmlhttp.responseType = "blob";
					console.log(xmlhttp)
					xmlhttp.onload = function() {
						if (this.status == 200) {
							resolve(this.response);
						} else {
							reject(this.status);
						}
					}
					xmlhttp.send();
				});

			},
			ok: function() {

			},
			//    地区
			getAreaData: function() {
				var _this = this
				_this.send({
					url: '/FileAreaIdList',
					data: {
						AdminId: _this.information.AdminId,
					},
					success: function(res) {
						//
						_this.cityList = res
						_this.send({
							url: '/GetfileDay',
							data: {
								AreaId: _this.AreaId,
								AdminId: _this.information.AdminId,
							},
							success: function(res) {
								console.log(res)
								_this.getfileData = res

								_this.page = 1
							}
						})
						//
						// convertTree(res[1])
						// function convertTree(tree) {
						//     const result = []
						//     tree.forEach((item) => {
						//         // 解构赋值
						//         let {
						//             AreaId: value,
						//             AreaName: label,
						//             children: children
						//         } = item
						//         if (children) {
						//             children = convertTree(children)
						//         }
						//         result.push({
						//             value,
						//             label,
						//             children
						//         })
						//     })
						//     _this.addressData = result
						//     return result
						// }
						// console.log(_this.addressData)
					}
				})
			},
			choice: function(value) {
				var _this = this
				if (value) {
					var data = value.value.split(',')
				}
				console.log(data)
				_this.AreaId = data[0]
				if (data[2] == '1') {
					_this.areaData = _this.cityList[data[1]].children
				} else {

				}
				_this.send({
					url: _this.url,
					data: {
						AreaId: data[0],
						AdminId: _this.information.AdminId,
						FileTypeId: 8,
						LawyerName: _this.lawyerNameData,
						page: _this.page,
						pageSize: _this.pageSize,
					},
					success: function(res) {
						console.log(res)
						if (res) {
							_this.ElectronicsList = res.List
							_this.count = res.Count
						} else {
							_this.ElectronicsList = []
						}

					}
				})
			},
			choice1: function(value) {
				var _this = this
				var data = value.value.split(',')
				console.log(data[0])
			},
			screenData: function(value, selectedData) {
				var _this = this
				_this.send({
					url: _this.url,
					data: {
						AreaId: selectedData[1].value,
						AdminId: _this.information.AdminId,
						FileTypeId: 8,
						LawyerName: _this.lawyerNameData,
						page: _this.page,
						pageSize: _this.pageSize,
					},
					success: function(res) {
						// console.log(res)
					}
				})
			},
			deleteClick: function(FileId) {
				var _this = this
				_this.send({
					confirm: true,
					url: '/AdminActiveDelete',
					data: {
						AdminId: _this.information.AdminId,
						FileId: FileId,
					},
					success: function(res) {
						_this.getAreaData()
					}
				})
			},

			lawyerList: function() {
				var _this = this
				_this.send({
					url: '/LawyerList',
					data: {
						AdminId: _this.information.AdminId,
					},
					success: function(res) {
						_this.lawyerData = res.list
						// console.log(res)

					}
				})
			},
			fileAdminList: function() {
				var _this = this
				_this.send({
					url: _this.url,
					data: {
						AreaId: _this.AreaId,
						AdminId: _this.information.AdminId,
						FileTypeId: 8,
						LawyerName: _this.lawyerNameData,
						page: _this.page,
						pageSize: _this.pageSize,
					},
					success: function(res) {
						// console.log(res)
						if (res) {
							var ElectronicsList = []
							_this.ElectronicsList = res.List
							_this.count = res.Count
						} else {
							_this.ElectronicsList = []
						}
					}
				})
			}
		},

		mounted() {
			var _this = this
      if(SYSCONFIG.location == 'chong-qing'){
        _this.url = '/FileAdminNewList'
      }else{
        _this.url = '/CD_FileAdminNewList'
      }
			_this.getTypeList()
			if (window.sessionStorage.adminLevel == undefined) {
				// this.$router.push({name:'login'})
			} else {
				// var data = JSON.parse(window.sessionStorage.information)
				// SYSCONFIG.server.information = JSON.parse(window.sessionStorage.information)
				// console.log(SYSCONFIG.server.information.AdminLogin)
				this.information = window.sessionStorage
				// _this.getAreaData()
				_this.fileAdminList()
				_this.send({
					url: '/GetfileDay',
					data: {
						AreaId: _this.AreaId,
						AdminId: _this.information.AdminId,
					},
					success: function(res) {
						console.log(res)
						_this.getfileData = res
					}
				})
			}

			_this.lawyerList()
		},
	}
</script>
