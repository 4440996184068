import nullPage from '@/views/nullPage'

let routes = {
  path:'user-client',
  component:nullPage,
  children:[
    {path:'home',component:require('@/views/mobile/open/cheng-du/long-quan-yi/home').default,name:'CQ'},
  ]
}

export default {
  routes:routes
}