<style scoped>
	.header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
	.menu{font-size:0.24rem;padding:0.3rem 0.3rem;}
	.list{padding:0.2rem 0;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<Modal
			v-model="detialPanel.state"
			title="申请详情"
			ok-text="关闭"
			cancel-text
		>
			<template v-if="detialPanel.data.Content">
				<div>姓名：{{detialPanel.data.Content.applyName}}</div>
				<div>性别：{{detialPanel.data.Content.sex}}</div>
				<div>身份证号码：{{detialPanel.data.Content.idCard}}</div>
				<div>民族：{{detialPanel.data.Content.nation}}</div>
				<div>出生日期：{{detialPanel.data.Content.birthday}}</div>
				<div>年龄：{{detialPanel.data.Content.age}}</div>
				<div>住所地址：{{detialPanel.data.Content.address}}</div>
				<div>联系电话：{{detialPanel.data.Content.phone}}</div>
			</template>
		</Modal>
    <div class='header'></div>
		<div class='body'>
      <div style="padding:0.2rem;font-size:0.36rem;text-align: center;line-height:1rem;">
        调解员：<Icon type="md-contact" style="color:#57c5f7;font-size:0.42rem;"/>{{$store.state.mediatorInfo.name}}，你好！
      </div>
			<div class="menu">
				<Row>
					<Col span="24" style="font-size:0.3rem;font-weight: bold;"><Icon type="ios-list" style="font-size:0.5rem;"/>百米服务圈：申请列表</Col>
					<!-- <Col span="12" style="text-align: right;"> -->
					</Col>
				</Row>
			</div>
			<ul v-if="list.length>0">
				<li v-for="(item,index) in list" :style="index%2==0?'background-color: #fff;':''" class='list' @click="$router.push({name:'CJYMMD',query:{mediateId:item.MediationId}})">
					<Row type="flex" align="middle" style="font-size:0.2rem;padding:0.1rem 0.3rem;">
						<Col span="18">
							<Row>
								<Col span="24" style="font-size: 0.28rem;font-weight: bold;padding-bottom:0.1rem">{{item.MediationType}} | 申请人：{{item.ProposerName}} </Col>
								<Col span="24" style="color:#666666">
									申请时间：{{item.CreatedTime}}
								</Col>
							</Row>
						</Col>
            <Col span="6" style="color:#008fd6;text-align: right;">
              <Button v-if="item.ProcessingId==1" type="warning" ghost>待处理</Button>
              <Button v-if="item.ProcessingId==2" type="primary" ghost>处理中</Button>
              <Button v-if="item.ProcessingId==4" type="error" ghost>对方拒绝</Button>
              <Button v-if="item.ProcessingId==3" type="success" ghost>调解成功</Button>
              <!-- <span @click="getDetail(item)">查看详情 ></span> -->
            </Col>
					</Row>
				</li>
			</ul>
			<div v-else style="background-image:url(/img/empty.png);height:4.95rem;width:6.9rem;background-size:100% 100%;margin:1.1rem auto;"></div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'list',
		data(){
			return{
				list:[],
				detialPanel:{
					state:false,
					data:{},
					result:{},
				},
			}
		},
		mounted(){
			if(this.$store.state.mediatorInfo.id == undefined){
				this.$Modal.error({
          title:'系统通知',
          content:'未获取到用户信息！请重新进入！',
        })
        this.$router.push({name:'CJYMMLI'})
			}else{
			  this.getList()
      }
		},
		methods:{
			getList:function(){
				var _this = this
				_this.send({
					url:'/MediatorMediationList',
					data:{
						MediatorId:_this.$store.state.mediatorInfo.id,
					},
					success:function(res){
						_this.list = res
					}
				})
			},
		},
	}
</script>
