<template>
    <!--url(/img/pc/bg.png)-->
    <div style="background: linear-gradient(-90deg, #2340EE, #339AEF);background-size: 100% 100%;position: fixed;height: 100%;width: 100%;text-align: center">
        <div style="background: url(/img/pc/bg.png);min-width: 1200px;text-align: left;margin-bottom: 60px;">
            <div style="height: 180px;width: 100%;background: rgba(255,255,255,0.1);font-size: 48px;font-family: PingFang SC;font-weight: bold;color: #FFFFFF;">
                <div>
                    <img src="/img/pc/mark.png" style="margin:39px 20px 0 60px;">
                    <div style="display: inline-block;line-height: 180px;vertical-align: top">重庆市渝中区人民法院—智能法律问答系统</div>
                    <!--<span style="font-size: 18px;float: right;vertical-align: top;margin-right: 60px;line-height: 180px"> 已回答 <span style="color: #FFC618">{{total_click}}</span> 个问题</span>-->
                </div>
            </div>
        </div>
        <div style="margin-bottom: 60px" @click="search">
            <img src="/img/pc/wsxz.png">
        </div>
        <div style="padding-left: 40px;text-align: left;height: 45%;overflow: auto;overflow-x: hidden;overflow-y: auto;width: 1200px;display: inline-block" class="nui-scroll">
            <div style="width: 1200px;display: inline-block;height: 70%">
                <div @click="ondetails(value.group_r,index)" v-for="(value,index) in resultData" style="width: 320px;height: 209px;background: #FFFFFF;box-shadow: 0px 6px 12px 0px rgba(72, 19, 245, 0.3);border-radius: 4px;display: inline-block;margin: 0 60px 60px 0;vertical-align: top" v-if="value.gid != 23">
                    <div style="padding: 40px 29px 40px 40px;font-size: 0;cursor: pointer;">
                        <div style="margin-bottom: 20px">
                            <img style="margin: 0 16px 0 0" :src="'/img/pc/web-icon'+index+'.png'">
                            <div style="font-size: 30px;font-family: PingFang SC;font-weight: 500;color: #333333;display: inline-block;vertical-align: top;margin: 0 16px 0 0">
                                {{value.group_r}}
                            </div>
                        </div>
                        <div style="font-size: 18px;font-family: PingFang SC;font-weight: 500;color: #6D6D92;">{{value.content}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div style="position: fixed;bottom: 10px;right: 30px;font-size: 14px;z-index: 999;color: #FFF;">
            <span style="margin-right: 20px">exe版本 v1.0.0</span>
            <span>程序版本 v2.3.7</span>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "chongqing",
        data() {
            return {
                total_click : '',
                resultData : [],
                disconnection : null
            }
        },
        methods :{
            ondetails : function (c_keyword,id) {
                var _this = this
                _this.$router.push({name:'cqadviser',query: {c_keyword:c_keyword,id:id,total_click:_this.total_click}});
            },
            search : function () {
                var _this = this
                _this.$router.push({name:'search',});
            },
            get_group_count : function () {
                var _this = this

                axios.post('https://vn-server.lvwa18.com/api/get_count',{
                    params:{},
                })
                    .then((res)=>{
                        _this.total_click = res.data.Data.total_click
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                axios.post('https://vn-server.lvwa18.com/api/get_group_count',{
                    params:{},
                })
                    .then((res)=>{
                        _this.resultData = res.data.Data.result
                        // _this.total_click = res.data.Data.total_click
                        clearInterval(_this.disconnection);
                    })
                    .catch(function (error) {
                        console.log(error);
                        // _this.disconnection = setInterval(() => {
                        //     _this.get_group_count()
                        // }, 5000)
                    })
            }
        },
        mounted (){
            var _this = this
            _this.get_group_count()
        }
    }
</script>

<style scoped>
    div::-webkit-scrollbar{
        width:10px;
        height:10px;
        border-radius: 6px;
        opacity: 0.1;
        /**/
    }
    div::-webkit-scrollbar-track{
        background: rgba(16, 46, 77, 0.1);
        border-radius:2px;
    }
    div::-webkit-scrollbar-thumb{
        background: rgba(136, 193, 251, 1);
        border-radius:10px;
    }
    div::-webkit-scrollbar-corner{
        background: #179a16;
    }
</style>
