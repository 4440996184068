<style scoped>
</style>

<template>
  <div>
    <Modal v-model="RNAPanel.state" title="请选择角色" @on-ok="" width="70%" :closable="false" :mask-closable="false">
      <div style="padding:0.3rem">
        <div>
          是否为社区矫正人员：
          <RadioGroup v-model="isCorrection">
            <Radio label="y">是</Radio>
            <Radio label="n">否</Radio>
          </RadioGroup>
        </div>
        <div style="padding:0.2rem"></div>
        <template v-if="isCorrection=='y'">
          <Row>
            <Col span="8" style="text-align:right;">姓名：</Col>
            <Col span="16">
              <template v-if="$store.state.userInfo.RealName">{{RNAPanel.name}}</template>
              <template v-else><Input v-model="RNAPanel.name"/></template>
            </Col>
          </Row>
          <div style="padding:0.1rem"></div>
          <Row>
            <Col span="8" style="text-align:right;">身份证：</Col>
            <Col span="16">
              <template v-if="$store.state.userInfo.IDCard">{{RNAPanel.IDCardNum}}</template>
              <template v-else><Input v-model="RNAPanel.IDCardNum"/></template>
            </Col>
          </Row>
          <div v-if="!RNAPanel.name||!RNAPanel.IDCardNum" style="color: red;">
            *填写实名信息完成后无法修改，请仔细填写身份信息！确认后分数将纳入统计！
          </div>
        </template>
      </div>
      <div style="text-align: center;" slot="footer">
        <Button type="primary" @click="saveRNA">确认</Button>
      </div>
    </Modal>
    <div style="padding:0.2rem"></div>
    <Row type="flex" align="middle" v-if="isCorrection=='y'">
      <Col span="8" style="text-align:right;">所属司法所：</Col>
      <Col span="12">
        <Select v-model="organization.selected">
          <Option v-for="(item,index) in organization.list" :value="item.id" :key="item.name+item.id">{{ item.name }}</Option>
        </Select>
      </Col>
    </Row>
    <div style="margin-top:0.4rem;border:1px solid #f1f1f1"></div>
    <template v-for="(item,index) in examContent">
      <component
        style="margin-bottom:20px;padding:15px;"
        :is="item.name"
        :indexQ="index"
        :contentIn="item.content"
        @change="item.content=$event"
        :state="$route.query.examId?'read':'answer'">
      </component>
    </template>
    <div style="text-align: center;">
      <Button v-if="$route.query.correctionId" type="primary" @click="submitExam">提 交 试 卷</Button>
      <Button v-if="$route.query.examId" type="primary" @click="submitExam">返 回</Button>
    </div>
    <div style="padding:0.2rem"></div>
  </div>
</template>

<script>
import examRadio from '@/components/exam/examRadio'
import examInput from '@/components/exam/examInput'
import examTof from '@/components/exam/examTof'
import examTextarea from '@/components/exam/examTextarea'
import examCheckbox from '@/components/exam/examCheckbox'
export default {
  name:'exam',
  data() {
    return {
      examContent:[],
      examId:'',
      isCorrection:'y',
      RNAPanel:{//Real name authentication
        state:true,
        name:'',
        IDCardNum:'',
      },
      organization:{
        selected:'',
        list:[
          {id:1,name:'简城司法所',},
          {id:2,name:'射洪坝司法所',},
          {id:3,name:'平泉司法所',},
          {id:4,name:'禾丰司法所',},
          {id:5,name:'三星司法所',},
          {id:6,name:'云龙司法所',},
          {id:7,name:'镇金司法所',},
        ],
      }
    };
 },
  methods: {
    saveRNA:function(){
      var _this = this
      if(!_this.RNAPanel.name||!_this.RNAPanel.IDCardNum&&isCorrection=='y'){
        _this.$Modal.warning({
          title:'提交前请完善司法所信息！',
        })
        return
      }
      if(_this.$store.state.userInfo.RealName&&_this.$store.state.userInfo.IDCard){
        _this.RNAPanel.state = false
        return
      }
      _this.send({
        url:'/EditUser',
        confirm:true,
        data:{
          RealName:_this.RNAPanel.name,
          IDCard:_this.RNAPanel.IDCardNum,
          UserId:_this.$store.state.userInfo.UserId,
        },
        success:function(){
          _this.$store.state.userInfo.RealName = _this.RNAPanel.name
          _this.$store.state.userInfo.IDCard = _this.RNAPanel.IDCardNum
          _this.RNAPanel.state = false
        }
      })
    },
    getExamContent:function(){
      var _this = this
      var _url = ''
      var sendData = {}
      if(_this.$route.query.examId){
        _url = '/UserExamContentAdmin'
        sendData = {
          UserExamId:_this.$route.query.examId,
        }
      }
      if(_this.$route.query.correctionId){
        _url = '/ExamContentAdmin'
        sendData = {
          CurriculumId:_this.$route.query.correctionId,
        }
      }
      _this.send({
        url:_url,
        data:sendData,
        success:function(res){
          _this.examId = res.ExamId
          _this.examContent = res.ExamContent
        },
      })
    },
    submitExam:function(){
      var _this = this
      if(!_this.organization.selected){
        _this.$Modal.warning({
          title:'提交前请完善信息！',
        })
        return
      }
      _this.send({
        url:'/NewUserExam',
        data:{
          UserId:_this.$store.state.userInfo.UserId,
          CurriculumId:_this.$route.query.correctionId,
          ExamContent:_this.examContent,
          IsRectification:_this.isCorrection,
          TeamTypeId:_this.organization.selected,
        },
        success:function(res){
          _this.$Notice.success({
            title:'系统提示',
            desc:'交卷成功！'
          })
          _this.$router.push({name:'CJYCL'})
        },
      })
    },
  },
  mounted() {
    this.RNAPanel.name = this.$store.state.userInfo.RealName
    this.RNAPanel.IDCardNum = this.$store.state.userInfo.IDCard
    this.getExamContent()
  },
  components:{
    examRadio:examRadio,
    examInput:examInput,
    examTof:examTof,
    examTextarea:examTextarea,
    examCheckbox:examCheckbox,
  },
}
</script>
