<template>
    <div>
        <div>
            <div class="tips">律师端登录！</div>
        </div>
        <div style="margin: 0.6rem 0.55rem 0">
            <div class="signinFillIn">
                <!--<div class="mobileName">+86</div>-->
                <div class="title">账户</div>
                <input class="input" placeholder="请输入手机号码或者账户名" v-model="lawyerPhone"/>
            </div>
            <div class="signinFillIn">
                <div class="title">密   码</div>
                <input class="input" placeholder="请输入密码" v-model="lawyerPassword " type="password"/>
            </div>
        </div>
        <div class="center font-size">
            <div class="signin" v-if="lawyerPhone != ''&&lawyerPassword  != ''" style="background-color: #0A76CA;" @click="login">登录</div>
            <div class="signin" v-else>登录</div>
        </div>
        <div style="text-align: center">
          <router-link :to="{name:'lawFirmLogin'}" ><div>转到律所端</div></router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "zhqylogin",
        data() {
            return {
                lawyerPhone : '',
                lawyerPassword  : '',
            }
        },
        methods :{
            login : function () {
                var _this = this
                _this.send({
                    url : '/LawyerLoginOrById',
                    data: {lawyerPhone : _this.lawyerPhone,lawyerPassword :_this.lawyerPassword},
                    success: function (res) {
                        console.log(res.lawyerCase)
                        if(res.lawyerCase){
                            window.sessionStorage.lawyerCase = JSON.stringify(res.lawyerCase) || [{title: "", content: ""}]
                        }
                        if(res.lawyerPhoto){
                            window.sessionStorage.lawyerPhoto = JSON.stringify(res.lawyerPhoto) || []
                        }
                        if(res.lawyerCost){
                            window.sessionStorage.lawyerCost = JSON.stringify(res.lawyerCost) || [{title: "", money: "", content: ""}]
                        }
                        window.sessionStorage.lawDetailAddress = res.lawDetailAddress || ''
                        window.sessionStorage.lawOfficeId = res.lawOfficeId || ''
                        window.sessionStorage.lawOfficeName = res.lawOfficeName || ''
                        window.sessionStorage.lawyerContent = res.lawyerContent || ''
                        window.sessionStorage.lawyerId = res.lawyerId || ''
                        window.sessionStorage.lawyerImage = res.lawyerImage || ''
                        window.sessionStorage.lawyerIntro = res.lawyerIntro || ''
                        window.sessionStorage.lawyerName = res.lawyerName || ''
                        window.sessionStorage.lawyerPhone = res.lawyerPhone || ''
                        window.sessionStorage.lawyerSkill = res.lawyerSkill || ''
                        window.sessionStorage.lawyerYear = res.lawyerYear || ''
                        window.sessionStorage.practiceNumber = res.practiceNumber || ''
                        window.sessionStorage.skillName = res.skillName || ''
                        window.sessionStorage.lawProvinceId = res.lawProvinceId || ''
                        window.sessionStorage.lawCityId = res.lawCityId || ''
                        window.sessionStorage.lawAreaId = res.lawAreaId || ''
                        window.sessionStorage.provinceName = res.provinceName || ''
                        window.sessionStorage.cityName = res.cityName || ''
                        window.sessionStorage.areaName = res.areaName || ''
                        _this.setCookie(_this.lawyerPhone,_this.lawyerPassword)
                        _this.$router.push({name:'editInformation'});
                    }
                })
            },
            setCookie(lawyerPhone,lawyerPassword) {
                var exdate = new Date(); //获取时间
                exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * 30); //保存的天数
                //字符串拼接cookie
                window.document.cookie = "lawyerPhone" + "=" + lawyerPhone + ";path=/;expires=" + exdate.toGMTString();
                window.document.cookie = "lawyerPassword " + "=" + lawyerPassword  + ";path=/;expires=" + exdate.toGMTString();
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
        },
        mounted (){
            var _this = this
            _this.setPageSize()
            if (document.cookie.length > 0) {
                var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
                for (var i = 0; i < arr.length; i++) {
                    var arr2 = arr[i].split('='); //再次切割
                    //判断查找相对应的值
                    if (arr2[0] == 'lawyerPhone') {
                        window.sessionStorage.lawyerPhone = arr2[1];
                        _this.lawyerPhone = window.sessionStorage.lawyerPhone
                    }
                    if (arr2[0] == 'lawyerPassword') {
                        window.sessionStorage.lawyerPassword = arr2[1];
                        _this.lawyerPassword = window.sessionStorage.lawyerPassword
                    }
                }
            }        }
    }
</script>

<style scoped>
    .tips{font-size: 0.36rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.36rem;margin: 0.87rem 0 0 0.55rem;display: inline-block;}
    .password{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #373C40;line-height: 0.32rem;display: inline-block;float: right;margin: 0.84rem 0.43rem 0 0;}
    .mobileName{width: 0.80rem;height: 0.46rem;background: #E9EFF9;border-radius: 4px;font-size: 0.30rem;text-align: center;line-height: 0.46rem;display: inline-block;vertical-align: top;margin-top: 0.32rem}
    .mobileinput{color: #373C40;font-size: 0.28rem;display: inline-block;vertical-align: top;height: 100%;padding-left: 0.36rem;width:calc(100% - 1.17rem);}
    .signinFillIn{width: 6.4rem;height: 1.1rem;border-bottom: 0.01rem solid #DEDEE0;position: relative;}
    .title{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #373C40;line-height: 1.1rem;display: inline-block;vertical-align: top;position: absolute;left: 0;}
    .input{color: #373C40;font-size: 0.28rem;display: inline-block;vertical-align: top;height: 100%;padding-left: 1.16rem;width:calc(100% - 1.16rem);position: absolute;left: 0}
    .obtainCode{font-size: 0.28rem;font-family: PingFang SC;font-weight: 400;color: #0A76CA;line-height: 0.32rem;position: absolute;right: 0;top:0.42rem;}
    .signin{width: 6.40rem;height: 0.90rem;background: #CCEBFE;border-radius: 0.04rem;font-size: 0.30rem;font-family: PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 0.9rem;display: inline-block;margin: 0.4rem 0 0.75rem;}

</style>
