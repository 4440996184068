import nullPage from '@/views/nullPage'
import JianYang from './children/jian-yang'
import QingBaiJiang from './children/qing-bai-jiang'
import ChengDu from './children/cheng-du'

const routes = [
  {
    path:'customization',
    component:nullPage,
    children:new Array().concat(
      JianYang.routes,//简阳定制路由
      QingBaiJiang.routes,//青白江定制路由
      ChengDu.routes,//成都定制路由
    ),
  },
]

export default {
  routes:routes,
}