<style scoped>
    .home{background-color: rgb(241,239,240);padding:20px;}
    .screen{width: calc(100% - 80px);display: inline-block;}
    .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
    /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
    .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: #00b86c;padding: 0px 16px;}
    .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: #00b86c;}
    .screen :deep(.ivu-tabs-ink-bar){background-color: #00b86c;}
    .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
    .house{width: 72px;height: 72px;margin-top: 29px;}
    .number{font-size: 48px;color: #7a7a7a;}
    .tipsName{font-size: 16px;color: #8d8d8d;}
    .displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}
    .administration{border-bottom: 1px solid #dddddd;text-align: left;margin-bottom: 0}
    .administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
    .label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
    .forMtitle1{background-color: #f9f9f9;height: 60px;text-align: center;}
    .forMtitle1 p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
    .forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;height: 60px;}
    .forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
    .forMcontent{color: #666666;font-size:14px;padding:5px 0;border-bottom: 1px solid #dddddd;text-align: center;}
    .forMcontent p {display: inline-block;width: 20%;overflow: hidden; vertical-align:middle;white-space: nowrap;padding:10px 0;}
    .smallProgram{width: 32px;height: 32px;vertical-align: top;margin-top: 14px;}
    .alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -33px;}
    .alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}
    .qrCode{width: 100px;height: 100px;}
    .addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
    .myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}
    .icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}
    .contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
    .single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
    .inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}
</style>

<template>
    <div class="home">
        <!--审核-->
        <div>
            <div class="font-size" style="width:100%;display: inline-block;">
                <div style="padding:10px;"></div>
                <!--<div style="font-size:16px;border-left:solid #de4f0b 3px;padding-left:10px;">-->
                    <!--<span style="vertical-align:middle;">最新</span>-->
                    <!--<div style="display: inline-block;vertical-align:middle;margin-left:10px">-->
                        <!--（今日上传<span style="color: red;">{{getfileData.day}}</span>条-->
                        <!--总上传<span style="color: red;">{{getfileData.total}}</span>条）-->
                    <!--</div>-->
                <!--</div>-->
                <div style="padding:10px;"></div>
                <Tabs>
                    <TabPane label="档案列表">
                        <div class="administration">
                            <div style="padding:0 20px;">
                                <div style="font-size:15px;">
                                    <span style="vertical-align:middle;">数据筛选：</span>
                                    <span style="padding:5px;"></span>
                                    <!--<div class="inline-block">-->
                                        <!--<Select @on-open-change="getAreaData" v-model="model8" style="width:200px" @on-change="choice"-->
                                                <!--:label-in-value="labelInValue" :placeholder="tipsWord" clearable>-->
                                            <!--<Option v-for="(item,index) in cityList" v-if="item.FileIdCount != 0"-->
                                                    <!--:value="item.AreaId +','+ index  +','+ '1'" :label="item.AreaName" :key="index">-->
                                                <!--{{ item.AreaName }}</Option>-->
                                        <!--</Select>-->
                                        <!--<Select v-model="model9" style="width:200px" @on-change="choice" :label-in-value="labelInValue"-->
                                                <!--v-if="areaData" :placeholder="tipsWord" clearable>-->
                                            <!--<Option v-for="(i,index) in areaData" :value="i.AreaId +','+ index +','+ '2'" :label="i.AreaName"-->
                                                    <!--:key="index">{{ i.AreaName }}</Option>-->
                                        <!--</Select>-->
                                    <!--</div>-->
                                    <!--<span style="padding:10px;"></span>-->
                                    <!--<div class="inline-block">-->
                                        <!--&lt;!&ndash;<Cascader :data="evaluationData" v-model="evaluation"@on-change="addressSelect" placeholder="请选择分栏" clearable></Cascader>&ndash;&gt;-->
                                        <!--<Select v-model="model1" style="width:200px;" @on-change="addressSelect" placeholder="请选择分栏"-->
                                                <!--clearable>-->
                                            <!--<Option v-for="item in evaluationData" :value="item.value" :key="item.value">-->
                                                <!--{{ item.label }}</Option>-->
                                        <!--</Select>-->
                                    <!--</div>-->
                                    <!--<Select v-model="model1" style="width:200px;" @on-change="popularizingLaw"-->
                                    <!--placeholder="请选择档案类型" clearable>-->
                                    <!--<Option v-for="item in typeList" :value="item.FileTypeId" :key="item.FileTypeId">-->
                                    <!--{{ item.FileTypeName }}</Option>-->
                                    <!--</Select>-->
                                    <span style="padding:10px;"></span>
                                    <Select v-model="model2" style="width:200px;" @on-change="onLawyerName" placeholder="请选择律师名称"
                                            clearable filterable>
                                        <Option v-for="item in lawyerData" :value="item.LawyerName" :key="'L'+item.LawyerId">
                                            {{ item.LawyerName }}</Option>
                                    </Select>
                                    <span style="padding:10px;"></span>
                                    <div style="margin:10px 0px;display: inline-block;">
                                        <span>服务时间：</span>
                                        <div style="display: inline-block;">
                                            <RadioGroup type="button" v-model="serviceDate.identification" @on-change="fileAdminList(1)">
                                                <Radio label="m">本月</Radio>
                                                <Radio label="q">本季</Radio>
                                                <Radio label="y">本年</Radio>
                                                <Radio label="a">所有</Radio>
                                                <Radio label="custom">自定义</Radio>
                                            </RadioGroup>
                                            <DatePicker v-if="serviceDate.identification=='custom'"
                                                        @on-change="serviceDate.startTime = $event[0],serviceDate.endTime=$event[1]"
                                                        @on-ok="fileAdminList(1)" type="datetimerange" format="yyyy-MM-dd" placeholder="请选择时间区间">
                                            </DatePicker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="padding:10px;"></div>
                        </div>
                        <div style="margin-bottom: 40px;font-size: 16px;text-align: left;">
                            <div style="text-align: center;padding:20px;">
                                <div class="forMtitle1">
                                    <p style="width:20%">律师</p>
                                    <p style="width:20%">标题</p>
                                    <p style="width:20%">说明</p>
                                    <p style="width: 20%">审核状态</p>
                                    <p style="width: 20%">操作</p>
                                </div>
                                <div class="forMcontent" v-for="(value,index) in ElectronicsList">
                                    <p style="width:20%">{{value.LawyerName}}</p>
                                    <p style="width:20%">{{value.FolderTitle}}</p>
                                    <p style="width:20%">{{value.FolderExplain}}</p>
                                    <div style="width: 20%;display: inline-block;vertical-align:middle;">
                                        {{value.IsCheck==0?'未审核':value.IsCheck==1?'审核通过':'已驳回'}}
                                    </div>
                                    <div style="width: 20%;display: inline-block;vertical-align:middle;">
                                        <Button type="primary" size="small"
                                                @click="see(value.FolderUrl,value.DocumentUrl,value.FolderTitle,value.FolderExplain,value.FolderId);modal3 = true,imgPanel=value">查看/审核</Button>
                                        <span style="padding:5px;"></span>
                                        <!--<Button type="error" size="small" @click="deleteClick(value.FileId)">删除</Button>-->
                                    </div>
                                </div>
                                <div style="padding:10px;"></div>
                                <Page :total="count" show-total :page-size="pageSize" show-elevator
                                      @on-change="page = $event;fileAdminList()" :current="page" />
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
        <!--查看img-->
        <Modal v-model="modal3" title="法制讲座" width="70" draggable :styles="{top: '0'}">
            <div style="height: 420px;overflow: auto;">
                <!--<p>{{FolderUrl}}</p>-->
                <div style="text-align: center;margin-bottom: 10px;" v-if="DocumentUrl">
                    <Button type="primary" @click="seeFile(DocumentUrl)">查看文件</Button>
                </div>
                <!--<Carousel v-model="value1" v-if="modal3 && FolderUrl" @on-click="enlarge">-->
                <!--<CarouselItem v-for="(value,index) in FolderUrl" >-->
                <!--<img :src="value" style="width: 100%;">-->
                <!--</CarouselItem>-->
                <!--</Carousel>-->
                <viewer :images="tupians" v-if="FolderUrl" style="width: 47%;display: inline-block">
                    <!--<i-col span="4" v-for="item in tupians" v-if="tupians">-->
                    <!--<div class="detailed">-->
                    <!--<img :src="item" alt="">-->
                    <!--</div>-->
                    <!--</i-col>-->
                    <Carousel v-model="value1" v-if="modal3 && FolderUrl" @on-click="enlarge">
                        <CarouselItem v-for="(value,index) in FolderUrl" style="text-align: center">
                            <img :src="value" style="max-width:100%;max-height: 420px">
                        </CarouselItem>
                    </Carousel>
                </viewer>
                <div style="display: inline-block;vertical-align: top;width: 50%;margin-left: 20px">
                    <div v-if="FolderTitle">标题：{{FolderTitle}}</div>
                    <div v-if="FolderExplain">说明：{{FolderExplain}}</div>
                </div>
            </div>
            <div slot="footer">
                <template v-if="imgPanel.IsCheck==0">
                    <div style="padding:3px;"></div>
                    <!-- /审核失败/未审核 -->
                    <Button type="success" @click="toExamine(1,imgPanel.FolderId)">通过</Button>
                    <span style="padding:5px;"></span>
                    <Button type="warning" @click="toReject(imgPanel.FolderId)">驳回</Button>
                </template>
                <Button @click="cancel">关闭</Button>
            </div>
        </Modal>
        <Modal v-model="modal6" title="驳回理由" @on-ok="toRejectDetermine" ok-text="发送" style="height: 300px" draggable
               zIndex="9999">
            <Input v-model="checkMsg" maxlength="300" show-word-limit type="textarea" placeholder="驳回理由" />
        </Modal>
    </div>
</template>

<script>
    // import HelloWorld from '../../components/HelloWorld.vue'
    import JSZip from 'jszip'
    import FileSaver from 'file-saver'
    import axios from 'axios'
    export default {
        name: "archives",
        // components: {HelloWorld,},
        data() {
            return {
                serviceDate: {
                    identification: 'a',
                    startTime: '',
                    endTime: '',
                },
                // villageCountDate: {
                //   identification: 'a',
                //   startTime: '',
                //   endTime: '',
                // },
                lawyerCountDate: {
                    identification: 'a',
                    startTime: '',
                    endTime: '',
                },
                FileExplain: '',
                value1: 0,
                value2: ['0', '1', '2'],
                addressData: [{}],
                fileurl: '',
                fileName: '',
                documentUrl: '',
                lawyerId: '',
                modal3: false,
                modal1: false,
                modal2: false,
                modal4: false,
                modal6: false,
                modal5: '0',
                model8: '',
                model9: '',
                tipsWord: '请选择地址',
                cityList: [],
                ElectronicsList: [],
                areaData: null,
                labelInValue: true,
                fileId: '',
                checkMsg: '',
                listData: [],
                arCode: false, //二维码
                current: '',
                lawyerPhone: '',
                lawyerName: '',
                information: [],
                lawyerTable: {
                    col: [],
                    data: [],
                },
                vallageTable: {
                    col: [],
                    data: [],
                },
                page: 1,
                pageSize: 20,
                count: 0,
                page1: 1,
                pageSize1: 5,
                count1: 0,
                adminLogin: '',
                adminPassword: '',
                organizationPhone: '',
                organizationLinkman: '',
                organizationName: '',
                EvaluationCount: '',
                statisticsData: [
                    { name: '服务社区（村）数量', value: '' },
                    { name: '服务用户数量', value: '' },
                    { name: '律师数量', value: '' },
                    { name: '咨询消息数量', value: '' }
                ],
                timelist: [
                    { name: '本月', value: 0 },
                    { name: '本季', value: 1 },
                    { name: '本年', value: 2 },
                    { name: '总计', value: 3 },
                ],
                activeListData: [],
                tupians: [],
                typeList: [],
                fileType: '',
                model1: '',
                model2: '',
                getfileData: [],
                lawyerData: [],
                lawyerNameData: '',
                AreaId: '',
                ServiceTime: '',
                ServiceLong: '',
                ServiceCount: '',
                ServiceTel: '',
                EvaluationTypePid: '',
                EvaluationTitle: '',
                ServiceLinkman: '',
                DailyType: '',
                VillagesName: '',
                imgPanel: {

                },
                evaluationData: [],
                evaluation: [],
                evaluationTypeId: '',
                FolderUrl : '',
                DocumentUrl : '',
                FolderTitle : '',
                FolderExplain : '',
                FolderId : '',
            }
        },
        watch: {
            // 'villageCountDate.identification': function (val) {
            //   if (val != 'custom') {
            //     this.getVallageTable()
            //   }
            // },
            'lawyerCountDate.identification': function (val) {
                if (val != 'custom') {
                    this.getLawyerTableData()
                }
            },
            'serviceDate.identification': function (val) {
                if (val != 'custom') {
                    this.getAdminCount()
                }
            },
        },
        methods: {
            setTime: function (val, val1, val2) {
                console.log(val, val1, val2)
            },
            clickCell: function (item, col, index, event) {
                var _this = this
                var checkType = 0
                var key = col.key.split('-')[0] || ''
                var colId = col.key.split('-')[1] || ''
                console.log(item)
                key == 'check' ? checkType = 1 : key == 'uncheck' ? checkType = 0 : checkType = ''
                if (item.VillagesId) {
                    _this.send({
                        url: '/VillagesFileList',
                        data: {
                            VillagesId: item.VillagesId,
                            FileTypeId: colId,
                            IsCheck: checkType,
                            EndTime: '',
                            StartTime: '',
                        },
                        success: function (res) {
                            console.log(res)
                        }
                    })
                } else if (item.LawyerId) {
                    _this.send({
                        url: '/LawyerFileList',
                        data: {
                            LawyerId: item.LawyerId,
                            FileTypeId: colId,
                            IsCheck: checkType,
                            EndTime: '',
                            StartTime: '',
                        },
                        success: function (res) {
                            console.log(res)
                        }
                    })
                }
            },
            getLawyerTableData: function () {
                var _this = this
                _this.vallageTable.data = []
                _this.send({
                    url: '/FileLawyerGrouping',
                    data: {
                        AdminId: _this.information.AdminId,
                        StartTime: _this.lawyerCountDate.startTime,
                        EndTime: _this.lawyerCountDate.endTime,
                        DateType: _this.lawyerCountDate.identification,
                    },
                    success: function (res) {
                        var firstIndex
                        var list = []
                        for (var index in res.list) {
                            var tempObj = res.list[index]
                            if (!firstIndex) {
                                firstIndex = index
                                _this.lawyerTable.col = []
                                _this.lawyerTable.col.push({ title: '姓名', key: 'LawyerName', sortable: true, minWidth: 100 })
                                _this.lawyerTable.col.push({
                                    title: '服务时长',
                                    key: 'OnlineTime',
                                    sortable: true, minWidth: 80,
                                    render: (h, params) => {
                                        return h('span', [
                                            h('div', { style: { 'color': 'red' } }, parseInt(parseInt(params.row.OnlineTime) / 60) + '小时'),
                                            h('div', { style: { 'color': 'red' } }, parseInt(params.row.OnlineTime) % 60 + '分钟'),
                                        ])
                                    }
                                })
                                for (let i in res.list[firstIndex].Count) {
                                    _this.lawyerTable.col.push({
                                        title: res.list[firstIndex].Count[i].FileTypeName,
                                        children: [
                                            { title: '已审核', key: 'check-' + i, sortable: true, minWidth: 60 },
                                            { title: '未审核', key: 'uncheck-' + i, sortable: true, minWidth: 60 },
                                            {
                                                title: '合计',
                                                key: 'add-' + i,
                                                sortable: true,
                                                minWidth: 60,
                                                render: (h, params) => {
                                                    return h('span', { style: { 'color': 'red' } }, params.row['add-' + i])
                                                }
                                            },
                                        ]
                                    })
                                }
                                // _this.lawyerTable.col.push({
                                //   title: '服务时长(时)',
                                //   children:[
                                //     {title:'已审核',key: 'service-'+i,sortable: true,minWidth:60},
                                //     {title:'未审核',key: 'unservice-'+i,sortable: true,minWidth:60},
                                //     {title:'合计',key: 'addservice-'+i,sortable: true,minWidth:60},
                                //   ],
                                // })

                            }
                            for (var index1 in res.list[index].Count) {
                                tempObj['check-' + index1] = res.list[index].Count[index1].Count
                                tempObj['uncheck-' + index1] = res.list[index].UnCheck[index1].Count
                                tempObj['add-' + index1] = parseInt(res.list[index].Count[index1].Count) + parseInt(res.list[index].UnCheck[index1].Count)
                            }
                            // tempObj['service-'+index1] = res.list[index].ServiceLong[1]
                            // tempObj['unservice-'+index1] = res.list[index].ServiceLong[0]
                            // tempObj['addservice-'+index1] = parseInt(res.list[index].ServiceLong[1])+parseInt(res.list[index].ServiceLong[0])
                            delete tempObj.UnCheck
                            delete tempObj.Count
                            list.push(tempObj)
                        }
                        // console.log(list)
                        _this.lawyerTable.data = list
                    }
                })
            },
            // getVallageTable: function () {
            //   var _this = this
            //   _this.vallageTable.data = []
            //   _this.send({
            //     url: '/FileVillagesGrouping',
            //     data: {
            //       AdminId: _this.information.AdminId,
            //       StartTime: _this.villageCountDate.startTime,
            //       EndTime: _this.villageCountDate.endTime,
            //       DateType: _this.villageCountDate.identification,
            //     },
            //     success: function (res) {
            //       var firstIndex
            //       var list = []
            //       for (var index in res.list) {
            //         var tempObj = res.list[index]
            //         if (!firstIndex) {
            //           firstIndex = index
            //           _this.vallageTable.col = []
            //           _this.vallageTable.col.push({ title: '名称', key: 'VillagesName', sortable: true, minWidth: 100 })
            //           for (var i in res.list[firstIndex].Count) {
            //             _this.vallageTable.col.push({
            //               title: res.list[firstIndex].Count[i].FileTypeName,
            //               children: [
            //                 { title: '已审核', key: 'check-' + i, sortable: true, minWidth: 60 },
            //                 { title: '未审核', key: 'uncheck-' + i, sortable: true, minWidth: 60 },
            //                 {
            //                   title: '合计',
            //                   key: 'add-' + i,
            //                   sortable: true,
            //                   minWidth: 60,
            //                   render: (h, params) => {
            //                     return h('span', { style: { 'color': 'red' } }, params.row['add-' + i])
            //                   }
            //                 },
            //               ]
            //             })
            //           }
            //         }
            //         for (var index1 in res.list[index].Count) {
            //           tempObj['check-' + index1] = res.list[index].Count[index1].Count
            //           tempObj['uncheck-' + index1] = res.list[index].UnCheck[index1].Count
            //           tempObj['add-' + index1] = parseInt(res.list[index].Count[index1].Count) + parseInt(res.list[index].UnCheck[index1].Count)
            //         }
            //         delete tempObj.UnCheck
            //         delete tempObj.Count
            //         list.push(tempObj)
            //       }
            //       // console.log(list)
            //       _this.vallageTable.data = list
            //     }
            //   })
            // },
            onLawyerName: function (value) {
                var _this = this
                _this.lawyerNameData = value
                _this.page = 1
                _this.count = 0
                _this.ElectronicsList = []
                console.log(_this.Count)
                _this.send({
                    consulting: true,
                    url: '/FolderAdminList',
                    data: {
                        StartTime: _this.serviceDate.startTime,
                        EndTime: _this.serviceDate.endTime,
                        DateType: _this.serviceDate.identification,
                        AreaId: _this.AreaId,
                        AdminId: _this.information.AdminId,
                        FileTypeId: _this.fileType,
                        EvaluationTypeId: _this.evaluationTypeId,
                        LawyerName: _this.lawyerNameData,
                        page: _this.page,
                        pageSize: _this.pageSize,
                    },
                    success: function (res) {
                        console.log(res)
                        if (res) {
                            var ElectronicsList = []
                            _this.ElectronicsList = res.List
                            _this.count = res.Count
                        } else {
                            _this.ElectronicsList = []
                            _this.count = 0
                        }
                    }
                })
            },
            popularizingLaw: function (value) {
                var _this = this
                console.log(value)
                _this.fileType = value
                _this.page = 1
                _this.count = 0
                _this.ElectronicsList = []
                _this.send({
                    consulting: true,
                    url: '/FolderAdminList',
                    data: {
                        StartTime: _this.serviceDate.startTime,
                        EndTime: _this.serviceDate.endTime,
                        DateType: _this.serviceDate.identification,
                        AreaId: _this.AreaId,
                        AdminId: _this.information.AdminId,
                        FileTypeId: _this.fileType,
                        EvaluationTypeId: _this.evaluationTypeId,
                        LawyerName: _this.lawyerNameData,
                        page: _this.page,
                        pageSize: _this.pageSize,
                    },
                    success: function (res) {
                        console.log(res)
                        if (res) {
                            var ElectronicsList = []
                            _this.ElectronicsList = res.List
                            _this.count = res.Count
                        } else {
                            _this.ElectronicsList = []
                            _this.count = 0
                        }
                    }
                })
            },
            getTypeList: function () {
                var _this = this
                var data = []
                _this.send({
                    url: '/fileListAdmin',
                    success: function (res) {
                        _this.typeList = res
                        // console.log(res)
                    }
                })
                _this.send({
                    url: '/EvaluationType',
                    success: function (res) {
                        for (var i in res) {
                            data.push(res[i])
                        }
                        convertTree(data)
                        function convertTree(tree) {
                            const result = []
                            tree.forEach((item) => {
                                // 解构赋值
                                let {
                                    EvaluationTypeId: value,
                                    EvaluationTitle: label,
                                    list: children
                                } = item
                                if (children) {
                                    children = convertTree(children)
                                }
                                result.push({
                                    value,
                                    label,
                                    children
                                })
                            })
                            _this.evaluationData = result
                            return result
                        }
                        console.log(_this.evaluationData)
                    }
                })
            },
            addressSelect: function (value, selectedData) {
                var _this = this
                _this.page = 1
                // if(selectedData != []){
                //   if(selectedData[0].value == 0){
                //     _this.evaluationTypeId = 0
                //   }else{
                //     _this.evaluationTypeId = selectedData[1].value
                //   }
                // }else{
                //   _this.evaluationTypeId = ''
                // }
                if (value) {
                    _this.evaluationTypeId = value
                } else {
                    _this.evaluationTypeId = ''
                }
                console.log(_this.evaluationTypeId)
                _this.fileAdminList()
            },
            enlarge: function (e) {
                this.tupians = this.fileurl
                console.log(this.fileurl)
            },
            addMember: function () {
                var _this = this
                _this.send({
                    url: '/AddMember',
                    data: {
                        Level: _this.information.adminLevel,
                        AdminId: _this.information.AdminId,
                        AdminLogin: _this.adminLogin,
                        AdminPassword: _this.adminPassword,
                        OrganizationLinkman: _this.organizationLinkman,
                        OrganizationPhone: _this.organizationPhone,
                        OrganizationName: _this.organizationName,

                    },
                    success: function (res) {
                        _this.adminList()
                    }
                })
            },

            cancel() {
                this.fileurl = ''
                this.fileName = ''
                this.documentUrl = ''
                this.modal3 = false
            },
            enter: function (index) {
                console.log(index)
                this.arCode = true
                this.current = index
            },
            leave() {
                this.arCode = false
                this.current = null
            },
            //列表审核
            activeList: function () {
                var _this = this
                _this.activeListData = []
                _this.count1 = ''
                _this.send({
                    url: '/ActiveList',
                    data: {
                        OrganizationId: _this.information.OrganizationId,
                        page: _this.page1,
                        pageSize: _this.pageSize1,
                    },
                    success: function (res) {
                        _this.activeListData = res.List
                        _this.count1 = res.count
                        // console.log(_this.activeListData)
                    }
                })
            },
            toRejectDetermine: function () {
                var _this = this
                if (_this.checkMsg) {
                    _this.send({
                        url: '/CheckFolder',
                        data: {
                            IsCheck: 2,
                            FolderId: _this.FolderId,
                            CheckMsg: _this.checkMsg,
                            AdminId: _this.information.AdminId,
                        },
                        success: function (res) {
                            _this.fileAdminList()
                            _this.modal3 = false
                        }
                    })
                } else {
                    _this.$Message.error('驳回理由不能为空');
                }

            },
            toReject: function (fileId) {
                var _this = this
                _this.fileId = fileId
                _this.modal6 = true
            },
            toExamine: function (isCheck, fileId) {
                var _this = this
                _this.send({
                    url: '/CheckFolder',
                    confirm: true,
                    data: {
                        IsCheck: isCheck,
                        FolderId: fileId,
                        AdminId: _this.information.AdminId,
                    },
                    success: function (res) {
                        _this.fileAdminList()
                        _this.modal3 = false
                    }
                })
            },
            see: function (FolderUrl,DocumentUrl,FolderTitle,FolderExplain,FolderId) {
                if (FolderUrl) {
                    this.FolderUrl = FolderUrl.split(",")
                }
                this.DocumentUrl = DocumentUrl
                this.FolderTitle = FolderTitle
                this.FolderExplain = FolderExplain
                this.FolderId = FolderId
            },
            seeFile: function (url) {
                var _this = this
                var fileType = url.substr(url.lastIndexOf(".") + 1, 4).toLowerCase()
                if (fileType == "txt") {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: url,
                        transformResponse: [function (data) {
                            var render = new FileReader()
                            render.readAsText(data, 'UTF8')
                            render.onload = function () {
                                if (render.result.indexOf('�') != -1) {
                                    var renderGBK = new FileReader()
                                    renderGBK.readAsText(data, 'GBK')
                                    renderGBK.onload = function () {
                                        var newWindow = window.open('about:blank', '', 'width=400,height=400')
                                        newWindow.document.write(renderGBK.result)
                                    }
                                } else {
                                    var newWindow = window.open('about:blank', '', 'width=400,height=400')
                                    newWindow.document.write(render.result)
                                }
                            }
                        }]
                    })
                } else if (fileType == "doc" || fileType == "doc") {
                    _this.send({
                        url: "/AdminFileContent",
                        data: {
                            FileId: _this.fileId,
                        },
                        success: res => {
                            window.open((SYSCONFIG.server.default.port == 443 ? 'https://' : 'http://') + SYSCONFIG.server.default.host + res.DocumentUrl)
                            // window.location.href = res
                        }
                    })
                } else {
                    window.open(url)
                }
                return
                if (this.documentUrl.substr(this.documentUrl.lastIndexOf(".") + 1) == 'txt') {
                    _this.filesToRar([{
                        fileUrl: _this.documentUrl,
                        renameFileName: 'txt.txt',
                    }], 'txt')
                } else {
                    window.location.href = this.documentUrl
                }

            },
            filesToRar: function (arrImages, filename) {
                let _this = this;
                let zip = new JSZip();
                let cache = {};
                let promises = [];
                _this.title = '正在加载压缩文件';

                for (let item of arrImages) {
                    const promise = _this.getImgArrayBuffer(item.fileUrl).then(data => {
                        // 下载文件, 并存成ArrayBuffer对象(blob)
                        zip.file(item.renameFileName, data, {
                            binary: true
                        }); // 逐个添加文件
                        cache[item.renameFileName] = data;
                    });
                    promises.push(promise);
                }

                Promise.all(promises).then(() => {
                    zip.generateAsync({
                        type: "blob"
                    }).then(content => {
                        _this.title = '正在压缩';
                        // 生成二进制流
                        FileSaver.saveAs(content, filename); // 利用file-saver保存文件  自定义文件名
                        _this.title = '压缩完成';
                    });
                }).catch(res => {
                    _this.$message.error('文件压缩失败');
                });
            },
            getImgArrayBuffer(url) {
                let _this = this;
                return new Promise((resolve, reject) => {
                    //通过请求获取文件blob格式
                    let xmlhttp = new XMLHttpRequest();
                    xmlhttp.open("GET", url, true);
                    xmlhttp.responseType = "blob";
                    console.log(xmlhttp)
                    xmlhttp.onload = function () {
                        if (this.status == 200) {
                            resolve(this.response);
                        } else {
                            reject(this.status);
                        }
                    }
                    xmlhttp.send();
                });

            },
            //    地区
            getAreaData: function () {
                var _this = this
                _this.send({
                    url: '/FileAreaIdList',
                    data: {
                        AdminId: _this.information.AdminId,
                    },
                    success: function (res) {
                        //
                        _this.cityList = res
                        _this.send({
                            url: '/GetfileDay',
                            data: {
                                AreaId: _this.AreaId,
                                AdminId: _this.information.AdminId,
                            },
                            success: function (res) {
                                console.log(res)
                                _this.getfileData = res

                                _this.page = 1
                            }
                        })
                        //
                        // convertTree(res[1])
                        // function convertTree(tree) {
                        //     const result = []
                        //     tree.forEach((item) => {
                        //         // 解构赋值
                        //         let {
                        //             AreaId: value,
                        //             AreaName: label,
                        //             children: children
                        //         } = item
                        //         if (children) {
                        //             children = convertTree(children)
                        //         }
                        //         result.push({
                        //             value,
                        //             label,
                        //             children
                        //         })
                        //     })
                        //     _this.addressData = result
                        //     return result
                        // }
                        // console.log(_this.addressData)
                    }
                })
            },
            choice: function (value) {
                var _this = this
                _this.page = 1
                console.log(value)
                if (value) {
                    var data = value.value.split(',')
                } else {
                    var data = ['0']
                }
                _this.AreaId = data[0]
                if (data[2] == '1') {
                    _this.areaData = _this.cityList[data[1]].children
                } else {

                }
                _this.send({
                    consulting: true,
                    url: '/FolderAdminList',
                    data: {
                        StartTime: _this.serviceDate.startTime,
                        EndTime: _this.serviceDate.endTime,
                        DateType: _this.serviceDate.identification,
                        AreaId: data[0],
                        AdminId: _this.information.AdminId,
                        FileTypeId: _this.fileType,
                        EvaluationTypeId: _this.evaluationTypeId,
                        LawyerName: _this.lawyerNameData,
                        page: _this.page,
                        pageSize: _this.pageSize,
                    },
                    success: function (res) {
                        console.log(res)
                        if (res) {
                            _this.ElectronicsList = res.List
                            _this.count = res.Count
                        } else {
                            _this.ElectronicsList = []
                            _this.count = 0
                        }

                    }
                })
            },
            choice1: function (value) {
                var _this = this
                var data = value.value.split(',')
                console.log(data[0])
            },
            screenData: function (value, selectedData) {
                var _this = this
                _this.send({
                    consulting: true,
                    url: '/FolderAdminList',
                    data: {
                        StartTime: _this.serviceDate.startTime,
                        EndTime: _this.serviceDate.endTime,
                        DateType: _this.serviceDate.identification,
                        AreaId: selectedData[1].value,
                        AdminId: _this.information.AdminId,
                        FileTypeId: _this.fileType,
                        EvaluationTypeId: _this.evaluationTypeId,
                        LawyerName: _this.lawyerNameData,
                        page: _this.page,
                        pageSize: _this.pageSize,
                    },
                    success: function (res) {
                        // console.log(res)
                    }
                })
            },
            deleteClick: function (FileId) {
                var _this = this
                _this.send({
                    confirm: true,
                    url: '/AdminActiveDelete',
                    data: {
                        AdminId: _this.information.AdminId,
                        FileId: FileId,
                    },
                    success: function (res) {
                        _this.fileAdminList()
                    }
                })
            },

            lawyerList: function () {
                var _this = this
                _this.send({
                    url: '/LawyerList',
                    data: {
                        AdminId: _this.information.AdminId,
                    },
                    success: function (res) {
                        _this.lawyerData = res.list
                        // console.log(res)

                    }
                })
            },
            fileAdminList: function (page) {
                var _this = this
                if (page) { _this.page = page }
                _this.send({
                    consulting: true,
                    url: '/FolderAdminList',
                    data: {
                        StartTime: _this.serviceDate.startTime,
                        EndTime: _this.serviceDate.endTime,
                        DateType: _this.serviceDate.identification,
                        AreaId: _this.AreaId,
                        AdminId: _this.information.AdminId,
                        FileTypeId: _this.fileType,
                        EvaluationTypeId: _this.evaluationTypeId,
                        LawyerName: _this.lawyerNameData,
                        page: _this.page,
                        pageSize: _this.pageSize,
                    },
                    success: function (res) {
                        // console.log(res)
                        if (res) {
                            var ElectronicsList = []
                            _this.ElectronicsList = res.List
                            _this.count = res.Count
                        } else {
                            _this.ElectronicsList = []
                            _this.count = 0
                        }
                    }
                })
            }
        },

        mounted() {
            var _this = this
            _this.getTypeList()
            if (window.sessionStorage.adminLevel == undefined) {
                // this.$router.push({name:'login'})
            } else {
                // var data = JSON.parse(window.sessionStorage.information)
                // SYSCONFIG.server.information = JSON.parse(window.sessionStorage.information)
                // console.log(SYSCONFIG.server.information.AdminLogin)
                this.information = window.sessionStorage
                // this.getLawyerTableData()
                // this.getVallageTable()
                // _this.getAreaData()
                _this.fileAdminList()
                _this.send({
                    url: '/GetfileDay',
                    data: {
                        AreaId: _this.AreaId,
                        AdminId: _this.information.AdminId,
                    },
                    success: function (res) {
                        console.log(res)
                        _this.getfileData = res
                    }
                })
            }

            _this.lawyerList()
        },
    }
</script>
