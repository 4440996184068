<style scoped>
.data-body{position:absolute;left:0;right:0;top:0;bottom:0;background-image:url(/img/manager/big-data/bg.png);background-size:cover;min-width: 1400px;overflow: auto;}
.container{min-height:100%;display:flex;flex-direction:column;z-index:1;position:relative;}/*1399+1320+1378 min-width:4300px;*/
.title{height:120px;background-image:url(/img/manager/big-data/title-bg.png);background-repeat:no-repeat;background-position:center;text-align:center;line-height:100px;}
.title-text{font-size:42px;font-weight:bold;background-image: linear-gradient(#fff,#3cf8f9);background-clip:text;color:rgba(0,0,0,0);}
.title-time{color:#fff;font-size:14px;margin-top:-37px;}
.content{flex:1;position:relative;}
.container :deep(.child-title){color:#39ffff;font-size:30px;text-align:center;background-image:url(/img/manager/big-data/child-title.png);background-repeat:no-repeat;height:80px;line-height:80px;background-position:center;}
.container :deep(.ivu-carousel-arrow){width:50px;height:200px;}
</style>

<template>
<div class="data-body">
  <div v-if="location == 'chong-qing'">
    <div style="position: fixed;width: 100%;height: 100%;z-index: 99999;background-color: rgba(227, 227, 227 , 0.8);top: 0;left: 0;" v-if="information.IsSign != 1">
      <div style="text-align: center;color: #000;font-size: 20px;margin-top: 20%">
        <div>感谢您一直以来对“重庆村居法务平台”的支持和关注</div>
        <div>本功能为增值服务项目，请购买后使用！</div>
        <div style="margin-top: 30px">
          <div>平台服务商： 重庆律蛙信息科技有限公司</div>
          <div>地   址：重庆市江北区五里店街道创富路5号1幢4楼</div>
          <div>联系人：唐应坤  18980633318</div>
        </div>
        <div style="display:inline-block;margin-top: 20px">
          <Button @click="introduction()">“重庆村居法务平台”的定制增值服务项目介绍.pdf</Button>
        </div>
        <div style="margin-top: 20px">
          <Button @click="introduction1('https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/pdf/%E9%87%8D%E5%BA%86%E6%9D%91%E5%B1%85%E6%B3%95%E5%8A%A1%E5%B9%B3%E5%8F%B0-%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.docx')">重庆村居法务平台-服务协议.docx</Button>
        </div>
      </div>
    </div>
  </div>
  <iframe src="/img/manager/big-data/material/index.html" style="border:none;position:fixed;z-index:0;width:100%;height:100%"></iframe>
  <div class="container">
    <!-- :style="screenWidth<4300?'min-width:4300px;':''" -->
    <div class="title">
      <div class="title-text" v-if="areaId == '2278'">成都司法通-律蛙数字法治-智慧司法平台-PLS</div>
      <div class="title-text" v-else-if="areaId == '2236' || location == 'chong-qing'">重庆村居法务</div>
      <div class="title-text" v-else-if="areaId == '3269'">{{AdminName}}●{{sysName}}</div>
      <div class="title-text" v-else-if="adminId == '291'">温江区公共法律服务“掌上办”工作平台</div>
      <div class="title-text" v-else>{{areaName}}●{{sysName}}</div>
      <div class="title-time">{{titleTime}}</div>
    </div>
    <div class="content" style="background-image:url(/img/manager/big-data/map-count/content-bg.png);background-position:bottom center;background-repeat: no-repeat;">
      <Row class="text-center" v-if="screenWidth>=4300">
        <!-- screenWidth>=4300 -->
        <Col span="8"><data-count :adminId="adminId" style="width:1399px" class="inline-block align-top text-left"></data-count></Col>
        <Col span="8"><map-count :areaCode="areaCode" :areaId="areaId" :adminId="adminId" style="width:1378px" class="inline-block align-top text-left"></map-count></Col>
        <Col span="8"><use-ranking :areaId="areaId" :adminId="adminId" style="width:1320px" class="inline-block align-top text-left"></use-ranking></Col>
      </Row>
      <Carousel v-else v-model="crousel.index" :radius-dot="false" @on-change="">
        <CarouselItem>
          <transition name="transition-drop">
            <Keep-alive>
              <data-count v-if="crousel.index==0" :adminId="adminId" style="width:1399px;overflow: auto;" class="align-top text-left block-center"></data-count>
            </Keep-alive>
          </transition>
        </CarouselItem>
        <CarouselItem>
          <transition name="transition-drop">
            <keep-alive>
              <map-count v-if="crousel.index==1" :areaCode="areaCode" style="width:1378px;overflow: auto;" :areaId="areaId" :adminId="adminId" :electronicsList="electronicsList" class="align-top text-left block-center"></map-count>
            </keep-alive>
          </transition>
        </CarouselItem>
        <CarouselItem>
          <transition name="transition-drop">
            <keep-alive>
              <use-ranking v-if="crousel.index==2" :areaId="areaId" style="width:1320px;overflow: auto;" :adminId="adminId" :electronicsList="electronicsList" class="align-top text-left block-center"></use-ranking>
            </keep-alive>
          </transition>
        </CarouselItem>
      </Carousel>
    </div>

  </div>
</div>
</template>

<script>
import dataCount from './data-count'
import mapCount from './map-count'
import useRanking from './use-ranking'
export default {
  name:'big-data',
  data() {
    return {
      areaId:'',
      adminId:'',
      titleTime:'',
      crousel:{
        index:1,
      },
      information : [],
      location: '',
      electronicsList: []
    };
  },
  components:{
    'data-count':dataCount,
    'map-count':mapCount,
    'use-ranking':useRanking,
  },
  created() {
    this.adminId = window.sessionStorage.AdminId
    this.areaId = window.sessionStorage.AreaId
  },
  mounted:function(){
    this.setTitleTime()
    var meta = document.getElementsByTagName('meta');
    meta[2].content = ''
    this.information = window.sessionStorage
    this.location = SYSCONFIG.location
    this.justiceList()
  },
  methods: {
    justiceList: function (){
      var _this = this
      if(_this.information.adminLevel == 1){
        var url = '/AllAdminList'
      }else {
        var url = '/justiceList'
      }
      _this.send({
        url : url,
        data: {
          AdminId : window.sessionStorage.AdminId,
          Level : _this.information.adminLevel,
          page: 1,
          pageSize : 9999,
        },
        success: function (res) {
          var data = []
          for(var i in res.list){
            data.push({
              adminLogin: res.list[i].AccountLogin,
              adminPassword: require('js-base64').Base64.encode(res.list[i].AccountPassword),
              name: _this.information.adminLevel == 2 ? res.list[i].AreaName : res.list[i].AreaShortName, // 用于匹配文字
            })
          }
          _this.electronicsList = data
          console.log(this.electronicsList)
        }
      })
    },
    setTitleTime:function(){
      let _this = this
      let date = new Date()
      let dateDay = ''
      switch(date.getDay()){
        case 1:dateDay='一';break
        case 2:dateDay='二';break
        case 3:dateDay='三';break
        case 4:dateDay='四';break
        case 5:dateDay='五';break
        case 6:dateDay='六';break
        case 0:dateDay='日';break
      }
      _this.titleTime = date.getFullYear()+'年'+(date.getMonth()+1)+'月'+date.getDate()+'日 星期'+dateDay+' '+date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
      setTimeout(()=>{
        _this.setTitleTime()
      },1000)
    },
    setScollStyle:function(){

    },
    introduction : function (){
      window.open('https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/pdf/%E2%80%9C%E9%87%8D%E5%BA%86%E6%9D%91%E5%B1%85%E6%B3%95%E5%8A%A1%E2%80%9D%E5%B9%B3%E5%8F%B0%E7%9A%84%E5%AE%9A%E5%88%B6%E5%A2%9E%E5%80%BC%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE%E4%BB%8B%E7%BB%8DV2.pdf');
    },
    introduction1 : function (url){
      window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
    },
  },
  computed:{
    'areaName':function(){
      return window.sessionStorage.AreaName
    },
    'AdminName':function(){
      return window.sessionStorage.AdminName
    },
    'areaCode':function(){
      return window.sessionStorage.areaCode
    },
    'screenWidth':function(){
      return document.body.clientWidth
    },
    'sysName':function(){
      return window.sessionStorage.sysName
    }
  },
}
</script>
