//管理端路由模块加载器 Zima -- 2020-10-20
var open = {
  HaiNan:require('@/views/manager/open/hai-nan/index').default
}

var routes = [
  {path: '/open/V1ZWa1IyTkhTblJTYmxVOQ==',component:open.HaiNan,meta:{title:'海南省-村居法律顾问平台'}},
]

export default {
  routes:routes
}