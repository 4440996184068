<template>
  <div class="chengDu">
    <div class="title">
      <div class="title-text">成都司法通-律蛙数字法治-智慧司法平台-PLS</div>
      <div class="subtitle">实时服务监管系统</div>
      <div class="title-time">{{ titleTime }}</div>
    </div>
    <div class="content">
      <!-- 页面左边部分 -->
      <div class="content_left">
        <div class="content_left_wrap">
          <!-- 左边标题 -->
          <div class="content_title">
            <div class="title_left">司法通数据</div>
            <div class="title_right">
              <!-- <Button
                                :class="item.active ? 'title_btn_active' : 'title_btn'"
                                v-for="item in btnList.leftButton"
                                :key="item.id"
                                @click="changeBtn(item.id, item.group)"
                                >{{ item.name }}</Button
                            > -->
            </div>
          </div>
          <!-- 内容部分 -->
          <div class="content_main">
            <!-- 五个数据 -->
            <div class="left_data_list">
              <Row class="panel-top-data">
                <Col :span="24 / platformData.length" v-for="(item, index) in platformData">
                  <div class="platformDataItem">
                    <div class="panel-top-data-top">
                      <div class="panel-top-data-text">
                        {{ item.name }}
                      </div>
                    </div>
                    <div class="panel-top-data-bottom">
                      <div class="panel-top-data-icon">
                        <Avatar
                          :style="`background-image: url(/img/manager/cheng-du/icon-${
                            index + 1
                          }.png);background-color: #102f64;`"
                        />
                      </div>
                      <div class="panel-top-data-num" :style="`color:${item.color}`">
                        {{ item.value }}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <!-- 表格和地图 -->
            <div class="left_data_map">
              <div class="data_map_table">
                <!-- 表格上的tabbar -->
                <div class="panel-type-bar">
                  <span class="panel-type-bar-icon"></span>
                  <span class="panel-l-type">当日咨询 New30</span>
                  <span
                    class="panel-type-bar-menu"
                    :class="{
                      'panel-type-bar-menu-selected': topList.selected == 'consulting',
                    }"
                    @click="topList.selected = 'consulting'"
                    >咨询服务</span
                  >
                  <span
                    class="panel-type-bar-menu"
                    :class="{
                      'panel-type-bar-menu-selected': topList.selected == 'archives',
                    }"
                    @click="topList.selected = 'archives'"
                    >村居治理档案</span
                  >
                  <span
                    class="panel-type-bar-menu"
                    :class="{
                      'panel-type-bar-menu-selected': topList.selected == 'duty',
                    }"
                    @click="topList.selected = 'duty'"
                    >值班情况</span
                  >
                </div>
                <!-- 表格区域 -->
                <div style="display: flex; flex-direction: row">
                  <div class="panel-l text-center" v-if="topList.selected == 'consulting'">
                    <Row class="panel-l-title">
                      <Col span="4">时间</Col>
                      <Col span="12" class="text-left">村(社区)</Col>
                      <Col span="4">村居顾问</Col>
                      <Col span="4">类型</Col>
                    </Row>
                    <div id="consulting" class="panel-l-list">
                      <Row
                        v-for="(item, index) in topList.consulting"
                        :key="index"
                        :style="index % 2 == 0 ? 'background-color:rgba(249,250,252,0.1)' : ''"
                        class="panel-l-list-item"
                      >
                        <Col span="4">{{
                          item.consultTime
                            ? item.consultTime.substring(0, 5)
                            : formatTopTime(item.ask_time)
                        }}</Col>
                        <Col
                          span="12"
                          class="text-left"
                          :title="
                            (item.area.replace('司法局', '') || '') +
                            (item.Street || '') +
                            item.VillagesName
                          "
                        >
                          {{ item.area.replace('司法局', '') || '' }}{{ item.Street || ''
                          }}{{ item.VillagesName }}
                        </Col>
                        <Col span="4">{{ item.LawyerName }}</Col>
                        <Col span="4" class="text-left">{{
                          item.OrderTypeTitle || '留言咨询'
                        }}</Col>
                      </Row>
                    </div>
                  </div>
                  <div class="panel-l text-center" v-if="topList.selected == 'archives'">
                    <Row class="panel-l-title">
                      <Col span="4">时间</Col>
                      <Col span="12" class="text-left">村(社区)</Col>
                      <Col span="4">村居顾问</Col>
                      <Col span="4">类型</Col>
                    </Row>
                    <div id="archives" class="panel-l-list">
                      <Row
                        v-for="(item, index) in topList.archives"
                        :key="index"
                        :style="index % 2 == 0 ? 'background-color:rgba(249,250,252,0.1)' : ''"
                        class="panel-l-list-item"
                      >
                        <Col span="4">{{ formatTopTime(item.FileTime) }}</Col>
                        <Col
                          span="12"
                          class="text-left"
                          :title="
                            (item.area.replace('司法局', '') || '') +
                            (item.Street || '') +
                            item.VillagesName
                          "
                          >{{ item.area.replace('司法局', '') || '' }}{{ item.Street || ''
                          }}{{ item.VillagesName }}</Col
                        >
                        <Col span="4">{{ item.LawyerName }}</Col>
                        <Col span="4">{{ item.FileTypeName }}</Col>
                      </Row>
                    </div>
                  </div>
                  <div class="panel-l text-center" v-if="topList.selected == 'duty'">
                    <Row class="panel-l-title">
                      <Col span="4">时间</Col>
                      <Col span="12" class="text-left">村(社区)</Col>
                      <Col span="4">村居顾问</Col>
                      <Col span="4">类型</Col>
                    </Row>
                    <div id="duty" class="panel-l-list">
                      <Row
                        v-for="(item, index) in topList.duty"
                        :key="index"
                        :style="index % 2 == 0 ? 'background-color:rgba(249,250,252,0.1)' : ''"
                        class="panel-l-list-item"
                      >
                        <Col span="4">{{
                          formatTopTime(item.ClockInOutTime || item.ClockInTime)
                        }}</Col>
                        <Col
                          span="12"
                          class="text-left"
                          :title="
                            (item.area.replace('司法局', '') || '') +
                            (item.Street || '') +
                            item.VillagesName
                          "
                          >{{ item.area.replace('司法局', '') || '' }}{{ item.Street || ''
                          }}{{ item.VillagesName }}</Col
                        >
                        <Col span="4">{{ item.LawyerName }}</Col>
                        <Col span="4">{{ item.ClockInOutTime ? '值班结束' : '开始值班' }}</Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              <div class="data_map_map">
                <div class="panel-map" ref="echarts-map"></div>
              </div>
            </div>
            <!-- 区域情况 -->
            <div class="useInfo">
              <Row>
                <Col span="24">
                  <div style="height: 200px" ref="echarts-useData"></div>
                </Col>
              </Row>
            </div>
            <!-- 左边最底层数据 -->
            <div class="left_data_bottom">
              <div class="inline-block align-top panel-bottom-c text-left" style="width: 380px">
                <div class="panel-bottom-c-title">
                  <span
                    class="inline-block align-middle"
                    style="
                      background-image: url(/img/manager/big-data/data-count/bottom-c-title-icon.png);
                      width: 46px;
                      height: 47px;
                      margin: -10px -10px 0 0;
                    "
                  ></span>
                  7日服务数据
                </div>
                <div style="padding: 10px"></div>
                <div
                  style="width: 360px; height: 200px"
                  ref="echarts-serveDate"
                  class="inline-block"
                ></div>
              </div>

              <div class="inline-block" style="width: 200px; padding: 0px 20px">
                <div class="panel-bottom-l-title">村居治理档案</div>

                <Row style="height: 200px">
                  <Col
                    span="12"
                    class="panel-bottom-l-col-bg"
                    v-for="(item, index) in archivesData"
                    :key="index"
                  >
                    <div class="panel-bottom-l-text-num">
                      {{ item.value }}
                    </div>
                    <div class="panel-bottom-l-text-title">
                      {{ item.name }}
                    </div>
                  </Col>
                </Row>
              </div>

              <div class="inline-block align-top panel-bottom-c text-left" style="width: 380px">
                <div class="panel-bottom-c-title">
                  <span
                    class="inline-block align-middle"
                    style="
                      background-image: url(/img/manager/big-data/data-count/bottom-c-title-icon.png);
                      width: 46px;
                      height: 47px;
                      margin: -10px -10px 0 0;
                    "
                  ></span>
                  服务类别排名
                </div>
                <div style="padding: 10px"></div>
                <div
                  style="width: 360px; height: 200px"
                  ref="echarts-serveRanking"
                  class="inline-block"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 页面右边部分 -->
      <div class="content_right">
        <!-- 法律援助区域 -->
        <div class="content_main_wrap">
          <div class="content_title">
            <div class="title_left">法律援助数据</div>
            <div class="title_right">
              <Button
                :class="item.active ? 'title_btn_active' : 'title_btn'"
                v-for="item in btnList.centerButton"
                :key="item.id"
                @click="changeBtn(item.id, item.group)"
                >{{ item.name }}</Button
              >
            </div>
          </div>
          <div class="content_main">
            <div class="count_data">
              <div class="warp_left">
                <div class="top_text">办案总数量</div>
                <div class="bottom_text">{{ totalCase }}</div>
              </div>
              <div class="warp_right">
                <Avatar
                  src="/img/manager/cheng-du/icon-count1.png"
                  width="30px"
                  height="30px"
                  shape="square"
                />
              </div>
            </div>
            <div class="count_data">
              <div class="warp_left">
                <div class="top_text">结案总数量</div>
                <div class="bottom_text">{{ finalCase }}</div>
              </div>
              <div class="warp_right">
                <Avatar
                  src="/img/manager/cheng-du/icon-count2.png"
                  width="30px"
                  height="30px"
                  shape="square"
                />
              </div>
            </div>
            <div class="list_box">
              <div class="list-item" v-for="item in dataList" :key="item.id">
                <div class="item-top">{{ item.name }}</div>
                <div class="item-bottom">{{ item.count }}</div>
              </div>
            </div>
            <div class="carousel">
              <div class="carousel-point">
                <div
                  :class="item.active ? 'pointSolid' : 'pointHollow'"
                  v-for="item in carouselListLeft"
                  :key="item.id"
                ></div>
              </div>
              <div class="carousel-text">
                {{ activeItemLeft }}
              </div>
            </div>
            <div class="data_table">
              <div
                style="width: 380px; height: 550px"
                ref="echarts-peopleNumber"
                class="inline-block"
              ></div>
            </div>
          </div>
        </div>
        <!-- 12348热线区域 -->
        <div class="content_main_wrap">
          <div class="content_title">
            <div class="title_left">12348热线数据</div>
            <div class="title_right">
              <Button
                :class="item.active ? 'title_btn_active' : 'title_btn'"
                v-for="item in btnList.rightButton"
                :key="item.id"
                @click="changeBtn(item.id, item.group)"
                >{{ item.name }}</Button
              >
            </div>
          </div>
          <div class="content_main">
            <div class="count_data">
              <div class="warp_left">
                <div class="top_text">呼入数量</div>
                <div class="bottom_text">{{ callIn }}</div>
              </div>
              <div class="warp_right">
                <Avatar
                  src="/img/manager/cheng-du/call-in.png"
                  width="30px"
                  height="30px"
                  shape="square"
                />
              </div>
            </div>
            <div class="count_data">
              <div class="warp_left">
                <div class="top_text">呼出数量</div>
                <div class="bottom_text">{{ callOut }}</div>
              </div>
              <div class="warp_right">
                <Avatar
                  src="/img/manager/cheng-du/call-out.png"
                  width="30px"
                  height="30px"
                  shape="square"
                />
              </div>
            </div>
            <div class="count_data">
              <div class="warp_left">
                <div class="top_text">接通数量</div>
                <div class="bottom_text">{{ called }}</div>
              </div>
              <div class="warp_right">
                <Avatar
                  src="/img/manager/cheng-du/calling.png"
                  width="30px"
                  height="30px"
                  shape="square"
                />
              </div>
            </div>

            <div class="carousel">
              <div class="carousel-point">
                <div
                  :class="item.active ? 'pointSolid' : 'pointHollow'"
                  v-for="item in carouselListRight"
                  :key="item.id"
                ></div>
              </div>
              <div class="carousel-text">
                {{ activeItemRight }}
              </div>
            </div>
            <div class="data_table">
              <div
                style="width: 380px; height: 530px"
                ref="echarts-callNumber"
                class="inline-block"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import axios from 'axios'
export default {
  name: 'CodeChengDu',
  data() {
    return {
      areaId: '',
      adminId: '',
      titleTime: '',
      num: 0,
      changerTimer: null,
      scrollTimer: null,
      rightDataList: [],
      btnList: {
        // 司法通数据按钮组
        // leftButton: [
        // 	{ id: 1, name: '本年', active: true, group: 'leftButton' },
        // 	{ id: 2, name: '本月', active: false, group: 'leftButton' },
        // 	// { id: 3, name: '本日', active: false, group: 'leftButton' }
        // ],
        // 法律援助数据按钮组
        centerButton: [
          {
            id: 1,
            name: '本年',
            active: true,
            group: 'centerButton',
            totalCase: '6196',
            finalCase: '1586',
            table: 'peopleYear',
            xingshi: '114',
            mingshi: '3706',
            xingzheng: '98',
            //falvbangzhu: '2315',
            zhixing: '304',
          },
          {
            id: 2,
            name: '上月',
            active: false,
            group: 'centerButton',
            totalCase: '1255',
            finalCase: '60',
            table: 'peopleMonth',
            xingshi: '15',
            mingshi: '764',
            xingzheng: '20',
            //falvbangzhu: '2315',
            zhixing: '88',
          },
          // {
          // 	id: 3,
          // 	name: '本日',
          // 	active: false,
          // 	group: 'centerButton',
          // },
        ],
        // 12348热线数据按钮组
        rightButton: [
          {
            id: 1,
            name: '本年',
            active: true,
            group: 'rightButton',
            callIn: '146779',
            callOut: '5498',
            called: '135870',
            table: 'fullYear',
          },
          {
            id: 2,
            name: '上月',
            active: false,
            group: 'rightButton',
            callIn: '32559',
            callOut: '2032',
            called: '30040',
            table: 'lastMonth',
          },
          // {
          // 	id: 3,
          // 	name: '本日',
          // 	active: false,
          // 	group: 'rightButton',
          // },
        ],
      },

      archivesData: [
        { name: '法治宣讲', value: '0000' }, //法制宣传
        { name: '参与调解', value: '0000' }, //
        { name: '法律服务', value: '0000' },
        { name: '依法治理', value: '0000' },
        { name: '法律咨询', value: '0000' },
        { name: '值班日志', value: '0000' },
      ],
      platformData: [
        { name: '用户量', value: '000000', color: '#9354D8' },
        { name: '服务次数', value: '000000', color: '#C89019' },
        { name: '服务村(社区)数量', value: '000000', color: '#1CB37F' },
        { name: '法律顾问', value: '000000', color: '#00A8FF' },
      ],
      topList: {
        consulting: [], //咨询服务区县呼入/呼出
        archives: [], //档案
        duty: [], //值班
        selected: 'consulting',
      },
      dataList: [
        { name: '刑事', count: 209, id: 1 },
        { name: '民事', count: 370, id: 2 },
        { name: '行政', count: 8, id: 3 },
        //{ name: '法律帮助', count: 2315, id: 4 },
        { name: '执行', count: 205, id: 4 },
      ],
      carouselListLeft: [
        { id: 1, name: '受援人数', active: false },
        { id: 2, name: '受援人数', active: true },
        { id: 3, name: '受援人数', active: false },
        { id: 4, name: '受援人数', active: false },
      ],
      carouselListRight: [
        { id: 1, name: '区县呼入/呼出', active: false },
        { id: 2, name: '区县接通数', active: true },
        { id: 3, name: '区县呼入/呼出', active: false },
        { id: 4, name: '区县呼入/呼出', active: false },
      ],
      // 呼入数量
      callIn: '146779',
      // 呼出数量
      callOut: '5498',
      // 接通数量
      called: '135870',
      // 办案总数量
      totalCase: '6196',
      // 结案总数量
      finalCase: '1586',
      fullYear: [
        { area: '四川天府新区', num: 2327 },
        { area: '成都东部新区', num: 0 },
        { area: '成都高新区', num: 2357 },
        { area: '锦江区', num: 2878 },
        { area: '青羊区', num: 2295 },
        { area: '金牛区', num: 3136 },
        { area: '武侯区', num: 2412 },
        { area: '成华区', num: 2121 },
        { area: '龙泉驿区', num: 2967 },
        { area: '青白江区', num: 2875 },
        { area: '新都区', num: 2975 },
        { area: '温江区', num: 3779 },
        { area: '双流区', num: 2644 },
        { area: '郫都区', num: 2361 },
        { area: '新津区', num: 2489 },
        { area: '简阳市', num: 2063 },
        { area: '都江堰市', num: 2143 },
        { area: '彭州市', num: 2843 },
        { area: '邛崃市', num: 2375 },
        { area: '崇州市', num: 1452 },
        { area: '金堂县', num: 2424 },
        { area: '大邑县', num: 1032 },
        { area: '蒲江县', num: 1683 },
      ],
      lastMonth: [
        { area: '四川天府新区', num: 538 },
        { area: '成都东部新区', num: 0 },
        { area: '成都高新区', num: 586 },
        { area: '锦江区', num: 682 },
        { area: '青羊区', num: 551 },
        { area: '金牛区', num: 724 },
        { area: '武侯区', num: 477 },
        { area: '成华区', num: 476 },
        { area: '龙泉驿区', num: 723 },
        { area: '青白江区', num: 712 },
        { area: '新都区', num: 680 },
        { area: '温江区', num: 902 },
        { area: '双流区', num: 598 },
        { area: '郫都区', num: 543 },
        { area: '新津区', num: 578 },
        { area: '简阳市', num: 453 },
        { area: '都江堰市', num: 464 },
        { area: '彭州市', num: 777 },
        { area: '邛崃市', num: 493 },
        { area: '崇州市', num: 390 },
        { area: '金堂县', num: 569 },
        { area: '大邑县', num: 242 },
        { area: '蒲江县', num: 510 },
      ],
      peopleYear: [
        { name: '农民工', num: 2323 },
        { name: '妇女', num: 927 },
        { name: '老年人', num: 286 },
        { name: '残疾人', num: 136 },
        { name: '未成年人', num: 880 },
        { name: '少数民族', num: 103 },
      ],
      peopleMonth: [
        { name: '农民工', num: 481 },
        { name: '妇女', num: 211 },
        { name: '老年人', num: 39 },
        { name: '残疾人', num: 30 },
        { name: '未成年人', num: 171 },
        { name: '少数民族', num: 25 },
      ],
      //12348表格
      echartsCallNumber: {
        //呼出/呼入排名
        tooltip: {
          //提示框组件
          trigger: 'axis', //触发类型:坐标轴触发
        },
        grid: {
          //直角坐标系
          top: '5%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true, //设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {
          //轴
          boundaryGap: false, //坐标轴两边留白策略
          type: 'value', //数值轴
          axisLine: {
            //轴线相关设置
            lineStyle: {
              color: '#ffffff',
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          //y轴123
          type: 'category', //类目轴
          data: [
            '载入中',
            '载入中',
            '载入中',
            '载入中',
            '载入中',
            '载入中',
            '载入中',
            '载入中',
            '载入中',
            '载入中',
            '载入中',
          ],
          axisLine: {
            //轴线相关设置
            lineStyle: {
              color: '#ffffff',
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            data: [6666, 6666, 6666, 6666, 6666, 6666, 6666, 6666, 6666, 6666, 6666],
            type: 'bar', //柱状图
            barWidth: 8,
            areaStyle: {},
            itemStyle: {
              //柱子的样式
              barBorderRadius: [0, 5, 5, 0],
            },
            color: {
              //色彩渐变
              type: 'linear', //线性渐变
              x: 0,
              y: 1,
              x2: 1,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: '#1D3EC3', //蓝色
                },
                {
                  offset: 1,
                  color: '#47CD93', //紫色
                },
              ],
            },
          },
        ],
      },
      //法律援助表格
      echartsPeopleNumber: {
        //人群数量排名
        tooltip: {
          //提示框组件
          trigger: 'axis', //触发类型:坐标轴触发
        },
        xAxis: {
          //x轴
          type: 'category', //类目轴
          data: ['加载中', '加载中', '加载中', '加载中', '加载中', '加载中', '加载中'],
          label: {
            show: false,
          },
          axisLine: {
            //轴线相关设置
            lineStyle: {
              color: '#ffffff',
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          //y轴
          type: 'value', //数值轴
          axisLine: {
            //轴线相关设置
            lineStyle: {
              color: '#ffffff',
            },
          },
          splitLine: {
            show: false,
          },
          axisLabel: {
            rotate: 45,
          },
        },
        series: [
          //折线/面积图
          {
            data: [50, 80, 500, 130, 400, 390, 998],
            type: 'bar', //柱状图
            barWidth: 45, //柱宽度
            label: {
              show: true,
              position: 'top',
              color: '#5DEFFF',
              align: 'center',
              formatter: function (data) {
                return '{top|' + data.name + '}' + '\n' + '{bottom|' + data.value + '}'
              },
              rich: {
                top: {
                  color: '#FFF',
                },
                bottom: {
                  color: '#5DEFFF',
                },
              },
            },
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(45, 120, 232, 0.2)',
            },
            itemStyle: {
              //柱子的样式
              color: '#2D78E8',
              width: '45px',
            },
          },
        ],
      },
      //地图表格
      echartsMap: {
        tooltip: {
          trigger: 'item',
          formatter: '{b}:{c}',
        },
        grid: {
          //直角坐标系
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true, //设置直角坐标系区域包含坐标轴的刻度标签
        },
        visualMap: {
          min: 0,
          max: 0,
          show: false, //取消左侧visualMap-continuous组件保留数据映射功能
          inRange: {
            //设置最低最高颜色
            color: ['#063B89', '#6099EC'],
          },
        },
        series: [
          {
            type: 'map',
            zoom: 1.3, //地图缩放
            map: '地图',
            nameMap: {
              新津县: '新津区',
            }, //自定义地图名称映射geo-json数据能够对应，则忽略
            emphasis: {
              //地图各版块高亮样式设置
              label: {
                color: '#ffffff', //地图板块高亮区域文字颜色
              },
            },
            itemStyle: {
              borderColor: '#ffffff', //地图各板块描边颜色
              borderWidth: 0.3,
            },
            label: {
              show: true, //区域名称上屏
              color: '#4ADEFE', //地图板块区域名称颜色
              textBorderColor: '#000000', //地图板块区域名称文字描边颜色
              textBorderWidth: 2, //地图板块区域名称文字描边宽度
              fontSize: 10,
            },
            // formatter:[

            // ],
            data: [
              //{ name: '巫溪县', value: 20057.34},
            ],
          },
        ],
      },
      //区域服务表格
      echartsUseData: {
        //使用数据图标
        tooltip: {
          //提示框组件
          trigger: 'axis', //触发类型:坐标轴触发
        },
        legend: {
          //图例组件
          left: '40px',
          textStyle: {
            color: '#ffffff',
          },
        },
        grid: {
          //直角坐标系
          top: '40px',
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true, //设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {
          //x轴
          type: 'category', //类目轴
          data: ['加载中', '加载中', '加载中', '加载中', '加载中', '加载中', '加载中'],
          axisLine: {
            //轴线相关设置
            lineStyle: {
              color: 'rgba(10,84,234)',
            },
          },
          axisLabel: {
            formatter: function (value, index) {
              if (value.length > 4) {
                let name = value.slice(0, 3)
                let str = name.split('')
                return str.join('\n') + '\n...'
              }
              let str = value.split('')
              return str.join('\n')
            },
            color: '#fff',
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          //y轴
          type: 'value', //数值轴
          axisLine: {
            //轴线相关设置
            lineStyle: {
              color: 'rgba(10,84,234)',
            },
          },
          axisLabel: {
            color: '#fff',
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: '区域服务情况',
            data: [50, 80, 500, 130, 400, 390, 998],
            type: 'bar', //柱状图
            barWidth: 8, //柱宽度
            color: {
              //柱子颜色渐变
              type: 'linear', //线性渐变
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#76d7ff', //深色
                },
                {
                  offset: 1,
                  color: '#1122ff', //亮色
                },
              ],
            },
            itemStyle: {
              //柱子的样式
              barBorderRadius: [5, 5, 0, 0],
            },
          },
        ],
      },
      //7日服务数据表格
      echartsServeData: {
        tooltip: {
          //提示框组件
          trigger: 'axis', //触发类型:坐标轴触发
        },
        grid: {
          //直角坐标系
          top: '5%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true, //设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {
          //x轴
          boundaryGap: false, //坐标轴两边留白策略
          type: 'category', //类目轴
          data: ['加载中', '加载中', '加载中', '加载中', '加载中', '加载中', '加载中'],
          axisLine: {
            //轴线相关设置
            lineStyle: {
              color: '#ffffff',
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          //y轴
          type: 'value', //数值轴
          axisLine: {
            //轴线相关设置
            lineStyle: {
              color: '#ffffff',
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            //折线/面积图
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line', //折线图
            smooth: true, //是上色折现覆盖区域
            areaStyle: {},
            lineStyle: {
              //折线样式
              width: 4,
            },
            color: {
              //色彩渐变
              type: 'linear', //线性渐变
              x: 0,
              y: 1,
              x2: 1,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: '#008aff', //蓝色
                },
                {
                  offset: 1,
                  color: '#f950ff', //紫色
                },
              ],
            },
          },
        ],
      },
      //服务类别排名表格
      echartsServeRanking: {
        tooltip: {
          //提示框组件
          trigger: 'axis', //触发类型:坐标轴触发
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          //直角坐标系
          top: '5%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true, //设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {
          //x轴
          type: 'value', //数值轴
          axisLine: {
            //轴线相关设置
            lineStyle: {
              color: '#ffffff',
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          //y轴
          type: 'category', //类目轴
          data: ['加载中', '加载中', '加载中', '加载中', '加载中', '加载中', '加载中'],
          axisLine: {
            //轴线相关设置
            lineStyle: {
              color: '#ffffff',
            },
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          //折线/面积图
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'bar', //柱状图
            barWidth: 10, //柱宽度
            color: {
              //柱子颜色渐变
              type: 'linear', //线性渐变
              x: 0,
              y: 1,
              x2: 1,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: '#1d3ec3', //蓝色
                },
                {
                  offset: 1,
                  color: '#47cd93', //绿色
                },
              ],
            },
            itemStyle: {
              //柱子的样式
              barBorderRadius: [0, 5, 5, 0],
            },
          },
        ],
      },
    }
  },
  created() {
    this.adminId = window.sessionStorage.AdminId
    this.areaId = window.sessionStorage.AreaId
    this.setTitleTime()
  },
  mounted() {
    this.makeMap(this.areaCode)
    echarts.init(this.$refs['echarts-callNumber']).setOption(this.echartsCallNumber)
    echarts.init(this.$refs['echarts-peopleNumber']).setOption(this.echartsPeopleNumber)
    echarts.init(this.$refs['echarts-useData']).setOption(this.echartsUseData)
    echarts.init(this.$refs['echarts-serveDate']).setOption(this.echartsServeData)
    echarts.init(this.$refs['echarts-serveRanking']).setOption(this.echartsServeRanking)

    this.getUseData()
    this.getServeData()
    this.getServeRanking()
    this.getArchivesData()
    this.getPlatformData()
    this.getPeopleData(this.peopleYear)
    this.getCallData(this.fullYear)
    this.autoChange()
    this.autoSroll('consulting')
  },
  computed: {
    areaName: function () {
      return window.sessionStorage.AreaName
    },
    areaCode: function () {
      return window.sessionStorage.areaCode
    },
    activeItemLeft() {
      for (let i in this.carouselListLeft) {
        if (this.carouselListLeft[i].active) {
          return this.carouselListLeft[i].name
        }
      }
    },
    activeItemRight() {
      for (let i in this.carouselListRight) {
        if (this.carouselListRight[i].active) {
          return this.carouselListRight[i].name
        }
      }
    },
  },
  destroyed() {
    clearInterval(this.changerTimer)
    clearInterval(this.scrollTimer)
    this.changerTimer = null
    this.scrollTimer = null
  },
  watch: {
    'topList.selected': {
      handler: function (value) {
        switch (value) {
          case 'consulting':
            this.getConsulting()
            break
          case 'archives':
            this.getArchives()
            break
          case 'duty':
            this.getDuty()
            break
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    autoChange() {
      let that = this
      let list = ['consulting', 'archives', 'duty']
      that.changerTimer = setInterval(() => {
        that.num += 1
        if (that.num > 2) {
          that.num = 0 // 循环
          // that.topList.selected = 'consulting'
          // clearInterval(that.scrollTimer)
          // that.autoSroll('consulting')
        }
        for (let n in list) {
          if (n == that.num) {
            that.topList.selected = list[n]
            clearInterval(that.scrollTimer)
            that.autoSroll(list[n])
          }
        }
      }, 5000)
    },
    autoSroll(Id) {
      // flag 为true时停止滚动
      let that = this
      let flag = false
      // 定时器
      function roll() {
        let h = -1
        that.scrollTimer = setInterval(function () {
          flag = true
          //获取当前滚动条高度
          let current = document.getElementById(Id).scrollTop
          if (current == h) {
            //滚动到底端,返回顶端
            h = 0
            document.getElementById(Id).scrollTop = h + 2
          } else {
            //以25ms/3.5px的速度滚动
            h = current
            document.getElementById(Id).scrollTop = h + 2
          }
        }, 100)
      }
      roll()
    },
    //点击切换按钮
    changeBtn(btnID, group) {
      this.btnList[group].forEach((item) => {
        if (item.id === btnID) {
          if (group === 'rightButton') {
            item.active = true
            this.callIn = item.callIn
            this.callOut = item.callOut
            this.called = item.called
            console.log(item.table)
            this.getCallData(this[item.table])
          } else {
            item.active = true
            this.totalCase = item.totalCase
            this.finalCase = item.finalCase
            this.getPeopleData(this[item.table])
            this.dataList[0].count = item.xingshi
            this.dataList[1].count = item.mingshi
            this.dataList[2].count = item.xingzheng
            //this.dataList[3].count = item.falvbangzhu
            this.dataList[3].count = item.zhixing
          }
        } else {
          item.active = false
        }
      })
    },
    // 顶部使用数据
    getPlatformData: function () {
      var _this = this
      _this.send({
        url: '/OrganizationCountUser',
        data: {
          AdminId: _this.adminId,
          // typeId: _this.typeId,
          // start_time: _this.startTime,//开始时间
          // end_time: _this.endTime
        },
        success: function (res) {
          _this.platformData[0].value = res.count
        },
      })
      _this.send({
        url: '/OrganizationCountOrder',
        data: {
          AdminId: _this.adminId,
          // typeId: _this.typeId,
          // start_time: _this.startTime,//开始时间
          // end_time: _this.endTime
        },
        success: function (res) {
          _this.platformData[1].value = res.count
        },
      })
      _this.send({
        url: '/OrganizationCountVillage',
        data: {
          AdminId: _this.adminId,
          // typeId: _this.typeId,
          // start_time: _this.startTime,//开始时间
          // end_time: _this.endTime
        },
        success: function (res) {
          _this.platformData[2].value = res.count
          // _this.platformData[3].value = res.count
        },
      })
      _this.send({
        url: '/OrganizationCountLawyer',
        data: {
          AdminId: _this.adminId,
          // typeId: _this.typeId,
          // start_time: _this.startTime,//开始时间
          // end_time: _this.endTime
        },
        success: function (res) {
          _this.platformData[3].value = res.count
          // _this.platformData[4].value = res.count
        },
      })
    },
    // 7日服务数据
    getServeData: function () {
      let _this = this
      _this.send({
        url: '/GetTenData',
        data: {
          AdminId: _this.adminId,
        },
        success: function (res) {
          _this.echartsServeData.series[0].data = []
          _this.echartsServeData.xAxis.data = []
          for (let i = 0; i < res.list.day.length; i++) {
            //取前7日数据，倒序输出
            if (i > 6) {
              continue
            }
            let count = 0
            for (let index in res.list.day[i]) {
              if (index == 'time') {
                continue
              }
              count += parseInt(res.list.day[i][index])
            }
            _this.echartsServeData.xAxis.data.push(
              res.list.day[i].time.substring(5, res.list.day[i].time.length)
            )
            _this.echartsServeData.series[0].data.push(count)
          }
          echarts.init(_this.$refs['echarts-serveDate']).setOption(_this.echartsServeData)
        },
      })
    },
    getUseData: function () {
      let _this = this
      _this.send({
        // url: '/DifficultCount',
        url: '/areaRank',
        loading: false,
        data: {
          AdminId: _this.adminId,
        },
        success: function (res) {
          _this.useData = []
          _this.echartsUseData.xAxis.data = []
          _this.echartsUseData.series[0].data = []
          // _this.echartsUseData.series[1].data = []
          let tableList = JSON.parse(JSON.stringify(res))
          let echartsList = JSON.parse(JSON.stringify(res))
          //按用户数排序
          tableList.sort((item, item1) => {
            return item1.userCount - item.userCount
          })
          //按咨询数排序
          echartsList.sort((item, item1) => {
            return item1.orderCount - item.orderCount
          })
          //推送数据到表格
          for (let index in tableList) {
            _this.useData.push({
              name: tableList[index].AdminName,
              consultingCount: tableList[index].orderCount,
              userCount: tableList[index].userCount,
              archivesCount: tableList[index].fileCount,
            })
          }
          //推送数据到图表
          for (let index in echartsList) {
            _this.echartsUseData.xAxis.data.push(echartsList[index].AdminName.replace('司法局', ''))
            _this.echartsUseData.series[0].data.push(echartsList[index].orderCount)
          }
          echarts.init(_this.$refs['echarts-useData']).setOption(_this.echartsUseData)
        },
      })
    },
    makeMap: function (areaCode) {
      let _this = this
      console.log(areaCode)
      // return
      _this
        .getMapGeo(areaCode) //获取地图渲染GEOJSON
        .then(_this.getMapData) //获取地图的数据
        //.then(_this.checkSquireArea) //检查特殊地区数据
        .then((res) => {
          _this.echartsMap.series[0].data = []
          for (let index in res) {
            if (res[index].user_count > _this.echartsMap.visualMap.max) {
              _this.echartsMap.visualMap.max = res[index].user_count
            }
            _this.echartsMap.series[0].data.push({
              name: res[index].AreaName,
              value: res[index].user_count,
            })
          }
          echarts.init(_this.$refs['echarts-map']).setOption(_this.echartsMap)
          // echarts.init(_this.$refs['echarts-map']).hideLoading()
        })
    },
    getMapGeo: function (areaCode) {
      let _this = this
      return new Promise((resolve, reject) => {
        axios.get(`/geo-json/${areaCode}.json`).then((res) => {
          console.log(res.data)
          echarts.registerMap('地图', res.data)
          echarts.init(_this.$refs['echarts-map']).setOption(_this.echartsMap)
          resolve(areaCode)
        })
      })
    },
    getMapData: function (areaCode) {
      let _this = this
      return new Promise((resolve, reject) => {
        _this.send({
          url: '/_GetMapCount',
          loading: false,
          data: {
            AreaCode: areaCode,
          },
          success: function (res) {
            resolve(res)
          },
        })
      })
    },
    //咨询Top30
    getConsulting: function () {
      let _this = this
      _this.send({
        url: '/topCount',
        loading: false,
        data: {
          AdminId: _this.adminId,
          pageSize: 30,
        },
        success: function (res) {
          _this.topList.consulting = res
        },
      })
    },
    //档案Top30
    getArchives: function () {
      let _this = this
      _this.send({
        url: '/topFileCount',
        loading: false,
        success: function (res) {
          _this.topList.archives = res
        },
      })
    },
    //值班Top30
    getDuty: function () {
      let _this = this
      _this.send({
        url: '/topDutyCount',
        loading: false,
        success: function (res) {
          _this.topList.duty = res
        },
      })
    },
    // 日志数据
    getArchivesData: function () {
      let _this = this
      _this.send({
        url: '/fileGroup',
        data: {
          AdminId: _this.adminId,
        },
        success: function (res) {
          _this.archivesData = []
          res.length = 6
          for (let index in res) {
            _this.archivesData.push({
              name: res[index].EvaluationTitle,
              value: res[index].total,
            })
          }
        },
      })
    },
    // 服务类别排名
    getServeRanking: function () {
      let _this = this
      _this.send({
        url: '/OrderTypeCount',
        data: {
          AdminId: _this.adminId,
        },
        success: function (res) {
          console.log(res)
          _this.echartsServeRanking.series[0].data = []
          _this.echartsServeRanking.yAxis.data = []
          //删除有效咨询
          for (let index in res) {
            if (res[index].OrderTypeId == '12') {
              res.splice(index, 1)
            }
          }
          //数据排序
          res.sort((item, item1) => {
            return item1.count - item.count
          })
          res.length = 10
          //倒序输出
          for (let i = res.length - 1; i >= 0; i--) {
            _this.echartsServeRanking.yAxis.data.push(res[i].OrderTypeTitle)
            _this.echartsServeRanking.series[0].data.push(res[i].count)
          }
          // for(let i in res){
          //   _this.echartsServeRanking.yAxis.data.push(res[i].OrderTypeTitle)
          //   _this.echartsServeRanking.series[0].data.push(res[i].count)
          // }
          echarts.init(_this.$refs['echarts-serveRanking']).setOption(_this.echartsServeRanking)
        },
      })
    },
    getPeopleData(data) {
      let _this = this
      let res = data
      _this.echartsPeopleNumber.xAxis.data = []
      _this.echartsPeopleNumber.series[0].data = []
      res.sort((item, item1) => {
        return item1.num - item.num
      })
      for (let i in res) {
        _this.echartsPeopleNumber.xAxis.data.push(res[i].name)
        _this.echartsPeopleNumber.series[0].data.push(res[i].num)
      }
      echarts.init(this.$refs['echarts-peopleNumber']).setOption(this.echartsPeopleNumber)
    },
    getCallData(data) {
      let _this = this
      let res = data
      _this.echartsCallNumber.yAxis.data = []
      _this.echartsCallNumber.series[0].data = []
      // 暂时取消排序
      // res.sort((item, item1) => {
      //   return item.num - item1.num
      // })

      for (let i in res) {
        _this.echartsCallNumber.yAxis.data.unshift(res[i].area)
        _this.echartsCallNumber.series[0].data.unshift(res[i].num)
      }
      echarts.init(this.$refs['echarts-callNumber']).setOption(this.echartsCallNumber)
    },
    formatTopTime: function (time) {
      time = time + '000'
      let date = new Date(parseInt(time))
      return (
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) +
        ':' +
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      )
    },
    formatTopDate: function (time) {
      time = time + '000'
      let date = new Date(parseInt(time))
      return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
    },

    setTitleTime: function () {
      let _this = this
      let date = new Date()
      let dateDay = ''
      switch (date.getDay()) {
        case 1:
          dateDay = '一'
          break
        case 2:
          dateDay = '二'
          break
        case 3:
          dateDay = '三'
          break
        case 4:
          dateDay = '四'
          break
        case 5:
          dateDay = '五'
          break
        case 6:
          dateDay = '六'
          break
        case 0:
          dateDay = '日'
          break
      }
      _this.titleTime =
        date.getFullYear() +
        '年' +
        (date.getMonth() + 1) +
        '月' +
        date.getDate() +
        '日 星期' +
        dateDay +
        ' ' +
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds()
      setTimeout(() => {
        _this.setTitleTime()
      }, 1000)
    },
  },
}
</script>
<style scoped>
.chengDu {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url(/img/manager/big-data/bg.png);
  background-size: cover;
  overflow: scroll;
}
.chengDu::-webkit-scrollbar {
  display: none;
}
.title {
  width: 1920px;
  height: 100px;
  background-image: url(/img/manager/cheng-du/top.png);
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 100px;
}
.title-text {
  position: relative;
  top: -20%;
  font-size: 34px;
  font-weight: bold;
  background: linear-gradient(92deg, #348fff 0%, #5df2ff 48.8525390625%, #01aaff 100%);
  background-clip: text;
  color: rgba(0, 0, 0, 0);
}
.title-time {
  position: relative;
  top: -150%;
  left: 40%;
  height: 50px;
  color: #fff;
  font-size: 14px;
}
.subtitle {
  position: relative;
  top: -70%;
  color: #fff;
  font-size: 26px;
  /* margin-top: -50px; */
  color: #5cf2ff;
  font-weight: 700;
}
.content {
  display: flex;
}
.content_left_wrap {
  width: 1000px;
  background: rgba(130, 130, 130, 0);
  border: 1px solid #16459b;
  background-image: url('/img/manager/cheng-du/left-bg.png');
}
.content_left {
  width: 1020px;
  height: 960px;
  padding: 20px 0 0 20px;
}
.main_left {
  width: 100%;
  height: 100%;
  background: rgba(130, 130, 130, 0);
  border: 1px solid #16459b;
}

.title_btn {
  width: 60px;
  height: 26px;
  background: rgba(130, 130, 130, 0);
  border: 1px solid #1f78d4;
  border-radius: 0px;
  margin-right: 10px;
  font-weight: 400;
  color: #fff;
}
.content_main {
  width: 100%;
  height: calc(100% - 40px);
  padding: 20px;
}
.left_data_list {
  height: 70px;
}
.platformDataItem {
  margin: 0px 10px;
  box-sizing: border-box;
  background-size: 100% 100%;
  background-image: url('/img/manager/cheng-du/bg.png');
  display: flex;
  flex-direction: column;
}
.panel-map {
  width: 300px;
  height: 260px;
  vertical-align: top;
  /* margin-top: -100px; */
}
.panel-top-data-top {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(10, 84, 255, 0.3) 30%, rgba(0, 0, 0, 0) 70%);
}

.panel-top-data-text {
  margin-left: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}
.panel-top-data-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.panel-top-data-icon {
  margin-left: 10px;
}
.panel-top-data-num {
  margin-right: 10px;
  font-size: 28px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #00a8ff;
  line-height: 26px;
}
.left_data_map {
  margin-top: 20px;
  display: flex;
}
.panel-type-bar {
  background-image: url(/img/manager/big-data/map-count/panel-type-bar-bg.png);
  width: 600px;
  line-height: 30px;
  height: 45px;
  background-size: 100% 100%;
  padding: 5px;
  position: relative;
  z-index: 2;
}
.panel-type-bar-icon {
  background-image: url(/img/manager/big-data/map-count/panel-type-bar-icon.png);
  width: 35px;
  height: 35px;
  background-size: cover;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.panel-type-bar-menu {
  width: 100px;
  margin: 0 10px;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  color: #39ffff;
  background-position: center;
  cursor: pointer;
  background-size: 100% 100%;
}
.panel-type-bar-menu-selected {
  font-weight: bold;
  color: #ffa100;
  background-image: url(/img/manager/big-data/map-count/panel-type-bar-menu-icon.png);
}
.panel-type-bar-menu:hover {
  background-image: url(/img/manager/big-data/map-count/panel-type-bar-menu-icon.png);
}
.panel-l-type {
  margin-left: 10px;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}
.panel-l {
  width: 420px;
  display: inline-block;
  background-image: url(/img/manager/big-data/map-count/panel-l-bg.png);
  background-repeat: no-repeat;
  color: #fff;
  line-height: 46px;
  padding: 10px;
}

.panel-l-title {
  height: 36px;
  font-weight: bold;
  font-size: 14px;
  background-color: rgba(8, 47, 94, 0.5);
}
.panel-l-list {
  height: 150px;
  overflow-y: auto;
}
.panel-l-list-item {
  height: 36px;
}
.panel-l-list * {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.useInfo {
  width: 100%;
  height: 200px;
  margin-top: 20px;
}
.panel-bottom-l-title {
  background-size: 100% 100%;
  text-align: center;
  background-image: url(/img/manager/big-data/data-count/bottom-l-title-bg.png);
  color: #39ffff;
  height: 30px;
  line-height: 28px;
  font-size: 14px;
  font-weight: bold;
}
.panel-bottom-l-text-num {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
.panel-bottom-l-text-title {
  font-size: 14px;
  font-weight: bold;
  color: #39ffff;
}
.panel-bottom-l-col-bg {
  text-align: center;
  background-image: url(/img/manager/big-data/data-count/bottom-l-col-bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 80px;
  /* padding: 20px 0; */
}
.panel-bottom-c {
  background-image: url(/img/manager/big-data/data-count/bottom-c-bg.png);
  background-size: 100% 100%;
  /* padding: 15px; */
  height: 265px;
}
.panel-bottom-c-title {
  color: #39ffff;
  font-size: 16px;
  font-weight: bold;
  background-image: url(/img/manager/big-data/data-count/bottom-c-title-bg.png);
  background-position: bottom;
  height: 32px;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.left_data_bottom {
  display: flex;
  margin-top: 20px;
}
.content_right {
  width: 900px;
  height: 960px;
  display: flex;
  padding-top: 20px;
}
.content_main_wrap {
  width: 420px;
  height: 100%;
  background: rgba(130, 130, 130, 0);
  background-image: url(/img/manager/cheng-du/center-bg.png);
  background-size: 100% 100%;
  margin-left: 20px;
}
.main_right {
  width: 420px;
  height: 100%;
  background: rgba(130, 130, 130, 0);
  background-image: url(/img/manager/cheng-du/center-bg.png);
  background-size: 100% 100%;
  margin-left: 20px;
}
.content_title {
  height: 40px;
  width: 100%;
  background: linear-gradient(92deg, rgba(8, 22, 80, 0.8), rgba(3, 67, 176, 0.8));
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title_left {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 0);
  background: linear-gradient(-90deg, #f7cb6b 0%, #ff7733 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 10px;
}

.title_btn {
  width: 60px;
  height: 26px;
  background: linear-gradient(92deg, #0072ff, #00eaff, #01aaff);
  border: 0px;
  border-radius: 0px;
  margin-right: 10px;
}

.count_data {
  width: 379px;
  height: 92px;
  background: linear-gradient(
    to left,
    rgba(52, 143, 255, 0.2) rgba(93, 242, 255, 0.2 50%),
    rgba(0, 0, 0, 0)
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
}
.top_text {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 30px;
  text-align: left;
}
.bottom_text {
  font-size: 30px;
  font-weight: bold;
  color: #5df0ff;
  line-height: 30px;
}
.warp_right {
  width: 50px;
  height: 50px;
  width: 52px;
  height: 52px;
  background: rgba(130, 130, 130, 0);
  border: 1px solid #1f78d4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.title_btn_active {
  width: 60px;
  height: 26px;
  background: linear-gradient(92deg, #0072ff, #00eaff, #01aaff);
  border: 0px;
  border-radius: 0px;
  margin-right: 10px;
  color: #0e4aba;
  font-weight: bold;
}
.title_btn {
  width: 60px;
  height: 26px;
  background: rgba(130, 130, 130, 0);
  border: 1px solid #1f78d4;
  border-radius: 0px;
  margin-right: 10px;
  font-weight: 400;
  color: #fff;
}
.carousel {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.carousel-point {
  display: flex;
}
.pointSolid {
  width: 10px;
  height: 10px;
  background: #05ffff;
  border-radius: 50%;
  margin-left: 10px;
}
.pointHollow {
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0);
  border: 2px solid #01eaeb;
  border-radius: 50%;
  margin-left: 10px;
}
.carousel-text {
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  color: #03fffd;
  line-height: 30px;
}
.list_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;
}
.item-top {
  width: 80px;
  height: 34px;
  background: #2d78e8;
  box-shadow: 0px 3px 7px 0px rgba(37, 205, 223, 0.25);
  border-radius: 17px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
}
.item-bottom {
  font-size: 24px;
  font-weight: bold;
  color: #5defff;
  line-height: 36px;
  text-align: center;
}
.ivu-btn[disabled] {
  color: #c5c8ce;
  background-color: #f7f7f7;
}
</style>
