<template>
  <div style="padding: 20px">
    <div style="font-size: 16px;text-align: left;margin: 10px 0 0 20px;display: inline-block;">
      <DatePicker :value="timeData" v-model="timeData" :transfer="transfer" type="daterange" placement="bottom-end"
                  placeholder="选择时间" style="width: 200px" @on-change="totalTime"></DatePicker>
    </div>
    <Tabs :value="currentType" @on-click="switchClick">
      <TabPane label="村（社区）分布" name="1"></TabPane>
      <TabPane label="法律顾问分布" name="2"></TabPane>
      <TabPane label="区县用户排名" name="9"></TabPane>
      <TabPane label="街道（镇）用户排名" name="3"></TabPane>
      <TabPane label="村（社区）用户数排名前20" name="4"></TabPane>
      <TabPane label="电子档案街道（镇）上传排名" name="5"></TabPane>
      <TabPane label="电子档案街道（镇）审核排名" name="6"></TabPane>
<!--      <TabPane label="电子档案村（社区）上传排名" name="10"></TabPane>-->
      <TabPane label="村居治理类型分布" name="7"></TabPane>
      <TabPane label="总服务数据" name="8"></TabPane>
    </Tabs>
    <div class="publicWelfare">
      <div  v-show="currentType == '1'">
        <div style="margin: 0 0 10px 30px">
          平台已配备村居法律顾问 <span style="color:red">{{statisticsData[0].value}}</span>入，覆盖
          <span style="color:red">{{statisticsData[1].value}}</span>个村（社区）；
          已累积用户<span style="color:red"> {{statisticsData[2].value}}</span>入，
          <span style="color:red">{{userOrderData.UserCount}}</span>名用户发起咨询 <span style="color:red">{{statisticsData[3].value}}</span>次，解答咨询
          <span style="color:red">{{statisticsData[4].value.reduce((x, y) => x + y)}}</span>次，覆盖<span style="color:red">{{userOrderData.VillagesCount}}</span>村 (社区)
        </div>
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th v-if="information.Hierarchy == 1">市州</th>
            <th v-if="information.Hierarchy == 2">区县</th>
            <th v-if="information.Hierarchy == 3">街道（镇）</th>
            <th v-if="information.Hierarchy == 4">村（社区）</th>
            <th @click="clickSort(currentType)" style="cursor: pointer;">
              村社区数
              <Icon v-if="streetCommunityData.sort == 1" type="md-arrow-dropup" size="22" style="vertical-align: top;margin-top: -2px;"/>
              <Icon v-if="streetCommunityData.sort == 2" type="md-arrow-dropdown" size="22" style="vertical-align: top;margin-top: -2px;"/>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in streetCommunityData.List">
            <td>{{index+1}}</td>
            <td>{{value.AdminName}}</td>
            <td>{{value.count}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-show="currentType == '2'">
        <div style="margin: 0 0 10px 30px">
          已绑定顾问：<span style="color:red">{{streetLawyerData.Binding}}</span>人
          <span style="color:#e5e5e5;margin:0 7px;">|</span>
          未绑定顾问：<span style="color:red">{{streetLawyerData.UnBinding}}</span>人
        </div>
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th v-if="information.Hierarchy == 1">市州</th>
            <th v-if="information.Hierarchy == 2">区县</th>
            <th v-if="information.Hierarchy == 3">街道（镇）</th>
            <th v-if="information.Hierarchy == 4">村（社区）</th>
            <th @click="clickSort(currentType)" style="cursor: pointer;">
              顾问数
              <Icon v-if="streetLawyerData.sort == 1" type="md-arrow-dropup" size="22" style="vertical-align: top;margin-top: -2px;"/>
              <Icon v-if="streetLawyerData.sort == 2" type="md-arrow-dropdown" size="22" style="vertical-align: top;margin-top: -2px;"/>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in streetLawyerData.List">
            <td>{{index+1}}</td>
            <td>{{value.AdminName}}</td>
            <td>{{value.count}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-show="currentType == '3'">
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th v-if="information.Hierarchy == 1">市州</th>
            <th v-if="information.Hierarchy == 2">区县</th>
            <th v-if="information.Hierarchy == 3">街道（镇）</th>
            <th v-if="information.Hierarchy == 4">村（社区）</th>
            <th @click="clickSort(currentType)" style="cursor: pointer;">用户数
              <Icon v-if="streetSserData.sort == 1" type="md-arrow-dropup" size="22" style="vertical-align: top;margin-top: -2px;"/>
              <Icon v-if="streetSserData.sort == 2" type="md-arrow-dropdown" size="22" style="vertical-align: top;margin-top: -2px;"/>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in streetSserData.List">
            <td>{{index+1}}</td>
            <td>{{value.AdminName}}</td>
            <td>{{value.UserCount}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-show="currentType == '4'">
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th v-if="information.Hierarchy == 1">市州</th>
            <th v-if="information.Hierarchy == 2">区县</th>
            <th v-if="information.Hierarchy == 3">街道（镇）</th>
            <th v-if="information.Hierarchy == 4">村（社区）</th>
            <th >用户数
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in communityUserData.List">
            <td>{{index+1}}</td>
            <td>{{value.CityName}}{{value.AdminName}}{{value.VillagesName}}</td>
            <td>{{value.UserCount}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-show="currentType == '5'">
        <div style="margin: 0 0 10px 30px">
          已经有<span style="color:red">{{ LawyerCount }}</span>名村居法律顾问累计完成电子档案<span style="color:red">{{ FileCount }}</span>个
        </div>
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th v-if="information.Hierarchy == 1">市州</th>
            <th v-if="information.Hierarchy == 2">区县</th>
            <th v-if="information.Hierarchy == 3">街道（镇）</th>
            <th v-if="information.Hierarchy == 4">村（社区）</th>
            <th @click="clickSort(currentType)" style="cursor: pointer;">上传数
              <Icon v-if="archivesData.sort == 1" type="md-arrow-dropup" size="22" style="vertical-align: top;margin-top: -2px;"/>
              <Icon v-if="archivesData.sort == 2" type="md-arrow-dropdown" size="22" style="vertical-align: top;margin-top: -2px;"/>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in archivesData.List">
            <td>{{index+1}}</td>
            <td>{{value.CityName}}{{value.AdminName}}{{value.VillagesName}}</td>
            <td>{{value.FileCount}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-show="currentType == '6'">
        <Select v-model="archivesIsCheckData.model" style="width:200px" @on-change="stateChoice" placeholder="请选择审核状态">
          <Option v-for="item in stateCityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      <table class="formtable">
        <thead>
        <tr>
          <th>序号</th>
          <th v-if="information.Hierarchy == 1">市州</th>
          <th v-if="information.Hierarchy == 2">区县</th>
          <th v-if="information.Hierarchy == 3">街道（镇）</th>
          <th v-if="information.Hierarchy == 4">村（社区）</th>
          <th @click="clickSort(currentType)" style="cursor: pointer;">上传数
            <Icon v-if="archivesIsCheckData.sort == 1" type="md-arrow-dropup" size="22" style="vertical-align: top;margin-top: -2px;"/>
            <Icon v-if="archivesIsCheckData.sort == 2" type="md-arrow-dropdown" size="22" style="vertical-align: top;margin-top: -2px;"/>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value,index) in archivesIsCheckData.List">
          <td>{{index+1}}</td>
          <td>{{value.CityName}}{{value.AdminName}}{{value.VillagesName}}</td>
          <td>{{value.FileCount}}</td>
        </tr>
        </tbody>
      </table>
      </div>
      <div v-show="currentType == '7'">
        <div style="margin: 0 0 10px 30px">
          共上传<span style="color:red">{{ governmentData.Total }}</span>条
        </div>
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>类型</th>
            <th @click="clickSort(currentType)" style="cursor: pointer;">数量
              <Icon v-if="archivesIsCheckData.sort == 1" type="md-arrow-dropup" size="22" style="vertical-align: top;margin-top: -2px;"/>
              <Icon v-if="archivesIsCheckData.sort == 2" type="md-arrow-dropdown" size="22" style="vertical-align: top;margin-top: -2px;"/>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in governmentData.List">
            <td>{{index+1}}</td>
            <td>{{value.OrderTypeTitle}}</td>
            <td>{{value.count}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-show="currentType == '8'">
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>法律顾问数据类别</th>
            <th>服务次数</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in orderTypeCountData">
            <td>{{index+1}}</td>
            <td>{{value.OrderTypeTitle}}</td>
            <td>{{value.count}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-show="currentType == '9'">
        <table class="formtable">
          <thead>
          <tr>
            <th>序号</th>
            <th>单位名称</th>
            <th>咨询次数</th>
            <th>用户人数</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in difficultData.List">
            <td>{{index+1}}</td>
            <td>{{value.AdminName}}</td>
            <td>{{value.Orders_count}}</td>
            <td>{{value.users_count}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dataReport",
  data(){
    return{
      timeData: '',
      transfer: true,
      currentType : '1',
      userOrderData : [],
      information : [],
      stateCityList : [
        {
          value: '0',
          label: '未审核'
        },
        {
          value: '1',
          label: '审核通过'
        },
        {
          value: '2',
          label: '已驳回'
        },
      ],
      orderTypeCountData: [
        {OrderTypeId: 1, OrderTypeTitle: "法律咨询-留言咨询", count: '',},
        {OrderTypeId: 2, OrderTypeTitle: "法律咨询-自助咨询", count: '',},
        {OrderTypeId: 3, OrderTypeTitle: "法律咨询-电话咨询", count: '',},
        {OrderTypeId: 4, OrderTypeTitle: "法律咨询-视频咨询", count: '',},
        {OrderTypeId: 5, OrderTypeTitle: "平台申请-法律援助", count: '',},
        {OrderTypeId: 6, OrderTypeTitle: "平台申请-人民调解", count: '',},
        {OrderTypeId: 7, OrderTypeTitle: "查询服务-模拟判决", count: '',},
        {OrderTypeId: 8, OrderTypeTitle: "查询服务-法律文书", count: '',},
        {OrderTypeId: 9, OrderTypeTitle: "查询服务-民法典", count: '',},
      ],
      streetCommunityData : {
        page : 1,
        List : [],
        pageSize : 999,
        sort : 1,
      },
      streetLawyerData : {
        page : 1,
        List : [],
        pageSize : 999,
        sort : 1,
        Binding : 0,
        UnBinding : 0,
      },
      streetSserData : {
        page : 1,
        List : [],
        pageSize : 20,
        sort : 1,
        VillagesCount : 0,
      },
      communityUserData : {
        page : 1,
        List : [],
        pageSize : 20,
        sort : 1,
        VillagesCount : 0,
      },
      archivesData : {
        page : 1,
        List : [],
        pageSize : 20,
        sort : 1,
      },
      archivesIsCheckData : {
        List : [],
        sort : 1,
        IsCheck : 0,
        model: '0',
      },
      governmentData : {
        List : [],
        sort : 1,
        Total : 0,
      },
      statisticsData :[
        {value:''},
        {value:''},
        {value:''},
        {value:''},
        {value:[]},
      ],
      difficultData : {
        List : [],
        count : '',
      },
      LawyerCount : '',
      FileCount : '',
      startTime : '',
      endTime : '',
    }
  },
  methods:{
    switchClick : function (e){
        this.currentType = e
        if(e == 5){
          this.pl_AdminFileSort()
        }
        if(e == 6){
          this.pl_AdminIsCheckFile()
        }
        if(e == 7){
          this.pl_ServiceCount()
        }
        if(e == 8){
          this.serviceTimes()
        }
        if(e == 9){
          this.difficultCount()
        }
    },
    pl_AdminVillageSort : function (){
      var _this = this
      _this.send({
        url: '/pl_AdminVillageSort',
        data: {
          AdminId: window.sessionStorage.AdminId,
          page: _this.streetCommunityData.page,
          pageSize: _this.streetCommunityData.pageSize,
          sort: _this.streetCommunityData.sort,
          start_time :_this.startTime,
          end_time :_this.endTime,
        },
        success: function (res) {
          _this.streetCommunityData.List = res
        }
      })
    },
    pl_AdminLawyerSort : function (){
      var _this = this
      _this.send({
        url: '/pl_AdminLawyerSort',
        data: {
          AdminId: window.sessionStorage.AdminId,
          page: _this.streetLawyerData.page,
          pageSize: _this.streetLawyerData.pageSize,
          sort: _this.streetLawyerData.sort,
          start_time :_this.startTime,
          end_time :_this.endTime,
        },
        success: function (res) {
          _this.streetLawyerData.List = res.List
          _this.streetLawyerData.Binding = res.Binding
          _this.streetLawyerData.UnBinding = res.UnBinding
        }
      })
    },
    pl_AdminUserSort : function (){
      var _this = this
      _this.send({
        url: '/pl_AdminUserSort',
        data: {
          AdminId: window.sessionStorage.AdminId,
          page: _this.streetSserData.page,
          pageSize: _this.streetSserData.pageSize,
          sort: _this.streetSserData.sort,
          start_time :_this.startTime,
          end_time :_this.endTime,
        },
        success: function (res) {
          _this.streetSserData.List = res.Data
          _this.streetSserData.VillagesCount = res.VillagesCount
        }
      })
    },
    pl_VillageUserSort : function (){
      var _this = this
      _this.send({
        url: '/pl_VillageUserSort',
        data: {
          AdminId: window.sessionStorage.AdminId,
          page: _this.communityUserData.page,
          pageSize: _this.communityUserData.pageSize,
          sort: _this.communityUserData.sort,
          start_time :_this.startTime,
          end_time :_this.endTime,
        },
        success: function (res) {
          _this.communityUserData.List = res
        }
      })
    },
    pl_AdminFileSort : function (){
      var _this = this
      _this.send({
        url: '/pl_AdminFileSort',
        data: {
          AdminId: window.sessionStorage.AdminId,
          page: _this.archivesData.page,
          pageSize: _this.archivesData.pageSize,
          sort: _this.archivesData.sort,
          start_time :_this.startTime,
          end_time :_this.endTime,
        },
        success: function (res) {
          _this.archivesData.List = res
        }
      })
    },
    pl_AdminIsCheckFile : function (){
      var _this = this
      _this.send({
        url: '/pl_AdminIsCheckFile',
        data: {
          AdminId: window.sessionStorage.AdminId,
          sort: _this.archivesIsCheckData.sort,
          IsCheck: _this.archivesIsCheckData.IsCheck,
          start_time :_this.startTime,
          end_time :_this.endTime,
        },
        success: function (res) {
          _this.archivesIsCheckData.List = res.List
        }
      })
    },
    pl_ServiceCount : function (){
      var _this = this
      _this.send({
        url: '/pl_ServiceCount',
        data: {
          AdminId: window.sessionStorage.AdminId,
          sort: _this.governmentData.sort,
          IsCheck: _this.governmentData.IsCheck,
          start_time :_this.startTime,
          end_time :_this.endTime,
        },
        success: function (res) {
          _this.governmentData.List = res.List
          _this.governmentData.Total = res.Total
        }
      })
    },
    pl_UserOrder : function (){
      var _this = this
      _this.send({
        url: '/pl_UserOrder',
        data: {
          AdminId: window.sessionStorage.AdminId,
          start_time :_this.startTime,
          end_time :_this.endTime,
        },
        success: function (res) {
          _this.userOrderData = res
        }
      })
    },

    stateChoice :function (e){
      this.archivesIsCheckData.IsCheck = e
      this.pl_AdminIsCheckFile()
    },
    serviceTimes : function (){
      var _this = this
      _this.send({
        url: '/UserOrderCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          _this.orderTypeCountData[0].count = res.count
        }
      })
      _this.send({
        url: '/zizhuUserCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          _this.orderTypeCountData[1].count = res.count
        }
      })
      _this.send({
        url: '/UserCallCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime,type:1,},
        success: function (res) {
          _this.orderTypeCountData[2].count = res.Count
        }
      })
      _this.send({
        url: '/UserVideoCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          _this.orderTypeCountData[3].count = res.count
        }
      })
      _this.send({
        url: '/UserLegalCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          _this.orderTypeCountData[4].count = res.count
        }
      })
      _this.send({
        url: '/UserMediateCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          _this.orderTypeCountData[5].count = res.count
        }
      })
      _this.send({
        url: '/UserDecisionCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          _this.orderTypeCountData[6].count = res.count
        }
      })
      _this.send({
        url: '/UserWritCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          _this.orderTypeCountData[7].count = res.count
        }
      })
      _this.send({
        url: '/MingCodeUserCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          _this.orderTypeCountData[8].count = res.count
        }
      })
    },
    clickSort : function (e){
      if(e == 1){
        if(this.streetCommunityData.sort == 1){this.streetCommunityData.sort = 2}
        else{this.streetCommunityData.sort = 1}
        //街道社区数量
        this.pl_AdminVillageSort()
      }
      if(e == 2){
        if(this.streetLawyerData.sort == 1){this.streetLawyerData.sort = 2}
        else{this.streetLawyerData.sort = 1}
        // 街道律师
        this.pl_AdminLawyerSort()
      }
      if(e == 3){
        if(this.streetSserData.sort == 1){this.streetSserData.sort = 2}
        else{this.streetSserData.sort = 1}
        // 街道用户数量
        this.pl_AdminUserSort()
      }
      if(e == 4){
        if(this.communityUserData.sort == 1){this.communityUserData.sort = 2}
        else{this.communityUserData.sort = 1}
        // 村社区用户数量
        this.pl_VillageUserSort()
      }
      if(e == 5){
        if(this.archivesData.sort == 1){this.archivesData.sort = 2}
        else{this.archivesData.sort = 1}
        // 村社区用户数量
        this.pl_AdminFileSort()
      }
      if(e == 6){
        if(this.archivesIsCheckData.sort == 1){this.archivesIsCheckData.sort = 2}
        else{this.archivesIsCheckData.sort = 1}
        // 村社区用户数量
        this.pl_AdminIsCheckFile()
      }
      if(e == 7){
        if(this.governmentData.sort == 1){this.governmentData.sort = 2}
        else{this.governmentData.sort = 1}
        // 村社区用户数量
        this.pl_ServiceCount()
      }
    },
    switchData : function (){
      var _this = this
      _this.send({
        url: '/OrganizationCountLawyer',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          //顾问
          _this.statisticsData[0].value = res.count
        }
      })
      _this.send({
        url: '/OrganizationCountVillage',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: 4,
          start_time :_this.startTime,
          end_time :_this.endTime,
        },
        success: function (res) {
          //社区
          _this.statisticsData[1].value = res.count
        }
      })
      _this.send({
        url: '/OrganizationCountUser',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          //用户
          _this.statisticsData[2].value = res.count
        }
      })
      _this.send({
        url: '/OrganizationCountOrder',
        data: {
          AdminId: window.sessionStorage.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime
        },
        success: function (res) {
          //咨询次数
          _this.statisticsData[3].value = res.count
        }
      })
      var statisticsData = _this.statisticsData[4].value
      _this.send({
        url: '/UserOrderCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          statisticsData.push(res.count)
        }
      })
      _this.send({
        url: '/zizhuUserCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          statisticsData.push(res.count)
        }
      })
      _this.send({
        url: '/UserVideoCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          statisticsData.push(res.count)
        }
      })
      _this.send({
        url: '/UserDecisionCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          statisticsData.push(res.count)
        }
      })
      _this.send({
        url: '/UserWritCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime},
        success: function (res) {
          statisticsData.push(res.count)
        }
      })
      _this.send({
        url: '/UserCallCount',
        data: {AdminId: _this.information.AdminId,
          typeId: _this.typeId,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime,type:1,},
        success: function (res) {
          statisticsData.push(res.Count)
        }
      })
      _this.statisticsData[4].value = statisticsData
      console.log()
    },
    difficultCount : function(){
      var _this = this
      if(_this.information.AdminId == 0){
        var AdminId = 80
      }else {
        var AdminId = _this.information.AdminId
      }
      _this.send({
        url: '/DifficultCount',
        data: {
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime,
          AdminId: 166
        },
        success: function (res) {
          _this.difficultData.List = res.list
          _this.difficultData.count = res.count
        }
      })
    },
    pl_LawyerFile : function (){
      var _this = this
      _this.send({
        url: '/pl_LawyerFile',
        data: {
          AdminId: _this.information.AdminId,
          start_time :_this.startTime,
          end_time :_this.endTime,
        },
        success: function (res) {
          _this.LawyerCount = res.LawyerCount
          _this.FileCount = res.FileCount
        }
      })
    },

    totalTime: function (e) {
      var _this = this
      _this.statisticsData[4].value = []
      _this.startTime = e[0],//开始时间
          _this.endTime = e[1]//结束时间
      _this.page = 1
      //街道社区数量
      this.pl_AdminVillageSort()
      // 街道律师
      this.pl_AdminLawyerSort()
      // 街道用户数量
      this.pl_AdminUserSort()
      // 村社区用户数量
      this.pl_VillageUserSort()
      // 用户发起咨询、覆盖社区数
      this.pl_UserOrder()
      //用户、顾问、社区数量
      this.switchData()
      //
      this.pl_LawyerFile()
    },
  },
  mounted() {
    var _this = this
    this.information = window.sessionStorage
    //街道社区数量
    this.pl_AdminVillageSort()
    // 街道律师
    this.pl_AdminLawyerSort()
    // 街道用户数量
    this.pl_AdminUserSort()
    // 村社区用户数量
    this.pl_VillageUserSort()
    // 用户发起咨询、覆盖社区数
    this.pl_UserOrder()
    //用户、顾问、社区数量
    this.switchData()
    //
    this.pl_LawyerFile()
    this.serviceTimes()
  }
}
</script>

<style scoped>
.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
}
.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
  text-align: center;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
}
</style>