<!-- 数据统计 -->
<style scoped>
.panel{background-image:url(/img/manager/big-data/data-count/bg.png);background-size:100% 100%;padding:30px;}
.panel-top-data{background-image: url(/img/manager/big-data/data-count/top-data-bg.png);height:207px;background-size:100% 100%;display:flex;padding:0px 35px;align-items:center;text-align:center;justify-content:space-evenly;}
.panel-top-data-icon{width:68px;height:68px;background-size:100% 100%;}
.panel-top-data-text-title{font-size:18px;color:#fff;font-weight:bold;}
.panel-top-data-text-num{font-size:30px;color:#f3a90a;font-weight:bold;}
.panel-bottom-l-title{text-align: center;background-image:url(/img/manager/big-data/data-count/bottom-l-title-bg.png);color:#39ffff;height:48px;line-height:48px;font-size:16px;font-weight:bold;}
.panel-bottom-l-text-num{font-size:22px;font-weight:bold;color:#fff;}
.panel-bottom-l-text-title{font-size:14px;font-weight:bold;color:#39ffff;}
.panel-bottom-l-col-bg{background-image:url(/img/manager/big-data/data-count/bottom-l-col-bg.png);background-repeat:no-repeat;background-position:center;height:94px;padding:5px 0;}
.panel-bottom-c{background-image:url(/img/manager/big-data/data-count/bottom-c-bg.png);background-size:100% 100%;padding:15px;}
.panel-bottom-c-title{color:#39ffff;font-size:16px;font-weight:bold;background-image:url(/img/manager/big-data/data-count/bottom-c-title-bg.png);background-position:bottom;height:32px;background-repeat:no-repeat;background-size:100% auto;}
</style>

<template>
  <div>
    <div class="child-title"></div>
    <div class="panel">
      <Row class="panel-top-data">
        <Col :span="24/platformData.length" v-for="(item,index) in platformData">
          <div @click="jumpEvent(item)" style="cursor: pointer;">
            <div class="panel-top-data-icon inline-block align-middle" :style="'background-image:url(/img/manager/big-data/data-count/'+(item.picture)+');'"></div>
            <span style="padding:7.5px;"></span>
            <div class="inline-block align-middle text-left">
              <div class="panel-top-data-text-title">{{item.name}}</div>
              <div class="panel-top-data-text-num">{{item.value}}<span style="font-size: 16px;margin-left: 5px">{{item.unit}}</span></div>
            </div>
          </div>
        </Col>
      </Row>
      <div style="padding:25px"></div>
      <div class="text-center">
        <div class="inline-block" style="width:312px;">
          <div class="panel-bottom-l-title">村居治理服务数据</div>
          <div style="padding:10px;"></div>
          <Row>
<!--            <Col span="8" class="panel-bottom-l-col-bg" v-for="(item,index) in archivesData">-->
<!--              <div class="panel-bottom-l-text-num">{{item.value}}<span style="font-size: 14px;margin-left: 5px">{{item.Unit}}</span></div>-->
<!--              <div class="panel-bottom-l-text-title">{{item.name}}</div>-->
<!--            </Col>-->
            <Col span="24" class="panel-bottom-l-col-bg">
              <div @click="jumpEvent({type:'page',url:'MAL'})" style="cursor: pointer;">
                <div class="panel-bottom-l-text-num">{{getfileData.Count}}<span style="font-size: 14px;margin-left: 5px">份</span></div>
                <div class="panel-bottom-l-text-title">电子档案</div>
              </div>
            </Col>
            <Col span="24" class="panel-bottom-l-col-bg" >
              <div @click="jumpEvent({type:'page',url:'punchIn'})" style="cursor: pointer;">
                <div class="panel-bottom-l-text-num">{{getClockDayData.total}}<span style="font-size: 14px;margin-left: 5px">次</span></div>
                <div class="panel-bottom-l-text-title">值班打卡</div>
              </div>
            </Col>
            <Col span="24" class="panel-bottom-l-col-bg" >
              <div @click="jumpEvent({type:'page',url:'MAL'})" style="cursor: pointer;">
                <div class="panel-bottom-l-text-num">{{parseInt(serviceDuration/60)}}<span style="font-size: 14px;margin-left: 5px">小时</span></div>
                <div class="panel-bottom-l-text-title">服务时长</div>
              </div>
            </Col>
          </Row>
        </div>
        <span style="padding:15px;"></span>
        <div class="inline-block align-top panel-bottom-c text-left" style="width:465px;">
          <div class="panel-bottom-c-title">
            <span class="inline-block align-middle" style="background-image:url(/img/manager/big-data/data-count/bottom-c-title-icon.png);width:46px;height:47px;margin:-10px -10px 0 0;"></span>
            7日服务数据
          </div>
          <div style="padding:10px;"></div>
          <div style="width:429px;height:278px" ref="echarts-serveDate" class="inline-block"></div>
        </div>
        <span style="padding:15px;"></span>
        <div class="inline-block align-top panel-bottom-c text-left" style="width:465px;">
          <div class="panel-bottom-c-title">
            <span class="inline-block align-middle" style="background-image:url(/img/manager/big-data/data-count/bottom-c-title-icon.png);width:46px;height:47px;margin:-10px -10px 0 0;"></span>
            服务类别排名
          </div>
          <div style="padding:10px;"></div>
          <div style="width:403px;height:278px" ref="echarts-serveRanking" class="inline-block"></div>
        </div>
      </div>
    </div>
    <serviceFrequency v-if="serveShow" :modal3="serveShow" @message-updated="handleMessageUpdated"/>
  </div>

</template>

<script>
import * as echarts from 'echarts'
import serviceFrequency from '../.././../../components/alert/serviceFrequency'
export default {
  name:'data-count',
  components: {serviceFrequency,},
  props:{
    adminId:{
      required:true,
    }
  },
  data() {
    return {
      serveShow: false,
      platformData:[
        {name:'区县数',value:'000000',unit:'个',picture:'icon-4.png'},
        {name:'用户数',value:'000000',unit:'人',type:'page',url:'userAdministration',picture:'icon-1.png'},
        {name:'服务次数',value:'000000',unit:'次',type:'alert',picture:'icon-2.png'},
        {name:'服务村(社区)数',value:'000000',unit:'个',type:'page',url:'community',picture:'icon-4.png'},
        {name:'村居顾问总数',value:'000000',unit:'人',type:'page',url:'lawyerManagement',picture:'icon-5.png'},
      ],
      archivesData:[
        {name:'法治宣讲',value:'0000',},//法制宣传
        {name:'参与调解',value:'0000',},//
        {name:'法律服务',value:'0000',},
        {name:'依法治理',value:'0000',},
        {name:'法律咨询',value:'0000',},
        {name:'值班日志',value:'0000',},
      ],
      getfileData : [],
      getClockDayData : [],
      serviceDuration : '',
      echartsServeData:{//7日服务数据
        tooltip: {//提示框组件
          trigger: 'axis'//触发类型:坐标轴触发
        },
        grid:{//直角坐标系
          top:'5%',
          left:'3%',
          right:'4%',
          bottom:'3%',
          containLabel:true,//设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {//x轴
          boundaryGap:false,//坐标轴两边留白策略
          type: 'category',//类目轴
          data: ['加载中', '加载中', '加载中', '加载中', '加载中', '加载中', '加载中'],
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#ffffff",
            }
          },
        },
        yAxis: {//y轴
          type: 'value',//数值轴
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#ffffff",
            },
          },
        },
        series: [{//折线/面积图
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: 'line',//折线图
          smooth: true,//是上色折现覆盖区域
          areaStyle: {},
          lineStyle: {//折线样式
            width:4,
          },
          color:{//色彩渐变
            type: 'linear',//线性渐变
            x:0,
            y:1,
            x2:1,
            y2:0,
            colorStops: [
              {
                offset:0,
                color: '#008aff' //蓝色
              },
              {
                offset:1,
                color: '#f950ff' //紫色
              },
            ],
          },
        }],
      },
      echartsServeRanking:{//服务类别排名
        tooltip: {//提示框组件
          trigger: 'axis',//触发类型:坐标轴触发
          axisPointer: {
            type: 'shadow',
          },
        },
        grid:{//直角坐标系
          top:'5%',
          left:'3%',
          right:'4%',
          bottom:'3%',
          containLabel:true,//设置直角坐标系区域包含坐标轴的刻度标签
        },
        xAxis: {//x轴
          type: 'value',//数值轴
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#ffffff",
            }
          },
        },
        yAxis: {//y轴
          type: 'category',//类目轴
          data: ['加载中', '加载中', '加载中', '加载中', '加载中', '加载中', '加载中'],
          axisLine: {//轴线相关设置
            lineStyle: {
              color: "#ffffff",
            },
          },
        },
        series: [//折线/面积图
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'bar',//柱状图
            barWidth : 10,//柱宽度
            color:{//柱子颜色渐变
              type: 'linear',//线性渐变
              x:0,
              y:1,
              x2:1,
              y2:0,
              colorStops: [
                {
                  offset:0,
                  color: '#1d3ec3' //蓝色
                },
                {
                  offset:1,
                  color: '#47cd93' //绿色
                },
              ],
            },
            itemStyle:{//柱子的样式
              barBorderRadius:[5,5,5,5],
            },
          }
        ],
      },
    };
  },
  mounted() {
    echarts.init(this.$refs['echarts-serveDate']).setOption(this.echartsServeData)
    echarts.init(this.$refs['echarts-serveRanking']).setOption(this.echartsServeRanking)
    this.getServeData()
    this.getServeRanking()
    this.getPlatformData()
    // this.getArchivesData()
    this.getfileDay()
  },
  methods: {
    handleMessageUpdated(message) {
      this.serveShow = message;
    },
    // 跳转
    jumpEvent: function (item){
      console.log(item)
      if(item.type == 'page'){
        let routeData = this.$router.resolve({name: item.url,});
        window.open(routeData.href, '_blank');
      }
      if(item.type == 'alert'){
        this.serveShow = true
      }
    },
    // 7日服务数据
    getServeData:function(){
      let _this = this
      _this.send({
        url: '/GetTenData',
        data: {
          AdminId: _this.adminId,
        },
        success: function (res) {
          _this.echartsServeData.series[0].data = []
          _this.echartsServeData.xAxis.data = []
          for(let i = 0;i<res.list.day.length;i++){//取前7日数据，倒序输出
            if(i > 6){
              continue
            }
            let count = 0
            for(let index in res.list.day[i]){
              if(index=='time'){continue}
              count += parseInt(res.list.day[i][index])
            }
            _this.echartsServeData.xAxis.data.push(res.list.day[i].time.substring(5,res.list.day[i].time.length))
            _this.echartsServeData.series[0].data.push(count)
          }
          echarts.init(_this.$refs['echarts-serveDate']).setOption(_this.echartsServeData)
        }
      })
    },
    // 服务类别排名
    getServeRanking:function(){
      let _this = this
      _this.send({
        url: '/OrderTypeCount',
        data: {
          AdminId: _this.adminId,
        },
        success: function (res) {
          _this.echartsServeRanking.series[0].data = []
          _this.echartsServeRanking.yAxis.data = []
          //删除有效咨询
          for(let index in res){
            if(res[index].OrderTypeId=='12'){
              res.splice(index,1)
            }
          }
          //数据排序
          res.sort((item,item1)=>{
            return item1.count-item.count
          })
          res.length = 10
          //倒序输出
          for(let i=res.length-1;i>=0;i--){
            _this.echartsServeRanking.yAxis.data.push(res[i].OrderTypeTitle)
            _this.echartsServeRanking.series[0].data.push(res[i].count)
          }
          // for(let i in res){
          //   _this.echartsServeRanking.yAxis.data.push(res[i].OrderTypeTitle)
          //   _this.echartsServeRanking.series[0].data.push(res[i].count)
          // }
          echarts.init(_this.$refs['echarts-serveRanking']).setOption(_this.echartsServeRanking)
        }
      })
    },
    // 顶部使用数据
    getPlatformData:function() {
        var _this = this
      if(window.sessionStorage.Level == 1){
        _this.platformData[0].name = '市州数'
      }
      if(window.sessionStorage.Level == 2){
        _this.platformData[0].name = '区县数'
      }
      if(window.sessionStorage.Level == 3){
        _this.platformData[0].name = '街道数'
      }
      _this.send({
        url: '/GetIndexCount',
        data: {
          AdminId: _this.adminId,
          // typeId: _this.typeId,
          // start_time: _this.startTime,//开始时间
          // end_time: _this.endTime
        },
        success: function (res) {
          _this.platformData[0].value = res.count
        }
      })
        _this.send({
          url: '/OrganizationCountUser',
          data: {
            AdminId: _this.adminId,
            // typeId: _this.typeId,
            // start_time: _this.startTime,//开始时间
            // end_time: _this.endTime
          },
          success: function (res) {
            _this.platformData[1].value = res.count
          }
        })
        _this.send({
          url: '/OrganizationCountOrder',
          data: {
            AdminId: _this.adminId,
            // typeId: _this.typeId,
            // start_time: _this.startTime,//开始时间
            // end_time: _this.endTime
          },
          success: function (res) {
            _this.platformData[2].value = res.count
          }
        })
        _this.send({
          url: '/OrganizationCountVillage',
          data: {
            AdminId: _this.adminId,
            // typeId: _this.typeId,
            // start_time: _this.startTime,//开始时间
            // end_time: _this.endTime
          },
          success: function (res) {
            _this.platformData[3].value = res.count
            // _this.platformData[3].value = res.count
          }
        })
        _this.send({
          url: '/OrganizationCountLawyer',
          data: {
            AdminId: _this.adminId,
            // typeId: _this.typeId,
            // start_time: _this.startTime,//开始时间
            // end_time: _this.endTime
          },
          success: function (res) {
            _this.platformData[4].value = res.count
            // _this.platformData[4].value = res.count
          }
        })
    },
    // 日志数据
    getArchivesData:function(){
      let _this = this
      _this.send({
        url: '/fileGroup',
        data: {
          AdminId: _this.adminId,
        },
        success: function (res) {
          _this.archivesData = []
          res.length = 6
          for(var index in res){
            _this.archivesData.push({
              name:res[index].EvaluationTitle,
              value:res[index].total,
              Unit:res[index].Unit,
            })
          }
        }
      })
    },

    getfileDay: function () {
      var _this = this
      _this.send({
        url: '/EleFileList',
        data: {
          AreaId: window.sessionStorage.AreaId,
          AdminId: _this.adminId,
        },
        success: function (res) {
          _this.getfileData = res
        }
      })

      _this.send({
        url: '/fileTotal',
        data: {
          AreaId: window.sessionStorage.AreaId,
          AdminId: _this.adminId,
        },
        success: function (res) {
          _this.serviceDuration = res
        }
      })
      _this.send({
        url: '/GetClockDay',
        data: {
          AreaId: window.sessionStorage.AreaId,
          AdminId: _this.adminId
        },
        success: function (res) {
          _this.getClockDayData = res
        }
      })
    },

  },
}
</script>
