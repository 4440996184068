<style scoped>
.touxiang{width: 1.26rem;height: 1.4rem;display: inline-block;background: url("/img/zhqy/touxiang.png");background-size: 100% 100%;margin-top: 0.2rem}
    .iconset{width: 0.44rem;height: 0.43rem;margin-top: 0.10rem;vertical-align: top;}
    .inputFrame{width: 6.9rem;height: 1rem;border-bottom: 0.01rem solid #DDD;
        font-size: 0.28rem;font-family: PingFang SC;
        font-weight: 500;line-height: 1rem;position: relative;display: inline-block;text-align: left}
    .textareaFrame{width: 6.9rem;height: 3rem;border-bottom: 0.01rem solid #DDD;
        font-size: 0.28rem;font-family: PingFang SC;
        font-weight: 500;position: relative;display: inline-block;text-align: left;margin-top: 0.01rem;}
    .clickUpload{width: 1.46rem;
        height: 0.54rem;
        background: #1186E1;
        border-radius: 0.27rem;
        font-size: 0.24rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFF;line-height: 0.54rem;text-align: center;float: right;margin-top: 0.22rem;}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {color: #CCC;}
input::-moz-placeholder,
textarea::-moz-placeholder {color: #CCC;}
input:-moz-placeholder,
textarea:-moz-placeholder {color: #CCC;}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {color: #CCC;}
    .addCase{width: 6.9rem;height: 0.86rem;border: 0.01rem dashed #1186E1;display: inline-block;font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #1186E1;
        line-height: 0.86rem;text-align: center;}
    .preservation{width: 7.5rem;
        height: 0.92rem;
        background: #1186E1;
        font-size: 0.3rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFF;
        line-height: 0.92rem;position: fixed;text-align: center;bottom: 1rem;}
    .shanchu{width: 0.23rem;height: 0.24rem;vertical-align: top;margin: 0.2rem 0.11rem 0 0}
.head-title{font-size: .36rem;color: #333;line-height: .88rem;display: inline-block;vertical-align: top;}
.return{width: .16rem;height: .3rem;display: inline-block;vertical-align: top;float: left;margin-top: .29rem;margin-left: .3rem;}

.inputFrame :deep(.ivu-cascader){position: absolute;color: #222;right: 0;text-align: right;width: 100%;top: 0;height: 100%}
.inputFrame :deep(.ivu-input){border:0;height: 100%;text-align: right;font-size: 0.28rem;}
.inputFrame :deep(.ivu-input-wrapper){height: 100%;}
.inputFrame :deep(.ivu-cascader-rel){height: 100%;font-size: 0.28rem}
</style>

<template>
    <div>
      <div style="background-color: #F5F5F5; height: 0.88rem; text-align: center;position: fixed;top: 0;width: 100%;right: 0;z-index: 9;">
        <img src="/img/zhqy/return.png" class="return" @click="$router.go(-1)">
        <p class="head-title">编辑信息</p>
      </div>
      <div style="padding: 0.44rem"></div>
<!--        <div style="font-size: 0.36rem;font-family: PingFang SC;font-weight: 500;color: #333333;line-height: 0.32rem;margin: 0.3rem 0 0.37rem 0.55rem">您好，请完善个人信息</div>-->
        <div style="text-align: center">
            <Upload action="https://vn-server.lvwa18.com/api/upload_file_H5" :show-upload-list="false"
                    　　:on-success="headPortrait">
                <img v-if="information.lawyerImage"  :src="information.lawyerImage" style="width: 1.6rem;height: 1.6rem;background: #FFFFFF;border: 0.02rem solid #E5E5E5;border-radius: 50%;text-align: center;display: inline-block">
                <div v-else style="width: 1.6rem;height: 1.6rem;background: #FFFFFF;border: 0.02rem solid #E5E5E5;border-radius: 50%;text-align: center;display: inline-block">
                    <div class="touxiang">
                        <div style="width: 0.68rem;height: 0.68rem;background: #0A77FA;opacity: 0.5;border-radius: 50%;vertical-align: top;margin: 0.26rem 0 0 0.29rem">
                            <img class="iconset" src="/img/zhqy/iconset.png">
                        </div>
                    </div>
                </div>
            </Upload>
        </div>
        <div style="text-align: center">
            <div style="border-top: 0.01rem solid #DDD;width: 6.9rem;display: inline-block"></div>
            <div class="inputFrame">
                <div style="color: #666;display: inline-block;">姓名</div>
                <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="填写您的名称" v-model="information.lawyerName"/>
            </div>
            <div class="inputFrame">
                <div style="color: #666;display: inline-block;">电话号码</div>
                <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="填写您的电话号码" v-model="information.lawyerPhone"/>
            </div>
            <div class="inputFrame">
                <div style="color: #666;display: inline-block;position: absolute;z-index: 99;">地址</div>
                <Cascader :data="addressData" v-model="value1" :placeholder="value2==''?'选择您的地址':''" @on-change="addressSelect">
                    <div style="line-height: 1rem;">{{value2}}</div>
                </Cascader>
            </div>
            <div class="inputFrame">
                <div style="color: #666;display: inline-block;">执业证号</div>
                <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="填写您的执业证号" v-model="information.practiceNumber"/>
            </div>
            <div class="inputFrame">
                <div style="color: #666;display: inline-block;">执业年限</div>
                <input style="position: absolute;color: #222;right: 0.84rem;text-align: right;width: 4.5rem;" placeholder="填写您的执业年限" v-model="information.lawyerYear"/>
                <div style="position: absolute;color: #222;right: 0.09rem;text-align: right;display: inline-block">年</div>
            </div>
            <div class="inputFrame">
                <div style="color: #666;display: inline-block;">律所名称</div>
                <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="填写您的律所名称" v-model="information.lawOfficeName" readonly/>
            </div>
            <div class="inputFrame" @click="modal = true">
                <div style="color: #666;display: inline-block;">办案领域</div>
                <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="请选择您的办案领域" v-model="fruitName" readonly/>
            </div>
            <!---->
            <div  v-for="(value,index) in information.lawyerCost">
                <div class="inputFrame">
                    <div style="color: #666;display: inline-block;">服务收费({{index+1}})标题</div>
                    <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="请输入您的标题" v-model="value.title"/>
                </div>
                <div class="inputFrame">
                    <div style="color: #666;display: inline-block;">服务收费({{index+1}})金额</div>
                    <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="请输入您的金额" v-model="value.money"/>
                </div>
                <div class="inputFrame">
                    <div style="color: #666;display: inline-block;">服务收费({{index+1}})说明</div>
                    <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="请输入您的说明" v-model="value.content"/>
                </div>
                <div style="height: 0.84rem;font-size: 0" @click="deleteServiceCharge(index)">
                    <img class="shanchu" src="/img/zhqy/shanchu.png">
                    <div style="display: inline-block;font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #F12727;margin-top: 0.22rem;line-height: 0.24rem">删除该服务收费</div>
                </div>
            </div>
            <div class="addCase" @click="addServiceCharge()">添加一个服务收费</div>
            <!---->
            <div class="textareaFrame">
                <div style="color: #666;display: inline-block;line-height: 1rem">个人简介</div>
                <textarea style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;height: calc(100% - 0.37rem);resize: none;margin-top: 0.37rem;" placeholder="填写您个人简介，字数不超过300字" v-model="information.lawyerIntro">

                </textarea>
            </div>
            <div class="inputFrame" style="height:auto;">
                <div style="color: #666;display: inline-block;">上传图片</div>
                <Upload action="https://vn-server.lvwa18.com/api/upload_file_H5" :show-upload-list="false" class="clickUpload"
                        　　:on-success="lawyerPicture">
                    <div >点击上传</div>
                </Upload>
                <div style="width: 6.3rem;height: 0.54rem;background: #F7F7F7;border-radius: 0.04rem;display: inline-block;margin-left: 0.2rem" v-for="(value,index) in information.lawyerPhoto">
                    <div style="font-size: 0.24rem;line-height: 0.54rem;vertical-align: top;font-family: PingFang SC;margin-left: 0.2rem;font-weight: 500;color: #1186E1;display: inline-block;width: 5.66rem;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{value.name}}</div>
                    <img src="/img/zhqy/lconDelete.png" style="width: 0.24rem;height: 0.24rem;vertical-align: top;margin-top: 0.15rem" @click="deleteLawyerPicture(index)">
                </div>
            </div>
            <!---->

            <div v-for="(value,index) in information.lawyerCase">
                <div class="inputFrame">
                    <div style="color: #666;display: inline-block;">律师案例{{index+1}}(标题）</div>
                    <input style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;" placeholder="填写案例标题" v-model="value.title"/>
                </div>
                <div class="textareaFrame">
                    <div style="color: #666;display: inline-block;line-height: 1rem">律师案例{{index+1}}(内容）</div>
                    <textarea v-model="value.content" style="position: absolute;color: #222;right: 0;text-align: right;width: 4.5rem;height: calc(100% - 0.37rem);resize: none;margin-top: 0.37rem;" placeholder="填写案例内容">

                    </textarea>
                </div>
                <div style="height: 0.84rem;font-size: 0" @click="deleteCase(index)">
                    <img class="shanchu" src="/img/zhqy/shanchu.png">
                    <div style="display: inline-block;font-size: 0.24rem;font-family: PingFang SC;font-weight: 500;color: #F12727;margin-top: 0.22rem;line-height: 0.24rem">删除该案例</div>
                </div>
            </div>
            <div class="addCase" @click="addCase()">添加一个案例</div>
            <div style="padding: 0.6rem"></div>
            <div class="preservation" @click="preservation">保存</div>
            <!---->
        </div>
        <!--领域-->
        <Modal
                v-model="modal"
                title="选择擅长领域"
                cancel-text="关闭"
                @on-ok="determine">
            <Collapse v-model="fieldShow" accordion>
                <Panel v-for="(value,index) in fieldData" :name="index.toString()">
                    {{value.keyword}}
                    <p slot="content">
                        <CheckboxGroup v-model="fruit">
                            <Checkbox :label="valeu1.id+','+valeu1.keyword" v-for="(valeu1,i) in value.list">{{valeu1.keyword}}</Checkbox>
                        </CheckboxGroup>
                    </p>
                </Panel>
            </Collapse>
        </Modal>

        <div style="width: 100%;height: 1rem;background-color: #FFF;position: fixed;bottom: 0;">
            <div @click="home" style="width: 49%;height: 1rem;font-size: 0.26rem;text-align: center;display: inline-block">
                <img src="/img/zhqy/sy1.png" style="width: 0.36rem;height: 0.36rem;margin-top: 0.1rem">
                <div>首页</div>
            </div>
            <div style="width: 49%;height: 1rem;font-size: 0.26rem;text-align: center;display: inline-block">
                <img src="/img/zhqy/geren.png" style="width: 0.36rem;height: 0.36rem;margin-top: 0.1rem">
                <div>个人信息</div>
            </div>
        </div>
        <div style="padding: 0.5rem"></div>
    </div>
</template>

<script>
    export default {
        name: "editInformation",
        data() {
            return {
                value1 : [],
                value2 : '',
                addressData :[],
                modal : false,
                fieldShow : '1',
                fieldData : [],
                fruit : [],
                fruitId : [],
                fruitName : [],
                information : {
                    lawyerId : '',
                    lawyerImage : '',//头像
                    lawyerName : '',//名称
                    lawyerPhone : '',//电话
                    practiceNumber : '',//执业证号
                    lawOfficeName : '',//律所名字
                    lawyerYear : '',//年限
                    lawyerSkill : '',//领域
                    lawyerIntro : '',//简介
                    lawyerPhoto : [],//图片
                    lawyerContent : '',//*律师详情
                    provinceId : '',//省ID
                    cityId : '',//城市ID
                    areaId : '',//区域ID
                    lawyerCost : [{title:'',money:'',content:''}],//服务收费
                    lawyerCase : [
                        {title:'',content:''}
                    ],//案例
                    provinceName : '',
                    cityName : '',
                    areaName : '',
                },
                name : '个人信息',
            }
        },
        methods :{
            home : function(){
                this.$router.push({name: 'zhqyHome', query: {}})//律师
            },
            //保存
            preservation : function(){
                var _this = this
                console.log(_this.information)
                _this.information.lawDetailAddress = _this.information.provinceName+_this.information.cityName+_this.information.areaName+_this.information.lawAddress

                var _this = this
                _this.send({
                    url: '/LawyerUpdate',
                    data: _this.information,
                    success: function (res) {
                        console.log(res)
                        _this.$Message.success('保存成功');
                        _this.login()
                    }
                })
            },

            addressSelect : function(value, selectedData){
                console.log(value, selectedData)
                var _this = this
                _this.information.provinceId = value[0]
                _this.information.cityId = value[1]
                _this.information.areaId = value[2]
                _this.value2 = selectedData[0].label+'/'+selectedData[1].label+'/'+selectedData[2].label
            },
            //    地区
            areaList: function() {
                var _this = this
                var data = []
                _this.send({
                    url: '/AreaAdminList',
                    data: {},
                    success: function(res) {
                        //
                        for(var i in res){
                            data.push(res[i])
                        }
                        convertTree(data)
                        function convertTree(tree) {
                            const result = []
                            tree.forEach((item) => {
                                // 解构赋值
                                let {
                                    AreaId: value,
                                    AreaName: label,
                                    list: children
                                } = item
                                if (children) {
                                    children = convertTree(children)
                                }
                                result.push({
                                    value,
                                    label,
                                    children
                                })
                            })
                            _this.addressData = result
                            return result
                        }
                        console.log(_this.addressData)
                    }
                })
            },
            //确定领域
            determine : function(){
                var _this = this
                var lawyerSkill = []
                _this.fruitId = []
                _this.fruitName = []
                _this.information.lawyerSkill = ''
                for(var i in _this.fruit){
                    _this.fruitId.push(_this.fruit[i].split( ',' )[0])
                    _this.fruitName.push(_this.fruit[i].split( ',' )[1])
                    lawyerSkill.push(_this.fruit[i].split( ',' )[0])
                }
                _this.information.lawyerSkill = lawyerSkill.toString()
                console.log(_this.fruit)
            },
            //删除律师图片
            deleteLawyerPicture :function(index){
                var _this = this
                delete _this.information.lawyerPhoto.splice(index, 1)
                console.log(_this.information.lawyerPhoto)
            },
            //律师图片
            lawyerPicture : function(response){
                var _this = this
                _this.information.lawyerPhoto.push(window.sessionStorage.url+response.Data)
                console.log(_this.information.lawyerPhoto)
            },
            // 头像
            headPortrait : function(response){
                var _this = this
                _this.information.lawyerImage = window.sessionStorage.url+response.Data.path
            },
            //添加服务收费
            addServiceCharge : function(){
                var _this = this
                _this.information.lawyerCost.push({title:'',money:'',content:''})
                console.log(_this.information.lawyerCost)
            },
            //删除单个服务收费
            deleteServiceCharge : function(index){
                var _this = this
                delete _this.information.lawyerCost.splice(index, 1)
                console.log(_this.information.lawyerCost)
            },
            //添加案例
            addCase : function(){
                var _this = this
                _this.information.lawyerCase.push({title:'',content:''})
                console.log(_this.information.lawyerCase)
            },
            //删除单个案例
            deleteCase : function(index){
                var _this = this
                delete _this.information.lawyerCase.splice(index, 1)
                console.log(_this.information.lawyerCase)
            },
            setPageSize:function() {
                var wsw = document.body.clientWidth
                document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
            },
            skillKey : function () {
                var _this = this
                _this.send({
                    url: '/skillKey',
                    success: function (res) {
                        _this.fieldData = res
                    }
                })
            },
            login : function () {
                var _this = this
                _this.send({
                    url : '/LawyerLoginOrById',
                    data: {lawyerId :window.sessionStorage.lawyerId},
                    success: function (res) {
                        console.log(res.lawyerCase)
                        window.sessionStorage.lawDetailAddress = res.lawDetailAddress || ''
                        window.sessionStorage.lawOfficeId = res.lawOfficeId || ''
                        window.sessionStorage.lawOfficeName = res.lawOfficeName || ''
                        window.sessionStorage.lawyerContent = res.lawyerContent || ''
                        window.sessionStorage.lawyerId = res.lawyerId || ''
                        window.sessionStorage.lawyerImage = res.lawyerImage || ''
                        window.sessionStorage.lawyerIntro = res.lawyerIntro || ''
                        window.sessionStorage.lawyerName = res.lawyerName || ''
                        window.sessionStorage.lawyerPhone = res.lawyerPhone || ''
                        window.sessionStorage.lawyerSkill = res.lawyerSkill || ''
                        window.sessionStorage.lawyerYear = res.lawyerYear || ''
                        window.sessionStorage.practiceNumber = res.practiceNumber || ''
                        window.sessionStorage.skillName = res.skillName || ''
                        window.sessionStorage.lawProvinceId = res.lawProvinceId || ''
                        window.sessionStorage.lawCityId = res.lawCityId || ''
                        window.sessionStorage.lawAreaId = res.lawAreaId || ''

                        window.sessionStorage.areaName = res.areaName || ''
                        window.sessionStorage.cityName = res.cityName || ''
                        window.sessionStorage.provinceName = res.provinceName || ''
                        _this.$router.push({name:'zhqyHome'});
                    }
                })
            },
        },
        mounted (){
            var _this = this
            if(window.sessionStorage.lawyerCase){
                _this.information.lawyerCase = JSON.parse(window.sessionStorage.lawyerCase) || [{title: "", content: ""}]
            }if(window.sessionStorage.lawyerCost){
                _this.information.lawyerCost = JSON.parse(window.sessionStorage.lawyerCost) || [{title: "", money: "", content: ""}]
            }if(window.sessionStorage.lawyerPhoto){
                _this.information.lawyerPhoto = JSON.parse(window.sessionStorage.lawyerPhoto) || []
            }
            _this.information.lawyerId = window.sessionStorage.lawyerId || ''
            _this.information.areaId = window.sessionStorage.areaId || ''
            _this.information.cityId = window.sessionStorage.cityId || ''
            _this.information.lawyerContent = window.sessionStorage.lawyerContent
            _this.information.lawyerImage = window.sessionStorage.lawyerImage || ''
            _this.information.lawOfficeName = window.sessionStorage.lawOfficeName || ''
            _this.information.lawyerIntro = window.sessionStorage.lawyerIntro || ''
            _this.information.lawyerName = window.sessionStorage.lawyerName || ''
            _this.information.lawyerPhone = window.sessionStorage.lawyerPhone || ''
            _this.information.lawyerSkill = window.sessionStorage.lawyerSkill || ''
            _this.fruitName = window.sessionStorage.skillName || []
            _this.fruitId = window.sessionStorage.lawyerSkill || []
            _this.information.lawyerYear = window.sessionStorage.lawyerYear || ''
            _this.information.practiceNumber = window.sessionStorage.practiceNumber || ''
            _this.information.provinceId = window.sessionStorage.provinceId || ''
            _this.information.lawProvinceId = window.sessionStorage.lawProvinceId || ''
            _this.information.lawCityId = window.sessionStorage.lawCityId || ''
            _this.information.lawAreaId = window.sessionStorage.lawAreaId || ''

            _this.information.areaName = window.sessionStorage.areaName || ''
            _this.information.cityName = window.sessionStorage.cityName || ''
            _this.information.provinceName = window.sessionStorage.provinceName || ''
            _this.value2 = window.sessionStorage.provinceName+'/'+window.sessionStorage.cityName+'/'+window.sessionStorage.areaName || ''
            for(var i in _this.fruitName.split(',')){
                _this.fruit.push(_this.fruitId.split(',')[i]+','+_this.fruitName.split(',')[i])
            }
            _this.setPageSize()
            _this.skillKey()
            _this.areaList()
        }
    }
</script>


