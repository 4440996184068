<style scoped>
.tableTitle{font-size:20px;}
.export-btn{font-size:10px;color:#2d8cf0;cursor:pointer;}
</style>

<template>
  <div>
    <div style="padding:10px"></div>
    <div style="text-align: center;">
      <span class="tableTitle">区域服务治理统计</span>
      <div class="export-btn" @click="exportData(table.data)">[导出数据]</div>
    </div>
    <div style="padding:5px"></div>
    <Table height="500" border :loading="table.loading" :columns="table.columns" :data="table.data" :resizable="true" ref="table" show-summary @on-row-click="lowerLevel" :summary-method="handleSummary"></Table>
    <div style="padding:20px"></div>
    <!--    查看下级数据-->
    <Modal
        v-model="modal1"
        title="区域服务治理统计" @on-cancel="cancel" width="1200">
      <div slot="footer">
        <template>
          <Button
              size="large"
              @click="cancel"
          >关闭</Button>
        </template>
      </div>
      <div>
        <div style="text-align: center;">
          <div class="tableTitle">区域服务治理统计</div>
          <span class="export-btn" @click="exportData(table1.data)">[导出数据]</span>
        </div>
        <div style="padding:5px"></div>
        <Table height="550" border :loading="table1.loading" :columns="table1.columns" :data="table1.data" ref="table1" :resizable="true" show-summary :summary-method="handleSummary"></Table>
        <div style="padding:20px"></div>
      </div>
    </Modal>
  </div>
</template>

<script>
//表头控制文件
import * as tableColumns from "./table-columns"
import * as tableDataSorting from "./data-sorting"
export default {
  name:'regionLitongTable',//分区域总统计表
  props:{
    dateSelecter:{type:Object,required:true,},
  },
  data:function(){
    return{
      table:{
        loading:true,
        columns:tableColumns.regionLitongTable,
        data:[],
      },
      table1:{
        loading:false,
        columns:tableColumns.regionLitongTable,
        data:[],
        areaName : '',
      },
      modal1 : false
    }
  },
  watch:{
    'dateSelecter.identification':function(val){
      if(val != 'custom'){
        this.getTableData()
      }
    }
  },
  mounted:function(){
    this.getTableData()
  },
  methods:{
    handleSummary ({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[key] = {
            key,
            value: v + ''
          };
        } else {
          if(this.modal1){
            sums[key] = {
              key,
              value: this.table1.areaName
            };
          }else {
            sums[key] = {
              key,
              value: window.sessionStorage.AdminName
            };
          }
        }
      });

      return sums;
    },
    exportData : function (data){
      var _this = this
      this.$refs.table.exportCsv({
        filename: '区域服务治理统计',
        columns : _this.table.columns,
        data : data
      });
    },
    lowerLevel : function (data,index){
      // console.log(this.dateSelecter)
      if(window.sessionStorage.Hierarchy == 2 || window.sessionStorage.Hierarchy == 1){
        this.table1.data = []
        this.table1.areaName = data.areaName
        this.table.loading = false
        this.dateSelecter.AdminId = data.adminId
        this.modal1 = true
        this.getTableData()
      }
    },
    // 弹窗取消
    cancel : function (){
      this.dateSelecter.AdminId = ''
      this.modal1 = false
    },
    //区域服务治理统计
    getTableData:function(){
      var _this = this
      if(this.modal1 == false){_this.table.loading = true}
      _this.table1.loading = true
      if(window.sessionStorage.Hierarchy == 4){
        var Data = [
          "/ReportVillageWritData", // 累计代拟文书
          "/ReportVillageCheckFileData", // 累计审核文件
          "/ReportVillageAttendCfData", // 累计列席会议
          "/ReportVillageCampaignData", //累计参加活动
          "/ReportVillageLawPreachData", //累计法治宣讲
          "/ReportVillageMediateCount", // 累计参与调解
          "/ReportVillagePetitionDealData", //累计接处信访
          "/ReportVillageDutyData", //累计值班接待
          "/ReportVillageOtherData", //累计其他服务
        ]
      }else{
        var Data = [
          "/ReportWritData", // 累计代拟文书
          "/ReportCheckFileData", // 累计审核文件
          "/ReportAttendCfData", // 累计列席会议
          "/ReportCampaignData", //累计参加活动
          "/ReportLawPreachData", //累计法治宣讲
          "/ReportInMediateData", // 累计参与调解
          "/ReportPetitionDealData", //累计接处信访
          "/ReportDutyData", //累计值班接待
          "/ReportOtherData", //累计其他服务
        ]
      }
      _this.$parent.requestHub(Data)
          .then(res=>{
            if(this.modal1 == false){_this.table.loading = false}
            _this.table1.loading = false
            var data = tableDataSorting.dataMerge(res,{local:'adminId',remote:'AdminId'},[
              {columnName:'areaName',dataIndex:0,remoteVariable:['AreaShortName||AdminName']},
              {columnName:'newReportWrit',dataIndex:0,remoteVariable:['Count','Intime']},
              {columnName:'totalReportWrit',dataIndex:0,remoteVariable:['Count','Total']},
              {columnName:'newReportCheckFile',dataIndex:1,remoteVariable:['Count','Intime']},
              {columnName:'totalReportCheckFile',dataIndex:1,remoteVariable:['Count','Total']},
              {columnName:'newReportAttendCf',dataIndex:2,remoteVariable:['Count','Intime']},
              {columnName:'totalReportAttendCf',dataIndex:2,remoteVariable:['Count','Total']},
              {columnName:'newReportCampaign',dataIndex:3,remoteVariable:['Count','Intime']},
              {columnName:'totalReportCampaign',dataIndex:3,remoteVariable:['Count','Total']},
              {columnName:'newReportLawPreach',dataIndex:4,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLawPreach',dataIndex:4,remoteVariable:['Count','Total']},
              {columnName:'newReportInMediate',dataIndex:5,remoteVariable:['Count','Intime']},
              {columnName:'totalReportInMediate',dataIndex:5,remoteVariable:['Count','Total']},
              {columnName:'newReportPetitionDeal',dataIndex:6,remoteVariable:['Count','Intime']},
              {columnName:'totalReportPetitionDeal',dataIndex:6,remoteVariable:['Count','Total']},
              {columnName:'newReportDuty',dataIndex:7,remoteVariable:['Count','Intime']},
              {columnName:'totalReportDuty',dataIndex:7,remoteVariable:['Count','Total']},
              {columnName:'newReportOther',dataIndex:8,remoteVariable:['Count','Intime']},
              {columnName:'totalReportOther',dataIndex:8,remoteVariable:['Count','Total']},
            ])
            if(_this.dateSelecter.AdminId == undefined || _this.dateSelecter.AdminId == ''){
              _this.table.data = data
            }else {
              _this.table1.data = data
            }
          })
          .catch(err=>{console.log("区域服务治理统计：失败！")})
    },
  },
}
</script>
