<template>
    <!--评价-->
    <div class="screen">
        <div style="background-color: white; padding: 20px">
            <div style="padding: 15px"></div>
            <div
                style="
                    font-size: 16px;
                    text-align: left;
                    margin-left: 20px;
                    border-left: solid #de4f0b 3px;
                    padding-left: 5px;
                    display: inline-block;
                "
            >
                咨询服务
            </div>
<!--            <div style="display: inline-block">-->
<!--                <router-link to="/handlingServices"-->
<!--                    ><Button type="primary" style="margin-left: 20px">申办服务</Button></router-link-->
<!--                >-->
<!--                <router-link to="/queryService"-->
<!--                    ><Button type="primary" style="margin-left: 20px">查询服务</Button></router-link-->
<!--                >-->
<!--            </div>-->
            <div style="padding: 15px"></div>
            <Tabs v-if="loadQuantity" :value="type" @on-click="opinion" style="margin: 0 20px">
                <TabPane v-for="(value, index) in serviceData" :label="tabsTabel(value)" :name="value.type"> </TabPane>
            </Tabs>
            <!--     自助咨询 -->
            <div v-if="type == 1">
              <div class="publicWelfare">
                <DatePicker
                    :value="timeData"
                    v-model="timeData"
                    type="daterange"
                    split-panels
                    placeholder="请选择时间"
                    style="width: 200px"
                    @on-change="totalTime1"
                ></DatePicker>
                <Select v-model="model1" style="width: 200px" @on-change="lawyerChoice" filterable clearable placeholder="请输入服务人员">
                  <Option v-for="(value, index) in lawyerData" :value="value.LawyerId" :key="value.LawyerId">
                    {{ value.LawyerName }}</Option
                  >
                </Select>
                <Select
                    style="width: 200px"
                    v-model="model14"
                    filterable
                    remote
                    clearable
                    :remote-method="remoteMethod2"
                    @on-change="lawyerChoice2"
                    :loading="loading2"
                    placeholder="请输入社区"
                >
                  <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">
                    {{ value.VillagesName }}</Option
                  >
                </Select>

<!--                <Button type="primary" style="margin-left: 20px" @click="robot();">关键词分析</Button>-->
                <div style="padding: 10px"></div>
                <table class="formtable">
                  <thead>
                  <tr>
                    <th>序号</th>
                    <th>用户</th>
                    <th>所属村(社区)</th>
                    <th>查询时间</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(value, index) in detailsSeeLIst">
                    <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
                    <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
                    <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
                    <td>{{ value.CityName }}{{ value._AdminName }}{{ value.VillagesName }}</td>
                    <td>{{ value.CreatTime }}</td>
                  </tr>
                  </tbody>
                </table>

                <Page
                    :total="count"
                    show-total
                    :page-size="pageSize"
                    show-elevator
                    @on-change="page = $event
                            detailsSee(1)"
                />
              </div>
            </div>
            <!--      -->
            <div v-if="type == 2">
                <div class="publicWelfare">
                    <DatePicker
                        :value="timeData"
                        v-model="timeData"
                        type="daterange"
                        split-panels
                        placeholder="请选择时间"
                        style="width: 200px"
                        @on-change="totalTime0"
                    ></DatePicker>
                    <Select v-model="model1" style="width: 200px" @on-change="lawyerClick" filterable clearable placeholder="请输入服务人员">
                        <Option v-for="(value, index) in lawyerData" :value="value.LawyerId" :key="value.LawyerId">
                            {{ value.LawyerName }}</Option
                        >
                    </Select>
                    <Select
                        style="width: 200px"
                        v-model="model141"
                        filterable
                        remote
                        clearable
                        :remote-method="remoteMethod2"
                        @on-change="lawyerClick2"
                        :loading="loading2"
                        placeholder="请输入社区"
                    >
                        <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">
                            {{ value.VillagesName }}</Option
                        >
                    </Select>
                  <Select v-model="isArchive" style="width: 200px" placeholder="请选择是否归档" clearable filterable @on-change="userOrderLIst">
                    <Option value="" label="全部"></Option>
                    <Option value="1" label="已归档"></Option>
                  </Select>
                  <div style="padding: 10px"></div>
                    <table class="formtable">
                        <thead>
                            <tr>
                                <th>序号</th>
                                <th>用户</th>
                                <th>所属村(社区)</th>
                                <!--<th>用户号码</th>-->
                                <th>发起咨询时间</th>
                                <th>服务状态</th>
                                <th>处理状态</th>
                                <th>处理时间</th>
                                <th>服务人员</th>
                                <th v-if="cq == ''">律师小结</th>
                                <th>
                                  <div style="vertical-align: top;display: inline-block;margin-top: 3px;">是否归档</div>
<!--                                  <div style="display: inline-block;cursor: pointer;" @click="userOrderLIst(1)">-->
<!--                                    <Icon size="25" type="md-arrow-dropup" />-->
<!--                                  </div>-->
                                </th>
                                <th>操作</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, index) in UserOrderLIst">
                                <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
                                <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
                              <td>
                                <div v-if="value.user"><Avatar :src="value.user.UserImg" /> {{ value.user.UserName }}</div>
                                <div v-else>--</div>
                              </td>
                              <td>{{ value.Villages.CityName }}{{ value.Villages.street }}{{ value.Villages.VillagesName }}</td>
                                <!--<td>{{value.UserPhone}}</td>-->
                                <td>{{ value.OrderCreatedTime }}</td>
                                <td>{{ value.state }}</td>
                                <td>{{ value.isReply }}</td>
                                <td>{{ value.handleTime }}</td>
                                <td>{{ value.lawyer.LawyerName }}</td>
                                <td v-if="cq == ''">{{ value.OrderSummary ? value.OrderSummary : '未填写' }}</td>
                                <td>
                                  <span style="cursor: pointer;color: #2d8cf0" v-if="value.archiveId" @click="see(value.FileStatsId);modal3 = true">已归档</span>
                                  <span v-else style="color:red;">未归档</span>
                                </td>
                               <td><Button type="primary" @click="flowPathClick(value)">流程</Button></td>
                            </tr>
                        </tbody>
                    </table>

                    <Page
                        :total="count"
                        show-total
                        :page-size="pageSize"
                        show-elevator
                        @on-change="
                            page = $event
                            userOrderLIst()
                        "
                    />
                </div>
            </div>
            <!--      -->
            <div v-if="type == 3">
                <div class="publicWelfare">
                    <DatePicker
                        :value="timeData"
                        v-model="timeData"
                        type="daterange"
                        split-panels
                        placeholder="请选择时间"
                        style="width: 200px"
                        @on-change="totalTime2"
                    ></DatePicker>
                    <Select v-model="model1" style="width: 200px" @on-change="lawyerChoiceA" filterable clearable placeholder="请输入服务人员">
                        <Option v-for="(value, index) in lawyerData" :value="value.LawyerId" :key="value.LawyerId">
                            {{ value.LawyerName }}</Option
                        >
                    </Select>
                    <Select
                        style="width: 200px"
                        v-model="model142"
                        filterable
                        remote
                        clearable
                        :remote-method="remoteMethod2"
                        @on-change="lawyerChoiceA2"
                        :loading="loading2"
                        placeholder="请输入社区"
                    >
                        <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">
                            {{ value.VillagesName }}</Option
                        >
                    </Select>
                  <Select v-model="isArchive" style="width: 200px" placeholder="请选择是否归档" clearable filterable @on-change="dialTelephone">
                    <Option value="" label="全部"></Option>
                    <Option value="1" label="已归档"></Option>
                  </Select>
                  <div style="padding: 10px"></div>
                    <table class="formtable">
                        <thead>
                            <tr>
                                <th>序号</th>
                                <th>用户</th>
                                <th>所属村(社区)</th>
                                <th>服务人员</th>
                                <th>拨打时间</th>
                                <th>通话时长</th>
                                <th>
                                  <div style="vertical-align: top;display: inline-block;margin-top: 3px;">是否归档</div>
<!--                                  <div style="display: inline-block;cursor: pointer;" @click="dialTelephone(1)">-->
<!--                                    <Icon size="25" type="md-arrow-dropup" />-->
<!--                                  </div>-->
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, index) in UserCallList">
                                <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
                                <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
                                <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
                                <td>{{ value.CityName }}{{ value._AdminName }}{{ value.VillagesName }}</td>
                                <td>{{ value.LawyerName }}</td>
                                <td>{{ value.Time }}</td>
                                <td>
                                  <span v-if="value.ServiceLong || value.ServiceMins">{{value.ServiceLong}}小时{{value.ServiceMins}}分钟</span>
                                  <span v-else>--</span>
                                </td>
                                <td >
                                  <span style="cursor: pointer;color: #2d8cf0" v-if="value.archiveId" @click="see(value.FileStatsId);modal3 = true">已归档</span>
                                  <span v-else style="color:red;">未归档</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Page
                        :total="count"
                        show-total
                        :page-size="pageSize"
                        show-elevator
                        @on-change="
                            page = $event
                            dialTelephone(serviceType, serviceName, typeData)
                        "
                    />
                </div>
            </div>
            <!--      -->
            <div v-if="type == 4 || type == 5 || type == 6">
                <div class="publicWelfare">
                    <DatePicker
                        :value="timeData"
                        v-model="timeData"
                        type="daterange"
                        split-panels
                        placeholder="请选择时间"
                        style="width: 200px"
                        @on-change="totalTime1"
                    ></DatePicker>
                    <Select v-model="model1" style="width: 200px" @on-change="lawyerChoice" filterable clearable placeholder="请输入服务人员">
                        <Option v-for="(value, index) in lawyerData" :value="value.LawyerId" :key="value.LawyerId">
                            {{ value.LawyerName }}</Option
                        >
                    </Select>
                    <Select
                        style="width: 200px"
                        v-model="model14"
                        filterable
                        remote
                        clearable
                        :remote-method="remoteMethod2"
                        @on-change="lawyerChoice2"
                        :loading="loading2"
                        placeholder="请输入社区"
                    >
                        <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">
                            {{ value.VillagesName }}</Option
                        >
                    </Select>
                  <Select v-model="isArchive" style="width: 200px" placeholder="请选择是否归档" clearable filterable @on-change="detailsSee">
                    <Option value="" label="全部"></Option>
                    <Option value="1" label="已归档"></Option>
                  </Select>
                  <div style="padding: 10px"></div>
                    <table class="formtable">
                        <thead>
                            <tr>
                                <th>序号</th>
                                <th>用户</th>
                                <th>所属村(社区)</th>
                                <th>服务人员</th>
                                <th v-if="serviceName == '法律援助' || serviceName == '调解申请'">申请状态</th>
                                <th>发起咨询时间</th>
                                <th v-if="type == 4">通话时长</th>
                                <th v-if="type == 4">
                                  <div style="vertical-align: top;display: inline-block;margin-top: 3px;">是否归档</div>
<!--                                  <div style="display: inline-block;cursor: pointer;" @click="detailsSee(4,1)">-->
<!--                                    <Icon size="25" type="md-arrow-dropup" />-->
<!--                                  </div>-->
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, index) in detailsSeeLIst">
                                <td v-if="(page - 1) * pageSize + index + 1 < 10">0{{ (page - 1) * pageSize + index + 1 }}</td>
                                <td v-else>{{ (page - 1) * pageSize + index + 1 }}</td>
                                <td><Avatar :src="value.UserImg" /> {{ value.UserName }}</td>
                                <td>{{ value.CityName }}{{ value._AdminName }}{{ value.VillagesName }}</td>
                                <td>{{ value.LawyerName }}</td>
                                <td v-if="serviceName == '法律援助' || serviceName == '调解申请'">
                                    {{ value.IsApply }}
                                </td>
                                <td>{{ value.OrderCreatedTime }}</td>
                                <td v-if="type == 4">
                                  <span v-if="value.ServiceLong || value.ServiceMins">{{value.ServiceLong}}小时{{value.ServiceMins}}分钟</span>
                                  <span v-else>--</span>
                                </td>
                                <td v-if="type == 4">
                                  <span style="cursor: pointer;color: #2d8cf0" v-if="value.archiveId" @click="see(value.FileStatsId);modal3 = true">已归档</span>
                                  <span v-else style="color:red;">未归档</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Page
                        :total="count"
                        show-total
                        :page-size="pageSize"
                        show-elevator
                        @on-change="
                            page = $event
                            detailsSee(4)
                        "
                    />
                </div>
            </div>
            <!--      -->
            <!--      -->
        </div>
        <!--    弹窗-->
        <Modal :draggable="true" v-model="modal8" title="案由" @on-ok="" @on-cancel="cancel" width="530">
            <div class="publicWelfare">
                <table class="formtable">
                    <thead>
                        <tr>
                            <!--<th>案由</th>-->
                            <th>序号</th>
                            <th>社区（村）</th>
                            <th>数量</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(value, index) in listData">
                            <!--<td>{{value.keyword}}</td>-->
                            <td>{{index+1}}</td>
                            <td>{{ value.CityName }}{{ value._AdminName }}{{ value.VillagesName }}</td>
                            <td>{{ value.nums }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Modal>
      <Modal :draggable="true" v-model="modal13" title="关键词排名" @on-ok="" @on-cancel="cancel" width="600">
        <div class="publicWelfare">
          <div id="robot" style="height: 600px; width: 100%"></div>
        </div>
      </Modal>
<!--      -->
      <Modal v-model="modal3" :title="eleFileViewData.FileName" width="950" draggable scrollable :styles="{top: '0'}":zIndex="zIndex" @on-cancel="cancel">
        <div style="height: 420px;overflow: auto;">
          <!--<p>{{fileurl}}</p>-->
          <div style="margin-bottom: 10px;" v-if="eleFileViewData.DocumentUrl">
            <div v-for="(value,index) in eleFileViewData.DocumentUrl" style="margin-left: 30px" >
              {{value.name}}
              <Button type="primary" @click="seeFile(domainName+value.url,1)" >查看</Button>
              <Button type="primary" @click="seeFile(domainName+value.url,2)" >下载</Button>
            </div>

          </div>
          <!--<Carousel v-model="value1" v-if="modal3 && fileurl" @on-click="enlarge">-->
          <!--<CarouselItem v-for="(value,index) in fileurl" >-->
          <!--<img :src="value" style="width: 100%;">-->
          <!--</CarouselItem>-->
          <!--</Carousel>-->
          <viewer :images="tupians" v-if="eleFileViewData.FileUrl" style="width: 47%;display: inline-block;">
            <!--<i-col span="4" v-for="item in tupians" v-if="tupians">-->
            <!--<div class="detailed">-->
            <!--<img :src="item" alt="">-->
            <!--</div>-->
            <!--</i-col>-->
            <Carousel v-model="value1" v-if="modal3 && eleFileViewData.FileUrl" @on-click="enlarge" dots="none">
              <CarouselItem v-for="(value,index) in eleFileViewData.FileUrl" style="text-align: center">
                <img :src="domainName+value" style="max-width:100%;max-height: 420px">
              </CarouselItem>
            </Carousel>
          </viewer>
          <div style="display: inline-block;vertical-align: top;width: 50%;margin-left: 20px">
            <div v-if="eleFileViewData.villagesNameArr">社区(村)：<p v-for="(value,index) in eleFileViewData.villagesNameArr" style="display: inline-block">
              {{value.VillagesName}}<span v-if="index+1<eleFileViewData.villagesNameArr.length">-</span></p>
            </div>
            <div v-if="cq == ''&& eleFileViewData.title">
              考核分栏：<span v-if="eleFileViewData.title">{{eleFileViewData.title}}-</span>{{eleFileViewData.pTitle}}
            </div>
            <div v-if="eleFileViewData.EvaluationCount">
              考核计数：<span>{{eleFileViewData.EvaluationCount}}{{eleFileViewData.Unit}}</span>
            </div>
            <div v-if="eleFileViewData.ServiceLinkman">联系人：{{eleFileViewData.ServiceLinkman}}</div>
            <div v-if="eleFileViewData.ServiceTel">联系电话：{{eleFileViewData.ServiceTel}}</div>
            <div v-if="eleFileViewData.ConsultHumanTypeArr">咨询人类别类型：
              <p v-for="(value,index) in eleFileViewData.ConsultHumanTypeArr" style="display: inline-block">
                {{value}}<span v-if="index+1<eleFileViewData.ConsultHumanTypeArr.length">-</span></p>
            </div>
            <div v-if="eleFileViewData.ManageSerTypeArr">服务事项：
              <p v-for="(value,index) in eleFileViewData.ManageSerTypeArr" style="display: inline-block">
                {{value}}<span v-if="index+1<eleFileViewData.ManageSerTypeArr.length">-</span></p>
            </div>
            <div v-if="eleFileViewData.MediateTypeArr">纠纷类型：
              <p v-for="(value,index) in eleFileViewData.MediateTypeArr" style="display: inline-block">
                {{value}}<span v-if="index+1<eleFileViewData.MediateTypeArr.length">-</span></p>
            </div>
            <div v-if="eleFileViewData.ConsultTypeArr">知识类型：
              <p v-for="(value,index) in eleFileViewData.ConsultTypeArr" style="display: inline-block">
                {{value}}<span v-if="index+1<eleFileViewData.ConsultTypeArr.length">-</span></p>
            </div>
            <div v-if="eleFileViewData.DealTypeArr">处理方式：
              <p v-for="(value,index) in eleFileViewData.DealTypeArr" style="display: inline-block">
                {{value}}<span v-if="index+1<eleFileViewData.DealTypeArr.length">-</span></p>
            </div>
            <div v-if="eleFileViewData.MediateHumanTypeArr">纠纷人群：
              <p v-for="(value,index) in eleFileViewData.MediateHumanTypeArr" style="display: inline-block">
                {{value}}<span v-if="index+1<eleFileViewData.MediateHumanTypeArr.length">-</span></p>
            </div>
            <div v-if="eleFileViewData.PetitionerArr">
              <div v-for="(value,index) in eleFileViewData.PetitionerArr" style="display: inline-block">
                <div>信访人：{{value.name}}  信访人电话：{{value.telephone}}</div>
              </div>
            </div>
            <div v-if="eleFileViewData.MediateResult">调解结果：
              <span v-if="eleFileViewData.MediateResult == 1">调解成功</span>
              <span v-if="eleFileViewData.MediateResult == 2">调解不成功</span>
            </div>
            <div v-if="eleFileViewData.ServiceCount">服务人数：{{eleFileViewData.ServiceCount}}</div>
            <div v-if="eleFileViewData.ServiceTime">服务时间：{{uploadDate(eleFileViewData.ServiceTime,2)}}</div>
            <div v-if="parseInt(eleFileViewData.ServiceLong) > 0 || eleFileViewData.ServiceMins">服务时长：{{parseInt(eleFileViewData.ServiceLong)}}小时
              <span v-if="eleFileViewData.ServiceMins">{{eleFileViewData.ServiceMins}}分钟</span>
            </div>
            <div v-if="cq == '' && eleFileViewData.DailyType">服务方式：<p v-for="(value,index) in eleFileViewData.DailyType" style="display: inline-block">
              {{value}}<span v-if="index+1<DailyType.length">-</span></p>
            </div>
            <div v-if="cq != '' && eleFileViewData.ServiceTypeName">
              服务方式：<span style="width: 8%;" v-if="eleFileViewData.ServiceTypeName">{{eleFileViewData.ServiceTypeName}}</span>
              <span style="width: 8%;" v-else>{{eleFileViewData.EvaluationTypePid}}</span>
            </div>
            <div v-if="eleFileViewData.FileExplain">内容：{{eleFileViewData.FileExplain}}</div>
            <div v-if="eleFileViewData.OrderGrade">评价：
              <span v-if="eleFileViewData.OrderGrade == 5">非常满意</span>
              <span v-if="eleFileViewData.OrderGrade == 4">满意</span>
              <span v-if="eleFileViewData.OrderGrade == 3">基本满意</span>
              <span v-if="eleFileViewData.OrderGrade == 2">不满意</span>
              <span v-if="eleFileViewData.OrderGrade == 1">非常不满意</span>
            </div>
          </div>
        </div>

        <div style="position: relative">
          <div style="width: 47%;position: absolute;z-index: 999;bottom: 0;text-align: center;">
            <img :style="value1 == index ?'outline: 2px solid #000;':'border: 2px;'" v-for="(value,index) in eleFileViewData.FileUrl" :src="domainName+value" style="width:40px;height: 40px;margin: 0 5px" @click="switchWalkingLantern(index)">
          </div>
        </div>
      </Modal>
<!--      -->
      <Modal
          v-model="flowPath.show"
          title="留言咨询流程">
          <div v-if="flowPath.data">
            <div>发起咨询时间：{{flowPath.data.contact.ContactCreatedTime}}</div>
            <div>首次响应时间：{{flowPath.data.contact.ContactCreatedTime}}</div>
            <div>办结咨询时间 ：{{flowPath.data.isReplyTime}}</div>
            <div>提交档案时间 ：{{flowPath.data.FileTime}}</div>
          </div>
      </Modal>
    </div>
    <!---->
</template>

<script>
import echarts from 'echarts'

export default {
    name: 'consultingService',
    data() {
        return {
            serviceName: '',
            chongqing: '',
            count: 0,
            listData: [],
            type: '1',
            tabsTabel(data) {
                return (h) => {
                    return h('div', [
                        h('span', data.name),
                        h(
                            'span',
                            {
                                style: { color: 'rgb(222, 79, 11)' }
                            },
                            data.count
                        ),
                        h('span', '条')
                    ])
                }
            },
            loadQuantity: false,
            serviceData: [
                { name: '自助咨询', type: '1', count: '' },
                { name: '留言咨询', type: '2', count: '' },
                { name: '电话咨询', type: '3', count: '' },
                { name: '视频咨询', type: '4', count: '' }
                // {name:'模拟判决',type:5,count:''},
                // {name:'文书下载',type:6,count:''},
                // {name:'企业征信',type:7,count:''},
                // {name:'法律法规库',type:8,count:''},
                // {name:'司法案例库',type:9,count:''},
                // {name:'民法典',type:10,count:''},
                // {name:'网上立案',type:11,count:''},
                // {name:'学法用法',type:12,count:''},
                // {name:'法律服务地图',type:13,count:''},
            ],
            UserOrderLIst: [],
            timeData: '',
            lawyerData: [],
            page: 1,
            pageSize: 15,
            model1: '',
            model141: '',
            model142: '',
            model14: '',
            loading2: false,
            modal8: false,
          modal13: false,
            UserCallList: [],
            options2: [],
            detailsSeeLIst: [],
          eleFileViewData : [],
          tupians: [],
          modal3 : false,
          value1: 0,
          cq : '',
          zIndex : 9,
          DailyType: '',
          num : 0,
          flowPath : {
            show : false,
          },
          isArchive : '',
        }
    },
    methods: {
        flowPathClick : function (value){
            this.flowPath.show = true
            this.flowPath.data = value
        },
        cancel() {
            console.log(111111)
        },
        opinion: function (e) {
            this.type = e
            this.num = 0
            this.page = 1
            console.log(e)
            if (e == 1) {
                this.robotImplement()
              this.detailsSee(e)
            }
            if (e == 2) {
                this.userOrderLIst()
            }
            if (e == 3) {
                this.dialTelephone()
            }
            if (e == 4 || e == 5 || e == 6) {
                this.detailsSee(e)
            }
            this.orderTypeCount()
        },
      robot: function () {
        var _this = this
        _this.modal13 = true
        this.$nextTick(() => {
          this.robotImplement()
        })
      },
        //自助咨询
        robotImplement: function () {
            var _this = this
            let myChart1 = echarts.init(document.getElementById('robot'), 'light')
            _this.send({
                url: '/keyword_click',
                data: {
                    AdminId: _this.information.AdminId
                },
                success: function (res) {
                    var click_count = [] //数量
                    var keyword = [] //名称
                    var gid = [] //名称
                    var compare = function (prop) {
                        return function (obj1, obj2) {
                            var val1 = obj1[prop]
                            var val2 = obj2[prop]
                            if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
                                val1 = Number(val1)
                                val2 = Number(val2)
                            }
                            if (val1 < val2) {
                                return -1
                            } else if (val1 > val2) {
                                return 1
                            } else {
                                return 0
                            }
                        }
                    }
                    res.sort(compare('click'))
                    for (var i in res) {
                        click_count.push(res[i].click)
                        keyword.push(res[i].keyword)
                        gid.push(res[i].gid)
                    }
                    myChart1.getZr().on('click', (params) => {
                        console.log(gid[clickname.dataIndex])
                        _this.send({
                            url: '/keyword_details',
                            data: {
                                AdminId: window.sessionStorage.AdminId,
                                gid: gid[clickname.dataIndex],
                                keyword: clickname.name //开始时间
                            },
                            success: function (res) {
                                _this.listData = res
                                _this.modal8 = !_this.modal8
                            }
                        })
                    })
                    var clickname
                    var clickid
                    myChart1.setOption({
                        title: {
                            text: '关键词点击量TOP30',
                            subtext: '案由TOP30'
                        },
                        tooltip: {
                            trigger: 'axis',
                            formatter: function (params) {
                                for (var i in params) {
                                    clickname = params[i]
                                }
                                for (var i in gid) {
                                    clickid = gid[i]
                                }
                            },
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: ['关键词']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'value',
                            boundaryGap: [0, 0.01]
                        },
                        yAxis: {
                            type: 'category',
                            data: keyword
                        },
                        series: [
                            {
                                label: {
                                    show: true,
                                    position: 'right'
                                },
                                name: gid,
                                type: 'bar',
                                data: click_count
                            }
                        ]
                    })
                }
            })
        },
        //留言咨询
        userOrderLIst: function () {
            var _this = this
            _this.send({
                url: '/UserOrderLIst',
                loading: true,
                data: {
                    AdminId: _this.information.AdminId,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime, //结束时间
                    nameKeyId: _this.nameKeyId, //关键字
                    page: _this.page,
                    pageSize: _this.pageSize,
                    VillagesId: _this.VillagesId,
                    isArchive : _this.isArchive,
                },
                success: function (res) {
                    if (res) {
                        for (var i in res.list) {
                            if (res.list[i].jsonContent) {
                                for (var k in res.list[i].jsonContent) {
                                    if (JSON.parse(res.list[i].jsonContent[k]).type == 1) {
                                        console.log(JSON.parse(res.list[i].jsonContent[k]))
                                        var time = JSON.parse(res.list[i].jsonContent[k])
                                            .sendTime.replace(/年|月/g, '-')
                                            .replace('日', '')
                                        res.list[i].handleTime = time.substr(0, time.length - 3)
                                        break
                                    } else {
                                        res.list[i].handleTime = '--'
                                    }
                                }
                            } else {
                                res.list[i].handleTime = '--'
                            }
                        }
                        _this.UserOrderLIst = res.list
                        _this.count = res.count
                    } else {
                        _this.UserOrderLIst = []
                        _this.count = 0
                    }
                }
            })
        },
        //电话咨询
        dialTelephone: function (isArchive) {
            var _this = this
            _this.send({
                url: '/UserCallList',
                loading: true,
                data: {
                    type: 1,
                    AdminId: _this.information.AdminId,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime, //结束时间
                    page: _this.page,
                    pageSize: _this.pageSize,
                    VillagesId: _this.VillagesId,
                    nameKeyId: _this.nameKeyId, //关键字
                    isArchive : _this.isArchive
                },
                success: function (res) {
                    if (res) {
                        _this.UserCallList = res.List
                        _this.count = res.Count
                    } else {
                        _this.UserCallList = []
                        _this.count = 0
                    }
                }
            })
        },
        //视频咨询
        detailsSee: function (type,isArchive) {
            var _this = this
            if (type == 1) {
              _this.send({
                url: '/UserKeywordList',
                loading: true,
                data: {
                  AdminId: _this.information.AdminId,
                  start_time: _this.startTime, //开始时间
                  end_time: _this.endTime, //结束时间
                  page: _this.page,
                  pageSize: _this.pageSize,
                  VillagesId: _this.VillagesId,
                  nameKeyId: _this.nameKeyId, //关键字
                  isArchive : _this.isArchive
                },
                success: function (res) {
                  _this.detailsSeeLIst = res.list
                  _this.count = res.count
                }
              })
            }
            if (type == 4) {
                var _this = this
                _this.send({
                    url: '/UserVideoList',
                    loading: true,
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId, //关键字
                    },
                    success: function (res) {
                        _this.detailsSeeLIst = res.list
                        console.log(_this.detailsSeeLIst)
                        _this.count = res.count
                    }
                })
            }
            if (type == 5) {
                var _this = this
                _this.send({
                    url: '/UserDecisionList',
                    loading: true,
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        _this.detailsSeeLIst = res.list
                        console.log(_this.detailsSeeLIst)
                        _this.count = res.count
                    }
                })
            }
            if (type == 6) {
                var _this = this
                _this.send({
                    url: '/UserWritList',
                    loading: true,
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        _this.detailsSeeLIst = res.list
                        console.log(_this.detailsSeeLIst)
                        _this.count = res.count
                    }
                })
            }
            if (type == 7) {
                var _this = this
                _this.send({
                    url: '/UserLegalList',
                    loading: true,
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        _this.detailsSeeLIst = res.list
                        console.log(_this.detailsSeeLIst)
                        _this.count = res.count
                    }
                })
            }
            if (type == 8) {
                var _this = this
                _this.send({
                    url: '/UserHandleList',
                    loading: true,
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        _this.detailsSeeLIst = res.list
                        console.log(_this.detailsSeeLIst)
                        _this.count = res.count
                    }
                })
            }
            if (type == 9) {
                var _this = this
                _this.send({
                    url: '/UserMediateList',
                    loading: true,
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        _this.detailsSeeLIst = res.list
                        console.log(_this.detailsSeeLIst)
                        _this.count = res.count
                    }
                })
            }
            if (type == 10) {
                var _this = this
                _this.send({
                    url: '/UserMediateList',
                    loading: true,
                    data: {
                        AdminId: _this.information.AdminId,
                        start_time: _this.startTime, //开始时间
                        end_time: _this.endTime, //结束时间
                        page: _this.page,
                        pageSize: _this.pageSize,
                        VillagesId: _this.VillagesId,
                        nameKeyId: _this.nameKeyId //关键字
                    },
                    success: function (res) {
                        _this.detailsSeeLIst = res.list
                        console.log(_this.detailsSeeLIst)
                        _this.count = res.count
                    }
                })
            }
        },
        //
        //
        lawyerClick: function (e) {
            var _this = this
            if (e == undefined) {
                _this.nameKeyId = ''
            } else {
                _this.nameKeyId = e
            }
            _this.page = 1
            _this.userOrderLIst()
        },
        lawyerClick2: function (e) {
            var _this = this
            if (e == undefined) {
                _this.VillagesId = ''
                _this.options2 = []
            } else {
                _this.VillagesId = e
            }
            _this.page = 1
            _this.userOrderLIst()
        },
        totalTime0: function (e) {
            var _this = this
            ;(_this.startTime = e[0]), //开始时间
                (_this.endTime = e[1]) //结束时间
            _this.page = 1
            _this.userOrderLIst()
        },
        totalTime2: function (e) {
            var _this = this
            ;(_this.startTime = e[0]), //开始时间
                (_this.endTime = e[1]) //结束时间
            _this.page = 1
            _this.dialTelephone()
        },
        lawyerList: function () {
            var _this = this
            _this.send({
                url: '/LawyerList',
                data: {
                    AdminId: _this.information.AdminId
                },
                success: function (res) {
                    _this.lawyerData = res.list
                }
            })
        },
        remoteMethod2(query) {
            console.log(query)
            var _this = this
            _this.send({
                url: '/villagesList',
                data: {
                    AdminId: _this.information.AdminId,
                    Key: query
                },
                success: function (res) {
                    _this.options2 = res.list
                }
            })
        },
        lawyerChoiceA: function (e) {
            var _this = this
            if (e == undefined) {
                _this.nameKeyId = ''
            } else {
                _this.nameKeyId = e
            }
            _this.page = 1
            _this.dialTelephone()
        },
        lawyerChoiceA2: function (e) {
            var _this = this
            if (e == undefined) {
                _this.VillagesId = ''
                _this.options2 = []
            } else {
                _this.VillagesId = e
            }
            _this.page = 1
            _this.dialTelephone()
        },
        totalTime1: function (e) {
            var _this = this
            ;(_this.startTime = e[0]), //开始时间
                (_this.endTime = e[1]) //结束时间
            _this.page = 1
            _this.detailsSee(_this.type)
        },
        lawyerChoice: function (e) {
            var _this = this
            if (e == undefined) {
                _this.nameKeyId = ''
            } else {
                _this.nameKeyId = e
            }
            _this.page = 1
            _this.detailsSee(_this.type)
        },
        lawyerChoice2: function (e) {
            var _this = this
            if (e == undefined) {
                _this.VillagesId = ''
                _this.options2 = []
            } else {
                _this.VillagesId = e
            }
            _this.page = 1
            _this.detailsSee(_this.type)
        },
        orderTypeCount: function () {
            var _this = this
            _this.send({
                url: '/OrderTypeCount',
                data: {
                    AdminId: _this.information.AdminId,
                    typeId: _this.typeId,
                    start_time: _this.startTime, //开始时间
                    end_time: _this.endTime
                },
                success: function (res) {
                    _this.serviceData[0].count = res[1].count
                    _this.serviceData[1].count = res[0].count
                    _this.serviceData[2].count = res[9].count
                    _this.serviceData[3].count = res[2].count
                    _this.loadQuantity = true
                }
            })
        },

        see: function (FileStatsId) {
          var _this = this
          _this.send({
            url: '/eleFileView',
            data: {
              FileStatsId: FileStatsId,
              AdminId: _this.information.AdminId,
            },
            success: function (res) {
              try{
                res.DocumentUrl =  JSON.parse(res.DocumentUrl)
              }catch(e){
                res.DocumentUrl =  [{name:_this.getFileName(res.DocumentUrl),url:res.DocumentUrl}]
              }
              if(res.FileUrl){
                res.FileUrl = res.FileUrl.split(",")
              }
              _this.eleFileViewData = res
              console.log(_this.eleFileViewData)
            }
          })
        },
      enlarge: function (e) {
        this.tupians = this.fileurl
        console.log(this.fileurl)
      },
      switchWalkingLantern : function (index){
        this.value1 = index
      },
      uploadDate : function (source,type){
        var date = new Date(1000 * source); //获取一个时间对象
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        var D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
        var H = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
        var B = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes()) + ' ';
        if(type == 1){return Y + M + D + H + B}
        if(type == 2){return Y + M + D}
      },
    },
    mounted() {
        this.information = window.sessionStorage
        this.detailsSee(1)
        this.lawyerList()
        this.orderTypeCount()
      if(SYSCONFIG.location == 'chong-qing'){
        this.cq = 'cq'
      }
      this.domainName = window.sessionStorage.url
    }
}
</script>

<style scoped>
/*.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: rgb(222, 79, 11);padding: 0px 16px;}*/
/*.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: rgb(222, 79, 11);}*/
/*.screen :deep(.ivu-tabs-ink-bar){background-color: rgb(222, 79, 11);}*/
/*表格*/
.publicWelfare table {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}

.formtable {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}

.publicWelfare table th {
    background: #f7f7f7;
    white-space: nowrap;
    color: #5c6b77;
    font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
    border: 1px solid #e9e9e9;
    padding: 8px 16px;
    text-align: left;
}
</style>
