//分区域总统计表API数据整理
export var dataMerge = function(data,idName,rules){
  var tableData = []
  //整理数据
  data.map((item,index)=>{
    var tempArr = []
    for(var index1 in item){
      //从第一个数组取出adminId形成基准数据，对齐数据长度
      if(index==0){tableData.push({[idName.local]:item[index1][idName.remote]})}
      //格式化对象为数组
      tempArr.push(item[index1])
    }
    data[index] = tempArr
  })
  console.log(data)
  console.log(tableData)
  //根据基准数据找出子数据并合成至tableData，简化运算流程降低运算负载
  tableData.map((item)=>{
    rules.map(item1=>{
      //根据传递的基准数据，取出对象
      let remoteVariable = data[item1.dataIndex].find((item2)=>{
        return item2[idName.remote]==item[idName.local]
      })??[]
      //判断是否对象取值为多个
      let tempArr = item1.remoteVariable[0].split('||')
      if(tempArr.length>0){
        tempArr.map(item2=>{
          console.log(remoteVariable[item2])
          if(remoteVariable[item2]){
            item[item1.columnName] = remoteVariable[item2]??0
          }else {
            item[item1.columnName] = {Intime:0,Total:0}
          }
        })
      }else{
        item[item1.columnName] = remoteVariable[item1.remoteVariable[0]]??item1.remoteVariable[1]??{}
      }
      //多层取值，最多支持到两层
      if(item1.remoteVariable[1]&&item[item1.columnName]){
        item[item1.columnName] = item[item1.columnName][item1.remoteVariable[1]]??0
      }
    })
  })
  return tableData
}
