import { render, staticRenderFns } from "./correctionL.vue?vue&type=template&id=7172f81e&scoped=true&"
import script from "./correctionL.vue?vue&type=script&lang=js&"
export * from "./correctionL.vue?vue&type=script&lang=js&"
import style0 from "./correctionL.vue?vue&type=style&index=0&id=7172f81e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7172f81e",
  null
  
)

export default component.exports