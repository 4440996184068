//地图名称映射文件
export default{
  // '彭水苗族土家族自治县':'彭水县',
  // '酉阳土家族苗族自治县':'酉阳县',
  // '秀山土家族苗族自治县':'秀山县',
  // '石柱土家族自治县':'石柱县',
  '鱼嘴镇人民政府':'鱼嘴镇',
  '复盛镇人民政府':'复盛镇',
  '五宝镇人民政府':'五宝镇',
  '新津县':'新津区',
}