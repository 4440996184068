<style>
  .click-tips{background-color:rgba(180, 147, 0, 0.5);color:#fff;text-align:center;border-radius:0.05rem;border:0.02rem solid #fff;padding:0 0.1rem}
  .click-position{background-color:rgba(24, 182, 255, 0.8);width:0.5rem;height:0.5rem;border-radius:0.25rem;border:0.08rem solid #ddd;}
</style>

<template>
  <div>
    <div id="viewer"></div>
  </div>
</template>

<script>
import {Viewer} from 'photo-sphere-viewer'
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css'
import { MarkersPlugin } from "photo-sphere-viewer/dist/plugins/markers";
import "photo-sphere-viewer/dist/plugins/markers.css";
import {GyroscopePlugin} from 'photo-sphere-viewer/dist/plugins/gyroscope.js'
import {VirtualTourPlugin} from 'photo-sphere-viewer/dist/plugins/virtual-tour.js'
import "photo-sphere-viewer/dist/plugins/virtual-tour.css";
import vrConfig from './vr-config'
export default {
  data(){
    return{
      viewer:{},
    }
  },
  mounted(){
    this.makeViewer(this.$route.query.scene||'sceneA',this.$route.query.node||false)
  },
  methods:{
    makeViewer:function(scene,node){
      this.viewer = new Viewer({
        container:'viewer',
        autorotateIdle:true,
        autorotateDelay:8000,
        autorotateSpeed:'0.1rpm',
        size:{
          width: '100vw',
          height: '100vh',
        },
        plugins: [
          [VirtualTourPlugin, {
            positionMode: VirtualTourPlugin.MODE_GPS,
            renderMode  : VirtualTourPlugin.MODE_3D,
          }],
          GyroscopePlugin,
          MarkersPlugin,
        ],
      })
      this.viewer.on('click', function(e, data) {
        console.log(data)
      });
      const markersPlugin = this.viewer.getPlugin(MarkersPlugin);
      markersPlugin.on('select-marker', (e, marker,visible) => {
        console.log(marker)
      });
      const virtualTour = this.viewer.getPlugin(VirtualTourPlugin);
      virtualTour.setNodes(vrConfig[scene],node||vrConfig[scene][0].id)
    }
  },
}
</script>
