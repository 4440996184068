<template>
  <div class="bg">
    <div class="introduce-text">成都仲裁委员会按照国务院办公厅印发的《仲裁委员会收费办法》（国办发[1995]44号），向当事人收取仲裁费，包括案件受理费和处理费。仲裁费由申请仲裁的一方当事人预交，原则上由败诉的当事人承担，当事人部分胜诉、部分败诉的，由仲裁庭根据当事人各方责任大小确定其各自应当分担的比例。仲裁庭在调解书或裁决书中将写明双方当事人最终应当支付的仲裁费用金额。</div>
    <div class="introduce-text">
      <van-cell-group inset>
        <van-field v-model="money" label="输入争议金额：" label-width="1.95rem" type="number">
          <template #button>单位( 元 )</template>
        </van-field>
      </van-cell-group>
      <div style="text-align: center;margin: 0.2rem">
        <van-button type="primary" color="#008fd6" @click="calcFee">计算</van-button>
      </div>
      <van-cell-group inset>
        <van-field v-model="disputedAmount" label="争议金额：" label-width="1.5rem" type="number" disabled>
          <template #button>单位( 元 )</template>
        </van-field>
        <van-field v-model="arbitrationFees" label="仲裁收费：" label-width="1.5rem" type="number" disabled>
          <template #button>单位( 元 )</template>
        </van-field>
      </van-cell-group>
    </div>
    <div class="introduce-text">备注：因小数位四舍五入顺序的不同，以上计算结果与本会立案室办案系统的计算结果可能会略有差异，以立案室的计算为准。请咨询028-86715847</div>
  </div>
</template>

<script>
export default {
name: "chargeFast",
  data() {
    return {
      money : '',
      disputedAmount : '',
      arbitrationFees : '',
    }
  },
  methods: {
    calcFee : function (){
      var money = this.money
          var fee1 = 0 // 受理费(元)
          var fee2 = 0; // 处理费(元) = 受理费 * 35% 取整
      if (isNaN(money)) return;
      if (money >= 0 && money <= 1000) {
        var fee1 = 80;
        var fee2 = Math.floor(fee1 * 35 / 100);
      }
      else if (money > 1000 && money <= 50000) {
        var fee1 = Math.floor(80 + (money - 1000) * 4 / 100);
        var fee2 = Math.floor(fee1 * 35 / 100);
      }
      else if (money > 50000 && money <= 100000) {
        var fee1 = Math.floor(2040 + (money - 50000) * 3 / 100);
        var fee2 = Math.floor(fee1 * 35 / 100);
      }
      else if (money > 100000 && money <= 200000) {
        var fee1 = Math.floor(3540 + (money - 100000) * 2 / 100);
        var fee2 = Math.floor(fee1 * 35 / 100);
      }
      else if (money > 200000 && money <= 500000) {
        var fee1 = Math.floor(5540 + (money - 200000) * 1 / 100);
        var fee2 = Math.floor(fee1 * 35 / 100);
      }
      else if (money > 500000 && money <= 1000000) {
        var fee1 = Math.floor(8540 + (money - 500000) * 5 / 1000);
        var fee2 = Math.floor(fee1 * 35 / 100);
      }
      else if (money > 1000000) {
        var fee1 = Math.floor(11040 + (money - 1000000) * 25 / 10000);
        var fee2 = Math.floor(fee1 * 35 / 100);
      }
      //处理费不超过50000
      if (fee2 > 50000) {
        var fee2 = 50000;
      }
      this.disputedAmount = money
      this.arbitrationFees = fee1 + fee2
      this.money = ''
    },
  }
}
</script>



<style scoped>
.bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
.img{width: 6.8rem}
.introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;margin-bottom: 0.2rem}
.introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
.introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
.connect{color: #008fd6;padding: 0.1rem;text-decoration:underline;}
</style>