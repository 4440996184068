<style scoped>
.bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
.introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
.introduce-video-player{width:100%;}
.mediator-list{color:#FFF;}
.mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.1rem;}
.mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
.introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
.introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
.introduce-text-content{color:#999999;font-size:0.26rem;line-height:1.7em;}
</style>

<template>
  <div class="bg">
    <div class="introduce-text">
      <div class="introduce-text-title">自然人赋强公证需提交的材料</div>
      <div class="introduce-text-content">
        一、自然人向银行借款的</br>
        1、借款人身份证；</br>
        2、户口簿；</br>
        3、结婚证或离婚证、离婚协议；</br>
        4、抵押借款的抵押物登记证。</br>
        二、银行应提交</br>
        1、营业执照副本；</br>
        2、金融许可证；</br>
        3、法人授权委托书，法人身份证复印件；</br>
        4、经办人身份证；</br>
        5、借款合同、抵押合同。
      </div>
    </div>
    <div style="padding:0.15rem;"></div>
    <Row style="text-align: center;">
      <Col span="24"><a href="https://scjys.egongzheng.com/H5/templates/index.html"><Button type="warning" style="width:4rem;">去 办 理</Button></a></Col>
    </Row>
    <div style="padding:0.15rem;"></div>
  </div>
</template>

<script>
export default {
  name:'guild10',
  data() {
    return {

    };
  },
  methods: {

  },
  mounted() {

  },
}
</script>
