<style scoped>
	.header{background-image: url(/img/mediate/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<div class='header'></div>
		<div style="padding:0.3rem">
			<stepMenu :step='1' :menu="['选择地区','基本信息','事项描述']"></stepMenu>
			<div style="padding:0.3rem"></div>
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
        <FormItem label prop>
          <div class="inline-block">
            <span style="color: red;padding: 0 0.1rem;">*</span>机构所在：
          </div>
          <br />
          <div style="text-align: center">
            <FormItem label prop="orgCity">
              <Select v-model="formValidate.orgCity">
                <Option v-for="(value,index) in mediate.loadcity" :value="value">{{ value }}</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea" v-if="mediate.orgArea.length">
              <Select v-model="formValidate.orgArea">
                <Option v-for="(value,index) in mediate.orgArea" :value="value">{{ value }}</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea" v-if="mediate.orgZhen.length">
              <Select v-model="formValidate.orgZhen">
                <Option v-for="(value,index) in mediate.orgZhen" :value="value">{{ value }}</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea" v-if="mediate.hui.length">
              <Select v-model="hui1">
                <Option v-for="(value,index) in mediate.hui" :value="value.code+','+value.mc">{{ value.mc }}</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea" v-if="mediate.ren.length">
              <Select v-model="formValidate.ren">
                <Option v-for="(value,index) in mediate.ren" :value="value">{{ value }}</Option>
              </Select>
            </FormItem>
          </div>
        </FormItem>
			</Form>
		</div>
		<div style="padding: 0.3rem;"></div>
		<div style="text-align: center;">
			<router-link :to="{name:'MUCAS2'}">
			  <Button type="primary" style="width:3rem;">下一步</Button>
			</router-link>
		</div>
	</div>
</template>

<script>
  import stepMenu from '@/components/stepMenu.vue'
  export default {
    name: 'step1',
    data() {
      return {
        hui1 : '',
        mediate: {
          loadcity :[],
          orgArea:[],
          orgZhen:[],
          hui:[],
          ren:[],
        }
      }
    },
    watch: {
      'formValidate.orgCity': function (val) {
        this.formValidate.orgArea = ''
        this.formValidate.orgZhen = ''
        this.formValidate.hui = ''
        this.hui1 = ''
        this.formValidate.ren = ''
        val ? this.mediate1() : ''
      },
      'formValidate.orgArea': function (val) {
        this.formValidate.orgZhen = ''
        this.formValidate.hui = ''
        this.hui1 = ''
        this.formValidate.ren = ''
        val ? this.mediate2() : ''
      },
      'formValidate.orgZhen': function (val) {
        this.formValidate.hui = ''
        this.hui1 = ''
        this.formValidate.ren = ''
        val ? this.mediate3() : ''
      },
      'hui1': function (val) {
        this.formValidate.ren = ''
        val ? this.mediate4() : ''
      },
    },
    methods: {
      mediate1: function () {
        var _this = this
        _this.send({
          url: '/loadarea',
          data: {
            city: _this.formValidate.orgCity,
          },
          success: function (res) {
            _this.mediate.orgArea = res.data
          }
        })
      },
      mediate2: function () {
        var _this = this
        _this.send({
          url: '/loadzhen',
          data: {
            city: _this.formValidate.orgCity,
            area: _this.formValidate.orgArea,
          },
          success: function (res) {
            _this.mediate.orgZhen = res.data
          }
        })
      },
      mediate3: function () {
        var _this = this
        _this.send({
          url: '/loadhui',
          data: {
            city: _this.formValidate.orgCity,
            area: _this.formValidate.orgArea,
            zhen: _this.formValidate.orgZhen,
          },
          success: function (res) {
            _this.mediate.hui = res.data
          }
        })
      },
      mediate4: function (e) {
        var _this = this
        console.log(_this.hui1.split(","))
        _this.formValidate.hui = _this.hui1.split(",")[1]
        _this.send({
          url: '/loadren',
          data: {
            city: _this.formValidate.orgCity,
            area: _this.formValidate.orgArea,
            code: _this.hui1.split(",")[0],
          },
          success: function (res) {
            _this.mediate.ren = res.data

          }
        })
      },
      loadcity : function () {
        var _this = this
        _this.send({
          url : '/loadcity',
          success: function (res) {
            _this.mediate.loadcity = res.data
          }
        })
      },
    },
    beforeRouteLeave: function (to, form, next) {
      if (to.name == 'MUCAS2') {
        this.$refs.formValidate.validate((valid) => {
          if (valid == false) {
            this.$Message.error('请录入完成后再进行下一步操作!');
          } else {
            next()
          }
        })
      } else {
        next()
      }
    },
    mounted() {
      var _this = this
      _this.loadcity()
    },
    components: {
      stepMenu: stepMenu,
    },
    computed: {
      ruleValidate: function () {
        return this.$store.state.mediateData.ruleValidate
      },
      formValidate: function () {
        return this.$store.state.mediateData.formValidate
      },
    },
  }
</script>
