<template>
  <div >
      <div v-if="list">
        <div style="padding:0.10rem"></div>
        <div style="text-align: center;font-size: 0.36rem">{{list.LawStatuteTitle}}</div>
        <div style="text-align: center;font-size: 0.18rem;color: #bbb;">
          推送时间：{{list.LawStatuteTime}} 来源：{{list.LawStatuteSource}}
        </div>
        <div style="padding: 0 0.2rem">
          <div v-html="list.LawStatuteContent" ></div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
name: "articleH",
  data(){
    return{
      list:[],
    }
  },
  mounted(){
    this.setPageSize()
    this.getLawStatuteById()
  },
  methods:{
    getLawStatuteById:function(){
      var _this = this
      _this.send({
        url:'/GetLawStatuteById',
        data:{
          LawStatuteId:_this.$route.query.LawStatuteId,
        },
        success:function(res){
          if(res){
            _this.list = res
          }
        }
      })
    },
    setPageSize:function() {
      var wsw = document.body.clientWidth
      document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
    },
  },
}
</script>

<style scoped>

</style>
