<style scoped>
  .home{background-color: rgb(241,239,240);position: absolute;width: calc(100% - 240px);height: calc(100% - 120px);overflow: auto;}
  .screen{width: calc(100% - 80px);display: inline-block;}
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: #e63b3b;padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: #e63b3b;}
  .screen :deep(.ivu-tabs-ink-bar){background-color: #e63b3b;}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .house{width: 72px;height: 72px;margin-top: 29px;}
  .number{font-size: 48px;color: #7a7a7a;}
  .tipsName{font-size: 16px;color: #8d8d8d;}
  .displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}
  .administration{width: 100%;background-color: #fff;margin-top: 40px;border-bottom: 1px solid #dddddd;height: 80px;text-align: left;margin-bottom: 0}
  .administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
  .label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
  .forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;margin:40px 40px 0 40px;height: 60px;}
  .forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
  .forMcontent{width: calc(100% - 80px);height: 60px;color: #666666;font-size: 16px;margin:0px 40px 0 40px;border-bottom: 1px solid #dddddd;text-align: center;}
  .forMcontent p {line-height: 60px;display: inline-block;width: 20%;overflow: hidden;white-space: nowrap;}
  .smallProgram{width: 32px;height: 32px;vertical-align: top;margin-top: 14px;}
  .alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -23px;}
  .alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}
  .qrCode{width: 100px;height: 100px;}
  .addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
  .myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}
  .icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}
  .contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
  .single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
  .inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}

  /*表格*/
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align: left;}
</style>

<template>
  <div class="home">
    <!--<HelloWorld>-->

    <!--</HelloWorld>-->
    <div style="background-color: rgb(241,239,240);">
      <!--<div class="screen">-->
      <!--<div>本月 </div>-->
      <!--</div>-->
      <div style="background-color: white;margin:20px;">
        <div style="padding:15px"></div>
        <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 总统计数</div>
        <div style="font-size: 16px;text-align: left;margin: 10px 0 0 20px;">
          <!---->
          <DatePicker :transfer="transfer" type="daterange" placement="bottom-end" placeholder="选择时间" style="width: 200px" @on-change="totalTime"></DatePicker>
        </div>
        <div style="white-space:nowrap;">
          <div class="displayData" v-for="(value,index) in statisticsData" :key="index" :style="{'margin-left':(index==0?'0':'2%')}" v-if="index != 3" @click="orderTypeCount(value.type)">
            <!--<img class="house" :src="/img/house.png">-->
            <img class="house" :src="'/img/house'+''+index+'.png'">
            <p class="number">{{value.value}}</p>
            <p class="tipsName">{{value.name}}</p>
          </div>
          <div class="displayData" v-for="(value,index) in statisticsData" :key="index" :style="{'margin-left':(index !=3?'0':'2%'),'cursor': 'pointer'}" v-if="index == 3" @click="modal3 = true;orderTypeCount(value.type)">
            <!--<img class="house" :src="/img/house.png">-->
            <img class="house" :src="'/img/house'+''+index+'.png'">
            <p class="number">{{value.value}}</p>
            <p class="tipsName">{{value.name}}</p>
          </div>

        </div>
        <!--<Tabs v-model="boxt" class="screen"  @on-click="switchData">-->
        <!--<TabPane :label="time.name"  v-for="(time,i) in timelist">-->
        <!---->

        <!--</TabPane>-->
        <!--</Tabs>-->
        <div id="allDataView" style="height: 400px;"></div>
      </div>
      <div style="background-color: white;margin:20px;">
        <div style="padding:15px"></div>
        <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 昨日数据</div>
        <Row style="font-size:14px;height:180px" type="flex" justify="center" align="middle">
          <Col span="6" style="border-right:1px solid rgb(209, 209, 209)">
            <Row><Col>服务社区（村）</Col></Row>
            <div style="padding:10px"></div>
            <Row><Col style="color:red">{{yesterdayData.village}}</Col></Row>
          </Col>
          <Col span="6" style="border-right:1px solid rgb(209, 209, 209)">
            <Row><Col>服务律师</Col></Row>
            <div style="padding:10px"></div>
            <Row><Col style="color:red">{{yesterdayData.lawyer}}</Col></Row>
          </Col>
          <Col span="6" style="border-right:1px solid rgb(209, 209, 209)">
            <Row><Col>服务用户</Col></Row>
            <div style="padding:10px"></div>
            <Row><Col style="color:red">{{yesterdayData.user}}</Col></Row>
          </Col>
          <Col span="6">
            <Row><Col>咨询次数</Col></Row>
            <div style="padding:10px"></div>
            <Row><Col style="color:red">{{yesterdayData.order}}</Col></Row>
          </Col>
        </Row>
      </div>
      <div style="background-color: white;margin:20px;">
        <div style="padding:15px"></div>
        <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 十日新增数据</div>
        <div id="myChart1" style="height:400px"></div>
        <div style="padding:15px"></div>
      </div>
      <div style="background-color: white;margin:20px;">
        <div style="padding:15px"></div>
        <div style="position: relative">
          <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 地图分布</div>
          <div id="mapChart" :style="{width: '100%', height: '600px'}"></div>
          <div v-if="map.mapHistory.length>1&&map.mapLodingState==false" style="cursor:pointer;padding:5px 15px;position:absolute;top:80px;left:40px;z-index:999;background-color:#29c8ff;font-size:14px;color:#fff;border-radius:5px" @click="backMap">返回</div>
          <div ref=childPanel style="color:#fff;font-size:14px;padding:15px;border-radius:5px;background-color:rgba(0,0,0,0.5);position:absolute;display:none;">
            <div v-for="(item,index) in map.childData">{{item.name}}:{{item.value}}</div>
          </div>
        </div>
        <div style="padding:15px"></div>
      </div>
      <div style="background-color: white;margin:20px;">
        <div style="padding:15px"></div>
        <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 用户属性</div>
        <Row style="font-size:16px">
          <Col span="12">
            <div>性别分布</div>
            <Row type="flex" justify="center" align="middle">
              <Col span="14"><div style="height:400px" id="sexDistributed"></div></Col>
              <Col span="10">
                <Row v-for="(item,index) in sexDistributedList" style="font-size:14px">
                  <Col span="8" style="text-align: right">{{item.key}}</Col>
                  <Col span="12" style="text-align: left">：{{item.value}}</Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span="12">
            <div>年龄分布</div>
            <div style="padding:10px;"></div>
            <Row type="flex" justify="center" align="middle">
              <Col span="16"><div style="height:400px" id="ageDistributed"></div></Col>
              <Col span="8">
                <Row v-for="(item,index) in ageDistributedList" style="font-size:14px">
                  <Col span="12" style="text-align: right">{{item.key}}</Col>
                  <Col span="8" style="text-align: left">：{{item.value}}</Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style="padding:15px"></div>
      </div>
      <div style="background-color: white;margin:20px;" v-if="information.AdminId == 0">
        <div style="padding:15px"></div>
        <div style="font-size:16px;text-align: left;margin-left:20px;border-left:solid #de4f0b 3px;padding-left:5px"> 同时在线人数:{{getOnlineNum}}</div>
        <Row>
          <Col span="12">
            <!--<Row  type="flex" justify="center" align="middle">-->
            <!--<Col span="12"><div style="height:400px;" id="devicesDistributed"></div></Col>-->
            <!--<Col span="12">-->
            <!--<Row v-for="(item,index) in equipment" style="font-size:14px">-->
            <!--<Col span="8" style="text-align: right">{{item.name}}</Col>-->
            <!--<Col span="12" style="text-align: left">：{{item.value}}</Col>-->
            <!--</Row>-->
            <!--</Col>-->
            <!--</Row>-->
          </Col>
          <Col span="12">
            <!--<Row  type="flex" justify="center" align="middle">-->
            <!--<div style="font-size: 16px" >同时在线人数:{{getOnlineNum}}</div>-->
            <!--</Row>-->
          </Col>
        </Row>
        <div style="padding:15px"></div>
      </div>
      <!--律所管理律师列表-->
      <div v-if="information.adminLevel == 4">
        <div class="font-size" style="width: calc(100% - 80px);display: inline-block;">
          <div class="administration">
            <img class="label" src="/img/label.png">
            <p>管理</p>
            <div class="addTo" @click="modal1 = true">添加</div>
          </div>
          <div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;">
            <div class="forMtitle" v-if="listData != []">
              <p>律师</p>
              <p>电话</p>
              <p>小程序码</p>
              <p>评分</p>
              <p>操作</p>
            </div>
            <!--for-->
            <div class="forMcontent" v-for="(value,index) in listData" :key="index">
              <p>{{value.LawyerName}}</p>
              <p>{{value.LawyerPhone}}</p>
              <!--<div style="position: relative;line-height: 60px;display: inline-block;width: 20%;vertical-align: top">-->
              <!--<img class="smallProgram" @click="alertbox(value.LawyerQRcode,value.LawyerName)" src="/img/smallProgram1.png">-->
              <!--<div >发送</div>-->
              <!--</div>-->
              <div style="color: #00b86c;cursor:pointer;width: 20%;display: inline-block;line-height: 60px;vertical-align: top;overflow: hidden;white-space: nowrap;">
                <img class="smallProgram" @click="alertbox(value.LawyerQRcode,value.LawyerName)" src="/img/smallProgram1.png">
                <div style="display: inline-block;margin: 0 8%"></div>
                <Button type="success" @click="alertbox1(value.LawyerQRcode,value.LawyerName,value.LawyerId,value.LawyerPhone)">发送</Button>
                <!--value.LawyerId,value.LawyerPhone-->
              </div>
              <p v-if="value.avgGrade">{{value.avgGrade}}</p>
              <p v-else>暂无评分</p>
              <div style="color: #00b86c;cursor:pointer;width: 20%;display: inline-block;line-height: 60px;vertical-align: top;overflow: hidden;white-space: nowrap;">
                <Button type="success" style="margin: 0 10px" @click="deleteLawyer(value.LawyerId)">删除</Button>
                <Button type="success" style="margin: 0 10px" @click="modal4 = true;lawyerId = value.LawyerId">更换律师</Button>
              </div>
              <!--<p style="color: #00b86c;cursor:pointer" @click="deleteLawyer(value.LawyerId)">删除</p>-->
            </div>
          </div>
        </div>
        <div style="text-align: left;margin-left: 40px;font-size: 16px">
          <!--<Page :total="count" page-size="5"  show-elevator />-->
          <!--<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>-->
          <Page
                  :total="count"
                  :page-size="pageSize"
                  show-elevator
                  @on-change="page = $event;lawList()"
          />
        </div>
      </div>
      <!--管理律师列表-->
      <div v-if="information.adminLevel == 3">
        <div class="font-size" style="width: calc(100% - 80px);display: inline-block;">
          <div class="administration">
            <img class="label" src="/img/label.png">
            <p>管理</p>
            <div class="addTo" @click="modal2 = true">添加</div>
          </div>
          <div style="background-color: #fff;width: 100%;display: inline-block;margin-bottom: 40px;">
            <div class="forMtitle" v-if="listData != []">
              <p>机构名称</p>
              <p>电话</p>
              <p>登录名</p>
              <p>密码</p>
              <p>操作</p>
            </div>
            <!--for-->
            <div class="forMcontent" v-for="(value,index) in listData" :key="index">
              <p>{{value.OrganizationName}}</p>
              <p>{{value.OrganizationPhone}}</p>
              <p>{{value.AdminLogin}}</p>
              <div style="position: relative;line-height: 60px;display: inline-block;width: 20%">
                <p>{{value.AccountPassword}}</p>
                <!--弹出-->
                <div class="alertCode" v-if="arCode&&index==current">
                  <!---->
                  <p>{{value.LawyerName}}</p>
                  <div style="width: 100%;position: absolute;top: 36px;">
                    <img class="qrCode" :src="value.LawyerQRcode">
                  </div>
                </div>
              </div>
              <p style="color: #00b86c;cursor:pointer" @click="deleteLawFirm(value.AdminId,value.OrganizationId)">删除</p>
            </div>
          </div>
        </div>
        <div style="text-align: left;margin-left: 40px;font-size: 16px">
          <!--<Page :total="count" page-size="5"  show-elevator />-->
          <!--<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>-->
          <Page
                  :total="count"
                  show-total
                  :page-size="pageSize"
                  show-elevator
                  @on-change="page = $event;lawList()"
          />
        </div>
      </div>
      <!---->
    </div>
    <!--弹出-->
    <Modal
            v-model="modal1"
            title="添加"
            @on-ok="ok"
            @on-cancel="cancel">
      <div class="single">
        <p class="contacts">律师名称</p>
        <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName">
      </div>
      <div class="single">
        <p class="contacts">律师电话</p>
        <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone">
      </div>
    </Modal>
    <!--更换律师-->
    <Modal
            v-model="modal4"
            title="更换律师"
            @on-ok="replace();"
            @on-cancel="cancel">
      <div class="single">
        <p class="contacts">律师名称</p>
        <input type="text" class="inputWord" placeholder="请输入律师名" v-model="lawyerName">
      </div>
      <div class="single">
        <p class="contacts">律师电话</p>
        <input type="text" class="inputWord" placeholder="请输入律师电话" v-model="lawyerPhone">
      </div>
    </Modal>
    <!---->
    <Modal
            v-model="modal2"
            title="添加"
            @on-ok="addMember"
            @on-cancel="cancel">

      <div class="single">
        <p class="contacts">村居律所名称</p>
        <input type="text" class="inputWord" placeholder="请输入村居律所名称" v-model="organizationName">
      </div>
      <div class="single">
        <p class="contacts">联系人</p>
        <input type="text" class="inputWord" placeholder="请输入联系人" v-model="organizationLinkman">
      </div>
      <div class="single" v-if="cityList.length != 0">
        <p class="contacts">地址</p>
        <div style="display: inline-block">
          <Select v-model="model8" style="width:200px" @on-change="choice" :label-in-value="labelInValue" :placeholder="tipsWord">
            <Option  v-for="(item,index) in cityList" :value="item.AreaId +','+ index  +','+ '1'" :label="item.AreaName" :key="index">{{ item.AreaName }}</Option>
          </Select>
          <Select v-model="model9" style="width:200px" @on-change="choice" :label-in-value="labelInValue" v-if="areaData" :placeholder="tipsWord">
            <Option v-for="(i,index) in areaData" :value="i.AreaId +','+ index +','+ '2'" :label="i.AreaName" :key="index">{{ i.AreaName }}</Option>
          </Select>
        </div>
      </div>
      <div class="single">
        <p class="contacts">联系电话</p>
        <input type="text" class="inputWord" placeholder="请输入联系电话" v-model="organizationPhone">
      </div>
      <div class="single">
        <p class="contacts">登录名称</p>
        <input type="text" class="inputWord" placeholder="请输入登录名" v-model="adminLogin">
      </div>
      <div class="single">
        <p class="contacts">登录密码</p>
        <input type="text" class="inputWord" placeholder="请输入登录密码" v-model="adminPassword">
      </div>
    </Modal>
    <Modal
            v-model="modal3"
            title="服务次数"
            @on-ok=""
            @on-cancel="cancel">
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>服务类型</th>
            <th>服务次数</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in orderTypeCountData">
            <td>{{value.OrderTypeTitle}}</td>
            <td v-if="value.OrderTypeTitle == '人工咨询'">{{value.count}}
              <Button style="background-color:  rgb(243, 133, 4);color: #fff" @click="modal12 = true;orderTypeCount(5);lawyerList()">详情</Button>
            </td>
            <td v-else-if="value.OrderTypeTitle == '自助咨询'">{{value.count}}
              <Button style="background-color:  rgb(243, 133, 4);color: #fff" type="warning" @click="robot()">案由排名</Button>
            </td>
            <td v-else>{{value.count}}
              <Button style="background-color:  rgb(243, 133, 4);color: #fff" @click="detailsSee(value.OrderTypeId,value.OrderTypeTitle);modal17 = true">详情</Button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </Modal>
    <!--服务次数剩下的详情-->
    <Modal
            v-model="modal17"
            @on-ok=""
            @on-cancel="cancel"
            width="650">
      <p slot="header" style="color:#17233d;text-align:left">
        <span>{{serviceName}}</span>
        <span style="margin-left: 30px">共{{count}}条</span>
      </p>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>用户</th>
            <th>律师名称</th>
            <th>社区</th>
            <th>咨询时间</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in detailsSeeLIst">
            <td><Avatar :src="value.UserImg" /> {{value.UserName}}</td>
            <td>{{value.LawyerName}}</td>
            <td>{{value.VillagesName}}</td>
            <td>{{value.OrderCreatedTime}}</td>

          </tr>
          </tbody>
        </table>

        <Page
                :total="count"
                show-total
                :page-size="pageSize"
                show-elevator
                @on-change="page = $event;detailsSee(serviceType,serviceName)"
        />
      </div>
    </Modal>
    <!--人工咨询详情-->
    <Modal
            v-model="modal12"
            title="人工咨询详情"
            @on-ok=""
            @on-cancel="cancel"
            width="650">
      <p slot="header" style="color:#17233d;text-align:left">
        <span>人工咨询详情</span>
        <span style="margin-left: 30px">共{{count}}条</span>
      </p>
      <div class="publicWelfare">
        <DatePicker type="daterange" split-panels placeholder="请选择时间" style="width: 200px" @on-change="totalTime"></DatePicker>
        <Select v-model="model1" style="width:200px" @on-change="lawyerClick" filterable clearable >
          <Option v-for="(value,index) in lawyerData" :value="value.LawyerId" :key="value.LawyerName" >{{ value.LawyerName }}</Option>
        </Select>
        <table class="formtable">
          <thead>
          <tr>
            <th>用户</th>
            <th>律师名称</th>
            <th>社区</th>
            <!--<th>用户号码</th>-->
            <th>咨询时间</th>
            <th>状态</th>
            <th>处理状态</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in UserOrderLIst">
            <td><Avatar :src="value.user.UserImg" /> {{ value.user.UserName }}</td>
            <td>{{ value.lawyer.LawyerName }}</td>
            <td>{{ value.Villages.CityName }}{{ value.Villages.street }}{{ value.Villages.VillagesName }}</td>
            <!--<td>{{value.UserPhone}}</td>-->
            <td>{{value.OrderCreatedTime}}</td>
            <td>{{value.state}}</td>
            <td>{{value.isReply}}</td>
          </tr>
          </tbody>
        </table>

        <Page
                :total="count"
                show-total
                :page-size="pageSize"
                show-elevator
                @on-change="page = $event;orderTypeCount(5)"
        />
      </div>
    </Modal>
    <!--案由排名-->
    <Modal
            v-model="modal13"
            title="咨询案由排名"
            @on-ok=""
            @on-cancel="cancel" width="600">
      <div class="publicWelfare">
        <div id="robot" style="height:600px;width: 100%"></div>
      </div>
    </Modal>
    <!--服务社区（村）数量-->
    <Modal
            v-model="modal10"
            title="服务社区（村）数量"
            @on-ok=""
            @on-cancel="cancel"
            width="750">
      <p slot="header" style="color:#17233d;text-align:left">
        <span>服务社区（村）数量</span>
        <span style="margin-left: 30px">共{{count}}条</span>
      </p>
      <div class="publicWelfare">
        <Row>
          <Col span="12">
            <Input v-model="search" search enter-button placeholder="搜索社区（村）名称" @on-search="searchDara"/>
          </Col>
          <Col span="12" style="text-align: center">
            <Button type="primary" v-if="sort == 2" @click="sortClick('')">用户量(升序)</Button>
            <Button type="primary" v-else="" @click="sortClick(2)">用户量(降序)</Button>
          </Col>

        </Row>
        <table class="formtable">
          <thead>
          <tr>
            <th>律师名称</th>
            <th>服务社区（村）</th>
            <th>创建时间</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in adminVillagesData">
            <td><Avatar :src="value.LawyerImg" /> {{value.LawyerName}}</td>
            <td>{{value.CityName}}{{value.VillagesName}}<samp style="padding: 10px;"></samp>用户数量：（{{value.UserCount}}）</td>
            <td>{{value.CreatTime}}</td>
          </tr>
          </tbody>
        </table>
        <Page
                :total="count"
                show-total
                :page-size="pageSize"
                show-elevator
                @on-change="page = $event;orderTypeCount(1)"
        />
      </div>

    </Modal>
    <!--服务社区（村）数量-->
    <Modal
            v-model="modal11"
            title="用户人数"
            @on-ok=""
            @on-cancel="cancel">
      <p slot="header" style="color:#17233d;text-align:left">
        <span>用户人数</span>
        <span style="margin-left: 30px">共{{count}}条</span>
      </p>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>用户</th>
            <th>社区</th>
            <!--<th>用户号码</th>-->
            <th>注册时间</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in adminUserData">
            <td><Avatar :src="value.UserImg" /> {{value.UserName}}</td>
            <td>{{ value.village.CityName.replace('重庆市', '') }}{{ value.village._AdminName }}{{ value.village.VillagesName }}</td>
            <!--<td>{{value.UserPhone}}</td>-->
            <td>{{value.CreatTime}}</td>
          </tr>
          </tbody>
        </table>
        <Page
                :total="count"
                :page-size="pageSize"
                show-elevator
                show-total
                @on-change="page = $event;orderTypeCount(2)"
        />
      </div>
    </Modal>
    <!---->
    <Modal
            v-model="modal15"
            title="用户人数"
            @on-ok=""
            @on-cancel="cancel" width="530">
      <p slot="header" style="color:#17233d;text-align:left">
        <span>律师人数</span>
        <span style="margin-left: 30px">共{{count}}条</span>
      </p>
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <th>律师名称</th>
            <th>所属律所</th>
            <th>电话</th>
            <th>评分</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in adminLawyerList">
            <td><Avatar :src="value.LawyerImg" /> {{value.LawyerName}}</td>
            <td>{{value.organization.OrganizationName}}</td>
            <td>{{value.LawyerPhone}}</td>
            <td v-if="value.avgs == 0">暂无</td>
            <td v-else="">{{Math.floor(value.avgs * 100) / 100}}</td>
          </tr>
          </tbody>
        </table>
        <Page
                :total="count"
                :page-size="pageSize"
                show-elevator
                show-total
                @on-change="page = $event;orderTypeCount(3)"
        />
      </div>
    </Modal>
    <!---->
    <Modal
            v-model="modal8"
            title="案由"
            @on-ok=""
            @on-cancel="cancel" width="530">
      <div class="publicWelfare">
        <table class="formtable">
          <thead>
          <tr>
            <!--<th>案由</th>-->
            <th>社区（村）</th>
            <th>数量</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value,index) in listData">
            <!--<td>{{value.keyword}}</td>-->
            <td>{{value.VillagesName}}</td>
            <td>{{value.nums}}</td>

          </tr>
          </tbody>
        </table>
      </div>
    </Modal>
    <!--弹窗img-->
    <Modal
            v-model="modal5"
            :title="lawName+'的太阳码'"
            @on-ok="downloadUrl"
            @on-cancel="cancel"
            ok-text="下载"
            cancel-text="关闭"
            width="350">
      <div style="text-align: center">
        <img v-if="modal5" :src="urlImg" style="width: 300px;height: 300px">
      </div>
    </Modal>
    <!--弹窗发送-->
    <Modal
            v-model="modal6"
            :title="lawName+'的太阳码'"
            @on-ok="sendOut"
            @on-cancel="cancel"
            ok-text="发送"
            cancel-text="关闭"
            width="350">
      <div style="text-align: center">
        <p style="font-size: 16px">是否发送律师小程序码至手机</p>
        <img v-if="modal6" :src="urlImg" style="width: 300px;height: 300px">
      </div>
    </Modal>
  </div>
</template>

<script>
  import echarts from 'echarts'
  import axios from 'axios'
  // import HelloWorld from '../../components/HelloWorld.vue'
  export default {
    name: "home",
    // components: {HelloWorld,},
    data () {
      return {
        map:{
          mapChart:false,
          mapLodingState:false,
          mapName:"中国",
          mapHistory:[],
          mapData:[
            {
              name:'四川省',
              value:395,
              type:[{name:'服务社区（村）数量',value:152,},{name:'律师数量',value:30,},{name:'咨询数量',value:80,},{name:'用户数量',value:80,},]
            },
            {
              name:'贵州省',
              value:98,
              type:[{name:'服务社区（村）数量',value:12,},{name:'律师数量',value:50,},{name:'咨询数量',value:30,},{name:'用户数量',value:30,},]
            },
            {
              name:'云南省',
              value:15,
              type:[{name:'服务社区（村）数量',value:12,},{name:'律师数量',value:70,},{name:'咨询数量',value:20,},{name:'用户数量',value:20,},]
            },
          ],
          childData:[
            {name:'服务社区（村）数',value:152,},
            {name:'律师人数',value:30,},
            {name:'用户人数',value:30,},
            {name:'咨询次数',value:80,},
          ],
        },
        yesterdayData:{
          village:'',
          lawyer:'',
          user:'',
          order:'',
        },
        ageDistributedList:[],
        devicesDistributedList:[],
        sexDistributedList:[],
        areaData: null,
        labelInValue : true,
        value2: ['0', '1', '2'],
        addressData : [],
        model8: '',
        model9: '',
        cityList: [],
        ElectronicsList: [],
        tipsWord: '请选择地址',
        boxt : 0,
        modal1: false,
        modal2: false,
        modal3: false,
        modal4: false,
        modal5: false,
        modal6: false,
        modal17: false,
        modal8: false,
        orderTypeCountData : [],
        listData : [],
        arCode : false,//二维码
        current : '',
        lawyerPhone : '',
        lawyerName : '',
        information :[],
        page : 1,
        pageSize : 15,
        count : 0,
        typeId : 1,
        adminLogin : '',
        adminPassword : '',
        organizationPhone : '',
        organizationLinkman : '',
        organizationName : '',
        urlImg : '',
        lawName : '',
        lawyerId1 : '',
        lLawyerPhone : '',
        AreaId : '',
        statisticsData : [
          {name : '服务社区（村）数',value:'',type : '1'},
          {name : '律师人数',value:'',type : '3'},
          {name : '用户人数',value:'',type : '2'},
          {name : '咨询次数',value:'',type : '4'}
        ],
        timelist : [
          {name:'总计',value:4},
          {name:'本月',value:1},
          {name:'本季',value:2},
          {name:'本年',value:3},
        ],
        //
        modal10 : false,
        adminVillagesData : [],
        //
        modal11 : false,
        modal12 : false,
        modal13 : false,
        modal15 : false,
        adminLawyerList : [],
        adminUserData : [],
        equipment : [],
        UserOrderLIst : [],
        getOnlineNum : '',

        start_time : '',//开始时间
        end_time : '',//结束时间
        nameKeyId : '',
        lawyerData : [],
        model1 : '',
        startTime : '',//开始时间
        endTime : '',
        transfer : true,
        //
        search : '',
        model2 : '',
        sort : '',
        detailsSeeLIst : [],
        serviceType : '',
        serviceName : '',


        clickname : '',
        sysName : '',
      }
    },

    mounted () {
      var _this = this
      _this.map.mapName = window.sessionStorage.AreaName
      _this.getMapGeo()
      _this.sexDistributed()
      _this.ageDistributed()
      _this.allDataView()
      // _this.devicesDistributed()
      _this.newlyDataView()
      _this.getYesterdayData()
      _this.onLine()
      if(window.sessionStorage.adminLevel == undefined){
        // this.$router.push({name:'login'})
      }else {
        // var data = JSON.parse(window.sessionStorage.information)
        // SYSCONFIG.server.information = JSON.parse(window.sessionStorage.information)
        // console.log(SYSCONFIG.server.information.AdminLogin)
        this.information = window.sessionStorage
        if(_this.information.adminLevel == 4){
          _this.lawList()
          console.log(this.information)
        }
        if(_this.information.adminLevel == 3){
          _this.adminList()
          console.log(this.information)
        }
        _this.switchData(0)
        _this.getAreaData()
      }
      _this.sysName = window.sessionStorage.sysName
    },
    methods:{
      onLine : function(){
        var _this = this
        axios({
          url: 'https://cj.lvwa18.com:6003/cj/getOnlineNum',
          method: 'get',
          data: { AdminId: window.sessionStorage.AdminId,}
        }).then(res => {
          _this.getOnlineNum = res.data
          console.log('请求结果：', res.data);
        });
      },
      getYesterdayData:function(){
        var _this = this
        _this.send({
          url:'/YesterdayGetCount',
          data:{
            AdminId:window.sessionStorage.AdminId
          },
          success:function (res) {
            _this.yesterdayData = {
              village:res.villages_sql,
              lawyer:res.lawyer_count,
              user:res.user_count,
              order:res.order_count,
            }
          }
        })
      },
      allDataView:function(){
        var _this = this
        let allDataDom = this.$echarts.init(document.getElementById("allDataView"));
        _this.send({
          url: '/GetCount',
          data: {
            AdminId: window.sessionStorage.AdminId,
          },
          success: function (res) {
            var time = []//时间
            var order_count = []//咨询数量
            var lawyer_count = []//律师数量
            var user_count = []//用户数量
            var villages_sql = []//创建村数量
            for (var i in res) {
              // for (var i = 19;i< res.list.length;i++) {
              time.push(res[i].time)
              order_count.push(res[i].order_count)
              lawyer_count.push(res[i].lawyer_count)
              user_count.push(res[i].user_count)
              villages_sql.push(res[i].villages_sql)
            }
            // 绘制图表
            allDataDom.setOption({
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross"
                }
              },
              xAxis: {
                type: "category",
                axisTick: {
                  alignWithLabel: true
                },
                data: time
              },
              legend: {
                data: ["服务社区（村）数", "用户人数", "律师人数", "咨询次数"],
                left: "center",
                // top: 50
              },
              grid: {
                // top: 100
              },
              yAxis: [
                {
                  type: "value"
                }
              ],
              series: [
                {
                  name: "服务社区（村）数",
                  type: "line",
                  data: villages_sql
                },
                {
                  name: "用户人数",
                  type: "line",
                  data: user_count
                },
                {
                  name: "律师人数",
                  type: "line",
                  data: lawyer_count
                },
                {
                  name: "咨询次数",
                  type: "line",
                  data: order_count
                }
              ]
            });
          }
        })
      },
      devicesDistributed:function(){
        var _this = this
        _this.send({
          url:'/UserPhoneTypeCount',
          data:{
            AdminId:window.sessionStorage.AdminId
          },
          success: function (res) {
            var title = []
            var data = []
            _this.equipment = []
            for(var index in res){
              title.push(res[index].title)
              data.push({name:res[index].title,value:res[index].total})
              if(res[index].PhoneType == null){
                _this.equipment.push({
                  // key:res[index].PhoneType,
                  name: '未知',
                  value:res[index].user_count
                })
              }
              if(res[index].PhoneType == '1'){
                _this.equipment.push({
                  name: 'Android',
                  value:res[index].user_count
                })
              }if(res[index].PhoneType == '2'){
                _this.equipment.push({
                  name: 'Iphone',
                  value:res[index].user_count
                })
              }if(res[index].PhoneType == '3'){
                _this.equipment.push({
                  name: '未知',
                  value:res[index].user_count
                })
              }
            }
            var devicesDom = echarts.init(document.getElementById('devicesDistributed'))
            var option = {
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
              },
              legend: {top:40},
              series: [
                {
                  name: '访问设备',
                  type: 'pie',
                  radius: ['50%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: 'center'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: '24',
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: false
                  },
                  data: _this.equipment
                }
              ]
            }
            devicesDom.setOption(option, true);
          }
        })

      },
      ageDistributed:function(){
        var _this = this
        _this.send({
          url:'/UserAgeCount',
          data:{
            AdminId:window.sessionStorage.AdminId
          },
          success:function (res) {
            var title = []
            var data = []
            _this.ageDistributedList = []
            for(var index in res){
              title.push(res[index].title)
              data.push({name:res[index].title,value:res[index].total})
              _this.ageDistributedList.push({key:res[index].title,value:res[index].total})
            }
            var ageDom = echarts.init(document.getElementById('ageDistributed'))
            var option = {
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
              },
              legend: {},
              series: [
                {
                  name: '年龄分布',
                  type: 'pie',
                  radius: ['50%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: 'center'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: '24',
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: false
                  },
                  data: data
                }
              ]
            }
            ageDom.setOption(option, true);
          }
        })

      },
      sexDistributed:function(){
        var _this = this
        var sexDom = echarts.init(document.getElementById('sexDistributed'))
        _this.send({
          url:'/UserSexCount',
          data:{
            AdminId:window.sessionStorage.AdminId,
          },
          success:function (res) {
            var sexObj = {}
            _this.sexDistributedList = []
            for(var index in res){
              if(res[index].UserSex==2){
                sexObj.men = res[index].user_count
                _this.sexDistributedList.push({key:'男',value:res[index].user_count})
              }else if(res[index].UserSex == 1){
                sexObj.women = res[index].user_count
                _this.sexDistributedList.push({key:'女',value:res[index].user_count})
              }else{
                sexObj.others = res[index].user_count
                _this.sexDistributedList.push({key:'未知',value:res[index].user_count})
              }
            }
            var option = {
              tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
              },
              legend: {top:40},
              series: [
                {
                  name: '性别分布',
                  type: 'pie',
                  radius: ['50%', '70%'],
                  avoidLabelOverlap: false,
                  label: {
                    show: false,
                    position: 'center'
                  },
                  emphasis: {
                    label: {
                      show: true,
                      fontSize: '24',
                      fontWeight: 'bold'
                    }
                  },
                  labelLine: {
                    show: false
                  },
                  data: [
                    {value: sexObj.men, name: '男'},
                    {value: sexObj.women, name: '女'},
                    {value: sexObj.others, name: '未知'},
                  ]
                }
              ]
            };
            sexDom.setOption(option, true);
          }
        })
      },
      backMap:function(){
        this.map.mapName = this.map.mapHistory[this.map.mapHistory.length-2]
        this.map.mapHistory.length = this.map.mapHistory.length-1
        this.getMapGeo()
      },
      getMapGeo:function(){
        var _this = this
        _this.map.mapLodingState = true
        if(!_this.map.mapChart){
          _this.map.mapChart = echarts.init(document.getElementById('mapChart'))
        }
        _this.map.mapChart.showLoading({
          text: '数据正在加载...',
          textStyle: { fontSize : 30 , color: '#444' },
          effectOption: {backgroundColor: 'rgba(0, 0, 0, 0)'}
        });
        axios.get('https://restapi.amap.com/v3/config/district?parameters',{
          params:{
            key:'82c553750fcb97e01ef7b6e6e581ae79',
            keywords:_this.map.mapName,
            districts:0,
          },
        })
                .then((res)=>{
                  if(!res.data.districts[0]||res.data.districts[0].level=='district'){
                    _this.map.mapChart.hideLoading()
                    _this.map.mapLodingState = false
                    _this.$Message.warning({
                      content:'已经是最详细区域！'
                    })
                  }
                  else{
                    axios.get('/geo-json/'+res.data.districts[0].adcode+'.json')
                            .then((res1)=>{
                              _this.getMapData(res.data.districts[0].adcode)
                                      .then((mapData)=>{
                                        _this.map.mapName = _this.map.mapName
                                        if(_this.map.mapHistory[_this.map.mapHistory.length-1]!=_this.map.mapName){
                                          _this.map.mapHistory.push(_this.map.mapName)
                                        }
                                        _this.loadMap(res1.data)
                                      })
                            })
                  }
                })
      },
      loadMap:function(mapGeo){
        var _this = this
        var myChart = _this.map.mapChart
        echarts.registerMap(_this.map.mapName,mapGeo);
        var mapOption = {
          title: {
            text: _this.sysName + ' 地图分布状况 - '+_this.map.mapName,
            subtext: '鼠标点击可查看下级目录',
            left: 'center',
          },
          visualMap: {
            inRange: {
              color: ['lightskyblue', 'yellow', 'orangered']
            }
          },
          series:[
            {
              type:'map',
              map:_this.map.mapName,
              name: "服务社区（村）数量",
              data:_this.map.mapData
            }
          ]
        }
        myChart.setOption(mapOption, true);
        myChart.on('mousemove',function(params){
          if(!params.data.type){return}
          _this.map.childData = params.data.type || {}
          _this.$refs.childPanel.style.display = 'block'
          _this.$refs.childPanel.style.left = params.event.offsetX+20+'px'
          _this.$refs.childPanel.style.top = params.event.offsetY+20+'px'
        })
        myChart.on('mouseout', function(params) {
          _this.$refs.childPanel.style.display='none'
        })

        if(myChart._$handlers.click){
          myChart._$handlers.click.length = 0;
        }
        myChart.on('click',function(params){
          _this.map.mapName = params.name
          _this.getMapGeo()
        });
        myChart.hideLoading()
        _this.map.mapLodingState = false
      },
      getMapData:function (adcode) {
        var _this = this
        return new Promise((resolve, reject) => {
          _this.send({
            url:'/_GetMapCount',
            data:{
              AreaCode:adcode,
            },
            success:function (res) {
              _this.map.mapData = []
              for(var index in res){
                _this.map.mapData.push({
                  name:res[index].AreaName,
                  value:res[index].villages_count+res[index].lawyer_count+res[index].order_count+res[index].user_count,
                  type:[
                    {name:'服务社区（村）数',value:res[index].villages_count},
                    {name:'律师人数',value:res[index].lawyer_count},
                    {name:'用户人数',value:res[index].user_count},
                    {name:'咨询次数',value:res[index].order_count},
                  ],
                })
              }
              resolve(res)
            }
          })
        })
      },
      newlyDataView:function () {
        var _this = this
        // 新增
        let myChart1 = this.$echarts.init(document.getElementById("myChart1"),"light");
        _this.send({
          url : '/GetManageData',
          data: {
            AdminId : window.sessionStorage.AdminId,
          },
          success: function (res) {
            var time = []//时间
            var order_count = []//咨询数量
            var lawyer_count = []//律师数量
            var user_count = []//用户数量
            var villages_sql = []//创建村数量
            for(var i in res.list.day){
              time.push(res.list.day[i].time)
              order_count.push(res.list.day[i].order_count)
              lawyer_count.push(res.list.day[i].lawyer_count)
              user_count.push(res.list.day[i].user_count)
              villages_sql.push(res.list.day[i].villages_sql)
            }
            myChart1.setOption({
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross"
                }
              },
              // title: { text: "十日新增数据", left: "center" },
              xAxis: {
                type: "category",
                axisTick: {
                  alignWithLabel: true
                },
                data: time
              },
              legend: {
                data: ["服务社区（村）数", "用户人数", "律师人数", "咨询次数"],
                left: "center",
                // top: 50
              },
              grid: {
                // top: 100
              },
              yAxis: [
                {
                  type: "value"
                }
              ],
              series: [
                {
                  name: "服务社区（村）数",
                  type: "bar",
                  data: villages_sql
                },
                {
                  name: "用户人数",
                  type: "bar",
                  data: user_count
                },
                {
                  name: "律师人数",
                  type: "bar",
                  data: lawyer_count
                },
                {
                  name: "咨询次数",
                  type: "line",
                  data: order_count
                }
              ]
            });
          }
        })
      },
      deleteLawyer : function(lawyerId){
        var _this = this
        _this.send({
          url : '/DeleteLawyer',
          confirm:true,
          data: {
            OrganizationId : _this.information.OrganizationId,
            LawyerId : lawyerId,
          },
          success: function (res) {
            console.log(res)
            _this.listData = []
            _this.page = '1'
            _this.lawList()
          }
        })
      },
      lawList :function(){
        var _this = this
        _this.send({
          url : '/LawyerListById',
          data: {
            OrganizationId : _this.information.OrganizationId,
            page: _this.page,
            pageSize : _this.pageSize,
          },
          success: function (res) {
            _this.listData = res.List
            _this.count = res.count

          }
        })
      },
      addMember :function(){
        var _this = this
        console.log(_this.AreaId)
        if(_this.organizationName != ''&&_this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword != ''&& _this.adminLogin != ''){
          if(_this.cityList.length == 0){
            _this.AreaId = _this.information.AreaId
          }
          _this.send({
            url : '/AddMember',
            data: {
              Level : _this.information.adminLevel,
              AdminId : _this.information.AdminId,
              AdminLogin : _this.adminLogin,
              AdminPassword : _this.adminPassword,
              OrganizationLinkman : _this.organizationLinkman,
              OrganizationPhone : _this.organizationPhone,
              OrganizationName : _this.organizationName,
              AreaId : _this.AreaId,
            },
            success: function (res) {
              _this.adminList()

              _this.adminLogin=''
              _this.adminPassword=''
              _this.organizationLinkman=''
              _this.organizationPhone=''
              _this.organizationName =''
              _this.AreaId = ''
              _this.model8 = ''
            }
          })
        }else{
          _this.$Notice.warning({
            title:'系统提示',
            desc:'输入完成后添加',
          })
        }
      },
      ok:function () {
        var _this = this
        var op = _this.information.OrganizationId+'p'+_this.lawyerPhone +'p'+1
        if(_this.lawyerName != '' && _this.lawyerPhone != ''){
          _this.send({
            url : '/getVillageSunCode',
            consulting1:true,
            responseType:"arraybuffer",
            data: {
              OrganizationId : _this.information.OrganizationId,
              scene: op,
              userType : '2',
              page: 'pages/login/login',
              width: '430',
              jpg: 'jpg',
              LawyerName : _this.lawyerName,
              LawyerPhone : _this.lawyerPhone
            },
            success: function (res) {
              _this.listData = []
              _this.page = '1'
              _this.lawList()
            }
          })
        }else {
          _this.$Notice.warning({
            title:'系统提示',
            desc:'名称或电话不能为空',
          })
        }

      },
      cancel () {
        var _this = this
        _this.page = 1
        _this.count = 0
        _this.detailsSeeLIst = []
        _this.listData = []
      },
      enter:function(index){
        console.log(index)
        this.arCode = true
        this.current = index
      },
      alertbox:function(url,name){
        var _this = this
        _this.modal5 = true
        _this.urlImg = url
        _this.lawName = name
      },
      alertbox1:function(url,name,lawyerId1,lLawyerPhone){
        var _this = this
        _this.modal6 = true
        _this.urlImg = url
        _this.lawName = name
        _this.lawyerId1 = lawyerId1
        _this.lLawyerPhone = lLawyerPhone
      },
      downloadUrl : function(){
        var _this = this
        _this.modal5 = false
        // window.URL = _this.urlImg
        var downloadIamge = function(imgsrc, name) { //下载图片地址和图片名
          let image = new Image();
          // 解决跨域 Canvas 污染问题
          image.setAttribute("crossOrigin", "anonymous");
          image.onload = function() {
            let canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            let context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, image.width, image.height);
            let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
            let a = document.createElement("a"); // 生成一个a元素
            let event = new MouseEvent("click"); // 创建一个单击事件
            a.download = name || "photo"; // 设置图片名称
            a.href = url; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
          };
          image.src = imgsrc;
        }
        downloadIamge(_this.urlImg, _this.lawName+".jpg");
      },
      leave(){
        this.arCode = false
        this.current = null
      },
      //    统计
      switchData : function (e) {
        var _this = this
        _this.typeId = _this.timelist[e].value
        _this.send({
          url : '/GetOrganizationCount',
          data: {
            AdminId : window.sessionStorage.AdminId,
            typeId : _this.typeId,
            start_time : _this.startTime ,//开始时间
            end_time : _this.endTime
          },
          success: function (res) {
            _this.statisticsData[0].value = res.villages_count
            _this.statisticsData[1].value = res.lawyer_count
            _this.statisticsData[2].value = res.user_count
            _this.statisticsData[3].value = res.order_count
          }
        })
      },
      //    管理列表
      adminList : function () {
        var _this = this
        _this.send({
          url : '/AdminList',
          data: {
            AdminId : _this.information.AdminId,
          },
          success: function (res) {
            console.log(res)
            _this.listData = res
            _this.count = res.count
          }
        })
      },
      //    机器人咨询TOP30
      robot : function(){
        var _this = this
        _this.modal13 = true
        this.$nextTick(() => {
          this.robotImplement()
        })
      },
      robotImplement : function(){
        var _this = this
        let myChart1 = this.$echarts.init(document.getElementById("robot"),"light");
        _this.send({
          url : '/keyword_click',
          data: {
            AdminId : _this.information.AdminId,
          },
          success: function (res) {
            var click_count = []//数量
            var keyword = []//名称
            var gid = []//名称

            var compare = function (prop) {
              return function (obj1, obj2) {
                var val1 = obj1[prop];
                var val2 = obj2[prop];
                if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
                  val1 = Number(val1);
                  val2 = Number(val2);
                }
                if (val1 < val2) {
                  return -1;
                } else if (val1 > val2) {
                  return 1;
                } else {
                  return 0;
                }
              }
            }
//调用方式
            res.sort(compare("click"))
            console.log(res.sort(compare("click")))
            // for(var i=res.length-1;i>=0;i--){
            //     click_count.push(res[i].click)
            //     keyword.push(res[i].keyword)
            // }
            for(var i in res){
              click_count.push(res[i].click)
              keyword.push(res[i].keyword)
              gid.push(res[i].gid)
            }
            console.log(keyword)
            // myChart1.on('click', params => {
            //     console.log(params)
            // })
            myChart1.getZr().on('click', params => {
              // console.log(clickname.name)
              // console.log(gid[clickname.dataIndex])
              _this.send({
                url : '/keyword_details',
                data: {
                  AdminId : window.sessionStorage.AdminId,
                  gid : gid[clickname.dataIndex],
                  keyword : clickname.name ,//开始时间
                },
                success: function (res) {
                  _this.listData = res
                  _this.modal8 = !_this.modal8
                }
              })
            })
            var clickname
            var clickid
            myChart1.setOption({
              title: {
                text: '关键字点击量TOP30',
                subtext: '案由TOP30'
              },
              tooltip: {
                trigger: 'axis',
                formatter:function(params){
                  for(var i in params){
                    clickname = params[i]

                    // return params[i].name+','+ gid[i]
                  }
                  for(var i in gid){
                    clickid = gid[i]
                    // console.log(gid[i])
                  }
                },
                axisPointer: {
                  type: 'shadow'
                }
              },
              legend: {
                data: ['关键字']
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01],
              },
              yAxis: {
                type: 'category',
                data:keyword
              },
              series: [
                {
                  label: {
                    show: true,
                    position: 'right'
                  },
                  name: gid,
                  type: 'bar',
                  data:click_count
                }
              ]
            });
          }
        })
      },
      //    服务村
      orderTypeCount : function (type) {
        console.log(type)
        if(type == 1){
          var _this = this
          _this.modal10 = true
          _this.adminVillagesData = []
        //   删除接口
        }
        if(type == 2){
          var _this = this
          _this.modal11 = true
          _this.send({
            url : '/AdminUserList',
            data: {
              AdminId : _this.information.AdminId,
              typeId : _this.typeId,
              page : _this.page,
              pageSize : _this.pageSize,
              start_time : _this.startTime ,//开始时间
              end_time : _this.endTime
            },
            success: function (res) {
              _this.adminUserData = res.data
              _this.count = res.total
            }
          })
        }
        if(type == 3){
          var _this = this
          _this.modal15 = true
          _this.send({
            url : '/AdminLawyerList',
            data: {
              AdminId : _this.information.AdminId,
              page : _this.page,
              pageSize : _this.pageSize,
            },
            success: function (res) {
              _this.adminLawyerList = res.List
              for(var i in res.List){
                var num = res.List[i].avgs
                console.log(Math.floor(num * 100) / 100 )
              }
              _this.count = res.Count
            }
          })
        }
        if(type == 4){
          var _this = this
          _this.send({
            url : '/OrderTypeCount',
            data: {
              AdminId : _this.information.AdminId,
              typeId : _this.typeId,
              start_time : _this.startTime ,//开始时间
              end_time : _this.endTime
            },
            success: function (res) {
              _this.orderTypeCountData = res
            }
          })
        }if(type == 5){
          var _this = this
          _this.send({
            url : '/UserOrderLIst',
            data: {
              AdminId : _this.information.AdminId,
              start_time : _this.startTime,//开始时间
              end_time : _this.endTime,//结束时间
              nameKeyId : _this.nameKeyId,//关键字
              page : _this.page,
              pageSize : _this.pageSize,
            },
            success: function (res) {
              _this.UserOrderLIst = res.list
              _this.count = res.count
            }
          })
          // 详情
        }
      },
      replace : function () {
        var _this = this
        var op = _this.information.OrganizationId+'p'+_this.lawyerPhone +'p'+1
        _this.send({
          url : '/getVillageSunCode',
          consulting1:true,
          responseType:"arraybuffer",
          data: {
            OrganizationId : _this.information.OrganizationId,
            scene: op,
            userType : 2,
            page: 'pages/login/login',
            width: '430',
            jpg: 'jpg',
            LawyerName : _this.lawyerName,
            LawyerPhone : _this.lawyerPhone,
            LawyerId : _this.lawyerId
          },
          success: function (res) {
            _this.listData = []
            _this.lawyerName = '',
                    _this.lawyerPhone = '',
                    _this.page = '1'
            _this.lawList()
          }
        })
      },
      deleteLawFirm : function (adminid,organizationId) {
        var _this = this
        _this.send({
          url : '/deleteMember',
          confirm:true,
          data: {
            AdminId : adminid,
            Level : _this.information.Level,
            OrganizationId : organizationId
          },
          success: function (res) {
            console.log(res)
            _this.listData = []
            _this.page = '1'
            _this.adminList()
          }
        })
      },
      sendOut : function () {
        var _this = this
        _this.send({
          url : '/SendMessage',
          data: {
            LawyerId:_this.lawyerId1,
            LawyerPhone:_this.lLawyerPhone},
          success: function (res) {
            if(res.Code == 'OK'){
              _this.$Notice.warning({
                title:'系统提示',
                desc:'发送成功',
              })
            }
          }
        })
      },
      //    地区
      getAreaData : function () {
        var _this = this
        _this.send({
          url : '/GetAreaData',
          data: {
            AreaId: _this.information.AreaId,
          },
          success: function (res) {
            //
            _this.cityList = res[1]
            console.log(_this.cityList)

            //
            convertTree(res[1])
            function convertTree(tree) {
              const result = []
              tree.forEach((item) => {
                // 解构赋值
                let {
                  AreaId: value,
                  AreaName: label,
                  children: children
                } = item
                if (children) {
                  children = convertTree(children)
                }
                result.push({
                  value,
                  label,
                  children
                })
              })
              _this.addressData = result
              return result
            }
            console.log(_this.addressData)
          }
        })
      },
      choice : function(value){
        var _this = this
        var data = value.value.split(',')
        console.log(data)
        _this.AreaId = data[0]
        if(data[2] == '1'){
          _this.areaData = _this.cityList[data[1]].children
        }else {

        }
        console.log()
        _this.send({
          url : '/GetfileList',
          data: {
            AreaId: data[0],
            AdminId: _this.information.AdminId,
          },
          success: function (res) {
            console.log(res)
            var ElectronicsList = []
            _this.ElectronicsList = res
          }
        })
      },
      oncliTime : function (e) {
        console.log(e)
        var _this = this
        _this.start_time = e[0],//开始时间
                _this.end_time = e[1]//结束时间
        _this.page = 1
        _this.orderTypeCount(5)
      },
      totalTime : function(e){
        var _this = this
        _this.startTime = e[0],//开始时间
                _this.endTime = e[1]//结束时间
        _this.switchData(0)
      },
      lawyerList : function () {
        var _this = this
        _this.send({
          url : '/LawyerList',
          data: {
            AdminId: _this.information.AdminId,
          },
          success: function (res) {
            _this.lawyerData = res.list
            console.log(res)

          }
        })
      },
      lawyerClick : function (e) {
        var _this = this
        if(e == undefined){
          _this.nameKeyId = ''
        }else {
          _this.nameKeyId = e
        }
        _this.page = 1
        _this.orderTypeCount(5)
        console.log(e)
      },
      searchDara : function () {
        console.log()
        var _this = this
        _this.adminVillagesData = []
        _this.count = ''
        _this.page = '1'
        _this.orderTypeCount(1)
      },
      //    排序
      sortClick : function (e) {
        var _this = this
        _this.adminVillagesData = []
        _this.count = ''
        _this.page = '1'
        _this.sort = e
        _this.orderTypeCount(1)
        console.log(this.sort)
      },
      detailsSee : function (type,name) {
        var _this = this
        _this.serviceType = type
        _this.serviceName = name
        console.log(type)
        if(type == 3){
          var _this = this
          _this.send({
            url : '/UserVideoList',
            data: {
              AdminId : _this.information.AdminId,
              start_time : _this.startTime,//开始时间
              end_time : _this.endTime,//结束时间
              page : _this.page,
              pageSize : _this.pageSize,
            },
            success: function (res) {
              _this.detailsSeeLIst = res.list
              console.log(_this.detailsSeeLIst)
              _this.count = res.count
            }
          })
        }
        if(type == 4){
          var _this = this
          _this.send({
            url : '/UserDecisionList',
            data: {
              AdminId : _this.information.AdminId,
              start_time : _this.startTime,//开始时间
              end_time : _this.endTime,//结束时间
              page : _this.page,
              pageSize : _this.pageSize,
            },
            success: function (res) {
              _this.detailsSeeLIst = res.list
              console.log(_this.detailsSeeLIst)
              _this.count = res.count
            }
          })
        }
        if(type == 5){
          var _this = this
          _this.send({
            url : '/UserWritList',
            data: {
              AdminId : _this.information.AdminId,
              start_time : _this.startTime,//开始时间
              end_time : _this.endTime,//结束时间
              page : _this.page,
              pageSize : _this.pageSize,
            },
            success: function (res) {
              _this.detailsSeeLIst = res.list
              console.log(_this.detailsSeeLIst)
              _this.count = res.count
            }
          })
        }
        if(type == 6){
          var _this = this
          _this.send({
            url : '/UserLegalList',
            data: {
              AdminId : _this.information.AdminId,
              start_time : _this.startTime,//开始时间
              end_time : _this.endTime,//结束时间
              page : _this.page,
              pageSize : _this.pageSize,
            },
            success: function (res) {
              _this.detailsSeeLIst = res.list
              console.log(_this.detailsSeeLIst)
              _this.count = res.count
            }
          })
        }
        if(type == 7){
          var _this = this
          _this.send({
            url : '/UserHandleList',
            data: {
              AdminId : _this.information.AdminId,
              start_time : _this.startTime,//开始时间
              end_time : _this.endTime,//结束时间
              page : _this.page,
              pageSize : _this.pageSize,
            },
            success: function (res) {
              _this.detailsSeeLIst = res.list
              console.log(_this.detailsSeeLIst)
              _this.count = res.count
            }
          })
        }
        if(type == 8){
          var _this = this
          _this.send({
            url : '/UserMediateList',
            data: {
              AdminId : _this.information.AdminId,
              start_time : _this.startTime,//开始时间
              end_time : _this.endTime,//结束时间
              page : _this.page,
              pageSize : _this.pageSize,
            },
            success: function (res) {
              _this.detailsSeeLIst = res.list
              console.log(_this.detailsSeeLIst)
              _this.count = res.count
            }
          })
        }
      }
    },

  }
</script>

