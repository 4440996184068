var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_vm._m(0),_vm._m(1),_c('div',{staticStyle:{"margin-top":"0.3rem"}},[_c('div',{staticClass:"lump",on:{"click":function($event){return _vm.download('https://ai-cdn.lvwa18.com/img/rusticate/files/jian-yang/关于印发《关于村（居）民委员会依法协助做好社区矫正工作的实施意见》的通知.pdf')}}},[_c('div',{staticStyle:{"width":"5.5rem","display":"inline-block"}},[_vm._v("1、关于村（居）民委员会依法协助做好社区矫正工作的实施意见")]),_vm._m(2)]),_c('div',{staticClass:"lump",on:{"click":function($event){return _vm.download('https://ai-cdn.lvwa18.com/img/rusticate/files/jian-yang/社区矫正法.pdf')}}},[_vm._v(" 2、社区矫正法 "),_vm._m(3)]),_c('div',{staticClass:"lump",on:{"click":function($event){return _vm.download('https://ai-cdn.lvwa18.com/img/rusticate/files/jian-yang/社区矫正法实施办法.pdf')}}},[_vm._v(" 3、社区矫正法实施办法 "),_vm._m(4)]),_c('div',{staticClass:"lump",on:{"click":function($event){return _vm.download('https://ai-cdn.lvwa18.com/img/rusticate/files/jian-yang/四川省社区矫正实施细则.pdf')}}},[_vm._v(" 4、四川省社区矫正实施细则 "),_vm._m(5)])]),_c('div',{staticStyle:{"text-align":"right"}},[_c('Button',{staticStyle:{"margin-top":"0.2rem"},attrs:{"type":"warning","to":{name:'CJYCL'}}},[_vm._v("查看矫正课程 >>>")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","width":"100%"}},[_c('div',{staticClass:"introduce-video"},[_c('video',{staticClass:"introduce-video-player",attrs:{"preload":"","controls":"","src":"/static/customization/jian-yang/videos/社区矫正一分钟简阳版.mp4"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduce-text"},[_c('div',{staticClass:"introduce-text-title"},[_vm._v("社区矫正")]),_c('div',{staticClass:"introduce-text-content"},[_vm._v(" 社区矫正是与监禁矫正相对的行刑方式，是指将符合社区矫正的罪犯置于社区内，由专门国家机关在相关社会团体和民间组织以及社会志愿者协助下，在判决、裁定或决定确定的期限内，矫正其犯罪心理和行为恶习，并促使其顺利回归社会的非监禁刑事执行活动。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"inline-block","color":"#00a0e9","float":"right","margin-top":"0.15rem"}},[_c('div',{},[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"inline-block","color":"#00a0e9","float":"right"}},[_c('div',{},[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"inline-block","color":"#00a0e9","float":"right"}},[_c('div',{},[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"inline-block","color":"#00a0e9","float":"right"}},[_c('div',{},[_vm._v("查看")])])
}]

export { render, staticRenderFns }