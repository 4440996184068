<template>
    <div class="login background-white">
        <div class="min container vertical" :style="{ 'left': mobile ? '-14%' : '' }">
            <div class="inline-block">
                <img class="left-img" src="https://ai.lvwa18.com/img/administration/left-img.png">
            </div>
            <div class="inline-block vertical-align" style="margin-left: 141px;">
                <div>
                    <div style="background-position:center;background-image:url('/img/logoGov.png');height:150px;background-size:auto 100%;background-repeat:no-repeat"></div>
                    <br/>
                    <p class="hello">律蛙数字法治-智慧司法平台</p>
                    <p class="cooperation" style="text-align:center"><span style="color:rgb(237, 105, 112)">管理端</span> </p>
                </div>
                <div>
                    <div class="input-Administration user1" :class="u==false ? 'typeselect' : 'typeselect1'">
                        <!---->
                        <img class="user" id="img_id" src="https://ai.lvwa18.com/img/administration/user.png">
                        <input  v-model="account" class="input-content" type="text" placeholder="请输用户名" id="txtUserName" autocomplete="on" @focus="changeActive($event)" @blur.prevent="removeActive($event)">
                    </div>
                    <div class="input-Administration password1" :class="m==false ? 'typeselect' : 'typeselect1'">
                        <img class="user" id="lock" src="https://ai.lvwa18.com/img/administration/password.png">
                        <input v-model="password" class="input-content" type="password" placeholder="请输密码" id="txtPassword" @focus="changeActive1($event)" @blur.prevent="removeActive1($event)">
                    </div>
                    <!--<div class="inline-block" style="margin-left: 25px;margin-top: 22px;">-->
                    <!--<a href="javascript:;">-->

                    <!--<label>-->
                    <!--<input v-model="checked" class="remember" checked="checked" type="checkbox" id="chkRememberPwd" @click="remember =!remember"><p class="inline-block remember-x">记住密码</p>-->
                    <!--</label>-->
                    <!--</a>-->
                    <!--</div>-->
                    <a href="javascript:;" @click="login()"><div class="signin">登 录</div></a>



                </div>
            </div>
        </div>
        <div class="bottom-png min center">
            <!--<p class="inline-block copyright">Copyright © 2017 律蛙智能 版权所有（http://www.lvwa18.com）All Right.</p>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "login",
        data () {
            return {
                u : false,
                m : false,
                // remember : true,
                //
                checked: true,
                mobile : false,
                account : '',
                password : ''
            }
        },
        methods:{
            // 鼠标移入加入class
            changeActive(){
                this.u = true
            },
            removeActive(){
                this.u = false
            },changeActive1(){
                this.m = true
            },
            removeActive1(){
                this.m = false
            },
            //
            login: function() {
                var _this = this
                _this.send({
                    url : '/OrganizationLogin',
                    data: {
                        AdminLogin: _this.account,
                        AdminPassword: _this.password,
                    },
                    success: function (res) {
                        console.log(res.AdminId)
                        // var objStr = JSON.stringify(res)JSON.stringify(res)
                        window.sessionStorage.AdminId = res.AdminId
                        window.sessionStorage.AdminLogin = res.AdminLogin
                        window.sessionStorage.OrganizationName = res.OrganizationName
                        window.sessionStorage.OrganizationId = res.OrganizationId
                        window.sessionStorage.OrganizationLinkman = res.OrganizationLinkman
                        window.sessionStorage.OrganizationPhone = res.OrganizationPhone
                        SYSCONFIG.server.information.AdminId = res.AdminId
                        SYSCONFIG.server.information.AdminLogin = res.AdminLogin
                        SYSCONFIG.server.information.OrganizationName = res.OrganizationName
                        SYSCONFIG.server.information.OrganizationId = res.OrganizationId
                        SYSCONFIG.server.information.OrganizationLinkman = res.OrganizationLinkman
                        SYSCONFIG.server.information.OrganizationPhone = res.OrganizationPhone
                        _this.$router.push({name:'adminhome'})
                    }
                })

            },
        },
        mounted () {
        }
    }
</script>

<style scoped>

    .container{width: 1200px;margin: auto auto;}

    .min{min-width: 1200px;}
    .background-white{background-color: white;position: fixed;height: 100%;width: 100%}
    .vertical{height: 450px;position: absolute;width: 1044px;left: 50%;margin-left: -522px;top:50%;margin-top:-300px;}
    .left-img{width: 443px;height: 446px;}
    .hello{font-size: 30px;color: #cccccc;}
    .cooperation{font-size: 28px;color: #cccccc;}
    .input-content{background:none;outline:none;border:0px;vertical-align: top;margin-top: 5px;color: #999999;height: 45px;width: 350px;display: inline-block}
    .input-Administration{width: 460px;height: 52px;background-color: #fafafa;border-radius:26px;margin-top: 40px;}
    .user{width: 17px;height: 20px;margin-left: 31px;margin-right: 23px;margin-top: 16px;}
    .remember{width:15px;height:15px;border: 1px solid #4a83f6;font-size: 18px;line-height: 16px;text-align: center;color: #4a83f6;}
    .remember-x{color: #4a83f6;vertical-align: top;margin-top: 1px;line-height: 16px;margin-left: 10px;}
    .signin{background-color:rgb(237, 105, 112);color: white;font-size: 14px;border-radius: 26px;width: 460px;height: 52px;line-height: 52px;text-align: center;margin-top: 35px;}


    /*管理*/

    .lvwa_texe div{width: 33%;}
    .layui-input{border: 0;background-color: rgb(244,245,249);height: 30px;}
    .layui-input,textarea,select,a:focus {outline: none;}
    .jump_page,textarea,select,a:focus {outline: none;}

    .lvwa_texe1 div{width:22%;white-space:nowrap;overflow:hidden}
    .bottom-png {
        background: url(/img/loginGov-bg.png) no-repeat;
        width: 100%;
        height: 281px;
        background-size: 100% 100%;
        position: absolute;
        bottom: 0;
        z-index: -1;
    }
    .copyright {
        font-size: 14px;
        color: #519eec;
        margin-top: 232px;
    }
    .typeselect{border: 0px; color: rgb(151, 196, 243); background-color: rgb(250, 250, 250);}
    .typeselect1{border: 1px solid rgb(137,188,241);color: rgb(151, 196, 243);background-color: white;}
</style>
