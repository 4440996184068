<template>
  <div>
    <Row :gutter="16">
      <Col span="14">
        <div style="padding: 18px 20px;display:inline-block;">角色列表</div>
        <Button type="primary" @click="addRole.show = true;addRole.title = '添加角色';">添加角色</Button>
        <Table :columns="roleColumns" :data="roleList" row-key="id" @on-row-click="singleLineClick" highlight-row border>
          <template slot-scope="{ row, index }" slot="action">
            <Button type="primary" size="small" style="margin-right: 5px" @click.stop="roleModify(row,'修改菜单')">修改</Button>
            <Button type="error" size="small" @click.stop="roleDelete(row)">删除</Button>
          </template>
        </Table>
      </Col>
      <Col span="10">
        <div style="padding: 18px 20px;display: inline-block">菜单分配</div>
        <div style="float: right;margin: 12px 30px 0 0">
          <Button type="primary" @click="saveMenu">保存</Button>
        </div>
        <Table :columns="navigationColumns" :data="navigationBar" row-key="id" >
          <template slot-scope="{ row, index }" slot="action">
            <Checkbox v-model="row.show" @on-change="operations(row)"></Checkbox>
          </template>
        </Table>
      </Col>
    </Row>
<!--    添加角色-->
    <Modal
        v-model="addRole.show"
        :title="addRole.title"
        @on-ok="addRoleDetermine" @on-cancel="roleClose">
      <Form :model="addRole.inputData" :label-width="60">
        <FormItem label="名称">
          <Input v-model="addRole.inputData.name" placeholder="名称"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "roles",
  data () {
    return {
      // 角色数据
      roleList: [], // 表格数据
      roleColumns: [ // 表格列定义
        { title: '名称', key: 'name', tree: true },
        { title: 'ID', key: 'id' },
        {
          title: '操作',
          slot: 'action',
          width: 150,
          align: 'center'
        }
      ],
      // 导航栏数据
      navigationBar: [], // 表格数据
      navigationColumns: [ // 表格列定义
        {
          title: '操作',
          slot: 'action',
          width: 150,
          align: 'center'
        },
        { title: '菜单标题', key: 'name', tree: true },
        { title: 'ID', key: 'id' },
      ],
      addRole: {
        inputData: {
          name: '',
          id : '',
        },
        title: '',
        show: false,
      }
    }
  },
  methods:{
    // 获取当前角色数据
    rolePerMissionList: function(){
      var _this = this;
      this.send({
        url: '/roleList',
        data: {
          page: '',
          pageSize: '',
        },
        success: (res) => {
          console.log(res);
          _this.roleList = res.data
        }
      });
    },
    // 确定添加角色
    addRoleDetermine: function (){
      console.log(this.addRole.inputData.id)
      var _this = this;
      if(this.addRole.inputData.id){
        this.send({
          url: '/updateRole',
          data: this.addRole.inputData,
          success: (res) => {
            _this.roleClose()
            _this.rolePerMissionList()
          }
        });
      }else {
        this.send({
          url: '/addRole',
          data: this.addRole.inputData,
          success: (res) => {
            _this.roleClose()
            _this.rolePerMissionList()
          }
        });
      }
    },
    // 修改角色
    roleModify: function (row){
      var _this = this;
      _this.addRole.show = true
      _this.addRole.title = '修改角色'
      _this.addRole.inputData.name = row.name
      _this.addRole.inputData.id = row.id
    },
    // 删除角色
    roleDelete: function (row){
      var _this = this;
      this.send({
        url: '/delRole',
        confirm: true,
        data: {
          id: row.id
        },
        success: (res) => {
          _this.roleClose()
          _this.rolePerMissionList()
        }
      });
    },
    // 关闭角色添加弹窗
    roleClose: function (){
      this.addRole.inputData = { name: '', id : '', }
    },
    // 点击单行数据
    singleLineClick: function (data,index){
      console.log(data,index)
      var _this = this
      _this.addRole.inputData.id = data.id
      _this.navigationBar = _this.menuData(_this.navigationBar)
      this.send({
        url : '/RolePerMissionList',
        data: {id:data.id},
        success: function (res) {
          console.log(_this.navigationBar, res[0].p_id)
          function updateShow(data, ids) {
            data.forEach(item => {
              if (ids.includes(item.id)) {
                item.show = true; // 更新 show 属性为 true
              }
              // 如果有 children，递归处理
              if (item.children) {
                updateShow(item.children, ids);
              }
            });
          }
          updateShow(_this.navigationBar, res[0].p_id)
        }
      })
    },
    // 指定角色删除单个菜单
    operations: function (row){
      console.log(row)
      function updateShow(data, id) {
        data.forEach(item => {
          if (item.id === id) {
            item.show = !item.show;
          }
          // 如果有 children，递归处理
          if (item.children) {
            updateShow(item.children, id);
          }
        });
      }
      updateShow(this.navigationBar, row.id)
      console.log(this.navigationBar)
    },
    saveMenu: function (){
      console.log(this.navigationBar)
      console.log(this.addRole.inputData.id)
      const ids = getVisibleIds(this.navigationBar).join(','); // 获取所有可见 ID 并以逗号连接
      console.log(ids); // 打印以逗号隔开的 ID
      function getVisibleIds(data) {
        let ids = [];
        data.forEach(item => {
          if (item.show) {
            ids.push(item.id);
          }
          if (item.children) {
            ids = ids.concat(getVisibleIds(item.children)); // 递归获取子项的 ID
          }
        });
        return ids;
      }
      this.send({
        url : '/rolePerMission',
        data: {
          p_id:ids,
          role_id:this.addRole.inputData.id,
        },
        success: function (res) {
        }
      })
    },









    // 获取菜单列表
    permissionList: function(){
      var _this = this
      this.send({
        url : '/permissionList',
        data: {},
        success: function (res) {
          _this.navigationBar = _this.menuData(res)
        }
      })
    },
    // 菜单数据
    menuData: function(data) {
      return data.map(item => {
        // 创建一个新对象，只包含需要的属性
        const newItem = {
          name: item.name,
          id: item.id,
          children: item.get_tree || item.children ? this.menuData(item.get_tree||item.children) : [], // 递归处理子项
          show: false,
        };
        return newItem;
      });
    },
  },
  mounted() {
    this.rolePerMissionList()
    this.permissionList()
  }
}
</script>

<style scoped>

</style>
