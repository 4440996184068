<style scoped>
	.header {background-image: url(/img/legalAid/banner.png);height: 2.6rem;background-size: 100% auto;background-repeat: no-repeat;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
		<div class='header'></div>
		<div style="padding:0.3rem" v-if="AreaId == '1238'">
			<stepMenu :step='1' :menu="['选择地区','基本信息','事项描述']"></stepMenu>
			<div style="padding:0.3rem"></div>
      <FormItem label prop="orgCity">
			<Select v-model="formValidate.orgCity">
				<Option  value="江西省">江西省</Option>
			</Select>
      </FormItem>
			<div style="padding:5px;"></div>
      <FormItem label prop="orgArea">
			<Select v-model="formValidate.orgArea">
				<Option  value="赣州市">赣州市</Option>
			</Select>
      </FormItem>
			<div style="padding:5px;"></div>
			<Select >
				<Option  value="崇义县">崇义县</Option>
			</Select>
		</div>
		<div style="padding:0.3rem" v-else-if="AreaId == '1681'">
			<stepMenu :step='1' :menu="['选择地区','基本信息','事项描述']"></stepMenu>
			<div style="padding:0.3rem"></div>
      <FormItem label prop="orgCity">
			<Select v-model="formValidate.orgCity">
				<Option  value="湖北省">湖北省</Option>
			</Select>
      </FormItem>
			<div style="padding:5px;"></div>
      <FormItem label prop="orgArea">
			<Select v-model="formValidate.orgArea">
				<Option  value="襄阳市">襄阳市</Option>
			</Select>
      </FormItem>
			<div style="padding:5px;"></div>
			<Select >
				<Option  value="谷城县">谷城县</Option>
			</Select>
		</div>
<!--		<div style="padding:0.3rem" v-else-if="AreaId == '1505'">-->
<!--			<stepMenu :step='1' :menu="['选择地区','基本信息','事项描述']"></stepMenu>-->
<!--			<div style="padding:0.3rem"></div>-->
<!--      <FormItem label prop="orgCity">-->
<!--			<Select v-model="formValidate.orgCity">-->
<!--				<Option  value="河南省">河南省</Option>-->
<!--			</Select>-->
<!--      </FormItem>-->
<!--			<div style="padding:5px;"></div>-->
<!--      <FormItem label prop="orgArea">-->
<!--			<Select v-model="formValidate.orgArea">-->
<!--				<Option  value="驻马店市">驻马店市</Option>-->
<!--			</Select>-->
<!--      </FormItem>-->
<!--			<div style="padding:5px;"></div>-->
<!--			<Select >-->
<!--				<Option  value="上蔡县">上蔡县</Option>-->
<!--			</Select>-->
<!--		</div>-->
      <div style="padding:0.3rem" v-else-if="AreaId == '649'">
        <stepMenu :step='1' :menu="['选择地区','基本信息','事项描述']"></stepMenu>
        <div style="padding:0.3rem"></div>
        <FormItem label prop="orgCity">
          <Select v-model="formValidate.orgCity">
            <Option  value="黑龙江省">黑龙江省</Option>
          </Select>
        </FormItem>
        <div style="padding:5px;"></div>
        <FormItem label prop="orgArea">
          <Select v-model="formValidate.orgArea">
            <Option  value="哈尔滨市">哈尔滨市</Option>
          </Select>
        </FormItem>
        <div style="padding:5px;"></div>
        <Select >
          <Option  value="松北区">松北区</Option>
        </Select>
      </div>
      <div style="padding:0.3rem" v-else-if="AreaId == '2726'">
        <stepMenu :step='1' :menu="['选择地区','基本信息','事项描述']"></stepMenu>
        <div style="padding:0.3rem"></div>
        <FormItem label prop="orgCity">
          <Select v-model="formValidate.orgCity">
            <Option  value="西藏自治区">西藏自治区</Option>
          </Select>
        </FormItem>
        <div style="padding:5px;"></div>
        <FormItem label prop="orgArea">
          <Select v-model="formValidate.orgArea">
            <Option  value="拉萨市">拉萨市</Option>
          </Select>
        </FormItem>
        <div style="padding:5px;"></div>
        <Select >
          <Option  value="曲水县">曲水县</Option>
        </Select>
      </div>
		<div style="padding:0.3rem" v-else>
			<stepMenu :step='1' :menu="['选择地区','基本信息','事项描述']"></stepMenu>
			<div style="padding:0.3rem"></div>
      <FormItem label prop="orgCity">
        <Select v-model="formValidate.orgCity">
          <Option v-for="(value,index) in mediate.loadcity" :value="value">{{ value }}</Option>
        </Select>
      </FormItem>
			<div style="padding:5px;"></div>
      <FormItem label prop="orgArea">
        <Select v-model="formValidate.orgArea">
          <Option v-for="(value,index) in mediate.orgArea" :value="value">{{ value }}</Option>
        </Select>
      </FormItem>
		</div>
		<div style="padding: 0.3rem;"></div>
		<div style="text-align: center;">
			<router-link :to="{name:'legalAidStep2'}">
			<Button type="primary" style="width:3rem;">下一步</Button>
			</router-link>
		</div>
    </Form>
	</div>
</template>

<script>
	import stepMenu from '../../../components/stepMenu.vue'
	export default {
		name: 'step1',
		data() {
			return {
				mediate: {
					loadcity :[],
					orgArea:[],
					orgZhen:[],
					hui:[],
					ren:[],
					AreaId : '',
				},
			}
		},
    beforeRouteLeave:function(to, form, next){
      if(to.name=='legalAidStep2'){
        this.$refs.formValidate.validate((valid) => {
          if(valid==false){
            this.$Message.error('请录入完成后再进行下一步操作!');
          }else{
            next()
          }
        })
      }else{
        next()
      }
    },
		mounted() {
			var _this = this
			_this.setPageSize()
			_this.AreaId = window.sessionStorage.AreaId
			_this.loadcity()
		},
		components: {
			stepMenu: stepMenu,
		},
		computed:{
			ruleValidate:function(){
				return this.$store.state.legalAidData.ruleValidate
			},
			formValidate:function(){
				return this.$store.state.legalAidData.formValidate
			},
		},
		watch: {
			'formValidate.orgCity': function(val) {
				this.formValidate.orgArea = ''
				this.formValidate.orgZhen = ''
				this.formValidate.hui = ''
				this.formValidate.ren = ''
				val ? this.mediate1() : ''
			},
			'formValidate.orgArea': function(val) {
				this.formValidate.orgZhen = ''
				this.formValidate.hui = ''
				this.formValidate.ren = ''
				val ? this.mediate2() : ''
			},
			'formValidate.orgZhen': function(val) {
				this.formValidate.hui = ''
				this.formValidate.ren = ''
				val ? this.mediate3() : ''
			},
			'formValidate.hui': function(val) {
				this.formValidate.ren = ''
				val ? this.mediate4() : ''
			},
		},
		methods: {
			loadcity : function () {
			    var _this = this
			    _this.send({
			        url : '/loadcity',
            data:{AreaId : window.sessionStorage.CityId},
			        success: function (res) {
			            _this.mediate.loadcity = res.data
			        }
			    })
			},
			mediate1: function() {
				var _this = this
				_this.send({
					url: '/loadarea',
					data: {
            AreaId : window.sessionStorage.CityId,
            city: _this.formValidate.orgCity,
					},
					success: function(res) {
						_this.mediate.orgArea = res.data
					}
				})
			},
			mediate2: function() {
				var _this = this
				_this.send({
					url: '/loadzhen',
					data: {
            AreaId : window.sessionStorage.CityId,
						city: _this.formValidate.orgCity,
						area: _this.formValidate.orgArea,
					},
					success: function(res) {
						_this.mediate.orgZhen = res.data
					}
				})
			},
			setPageSize: function() {
				var wsw = document.body.clientWidth
				document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
			},
		},
	}
</script>
