<style scoped>
	.header{background-image: url(/static/customization/jian-yang/images/correction-banner.png);height:1.6rem;background-size:100% auto;background-repeat: no-repeat;}
	.menu{font-size:0.24rem;padding:0.3rem 0.3rem;}
	.list{padding:0.2rem;}
  .list-def-img{width:1.6rem;height:1.2rem;}
  .list-title{color:#333333;font-size:0.28rem;font-weight:bold;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<div class='header'></div>
		<div class='body'>
			<div class="menu">
				<Row>
          <Col span="12" style="font-size:0.3rem;font-weight: bold;">
            <Icon type="ios-list" style="font-size:0.5rem;vertical-align:middle;"/>考试记录
          </Col>
				</Row>
			</div>
			<ul v-if="list.length>0">
				<li v-for="(item,index) in list" :style="index%2==0?'background-color: #fff;':''" class='list'>
          <Row>
            <Col span="24" class="list-title">
              <span>交卷时间：{{item.CreatedTime}} </span>
              <span style="color: gray;font-size:0.14rem;font-weight:normal;">[{{item.VillagesName}}]</span>
            </Col>
          </Row>
          <Row style="margin-top:0.1rem;" type="flex" align="middle">
            <Col span="12">
              得分：{{item.UserScore}}
              状态：{{item.UserScore>item.PassingScore?'已通过':'未通过'}}
            </Col>
            <Col span="12" style="text-align: right;">
              <Button type="primary" :to="{name:'CJYCE',query:{examId:item.UserExamId}}">查看详情</Button>
            </Col>
          </Row>
				</li>
			</ul>
			<div v-else style="background-image:url(/img/empty.png);height:4.95rem;width:6.9rem;background-size:100% 100%;margin:1.1rem auto;"></div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'list',
		data(){
			return{
				list:[],
			}
		},
		mounted(){
			var _this = this
			this.getList()
		},
		methods:{
      getList:function(){
        var _this = this
          _this.send({
              url:'/TotalExamListAdmin',
              data:{
                CurriculumId:_this.$route.query.correctionId,
                UserId:_this.$store.state.userInfo.UserId,
              },
              success:function (res) {
                _this.list = res
              }
          })
      },
		},
	}
</script>
