<style scoped>
.menu-text{vertical-align: middle;padding-left:5px;}
</style>

<template>
  <Menu theme="dark" :active-name="routerName" accordion style="background-color:#464b57;z-index:1;">
    <template v-for="(item,index) in menuList">
      <template v-if="item.children&&checkDisplay(item.routeName)">
        <Submenu :name="item.name" :key="'md'+index">
          <template slot="title">
            <icon-svg v-if="item.iconType=='svg'" :src="item.iconSrc" style="vertical-align: middle;"></icon-svg>
            <span class="menu-text">{{item.name}}</span>
          </template>
          <template v-for="(item1,index1) in item.children">
            <MenuItem v-if="checkDisplay(item1.routeName)" :name="item1.routeName" :to="{name:item1.routeName}" :key="'menu'+index1" :target="item1.target">
              <icon-svg v-if="item1.iconType=='svg'" :src="item1.iconSrc" style="vertical-align: middle;"></icon-svg>
              <span class="menu-text">{{item1.name}}</span>
            </MenuItem>
          </template>
        </Submenu>
      </template>
      <template v-else>
        <MenuItem v-if="checkDisplay(item.routeName)" :name="item.routeName" :to="{name:item.routeName}" :key="'md'+index" :target="item.target">
          <icon-svg v-if="item.iconType=='svg'" :src="item.iconSrc" style="vertical-align: middle;"></icon-svg>
          <span class="menu-text">{{item.name}}</span>
        </MenuItem>
      </template>
    </template>
  </Menu>
</template>

<script>
import iconSvg from '@/components/public/icon-svg'
export default {
  name:'menu-components',
  props:{
    menuList:{required:true,},
    displayRules:{required:true,},
  },
  data() {
    return {
    };
  },
  components:{
    'icon-svg':iconSvg,
  },
  methods: {
    //检查是否显示菜单
    checkDisplay:function(routeName){
      if(window.sessionStorage.ParentId == '2237' || window.sessionStorage.ParentId == '2262'){
        // this.displayRules.punchIn = {level:[],specific:[],specificAll:[],specificHidden:[],specificHideAll:[166]}
        this.displayRules.lawFirm = {level:[],specific:[],specificAll:[],specificHidden:[],specificHideAll:[0],specificLogin:[]}
      }else{
        this.displayRules.lawFirm = {level:[3],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]}
      }
      if(!this.displayRules[routeName])
        return true
      // specificHidden-指定AdminID隐藏;
      if(this.displayRules[routeName].specificHidden.indexOf(this.adminId)==-1 && this.displayRules[routeName].specificHidden != '')
        return true
      // level-按账号等级显示;
      if(this.displayRules[routeName].level.indexOf(this.adminLevel)!=-1)
        return true
      // specific-指定AdminID显示;
      if(this.displayRules[routeName].specific.indexOf(this.adminId)!=-1)
        return true
      if(this.displayRules[routeName].specificLogin.indexOf(this.AdminLogin)!=-1)
        return true
      // specificAll-指定AdminID及下属全部显示;
      if(this.displayRules[routeName].specificAll.find(specificAll=>{return this.parentIdArr.indexOf(specificAll.toString())!=-1}))
        return true
      // specificHideAll-指定AdminID及下属全部隐藏;
      if(!this.displayRules[routeName].specificAll.find(sa=>{return this.parentIdArr.indexOf(sa)!=-1}))
        return false
      return false
    },
  },
  computed:{
    adminLevel:function(){return parseInt(window.sessionStorage.adminLevel)},
    parentIdArr:function(){return window.sessionStorage.parentIdArrStr.split(",")},
    adminId:function(){return parseInt(window.sessionStorage.AdminId)},
    AdminLogin:function(){return parseInt(window.sessionStorage.cqykt)},
    routerName:function(){return this.$route.name}
  },
}
</script>
