//重庆路由表
import nullPage from '@/views/nullPage'
import YuKuaiZheng from './yu-kuai-zheng'
import lawyerVideoSelevter from '@/views/mobile/open/chong-qing/liang-jiang-xin-qu/lawyer-video-selecter'

var routes = {
  path:'chong-qing',
  component:nullPage,
  name:'chong-qing',
  children:[
    {path:'yu-kuai-zheng',component:nullPage,name:'CQ',children:YuKuaiZheng.routes},//重庆渝快政
    {path:'liang-jiang-xin-qu',component:nullPage,children:[
      {path:'lawyer-video-selecter',component:lawyerVideoSelevter}
    ]}
  ]
}

export default{
  routes:routes
}