<template>
  <div style="padding:20px;">

    <div style="background-color: white;">
      <div style="padding:10px;"></div>
      <div style="font-size:16px;border-left:solid #de4f0b 3px;padding-left:10px;">
        <span style="vertical-align:middle;">服务档案</span>
                <div style="display: inline-block;vertical-align:middle;margin-left:10px">
        <!--          （今日上传<span style="color: red;">{{getfileData.day}}</span>条-->
                  总上传<span style="color: red;">{{count}}</span>条
                </div>
        <!--        <div style="display: inline-block;vertical-align:middle;margin-left:10px">-->
        <!--          服务时长：<span style="color: red;">{{parseInt(serviceDuration/60)}}</span>小时<span style="color: red;">{{serviceDuration%60}}</span>分钟-->
        <!--        </div>-->
      </div>
      <div style="padding:10px;"></div>
      <div class="administration">
        <div style="padding:0 20px;">
          <div style="font-size:15px;">
            <!--            <span style="vertical-align:middle;">数据筛选：</span>-->
            <!--            <span style="padding:5px;"></span>-->
            <!--            <div class="inline-block">-->
            <!--              <Select @on-open-change="getAreaData" v-model="model8" style="width:200px" @on-change="choice"-->
            <!--                      :label-in-value="labelInValue" :placeholder="tipsWord" clearable>-->
            <!--                <Option v-for="(item,index) in cityList" v-if="cityList != ''"-->
            <!--                        :value="item.AdminId +','+ index  +','+ '2'" :label="item.AdminName" :key="index">-->
            <!--                  {{ item.AdminName}}</Option>-->
            <!--                <Option v-for="(item,index) in villagesList" v-if="villagesList != ''"-->
            <!--                        :value="item.VillagesId +','+ index  +','+ '2'" :label="item.VillagesName" :key="index">-->
            <!--                  {{ item.VillagesName }}</Option>-->
            <!--              </Select>-->
            <!--              <Select v-model="model9" style="width:200px" @on-change="choice" :label-in-value="labelInValue"-->
            <!--                      v-if="areaData" :placeholder="tipsWord" clearable>-->
            <!--                <Option v-for="(i,index) in areaData" :value="i.AreaId +','+ index +','+ '2'" :label="i.AreaName"-->
            <!--                        :key="index">{{ i.AreaName }}</Option>-->
            <!--              </Select>-->
            <!--            </div>-->
            <!--            <span style="padding:10px;"></span>-->
            <!--            <Select v-model="model10" clearable style="width:200px" @on-change="stateChoice" placeholder="请选择审核状态">-->
            <!--              <Option v-for="item in stateCityList" :value="item.value" :key="item.value">{{ item.label }}</Option>-->
            <!--            </Select>-->
            <!--            <span style="padding:10px;"></span>-->
            <!--            <div class="inline-block">-->
            <!--              <Cascader :data="evaluationData" v-model="evaluation"@on-change="addressSelect" placeholder="请选择考核分类" clearable></Cascader>-->
            <!--            </div>-->
            <!--            <span style="padding:10px;"></span>-->
            <!--            <Select v-model="model2" style="width:200px;" @on-change="onLawyerName" placeholder="请选择法律顾问"-->
            <!--                    clearable filterable>-->
            <!--              <Option v-for="item in lawyerData" :value="item.LawyerName" :key="'L'+item.LawyerId">-->
            <!--                {{ item.LawyerName }}</Option>-->
            <!--            </Select>-->
            <!--            <span style="padding:10px;"></span>-->
            <!--            <Select style="width:200px" v-model="model140" filterable remote clearable :remote-method="remoteMethod2"-->
            <!--                    @on-change="lawyerClick3" :loading="loading2" placeholder="请输入社区">-->
            <!--              <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">-->
            <!--                {{value.VillagesName}}</Option>-->
            <!--            </Select>-->
            <!--            <div style="margin:10px 0px;display: inline-block;">-->
            <!--              <span>服务时间：</span>-->
            <!--              <div style="display: inline-block;">-->
            <!--                <RadioGroup type="button" v-model="serviceDate.identification" @on-change="aQuestionnaireList(1)">-->
            <!--                  <Radio label="m">本月</Radio>-->
            <!--                  <Radio label="q">本季</Radio>-->
            <!--                  <Radio label="y">本年</Radio>-->
            <!--                  <Radio label="a">所有</Radio>-->
            <!--                  <Radio label="custom">自定义</Radio>-->
            <!--                </RadioGroup>-->
            <!--                <DatePicker v-if="serviceDate.identification=='custom'"-->
            <!--                            @on-change="serviceDate.startTime = $event[0],serviceDate.endTime=$event[1]"-->
            <!--                            @on-ok="aQuestionnaireList(1)" type="datetimerange" format="yyyy-MM-dd" placeholder="请选择时间区间">-->
            <!--                </DatePicker>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div style="margin:10px 10px;display: inline-block;">-->
            <!--              <Button type="primary" @click="clockDownload" >筛选导出</Button>-->
            <!--            </div>-->
          </div>
        </div>
        <div style="padding:10px;"></div>
      </div>
      <div style="margin-bottom: 40px;font-size: 16px;text-align: left;">
        <!--        <div style="text-align: left;margin-top: 20px">-->
        <!--          <Button @click="exportLawyerData='';LawyerName='';endTime='';submit('选择')" type="primary" size="small">批量导出</Button>-->
        <!--          <Button @click="submitCheckActive()" type="success" size="small" style="margin-left: 10px">批量审核</Button>-->
        <!--        </div>-->
        <div style="text-align: center;padding:20px;">
          <div class="forMtitle1">
            <p style="width:4%">
              序号
              <!--              <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll"></Checkbox>-->
            </p>
            <p style="width:9%;">上传时间</p>
<!--            <p style="width:7%;">服务时间</p>-->
            <p style="width:22%">村/社区</p>
            <p style="width:4%">用户</p>
            <p style="width:7%">村居公证员</p>
            <p style="width: 9%">服务方式</p>
            <p style="width: 7%">服务时长</p>
            <p style="width: 5%">评价</p>
            <p style="width: 7%">审核状态</p>
            <p style="width: 21%">操作</p>
          </div>
          <div class="forMcontent" v-for="(value,index) in adminUserData">
            <p style="width:4%">
              <span v-if="(page-1)*pageSize+index+1 < 10">0{{(page-1)*pageSize+index+1}}</span>
              <span v-else>{{(page-1)*pageSize+index+1}}</span>
<!--              <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange" class="articel">-->
<!--                <Checkbox :label="value.FileId+','+value.IsCheck">-->
<!--                  -->
<!--                </Checkbox>-->
<!--              </CheckboxGroup>-->
            </p>
            <p style="width: 9%">
              {{value.CreateTime||'暂无'}}
            </p>
<!--            <p style="width: 7%">-->
<!--              {{value.FileTime||'&#45;&#45;'}}-->
<!--            </p>-->
            <p  style="width:22%">
              {{value.VillagesName.CityName[0].replace('重庆市', '')}}{{value.VillagesName.AdminName[0]}}{{value.VillagesName.VillagesName[0]}}
            </p>
            <p style="width: 4%">
              {{value.ServiceLinkman||'--'}}
            </p>
            <p style="width:7%">{{value.GreffierName}}</p>
            <p style="width: 9%;">{{value.ServiceTypeName}}</p>
            <p style="width: 7%">
              {{value.ServiceLong}}小时{{value.ServiceMins}}分钟
            </p>
            <p style="width: 5%;display: inline-block;vertical-align:middle;">
              <span v-if="value.OrderGrade == 5">非常满意</span>
              <span v-else-if="value.OrderGrade == 4">满意</span>
              <span v-else-if="value.OrderGrade == 3">基本满意</span>
              <span v-else-if="value.OrderGrade == 2">不满意</span>
              <span v-else-if="value.OrderGrade == 1">非常不满意</span>
              <span v-else>--</span>
            </p>
            <div style="width: 7%;display: inline-block;vertical-align:middle;">
              <div :style="{'color':value.IsCheck==1?'red':''}">{{value.IsCheck==0?'未审核':value.IsCheck==1?'审核通过':'已驳回'}}</div>
            </div>
            <div style="width: 21%;display: inline-block;vertical-align:middle;">
              <Button type="primary" size="small"
                      @click="see(value);modal3 = true,imgPanel=value">查看/审核</Button>
              <!--              <span style="padding:3px;"></span>-->
              <!--              <Button type="primary"  size="small" @click="submit(value.FileId)" >导出</Button>-->
              <!--              <span style="padding:3px;"></span>-->
              <!--              <Button type="primary"  size="small" @click="exportLawyerData='';LawyerName='';endTime='';submit(value.FileId,'1')" >打印</Button>-->
              <!--              <span style="padding:3px;"></span>-->
              <!--              <Button type="error" size="small" @click="deleteClick(value.FileId)">删除</Button>-->
            </div>
          </div>
          <div style="padding:10px;"></div>
          <Page :total="count" show-total :page-size="pageSize" show-elevator
                @on-change="page = $event;aQuestionnaireList()" :current="page" />
        </div>
      </div>
    </div>
    <!--    查看图片文件-->
    <Modal v-model="modal" :title="seeData.GreffierFileTitle" width="950" draggable scrollable :styles="{top: '0'}":zIndex="zIndex">
      <div style="width: 47%;display: inline-block;">
        <div style="margin-bottom: 10px;" v-if="documentUrl">
          <div v-for="(value,index) in documentUrl" style="margin: 0 0 30px 30px" >
            {{value.name}}
            <Button type="primary" @click="seeFile(information.url+value.url,1)" >查看</Button>
            <Button type="primary" @click="seeFile(information.url+value.url,2)" >下载</Button>
          </div>
        </div>
        <viewer :images="FileImg" v-if="FileImg">
          <Carousel v-model="value1" v-if="modal && FileImg"  dots="none">
            <CarouselItem v-for="(value,index) in FileImg" style="text-align: center">
              <img :src="information.url+value" style="max-width:100%;max-height: 420px">
            </CarouselItem>
          </Carousel>
        </viewer>
      </div>
      <div style="display: inline-block;vertical-align: top;width: 50%;margin-left: 20px">
        <div v-if="seeData.GreffierName">姓名:{{seeData.GreffierName}}</div>
        <div v-if="seeData.GreffierFileTitle">标题:{{seeData.GreffierFileTitle}}</div>
        <div v-if="seeData.VillagesName">社区(村):
          <p v-for="(value,index) in seeData.VillagesName.VillagesName" style="display: inline-block">
            {{value}}<span v-if="index+1<seeData.VillagesName.length">-</span></p>
        </div>
        <div v-if="seeData.ServiceLinkman">联系人:{{seeData.ServiceLinkman}}</div>
        <div v-if="seeData.ServiceTel">联系电话:{{seeData.ServiceTel}}</div>
        <div v-if="seeData.Content">走访情况:{{seeData.Content}}</div>
        <div v-if="seeData.CreateTime">上传时间:{{seeData.CreateTime}}</div>
      </div>
      <div slot="footer">
        <template v-if="seeData.IsCheck==0">
          <div style="padding:3px;"></div>
          <!-- /审核失败/未审核 -->
          <Button type="success" @click="toExamine(1,seeData.GreffierFileId)">通过</Button>
          <span style="padding:5px;"></span>
          <Button type="warning" @click="toReject(seeData.GreffierFileId)">驳回</Button>
        </template>
        <Button @click="cancel">关闭</Button>
      </div>
    </Modal>
    <Modal v-model="modal6" title="驳回理由" @on-ok="toRejectDetermine" ok-text="发送" style="height: 300px" draggable
    >
      <Input v-model="checkMsg" maxlength="300" show-word-limit type="textarea" placeholder="驳回理由" />
    </Modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "notaryLog",
  data() {
    return {
      adminUserData:[],
      information:[],
      VillagesId : '',
      page : 1,
      pageSize : 15,
      startTime : '',
      endTime : '',
      timeData: '',
      model140: false,
      loading2: false,
      options2: [],
      userKey : '',
      modal : false,
      FileImg : '',
      documentUrl : [],
      value1 : 0,
      zIndex : 9,
      seeData : [],
      GreffierFileId : '',
      checkMsg : '',
      modal6: false,
      count : 0,
    }
  },
  methods:{
    userSearch : function (e){
      this.userKey = e
      this.AGWorkFileListGzy()
    },
    AGWorkFileListGzy:function (){
      var _this = this
      _this.send({
        url: '/AGWorkFileList',
        data: {
          AdminId: _this.information.AdminId,
          typeId: 4,
          page: _this.page,
          pageSize: _this.pageSize,
          start_time: _this.startTime,//开始时间
          end_time: _this.endTime,
          VillagesId: _this.VillagesId,
          key : _this.userKey,
        },
        success: function (res) {
          _this.adminUserData = res.List
          _this.count = res.Count
        }
      })
    },
    totalTime3: function (e) {
      var _this = this
      _this.startTime = e[0],//开始时间
          _this.endTime = e[1]//结束时间
      _this.page = 1
      _this.AGWorkFileListGzy(2)
    },
    lawyerClick3: function (e) {
      var _this = this
      if (e == undefined) {
        _this.VillagesId = ''
        _this.options2 = []
      } else {
        _this.VillagesId = e
      }
      _this.page = 1
      _this.AGWorkFileListGzy(2)
      console.log(e)
    },
    remoteMethod2(query) {
      console.log(query)
      var _this = this
      _this.send({
        url: '/villagesList',
        data: {
          AdminId: _this.information.AdminId,
          Key: query,
        },
        success: function (res) {
          console.log(res)
          _this.options2 = res.list
        }
      })
    },
    see : function (e){
      console.log(e)
      if (e.FileImg) {
        this.FileImg = e.FileImg.split(",")
        this.modal = true
      }else{this.FileImg = ''}
      if (e.DocumentUrl) {
        this.documentUrl = e.DocumentUrl
        this.modal = true
      }else{
        this.documentUrl = []
      }
      this.seeData = e
    },

    seeFile: function (url,type) {
      var _this = this
      var fileType = url.substr(url.lastIndexOf(".") + 1, 4).toLowerCase()
      if (fileType == "txt") {
        axios({
          method: 'get',
          responseType: 'blob',
          url: url,
          transformResponse: [function (data) {
            var render = new FileReader()
            render.readAsText(data, 'UTF8')
            render.onload = function () {
              if (render.result.indexOf('�') != -1) {
                var renderGBK = new FileReader()
                renderGBK.readAsText(data, 'GBK')
                renderGBK.onload = function () {
                  var newWindow = window.open('about:blank', '', 'width=400,height=400')
                  newWindow.document.write(renderGBK.result)
                }
              } else {
                var newWindow = window.open('about:blank', '', 'width=400,height=400')
                newWindow.document.write(render.result)
              }
            }
          }]
        })
      }
      else {
        if(fileType == 'pdf'){
          window.open(url)
        }else{
          if(type == 2){
            window.open(url)
          }else{
            _this.showPdf(url)
          }
        }
      }
      return
      if (this.documentUrl.substr(this.documentUrl.lastIndexOf(".") + 1) == 'txt') {
        _this.filesToRar([{
          fileUrl: _this.documentUrl,
          renameFileName: 'txt.txt',
        }], 'txt')
      } else {
        window.location.href = this.documentUrl
      }

    },
    showPdf : function (e){
      var _this = this
      if(SYSCONFIG.location == 'chong-qing'){
        var url = '/ShowPdf'
      }else{
        var url = '/CD_ShowPdf'
      }
      _this.send({
        url: url,
        data: {
          FilePath: e,
        },
        success: function (res) {
          window.open(window.sessionStorage.url+res)
        }
      })
    },
    cancel() {
      this.seeData = []
      this.modal = false
    },
    toRejectDetermine: function () {
      var _this = this
      if (_this.checkMsg) {
        _this.send({
          url: '/AGCheckWorkFile',
          data: {
            IsCheck: 2,
            GreffierFileId: _this.GreffierFileId,
            CheckMsg: _this.checkMsg,
            AdminId: _this.information.AdminId,
          },
          success: function (res) {
            _this.modal = false
            _this.AGWorkFileListGzy()
          }
        })
      } else {
        _this.$Message.error('驳回理由不能为空');
      }

    },
    toReject: function (GreffierFileId) {
      var _this = this
      _this.GreffierFileId = GreffierFileId
      _this.modal6 = true
    },
    toExamine: function (isCheck, GreffierFileId) {
      var _this = this
      _this.send({
        url: '/AGCheckWorkFile',
        confirm: true,
        data: {
          IsCheck: isCheck,
          GreffierFileId: GreffierFileId,
          AdminId: _this.information.AdminId,
          CheckMsg : '',
        },
        success: function (res) {
          _this.modal = false
          _this.AGWorkFileListGzy()
        }
      })
    },
  },
  mounted() {
    this.information = window.sessionStorage
    this.AGWorkFileListGzy()
  },
}
</script>

<style scoped>
.home{background-color: rgb(241,239,240);padding:20px;}
.screen{width: calc(100% - 80px);display: inline-block;}
.screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
/*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
.screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
.screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: #00b86c;padding: 0px 16px;}
.screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: #00b86c;}
.screen :deep(.ivu-tabs-ink-bar){background-color: #00b86c;}
.screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
.house{width: 72px;height: 72px;margin-top: 29px;}
.number{font-size: 48px;color: #7a7a7a;}
.tipsName{font-size: 16px;color: #8d8d8d;}
.displayData{width: 23.5%;height: 220px;background-color: #fff;font-size: 0;display: inline-block;margin-left: 2%;}
.administration{border-bottom: 1px solid #dddddd;text-align: left;margin-bottom: 0}
.administration p{font-size: 20px;color: #333333;display: inline-block;line-height: 80px;}
.label{width: 6px;height: 24px;vertical-align: top;margin: 28px 20px 0 20px;}
.forMtitle1{background-color: #f9f9f9;height: 60px;text-align: center;}
.forMtitle1 p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
.forMtitle{width: calc(100% - 80px);background-color: #f9f9f9;height: 60px;}
.forMtitle p{display: inline-block;color: #333333;font-size: 16px;width: 20%;line-height: 60px;overflow: hidden;white-space: nowrap;}
.forMcontent{color: #666666;font-size:14px;padding:5px 0;border-bottom: 1px solid #dddddd;text-align: center;}
.forMcontent p {display: inline-block;width: 20%;overflow: hidden; vertical-align:middle;white-space: nowrap;padding:10px 0;}
.smallProgram{width: 32px;height: 32px;vertical-align: top;margin-top: 14px;}
.alertCode{position: absolute;width: 124px;height: 150px;background-color: #fff;z-index: 99;text-align: center;top: -50px;border-radius: 4px;box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);right: -33px;}
.alertCode p {width: 100%;height: 26px;line-height: 26px;border-bottom: 1px solid #dddddd;color: #666666;font-size: 16px;display: inline-block; vertical-align: top;}
.qrCode{width: 100px;height: 100px;}
.addTo{width: 100px;height: 40px;border-radius: 20px;background-color: #00b86c;color: #ffffff;font-size: 16px;display: inline-block;text-align: center;float: right;line-height: 40px;margin: 20px 40px 0 0;cursor:pointer}
.myWord{font-size: 16px;color: #00b86c;height: 60px;line-height: 60px;text-align: center;border-bottom: 1px solid #dddddd;}
.icon1{width: 32px;height: 32px;margin-left: 10px;margin-right: 20px;vertical-align: top;margin-top: 18px;}
.contacts{font-size: 16px;color: #999999;display: inline-block;height: 66px;line-height: 66px;}
.single{width: 100%;border-bottom: 1px solid #dddddd;text-align: left;display: inline-block;}
.inputWord{color: #333333;font-size: 16px;border: 0;text-align: left;height: 66px;margin-left: 20px;width: 350px;}
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}

.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
  text-align: left;
}
</style>
