<style scoped>
.publicWelfare table {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}
.formtable {
    font-family: Consolas, Menlo, Courier, monospace;
    font-size: 12px;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 24px;
}
.publicWelfare table th {
    background: #f7f7f7;
    white-space: nowrap;
    color: #5c6b77;
    font-weight: 600;
}
.publicWelfare table td,
.publicWelfare table th {
    border: 1px solid #e9e9e9;
    padding: 8px 16px;
    text-align: left;
}
.details p {
    color: #000000;
}
.details span {
    color: #5c6b77;
}
</style>

<template>
    <div style="padding: 20px">
        <div style="background-color: white">
            <div style="font-size: 16px; text-align: left; border-left: solid #de4f0b 3px; padding-left: 5px">
                打卡管理
                <div style="display: inline-block; vertical-align: middle; margin-left: 10px">
                    （今日上传<span style="color: red">{{ getClockDayData.day }}</span
                    >条 总上传<span style="color: red">{{ getClockDayData.total }}</span
                    >条）
                </div>
            </div>
            <div style="padding: 10px"></div>
            <div>
                <DatePicker
                    type="daterange"
                    show-week-numbers
                    placeholder="日期筛选"
                    style="width: 150px"
                    @on-change="handleOk"
                ></DatePicker>
                <Select
                    style="width: 150px; margin-left: 20px; vertical-align: top"
                    v-model="model140"
                    filterable
                    remote
                    clearable
                    :remote-method="remoteMethod2"
                    @on-change="lawyerClick3"
                    :loading="loading2"
                    placeholder="请输入社区"
                >
                    <Option v-for="(value, index) in options2" :value="value.VillagesId" :key="value.VillagesName">
                        {{ value.VillagesName }}</Option
                    >
                </Select>
                <div style="display: inline-block;width: 150px; margin-left: 20px; vertical-align: top">
                  <Select v-model="areaRange.value" placeholder="请选择区域" clearable>
                    <Option v-for="(item, index) in areaRange.list" :value="item.AdminId" :label="item.AdminName">
                      {{ item.AdminName }}</Option>
                  </Select>
                </div>
                <div style="display: inline-block; margin-left: 20px; vertical-align: top">
                    <Input
                        v-model="searchLaw"
                        search
                        enter-button
                        placeholder="搜索律师名称"
                        @on-search="searchLawyer"
                        style="width: 300px"
                    />
                </div>
                <div style="display: inline-block; margin-left: 20px; vertical-align: top">
                    <Input
                        v-model="OrganizationName"
                        search
                        enter-button
                        placeholder="搜索律所名称"
                        @on-search="searchLawyer"
                        style="width: 300px"
                    />
                </div>
                <Button type="primary" @click="clockDownload('')" style="margin-left: 30px"
                    >批量导出法律顾问座班签到表</Button
                >
            </div>
            <div style="padding: 15px"></div>
            <div class="publicWelfare">
                <table class="formtable">
                    <thead>
                        <tr>
                            <th>
                                <Checkbox
                                    :indeterminate="indeterminate"
                                    :value="checkAll"
                                    @click.prevent.native="handleCheckAll"
                                    >{{ null }}</Checkbox
                                >
                                <Button @click="clockDownload('选择')" type="primary">导出</Button>
                                <Button @click="clockDownload('选择', 1)" type="primary" style="margin-left: 10px"
                                    >打印</Button
                                >
                            </th>
                            <th>律师名称</th>
                            <th>律所名称</th>
                            <th>上班时间/地址(备注、图片)</th>
                            <th>下班时间/地址(备注、图片)</th>
                            <th>在线时间</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(value, index) in punchInList">
                            <td nowrap="nowrap">
                                <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange" class="articel">
                                    <Checkbox :label="value.ClockInId" :disabled="value.Status == 0">{{ value.today }}</Checkbox>
                                </CheckboxGroup>
                            </td>
                            <td>{{ value.LawyerName }}</td>
                            <td>{{ value.OrganizationName }}</td>
                            <td>
                                <span style="cursor: pointer" @click="initmapFn(value.addressM, value.ClockInAddress)"
                                    >{{ value.ClockInTime }}/{{ value.ClockInAddress }}</span
                                >
                                <div v-if="value.InClockRemark">备注:{{ value.InClockRemark }}</div>
                                <div
                                    v-if="value.InClockUrl"
                                    @click="
                                        seeImg(value.InClockUrl)
                                        modal3 = true
                                    "
                                >
                                    图片:<Button>查看</Button>
                                </div>
                            </td>
                            <td>
                                <span
                                    style="cursor: pointer"
                                    @click="initmapFn(value.addressL, value.ClockInOutAddress)"
                                    >{{ value.ClockInOutTime }}/{{ value.ClockInOutAddress }}</span
                                >
                                <div v-if="value.OutClockRemark">备注:{{ value.OutClockRemark }}</div>
                                <div
                                    v-if="value.OutClockUrl"
                                    @click="
                                        seeImg(value.OutClockUrl)
                                        modal3 = true
                                    "
                                >
                                    图片:<Button>查看</Button>
                                </div>
                            </td>
                            <td v-if="value.OnlineTime">
                                {{ value.OnlineTime }}分钟{{ value.Status == 0 ? '（已作废）' : '' }}
                            </td>
                            <td v-else>{{ value.Status == 0 ? '（已作废）' : '' }}</td>
                            <td nowrap="nowrap">
                                <Button
                                    v-if="value.Status == 1"
                                    type="error"
                                    @click="ClockSet(value.LawyerId, value.ClockInId, 0)"
                                    >作废</Button
                                >
                                <Button
                                    v-if="value.Status == 0"
                                    type="success"
                                    @click="ClockSet(value.LawyerId, value.ClockInId, 1)"
                                    >恢复</Button
                                >
                                <Button type="primary" @click="clockDownload(value.ClockInId)" style="margin-left: 10px" :disabled="value.Status == 0"
                                    >导出</Button
                                >
                                <Button
                                    type="primary" :disabled="value.Status == 0"
                                    @click="clockDownload(value.ClockInId, 1)"
                                    style="margin-left: 10px"
                                    >打印</Button
                                >
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style="text-align: left; margin: 40px; font-size: 16px">
            <Page
                :total="count"
                show-total
                :page-size="pageSize"
                show-elevator
                @on-change="
                    page = $event
                    clockManage()
                "
            />
        </div>
        <!---->
        <Modal v-model="modal3" @on-cancel="cancel">
            <div style="text-align: center">
                <viewer :images="tupians">
                    <img :src="fileurl" style="max-width: 100%; max-height: 500px" @click="enlarge" />
                </viewer>
            </div>
        </Modal>
<!--        <Modal v-model="cqs" title="温馨提示">-->
<!--            <p>该功能为平台增值服务，试用截止时间为2022年4月30日。</p>-->
<!--        </Modal>-->

        <Modal v-model="showMap" :title="address" width="800">
            <div id="mapChart" style="height: 500px"></div>
        </Modal>
    </div>
</template>

<script>
import axios from 'axios'
import VueAMap from 'vue-amap'
import MapLoader from '@/assets/js/AMap.js'
VueAMap.initAMapApiLoader({
    // 高德key
    key: 'd0d75fc99ba7c446e3bef8ba5d5fcb38', // 自己到官网申请，我随便写的
    // 插件集合 （插件按需引入）
    plugin: [
        'AMap.Autocomplete',
        'AMap.PlaceSearch',
        'AMap.Scale',
        'AMap.OverView',
        'AMap.ToolBar',
        'AMap.MapType',
        'AMap.PolyEditor',
        'AMap.CircleEditor',
        'AMap.MarkerClusterer'
    ],
    v: '1.4.15', // 我也不知道为什么要写这个，不写项目会报错，而且我随便写的，跟我下载的版本对应不了
    uiVersion: '1.0.11' // ui版本号，也是需要写
})
export default {
    name: 'punchIn',
    data() {
        return {
            punchInList: [],
            date: '',
            information: [],
            start_time: '', //开始时间
            end_time: '', //结束时间
            searchLaw: '', //律师名称
            OrganizationName: '', //律所名称
            fileurl: '',
            modal3: false,
            cqs: false,
            page: 1,
            pageSize: 20,
            count: 0,
            tupians: [],
            clockDownloadData: [],
            checkAllGroup: [],
            indeterminate: true,
            checkAll: false,
            checkAllGroupPage: [],
            showMap: false,
            map: null,
            address: '',
            getClockDayData: [],
            model140: '',
            loading2: false,
            VillagesId: '',
            options2: [],
            areaRange:{value:'',list:[],},//区域范围
        }
    },
    watch: {
      'areaRange.value': function (val) {this.page=1;this.clockManage()},
    },
    mounted() {
        this.information = window.sessionStorage
        // || window.sessionStorage.AreaId == '2236'
        if (window.sessionStorage.ParentId == '2237' || window.sessionStorage.ParentId == '2262') {
            this.cqs = true
            this.clockManage()
        } else {
            this.clockManage()
        }
        this.getClockDay()
        this.getAdminList()
    },
    methods: {
        getClockDay: function () {
            var _this = this
            _this.send({
                url: '/GetClockDay',
                data: {
                    AreaId: window.sessionStorage.AreaId,
                    AdminId: _this.information.AdminId
                },
                success: function (res) {
                    console.log(res)
                    _this.getClockDayData = res
                }
            })
        },
        initmapFn(e, address) {
            var _this = this
            _this.showMap = true
            _this.address = address
            var center = [JSON.parse(e).point.longitude, JSON.parse(e).point.latitude]
            console.log(center, JSON.parse(e).point)
            // 创建地图，同时给地图设置中心点、级别、显示模式、自定义样式等属性
            _this.map1 = new AMap.Map('mapChart', {
                resizeEnable: true, //是否监控地图容器尺寸变化
                zoom: 17, // 缩放级别
                center: center, //中心点坐标
                expandZoomRange: true
            })
            let marker = new AMap.Marker({
                icon: 'img/landmark.png', // 添加标记图标
                map: _this.map,
                position: center
            })
            this.map1.add(marker)
            marker.on('click', function (e) {
                console.log(e)
                // marker.setLabel({// 设置label标签
                //   offset: new AMap.Pixel(1, 0),
                //   content: '<div>' + 123 + '</div>', //设置文本标注内容
                //   direction: 'top',
                // });
            })
        },
        // 地图标注
        onMarkerMap(data) {
            if (data[0]) {
                data.forEach((element, index) => {
                    if (element.lng) {
                        let marker = new AMap.Marker({
                            //在回调函数里面创建Marker实例,添加经纬度和标题
                            position: new AMap.LngLat(element.lng, element.lat), //添加经纬度
                            offset: new AMap.Pixel(-13, -30) // 偏移量
                            // title: "广州塔", // 鼠标移上去时显示的内容
                            // 可以自定义标记点显示的内容,允许插入html字符串
                            // content: "<h1>广州塔Content</h1>",
                        })
                        this.map1.add(marker) // 将创建的点标记添加到已有的地图实例：
                        //marker.setMap(this.map1);
                        //名称
                        marker.setLabel({
                            // 设置label标签
                            offset: new AMap.Pixel(-50, -30), //设置文本标注偏移量
                            content: `<div class="info">${element.enterpriseName}</div>`, //设置文本标注内容
                            direction: 'right' //设置文本标注方位
                        })
                    }
                })
            }
        },
        handleCheckAll: function () {
            var _this = this
            var checkAllGroup = [] //全选
            var back = [] //反选
            console.log(_this.checkAllGroup)
            if (_this.checkAllGroup.length == 0) {
                //全选
                for (var i in _this.punchInList) {
                  if(_this.punchInList[i].Status != 0){
                    checkAllGroup.push(_this.punchInList[i].ClockInId)
                  }
                }
                _this.checkAllGroup = checkAllGroup
                _this.checkAllGroupPage[_this.page - 1] = checkAllGroup
                _this.indeterminate = true
            }
            // _this.checkAllGroup.length < _this.punchInList.length && _this.checkAllGroup.length != 0
            else {
                //反选
                for (var i in _this.punchInList) {
                  if(_this.punchInList[i].Status != 0){
                    checkAllGroup.push(_this.punchInList[i].ClockInId)
                  }
                }
                // 首先构造Object
                const idSet = _this.checkAllGroup.reduce((acc, v) => {
                    acc[v] = true
                    return acc
                }, {})
                // 遍历list2，去掉在idSet中存在的id
                const result = checkAllGroup.filter((v) => !idSet[v])
                _this.checkAllGroup = result
                _this.checkAllGroupPage[_this.page - 1] = result
            }
            console.log(_this.checkAllGroup)
            console.log(_this.checkAllGroupPage)
        },
        checkAllGroupChange: function (data) {
            var _this = this
            _this.checkAllGroup = data
            _this.checkAllGroupPage[_this.page - 1] = data
            console.log(_this.checkAllGroup)
        },
        clockDownload: function (ClockInId, type) {
            var _this = this
            console.log(ClockInId)
            var clockArr = []
            if (ClockInId == '选择') {
                console.log(_this.checkAllGroupPage)
                if (_this.checkAllGroupPage != '') {
                    for (var i in _this.checkAllGroupPage) {
                        for (var index in _this.checkAllGroupPage[i]) {
                            clockArr.push(_this.checkAllGroupPage[i][index])
                        }
                    }
                } else {
                    this.$Message.info('请选择后导出')
                    return
                }
            }
            if (ClockInId == '') {
            }
            if (SYSCONFIG.location == 'chong-qing') {
                var url = '/ClockWord'
            } else {
                var url = '/CD_ClockWord'
            }
            _this.send({
                url: '/ClockWord',
              loading: true,
                data: {
                    AdminId: _this.information.AdminId,
                    start_time: _this.start_time,
                    end_time: _this.end_time,
                    key: _this.searchLaw,
                    OrganizationName: _this.OrganizationName,
                    VillagesId: _this.VillagesId,
                    page: _this.page,
                    pageSize: _this.pageSize,
                    clockArr: clockArr.toString() || ClockInId,
                    AdminFind: _this.areaRange.value,
                },
                success: function (res) {
                    console.log(res)
                    _this.clockDownloadData = window.sessionStorage.url + res
                    _this.checkAllGroupPage = []
                    _this.checkAllGroup = []
                    // window.open(res)
                    var fileUrl = window.sessionStorage.url + res
                    if (type == 1) {
                        if (SYSCONFIG.location == 'chong-qing') {
                            _this.showPdf(window.sessionStorage.url + res)
                        } else {
                            window.open(
                                'https://view.officeapps.live.com/op/view.aspx?src=' + window.sessionStorage.url + res
                            )
                        }
                    } else {
                        window.open(window.sessionStorage.url + res)
                    }
                    console.log(fileUrl + '?attname=考勤打卡.xlsx')
                }
            })
        },
        showPdf: function (e) {
            var _this = this
            if (SYSCONFIG.location == 'chong-qing') {
                var url = '/ShowPdf'
            } else {
                var url = '/CD_ShowPdf'
            }
          window.open(window.sessionStorage.url + 'api' + url + '?FilePath='+ e)
        },
        ClockSet: function (LawyerId, ClockInId, Status) {
            var _this = this
            _this.send({
                url: '/ClockSet',
                confirm: true,
                data: {
                    LawyerId: LawyerId,
                    ClockInId: ClockInId,
                    Status: Status
                },
                success: function (res) {
                    _this.$Notice.success({
                        title: '系统通知',
                        desc: '该数据已更新！'
                    })
                    _this.clockManage()
                }
            })
        },
        enlarge: function (e) {
            this.tupians = this.fileurl
            console.log(this.fileurl)
        },
        seeImg: function (url) {
            this.fileurl = window.sessionStorage.url + url
        },
      //获取区域列表
      getAdminList:function(){
        let _this = this
        _this.send({
          url: '/FindBottomVillagesAndAdmin_',
          data:{
            AdminId: window.sessionStorage.AdminId,
          },
          success: function (res) {
            _this.areaRange.list = []
            _this.areaRange.list = res.Admin
          }
        })
      },
        clockManage: function () {
            var _this = this
            _this.punchInList = []
            _this.send({
                url: '/ClockManage',
                data: {
                    start_time: _this.start_time,
                    end_time: _this.end_time,
                    AdminId: _this.information.AdminId,
                    key: _this.searchLaw,
                    OrganizationName: _this.OrganizationName,
                    page: _this.page,
                    pageSize: _this.pageSize,
                    VillagesId: _this.VillagesId,
                    AdminFind: _this.areaRange.value,
                },
                success: function (res) {
                    _this.punchInList = res.List
                    _this.count = res.Count
                    _this.checkAllGroup = _this.checkAllGroupPage[_this.page - 1] || []
                    console.log(_this.checkAllGroup)
                }
            })
        },
        handleOk: function (e) {
            console.log(e)
            var _this = this
            _this.page = 1
            _this.start_time = e[0]
            _this.end_time = e[1]
            this.clockManage()
        },
        searchLawyer: function () {
            this.page = 1
            this.clockManage()
        },
        cancel: function () {},
        remoteMethod2(query) {
            console.log(query)
            var _this = this
            _this.send({
                url: '/villagesList',
                data: {
                    AdminId: _this.information.AdminId,
                    Key: query
                },
                success: function (res) {
                    if (res) {
                        _this.options2 = res.list
                    }
                }
            })
        },
        lawyerClick3: function (e) {
            var _this = this
            if (e == undefined) {
                _this.VillagesId = ''
                _this.options2 = []
            } else {
                _this.VillagesId = e
            }
            _this.page = 1
            this.clockManage()
            console.log(e)
        }
    }
}
</script>
