import { render, staticRenderFns } from "./zhqyHome.vue?vue&type=template&id=52c550e2&scoped=true&"
import script from "./zhqyHome.vue?vue&type=script&lang=js&"
export * from "./zhqyHome.vue?vue&type=script&lang=js&"
import style0 from "./zhqyHome.vue?vue&type=style&index=0&id=52c550e2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c550e2",
  null
  
)

export default component.exports