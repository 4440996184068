//海南路由
import nullPage from '@/views/nullPage'
import HaiYiBan from './hai-yi-ban'

var routes = {
  path:'hai-nan',
  component:nullPage,
  name:'hai-nan',
  children:[
    {path:'hai-yi-ban',component:nullPage,name:'HN',children:HaiYiBan.routes},
  ]
}

export default{
  routes:routes
}