<style scoped>
.headTransparent{width: 100%;height: 0.7rem;background-image: linear-gradient(rgba(0,111,214,0.8), rgba(76,151,255,0.8));}
.userImg{width: 0.45rem;height: 0.45rem;border-radius: 0.45rem;margin-top: 0.01rem;}
.framePortrait{width: 0.47rem;height: 0.47rem;border-radius: 0.47rem;background-color: rgba(255,255,255,0.6);display: inline-block;text-align: center;margin: 0.14rem 0.15rem 0 0.3rem;vertical-align:top;}
.hi{color:#fff;font-size: 0.24rem;display: inline-block;line-height: 0.70rem;vertical-align:top;}
.address{color: #ffffff;font-size: 0.36rem;font-weight: 600;margin-top: 0.20rem;}
.sifa{width: 1.02rem;margin: 0.30rem 0;height: 1.10rem;}
.lawyerInformation{margin:auto;width: 6.90rem;height: 1.53rem;border-radius: 0.765rem;background-image: linear-gradient(rgba(0,111,214,0.8), rgba(76,151,255,0.8));margin-top: 0.30rem;text-align: left;font-size: 0;margin-bottom: 0.30rem;}
.lawyerImg{width: 1.06rem;height: 1.06rem;border-radius: 1.06rem;vertical-align: top;margin: 0.24rem 0.12rem 0 0.20rem;}
.lawIformation{display: inline-block;color: #fff;vertical-align: top;margin-top:0.20rem;}
.labelAdviser{background-color: #dab10c;color: #fff;font-size: 0.19rem;height: 0.22rem;line-height: 0.22rem;display: inline-block;}
.lvshi1{width: 0.16rem;height: 0.18rem;margin: 0.02rem 0.04rem 0 0.04rem;}
.dianhua{width: 0.96rem;height: 0.96rem;float: right;margin: 0.29rem 0.20rem 0 0;}
.threePlates{width: 7.10rem;padding:0.40rem 0;border-radius: 0.20rem;background-color: #fff;display: inline-block;box-shadow: 0px 0px 0.28rem 0.02rem rgba(0,0,0,0.15);font-size:0.26rem;margin-bottom:0.30rem;text-align: left}
.plate{width:0.86rem;height:0.86rem;display: inline-block;}
.threePlate{vertical-align:top;display: inline-block;font-size: 0.26rem;color: #454545;text-align: center;width: 33%;margin: 0.32rem 0 0 0;position: relative;}
</style>

<template>
  <div>
    <div
        style="background:url(https://ai-cdn.lvwa18.com/img/rusticate/newbg.png) no-repeat;background-size:100% 100%;height: 7.21rem">
      <div class="headTransparent">
        <div class="framePortrait" bindtap="myClikc">
          <img src="/img/logoGov.png" class="userImg" />
        </div>
        <div class="hi">Hi，<template v-if="$store.state.userInfo.UserName">{{$route.query.openId=='dongbuxinqu07'?'公共法律服务中心':$store.state.userInfo.UserName}} </template><span>你好！</span></div>
      </div>
      <!---->
      <div style="text-align: center;font-size:0;font-weight:700;">
        <img src="https://ai-cdn.lvwa18.com/img/rusticate/sifa.png" class="sifa" />
        <div style="color:#fff;font-size: 0.48rem;">
          <!-- <span>成都市智慧公共法律服务</span> -->
          <span>{{$store.state.userInfo.Area_name}}智慧公共法律服务</span>
        </div>
        <div class="address">
          <!-- <span>{{$store.state.userInfo.Area_name}}{{$store.state.userInfo.Admin_name}}{{$store.state.userInfo.VillagesName}}</span> -->
          <span v-if="$route.query.openId=='dongbuxinqu07'">公共法律服务中心</span>
          <span v-else>{{$store.state.userInfo.Admin_name}}{{$store.state.userInfo.VillagesName}}</span>
        </div>
      </div>

      <!--咨询服务-->
      <div style="text-align: center;margin-top: 0.5rem">
        <div class="threePlates">
          <div style="font-size:0.3rem;font-weight:600;padding-bottom:0.10rem;text-align: center;">
            <img style="width:1.07rem;height: 0.11rem;"
                 src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-l.png" />
            咨询服务
            <img style="width:1.07rem;height: 0.11rem;"
                 src="https://ai-cdn.lvwa18.com/img/rusticate/menu-title-bg-r.png" />
          </div>
          <div class="threePlate" @click="dialogue()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/plate0.png" class="plate" />
            <div><span>人工咨询</span></div>
          </div>
          <div class="threePlate" @click="selfHelp()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/plate1.png" class="plate" />
            <div><span>自助咨询</span></div>
          </div>
          <div class="threePlate" @click="videoCall()">
            <img src="https://ai-cdn.lvwa18.com/img/rusticate/plate2.png" class="plate" />
            <div><span>视频咨询</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css"
export default {
  name: "CDLQYHome",
  data:function(){
    return{
      lawyerInfo:{
        name:'',
        phone:'',
        img:'',
      },
      showAll:false,
      UTCDay:0,
      JYVillagesId :  '',
      LawyerId :  '',
    }
  },
  mounted(){
    var _this = this
    this.YunLaw()
  },
  methods : {
    videoCall:function(){
      this.$router.push({name:'MOPV',query:{roomId:this.randomRoomID()}})
    },
    randomRoomID: function() {
      return parseInt(Math.random() * 9999)
    },
    dialogue:function(){
      this.addOrder(1)
          .then(()=>{this.$router.push({name:'CDLQYD',query:{LawyerId:this.LawyerId,VillagesId:this.JYVillagesId}})})
    },
    // 统计订单类型
    addOrder  : function(orderTypeId){
      var _this = this
      return new Promise((resolve,reject)=>{
        _this.send({
          url:'/AddOrder',
          data:{
            LawyerId: _this.$store.state.userInfo.LawyerId,
            UserId: _this.$store.state.userInfo.UserId,
            OrderTypeId:orderTypeId
          },
          success:function(res){
            resolve()
          }
        })
      })
    },
    selfHelp : function(){
      this.addOrder(2)
          .then(()=>{window.location = "https://panda.lvwa18.com/menuSelect?source=TFSMY"})
    },
    YunLaw:function(){
      var _this = this
      _this.send({
        url:'/YunLaw',
        data:{},
        success:function(res){
          _this.LawyerPhone = res[0].LawyerPhone
          _this.LawyerName = res[0].LawyerName
          _this.LawyerId = res[0].LawyerId
          _this.LawyerImg = res[0].LawyerImg
          _this.JYVillagesId = res.VillagesId
          console.log(res.VillagesId)
        }
      })
    },
  },
}
</script>
