<style scoped>
	.header{background-image: url(/img/legalAid/banner.png);height:2.6rem;background-size:100% auto;background-repeat: no-repeat;}
  .text-break{width:6.5rem;display: inline-block;white-space: normal;vertical-align: top;}
</style>

<template>
	<div style="background-color:#f8f9fb;">
		<div class='header'></div>
		<div style="padding:0.3rem;">
			<stepMenu :step='2' :menu="['选择地区','基本信息','事项描述']"></stepMenu>
			<div style="padding:0.3rem"></div>
			<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
				<FormItem label="" prop="sfcl">
				    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证件：</div>
				    <div style="text-align: center;">
				      <Upload
				        action="https://vn-server.lvwa18.com/api/upload_file_H5"
				    　　:on-success="Picture"
				        :default-file-list="uploadPicture"
				        :on-remove="deleteValuePicture" accept=".jpg,.bmp"
				      >
				        <img src="/img/legalAid/IDcard.png" style="width:4.74rem;height:3.3rem;"/>
				        <div>点击拍摄/更换身份证件照片</div>
				        <div>1.身份证件上的信息不能被遮挡，且清晰可见；</div>
				        <div>2.支持jpg/bmp格式，最大不超过2MB。</div>
				      </Upload>
				    </div>
				</FormItem>

				<template v-if="formValidate.sfcl">
					<FormItem label="" prop="zxr" >
					    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>申请人：</div>
					    <Input v-model="formValidate.zxr" placeholder=""></Input>
					</FormItem>
					<div class="bold">请选择申请法律援助事项类别<span style="color: red;padding: 0 0.1rem;">*</span></div>
					<FormItem prop="zxsxlxzx" >
					    <RadioGroup v-model="formValidate.zxsxlxzx">
					        <Radio label="请求给付赡养费、抚养费、扶养费"><span>请求给付赡养费、抚养费、扶养费</span></Radio><br/>
					        <Radio label="请求发给抚恤金、救济金">请求发给抚恤金、救济金</Radio><br/>
					        <Radio label="依法请求国家赔偿或者行政补偿">依法请求国家赔偿或者行政补偿</Radio><br/>
					        <Radio label="请求给予社会保险待遇或者最低生活保障待遇">请求给予社会保险待遇或者最低生活保障待遇</Radio><br/>
					        <Radio label="因遭受家庭暴力、虐待、遗弃等侵权主张婚姻家庭民事权益"><span class="text-break">因遭受家庭暴力、虐待、遗弃等侵权主张婚姻家庭民事权益</span></Radio><br/>
					        <Radio label="因交通事故、医疗损害、工伤事故、食品药品安全事故或者环境污染等侵权行为造成损害请求赔偿"><span class="text-break">因交通事故、医疗损害、工伤事故、食品药品安全事故或者环境污染等侵权行为造成损害请求赔偿</span></Radio><br/>
					        <Radio label="因使用伪劣化肥、农药、种子、农机具和其他伪劣产品造成损害请求赔偿"><span class="text-break">因使用伪劣化肥、农药、种子、农机具和其他伪劣产品造成损害请求赔偿</span></Radio><br/>
					        <Radio label="劳动者（雇员）与用人单位（雇主）发生争议，请求保护劳动（民事）权益"><span class="text-break">劳动者（雇员）与用人单位（雇主）发生争议，请求保护劳动（民事）权益</span></Radio><br/>
					        <Radio label="因见义勇为或者为保护社会公共利益致使其合法权益受到损害，请求赔偿或者补偿"><span class="text-break">因见义勇为或者为保护社会公共利益致使其合法权益受到损害，请求赔偿或者补偿</span></Radio>
					        <Radio label="其他">其他</Radio>
					    </RadioGroup>
					</FormItem>
					<!---->
					<FormItem label="" prop="xb">
					    <div><span style="color: red;padding: 0 0.1rem;">*</span>性别：</div>
					    <RadioGroup v-model="formValidate.xb">
					        <Radio label="男">男</Radio>
					        <Radio label="女">女</Radio>
					    </RadioGroup>
					</FormItem>
					<!---->
					<FormItem label="" prop="zjhm">
					    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>身份证号码：</div>
					    <Input v-model="formValidate.zjhm" placeholder=""/>
					</FormItem>
					<!---->
					<FormItem label="" prop="mz">
					    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>民族：</div>
					    <Select v-model="formValidate.mz" placeholder="">
					        <Option :value="value.EthnicityName" v-for="(value,index) in nation">{{value.EthnicityName}}</Option>
					    </Select>
					</FormItem>
					<!---->
					<FormItem label="" prop="csrq">
					    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>出生日期：</div>
					    <Input v-model="formValidate.csrq" placeholder="如：xxxx-xx-xx"></Input>
					</FormItem>
					<!---->
					<FormItem label="" prop="nl">
					    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>年龄：</div>
					    <Input v-model="formValidate.nl" placeholder=""></Input>
					</FormItem>
					<!---->
					<!--<FormItem label="" prop="city">-->
					<!--<div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>住所地址：</div>-->
					<!--<Cascader :data="address" v-model="formValidate.dz"></Cascader>-->
					<!--</FormItem>-->
					<FormItem label="" prop="dz">
					    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>请填写住所地址：</div>
					    <Input v-model="formValidate.dz" placeholder="请填写住所地址"></Input>
					</FormItem>
					<!---->
					<FormItem label="" prop="lxdh">
					    <div class="inline-block"><span style="color: red;padding: 0 0.1rem;">*</span>联系电话：</div>
					    <Input v-model="formValidate.lxdh" placeholder="" type="number"></Input>
					</FormItem>
					<Checkbox  v-model="social" style="color:red">本人承诺以上所填内容和提交的证件、证明材料均为真实</Checkbox>
					<div style="padding: 0.3rem;"></div>
						<div style="text-align: center;">
							<router-link :to="{name:'legalAidStep1'}">
							<Button type="primary" style="width:3rem;">上一步</Button>
							</router-link>
							<span style="padding:0 0.3rem"></span>
							<router-link :to="{name:'legalAidStep3'}">
							<Button type="primary" style="width:3rem;">下一步</Button>
							</router-link>
						</div>
				</template>

        <!---->
			</Form>

		</div>
	</div>
</template>

<script>
	import stepMenu from '../../../components/stepMenu.vue'
	export default{
		name:'step2',
		data(){
			return{
				nation : [],
				social:false,
				uploadPicture:[],
			}
		},
		mounted(){
			var _this = this
			_this.setPageSize()
			_this.ethnicityList()
		},
		beforeRouteLeave:function(to, form, next){
			if(to.name=='legalAidStep3'){
        this.$refs.formValidate.validate((valid) => {
					if(valid==false){
						this.$Message.error('请录入完成后再进行下一步操作!');
					}else{
						if(this.social){
							next()
						}else{
							this.$Message.info('请选中本人承诺以上所填内容和提交的证件、证明材料均为真实！')
						}
					}
				})
			}else{
				next()
			}
		},
		components:{
			stepMenu:stepMenu,
		},
		computed:{
			ruleValidate:function(){
				return this.$store.state.legalAidData.ruleValidate
			},
			formValidate:function(){
				return this.$store.state.legalAidData.formValidate
			},
		},
		methods:{
			ethnicityList : function(){
			    var _this = this
			    _this.send({
			        url : '/EthnicityList',
			        success: function (res) {
			            _this.nation = res
			        }
			    })
			},
			deleteValuePicture : function(file, fileList){
			    var _this = this
			    _this.formValidate.sfclmc = ''
			    _this.formValidate.sfcl = ''
					_this.uploadPicture = fileList
			},
			Picture : function(response, file, fileList){
			    var _this = this
					_this.send({
						url:'/Identification',
						data:{
							ImgPath:window.sessionStorage.url+response.Data.path
						},
						success:function(res){
							var birthday = res.Birth.split('/')
							if(birthday[1].length<2){
								birthday[1] = '0'+birthday[1]
							}
							if(birthday[2].length<2){
								birthday[2] = '0'+birthday[2]
							}
							_this.formValidate.sfclmc = response.Data.name;
							_this.formValidate.sfcl = window.sessionStorage.url+response.Data.path;
							_this.uploadPicture = fileList;
							_this.formValidate.zxr = res.Name
							_this.formValidate.xb = res.Sex
							_this.formValidate.zjhm = res.IdNum
							_this.formValidate.mz = res.Nation+'族'
							_this.formValidate.csrq = `${birthday[0]}-${birthday[1]}-${birthday[2]}`
							_this.formValidate.dz = res.Address
						}
					})
			},
			setPageSize:function() {
			    var wsw = document.body.clientWidth
			    document.getElementsByTagName("html")[0].style.fontSize = wsw / 750 * 100 + "px"
			},
		},
	}
</script>
