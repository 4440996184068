<style scoped>
  .bg{background-color: #008fd6;position: absolute;top: 0;left: 0;right: 0;padding:0.2rem;min-height:100%;}
  .introduce-video{text-align:center;display: inline-block;padding:0.2rem;border-radius:0.1rem;background-color:rgba(160,160,160,0.5);}
  .introduce-video-player{width:100%;}
  .mediator-list{color:#FFF;}
  .mediator-list-title{font-size:0.28rem;font-weight:bold;margin-bottom:0.2rem;}
  .mediator-list-content{font-size:0.24rem;margin:0.1rem 0;}
  .introduce-text{background-color:#FFF;padding:0.4rem 0.2rem;border-radius:0.1rem;}
  .introduce-text-title{color:#008fd6;font-size:0.26rem;font-weight:bold;text-align: center;margin-bottom: 0.25rem;}
  .introduce-text-content{color:#999999;font-size:0.26rem;text-indent:2em;line-height:1.7em;}
  .lump{
    width: 6.9rem;
    background: #FFFFFF;
    box-shadow: 0px 0.2rem 0.8rem 0rem rgb(20 98 250 / 22%);
    border-radius: 0.2rem;
    font-size: 0.26rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #999999;
    line-height: 0.30rem;
    display: inline-block;
    margin: 0 0rem 0.3rem 0rem;
    vertical-align: top;
    padding: 0.3rem;
  }
</style>

<template>
  <div class="bg">
    <div style="text-align: center;width:100%;">
      <div class="introduce-video">
        <video class="introduce-video-player" preload controls src="/static/customization/jian-yang/videos/社区矫正一分钟简阳版.mp4"></video>
      </div>
    </div>
    <div class="introduce-text">
      <div class="introduce-text-title">社区矫正</div>
      <div class="introduce-text-content">
        社区矫正是与监禁矫正相对的行刑方式，是指将符合社区矫正的罪犯置于社区内，由专门国家机关在相关社会团体和民间组织以及社会志愿者协助下，在判决、裁定或决定确定的期限内，矫正其犯罪心理和行为恶习，并促使其顺利回归社会的非监禁刑事执行活动。
      </div>
    </div>
    <div style="margin-top: 0.3rem">
      <div class="lump" @click="download('https://ai-cdn.lvwa18.com/img/rusticate/files/jian-yang/关于印发《关于村（居）民委员会依法协助做好社区矫正工作的实施意见》的通知.pdf')">
        <div style="width: 5.5rem;display: inline-block">1、关于村（居）民委员会依法协助做好社区矫正工作的实施意见</div>
        <div style="display: inline-block;color: #00a0e9;float: right;margin-top: 0.15rem">
          <div style="">查看</div>
        </div>
      </div>
      <div class="lump" @click="download('https://ai-cdn.lvwa18.com/img/rusticate/files/jian-yang/社区矫正法.pdf')">
        2、社区矫正法
        <div style="display: inline-block;color: #00a0e9;float: right">
          <div style="">查看</div>
        </div>
      </div>
      <div class="lump" @click="download('https://ai-cdn.lvwa18.com/img/rusticate/files/jian-yang/社区矫正法实施办法.pdf')">
        3、社区矫正法实施办法
        <div style="display: inline-block;color: #00a0e9;float: right">
          <div style="">查看</div>
        </div>
      </div>
      <div class="lump" @click="download('https://ai-cdn.lvwa18.com/img/rusticate/files/jian-yang/四川省社区矫正实施细则.pdf')">
        4、四川省社区矫正实施细则
        <div style="display: inline-block;color: #00a0e9;float: right">
          <div style="">查看</div>
        </div>
      </div>
    </div>
    <div style="text-align:right;"><Button type="warning" style="margin-top:0.2rem;" :to="{name:'CJYCL'}">查看矫正课程 >>></Button></div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";

export default {
  name:'introduce',
  data() {
    return {
    };
 },
  mounted() {
  },
  methods: {
    download : function (url) {
      wx.miniProgram.navigateTo({
        url: `/pages/userSide/download/download?url=`+`${url}`+'&type='+1+''
      })
    }
  },
  computed:{
  },
}
</script>