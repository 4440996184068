<style scoped>
.header {
  background-image: url(/img/mediate/banner.png);
  height: 2.6rem;
  background-size: 100% auto;
  background-repeat: no-repeat;
}
</style>

<template>
  <div style="background-color:#f8f9fb;">
    <div class="header"></div>
    <div style="padding:0.3rem">
      <stepMenu :step="3" :menu="['纠纷类型','基本信息','机构所在']"></stepMenu>
      <div style="padding:0.3rem"></div>
      <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0">
        <FormItem label prop>
          <div class="inline-block">
            <span style="color: red;padding: 0 0.1rem;">*</span>机构所在：
          </div>
          <br />
          <div style="text-align: center" v-if="AreaId == '1238'">
            <FormItem label prop="orgCity">
              <Select v-model="formValidate.orgCity">
                <Option value="江西省">江西省</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.orgArea">
                <Option value="赣州市">赣州市</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.orgZhen">
                <Option value="崇义县">崇义县</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.ren">
                <Option value="崇义县调解委员会">崇义县调解委员会</Option>
              </Select>
            </FormItem>
          </div>
          <div style="text-align: center" v-else-if="AreaId == '1681'">
            <FormItem label prop="orgCity">
              <Select v-model="formValidate.orgCity">
                <Option value="湖北省">湖北省</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.orgArea">
                <Option value="襄阳市">襄阳市</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.orgZhen">
                <Option value="谷城县">谷城县</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.ren">
                <Option value="谷城县调解委员会">谷城县调解委员会</Option>
              </Select>
            </FormItem>
          </div>
<!--          <div style="text-align: center" v-else-if="AreaId == '1505'">-->
<!--            <FormItem label prop="orgCity">-->
<!--              <Select v-model="formValidate.orgCity">-->
<!--                <Option value="河南省">河南省</Option>-->
<!--              </Select>-->
<!--            </FormItem>-->
<!--            <div style="padding:5px;"></div>-->
<!--            <FormItem label prop="orgArea">-->
<!--              <Select v-model="formValidate.orgArea">-->
<!--                <Option value="驻马店市">驻马店市</Option>-->
<!--              </Select>-->
<!--            </FormItem>-->
<!--            <div style="padding:5px;"></div>-->
<!--            <FormItem label prop="orgArea">-->
<!--              <Select v-model="formValidate.orgZhen">-->
<!--                <Option value="上蔡县">上蔡县</Option>-->
<!--              </Select>-->
<!--            </FormItem>-->
<!--            <div style="padding:5px;"></div>-->
<!--            <FormItem label prop="orgArea">-->
<!--              <Select v-model="formValidate.ren">-->
<!--                <Option value="上蔡县调解委员会">上蔡县调解委员会</Option>-->
<!--              </Select>-->
<!--            </FormItem>-->
<!--          </div>-->
          <div style="text-align: center" v-else-if="AreaId == '649'">
            <FormItem label prop="orgCity">
              <Select v-model="formValidate.orgCity">
                <Option value="黑龙江省">黑龙江省</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.orgArea">
                <Option value="哈尔滨市">哈尔滨市</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.orgZhen">
                <Option value="松北区">松北区</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.ren">
                <Option value="松北区调解委员会">松北区调解委员会</Option>
              </Select>
            </FormItem>
          </div>
          <div style="text-align: center" v-else-if="AreaId == '2726'">
            <FormItem label prop="orgCity">
              <Select v-model="formValidate.orgCity">
                <Option value="西藏自治区">西藏自治区</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.orgArea">
                <Option value="拉萨市">拉萨市</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.orgZhen">
                <Option value="曲水县">曲水县</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.ren">
                <Option value="曲水县调解委员会">曲水县调解委员会</Option>
              </Select>
            </FormItem>
          </div>
          <div style="text-align: center" v-else-if="AreaId == '348'">
            <FormItem label prop="orgCity">
              <Select v-model="formValidate.orgCity">
                <Option value="内蒙古自治区">内蒙古自治区</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.orgArea">
                <Option value="呼和浩特市">呼和浩特市</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.orgZhen">
                <Option value="和林格尔县">和林格尔县</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea">
              <Select v-model="formValidate.ren">
                <Option value="和林格尔县调解委员会">和林格尔县调解委员会</Option>
              </Select>
            </FormItem>
          </div>
          <div style="text-align: center" v-else>
            <FormItem label prop="orgCity">
              <Select v-model="formValidate.orgCity">
                  <Option v-for="(value,index) in mediate.loadcity" :value="value">{{ value }}</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea" v-if="mediate.orgArea != ''">
              <Select v-model="formValidate.orgArea">
                <Option v-for="(value,index) in mediate.orgArea" :value="value">{{ value }}</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea" v-if="mediate.orgZhen.length">
              <Select v-model="formValidate.orgZhen">
                <Option v-for="(value,index) in mediate.orgZhen" :value="value">{{ value }}</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea" v-if="mediate.hui.length">
              <Select v-model="hui1">
                <Option v-for="(value,index) in mediate.hui" :value="value.code+','+value.mc">{{ value.mc }}</Option>
              </Select>
            </FormItem>
            <div style="padding:5px;"></div>
            <FormItem label prop="orgArea" v-if="mediate.ren.length">
              <Select v-model="formValidate.ren">
                <Option v-for="(value,index) in mediate.ren" :value="value">{{ value }}</Option>
              </Select>
            </FormItem>
          </div>
        </FormItem>
      </Form>
      <div style="text-align: center;">
				<router-link :to="{name:'mediateStep2'}">
					<Button type="primary" style="width:3rem;">上一步</Button>
				</router-link>
				<span style="padding:0 0.3rem"></span>
				<Button type="primary" style="width:3rem;" @click="submit">提交</Button>
			</div>
    </div>
      <Spin size="large" fix v-if="spinShow"></Spin>
  </div>
</template>

<script>
import stepMenu from "../../../components/stepMenu.vue";
export default {
  name: "step3",
  data() {
    return {
      hui1 : '',
      mediate: {
        loadcity :[],
        orgArea:[],
        orgZhen:[],
        hui:[],
        ren:[],
        AreaId : '',
      },
        spinShow : false,
    };
  },
  mounted() {
    var _this = this;
    _this.AreaId = window.sessionStorage.AreaId
    _this.setPageSize();
    _this.loadcity()
  },
  components: {
    stepMenu: stepMenu
  },
  computed: {
    ruleValidate: function() {
      return this.$store.state.mediateData.ruleValidate;
    },
    formValidate: function() {
      return this.$store.state.mediateData.formValidate;
    }
  },
  watch:{
    'formValidate.orgCity':function (val) {
      this.formValidate.orgArea = ''
      this.formValidate.orgZhen = ''
      this.formValidate.hui = ''
      this.hui1 = ''
      this.formValidate.ren = ''
      val?this.mediate1():''
    },
    'formValidate.orgArea':function (val) {
      this.formValidate.orgZhen = ''
      this.formValidate.hui = ''
        this.hui1 = ''
      this.formValidate.ren = ''
      val?this.mediate2():''
    },
    'formValidate.orgZhen':function (val) {
      this.formValidate.hui = ''
        this.hui1 = ''
      this.formValidate.ren = ''
      val?this.mediate3():''
    },
    'hui1':function (val) {
      this.formValidate.ren = ''
      val?this.mediate4():''
    },
  },
  methods: {
    setPageSize: function() {
      var wsw = document.body.clientWidth;
      document.getElementsByTagName("html")[0].style.fontSize =
        (wsw / 750) * 100 + "px";
    },
    submit : function(name){
        var _this = this
        _this.spinShow = true
        _this.$refs.formValidate.validate((valid) => {
            if (valid) {
              if(_this.formValidate.ren == ''){_this.formValidate.ren = '暂无'}
                _this.send({
                    url : '/mediate',
                    data : {
                        Content : _this.formValidate,
                        UserId : window.sessionStorage.userId,
                        AreaId : window.sessionStorage.AreaId
                    },
                    success: function (res) {
                        _this.spinShow = false
                        console.log(res)
                        if(res == true){
                            _this.$Modal.success({
                              title:'系统通知',
                              content:'人民调解申请成功！',
                              onOk:function(){
                                _this.$router.push({name:'mediateListH'})
                              }
                            })
                            // _this.$Message.success('操作成功')
                            // window.history.go(-1)
                        }else{
                            // const reg = /[\u4e00-\u9fa5]/g;
                            // console.log(res.match(reg).join(''))
                            // _this.$Message.success(res.match(reg).join(''))
                            _this.$Message.error('数据填写有误，请核对后提交！')
                        }
                        // _this.nation = res
                    },
                    error : function (res) {
                        console.log(res)
                    }
                })
            } else {
                _this.spinShow = false
                this.$Message.error('请输入完成后提交!');
            }
        })
    },
    mediate1 : function(){
            var _this = this
            _this.send({
                url : '/loadarea',
              data:{
                  AreaId : window.sessionStorage.CityId,
                  city:_this.formValidate.orgCity,
              },
                success: function (res) {
                    _this.mediate.orgArea = res.data
                }
            })
        },
      mediate2 : function(){
        var _this = this
        _this.send({
          url : '/loadzhen',
          data:{
            AreaId : window.sessionStorage.CityId,
            city:_this.formValidate.orgCity,
            area:_this.formValidate.orgArea,
          },
          success: function (res) {
            _this.mediate.orgZhen = res.data
          }
        })
      },
      mediate3 : function(){
        var _this = this
        _this.send({
          url : '/loadhui',
          data:{
            AreaId : window.sessionStorage.CityId,
            city:_this.formValidate.orgCity,
            area:_this.formValidate.orgArea,
            zhen:_this.formValidate.orgZhen,
          },
          success: function (res) {
            _this.mediate.hui = res.data
          }
        })
      },
      mediate4 : function(e){
        var _this = this
          console.log(_this.hui1.split(","))
          _this.formValidate.hui = _this.hui1.split(",")[1]
        _this.send({
          url : '/loadren',
          data:{
            city:_this.formValidate.orgCity,
            area:_this.formValidate.orgArea,
            code:_this.hui1.split(",")[0],
          },
          success: function (res) {
            _this.mediate.ren = res.data

          }
        })
      },
      loadcity : function () {
            var _this = this
            _this.send({
              url : '/loadcity',
              data:{AreaId : window.sessionStorage.CityId},
              success: function (res) {
                _this.mediate.loadcity = res.data
              }
            })
          },
  }
};
</script>
