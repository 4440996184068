<style scoped>
.tableTitle{font-size:20px;}
.export-btn{font-size:10px;color:#2d8cf0;cursor:pointer;}
</style>

<template>
  <div>
    <div style="padding:10px"></div>
    <div style="text-align: center;">
      <span class="tableTitle">顾问村居治理服务统计表</span>
      <div class="export-btn" @click="$emit('exportCsv',{dom:$refs.table,fileName:'顾问村居治理服务统计表'})">[导出数据]</div>
    </div>
    <div style="padding:5px"></div>
    <Table height="500" border :loading="table.loading" :columns="table.columns" :data="table.data" :resizable="true" ref="table" show-summary :summary-method="handleSummary"></Table>
    <div style="padding:20px"></div>
  </div>
</template>

<script>
//表头控制文件
import * as tableColumns from "./table-columns"
import * as tableDataSorting from "./data-sorting"
export default {
  name:'adviserGovernmentTable',//分区域总统计表
  props:{
    dateSelecter:{type:Object,required:true,},
  },
  data:function(){
    return{
      table:{
        loading:true,
        columns:tableColumns.adviserGovernmentTable,
        data:[],
      },
    }
  },
  watch:{
    'dateSelecter.identification':function(val){
      if(val != 'custom'){
        this.getTableData()
      }
    }
  },
  mounted:function(){
    this.getTableData()
  },
  methods:{
    handleSummary ({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          };
          return;
        }
        const values = data.map(item => Number(item[key]));
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[key] = {
            key,
            value: v + ''
          };
        } else {
          sums[key] = {
            key,
            value: window.sessionStorage.AdminName
          };
        }
      });

      return sums;
    },
    //区域模块访问统计表
    getTableData:function(){
      var _this = this
      _this.table.loading = true
      _this.table.data = []
      _this.$parent.requestHub([
        "/ReportLawyerWritData", //累计代拟文书
        "/ReportLCheckFileData", //累计审核文件
        "/ReportLAttendCfData", //累计列席会议
        "/ReportLCampaignData", //累计参加活动
        "/ReportLawyerPreachData", //累计法治宣讲
        "/ReportLawyerInMediateData", //累计参与调解
        "/ReportLPetitionDealData", //累计接处信访
        "/ReportLawyerDutyData", //累计值班接待
        "/ReportLawyerOtherData", //累计其他服务
        "/ReportLawyerServiceFileData", //累计服务档案
        "/ReportLawyerEvaluateData", //累计服务评价
    ])
          .then(res=>{
            _this.table.loading = false
            _this.table.data = tableDataSorting.dataMerge(res,{local:'lawyerId',remote:'LawyerId'},[
              {columnName:'lawyerName',dataIndex:0,remoteVariable:['LawyerName']},
              {columnName:'newReportLawyerWrit',dataIndex:0,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLawyerWrit',dataIndex:0,remoteVariable:['Count','Total']},
              {columnName:'newReportLCheckFile',dataIndex:1,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLCheckFile',dataIndex:1,remoteVariable:['Count','Total']},
              {columnName:'newReportLAttendCf',dataIndex:2,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLAttendCf',dataIndex:2,remoteVariable:['Count','Total']},
              {columnName:'newReportLCampaign',dataIndex:3,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLCampaign',dataIndex:3,remoteVariable:['Count','Total']},
              {columnName:'newReportLawyerPreach',dataIndex:4,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLawyerPreach',dataIndex:4,remoteVariable:['Count','Total']},
              {columnName:'newReportLawyerInMediate',dataIndex:5,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLawyerInMediate',dataIndex:5,remoteVariable:['Count','Total']},
              {columnName:'newReportLPetitionDeal',dataIndex:6,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLPetitionDeal',dataIndex:6,remoteVariable:['Count','Total']},
              {columnName:'newReportLawyerDuty',dataIndex:7,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLawyerDuty',dataIndex:7,remoteVariable:['Count','Total']},
              {columnName:'newReportLawyerOther',dataIndex:8,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLawyerOther',dataIndex:8,remoteVariable:['Count','Total']},
              {columnName:'newReportLawyerServiceFile',dataIndex:9,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLawyerServiceFile',dataIndex:9,remoteVariable:['Count','Total']},
              {columnName:'newReportLawyerEvaluate',dataIndex:10,remoteVariable:['Count','Intime']},
              {columnName:'totalReportLawyerEvaluate',dataIndex:10,remoteVariable:['Count','Total']},
            ])
          })
          .catch(err=>{console.log("区域模块访问统计表：失败！")})
    },
  },
}
</script>
