import nullPage from '@/views/nullPage'

const dataCenter = {
    bigData: require('@/views/manager/data-center/big-data').default,
    chengDu: require('@/views/manager/data-center/cheng-du/chengDu').default
}

const routes = [
    { path: 'big-data', component: dataCenter.bigData, name: 'MBDN', meta: { title: '大数据中心' } },
    { path: 'cheng-du/big-data', component: dataCenter.chengDu, name: 'MDCB', meta: { title: '成都大数据中心' } }
]

export default {
    routes: routes
}
