<style scoped>
</style>

<template>
  <div>
    <template  v-if="state=='edit'">
      <div>{{indexQ+1}}、判断题<Icon class="close-icon" type="md-close-circle" @click="$emit('del',indexQ)"></Icon></div>
      <Divider />
      <Row>
        <Col span="4" style="text-align: right;">题目：</Col>
        <Col span="20"><Input v-model="content.title" placeholder="请录入题目"/></Col>
      </Row>
      <Row>
        <Col span="4" style="text-align: right;">分值：</Col>
        <Col span="20" style="text-align: left;">
          <Input style="width:40px;" size="small" v-model="content.score"/> 分
        </Col>
      </Row>
      选择答案：
      <Checkbox true-value="T" false-value="F" v-model="content.answer">对</Checkbox>
      <Checkbox true-value="F" false-value="T" v-model="content.answer">错</Checkbox>
    </template>
    <template  v-if="state=='read'">
      <div>
        {{indexQ+1}}、判断题：{{content.title}}（{{content.score}}分）
        <Checkbox disabled true-value="T" false-value="F" v-model="content.userAnswer">对</Checkbox>
        <Checkbox disabled true-value="F" false-value="T" v-model="content.userAnswer">错</Checkbox>
      </div>
      <div>正确答案：{{content.answer=='T'?'对':'错'}}</div>
      <div>得分情况：{{content.userScore}}分</div>
    </template>
    <template  v-if="state=='grade'">
      <div style="text-align: left;">
        <div>
          {{indexQ+1}}、判断题：{{content.title}}（{{content.score}}分）
          <Checkbox disabled true-value="T" false-value="F" v-model="content.userAnswer">对</Checkbox>
          <Checkbox disabled true-value="F" false-value="T" v-model="content.userAnswer">错</Checkbox>
        </div>
        <div style="text-indent:1em;">
          <div>正确答案：{{content.answer=='T'?'对':'错'}}</div>
          <div>用户得分 <Input style="width:40px;" size="small" v-model="content.userScore"/></div>
        </div>
      </div>
    </template>
      <template  v-if="state=='answer'">
        <div>
          {{indexQ+1}}、判断题：{{content.title}}（{{content.score}}分）
          <Checkbox true-value="T" false-value="F" v-model="content.userAnswer">对</Checkbox>
          <Checkbox true-value="F" false-value="T" v-model="content.userAnswer">错</Checkbox>
        </div>
          
      </template>
  </div>
</template>

<script>
export default {
  name:'examTof',
  props:{
    state:String,
    indexQ:Number,
    contentIn:Object,
  },
  data() {
    return {
      content:{
        title:'',
        answer:'',
        userAnswer:'',
        score:'',
        must:false,
        allowSubmission:false,
        userScore:'',
      },
    };
 },
  methods: {
    
  },
  mounted() {
    if(this.contentIn.title!=undefined){
      this.content = this.contentIn
    }
  },
  watch:{
    content:{
      handler(){
        if(this.content.must!=true||!this.isEmpty(this.content.selected)){
          this.content.allowSubmission = true
        }else{
          this.content.allowSubmission = false
        }
        this.$emit('change',this.content)
      },
      deep:true,
      immediate:true,
    }
  }
}
</script>