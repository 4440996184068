<style scoped>
</style>

<template>
  <div>
    <div style="padding:0.2rem;">{{title}}</div>
    <Divider />
    <div style="padding:0.2rem;" v-html="content"></div>
    <Divider />
    <div style="padding:0.2rem;text-align: right;">
      <span style="padding:10px;"></span>
      <Button type="primary" style="padding:0 0.5rem;" :to="{name:'CJYCE',query:{correctionId:$route.query.correctionId}}">
        开始考试 >>>
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  name:'detial',
  data() {
    return {
      title:'',
      content:'',
    };
 },
  methods: {
    getDetial:function(){
      var _this = this
      _this.send({
        url:'/CurriculumContentAdmin',
        data:{
          CurriculumId:_this.$route.query.correctionId
        },
        success:function(res){
          _this.title = res.CurriculumTitle
          _this.content = res.CurriculumContent
        }
      })
    },
    setReadState:function(){
      var _this = this
      _this.send({
        url:'/NewUserStudyAdmin',
        data:{
          UserId:_this.$store.state.userInfo.UserId,
          CurriculumId:_this.$route.query.correctionId,
        }
      })
    },
  },
  mounted() {
    this.getDetial()
    this.setReadState()
  },
}
</script>
