<template>
  <div style="padding:20px;">
    <div class="publicWelfare">
<!--      <Row style="margin: 20px">-->
<!--        <Col span="12">-->
<!--          <Input-->
<!--              v-model="lawyerList.key"-->
<!--              search-->
<!--              enter-button="搜索"-->
<!--              placeholder="搜索顾问姓名/执业机构/村（社区）"-->
<!--              @on-search="switchClick(tabsType)"-->
<!--          />-->
<!--        </Col>-->
<!--      </Row>-->
      <!---->
      <table class="formtable">
        <thead>
        <tr>
          <th>序号</th>
          <th>姓名</th>
          <th>移动电话</th>
          <th>执业机构</th>
          <th>执业证号</th>
          <th>擅长领域</th>
          <th>操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(value, index) in lawyerList.data">
          <td v-if="(lawyerList.page - 1) * lawyerList.pageSize + index + 1 < 10">0{{ (lawyerList.page - 1) * lawyerList.pageSize + index + 1 }}</td>
          <td v-else>{{ (lawyerList.page - 1) * lawyerList.pageSize + index + 1 }}</td>
          <td><Avatar :src="value.LawyerImg" style="margin-right: 15px" />{{ value.LawyerName }}</td>
          <td>{{ value.LawyerPhone }}</td>
          <td>{{ value.AdminName }}</td>
          <td>{{ value.PracticeNumber || '--' }}</td>
          <td>
            <div v-if="value.skill_ids">
              <Tooltip placement="top" max-width="300">
                <div slot="content">
                                <span  v-for="(item,i) in value.skill_ids">
                                  {{item.keyword}}{{i+1 < value.skill_ids.length ?'、':''}}
                                </span>
                </div>
                <div style="display: inline-block" v-if="i < 3" v-for="(item,i) in value.skill_ids">
                  {{item.keyword}}<span v-if="i < 2">{{i+1 < value.skill_ids.length ?'、':''}}</span>
                </div>
              </Tooltip>
            </div>
            <div v-else>--</div>
          </td>
          <td>
            <div v-if="value.isCheck == null">
              <Button type="primary" @click="toExamine(1,value.LawyerId)">通过</Button>
              <div style="display: inline-block;width: 10px"></div>
              <Button type="warning" @click="toExamine(2,value.LawyerId)">驳回</Button>
            </div>
            <div v-else>
              <template v-if="value.isCheck == 1"><Tag color="green">审核通过</Tag></template>
              <template v-if="value.isCheck == 2"><Tag color="orange">已驳回</Tag></template>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <Page
          :total="lawyerList.total"
          show-total
          :page-size="lawyerList.pageSize"
          show-elevator
          @on-change="
                    lawyerList.page = $event
                    serachPublicBenefitLawByAdmin()
                "
          :current="lawyerList.page"
      />
      <div style="padding: 10px"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'publicInterestLawyer',

  data() {
    return {
      tabsType : '1',
      lawyerList : {
        data: [],
        total: 0,
        page: 1,
        pageSize: 15,
        key: '',
      },
    }
  },
  methods: {
    toExamine: function(isCheck, LawyerId) {
      var _this = this
      _this.send({
        url: '/CheckPublicLaw',
        confirm: true,
        data: {
          isCheck: isCheck,
          LawyerId: LawyerId,
          AdminId: window.sessionStorage.AdminId,
        },
        success: function(res) {
          _this.serachPublicBenefitLawByAdmin()
        }
      })
    },
    // 数据列表
    serachPublicBenefitLawByAdmin: function (value) {
      var _this = this
      _this.send({
        url: '/CheckPublicLawList',
        loading: true,
        data: {
          AdminId: window.sessionStorage.AdminId,
          key: _this.lawyerList.key,
          page: _this.lawyerList.page,
          pageSize: _this.lawyerList.pageSize
        },
        success: function (res) {
          _this.lawyerList.data = res.data
          _this.lawyerList.total = res.Count
        }
      })
    },
  },

  mounted() {
    var _this = this
    _this.adminLevel = window.sessionStorage.adminLevel
    _this.serachPublicBenefitLawByAdmin()
  },

  computed:{
    assetsLink:function(){
      return `${SYSCONFIG.server.default.ssl?'https':'http'}://${SYSCONFIG.server.default.host}:${SYSCONFIG.server.default.port}/`
    }
  },
}
</script>

<style scoped>

.forMcontent p {
  line-height: 60px;
  display: inline-block;
  width: 50%;
}
.forMtitle1 p {
  display: inline-block;
  color: #333333;
  font-size: 16px;
  line-height: 60px;
  overflow: hidden;
  white-space: nowrap;
  width: 20%;
}
.forMtitle p {
  display: inline-block;
  color: #333333;
  font-size: 16px;
  width: 50%;
  line-height: 60px;
  overflow: hidden;
  white-space: nowrap;
}

.administration p {
  font-size: 20px;
  color: #333333;
  display: inline-block;
  line-height: 80px;
}

.choiceLawyer :deep(.ivu-select-dropdown ){
  max-height: none;
}
.choiceLawyer :deep(.ivu-page ){
  margin-left: 30px;
}
.formtable {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
}
/*表格*/
.publicWelfare table {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 12px;
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show;
  border: 1px solid #e9e9e9;
  width: 100%;
  margin-bottom: 24px;
  text-align: center;
}
.publicWelfare table th {
  background: #f7f7f7;
  white-space: nowrap;
  color: #5c6b77;
  font-weight: 600;
  text-align: center;
}

.publicWelfare table td,
.publicWelfare table th {
  border: 1px solid #e9e9e9;
  padding: 8px 16px;
}
.placeholderColor /deep/ input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #515a6e;
}
.placeholderColor /deep/ input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #515a6e;
}
.placeholderColor /deep/ input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #515a6e;
}
.placeholderColor /deep/ input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #515a6e;
}
</style>
