<style scoped>
.loading{animation: ani-demo-spin 1s linear infinite;}
.video-full{position:absolute;top:0;bottom:2rem;background-color:black;}
.video-small{position:absolute;top:0.2rem;right:0.2rem;height:3rem;width:2rem;background-color:black;z-index:2;}
</style>

<template>
  <div style="position:absolute;height:100%;width:100%;">
		<Spin fix v-if="!videoState.link">
			<Icon type="ios-loading" size=18 class="loading"></Icon>
			<div>等待对方接听中</div>
		</Spin>
		<div style="position:absolute;top:0;bottom:0;left:0;right:0;">
			<div id="localVideo" :class="videoState.videoView=='own'?'video-full':'video-small'" @click="changeView()"></div>
			<div id="remoteVideo" v-show="videoState.link" :class="videoState.videoView=='own'?'video-small':'video-full'" @click="changeView()"></div>
		</div>
		<div style="height:2rem;position:absolute;bottom:0;width:100%;text-align:center;">
			<!-- <Button type="success" @click="openCamera">开关视频</Button>
			<Button type="primary">开关麦克风</Button>
			<Button type="warning">切换摄像头</Button> -->
			<Button type="error" @click="videoOff">挂断</Button>
		</div>
	</div>
</template>

<script>
import TRTC from 'trtc-js-sdk'
import getUserSig from '@/assets/js/video-call/GenerateTestUserSig'
export default {
  name:'video-call',
  data() {
    return {
      roomId:'',
      timer:{},
			client:{},
      localStream:{},
			videoState:{
				videoView:'own',//remote
				link:false,
			},
    };
  },
  mounted() {
    var _this = this
    _this.roomId = _this.$route.query.roomId||parseInt(Math.random()*100000)
    var userSig
    console.log(_this.roomId)
    var timer = setInterval(()=>{
      if(_this.$store.state.userInfo.UserId){
				clearInterval(timer)
        _this.$store.state.socketFun.sendMessage({
          receiveId:'1'+_this.$store.state.userInfo.LawyerId,
          conversationId:_this.roomId,
          type:'videoCall',
          content:{
            nickName:_this.$store.state.userInfo.UserName,
            type:'call',
            icon:_this.$store.state.userInfo.UserImg
          }
        })
				_this.initRTC()//初始化客户端
				.then(_this.joinRoom)//加入房间
				.then(_this.createStream)//创建流媒体
				.then(_this.playLocalStream)//播放本地流
				.then(_this.pushStream)//推送流
				.then(_this.addClientListen)//增加客户端事件监听
      }
    },500)
  },
  methods: {
		//初始化客户端
		initRTC:function(){
			var _this = this
			return new Promise((resolve,reject)=>{
				_this.client = TRTC.createClient({
				  mode: 'rtc',
				  sdkAppId:1400419031,
				  userId:_this.$store.state.userInfo.UserId.toString(),
				  userSig:getUserSig(_this.$store.state.userInfo.UserId.toString()).userSig,
				})
				resolve()
			})
		},
		//加入房间
		joinRoom:function(){
			var _this = this
			return new Promise((resolve,reject)=>{
				_this.client.join({roomId:parseInt(_this.roomId)})
				.then(() => {
				  console.log('进房成功'+_this.roomId)
					resolve()
				})
				.catch(error => {
				  console.error('进房失败 ' + error);
				})
			})
		},
		//创建流媒体
		createStream:function(){
		  var _this = this
			return new Promise((resolve,reject)=>{
				console.log("初始化本地流")
				_this.localStream = TRTC.createStream({
				  userId:_this.$store.state.userInfo.UserId.toString(),
				  audio: true,
				  video: true
				})
				_this.localStream.initialize()
				.then(() => {
					console.log("初始化本地流成功")
					resolve()
				})
				.catch(error => {
				  console.log('初始化本地流失败')
				  console.error(error);
				})
			})
		},
		//推送流
		pushStream:function(){
			console.log("推送本地流")
			var _this = this
			return new Promise((resolve,reject)=>{
				_this.client.publish(_this.localStream)
				.then(() => {
				  // alert('本地流发布成功')
				  console.log('本地流发布成功')
					resolve()
				})
				.catch(error => {
				  alert('本地流发布失败')
				  console.error(error)
				})
			})
		},
		//播放本地流
		playLocalStream:function(){
			console.log("播放本地流")
			var _this = this
			return new Promise((resolve,reject)=>{
				_this.localStream.play('localVideo')
				resolve()
			})
		},
		//增加客户端事件监听
		addClientListen:function(){
		  // alert("拉取远程流")
		  console.log("拉取远程流")
			var _this = this
			return new Promise((resolve,reject)=>{
				//远程流增加动作
				_this.client.on('stream-added', event => {
				  const remoteStream = event.stream
					//订阅远程流
				  _this.client.subscribe(remoteStream)
					_this.changeView()
				})
				//远程流订阅动作
				_this.client.on('stream-subscribed', event => {
					_this.videoState.link = true
				  const remoteStream = event.stream
					//播放远程流
				  remoteStream.play('remoteVideo')
				});
			})
		},
		
		videoOff:function(){
			this.localStream.removeTrack(this.localStream.getVideoTrack()).then(() => {
        
			})
		},
		closeCamera:function(){
			this.localStream.removeTrack(this.localStream.getVideoTrack()).then(() => {
				videoTrack.stop()
			})
		},
		openCamera:function(){
			this.localStream.addTrack(this.localStream.getVideoTrack()).then(() => {
				console.log('add video call success');
			})
		},
		changeView:function(){
			this.videoState.videoView=='own'?this.videoState.videoView='remote':this.videoState.videoView='own'
		},
		changeCameras:function(){
			var _this = this
			TRTC.getCameras().then(devices => {
			  console.log(devices)
				_this.localStream.switchDevice('video', cameraId).then(() => {
				  console.log('switch camera success');
				});
			});
		},
    
  },
}
</script>