import nullPage from '@/views/nullPage'

const mediation = {
  list:require('@/views/manager/mediation/list').default,
}

const routes = [
  {
    path:'mediation',
    component:nullPage,
    children:[
      {path:'list',component:mediation.list,name:'MML',meta:{title:'律蛙数字法治-智慧司法平台-人民调解'},},
    ]
  },
]

export default {
  routes:routes,
}
