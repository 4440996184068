<template>
<!-- 菜单 -->
  <div>
    <div style="margin: 12px 30px 0 0">
      <Button type="primary" @click="operations(tableData.formItem,'添加菜单')">添加菜单</Button>
    </div>
    <Table :columns="columns" :data="navigationBar" row-key="id">
<!--      <template slot-scope="{ row }" slot="name">-->
<!--        <strong>{{ row.name }}1</strong>-->
<!--      </template>-->
      <template slot-scope="{ row, index }" slot="action">
        <Button type="primary" size="small" style="margin-right: 5px" @click="operations(row,'修改菜单')">修改</Button>
        <Button type="error" size="small" @click="remove(row)">删除</Button>
      </template>
    </Table>
<!--操作当前菜单-->
    <Modal
        v-model="tableData.show"
        :title="tableData.title"
        @on-ok="menuSubmission" @on-cancel="cancel">
      <Form :model="tableData.formItem" :label-width="80">
        <FormItem label="菜单标题">
          <Input v-model="tableData.formItem.name" placeholder="菜单标题"></Input>
        </FormItem>
        <FormItem label="层级">
          <Input v-model="tableData.formItem.sort" placeholder="层级"></Input>
        </FormItem>
        <FormItem label="地址">
          <Input v-model="tableData.formItem.url" placeholder="地址"></Input>
        </FormItem>
        <FormItem label="上级类目">
          <Select v-model="tableData.formItem.pid" style="width:200px">
            <Option value="0" key="0">顶级类目</Option>
            <Option v-for="item in navigationBar" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "menuAdmin",
  data () {
    return {
      navigationBar: [], // 表格数据
      columns: [ // 表格列定义
        { title: '菜单标题', key: 'name', tree: true },
        { title: '菜单地址', key: 'url' },
        { title: '层级', key: 'sort' },
        { title: 'ID', key: 'id' },
        {
          title: '操作',
          slot: 'action',
          width: 150,
          align: 'center'
        }
      ],
    //   表格数据-增删改
      tableData: {
        show: false,
        title: '',
        formItem: {
          id : '',
          pid : 0, // 选择对应的二级菜单 默认0
          level : 0,
          name : '',
          sort : '',
          url : '',
        }
      },
    }
  },
  methods:{
    // 提交当前表单操作
    menuSubmission: function (){
      var _this = this
      if(this.tableData.formItem.id){
        var url = '/updatePermission'
      }else {
        var url =  '/addPermission'
      }
      this.send({
        url : url,
        data: {
          id : this.tableData.formItem.id,
          pid : this.tableData.formItem.pid,
          level : this.tableData.formItem.level,
          name : this.tableData.formItem.name,
          sort : this.tableData.formItem.sort,
          url : this.tableData.formItem.url,
        },
        success: function (res) {
          _this.cancel()
          _this.permissionList()
        }
      })
    },
    // 获取当前表单数据
    operations: function (row,title){
      console.log(this.navigationBar)
      this.tableData.formItem = row
      this.tableData.title = title
      this.tableData.show = true
    },
    // 获取菜单列表
    permissionList: function(){
      var _this = this
      this.send({
        url : '/permissionList',
        data: {},
        success: function (res) {
          console.log(res)
          for(var i in res){
            res[i].children = res[i].get_tree
          }
          _this.navigationBar = res
          console.log(_this.navigationBar)
        }
      })
    },
    // 删除菜单
    remove: function (row){
      var _this = this
      this.send({
        url : '/delPermission',
        data: {
          id: row.id
        },
        success: function (res) {
          _this.permissionList()
        }
      })
    },
    // 清空数据
    cancel: function (){
      this.tableData.formItem = {
        id : '',
        pid : 0, // 选择对应的二级菜单 默认0
        level : 0,
        name : '',
        sort : '',
        url : '',
      }
    },
  },
  mounted() {
    this.permissionList()
  }
}
</script>

<style scoped>

</style>
