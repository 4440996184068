<style scoped>
.bottom-false{width:100%;border-top:1px solid rgba(255, 255, 255, 0.5);color:#fff;font-size:16px;height:60px;line-height:60px;position:relative;display:inline-block;text-align:left;cursor:pointer;}
.bottom-false p{margin-left:20px;}
.forward{position:absolute;right:18px;top:21px;}
.layout:deep(.ivu-layout-header){height:120px}
/* 新样式 */
.header{padding:0px 50px;line-height:120px;height:120px;background-image:linear-gradient(to right, rgb(230, 59, 59), rgb(243, 133, 4));}
.logo{vertical-align:middle;width:80px;margin-right:20px;}
.header-title{vertical-align:middle;font-size:32px;font-weight:bold;color:#fff;}
.right-menu{float:right;}
.right-menu-text{font-size:16px;line-height:120px;color:#fff;display:inline-block;cursor:pointer;}
.right-menu-icon{width:20px;height:20px;vertical-align:middle;margin-right:5px;}
.body{position:absolute;top:120px;bottom:0;left:0;right:0;min-width:1200px;min-height:660px;}
.body:deep(.ivu-layout-sider){background-color:#464b57;}
.left-menu{background-color:#464b57;position:absolute;top:0;bottom:0;width:240px;overflow-x:hidden;overflow-y:auto;}
.welcome-name{font-size:16px;color:#ffffff;}
.welcome-text{font-size:14px;color:white}
.copyright{line-height:50px;font-size:13px;color:#dddddd;width:100%;}
</style>

<template>
  <div style="height:100%;width:100%;min-width: 1200px;">
    <!--修改密码-->
    <Modal v-model="resetPasswdPanel.state" title="修改密码" @on-ok="resetPasswd">
      密码：<Input v-model="resetPasswdPanel.passwd" placeholder="输入新密码" @on-change="checkPassword"></Input>
      <div class="password-tips-group slideup" >
        <div>
          <div style="display:inline-block;width: 12px">
            <Icon type="ios-checkmark-circle-outline" color="#19be6b" v-if="inspectData.space"/>
          </div>
          不能包括空格
        </div>
        <div class="password-tips ok" >
          <div style="display:inline-block;width: 12px">
            <Icon type="ios-checkmark-circle-outline" color="#19be6b" v-if="inspectData.length"/>
          </div>
          长度为8-16个字符
        </div>
        <div class="password-tips ok" >
          <div style="display:inline-block;width: 12px">
            <Icon type="ios-checkmark-circle-outline" color="#19be6b" v-if="inspectData.contain"/>
          </div>
          必须包含字母、数字、符号中至少2种
        </div>
        <div class="password-tips ok" >
          <div style="display:inline-block;width: 12px">
            <Icon type="ios-checkmark-circle-outline" color="#19be6b" v-if="inspectData.repeat"/>
          </div>
          请勿输入重复6位以上字母或数字，如111111
<!--          请勿输入连续、重复6位以上字母或数字，如111111、123、321-->
        </div>
      </div>
    </Modal>
    <!-- 弹窗 --END -->
<!--    明白人-->
    <div v-if="mbr || gzy" style="padding:0px 50px;line-height:120px;height:120px;background: url('/img/top.png');background-repeat: no-repeat;background-size: 100% 100%;">
      <img src="/img/logoGov.png" class="logo" @click="replaceCommunity">
      <span class="header-title">
          {{information.AdminName}} -{{sysName}}
      </span>
      <div class="right-menu">
        <a :href=" cq ?'https://notarization-manage-cq.lvwa18.com/':'http://notarization-manage.lvwa18.com/Admin/login/index.html'" target="_blank">
        <div style="cursor:pointer;display: inline-block;width: 128px;height: 36px;background: rgba(255,255,255,0);border: 1px solid #FFFFFF;border-radius: 4px;line-height: 36px;text-align: center;margin-right: 20px">
          <img style="width:16px;height:18px;vertical-align: top;margin: 9px 7px 0 0" src="/img/qiehuanyonghu.png">
          <div style="display: inline-block;font-size: 14px;font-family: PingFang SC;font-weight: 400;color: #FFFFFF;">公证受理系统</div>
        </div>
        </a>
<!--        <span @click="bigData()">-->
<!--          <img class="right-menu-icon" style="width: 24px" src="/img/bigData/bigData.png">-->
<!--          <span class="right-menu-text">大数据中心</span>-->
<!--        </span>-->
        <span style="padding:10px;"></span>
        <span @click="$router.go(0)">
          <img class="right-menu-icon" style="width: 24px" src="/img/shuaxin.png">
          <span class="right-menu-text">刷新</span>
        </span>
        <span style="padding:10px;"></span>
        <span @click="resetPasswdPanel.state = true">
          <img class="right-menu-icon" src="/img/changePassword.png">
          <span class="right-menu-text">修改密码</span>
        </span>
        <span style="padding:10px;"></span>
        <span @click="signOut()">
          <img class="right-menu-icon" src="/img/tuichu.png">
          <span class="right-menu-text">退出</span>
        </span>
      </div>
    </div>
    <div v-else class="header">
      <img src="/img/logoGov.png" class="logo" @click="replaceCommunity">
      <span class="header-title" @click="dataReport">
        <template v-if="information.OrganizationId == '86'">{{information.AdminName}}</template>
        <template v-else-if="information.ParentId == '2205' || information.AreaId == '2205'">
          {{information.AdminName}} - 智慧村居法律服务平台
        </template>
        <template v-else-if="information.ParentId == '1505' || information.AreaId == '1505'">
          {{information.AdminName}} - 律蛙数字法治-智慧司法平台
        </template>
        <template v-else-if="hostname == 'hhhtsft.lvwa18.com'">
          呼和浩特司法通 - {{information.AdminName}}管理端
        </template>
        <template v-else>{{information.AdminName}} - {{sysName}}</template>
      </span>
      <div class="right-menu">
        <div style="display:inline-block;" v-if="lawFirm == false">
          <router-link :to="{name:'loginMbr'}" target="_blank">
            <div style="display: inline-block;width: 128px;height: 36px;background: rgba(255,255,255,0);border: 1px solid #FFFFFF;border-radius: 4px;line-height: 36px;text-align: center;margin-right: 20px">
              <img style="width:16px;height:18px;vertical-align: top;margin: 9px 7px 0 0" src="/img/qiehuanyonghu.png">
              <div style="display: inline-block;font-size: 14px;font-family: PingFang SC;font-weight: 400;color: #FFFFFF;">法律明白人端</div>
            </div>
          </router-link>
          <router-link :to="{name:'loginGzy'}" target="_blank" v-if="cq">
            <div style="display: inline-block;width: 128px;height: 36px;background: rgba(255,255,255,0);border: 1px solid #FFFFFF;border-radius: 4px;line-height: 36px;text-align: center;margin-right: 20px">
              <img style="width:16px;height:18px;vertical-align: top;margin: 9px 7px 0 0" src="/img/qiehuanyonghu.png">
              <div style="display: inline-block;font-size: 14px;font-family: PingFang SC;font-weight: 400;color: #FFFFFF;">公证员端</div>
            </div>
          </router-link>
          <router-link :to="{name:'runReport'}" v-if="information.AdminId != '67'">
            <div style="display: inline-block;color: #FFF">
              <img class="right-menu-icon" style="width: 24px" src="/img/runReport.png">
              <span class="right-menu-text">运行报告</span>
              <div v-if="pollingCount" style="display: inline-block;background: #ed4014;height: 20px;border-radius: 10px;line-height: 20px;padding: 0 7px">
                {{pollingCount}}
              </div>
            </div>
          </router-link>
          <span style="padding:10px;"></span>
          <span @click="bigData()">
            <img class="right-menu-icon" style="width: 24px" src="/img/bigData/bigData.png">
            <span class="right-menu-text">大数据中心</span>
          </span>
        </div>
        <span style="padding:10px;"></span>
        <span @click="$router.go(0)">
          <img class="right-menu-icon" style="width: 24px" src="/img/shuaxin.png">
          <span class="right-menu-text">刷新</span>
        </span>
        <span style="padding:10px;"></span>
        <span @click="resetPasswdPanel.state = true" v-if="lawFirm == false">
          <img class="right-menu-icon" src="/img/changePassword.png">
          <span class="right-menu-text">修改密码</span>
        </span>
        <span style="padding:10px;"></span>
        <span @click="signOut()">
          <img class="right-menu-icon" src="/img/tuichu.png">
          <span class="right-menu-text">退出</span>
        </span>
      </div>
    </div>
    <div class="body">
      <div class="left-menu">
        <div style="padding:20px 0;text-align: center;">

          <div class="welcome-name"  style="margin-bottom: 20px">
            您好，<span>{{information.AdminLogin}}</span>
          </div>
          <div class="welcome-text">{{dateNow}}</div>
        </div>
        <!-- 菜单组件 -->
          <managerMenu :menuList="menu.list" :displayRules="menu.displayRules"  v-if="menu.list != ''"></managerMenu>
        <!--<div>-->
          <!--<div class="bottom-false">-->
            <!--<a href="http://notarization-manage.lvwa18.com/" target="_blank">-->
              <!--<p style="color:#FFF">公证管理</p>-->
            <!--</a>-->
            <!--<Icon color="#FFF" type="ios-arrow-forward" class="forward" />-->
          <!--</div>-->
        <!--</div>-->
        <div style="text-align: center;bottom:0;width:100%;z-index: 99;">
          <div class="copyright">Copyright © 2022-2035 律蛙科技</div>
        </div>
      </div>
      <div style="position: absolute;left:240px;right:0;top:0;bottom:0;background-color:rgb(241,239,240);">
        <div style="position: absolute;top:20px;bottom:20px;left:20px;right:20px;background-color:#fff;box-shadow:rgba(0,0,0,0.3) 1px 1px 5px 0px;overflow:auto;">
          <router-view />
          <!--    服务information-->
          <div v-if="cq">
            <div v-if="information.IsSign != 1">
              <div style="position: fixed;width: calc(100% - 240px);height: 100%;z-index: 99;background-color: rgba(227, 227, 227 , 0.8);top: 120px;" v-if="pathname == '/punchIn' || pathname == '/report'">
                <div style="text-align: center;color: #000;font-size: 20px;margin-top: 20%">
                  <div>感谢您一直以来对“重庆村居法务平台”的支持和关注</div>
                  <div>本功能为增值服务项目，请购买后使用！</div>
                  <div style="margin-top: 30px">
                    <div>平台服务商： 重庆律蛙信息科技有限公司</div>
                    <div>地   址：重庆市江北区五里店街道创富路5号1幢4楼</div>
                    <div>联系人：唐应坤  18980633318</div>
                  </div>
                  <div style="display:inline-block;margin-top: 20px">
                    <Button @click="introduction()">“重庆村居法务平台”的定制增值服务项目介绍.pdf</Button>
                  </div>
                  <div style="margin-top: 20px">
                    <Button @click="introduction1('https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/pdf/%E9%87%8D%E5%BA%86%E6%9D%91%E5%B1%85%E6%B3%95%E5%8A%A1%E5%B9%B3%E5%8F%B0-%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.docx')">重庆村居法务平台-服务协议.docx</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
        v-model="cqs"
        title="温馨提示" @on-ok="okBigData">
      <p>该功能为平台增值服务，试用截止时间为2022年4月30日。</p>
    </Modal>
    <Modal
        v-model="chengDu" width="600">
      <p>大数据中心</p>
      <div slot="footer">
        <template v-if="information.AdminId == 80">
          <div style="padding:3px;"></div>
          <div style="display: inline-block">请点击进入适合您的版本</div>
          <Button type="warning" @click="edition(3)">实时服务监管系统</Button>
          <span style="padding:5px;"></span>
          <Button type="success" @click="edition(1)">单屏数据版</Button>
          <span style="padding:5px;"></span>
          <Button type="info" @click="edition(2)">运管中心宽屏版</Button>
        </template>
        <template v-else>
          <div style="padding: 3px"></div>
          <div style="display: inline-block">请点击进入适合您的版本</div>
          <span style="padding:3px;"></span>
          <Button type="warning" @click="edition(1)">单屏数据版</Button>
          <span style="padding:5px;"></span>
          <Button type="info" @click="edition(2)">运管中心宽屏版</Button>
        </template>
      </div>
    </Modal>
  </div>

</template>

<script>
  import managerMenu from './components/menu/menu'
  export default {
    name: 'mdPage',
    data() {
      return {
        menu:{
          list:[],
          displayRules:{},
        },
        chengDu : false,
        cqs : false,
        resetPasswdPanel: {
          state: false,
          passwd: '',
        },
        path: '',//url
        current: '0',//
        information: [],
        //修改密码
        modal1: false,
        dazhou: [],
        sysName : '',
        count  : 0,
        clickTimer : null,
        cq : false,
        mbr : false,
        gzy : false,
        lawFirm : false,
      //   地址
        pathname : '',
      //   获取当前运行报告数量
        pollingCount: '',

        isValid: true,
        inspectData : {
          space: true,
          length: false,
          contain: false,
          repeat: true,
        },
        hostname: ''
      }
    },
    components:{
      managerMenu:managerMenu,
    },
    created() {
      let _this = this;
      window.addEventListener('setItem', ()=> {
        let printReportShowQuery = JSON.parse(sessionStorage.getItem('printReportShowQuery'));
        _this.pollingCount = printReportShowQuery.pollingCount
      });
    },

    mounted() {
      var _this = this
      _this.hostname = window.location.hostname
      if(window.sessionStorage.mbr){
        _this.mbr = true
      }
      if(window.sessionStorage.gzy){
        _this.gzy = true
      }
      if(window.sessionStorage.lawFirm){
        _this.lawFirm = true
      }
      if(SYSCONFIG.location == 'chong-qing'){
        _this.cq = true
        _this.reportCount()
        if(window.sessionStorage.mbr){
          _this.mbr = true
        }
        console.log(_this.mbr)
      }else if(window.sessionStorage.lawFirm){

      }else {
        _this.findDazhou()
      }
      _this.loadMenu()
      _this.information = window.sessionStorage
      if (window.sessionStorage.information == undefined) {
        this.$router.push({ name: 'managerLogin' })
        return
      }
      this.path = window.location.hash.split('/')[1]
      _this.path = _this.$route.path;
      if (_this.information.adminLevel == '4') {
        console.log(1)
      } else {
        console.log(2)
      }
      console.log(_this.sessionStorage)
      _this.sysName = window.sessionStorage.sysName
      if(_this.lawFirm == false){
        setInterval(function(){
          _this.cheackToken()
        },300000)
      }
      var meta = document.getElementsByTagName('meta');
      meta[2].content = ''
      this.pathname = window.location.pathname
    },
    methods: {
      checkPassword() {
        // 逐项检查密码规则
        this.isValid = true;

        // 检查空格
        if (this.resetPasswdPanel.passwd.includes(' ')) {
          this.isValid = false;
          this.inspectData.space = false
          return;
        }else {
          this.inspectData.space = true
        }

        // 检查长度是否在8-16个字符之间
        if (this.resetPasswdPanel.passwd.length < 8 || this.resetPasswdPanel.passwd.length > 16) {
          this.isValid = false;
          this.inspectData.length = false
          return;
        }else {
          this.inspectData.length = true
        }

        // 检查是否包含字母、数字、符号中至少2种
        let categories = 0;
        if (/[a-zA-Z]/.test(this.resetPasswdPanel.passwd)) categories++;
        if (/\d/.test(this.resetPasswdPanel.passwd)) categories++;
        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.resetPasswdPanel.passwd)) categories++;

        if (categories < 2) {
          this.isValid = false;
          this.inspectData.contain = false
          return;
        }else {
          this.inspectData.contain = true
        }

        // 检查是否输入连续、重复6位以上字母或数字
      // || /123|321/.test(this.resetPasswdPanel.passwd)
        if (/(.)\1{5,}/.test(this.resetPasswdPanel.passwd)) {
          this.isValid = false;
          this.inspectData.repeat = false
          return;
        }else {
          this.inspectData.repeat = true
        }
      },
      reportCount: function (){
        var _this = this
        _this.send({
          url : '/reportCount',
          data: {
            AdminId : window.sessionStorage.AdminId,
          },
          success: function (res) {
            _this.pollingCount = res
          }
        })
      },
      loadMenu:function(){
        let _this = this
        //动态加载地区的menu配置
        import (`./config/menu/${SYSCONFIG.location}${_this.mbr?'/mbr':''}${_this.gzy?'/gzy':''}${_this.lawFirm?'/lawFirm':''}`)
        .then(module=>{
          _this.menu.list = module.default.menuList
          _this.menu.displayRules = module.default.displayRules
        })
      },
      cheackToken : function(){
        var _this = this
        _this.send({
          url:"/CheackToken",
          data:{
            Token : window.sessionStorage.Token,
          },
          success: function (res) {
            console.log(res)
          }
        })
      },
      findDazhou: function () {
        var _this = this
        _this.send({
          url: '/CD_FindDazhou',
          data: {
            type: 'dazhou'
          },
          success: function (res) {
            _this.dazhou = res
            console.log(res)
          }
        })
      },
      resetPasswd: function () {
        var _this = this
        if (_this.isValid && _this.resetPasswdPanel.passwd != '') {
          _this.send({
            url: '/updateAccountPassword',
            data: {
              AdminId: window.sessionStorage.AdminId,
              AccountTypeId: window.sessionStorage.AccountTypeId,
              AccountLogin: window.sessionStorage.AdminLogin,
              AccountPassword : _this.resetPasswdPanel.passwd,
              AccountId: window.sessionStorage.AccountId,
            },
            success: function (res) {
              _this.$Notice.warning({
                title: '系统提示',
                desc: '修改成功',
              })
            }
          })
        } else {
          this.$Message.warning('密码不符合规则，请重新输入');
        }

      },
      //退出
      signOut: function () {
        var _this = this
        window.sessionStorage.information = ''
        if(this.lawFirm){
          window.sessionStorage.lawFirm = ''
          _this.$router.push({ name: 'loginLawFirm' })
          return
        }
        console.log(window.sessionStorage.information)
        _this.send({
          url: '/logout',
          data: {},
          success: function (res) {
            if(_this.mbr){
              _this.mbr = false
              window.sessionStorage.mbr = ''
              _this.$router.push({ name: 'loginMbr' })
            }else if(_this.gzy){
              _this.gzy = false
              window.sessionStorage.gzy = ''
              _this.$router.push({ name: 'loginGzy' })
            }else {
              _this.mbr = false
              window.sessionStorage.mbr = ''
              _this.$router.push({ name: 'managerLogin' })
            }
          }
        })


      },
      //退出
      bigData: function () {
        var _this = this
        if(false){
        // if(window.sessionStorage.ParentId == '2237' || window.sessionStorage.ParentId == '2262'){
          this.cqs = true
        }else{
          this.chengDu = true
          return
          if(window.sessionStorage.AdminId == '80'){
            this.chengDu = true
          }else {
            this.$Modal.confirm({
              title:'请选择进入的版本',
              okText:'旧版',
              cancelText:'新版',
              onOk:function(){
                // _this.$router.push({ name: 'bigData' })
                let routeData = _this.$router.resolve({name: "bigData",});
                window.open(routeData.href, '_blank');
              },
              onCancel:function(){
                // _this.$router.push({ name: 'MBDN' })
                let routeData = _this.$router.resolve({name: "MBDN",});
                window.open(routeData.href, '_blank');
              }
            })
          }
        }
      },
      edition : function (type){
        var _this = this
        _this.chengDu = false
        if(type == 1){
          let routeData = _this.$router.resolve({name: "bigData",});
          window.open(routeData.href, '_blank');
        }
        if(type == 2){
          let routeData = _this.$router.resolve({name: "MBDN",});
          window.open(routeData.href, '_blank');
        }
        if(type == 3){
          let routeData = _this.$router.resolve({name: "MDCB",});
          window.open(routeData.href, '_blank');
        }
      },
      okBigData : function (){
        let routeData = this.$router.resolve({
          name: "bigData",
        });
        window.open(routeData.href, '_blank');
      },
    //  跳转社区更换律师页面
      replaceCommunity : function (){
        var _this = this
        this.count++
        clearTimeout(this.clickTimer);
        this.clickTimer = window.setTimeout(function(){
          if(_this.count >= 3){
            _this.$router.push({ name: 'replaceCommunity' })
          }
          _this.count = 0;
          _this.clickTimer = null;
        },400);
      },
      dataReport : function (){
        var _this = this
        this.count++
        clearTimeout(this.clickTimer);
        this.clickTimer = window.setTimeout(function(){
          if(_this.count >= 3){
            _this.$router.push({ name: 'dataReport' })
          }
          _this.count = 0;
          _this.clickTimer = null;
        },400);
      },
      introduction : function (){
        window.open('https://ai-cdn.lvwa18.com/img/rusticate/cd-arbitration/pdf/%E2%80%9C%E9%87%8D%E5%BA%86%E6%9D%91%E5%B1%85%E6%B3%95%E5%8A%A1%E2%80%9D%E5%B9%B3%E5%8F%B0%E7%9A%84%E5%AE%9A%E5%88%B6%E5%A2%9E%E5%80%BC%E6%9C%8D%E5%8A%A1%E9%A1%B9%E7%9B%AE%E4%BB%8B%E7%BB%8DV2.pdf');
      },
      introduction1 : function (url){
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
      },
    },
    watch: {
      $route(to, from) {
        this.path = to.path
        this.information = window.sessionStorage
        this.pathname = window.location.pathname
      }
    },
    computed: {
      dateNow: function () {
        return `${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日`
      },
    },
  }
</script>
