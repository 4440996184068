<style scoped>
  .home{background-color: rgb(241,239,240);padding:20px;}
  .screen :deep(.ivu-tabs-nav-scroll){height: 60px;line-height: 60px;}
  /*.screen :deep(.ivu-tabs .ivu-tabs-tabpane){width: calc(100% - 180px);}*/
  .screen :deep(.ivu-tabs-nav){height: 60px;line-height: 60px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab-active){color: #00b86c;padding: 0px 16px;}
  .screen :deep(.ivu-tabs-nav .ivu-tabs-tab:hover){color: #00b86c;}
  .screen :deep(.ivu-tabs-ink-bar){background-color: #00b86c;}
  .screen :deep(.ivu-tabs-bar){border-bottom: 2px solid #d7d7d7;margin-bottom: 40px;}
  .bbb :deep(.ivu-select-single .ivu-select-selection .ivu-select-placeholder){color: #333}
  .publicWelfare table {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .formtable {font-family: Consolas,Menlo,Courier,monospace;font-size: 12px;border-collapse: collapse;border-spacing: 0;empty-cells: show;border: 1px solid #e9e9e9;width: 100%;margin-bottom: 24px;}
  .publicWelfare table th {background: #f7f7f7;white-space: nowrap;color: #5c6b77;font-weight: 600;}
  .publicWelfare table td, .publicWelfare table th {border: 1px solid #e9e9e9;padding: 8px 16px;text-align:center;}
  .input-text{width:3em}
</style>
<template>
  <div class="home">
        <!--查看img-->
    <Modal v-model="archivesPanel.state" :title="archivesPanel.fileContent.FileName" width="950" draggable :styles="{top: '0'}">
      <div style="height: 420px;overflow: auto;">
        <div style="text-align: center;margin-bottom: 10px;" v-if="archivesPanel.fileContent.DocumentUrl != '[]' && archivesPanel.fileContent.DocumentUrl" >
          <Button v-for="(item, index) in archivesPanel.fileContent.DocumentUrl" type="primary" @click="seeFile(item.url)">查看文件</Button>
        </div>
        <Row align="middle" >
          <Col span="12" v-if="archivesPanel.fileContent.FileUrl">
            <!-- 图片预览 -->
            <viewer :images="archivesPanel.fileContent.FileUrl">
              <Carousel v-if="archivesPanel.fileContent.FileUrl">
                <CarouselItem v-for="(value, index) in archivesPanel.fileContent.FileUrl.split(',')" style="text-align: center">
                  <img :src="assetsLink + value" style="width:auto;height:400px;">
                </CarouselItem>
              </Carousel>
            </viewer>
          </Col>
          <Col :span="archivesPanel.fileContent.FileUrl?'12':'24'">
            <div style="display: inline-block;vertical-align: top;width: 50%;margin-left: 20px">
              <div v-if="archivesPanel.fileContent.VillagesName">社区(村)：
                <p v-for="(value,index) in archivesPanel.fileContent.VillagesName" style="display: inline-block">
                  {{value}}<span v-if="index+1<archivesPanel.fileContent.VillagesName.length">-</span>
                </p>
              </div>
              <div>
                考核分栏：<span v-if="archivesPanel.fileContent.EvaluationTypePid">{{archivesPanel.fileContent.EvaluationTypePid}}-</span>{{archivesPanel.fileContent.EvaluationTitle}}
              </div>
              <div v-if="archivesPanel.fileContent.EvaluationCount">
                考核计数：<span>{{archivesPanel.fileContent.EvaluationCount}}{{tableItem.Unit}}</span>
              </div>
              <div v-if="archivesPanel.fileContent.ServiceLinkman">联系人名称：{{archivesPanel.fileContent.ServiceLinkman}}</div>
              <div v-if="archivesPanel.fileContent.ServiceTel">联系人电话：{{archivesPanel.fileContent.ServiceTel}}</div>
              <div v-if="archivesPanel.fileContent.ServiceCount">服务人数：{{archivesPanel.fileContent.ServiceCount}}</div>
              <div v-if="archivesPanel.fileContent.ServiceTime">服务时间：{{archivesPanel.fileContent.ServiceTime}}</div>
              <div v-if="parseInt(archivesPanel.fileContent.ServiceLong) > 0">服务时长：
                {{parseInt(archivesPanel.fileContent.ServiceLong)}}小时
                <span v-if="archivesPanel.fileContent.ServiceMins">{{archivesPanel.fileContent.ServiceMins}}分钟</span>
              </div>
              <div v-if="archivesPanel.fileContent.DailyType">服务方式：<p v-for="(value,index) in archivesPanel.fileContent.DailyType" style="display: inline-block">
                {{value}}<span v-if="index+1<archivesPanel.fileContent.DailyType.length">-</span></p>
              </div>
              <div v-if="archivesPanel.fileContent.FileExplain">内容：{{archivesPanel.fileContent.FileExplain}}</div>
            </div>
          </Col>
        </Row>
      </div>
      <div slot="footer">
        <Button @click="cancel">关闭</Button>
      </div>
    </Modal>
    <Modal v-model="tableDataListPanel.state" width="65" title="数据详情" @on-cancel="onCancel">
      <Row :gutter="16" style="line-height:40px;font-size:14px;font-weight:bold;">
        <Col span="8">标题</Col>
        <Col span="3">服务数量</Col>
        <Col span="3">律师</Col>
        <Col span="4">服务时间</Col>
        <Col span="4">上传时间</Col>
        <Col span="2">操作</Col>
      </Row>
      <Divider style="margin:10px 0;" />
      <template v-for="(value,key) in tableDataListPanel.list">
        <Row :gutter="16" style="font-size:13px;">
          <Col span="8">{{value.FileName}}</Col>
          <Col span="3">{{value.EvaluationCount}}{{tableItem.Unit}}</Col>
          <Col span="3">{{value.lawyer[0].LawyerName}}</Col>
          <Col span="4">{{value.ServiceTime}}</Col>
          <Col span="4">{{value.FileTime}}</Col>
          <Col span="2"><div @click="getContent(value.FileId)">查看</div></Col>
        </Row>
        <Divider dashed style="margin:10px 0;" />
      </template>
      <Page :total="tableDataListPanel.pageBar.total" :page-size="tableDataListPanel.pageBar.pageSize" show-elevator
        show-total @on-change="tableDataListPanel.pageBar.current = $event;getDataList(tableItem)"/>
    </Modal>
    <Modal v-model="scorePanel.state" title="基础分值设定" width="850px" @on-ok="setScoreRules">
      <Row>
        <Col span="24">
        <Row>
          <Col span="11">1、村（居）法律顾问每月至少到村（居）服务不少于</Col>
          <Col span="4"><Input size="small" v-model="scorePanel.data.serveTime.requirement" class="input-text" /> 个小时，
          </Col>
          <Col span="3"> 得 <Input size="small" v-model="scorePanel.data.serveTime.completeScore" class="input-text" />
          分。</Col>
          <Col span="6">不满足要求，扣 <Input size="small" v-model="scorePanel.data.serveTime.deductionScore"
            class="input-text" /> 分。</Col>
        </Row>
        </Col>
      </Row>
      <Divider style="margin:10px;" dashed />
      <Row>
        <Col span="24">
        <Row>
          <Col span="11">2、为村(居)治理提供法律意见</Col>
          <Col span="4"><Input size="small" v-model="scorePanel.data.opinion.requirement" class="input-text" /> 次，</Col>
          <Col span="3">得 <Input size="small" v-model="scorePanel.data.opinion.completeScore" class="input-text" /> 分，
          </Col>
          <Col span="6">少登记一次，扣 <Input size="small" v-model="scorePanel.data.opinion.deductionScore"
            class="input-text" /> 分。</Col>
        </Row>
        </Col>
      </Row>
      <Divider style="margin:10px;" dashed />
      <Row>
        <Col span="24">
        <Row>
          <Col span="11">3、为群众提供法律咨询和法律帮助</Col>
          <Col span="4"><Input size="small" v-model="scorePanel.data.consult.requirement" class="input-text" /> 次，</Col>
          <Col span="3">得 <Input size="small" v-model="scorePanel.data.consult.completeScore" class="input-text" /> 分，
          </Col>
          <Col span="6">少登记一次，扣 <Input size="small" v-model="scorePanel.data.consult.deductionScore"
            class="input-text" /> 分。</Col>
        </Row>
        </Col>
      </Row>
      <Divider style="margin:10px;" dashed />
      <Row>
        <Col span="24">
        <Row>
          <Col span="11">4、参与开展法治宣传</Col>
          <Col span="4"><Input size="small" v-model="scorePanel.data.propaganda.requirement" class="input-text" /> 次，
          </Col>
          <Col span="3">得 <Input size="small" v-model="scorePanel.data.propaganda.completeScore" class="input-text" />
          分，</Col>
          <Col span="6">少登记一次，扣 <Input size="small" v-model="scorePanel.data.propaganda.deductionScore"
            class="input-text" /> 分。</Col>
        </Row>
        </Col>
      </Row>
      <Divider style="margin:10px;" dashed />
      <Row>
        <Col span="24">
        <Row>
          <Col span="11">5、应人民调解组织邀请，参与人民调解工作。</Col>
          <Col span="4"><Input size="small" v-model="scorePanel.data.mediate.requirement" class="input-text" /> 次，</Col>
          <Col span="3">得 <Input size="small" v-model="scorePanel.data.mediate.completeScore" class="input-text" /> 分，
          </Col>
          <Col span="6">少登记一次，扣 <Input size="small" v-model="scorePanel.data.mediate.deductionScore"
            class="input-text" /> 分。</Col>
        </Row>
        </Col>
      </Row>
      <Divider style="margin:10px;" dashed />
      <Row>
        <Col span="24">
        <Row>
          <Col span="11">6、开展法律服务振兴乡村</Col>
          <Col span="4"><Input size="small" v-model="scorePanel.data.develop.requirement" class="input-text" /> 次，</Col>
          <Col span="3">得 <Input size="small" v-model="scorePanel.data.develop.completeScore" class="input-text" /> 分，
          </Col>
          <Col span="6">少登记一次，扣 <Input size="small" v-model="scorePanel.data.develop.deductionScore"
            class="input-text" /> 分。</Col>
        </Row>
        </Col>
      </Row>
    </Modal>
    <div style="font-size:16px;text-align: left;border-left:solid #de4f0b 3px;padding-left:5px">报表管理</div>
    <div style="padding:15px"></div>
    <Tabs v-model="selectValue" @on-click="switchClick">
      <TabPane label="村居治理" name="governance">
        <governance v-if="selectValue=='governance'"></governance>
      </TabPane>
      <TabPane label="律师数据" name="lawyer">
        <template v-if="selectValue=='lawyer'">
        <div style="margin:10px 10px;">
          <span>值班总时长：<span style="color:red">{{parseInt(lawyerTotalTime/60)}}小时{{lawyerTotalTime%60}}分</span></span>
          <span style="padding:10px"></span>
          <span>统计时间：</span>
          <div style="display: inline-block;">
            <RadioGroup type="button" v-model="lawyerCountDate.identification">
              <Radio label="m">月</Radio>
              <Radio label="q">季</Radio>
              <Radio label="y">年</Radio>
              <Radio label="a">所有</Radio>
              <Radio label="custom">自定义</Radio>
            </RadioGroup>
            <DatePicker v-if="lawyerCountDate.identification=='custom'"
              @on-change="lawyerCountDate.startTime = $event[0],lawyerCountDate.endTime=$event[1]" @on-ok="getLawyerList"
              type="datetimerange" format="yyyy-MM-dd" placeholder="请选择时间区间"></DatePicker>
          </div>
          <span style="padding:10px"></span>
          <div style="display: inline-block;">
            是否任职：
            <Select v-model="work" style="width:200px" clearable @on-change="getLawyerList">
              <Option value="1" key="1">任职中</Option>
              <Option value="0" key="0">未任职</Option>
            </Select>
          </div>
          <div style="float:right;">搜索律师：<Input style="width:200px;" placeholder="输入律师姓名" v-model="searchLawyerText" />
          </div>
        </div>
        <!--律师-->
        <Collapse  style="min-height: 500px" v-model="Lvalue1" accordion simple>
          <Panel :name="index.toString()" v-for="(value,index,k) in lawyerList"
            v-if="value.LawyerName.indexOf(searchLawyerText)!=-1">
            {{index+1}}、{{value.LawyerName}}
            <span style="font-size:12px;">
              （{{lawyerCountDate.identification=='m'?'本月':lawyerCountDate.identification=='q'?'季度':'当前'}}值班时长：
              <span style="color: red;">{{parseInt(value.OnlineTime/60)}}小时{{value.OnlineTime%60}}分钟 </span> |
              值班总时长：<span
                style="color: red;">{{parseInt(value.TotalOnlineTime/60)}}小时{{value.TotalOnlineTime%60}}分钟</span>）
              <span v-if="value.Work == 1">任职中</span>
              <span v-else="" style="color:red;">未任职</span>
              <span v-if="lawyerCountDate.identification!='a'&&lawyerCountDate.identification!='custom'" :style="{color:parseInt(value.OnlineTime) >= complianceValue?'red':''}">
                {{parseInt(value.OnlineTime) >= complianceValue ? '已达标' : '未达标'}}
              </span>
            </span>
            <div slot="content" class="publicWelfare" v-if="Lvalue1==index&&lawyerList[index].data"
              style="overflow: auto;">
              <table class="formtable">
                <thead>
                  <tr>
                    <th colspan="999">{{value.LawyerName}}-服务数据<span @click="exportExcel">[导出]</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td v-for="(item,index1) in StypeList" :colspan="item.list?item.list.length:0"
                      style="max-width:220px;">
                      <template>{{item.EvaluationTitle}}</template>
                    </td>
                    <!-- <td>备注</td> -->
                  </tr>
                  <tr>
                    <td></td>
                    <template v-for="(item,index1) in StypeList">
                      <td v-for="(item1,index1) in item.list" v-if="item.list" style="max-width:220px;">
                        <template>{{item1.EvaluationTitle}}</template>
                      </td>
                    </template>
                  </tr>
                  <tr>
                    <td></td>
                    <template v-for="(item,index1) in StypeList">
                      <td v-for="(item1,index2) in item.list" v-if="item.list" style="max-width:220px;">
                        <template>{{item1.Unit}}</template>
                      </td>
                    </template>
                  </tr>
                  <tr>
                    <td>当前</td>
                    <template v-for="(item1,index1) in StypeList">
                      <td v-for="(item2,index2) in item1.list" style="max-width:220px;">
                        <template v-for="(item3,index3) in lawyerList[index].data.this_month">
<!--                          {{item3.EvaluationTypeId==item2.EvaluationTypeId?item3.FileCount:''}}-->
                          <div style="cursor:pointer;color:blue;"
                               @click="getDataList({lawyerId:lawyerList[index].LawyerId,typeId:item3.EvaluationTypeId,Unit:item3.Unit})">
                            {{item3.EvaluationTypeId==item2.EvaluationTypeId?item3.FileCount:''}}</div>
                        </template>
                      </td>
                    </template>
                  </tr>
                  <tr>
                    <td>累计</td>
                    <template v-for="(item1,index1) in StypeList">
                      <td v-for="(item2,index2) in item1.list" style="max-width:220px;">
                        <template v-for="(item3,index3) in lawyerList[index].data.total">
                          {{item3.EvaluationTypeId==item2.EvaluationTypeId?item3.FileCount:''}}
                        </template>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </Panel>
        </Collapse>
        </template>
      </TabPane>
      <TabPane label="区域数据" name="area" style="position: relative">
        <template v-if="selectValue=='area'" >
          <Spin size="large" fix v-if="spinShow"></Spin>
          <div style="margin:10px 0px;">
            <span>统计时间：</span>
            <div style="display: inline-block;">
              <RadioGroup type="button" v-model="areaCountDate.identification">
                <Radio label="m">本月</Radio>
                <Radio label="q">本季</Radio>
                <Radio label="y">本年</Radio>
                <Radio label="a">所有</Radio>
                <Radio label="custom">自定义</Radio>
              </RadioGroup>
              <DatePicker v-if="areaCountDate.identification=='custom'"
                          @on-change="areaCountDate.startTime = $event[0],areaCountDate.endTime=$event[1]" @on-ok="getAdminCount"
                          type="datetimerange" format="yyyy-MM-dd" placeholder="请选择时间区间"></DatePicker>
            </div>
            <Button style="float: right;" type="primary" @click="getScoreRules">设置分值</Button>
          </div>
          <div class="publicWelfare" style="background-color:#fff;overflow: auto;">
            <table class="formtable">
              <thead>
              <tr>
                <th colspan="999">{{information.AdminName}}<span @click="exportExcel">[导出]</span></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td></td>
                <td v-for="(item,index) in StypeList" :colspan="item.list?item.list.length:0" style="max-width:220px;">
                  <template>{{item.EvaluationTitle}}</template>
                </td>
                <!-- <td>备注</td> -->
              </tr>
              <tr>
                <td></td>
                <template v-for="(item,index) in StypeList">
                  <td v-if="!item.list"></td>
                  <td v-for="(item1,index1) in item.list" v-if="item.list" style="max-width:220px;">
                    <template>{{item1.EvaluationTitle}}</template>
                  </td>
                </template>
              </tr>
              <tr>
                <td></td>
                <template v-for="(item,index) in StypeList">
                  <td v-if="!item.list"></td>
                  <td v-for="(item1,index1) in item.list" v-if="item.list" style="max-width:220px;">
                    <template>{{item1.Unit}}</template>
                  </td>
                </template>
              </tr>
              <tr>
                <td>当前</td>
                <template v-for="(item,index) in StypeList">
                  <td v-if="!item.list"></td>
                  <td v-if="item.list" v-for="(item1,index1) in item.list" style="max-width:220px;">
                    <template v-if="nowCount.this_month">
                      <template v-for="(item2,index2) in nowCount.this_month">
                        <div style="cursor:pointer;color:blue;"
                             @click="getDataList({adminId:information.AdminId,typeId:item1.EvaluationTypeId,Unit:item1.Unit})">
                          {{item2.EvaluationTypeId==item1.EvaluationTypeId?item2.FileCount:''}}</div>
                      </template>
                    </template>
                  </td>
                </template>
              </tr>
              <tr>
                <td>累计</td>
                <template v-for="(item,index) in StypeList">
                  <td v-if="!item.list"></td>
                  <td v-if="item.list" v-for="(item1,index1) in item.list" style="max-width:220px;">
                    <template v-if="nowCount.total">
                      <template v-for="(item2,index2) in nowCount.total">
                        {{item2.EvaluationTypeId==item1.EvaluationTypeId?item2.FileCount:''}}
                      </template>
                    </template>
                  </td>
                </template>
              </tr>
              </tbody>
            </table>
            <div style="padding:15px"></div>
          </div>
          <div style="padding:15px"></div>
          <!--</HelloWorld>-->
          <div style="background-color: rgb(241,239,240);font-size: 26px;">
            <div class="font-size">
              <div style="background-color: #fff;margin-bottom: 40px;font-size: 16px;text-align: left;">
                <!--司法厅列表-->
                <Collapse v-model="value1" accordion>
                  <Panel :name="index.toString()" v-for="(value,index,k) in electronicsList">
                    {{index+1}}、{{value.AdminName}}
                    <div slot="content" class="publicWelfare" v-if="value1[0]==index">
                      <table class="formtable">
                        <thead>
                        <tr>
                          <th colspan="999">{{value.AdminName}}<span @click="exportExcel">[导出]</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td></td>
                          <td v-for="(item,index) in StypeList" :colspan="item.list?item.list.length:0"
                              style="max-width:220px;">
                            <template>{{item.EvaluationTitle}}</template>
                          </td>
                          <!-- <td>备注</td> -->
                        </tr>
                        <tr>
                          <td></td>
                          <template v-for="(item,index) in StypeList">
                            <td v-for="(item1,index1) in item.list" v-if="item.list" style="max-width:220px;">
                              <template>{{item1.EvaluationTitle}}</template>
                            </td>
                          </template>
                        </tr>
                        <tr>
                          <td></td>
                          <template v-for="(item,index) in StypeList">
                            <td v-for="(item1,index1) in item.list" v-if="item.list" style="max-width:220px;">
                              <template>{{item1.Unit}}</template>
                            </td>
                          </template>
                        </tr>
                        <tr>
                          <td>当前</td>
                          <template v-for="(item1,index1) in StypeList">
                            <td v-for="(item2,index2) in item1.list" style="max-width:220px;" v-if="item1.list">
                              <template v-if="value1Data[index]">
                                <template v-for="(item3,index3) in value1Data[index].this_month">
                                  <template v-if="value1Data[index].this_month.length>0">
                                    <div style="cursor:pointer;color:blue;"
                                         @click="getDataList({adminId:value.AdminId,typeId:item2.EvaluationTypeId,Unit:item2.Unit})">
                                      {{item3.EvaluationTypeId==item2.EvaluationTypeId?item3.FileCount:''}}</div>
                                  </template>
                                </template>
                              </template>
                            </td>
                          </template>
                        </tr>
                        <tr>
                          <td>累计</td>
                          <template v-for="(item1,index1) in StypeList">
                            <td v-if="item1.list" v-for="(item2,index2) in item1.list" style="max-width:220px;">
                              <template v-if="value1Data[index]">
                                <template v-if="value1Data[index].total.length>0"
                                          v-for="(item3,index3) in value1Data[index].total">
                                  {{item3.EvaluationTypeId==item2.EvaluationTypeId?item3.FileCount:''}}
                                </template>
                                <template v-else>0</template>
                              </template>
                            </td>
                          </template>
                        </tr>
                        </tbody>
                      </table>
                      <Collapse v-model="value5" accordion>
                        <Panel v-for="(value1,index1,k1) in vallageTable[index]" :name="index1+'v'">
                          {{index1+1}}、{{value1.VillagesName}}（{{value1.LawyerName[0][0]}}）
                          <div slot="content" class="publicWelfare" v-if="value5[0]==index1+'v'">
                            <table class="formtable">
                              <thead>
                              <tr>
                                <th colspan="999">{{value1.LawyerName[0][0]}}-{{value1.VillagesName}} - 工作统计表<span
                                    @click="exportExcel">[导出]</span></th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td></td>
                                <td v-for="(item,index) in StypeList" :colspan="item.list?item.list.length:0"
                                    style="max-width:220px;">
                                  <template>{{item.EvaluationTitle}}</template>
                                </td>
                                <!-- <td>备注</td> -->
                              </tr>
                              <tr>
                                <td></td>
                                <template v-for="(item,index) in StypeList">
                                  <td v-for="(item1,index1) in item.list" v-if="item.list" style="max-width:220px;">
                                    <template>{{item1.EvaluationTitle}}</template>
                                  </td>
                                </template>
                              </tr>
                              <tr>
                                <td></td>
                                <template v-for="(item,index) in StypeList">
                                  <td v-for="(item1,index1) in item.list" v-if="item.list" style="max-width:220px;">
                                    <template>{{item1.Unit}}</template>
                                  </td>
                                </template>
                                <td></td>
                              </tr>
                              <tr>
                                <td>当前</td>
                                <template v-for="(item2,index2) in StypeList">
                                  <td v-if="item2.list" v-for="(item3,index3) in item2.list" style="max-width:220px;">
                                    <template v-if="vallageCount[index]!=undefined">
                                      <template v-if="vallageCount[index][index1+'v']">
                                        <template v-for="(item4,index4) in vallageCount[index][index1+'v'].this_month">
                                          <template v-if="vallageCount[index][index1+'v'].this_month.length>0">
                                            <div
                                                @click="getDataList({villageId:value1.VillagesId,typeId:item3.EvaluationTypeId,Unit:item3.Unit})">
                                              {{item4.EvaluationTypeId==item3.EvaluationTypeId?item4.FileCount:''}}
                                            </div>
                                          </template>
                                        </template>
                                      </template>
                                      <template v-else>失败
                                      </template>
                                    </template>
                                  </td>
                                </template>
                              </tr>
                              <tr>
                                <td>累计</td>
                                <template v-for="(item2,index2) in StypeList">
                                  <td v-if="item2.list" v-for="(item3,index3) in item2.list" style="max-width:220px;">
                                    <template v-if="vallageCount[index]!=undefined">
                                      <template v-if="vallageCount[index][index1+'v']">
                                        <template v-if="vallageCount[index][index1+'v'].total.length>0"
                                                  v-for="(item4,index4) in vallageCount[index][index1+'v'].total">
                                          {{item4.EvaluationTypeId==item3.EvaluationTypeId?item4.FileCount:''}}
                                        </template>
                                      </template>
                                      <template v-else>失败
                                      </template>
                                    </template>
                                  </td>
                                </template>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  </Panel>
                </Collapse>
                <div style="padding:10px"></div>
                <div style="text-align: left;margin-left: 40px;font-size: 16px">
                  <!--<Page :total="count" page-size="5"  show-elevator />-->
                  <!--<Page :total="count" pageSize="5" show-total on-page-size-change="box" @click="box" :current="page"/>-->
                  <Page :total="count" :page-size="pageSize" show-elevator @on-change="page = $event;totalAdminList()" />
                </div>
                <div style="padding:10px"></div>
              </div>
            </div>
          </div>
        </template>
      </TabPane>
      <TabPane :label="information.AdminName.replace('司法厅', '')+'数据'" name="current">
        <template v-if="selectValue=='current'" >
          <Spin size="large" fix v-if="spinShow"></Spin>
          <div style="margin:10px 0px;">
            <span>统计时间：</span>
            <div style="display: inline-block;">
              <RadioGroup type="button" v-model="areaCountDate.identification">
                <Radio label="m">本月</Radio>
                <Radio label="q">本季</Radio>
                <Radio label="y">本年</Radio>
                <Radio label="a">所有</Radio>
                <Radio label="custom">自定义</Radio>
              </RadioGroup>
              <DatePicker v-if="areaCountDate.identification=='custom'"
                          @on-change="areaCountDate.startTime = $event[0],areaCountDate.endTime=$event[1]" @on-ok="ReportEvaluationCount"
                          type="datetimerange" format="yyyy-MM-dd" placeholder="请选择时间区间"></DatePicker>
            </div>
          </div>
          <div class="publicWelfare" style="background-color:#fff;overflow: auto;">
            <table class="formtable">
              <thead>
              <tr>
                <th colspan="999">{{information.AdminName}}<span @click="exportExcel2">[导出]</span></th>
              </tr>
              </thead>
              <div id="your-table-id">
                <tr style="display: none">
                  <td>
                    {{information.AdminName}}村（居）法律顾问法律服务工作开展情况统计表
                  </td>
                </tr>
                <tbody>
                <tr>
                  <td></td>
                  <td v-for="(item,index) in StypeList" :colspan="item.list?item.list.length:0" style="max-width:220px;">
                    <template>{{item.EvaluationTitle}}</template>
                  </td>
                  <!-- <td>备注</td> -->
                </tr>
                <tr>
                  <td></td>
                  <template v-for="(item,index) in StypeList">
                    <td v-if="!item.list"></td>
                    <td v-for="(item1,index1) in item.list" v-if="item.list" style="max-width:220px;">
                      <template>{{item1.EvaluationTitle}}</template>
                    </td>
                  </template>
                </tr>
                <tr>
                  <td></td>
                  <template v-for="(item,index) in StypeList">
                    <td v-if="!item.list"></td>
                    <td v-for="(item1,index1) in item.list" v-if="item.list" style="max-width:220px;">
                      <template>{{item1.Unit}}</template>
                    </td>
                  </template>
                </tr>
                <tr v-for="(item,index) in ReportData">
                  <td>{{item[0].AreaShortName}}</td>
                  <template v-for="(item1,index) in StypeList">
                    <td v-if="!item1.list"></td>
                    <td v-if="item1.list" v-for="(item2,index1) in item1.list" style="max-width:220px;">
                      <template v-if="item[0].total">
                        <template v-for="(item3,index2) in item[0].total">
                          <div style="cursor:pointer;color:blue;" v-if="item2.EvaluationTypeId==item3.EvaluationTypeId"
                               @click="getDataList({adminId:information.AdminId,typeId:item2.EvaluationTypeId,Unit:item2.Unit})">
                            <!--                          {{item2.EvaluationTypeId}}{{item3.EvaluationTypeId}}-->
                            {{item2.EvaluationTypeId==item3.EvaluationTypeId?item3.FileCount:''}}
                          </div>
                        </template>
                      </template>
                    </td>
                  </template>
                </tr>
                </tbody>
              </div>
            </table>
            <div style="padding:15px"></div>
          </div>
        </template>
      </TabPane>
    </Tabs>


  </div>
</template>

<script>
import * as XLSX from 'xlsx'

import XLSXStyle from 'xlsx-style'
import FileSaver from 'file-saver'

import governance from '@/views/manager/data-center/governance/index'
  // import HelloWorld from '../../components/HelloWorld.vue'
  export default {
    name: "home",
    components: {governance,},
    data() {
      return {
        lawyerTotalTime:0,
        archivesPanel:{
          state:false,
          imgState:false,
          fileContent:{},
        },
        tableDataListPanel:{
          state:false,
          list:[],
          pageBar:{
            current:1,
            pageSize:15,
            total:0,
          },
          sendData:{}
        },
        scorePanel:{
          state:false,
          data:{
            serveTime:{
              completeScore:'',
              requirement:'',
              deductionScore:'',
            },
            opinion:{//意见
              completeScore:'',
              requirement:'',
              deductionScore:'',
            },
            consult:{//咨询
              completeScore:'',
              requirement:'',
              deductionScore:'',
            },
            propaganda:{//宣传
              completeScore:'',
              requirement:'',
              deductionScore:'',
            },
            mediate:{//调解
              completeScore:'',
              requirement:'',
              deductionScore:'',
            },
            develop:{//振兴乡村
              completeScore:'',
              requirement:'',
              deductionScore:'',
            },
          },
        },
        areaCountDate:{
          identification:'m',
          startTime:'',
          endTime:'',
        },
        lawyerCountDate:{
          identification:'m',
          startTime:'',
          endTime:'',
        },
        work:"",
        searchLawyerText:"",
        selectValue:'governance',
        nowCount:{},
        ReportData:{},
        area1: [],
        area2: [],
        area3: [],
        areaListData1: [],
        areaListData2: [],
        areaListData3: [],
        modify: true,
        information: [],
        electronicsList: [],
        StypeList:'',
        Lvalue1: '',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
        page: 1,
        pageSize: 50,
        modal1: false,
        modal3: false,
        //地址
        areaListData: [],
        areaList1: '',
        areaList2: '',
        areaList3: '',
        labelInValue: true,
        addressId: ['', '', ''],
        cityId: '',//获取市的index
        adminLogin: '',
        adminPassword: '',
        organizationPhone: '',
        organizationLinkman: '',
        organizationName: '',
        count: 0,
        vallageTable:{},
        vallageCount:{},
        value1Data:{},
        //
        addData: {
          typeId: '',
          upAdminId: '',
          AdminLogin: '',//登录名
          AdminPassword: '',//密码
          AreaId: '',//区域
          AreaName: '请选择',//区域名称
          AdminName: '',//名称
        },
        lawyerList:[],
        tableItem : [],
        spinShow: false,
        complianceValue: 200
      }
    },
    watch: {
      'area1': function (item) {
        if (item != '') {
          item = JSON.parse(item)
          console.log(item)
          if (item.AreaId) {
            this.addData.AreaId = item.AreaId
          }
          this.areaListData2 = []
          this.areaListData3 = []
          if (item.list) {
            this.areaListData2 = item.list
          }
        }

      },
      'area2': function (item) {
        if (item != '') {
          item = JSON.parse(item)
          if (item.AreaId) {
            this.addData.AreaId = item.AreaId
          }
          this.areaListData3 = []
          if (item.list) {
            this.areaListData3 = item.list
          }
        }
      },
      'area3': function (item) {
        if (item != '') {
          item = JSON.parse(item)
          if (item.AreaId) {
            this.addData.AreaId = item.AreaId
          }
        }
      },
      value1:function(val){
        var _this = this
        if(val.length<1){
          return
        }
        _this.spinShow = true
        _this.send({
          url:'/VillagesEvaluationCount',
          data:{
            StartTime:_this.areaCountDate.startTime,
            EndTime:_this.areaCountDate.endTime,
            DateType:_this.areaCountDate.identification,
            // AdminId:1206,
            AdminId:_this.electronicsList[val[0]].AdminId,
            DataValue:'',
            EvaluationData:'',
          },
          success:function(res){
            _this.spinShow = false
            _this.$set(_this.value1Data,val[0],{})
            _this.$set(_this.value1Data[val[0]],'total',[])
            for(var index in res.total){
              _this.value1Data[val[0]].total.push(res.total[index])
            }
            _this.$set(_this.value1Data[val[0]],'this_month',[])
            for(var index in res.this_month){
              _this.value1Data[val[0]].this_month.push(res.this_month[index])
            }
          }
        })
        _this.send({
          url:'/AdminVillagesList',
          data:{
            pageSize:9999,
            AdminId:_this.electronicsList[val[0]].AdminId,
          },
          success:function(res){
            _this.$set(_this.vallageTable,val[0],[])
            for(var i in res.List){
              if (res.List[i].LawyerName.indexOf(',') == -1) {
                var LawyerData = []
                LawyerData.push(res.List[i].LawyerName.split('+'))
                res.List[i].LawyerName = LawyerData
              } else {
                var LawyerList = res.List[i].LawyerName.split(',')
                var LawyerData = []
                for (var j in LawyerList) {
                  LawyerData.push(LawyerList[j].split('+'))
                }
                res.List[i].LawyerName = LawyerData
              }
              _this.vallageTable[val[0]].push(res.List[i])
              console.log(_this.vallageTable)
            }
          }
        })
      },
      value5:function(val){
        var _this = this
        if(val.length<1){
          return
        }
        _this.spinShow = true
        _this.send({
          url:'/VillagesEvaluationCount',
          data:{
            // VillagesId:5814,
            StartTime:_this.areaCountDate.startTime,
            EndTime:_this.areaCountDate.endTime,
            DateType:_this.areaCountDate.identification,
            VillagesId:_this.vallageTable[_this.value1[0]][parseInt(val[0])].VillagesId,
            DataValue:'',
            EvaluationData:'',
          },
          success:function(res){
            _this.spinShow = false
            _this.$set( _this.vallageCount,_this.value1[0],{})
            _this.$set( _this.vallageCount[_this.value1[0]],val[0],{})
            _this.$set( _this.vallageCount[_this.value1[0]][val[0]],'this_month',[])
            for(var index in res.this_month){
              _this.vallageCount[_this.value1[0]][val[0]].this_month.push(res.this_month[index])
            }
            _this.$set( _this.vallageCount[_this.value1[0]][val[0]],'total',[])
            for(var index in res.total){
              _this.vallageCount[_this.value1[0]][val[0]].total.push(res.total[index])
            }
          }
        })
      },
      'areaCountDate.identification':function(val){
        if(val!='custom'){
          this.areaCountDate.startTime = ''
          this.areaCountDate.endTime = ''
          if(this.selectValue=='area'){this.getAdminCount()}
          if(this.selectValue=='current'){this.ReportEvaluationCount()}

        }
      },
      'lawyerCountDate.identification':function(val){
        if(val!='custom'){
          this.Lvalue1 = ''
          this.lawyerCountDate.startTime = ''
          this.lawyerCountDate.endTime = ''
          this.getLawyerList()
          this.complianceValue = this.lawyerCountDate.identification=='m'?200:this.lawyerCountDate.identification=='q'?600:2400
        }
      },
      Lvalue1:function(val){
        var _this = this
        if(val.length<1){
          return
        }
        _this.spinShow = true
        _this.send({
          url:'/VillagesEvaluationCount',
          data:{
            StartTime:_this.lawyerCountDate.startTime,
            EndTime:_this.lawyerCountDate.endTime,
            DateType:_this.lawyerCountDate.identification,
            LawyerId:_this.lawyerList[val].LawyerId,
          },
          success:function(res){
            _this.spinShow = false
            _this.$set(_this.lawyerList[val],'data',{})
            _this.$set(_this.lawyerList[val].data,'this_month',[])
            _this.$set(_this.lawyerList[val].data,'total',[])
            for(var index in res.this_month){
              _this.lawyerList[val].data.this_month.push(res.this_month[index])
            }
            for(var index in res.total){
              _this.lawyerList[val].data.total.push(res.total[index])
            }
          }
        })
      }
    },
    methods: {
      getContent:function(id){
        var _this = this
        _this.send({
          url:"/AdminFileContent",
          data:{
            FileId:id,
          },
          success:function(res){
            if(res.DocumentUrl){
              try {
                res.DocumentUrl = JSON.parse(res.DocumentUrl)
              } catch (e) {
                //旧格式兼容，旧格式仅为文件路径，需截从路径取出文件名
                res.DocumentUrl = [{ name: getFileName(res.DocumentUrl), url: res.DocumentUrl }]
              }
            }
            _this.archivesPanel.fileContent = res
            _this.archivesPanel.state = true
          }
        })
        function getFileName(url) {
          return url.substring(url.lastIndexOf("/"))
        }
      },
      getDataList:function(tableItem){
        var _this = this
        _this.tableItem = tableItem
        console.log(_this.areaCountDate)
        _this.send({
          url:"/VillagesEvaluationList",
          data:{
            StartTime:_this.selectValue=='lawyer'?_this.lawyerCountDate.startTime:_this.areaCountDate.startTime,
            EndTime:_this.selectValue=='lawyer'?_this.lawyerCountDate.endTime:_this.areaCountDate.endTime,
            DateType:_this.selectValue=='lawyer'?_this.lawyerCountDate.identification:_this.areaCountDate.identification,
            PageSize:_this.tableDataListPanel.pageBar.pageSize,
            page:_this.tableDataListPanel.pageBar.current,
            AdminId:tableItem.adminId||window.sessionStorage.AdminId,
            EvaluationTypeId:tableItem.typeId,
            VillagesId:tableItem.villageId,
            LawyerId:tableItem.lawyerId,
          },
          success:function(res){
            _this.tableDataListPanel.sendData = tableItem
            _this.tableDataListPanel.state = true
            _this.tableDataListPanel.list = res.List
            _this.tableDataListPanel.pageBar.total = res.Count
            _this.tableDataListPanel.pageBar.current = res.CountPage
          }
        })
      },
      onCancel : function (){
        var _this = this
        _this.tableDataListPanel.pageBar.current = 1
        _this.tableDataListPanel.pageBar.total = 0
      },
      getScoreRules:function(){
        var _this = this
        _this.send({
          consulting : true,
          url:'/GetVillageScoreSetting',
          data:{
            AdminId:_this.information.AdminId,
          },
          success:function(res){
            if(res){
              _this.scorePanel.data = res.SettingContent
              _this.scorePanel.state = true
            }
          }
        })
      },
      setScoreRules:function(){
        var _this =this
        _this.send({
          url:'/SetVillageScoreSetting',
          data:{
            AdminId:_this.information.AdminId,
            SettingContent:_this.scorePanel.data
          },
          success:function(res){
            _this.$Notice.success({
              title:"系统提示",
              desc:"保存成功！"
            })
          }
        })
      },
      getLawyerList:function(){
        var _this = this
        console.log(_this.lawyerCountDate.identification)
        return new Promise(resolve=>{
          _this.send({
            url:'/FileLawyerGrouping',
            loading: true,
            data:{
              StartTime:_this.lawyerCountDate.startTime,
              EndTime:_this.lawyerCountDate.endTime,
              DateType:_this.lawyerCountDate.identification,
              AdminId:_this.information.AdminId,
            },
            success:res=>{
              var data = []
              for(var i in res.list) {
                if(_this.work){
                  if(res.list[i].Work == _this.work){
                    data.push(res.list[i])
                  }
                }else {
                  data.push(res.list[i])
                }
              }
              _this.lawyerList = data
              // _this.lawyerList = res.list
              _this.lawyerTotalTime = res._totaltime
              resolve()
            }
          })
        })
      },
      exportExcel2(){
        const table = document.getElementById("your-table-id");
        let titles = ['时间','电压']
        var sheet = XLSX.utils.table_to_sheet(table);
        /**设置标题头背景色 */


        for (const key in sheet) {
          // 第一行，表头
          if (key.replace(/[^0-9]/ig, '') === '1') {
            sheet[key].s = {
              font: {//字体
                name: '黑体',
                sz: 20,
                bold: true
              },
              border: {//边框
                bottom: {
                  style: 'thin',
                  color: 'FF000000'
                }
              },
              alignment:{
                vertical: 'center',
                horizontal:'center' //水平居中
              }
            }
            sheet['!rows'] = [{ hpx: 170 }];
            sheet['!merges'] = [
              { s: { r: 0, c: 0 }, e: { r: 0, c: 23 } },
              { s: { r: 1, c: 1 }, e: { r: 1, c: 3 } }, // 设置第二行合并
              { s: { r: 1, c: 4 }, e: { r: 1, c: 10 } },
              { s: { r: 1, c: 11 }, e: { r: 1, c: 14 } },
              { s: { r: 1, c: 15 }, e: { r: 1, c: 17 } },
              { s: { r: 1, c: 18 }, e: { r: 1, c: 21 } },
            ];
          }else if (key.replace(/[^0-9]/ig, '') === '2') {
            sheet[key].s = {
              font: {//字体
                name: '宋体',
                sz: 18,
                bold: true
              },
              alignment:{
                vertical: 'center',
                horizontal:'center' //水平居中
              }
            }
          }else if (key.replace(/[^0-9]/ig, '') === '3') {
            sheet[key].s = {
              alignment:{
                vertical: 'center',
                horizontal:'center', //水平居中
                wrapText: true // 自动换行
              }
            }
          }
          else{
            if(sheet[key].t){
              sheet[key].s = {
                alignment:{
                  vertical: 'center',
                  horizontal:'center' //水平居中
                },
                border: {
                  top: {
                    style: 'thin',
                    color: { rgb: 'FF000000' }
                  },
                  bottom: {
                    style: 'thin',
                    color: { rgb: 'FF000000' }
                  },
                  left: {
                    style: 'thin',
                    color: { rgb: 'FF000000' }
                  },
                  right: {
                    style: 'thin',
                    color: { rgb: 'FF000000' }
                  }
                },
              }
            }
          }
          if(sheet[key].t){
            sheet[key].s.border =  {
                top: {
                  style: 'thin',
                  color: { rgb: 'FF000000' }
                },
                bottom: {
                  style: 'thin',
                  color: { rgb: 'FF000000' }
                },
                left: {
                  style: 'thin',
                  color: { rgb: 'FF000000' }
                },
                right: {
                  style: 'thin',
                  color: { rgb: 'FF000000' }
                }
            }
          }
        }
        console.log(sheet)
        this.openDownload(this.sheet2blob(sheet,this.information.AdminName), this.information.AdminName+"村（居）法律顾问法律服务工作开展情况统计表.xlsx");
      },
      sheet2blob(sheet, sheetName) {
        let wb = XLSX.utils.book_new();
        wb.SheetNames.push(sheetName)
        wb.Sheets[sheetName] = sheet;
        var wbout = XLSXStyle.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' })

        // var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' });
        var blob = new Blob([s2ab(wbout)], { type: "" },sheetName);
        // 字符串转ArrayBuffer
        function s2ab(s) {
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
          return buf;
        }
        return blob;
      },
      openDownload(url, saveName) {
        if (typeof url == "object" && url instanceof Blob) {
          url = URL.createObjectURL(url); // 创建blob地址
        }
        var aLink = document.createElement("a");
        aLink.href = url;
        aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
        var event;
        if (window.MouseEvent) event = new MouseEvent("click");
        else {
          event = document.createEvent("MouseEvents");
          event.initMouseEvent(
              "click",
              true,
              false,
              window,
              0,
              0,
              0,
              0,
              0,
              false,
              false,
              false,
              false,
              0,
              null
          );
        }
        aLink.dispatchEvent(event);
      },

      exportExcel:function(dom){
        var table2excel = new Table2Excel()
        table2excel.export(dom.srcElement.parentElement.parentElement.parentElement.parentElement)
      },
      seeFile: function (url) {
        console.log(url)
        var _this = this
        var fileType = url.substr(url.lastIndexOf(".") + 1, 4).toLowerCase()
        if (fileType == "txt") {
          axios({
            method: 'get',
            responseType: 'blob',
            url: url,
            transformResponse: [function (data) {
              var render = new FileReader()
              render.readAsText(data, 'UTF8')
              render.onload = function () {
                if (render.result.indexOf('�') != -1) {
                  var renderGBK = new FileReader()
                  renderGBK.readAsText(data, 'GBK')
                  renderGBK.onload = function () {
                    var newWindow = window.open('about:blank', '', 'width=400,height=400')
                    newWindow.document.write(renderGBK.result)
                  }
                } else {
                  var newWindow = window.open('about:blank', '', 'width=400,height=400')
                  newWindow.document.write(render.result)
                }
              }
            }]
          })
        } else{
          if (fileType == 'pdf') {
            window.open(_this.assetsLink+url)
          } else {
            if (SYSCONFIG.location == 'chong-qing') {
              _this.showPdf(url)
            } else {
              window.open('https://view.officeapps.live.com/op/view.aspx?src=' + _this.assetsLink+url)
            }
          }
        }
      },
      showPdf: function (e) {
        var _this = this
        if (_this.location == 'chong-qing') {
          var url = '/ShowPdf'
        } else {
          var url = '/CD_ShowPdf'
        }
        window.open(window.sessionStorage.url + 'api' + url + '?FilePath='+ e)
      },
      totalAdminList: function () {
        var _this = this
        _this.addData.typeId = ''
        _this.addData.upAdminId = ''
        _this.addData.AdminLogin = ''
        _this.addData.AdminPassword = ''
        _this.addData.AreaId = ''
        _this.addData.AreaName = '请选择'
        _this.addData.AdminName = '',
          _this.addressId = ['', '', ''],
          _this.areaList1 = ''
        _this.areaList2 = ''
        _this.areaList3 = ''
        _this.area1 = []
        _this.area2 = []
        _this.area3 = []
        _this.areaListData2 = []
        _this.areaListData3 = []
        if (_this.information.adminLevel == 2) {
          _this.send({
            url: '/justiceList',
            data: {
              AdminId: window.sessionStorage.AdminId,
              Level: _this.information.adminLevel,
              page: _this.page,
              pageSize: _this.pageSize,
            },
            success: function (res) {
              _this.electronicsList = []
              _this.electronicsList = res.list
              _this.count = res.count
              // console.log(res.list)
            }
          })
        }
        if (_this.information.adminLevel == 1) {
          _this.send({
            url: '/AllAdminList',
            data: {
              AdminId: window.sessionStorage.AdminId,
              Level: _this.information.adminLevel,
              page: _this.page,
              pageSize: _this.pageSize,
            },
            success: function (res) {
              _this.electronicsList = []
              _this.electronicsList = res.list
              _this.count = res.count
              // console.log(res.list)
            }
          })
        }

      },
      addTto: function (typeId, adminId, type, adminName, adminLogin, adminPassword, areaId, AreaName) {
        var _this = this
        if (AreaName) {
          _this.modify = false
        } else {
          _this.modify = true
        }
        console.log(typeId, adminId, type, adminName, adminLogin, adminPassword, areaId, AreaName)
        //type==1编辑   type==2添加
        if (type == 1) {
          _this.addData.OwnAdminId = adminId
          _this.addData.upAdminId = ''
          _this.addData.AdminName = adminName
          _this.addData.AdminLogin = adminLogin
          _this.addData.AdminPassword = adminPassword
          _this.addData.AreaId = areaId
          _this.addData.AreaName = AreaName
        }
        if (type == 2) {
          _this.addData.OwnAdminId = ''
          _this.addData.upAdminId = adminId
          console.log(typeId, adminId, type)
        }
        // if(typeId == 3){
        //     _this.addData.OwnAdminId = ''
        //     _this.addData.upAdminId = adminId
        // }
        _this.addData.typeId = typeId,
          _this.modal1 = !_this.modal1
        console.log(_this.addData)
      },
      //删除
      deleteClick: function (ownAdminId) {
        var _this = this
        _this.send({
          confirm: true,
          url: '/DeleteTotalAdmin',
          data: {
            AdminId: _this.information.AdminId,
            OwnAdminId: ownAdminId,
          },
          success: function (res) {
            _this.totalAdminList()
          }
        })
      },
      //弹窗取消
      cancel() {
        var _this = this
        _this.addData.typeId = ''
        _this.addData.upAdminId = '',
        _this.addData.AdminLogin = '',//登录名
        _this.addData.AdminPassword = '',//密码
        _this.addData.AreaId = '',//区域
        _this.addData.AdminName = ''//名称
        _this.addData.AreaName = '请选择'
        _this.areaList1 = ''
        _this.areaList2 = ''
        _this.areaList3 = ''
        _this.area1 = []
        _this.area2 = []
        _this.area3 = []
        _this.areaListData2 = []
        _this.areaListData3 = []
        _this.archivesPanel.state = false
      },
      //    地区
      areaList: function () {
        var _this = this
        _this.send({
          url: '/EvaluationTypeList',
          data: {
            AdminId:_this.information.AdminId,
          },
          success: function (res) {
            if(_this.selectValue=='current'){
              for(var i in res){
                if(res[i].EvaluationTypeId == 4){
                  res[i].list.push({
                    AdminId:0,
                    EvaluationTitle:"调解总计",
                    EvaluationTypeId:999,
                    EvaluationTypePid:1000,
                    IsCount:1,
                    ReplaceId:null,
                    Sort:null,
                    Unit:"件",
                  })
                }
              }
            }
            _this.StypeList = res
          }
        })
      },
      //    选择
      choice: function (value) {
        var _this = this
        var data = value.value.split(',')
        if (data[1] == 0) {
          _this.addressId = ['', '', '']
        } if (data[1] == 1) {
          _this.addressId[2] = ''
          _this.addressId[1] = ''
          _this.cityId = data[2]
        }
        _this.addData.AreaId = data[0]
        _this.addressId[data[1]] = data[0]
        console.log(_this.addressId)
        // console.log(data)
        // console.log(_this.addressId)
      },
      addTto1: function (AreaId, Level, AdminId) {
        var _this = this
        _this.AreaId = AreaId
        _this.Level = Level
        _this.AdminId = AdminId
        _this.modifyType = false
      },
      addMember1: function () {
        var _this = this
        console.log(_this.AreaId, _this.Level, _this.AdminId, _this.modifyType)
        if (_this.modifyType == false) {
          if (_this.organizationName != '' && _this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword != '' && _this.adminLogin != '') {
            // if(_this.cityList.length == 0){
            //     // var AreaId = _this.information.AreaId
            //     _this.AreaId = _this.information.AreaId
            // }

            _this.send({
              url: '/AddMember',
              data: {
                Level: _this.Level,
                AdminId: _this.AdminId,
                AdminLogin: _this.adminLogin,
                AdminPassword: _this.adminPassword,
                OrganizationLinkman: _this.organizationLinkman,
                OrganizationPhone: _this.organizationPhone,
                OrganizationName: _this.organizationName,
                AreaId: _this.addData.AreaId,
              },
              success: function (res) {
                _this.totalAdminList()
                _this.adminLogin = ''
                _this.adminPassword = ''
                _this.organizationLinkman = ''
                _this.organizationPhone = ''
                _this.organizationName = ''
                _this.addData.AreaId = ''
                _this.model8 = ''
              }
            })
          } else {
            _this.$Notice.warning({
              title: '系统提示',
              desc: '输入完成后添加',
            })
          }
        } else {
          if (_this.organizationName != '' && _this.organizationPhone != '' && _this.organizationLinkman != '' && _this.adminPassword != '' && _this.adminLogin != '') {
            // if(_this.cityList.length == 0){
            //     // var AreaId = _this.information.AreaId
            //     _this.AreaId = _this.information.AreaId
            // }
            _this.send({
              url: '/AdminEdit',
              data: {
                AdminId: _this.AdminId,
                AdminLogin: _this.adminLogin,
                AdminPassword: _this.adminPassword,
                AdminName: _this.AdminName,
                OrganizationName: _this.organizationName,
                OrganizationLinkman: _this.organizationLinkman,
                OrganizationPhone: _this.organizationPhone,
                AreaId: _this.addData.AreaId,
              },
              success: function (res) {
                _this.totalAdminList()
                _this.adminLogin = ''
                _this.adminPassword = ''
                _this.organizationLinkman = ''
                _this.organizationPhone = ''
                _this.organizationName = ''
                _this.addData.AreaId = ''
                _this.model8 = ''
              }
            })
          } else {
            _this.$Notice.warning({
              title: '系统提示',
              desc: '输入完成后添加',
            })
          }
        }
      },
      cancel1() {
        var _this = this
        _this.AdminId = ''
        _this.adminLogin = ''
        _this.adminPassword = ''
        _this.organizationLinkman = ''
        _this.organizationPhone = ''
        _this.organizationName = ''
        _this.addData.AreaId = ''
        _this.model8 = ''
        _this.Level = ''
        _this.modifyType = false
      },
      onekeyLogin: function (AdminLogin, AdminPassword) {
        // this.$router.push({path: '/login',query:{ adminLogin:AdminLogin,adminPassword:AdminPasswo}});
      },
      getAdminCount:function(){
        var _this = this
        _this.spinShow = true
        _this.send({
          url:'/VillagesEvaluationCount',
          data:{
            StartTime:_this.areaCountDate.startTime,
            EndTime:_this.areaCountDate.endTime,
            DateType:_this.areaCountDate.identification,
            // AdminId:1206,
            AdminId:_this.information.AdminId,
          },
          success:function(res){
            _this.spinShow = false
            _this.value1 = ''
            _this.nowCount = {}
            _this.nowCount = res
          }
        })
      },
      ReportEvaluationCount : function (){
        var _this = this
        _this.send({
          url: '/ReportEvaluationCount',
          data: {
            StartTime:_this.areaCountDate.startTime,
            EndTime:_this.areaCountDate.endTime,
            DateType:_this.areaCountDate.identification,
            AdminId:_this.information.AdminId,
          },
          success: function (res) {
            console.log(_this.electronicsList)
            for(var i in res){
              for(var j in _this.electronicsList){
                  if(_this.electronicsList[j].AdminId == i){
                    res[i][0].AreaShortName = _this.electronicsList[j].AreaShortName || _this.electronicsList[j].AdminName
                  }
              }
              if(i == window.sessionStorage.AdminId){res[i][0].AreaShortName = window.sessionStorage.AdminName.replace('司法厅', '')}
            }
            for(var index in res){
              var FileCount = 0
              for(var k in res[index][0].total){
                if(res[index][0].total[k].EvaluationTypeId == 19 || res[index][0].total[k].EvaluationTypeId == 20){
                  FileCount += parseInt(res[index][0].total[k].FileCount)
                }
              }
              res[index][0].total.push({
                EvaluationTitle:"调解总计",
                EvaluationTypeId:999,
                FileCount: FileCount || '',
                IsCount:1,
                Unit:"件",
              })
            }
            _this.ReportData = res
          }
        })
      },
      switchClick : function (e){
        var _this = this
        if(e == 'lawyer'){
          _this.getLawyerList()
          _this.areaList()
        }
        if(e == 'area'){
          if(_this.areaCountDate.identification == 'custom'){_this.areaCountDate.identification = 'm'}
          _this.totalAdminList()
          _this.areaList()
          _this.getAdminCount()
        }if(e == 'current'){
          if(_this.areaCountDate.identification == 'custom'){_this.areaCountDate.identification = 'm'}
          _this.totalAdminList()
          _this.areaList()
          _this.getAdminCount()
          _this.ReportEvaluationCount ()
        }
      },
      appointment: function (e){
        console.log(e,this.work)
        var _this = this
        var data = []
        for(var i in _this.lawyerList) {
          console.log(_this.lawyerList[i].Work == e)
          if(_this.lawyerList[i].Work == e){
            data.push(_this.lawyerList[i])
          }
        }
        _this.lawyerList = data
      },
    },
    mounted() {
      var _this = this
      if (window.sessionStorage.adminLevel == undefined) {
        this.$router.push({name:'login'})
      } else {
        this.information = window.sessionStorage
        // _this.information.AdminId = 0
        //     || window.sessionStorage.AreaId == '2236'
      }
    },
    computed:{
      location:function(){
        return SYSCONFIG.location
      },
      assetsLink:function(){
        return `${SYSCONFIG.server.default.ssl?'https':'http'}://${SYSCONFIG.server.default.host}:${SYSCONFIG.server.default.port}/`
      }
    },
  }
</script>
